import dayjs from 'dayjs';
import {GridActionsCellItem, GridRowModes} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {constants} from '../../../../constants/common';

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

export const invoiceInstallmentColumns = (
  onRowDelete,
  onCancel,
  handleRowSaveClick,
  handleEditClick,
  rowModesModel,
  columnAlignment,
) => {
  return [
    {
      field: 'dueDate',
      headerName: 'Due Date',
      width: 150,
      align: columnAlignment.left,
      headerAlign: columnAlignment.left,
      editable: true,
      valueFormatter: params =>
        params?.value
          ? dayjs(params?.value).format(constants.DATE_FORMAT_2)
          : '',
      type: 'date',
    },
    {
      field: 'tranAmt',
      headerName: 'Amount',
      type: 'number',

      valueFormatter: params =>
        params?.value?.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      width: 150,
      align: columnAlignment.left,
      headerAlign: columnAlignment.left,
      editable: true,
    },

    {
      field: 'balance',
      headerName: 'Balance',
      width: 150,
      align: columnAlignment.left,
      headerAlign: columnAlignment.left,
      valueFormatter: params =>
        params?.value?.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      type: 'number',
      editable: false,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({row}) => {
        const isInEditMode =
          rowModesModel && rowModesModel[row.id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={() => handleRowSaveClick(row.id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={() => onCancel(row.id)}
              color="inherit"
            />,
          ];
        }
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={() => handleEditClick(row.id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => onRowDelete(row.id)}
            color="inherit"
          />,
        ];
      },
    },
  ];
};
