import {Route, Routes, Navigate, useLocation} from 'react-router-dom';
import {UserInvoices} from '../../../components/inquiries/by-user/user-invoices/UserInvoices';
import {UserPayments} from '../../../components/inquiries/by-user/payments/UserPayments';
import CommunicateWrapper from '../../../components/communicate/CommunicateWrapper';
import {ScheduledPayments} from '../../../components/inquiries/by-user/scheduled-paymnets/ScheduledPayments';
import Communication from '../../../components/messages/communication/Communication';
import {PendingPayments} from '../../../components/inquiries/by-user/pending-payments/PendingPayments';
import MailBox from '../../../components/messages/mail-box/MailBox';
import {ExpectedPayments} from '../../../components/inquiries/by-user/ExpectedPayments/ExpectedPayments';
import {FollowUp} from '../../../components/messages/follow-up/FollowUp';
import {ReportingGroupLogs} from '../../../components/inquiries/by-user/reporting-group-logs/ReportingGroupLogs';
import {ProjectedCashReceipts} from '../../../components/inquiries/by-user/projected-cash-receipts/ProjectedCashReceipts';
import {UserContacts} from '../../../components/inquiries/by-user/user-contacts/UserContacts';
import {ActivitySummaryWrapper} from '../../../components/inquiries/by-user/activity-summary/ActivitySummaryWrapper';

export function HandleInquiriesByUserChildRoutes() {
  const location = useLocation();
  const keyForCommunicationRoutes = location.pathname.includes('/activity')
    ? location.pathname.replace('/activity', '')
    : location.pathname;
  return (
    <Routes>
      <Route
        path="activities"
        element={<Communication key={keyForCommunicationRoutes} />}
      >
        <Route path="activity" element={<CommunicateWrapper />} />
      </Route>
      <Route path="payments" element={<UserPayments />}>
        <Route path="activity" element={<CommunicateWrapper />} />
      </Route>
      <Route path="invoices" element={<UserInvoices />}>
        <Route path="activity" element={<CommunicateWrapper />} />
      </Route>
      <Route path="scheduled-payments" element={<ScheduledPayments />}>
        <Route path="activity" element={<CommunicateWrapper />} />
      </Route>
      <Route path="pending-payments" element={<PendingPayments />}>
        <Route path="activity" element={<CommunicateWrapper />} />
      </Route>
      <Route path="mail" element={<MailBox isMailNotFiled={false} />}>
        <Route path="activity" element={<CommunicateWrapper />} />
      </Route>
      <Route path="expected-payments" element={<ExpectedPayments />}>
        <Route path="activity" element={<CommunicateWrapper />} />
      </Route>
      <Route path="follow-up" element={<FollowUp />}>
        <Route path="activity" element={<CommunicateWrapper />} />
      </Route>
      <Route path="reporting-group-logs" element={<ReportingGroupLogs />}>
        <Route path="activity" element={<CommunicateWrapper />} />
      </Route>
      <Route path="projected-cash-receipts" element={<ProjectedCashReceipts />}>
        <Route path="activity" element={<CommunicateWrapper />} />
      </Route>
      <Route path="contacts" element={<UserContacts />} />
      <Route path="activity-summary" element={<ActivitySummaryWrapper />} />
      <Route
        path="*"
        element={<Navigate replace to="/inquiries/by-user/activities" />}
      />
    </Routes>
  );
}
