import {gridModeTypes} from '../constants/common';
import data from '../lib/data';
import {isEmpty} from '../lib/utils';
import {downloadBlobFilePostApi} from './download';

export const fetchUserContacts = ({
  userKey,
  groupKey,
  settingKey,
  sortQuery,
  globalFilterQuery,
  sqlQuery,
  skip,
  pageSize,
}) => {
  let endpoint = `v4/api/accountoverview/for/contactsbyuser/${groupKey}?`;
  const sortJson =
    sortQuery?.length === 0
      ? null
      : {
          ViewSetting: {
            SortSetting: sortQuery.map((item, index) => {
              return {
                SortIndex: index + 1,
                SortCol: item.field,
                SortOrder: item.sort,
              };
            }),
          },
        };

  const payload = {
    groupKey,
    userKey,
    mode: gridModeTypes.Contacts,
    sortJson: sortJson ? JSON.stringify(sortJson) : null,
    settingKey,
    filterJson: globalFilterQuery ? JSON.stringify(globalFilterQuery) : null,
    filterSql: sqlQuery,
    source: 'react',
  };

  if (!isEmpty(skip)) {
    endpoint += `&%24skip=${skip}`;
  }

  if (!isEmpty(pageSize)) {
    endpoint += `&%24top=${pageSize}`;
  }

  return data.post(endpoint, payload);
};

export const exportUserContacts = ({
  userKey,
  groupKey,
  settingKey,
  sortQuery,
  globalFilterQuery,
  skip,
  pageSize,
  sqlQuery,
}) => {
  let endpoint = `v4/api/export/Contacts?`;
  const sortJson =
    sortQuery?.length === 0
      ? null
      : {
          ViewSetting: {
            SortSetting: sortQuery.map((item, index) => {
              return {
                SortIndex: index + 1,
                SortCol: item.field,
                SortOrder: item.sort,
              };
            }),
          },
        };

  const payload = {
    countAll: true,
    mode: gridModeTypes.Contacts,
    sortJson: sortJson ? JSON.stringify(sortJson) : null,
    settingKey,
    columSetting: '',
    userKey,
    groupKey,
    filterJson: globalFilterQuery ? JSON.stringify(globalFilterQuery) : null,
    filterSql: sqlQuery,
    source: 'react',
  };
  if (!isEmpty(skip)) {
    endpoint += `&%24skip=${skip}`;
  }

  if (!isEmpty(pageSize)) {
    endpoint += `&%24top=${pageSize}`;
  }

  return downloadBlobFilePostApi(endpoint, payload);
};
