import {constants, fileNames} from '../../constants/common';
import {
  exportExpectedPayments,
  fetchExpectedPayments,
} from '../../services/payment';
import {downloadExcel} from '../../utils/fileUtils';
import {actionTypes} from '../constants/actionTypes';

export const getExpectedPayments = options => (dispatch, getState) => {
  const toast = getState().generalReducer?.toastRef;
  const expectedPaymentsState = getState().expectedPaymentsReducer;
  const {
    userKey,
    assignedUserKey,
    groupKey,
    settingKey,
    sortQuery,
    filterQuery,
    sqlQuery,
    pageSize,
    skip,
    isBrokenPromise,
  } = options;
  const filterQueryVal =
    settingKey ===
    expectedPaymentsState?.expectedPaymentsViewSettings?.settingsKey
      ? filterQuery
      : '';

  dispatch({type: actionTypes.FETCHING_EXPECTED_PAYMENTS});
  fetchExpectedPayments({
    userKey,
    assignedUserKey,
    groupKey,
    isBrokenPromise,
    settingKey,
    sortQuery,
    globalFilterQuery: filterQueryVal,
    pageSize,
    skip,
    sqlQuery,
  })
    .then(res => {
      dispatch({
        type: actionTypes.FETCHED_EXPECTED_PAYMENTS,
        expectedPaymentsData: res.data.value,
        expectedPaymentsCount: res.data.count,
        expectedPaymentsNextLink: res.data.nextLink,
      });
    })
    .catch(() => {
      toast?.current.show({
        severity: 'warn',
        summary: constants.SOMETHING_WENT_WRONG,
        detail: '',
      });
      dispatch({type: actionTypes.FETCHING_EXPECTED_PAYMENTS_FAILED});
    });
};

export const updateExpectedPaymentsViewSettingObject = ({
  settingId,
  viewSettings,
}) => {
  return {
    type: actionTypes.SET_EXPECTED_PAYMENTS_VIEW_SETTING_OBJECT,
    payload: {settingId, viewSettings},
  };
};

export const expectedPaymentsExportToExcel =
  ({
    userKey,
    groupKey,
    modeType,
    settingKey,
    sortQuery,
    filterQuery,
    sqlQuery,
    isBrokenPromise,
    assignedUserKey,
  }) =>
  (dispatch, getState) => {
    const toast = getState().generalReducer?.toastRef;

    dispatch({type: actionTypes.EXPORTING_EXPECTED_PAYMENTS_TO_EXCEL});
    exportExpectedPayments({
      userKey,
      groupKey,
      settingKey,
      sortQuery,
      globalFilterQuery: filterQuery,
      sqlQuery,
      isBrokenPromise,
      assignedUserKey,
    })
      .then(res => {
        downloadExcel(
          res.data,
          fileNames[modeType] ?? 'ExportFile',
          toast,
          constants.EXPECTED_PAYMENTS_EXPORTED_TO_EXCEL,
        );
        dispatch({type: actionTypes.EXPORTED_EXPECTED_PAYMENTS_TO_EXCEL});
      })
      .catch(() => {
        dispatch({
          type: actionTypes.EXPORTING_EXPECTED_PAYMENTS_TO_EXCEL_FAILED,
        });
      });
  };

export const updateIsBrokenPromise = isBrokenPromise => {
  return {
    type: actionTypes.SET_IS_BROKEN_PROMISE,
    isBrokenPromise,
  };
};

export const updateExpectedPaymentsAlertDescription = alertDescription => {
  return {
    type: actionTypes.SET_EXPECTED_PAYMENTS_ALERT_DESCRIPTION,
    alertDescription,
  };
};

export const cleanupExpectedPayments = () => {
  return {
    type: actionTypes.CLEANUP_EXPECTED_PAYMENTS,
  };
};
