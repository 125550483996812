import dayjs from 'dayjs';
import {filterOperators} from '../../../../../lib/commonTableHelpers';
import {CustomerMenu} from '../../../../common/CommonRowComponents';
import {constants} from '../../../../../constants/common';
import {InvoiceLink} from '../../../../common/RowComponents';
import {Checkbox} from '../../../../../ui/inputs';

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

export const detailsColumns = [
  {
    field: 'custName',
    headerName: 'Cust Name',
    sortable: true,
    renderCell: cellValues => {
      return <CustomerMenu values={cellValues} />;
    },
    minWidth: 180,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'custId',
    headerName: 'Cust ID',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'companyId',
    headerName: 'Company',
    sortable: true,
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'entryUserId',
    headerName: 'Entry User ID',
    sortable: true,
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'entryUserName',
    headerName: 'Entry User Name',
    sortable: true,
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
  },

  {
    field: 'pendPmtId',
    headerName: 'Online Pmt ID',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'paymentAmt',
    headerName: 'Payment Amount',
    sortable: true,
    minWidth: 120,
    type: 'number',
    renderCell: ({row}) => (
      <span>
        {Number(row.paymentAmt || 0).toLocaleString('en-US', {
          maximumFractionDigits: 2,
        })}
      </span>
    ),
    filterOperators: filterOperators.number,
  },
  {
    field: 'currId',
    headerName: 'Curr ID',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'exportDate',
    headerName: 'Export Date',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_TIME_FORMAT)
        : '',
    minWidth: 150,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'createDate',
    headerName: 'Create Date',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_TIME_FORMAT)
        : '',
    minWidth: 150,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'reconcileDate',
    headerName: 'Reconcile Date',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 150,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'statusDesc',
    headerName: 'Status',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'note',
    headerName: 'Notes',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'procRefNo',
    headerName: 'Proc Ref No',
    sortable: true,
    minWidth: 150,
    renderHeaderFilter: () => null,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'procStatus',
    headerName: 'Proc Status',
    sortable: true,
    minWidth: 150,
    renderHeaderFilter: () => null,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'procStatusMsg',
    headerName: 'Proc Status Msg',
    sortable: true,
    minWidth: 150,
    maxWidth: 400,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'procAcctName',
    headerName: 'Proc Acct Name',
    sortable: true,
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'procAcctType',
    headerName: 'Proc Acct Type',
    sortable: true,
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'procAcctId',
    headerName: 'Proc Acct ID',
    sortable: true,
    minWidth: 150,
    renderHeaderFilter: () => null,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'userId',
    headerName: 'User ID',
    sortable: true,
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'assignedUserId',
    headerName: 'Assigned User ID',
    sortable: true,
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'createDateDays',
    headerName: 'Create Date Days',
    sortable: true,
    minWidth: 120,
    renderHeaderFilter: () => null,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'createDateUTC',
    headerName: 'Create Date UTC',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 150,
    renderHeaderFilter: () => null,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'exportDateUTC',
    headerName: 'Export Date UTC',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 150,
    renderHeaderFilter: () => null,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'reconcileDateUTC',
    headerName: 'Reconcile Date UTC',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 150,
    renderHeaderFilter: () => null,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'invoiceNo',
    headerName: 'Invoice No',
    sortable: true,
    renderCell: cellValues => {
      const cellValuesTemp = cellValues;
      cellValuesTemp.row.tranId = cellValues.row.invoiceNo;
      cellValuesTemp.row.tranType = 'IN';
      cellValuesTemp.row.invcKey = cellValues.row.invoiceKey;
      return <InvoiceLink values={cellValuesTemp} />;
    },
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'invoiceBalance',
    headerName: 'Balance',
    sortable: true,
    renderCell: ({row}) => (
      <span>
        {Number(row.invoiceBalance || 0).toLocaleString('en-US', {
          maximumFractionDigits: 2,
        })}
      </span>
    ),
    minWidth: 60,
    renderHeaderFilter: () => null,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'invoiceApplAmt',
    headerName: 'Invoice Appl Amt',
    sortable: true,
    minWidth: 60,
    renderCell: ({row}) => (
      <span>
        {Number(row.invoiceApplAmt || 0).toLocaleString('en-US', {
          maximumFractionDigits: 2,
        })}
      </span>
    ),
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'invoiceDate',
    headerName: 'Invoice Date',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_TIME_FORMAT)
        : '',
    minWidth: 150,
    renderHeaderFilter: () => null,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'invoiceAmt',
    headerName: 'Invoice Amt',
    sortable: true,
    renderCell: ({row}) => (
      <span>
        {Number(row.invoiceAmt || 0).toLocaleString('en-US', {
          maximumFractionDigits: 2,
        })}
      </span>
    ),
    minWidth: 60,
    renderHeaderFilter: () => null,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'custPONo',
    headerName: 'Cust PO No',
    sortable: true,
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'parentId',
    headerName: 'Parent ID',
    sortable: true,
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'consolidatedInvcNo',
    headerName: 'Consolidated Invc No',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'subBilling',
    headerName: 'Subscripiton',
    sortable: true,
    minWidth: 120,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'subNotes',
    headerName: 'Subscription Notes',
    sortable: true,
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
    renderHeaderFilter: () => null,
  },
  {
    field: 'invcUDF1',
    headerName: 'InvcUDF1',
    sortable: true,
    minWidth: 80,
    renderHeaderFilter: () => null,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'invcUDF2',
    headerName: 'InvcUDF2',
    sortable: true,
    minWidth: 80,
    renderHeaderFilter: () => null,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'invcUDF3',
    headerName: 'InvcUDF3',
    sortable: true,
    minWidth: 80,
    renderHeaderFilter: () => null,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'invcUDF4',
    headerName: 'InvcUDF4',
    sortable: true,
    minWidth: 80,
    renderHeaderFilter: () => null,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'invcUDF5',
    headerName: 'InvcUDF5',
    sortable: true,
    minWidth: 80,
    renderHeaderFilter: () => null,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'invcUDF6',
    headerName: 'InvcUDF6',
    sortable: true,
    minWidth: 80,
    renderHeaderFilter: () => null,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'invcUDF7',
    headerName: 'InvcUDF7',
    sortable: true,
    minWidth: 80,
    renderHeaderFilter: () => null,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'invcUDF8',
    headerName: 'InvcUDF8',
    sortable: true,
    minWidth: 80,
    renderHeaderFilter: () => null,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'invcUDF9',
    headerName: 'InvcUDF9',
    sortable: true,
    minWidth: 80,
    renderHeaderFilter: () => null,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'invcUDF10',
    headerName: 'InvcUDF10',
    sortable: true,
    minWidth: 80,
    renderHeaderFilter: () => null,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'amount01',
    headerName: 'Amount01',
    sortable: true,
    valueFormatter: params =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 3,
      }),
    minWidth: 80,
    renderHeaderFilter: () => null,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'amount02',
    headerName: 'Amount02',
    sortable: true,
    valueFormatter: params =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 3,
      }),
    minWidth: 80,
    renderHeaderFilter: () => null,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'amount03',
    headerName: 'Amount03',
    sortable: true,
    valueFormatter: params =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 3,
      }),
    minWidth: 80,
    renderHeaderFilter: () => null,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'amount04',
    headerName: 'Amount04',
    sortable: true,
    valueFormatter: params =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 3,
      }),
    minWidth: 80,
    renderHeaderFilter: () => null,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'amount05',
    headerName: 'Amount05',
    sortable: true,
    valueFormatter: params =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 3,
      }),
    minWidth: 80,
    renderHeaderFilter: () => null,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'amount06',
    headerName: 'Amount06',
    sortable: true,
    valueFormatter: params =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 3,
      }),
    minWidth: 80,
    renderHeaderFilter: () => null,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'amount07',
    headerName: 'Amount07',
    sortable: true,
    valueFormatter: params =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 3,
      }),
    minWidth: 80,
    renderHeaderFilter: () => null,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'amount08',
    headerName: 'Amount08',
    sortable: true,
    valueFormatter: params =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 3,
      }),
    minWidth: 80,
    renderHeaderFilter: () => null,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'amount09',
    headerName: 'Amount09',
    sortable: true,
    valueFormatter: params =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 3,
      }),
    minWidth: 80,
    renderHeaderFilter: () => null,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'amount10',
    headerName: 'Amount10',
    sortable: true,
    valueFormatter: params =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 3,
      }),
    minWidth: 80,
    renderHeaderFilter: () => null,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'date01',
    headerName: 'Date01',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 120,
    renderHeaderFilter: () => null,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'date02',
    headerName: 'Date02',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 120,
    renderHeaderFilter: () => null,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'date03',
    headerName: 'Date03',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 120,
    renderHeaderFilter: () => null,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'date04',
    headerName: 'Date04',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 120,
    renderHeaderFilter: () => null,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'date05',
    headerName: 'Date05',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 120,
    renderHeaderFilter: () => null,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'date06',
    headerName: 'Date06',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 120,
    renderHeaderFilter: () => null,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'date07',
    headerName: 'Date07',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 120,
    renderHeaderFilter: () => null,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'date08',
    headerName: 'Date08',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 120,
    renderHeaderFilter: () => null,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'date09',
    headerName: 'Date09',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 120,
    renderHeaderFilter: () => null,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'date10',
    headerName: 'Date10',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 120,
    renderHeaderFilter: () => null,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'webhookStatusDesc',
    headerName: 'WebhookStatus',
    sortable: true,
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'webhookMessage',
    headerName: 'WebhookMessage',
    sortable: true,
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'webhookSentDate',
    headerName: 'WebhookSentDate',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 150,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'totalSurchargeAmount',
    headerName: 'Total Surcharge Amount',
    sortable: true,
    renderCell: ({row}) => (
      <span>
        {Number(row.totalSurchargeAmount || 0).toLocaleString('en-US', {
          maximumFractionDigits: 2,
        })}
      </span>
    ),
    minWidth: 120,
    renderHeaderFilter: () => null,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'isSchpmt',
    headerName: 'Is Schedule Payment',
    sortable: true,
    renderCell: cellValues => {
      return <Checkbox disabled checked={cellValues.row.isSchpmt} />;
    },
    minWidth: 80,
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
];
