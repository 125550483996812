import {useEffect, useState} from 'react';
import dayjs from 'dayjs';
import {FieldChangeHandlerContext} from '@mui/x-date-pickers/internals';
import {TimeRangeValidationError} from '@mui/x-date-pickers-pro';
import {useSelector} from 'react-redux';
import {constants} from '../../../../constants/common';
import {
  Button,
  CheckboxLabel,
  DateTimePicker,
  MultiInputTimeRangeField,
  Select,
  TextField,
} from '../../../../ui/inputs';
import {Stack, Box} from '../../../../ui/layouts';
import {getTimeZoneList} from '../../../../services/application';
import {Typography} from '../../../../ui/displays';
import {
  deleteSchedulePayments,
  getAutoSchedulePayments,
  saveSchedulePayments,
} from '../../../../services/schedulePayments';
import {getUserData} from '../../../../store/actions/userActions';
import Backdrop from '../../../../ui/utils/backdrop/Backdrop';
import {
  convertDateTimeToString,
  convertDateTimeToTimeString,
  convertStringToTime,
  getMonthlyDropDown,
  hourlyDropDown,
  runDropDown,
  runDropDownValues,
} from './helper';
import {IScheduleTask, RunTimeValue} from './schedulePaymentInterface';

const monthlyDropDown = getMonthlyDropDown();

export const ScheduleTasks = ({onCloseDialog}: {onCloseDialog: () => void}) => {
  const {groupKey} = getUserData();
  const [scheduleTask, setScheduleTask] = useState<IScheduleTask>({
    scheduleKey: null,
    entity: null,
    databaseName: null,
    lastRunMessage: null,
    entityKey: groupKey,
    scheduleDescription: '',
    timeZone: '',
    nextRun: '',
    frequency: 1,
    frequencyType: 0,
    startTime: '00:00:00',
    endTime: '23:59:00',
    lastRun: '',
    enabled: false,
  });
  const initialErrors = {timeZone: false, startDate: false};
  const [scheduleTaskErrors, setScheduleTaskErrors] = useState(initialErrors);
  const [timeRangeError, setTimeRangeError] =
    useState<FieldChangeHandlerContext<TimeRangeValidationError>>();
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [timeZoneDropdown, setTimeZoneDropDown] = useState<
    {label: string; value: string}[]
  >([]);

  const toast = useSelector((store: any) => store.generalReducer?.toastRef);

  const getTimeZones = async () => {
    const timeZoneResponse = await getTimeZoneList();
    return timeZoneResponse.data?.map((item: {DisplayName: any; Id: any}) => {
      return {label: item.DisplayName, value: item.Id};
    });
  };
  const getScheduleTask = async () => {
    const res = await getAutoSchedulePayments(groupKey);
    return res.data;
  };
  const loadInitData = async () => {
    try {
      setIsLoadingData(true);
      const [timeZones, scheduleTaskInfo] = await Promise.all([
        getTimeZones(),
        getScheduleTask(),
      ]);

      setTimeZoneDropDown(timeZones);
      if (scheduleTaskInfo) {
        setScheduleTask(scheduleTaskInfo);
      }

      setIsLoadingData(false);
    } catch (error) {
      setIsLoadingData(false);
    }
  };
  useEffect(() => {
    loadInitData();
  }, []);

  const handleOnScheduleTaskChange = (
    field: keyof IScheduleTask,
    value: string | boolean | number,
  ) => {
    setScheduleTask({...scheduleTask, [field]: value});
  };

  const handleOnFrequencyTypeChange = (value: RunTimeValue) => {
    setScheduleTask({
      ...scheduleTask,
      frequencyType: value,
      runMon: value === 1,
      runTue: value === 1,
      runWed: value === 1,
      runThu: value === 1,
      runFri: value === 1,
      enabled: value === 1,
    });
  };

  const onSave = async () => {
    const hasError =
      !scheduleTask.timeZone ||
      !scheduleTask.nextRun ||
      Boolean(timeRangeError?.validationError[0]) ||
      Boolean(timeRangeError?.validationError[1]);

    setScheduleTaskErrors({
      timeZone: !scheduleTask.timeZone,
      startDate: !scheduleTask.nextRun,
    });

    if (!hasError) {
      setScheduleTaskErrors(initialErrors);
      // Make API call to save or update
      try {
        setIsLoadingData(true);
        await saveSchedulePayments(groupKey, scheduleTask);
        setIsLoadingData(false);
        onCloseDialog();
      } catch (error) {
        setIsLoadingData(false);
        toast?.current.show({
          severity: 'error',
          summary: constants.SOMETHING_WENT_WRONG,
          detail: '',
        });
      }
    }
  };

  const onDelete = async () => {
    try {
      setIsLoadingData(true);
      await deleteSchedulePayments(groupKey);
      onCloseDialog();
    } catch (error) {
      setIsLoadingData(false);
    }
  };

  return (
    <Stack spacing={{xs: 1}} direction="column" useFlexGap flexWrap="wrap">
      <Backdrop isLoading={isLoadingData} open={isLoadingData} />
      <TextField
        label={constants.SCHEDULE_DESCRIPTION}
        margin="dense"
        value={scheduleTask.scheduleDescription}
        onChange={e =>
          handleOnScheduleTaskChange('scheduleDescription', e.target.value)
        }
      />
      <Select
        label={constants.TIME_ZONE}
        items={timeZoneDropdown}
        margin="dense"
        value={scheduleTask.timeZone}
        onChange={e =>
          handleOnScheduleTaskChange('timeZone', e.target.value as string)
        }
        error={scheduleTaskErrors.timeZone}
      />
      <DateTimePicker
        label={scheduleTask.id ? constants.NEXT_RUN_DATE : constants.START_DATE}
        orientation="landscape"
        value={
          scheduleTask.nextRun
            ? dayjs.utc(new Date(scheduleTask.nextRun))
            : undefined
        }
        onChange={e => {
          if (e) {
            const dateTimeString = convertDateTimeToString(e);
            handleOnScheduleTaskChange('nextRun', dateTimeString);
          }
        }}
        slotProps={{
          textField: {
            size: 'small',
            error: scheduleTaskErrors.startDate,
          },
        }}
        margin="dense"
      />
      <Box display="flex" gap={1}>
        <Box display="flex" gap={1} sx={{width: '450px'}}>
          <div className="w-100-px">
            <Select
              label={constants.RUN}
              items={runDropDown}
              value={scheduleTask.frequencyType}
              onChange={e => {
                handleOnFrequencyTypeChange(e.target.value as RunTimeValue);
              }}
            />
          </div>
          {(scheduleTask.frequencyType === runDropDownValues.HOURLY ||
            scheduleTask.frequencyType === runDropDownValues.MONTHLY) && (
            <div className="w-150-px">
              <Select
                label=""
                value={scheduleTask.frequency}
                onChange={e =>
                  handleOnScheduleTaskChange(
                    'frequency',
                    e.target.value as number,
                  )
                }
                items={
                  scheduleTask.frequencyType === runDropDownValues.MONTHLY
                    ? monthlyDropDown
                    : hourlyDropDown
                }
              />
            </div>
          )}
        </Box>
        {scheduleTask.frequencyType === runDropDownValues.HOURLY && (
          <MultiInputTimeRangeField
            startLabel="Between"
            endLabel="And"
            margin="normal"
            value={[
              convertStringToTime(scheduleTask.startTime),
              convertStringToTime(scheduleTask.endTime),
            ]}
            onChange={(time, error) => {
              const newTime = convertDateTimeToTimeString(time);
              setScheduleTask({
                ...scheduleTask,
                startTime: newTime.startTime,
                endTime: newTime.endTime,
              });
              setTimeRangeError(error);
            }}
          />
        )}
      </Box>
      {scheduleTask.frequencyType === runDropDownValues.DAILY && (
        <>
          <Typography variant="subtitle1">On These Days</Typography>
          <Box>
            <CheckboxLabel
              checked={scheduleTask.runMon}
              label={constants.MONDAY}
              onChange={e =>
                handleOnScheduleTaskChange('runMon', e.target.checked)
              }
            />
            <CheckboxLabel
              checked={scheduleTask.runTue}
              label={constants.TUESDAY}
              onChange={e =>
                handleOnScheduleTaskChange('runTue', e.target.checked)
              }
            />
            <CheckboxLabel
              checked={scheduleTask.runWed}
              label={constants.WEDNESDAY}
              onChange={e =>
                handleOnScheduleTaskChange('runWed', e.target.checked)
              }
            />
            <CheckboxLabel
              checked={scheduleTask.runThu}
              label={constants.THURSDAY}
              onChange={e =>
                handleOnScheduleTaskChange('runThu', e.target.checked)
              }
            />
            <CheckboxLabel
              checked={scheduleTask.runFri}
              label={constants.FRIDAY}
              onChange={e =>
                handleOnScheduleTaskChange('runFri', e.target.checked)
              }
            />
            <CheckboxLabel
              checked={scheduleTask.runSat}
              label={constants.SATURDAY}
              onChange={e =>
                handleOnScheduleTaskChange('runSat', e.target.checked)
              }
            />
            <CheckboxLabel
              checked={scheduleTask.runSun}
              label={constants.SUNDAY}
              onChange={e =>
                handleOnScheduleTaskChange('runSun', e.target.checked)
              }
            />
          </Box>
        </>
      )}
      {scheduleTask.id && (
        <TextField
          label={constants.LAST_RUN_DATE}
          margin="dense"
          disabled
          InputLabelProps={{
            shrink: true,
          }}
          value={
            scheduleTask.lastRun
              ? dayjs
                  .utc(scheduleTask.lastRun)
                  .local()
                  .format('M/D/YYYY h:mm:ss A')
              : undefined
          }
        />
      )}
      {(scheduleTask.frequencyType === runDropDownValues.DAILY ||
        scheduleTask.id) && (
        <CheckboxLabel
          checked={scheduleTask.enabled}
          label={constants.ENABLED}
          onChange={e =>
            handleOnScheduleTaskChange('enabled', e.target.checked)
          }
        />
      )}

      <Box display="flex" gap={1}>
        <Button variant="contained" onClick={onSave}>
          {constants.SAVE}
        </Button>
        <Button variant="contained" onClick={() => onCloseDialog()}>
          {constants.CANCEL}
        </Button>
        {scheduleTask.id && (
          <Button variant="contained" onClick={onDelete}>
            {constants.DELETE}
          </Button>
        )}
      </Box>
    </Stack>
  );
};
