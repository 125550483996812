import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  useSearchParams,
  useLocation,
  Outlet,
  useNavigate,
} from 'react-router-dom';
import moment from 'moment';
import {
  updateTableFilterDisplayType,
  updateSelectedCustomer,
  updateSideNavRefresh,
} from '../../store/actions/generalActions';
import '../css/account-overview.css';
import {fetchAccountOverviewSummary} from '../../services/communications';
import {getUserData} from '../../store/actions/userActions';
import {constants} from '../../constants/common';
import {isEmpty} from '../../lib/utils';
import Backdrop from '../common/Backdrop';
import {MODE_TYPES} from '../../utils/constants';
import {OpenDocs} from '../communicate/open-docs/OpenDocs';
import {StatementOfAccount} from './statement-of-account/StatementOfAccount';
import {ActivitiesOverview} from './activities-overview/ActivitiesOverview';
import {AccountNotes} from './account-notes/AccountNotes';
import {useQueryKeys} from '../../hooks/useQueryKeys';
import {fetchSelectedCustomerInfo} from '../../services/customerInfo';
import {useLocalStorage} from '../../hooks/useLocalStorage';
import {fetchCompanySettings} from '../../services/payment';
import {Box} from '../../ui/layouts';
import {Typography} from '../../ui/displays';

interface IOpenDocProp {
  invoiceType: boolean;
  selectedInvoice: any;
}
interface ISummaryObject {
  dateEstab: string;
  oldestTranDate: string;
  InsertDate: string;
}
interface ICompanySettings {
  onlineCMApplOption: number;
}

function AccountOverview() {
  const dispatch = useDispatch<any>();
  const toast = useSelector((store: any) => store.generalReducer?.toastRef);
  const [searchParams] = useSearchParams();
  const [NewCommunicate] = useState(searchParams.get('NewCommunicate'));
  const selectedCustomer = useSelector(
    (store: any) => store.generalReducer.selectedCustomer,
  );
  const {userKey, custKey: customerKey} = useQueryKeys();

  const custKey: number = customerKey ?? selectedCustomer?.custKey;

  const [showCustomerDocs, setShowCustomerDocs] = useState(false);
  const [openDocsProp] = useState<IOpenDocProp>();
  const [activityRefresh, setActivityRefresh] = useState(false);
  const [localStorageValue, setLocalStorage] =
    useLocalStorage('recentAccounts');
  const location = useLocation();
  const [state, setState] = useState(location.state);
  const {search} = location;
  const navigate = useNavigate();
  const {custName, custId, taskActKey, selectCompanyId} = state || {};
  const [accountSummary, setAccountSummary] = useState([]);
  const {groupKey} = getUserData();
  const [stateCopy, setStateCopy] = useState(state);
  const [companySettings, setCompanySettings] = useState<ICompanySettings>({
    onlineCMApplOption: 0,
  });
  const [showToolBars, setShowToolBars] = useState<boolean>(true);
  const loadCompanySettings = async ({companyId}: {companyId: string}) => {
    if (!isEmpty(companyId)) {
      try {
        const res = await fetchCompanySettings({
          companyId,
          groupKey,
        });
        setCompanySettings(res.data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const calculateYearDifference = (summaryObject: ISummaryObject) => {
    let tempYears = 0;
    if (!isEmpty(summaryObject?.dateEstab)) {
      tempYears = moment()
        .startOf('year')
        .diff(moment(summaryObject?.dateEstab).startOf('year'), 'years', false);
    } else if (!isEmpty(summaryObject?.oldestTranDate)) {
      tempYears = moment()
        .startOf('year')
        .diff(
          moment(summaryObject?.oldestTranDate).startOf('year'),
          'years',
          false,
        );
    } else if (!isEmpty(summaryObject?.InsertDate)) {
      tempYears = moment()
        .startOf('year')
        .diff(
          moment(summaryObject?.InsertDate, 'years').startOf('year'),
          'years',
          false,
        );
    }
    return tempYears;
  };

  const handleSummaryData = async () => {
    const res = await fetchAccountOverviewSummary(groupKey, custKey);
    if (res.data) {
      const summaryObject = res.data;
      const years = calculateYearDifference(summaryObject);
      const yearLabel = years === 1 ? constants.YEAR : constants.YEARS;
      let avgDaystext = '';
      let tooltipText = '';
      if (summaryObject.avgDaysLateTotal > 0) {
        avgDaystext = `${summaryObject.avgDaysLateTotal} ${constants.DAYS_LATE}`;
      } else if (summaryObject.avgDaysLateTotal < 0) {
        avgDaystext = `${Math.abs(summaryObject.avgDaysLateTotal)} ${
          constants.DAYS_EARLY
        }`;
      } else {
        avgDaystext = constants.ON_TIME;
      }
      tooltipText = constants.usuallyPaysTooltipText(
        years,
        yearLabel,
        avgDaystext,
        summaryObject.avgDaysLateWeighted,
      );
      res.data.avgDaystext = avgDaystext;
      res.data.tooltipText = tooltipText;
      setAccountSummary(res.data);
    }
  };

  const showNewActivityNewIssue = (selectedInvoice?: any) => {
    const props = {
      taskActKey,
      custKey,
      groupKey,
      custName,
      taskKey: -1,
      newActivity: true,
      ModeType: MODE_TYPES.new,
      selectedInvoice,
      custId: stateCopy.custId,
      companyId: stateCopy.companyId,
      selectCompanyId: stateCopy.selectCompanyId,
    };
    const params = new URLSearchParams(search);
    params.set('activityId', '-1');

    navigate(`activity?${params.toString()}`, {
      state: {...props, from: location},
    });
  };

  const showNewActivityExistingIssue = (
    rowData: any,
    hideSaveAndNext = false,
  ) => {
    const communicateProps = {
      ...rowData,
      newActivity: true,
      ModeType: MODE_TYPES.activityOnEnquiry,
      tabName: 'email',
      custId: stateCopy.custId,
      companyId: stateCopy.companyId,
      selectCompanyId,
      hideSaveAndNext,
    };
    const params = new URLSearchParams(search);
    params.set('activityId', communicateProps.id);

    navigate(`activity?${params.toString()}`, {
      state: {...communicateProps, from: location},
    });
  };

  const addRecentAccountsToLocalStorage = ({data}: any) => {
    const recentAccounts = localStorageValue || [];
    const currentAccount = {
      custName: data.custName,
      custKey,
    };
    const isDuplicate = recentAccounts.some(
      (recentAcc: {custKey: number}) =>
        `${currentAccount.custKey}` === `${recentAcc.custKey}`,
    );
    if (!isDuplicate) {
      recentAccounts.push(currentAccount);
      setLocalStorage(recentAccounts);
      dispatch(updateSideNavRefresh());
    }
  };

  useEffect(() => {
    dispatch(updateTableFilterDisplayType('menu'));
    if (custKey > 0) {
      fetchSelectedCustomerInfo(custKey)
        .then(result => {
          if (result.data && result.data.custName) {
            addRecentAccountsToLocalStorage(result);
            setState(result.data);
            setStateCopy({
              ...result.data,
              companyId: result.data.companyID,
              displayCompanyID: result.data.displayCompanyID,
              custId: result.data.custID,
              selectCompanyId: result.data.displayCompanyID,
            });
            loadCompanySettings({companyId: result.data.companyID});
            dispatch(updateSelectedCustomer(result.data));
          }
        })
        .catch(() => {
          toast?.current.show({
            severity: 'warn',
            summary: constants.SOMETHING_WENT_WRONG,
            detail: '',
          });
        });
    }

    if (NewCommunicate) {
      if (state?.ModeType === 0 || state === null) {
        showNewActivityNewIssue();
      } else {
        showNewActivityExistingIssue({...state});
      }
    }
    handleSummaryData();
    sessionStorage.removeItem('customFilterQueryKey');
    // we can ignore this es-lint warning as we need only cust-key in dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [custKey]);

  const onActivityReload = () => setActivityRefresh(true);

  const handleShowToolbars = (value: boolean) => {
    setShowToolBars(value);
  };

  return (
    <>
      {showCustomerDocs && (
        <OpenDocs
          showCustomerDocs={showCustomerDocs}
          setShowCustomerDocs={setShowCustomerDocs}
          groupKey={groupKey}
          custName={custName}
          custKey={custKey}
          isInvoice={openDocsProp?.invoiceType}
          selectedInvoice={openDocsProp?.selectedInvoice}
          workSessionID={null}
          custId={custId}
        />
      )}

      {isEmpty(accountSummary) && <Backdrop />}
      {stateCopy?.custId && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          borderBottom="1px solid #dbe0e3"
          paddingY={1}
        >
          <Typography
            variant="h6"
            fontSize={24}
            fontWeight={500}
            style={{color: '#5B6E6B'}}
            textTransform="uppercase"
          >
            {`${stateCopy?.custId}:`} {stateCopy?.custName}
          </Typography>
          <Typography
            variant="h6"
            fontSize={24}
            fontWeight={500}
            style={{color: '#5B6E6B'}}
            textTransform="uppercase"
          >
            {`${constants.COMPANY} ${stateCopy?.displayCompanyID}`}
          </Typography>
        </Box>
      )}
      {/* <hr /> */}
      <Outlet context={{onReload: onActivityReload}} />
      <StatementOfAccount
        accountSummary={accountSummary}
        showNewActivityNewIssue={showNewActivityNewIssue}
        allowOnlineCMApplication={companySettings.onlineCMApplOption}
        handleShowToolbars={handleShowToolbars}
        showToolBars={showToolBars}
      />
      <ActivitiesOverview
        refresh={activityRefresh}
        setRefresh={setActivityRefresh}
        showToolBars={showToolBars}
      />
      <AccountNotes
        custKey={custKey}
        userKey={userKey}
        custName={custName}
        accountOverview
      />
    </>
  );
}
export default AccountOverview;
