import dayjs from 'dayjs';
import {constants} from '../../../constants/common';
import {Button} from '../../../ui/inputs';
import {filterOperators} from '../../../lib/commonTableHelpers';

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

export const accountNotesColumns = handleDeleteAccountNotes => {
  return [
    {
      field: 'entryDate',
      headerName: 'Entry Date',
      minWidth: 250,
      sortable: true,
      disableColumnMenu: true,
      valueFormatter: params =>
        params.value
          ? dayjs(params?.value).utc().format(constants.DATE_TIME_FORMAT)
          : '',
      type: 'date',
      filterOperators: filterOperators.date,
    },
    {
      field: 'notes',
      headerName: 'Notes',
      sortable: true,
      flex: 1,
      disableColumnMenu: true,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      disableColumnMenu: true,
      renderCell: cellValues => {
        const {row} = cellValues;
        return (
          <Button onClick={() => handleDeleteAccountNotes(row)}>
            {constants.DELETE}
          </Button>
        );
      },
    },
  ];
};
