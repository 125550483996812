import data from '../lib/data';

export const getSideBarMenu = payload => {
  const endpoint = `v2/api/sidenav/sidenavbar/${payload.GroupKey}/${payload.UserId}`;
  return data.post(endpoint, payload);
};

export const updateSessionTime = ({sessionId, windowName, userId}) => {
  const endpoint = `v4/api/global/${sessionId}/${windowName}/${userId}`;
  return data.put(endpoint);
};

export const getMessagesCount = userId => {
  const endpoint = `v2/api/sidenav/for/${userId}/messagescount`;
  return data.get(endpoint);
};

export const getNewNotificationDetails = userId => {
  const endpoint = `v4/api/notification/for/${userId}`;
  return data.get(endpoint);
};

export const updateNotificationAsRead = userId => {
  const endpoint = `v4/api/notification/for/${userId}`;
  return data.put(endpoint);
};

export const logMessage = payload => {
  const endpoint = '/v4/api/error/log';
  return data.post(endpoint, payload);
};

export const getTimeZoneList = () => {
  const endpoint = `v4/api/global/timezones`;
  return data.get(endpoint);
};

export const getZenDeskToken = payload => {
  const endpoint = `v1/api/user/Zendesk/info`;
  return data.post(endpoint, payload);
};
