export const DEFAULT_ACTIVITY_INFO = {
  customerTask: {
    workSessionID: '',
    entryDate: '',
    automatic: 0,
    completionDate: '',
    description: '',
    status: 0,
    assignedUserKey: '',
    memoText: '',
    isAccountNote: false,
    startDate: '',
    endDate: '',
    erpNotesKey: '',
    isArchived: false,
    id: '',
    tableName: '',
    idName: '',
  },
  customerTaskActivity: {
    workSessionID: '',
    taskKey: '',
    userKey: 0,
    dateEntered: '',
    notes: '',
    toDo: 0,
    toDoDate: '',
    toDoDesc: '',
    toDoDuration: 0,
    toDoComplete: 0,
    toDoCompleteDate: '',
    actualMinutes: 0,
    activityKey: 0,
    mailKey: '',
    notScheduled: 0,
    ruleKey: '',
    collectionsCodeKey: '',
    createUserKey: '',
    successful: '',
    daysToRespond: '',
    followUpRespondBy: '',
    closeStatus: '',
    taskActKey: '',
    bulkWorkSessionID: '',
    id: '',
    tableName: '',
    idName: '',
  },
  mail: {
    workSessionID: '',
    messageSequenceNo: 0,
    messageID: '',
    repyToID: '',
    fromAddress: '',
    sentDate: '',
    toAddress: '',
    ccAddress: '',
    bccAddress: '',
    status: '',
    bodyText: '',
    bodyHTML: '',
    subject: '',
    attachment: '',
    groupKey: '',
    mailSettingsKey: '',
    voiceMessageTo: '',
    voiceMessageBody: '',
    voiceMessageStatus: '',
    smsTo: '',
    smsSubject: '',
    smsBody: '',
    smsStatus: '',
    id: 0,
    tableName: '',
    idName: '',
  },
  expectedPayment: {
    workSessionID: '',
    entryDate: '',
    paymentAmount: 0,
    payByDate: '',
    actualPaymentAmount: 0,
    actualPaymentDate: '',
    promised: 0,
    broken: 0,
    id: '',
    tableName: '',
    idName: '',
  },
  activity: {
    activityKey: 0,
    activityID: '',
    description: '',
    systemActivity: 0,
    buttonText: '',
    smallIcon: '',
    largeIcon: '',
    activityType: 0,
    groupKey: '',
    procNotes: '',
    excludeFromDashboard: false,
    id: 0,
    tableName: '',
    idName: '',
  },
  taggedAmount: 0,
  isEmailEnabled: false,
  isTextMessageEnabled: false,
  isAttachmentsEnabled: false,
  isPaymentEnabled: false,
  closePriorActivity: false,
  attachments: [],
  canReassign: false,
};
