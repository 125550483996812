import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {ActivitySummary} from './ActivitiySummary';
import Communication from '../../../messages/communication/Communication';
import {FollowUp} from '../../../messages/follow-up/FollowUp';
import MailBox from '../../../messages/mail-box/MailBox';
import {ExpectedPayments} from '../ExpectedPayments/ExpectedPayments';
import {getQueryParameters} from '../../../../lib/utils';
import {PendingPayments} from '../pending-payments/PendingPayments';

const todayDate = new Date().toISOString().split('T')[0];

export const ActivitySummaryWrapper = () => {
  const location = useLocation();
  const [alertCodeText, setAlertCodeText] = useState<string>('');
  const [isAlert, setIsAlert] = useState<boolean>(false);

  useEffect(() => {
    if (location.search && location.search !== '') {
      const searchParams = new URLSearchParams(location.search);
      const uniqueParams = new URLSearchParams();

      searchParams.forEach((value, key) => {
        if (!uniqueParams.has(key)) {
          uniqueParams.append(key, value);
        }
      });
      const queryString = uniqueParams.toString();
      const queryParams = getQueryParameters(queryString.replace(/^#\/?/, ''));

      if (queryParams.AlertCode) {
        setIsAlert(true);
        setAlertCodeText(queryParams.AlertCode as string);
      }
    } else {
      setIsAlert(false);
    }
  }, [location.search]);

  const getAlertCodeView = (alertCode: string) => {
    switch (alertCode) {
      case 'PastDueFollowupAlert':
        return <FollowUp maxDate={todayDate} />;
      case 'FollowupDueAlert':
        return <FollowUp maxDate={todayDate} minDate={todayDate} />;
      case 'UnfiledMailAlert':
        return <MailBox isMailNotFiled />;
      case 'BrokenPromise':
        return <ExpectedPayments />;
      case 'PendPmtNotReconciled':
      case 'PendPmtDeclined':
      case 'PendPmtNoResponse':
        return <PendingPayments />;
      default:
        return <Communication />;
    }
  };

  return (
    <div>
      {!isAlert ? (
        <div>
          <ActivitySummary />
        </div>
      ) : (
        getAlertCodeView(alertCodeText)
      )}
    </div>
  );
};
