import {useEffect} from 'react';
import {constants} from '../../constants/common';
import {Button, Checkbox} from '../../ui/inputs';
import TextField from '../../ui/inputs/textfield/TextField';

function AddEditPaymentMethodTemplate({
  isEdit = false,
  isDefaultMethod = false,
  setIsDefaultMethod = () => {},
  paymentMethod = {},
  onPaymentMethodChange = () => {},
  onAddEditPaymentMethodClick = () => {},
  isRequireFieldErrorMessage,
  setIsRequireFieldErrorMessage = () => {},
  onEditOrAddClose = () => {},
}) {
  useEffect(() => {
    return () => {
      setIsRequireFieldErrorMessage(false);
    };
  }, []);
  return (
    <div className="my-2">
      <TextField
        id="payment-method"
        label={constants.PAYMENT_METHOD}
        value={paymentMethod}
        fullWidth={false}
        margin="dense"
        onChange={onPaymentMethodChange}
      />
      <div className="d-flex  align-items-center w-300-px ">
        <label className="m-1 me-3" htmlFor="default-method">
          {constants.DEFAULT_METHOD}
        </label>
        <div className="w-auto ms-4">
          <Checkbox
            id="default-method"
            checked={isDefaultMethod}
            onChange={e => setIsDefaultMethod(e.target.checked)}
          />
        </div>
      </div>

      {isRequireFieldErrorMessage && (
        <div className="text-error bold ps-1">
          {constants.PAYMENT_METHOD_IS_REQUIRED}
        </div>
      )}
      <div className="d-flex flex-wrap align-items-center mt-2">
        <Button
          variant="contained"
          size="medium"
          onClick={onAddEditPaymentMethodClick}
        >
          {isEdit ? constants.UPDATE : constants.INSERT}
        </Button>
        <Button
          variant="contained"
          size="medium"
          className="ms-3"
          onClick={() => {
            onEditOrAddClose();
          }}
        >
          {constants.CANCEL}
        </Button>
      </div>
    </div>
  );
}

export default AddEditPaymentMethodTemplate;
