import dayjs from 'dayjs';
import {constants} from '../../../../constants/common';

export const activityDetailColumns = (handleViewActivityDetails = () => {}) => {
  return [
    {
      field: 'paymentMemo',
      headerName: '',
      renderCell: ({row}) => (
        <span
          aria-hidden="true"
          className="blueLink cursor-pointer"
          onClick={() => handleViewActivityDetails(row)}
        >
          {constants.VIEW_DETAILS}
        </span>
      ),
      sortable: false,
      width: 100,
    },
    {
      field: 'dateEntered',
      headerName: 'Activity Date',
      sortable: false,
      valueFormatter: params =>
        params?.value
          ? dayjs(params?.value).utc().format(constants.DATE_TIME_FORMAT)
          : '',
      width: 180,
      type: 'date',
    },
    {
      field: 'description',
      headerName: 'Issue',
      minWidth: 150,
      maxWidth: 250,
      sortable: false,
      flex: 1,
    },
    {
      field: 'activityId',
      headerName: 'Activity Type',
      width: 100,
      sortable: false,
    },
    {
      field: 'notes',
      headerName: 'Notes',
      minWidth: undefined,
      flex: 1,
      sortable: false,
    },
  ];
};
