import {ChangeEvent} from 'react';
import {Checkbox as MuiCheckbox, CheckboxProps} from '@mui/material';

interface Props extends CheckboxProps {
  /**
   * checked
   */
  checked?: boolean;
  /**
   * onChange event
   */
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

/**
 * Checkbox component to show Checkbox.
 * More information available here https://mui.com/material-ui/react-checkbox/
 */

const Checkbox: React.FC<Props> = ({checked, onChange, ...otherProps}) => {
  return (
    <MuiCheckbox
      checked={checked || false}
      onChange={onChange}
      {...otherProps}
    />
  );
};

export default Checkbox;
