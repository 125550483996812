import {useDispatch, useSelector} from 'react-redux';
import {ViewSettingsDropDown} from '../../../common/view-settings/view-settings-dropdown/ViewSettingsDropDown';
import {
  updateExpectedPaymentsViewSettingObject,
  updateIsBrokenPromise,
} from '../../../../store/actions/expectedPaymentsActions';
import {CheckboxLabel} from '../../../../ui/inputs';
import {Box} from '../../../../ui/layouts';
import {gridModeTypes} from '../../../../constants/common';

export const ViewSettings = () => {
  const dispatch = useDispatch();

  const {
    expectedPaymentsSettingId,
    alertDescription,
    isBrokenPromise,
    expectedPaymentsViewSettings,
  } = useSelector((store: any) => store.expectedPaymentsReducer);

  return (
    <Box
      display="flex"
      justifyContent={alertDescription != null ? `space-between` : 'flex-end'}
      alignItems="center"
      marginY="12px"
    >
      {alertDescription != null && (
        <CheckboxLabel
          label={`Restricted to Alert ${alertDescription}`}
          checked={isBrokenPromise}
          onChange={e => {
            dispatch(updateIsBrokenPromise(e.target.checked));
          }}
        />
      )}
      <Box width="250px">
        <ViewSettingsDropDown
          settingId={expectedPaymentsSettingId}
          viewName="ExpectedPayment"
          isQueryParamDefault
          onViewSettingSelected={(selectedSettingId, viewSettings) =>
            dispatch(
              updateExpectedPaymentsViewSettingObject({
                settingId: selectedSettingId,
                viewSettings,
              }),
            )
          }
          currentViewSettings={expectedPaymentsViewSettings}
          activityType={gridModeTypes.ExpectedPayments}
        />
      </Box>
    </Box>
  );
};
