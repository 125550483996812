import data from '../lib/data';
import {isEmpty} from '../lib/utils';

export const fetchNACMService = custKey => {
  const endpoint = `v2/api/customerInfo/nacm/for/${custKey}`;
  return data.get(endpoint);
};

export const fetchPortalAccessService = (custKey, portalAccess) => {
  let endpoint = `v2/api/customerInfo/accessLinkHistory/for/${custKey}?`;

  if (!isEmpty(portalAccess.skip)) {
    endpoint += `&%24skip=${portalAccess.skip}`;
  }

  if (!isEmpty(portalAccess.pageSize)) {
    endpoint += `&%24top=${portalAccess.pageSize}`;
  }
  return data.get(endpoint);
};

export const fetchCreditDetailsService = custKey => {
  const endpoint = `v2/api/customerInfo/creditDetails/for/${custKey}`;
  return data.get(endpoint);
};

export const fetchInfoService = custKey => {
  const endpoint = `v2/api/customerInfo/details/for/${custKey}`;
  return data.get(endpoint);
};

export const fetchHistoryService = custKey => {
  const endpoint = `v2/api/customerInfo/statistics/for/${custKey}`;
  return data.get(endpoint);
};

export const fetchRolledUpHistoryService = custKey => {
  const endpoint = `v2/api/customerInfo/rolledUpStatistics/for/${custKey}`;
  return data.get(endpoint);
};

export const fetchContactsService = (groupKey, custKey, state) => {
  let endpoint = `v4/api/emailviewer/for/${groupKey}/${custKey}/contacts?&isOnlyActive=false`;
  if (!isEmpty(state?.contactsSkip)) {
    endpoint += `&skip=${state?.contactsSkip}`;
  }
  if (!isEmpty(state?.contactsPageSize)) {
    endpoint += `&top=${state?.contactsPageSize}`;
  }
  return data.get(endpoint);
};

export const fetchAccountNotesService = custKey => {
  const endpoint = `v2/api/customerInfo/notes/for/${custKey}`;
  return data.get(endpoint);
};

export const fetchTransactionsService = custKey => {
  const endpoint = `v2/api/customerInfo/invoices/for/${custKey}`;
  return data.get(endpoint);
};

export const fetchRecentPaymentsService = custKey => {
  const endpoint = `v2/api/customerInfo/payments/for/${custKey}`;
  return data.get(endpoint);
};

export const fetchCreditClassService = groupKey => {
  const endpoint = `v2/api/customerInfo/for/${groupKey}/creditClass`;
  return data.get(endpoint);
};

export const fetchReportInfoService = groupKey => {
  const endpoint = `v2/api/customerInfo/for/${groupKey}/reportInfo`;
  return data.get(endpoint);
};

export const saveCreditDetailsService = (custKey, payload) => {
  const endpoint = `v2/api/customerInfo/for/${custKey}/creditDetails`;
  return data.put(endpoint, payload);
};

export const saveInfoService = (custKey, payload) => {
  const endpoint = `v2/api/customerInfo/for/${custKey}/customerDetails`;
  return data.put(endpoint, payload);
};

export const addContact = payload => {
  const endpoint = `v2/api/activity/email/contact`;
  return data.post(endpoint, payload);
};

export const updateContact = (contactKey, payload) => {
  const endpoint = `v2/api/customerInfo/for/${contactKey}/contactDetails`;
  return data.put(endpoint, payload);
};

export const deleteCustomerContact = contactKey => {
  const endpoint = `v2/api/customerInfo/for/${contactKey}`;
  return data.delete(endpoint, {
    data: {
      contactKey,
    },
  });
};

export const fetchSelectedCustomerInfo = custKey => {
  const endpoint = `v2/api/customerInfo/for/${custKey}/customer`;
  return data.get(endpoint);
};

export const getCreditScoreScale = groupKey => {
  const endpoint = `v2/api/customerInfo/CreditScoreScale/${groupKey}`;
  return data.get(endpoint);
};

export const getCreditScore = (groupKey, custKey) => {
  const endpoint = `v2/api/customerInfo/CreditScore/${groupKey}/${custKey}`;
  return data.get(endpoint);
};
