import {Checkbox, FileUpload, Toast} from 'primereact';
import {useState, useRef} from 'react';
import {isValidateFile} from '../../../../lib/utils';
import {useFileManager} from '../FileManager';

export const FileUploadDialog = ({onHide}) => {
  const {onFilesUpload} = useFileManager();
  const [overWrite, setOverWrite] = useState(false);
  const toast = useRef();

  const myUploader = event => {
    const {files} = event;
    const isValidFiles = files.every(({name}) => {
      return isValidateFile(
        [
          'csv',
          'doc',
          'docx',
          'gif',
          'jpeg',
          'jpg',
          'pdf',
          'png',
          'tif',
          'tiff',
          'txt',
          'xls',
          'xlsx',
          'zip',
        ],
        name,
      );
    });
    if (!isValidFiles) {
      toast?.current?.show({
        severity: 'error',
        summary: 'File not supported',
        detail: 'Please provide valid file!',
      });
      return;
    }
    onFilesUpload(files, overWrite);
    onHide();
  };

  return (
    <>
      <Toast ref={toast} position="top-right" />
      <FileUpload
        name="files[]"
        customUpload
        maxFileSize={25000000}
        multiple
        accept=".xls,.xlsx,.csv,.doc,.docx,.txt,.zip,image/*"
        uploadHandler={myUploader}
        emptyTemplate={
          <p className="m-0">Drag and drop files to here to upload.</p>
        }
      />
      <label className="mt-2">
        {' '}
        <Checkbox
          checked={overWrite}
          onChange={e => setOverWrite(e.checked)}
        />{' '}
        Overwrite if file exists?
      </label>
      <div className="mt-4">
        <div>
          <span className="text-bold">Max file size allowed:</span>25.00 MB
        </div>
        <span className="text-bold">File extensions allowed:</span> *.csv,
        *.doc, *.docx, *.gif, *.jpeg, *.jpg, *.pdf, *.png, *.tif, *.tiff, *.txt,
        *.xls, *.xlsx, *.zip
      </div>
    </>
  );
};
