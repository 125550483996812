import {createContext} from 'react';

export const NavbarContext = createContext(null);

export const NavBarProvider = props => {
  const {children, ...otherProps} = props;

  return (
    <NavbarContext.Provider value={otherProps}>
      {children}
    </NavbarContext.Provider>
  );
};
