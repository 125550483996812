import {styled} from '@mui/material';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  fetchRecentPayments,
  fetchTransactions,
} from '../../../store/actions/customerInfoActions';
import Backdrop from '../../../ui/utils/backdrop/Backdrop';
import {
  transactionsColumns,
  transactionsRecentPaymentsColumns,
} from '../../common/table/columns/customer-info-tab-columns/transactionsColumns';
import CustomDataTable from '../../common/table/CustomDataTable';

function Transactions() {
  const {transactions, fetchingTransactions} = useSelector(
    store => store.customerInfoReducer.transactions,
  );
  const {recentPayments, fetchingRecentPayments} = useSelector(
    store => store.customerInfoReducer.recentPayments,
  );

  const dispatch = useDispatch();
  const selectedCustomer = useSelector(
    store => store.generalReducer?.selectedCustomer,
  );

  const fetchData = () => {
    dispatch(fetchTransactions(selectedCustomer?.custKey));
    dispatch(fetchRecentPayments(selectedCustomer?.custKey));
  };

  useEffect(() => {
    fetchData();
  }, [selectedCustomer]);

  const StyledRecentPaymentDiv = styled('div')(() => ({minWidth: '400px'}));

  return (
    <div className="customer-info-item-tab-content-wrapper position-relative p-3">
      <Backdrop open={fetchingRecentPayments} />
      <StyledRecentPaymentDiv>
        <CustomDataTable
          columns={transactionsRecentPaymentsColumns()}
          paginator={false}
          selectionMode={false}
          tableData={recentPayments}
          loading={fetchingRecentPayments}
          scrollHeight="450px"
        />
      </StyledRecentPaymentDiv>
      <div className="ms-3">
        <CustomDataTable
          columns={transactionsColumns()}
          paginator={false}
          selectionMode={false}
          tableData={transactions}
          loading={fetchingTransactions}
          scrollHeight="450px"
        />
      </div>
    </div>
  );
}

export default Transactions;
