export const applicationDetailsAggregateData = (data, columns) => {
  const aggregatedData = {
    tranId: 'Total',
  };

  // Calculate column sums
  columns.forEach(column => {
    if (column.type === 'number') {
      const columnName = column.field;
      aggregatedData[columnName] = data.reduce(
        (sum, row) => sum + (row[columnName] || 0),
        0,
      );
    }
  });

  return aggregatedData;
};
