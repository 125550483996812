import {ChangeEvent, useEffect, useState} from 'react';
import {
  GridPaginationModel,
  GridSortModel,
  GridColumnVisibilityModel,
  GridRowModesModel,
  GridRowModes,
  GridEventListener,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Checkbox, TextField} from '../../../ui/inputs';
import {Box, Stack} from '../../../ui/layouts';
import {constants, reservedChar} from '../../../constants/common';
import {
  ICreateOrUpdateViewSettings,
  IRenderViewSettingsProps,
  IViewSetting,
  IViewSettings,
} from './interface';
import {Typography} from '../../../ui/displays';
import {DataGrid} from '../../../ui/data';
import {ViewSettingsColumns} from './columns';
import {Dialog} from '../../../ui';
import {
  addViewSettingsState,
  deleteViewSettingsBySettingsKey,
  updateViewSettingsState,
} from '../../../store/actions/viewSettingsActions';
import RenderViewSettingsColumns from './RenderViewSettingsColumns';
import RenderViewSettingsCopy from './RenderViewSettingsCopy';
import {Snackbar} from '../../../ui/feedback';
import {ISnackbarProps} from '../../../ui/feedback/snackbar/Snackbar';
import {isEmpty} from '../../../lib/utils';
import {useQueryKeys} from '../../../hooks/useQueryKeys';
import {useSessionStorage} from '../../../hooks/useSessionStorage';

import {
  columnAlignments,
  handleColumnResize,
  handleDuplicatesInColumnSettings,
} from '../../../lib/commonTableHelpers';
import {PAGE_SIZE} from '../../../utils/constants';
import {
  createOrUpdateViewSettings,
  updateViewSettingsConfiguration,
} from '../../../services/viewSettings';
import {
  filterSystemViewSettings,
  sortViewSettingsByReactView,
} from './view-settings-dropdown/viewSettingsHelper';

function RenderViewSettings({
  viewName,
  loadViewSetting,
  handleCloseViewSettingsPopup,
  filterSqlText,
  sortSettingJson,
  columnSettingJson,
  mode,
  updateTableOnColumnSettingsChange,
}: IRenderViewSettingsProps) {
  const [currentViewSettingsName, setCurrentViewSettingsName] =
    useState<string>('');
  const [currentViewSettingsKey, setCurrentViewSettingsKey] =
    useState<string>('');
  const [selectedColumnSettings, setSelectedColumnSettings] = useState<any[]>(
    [],
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: PAGE_SIZE,
  });
  const {userKey} = useQueryKeys();
  const [{userId, groupKey, sessionViewGroup}] = useSessionStorage('profile');

  const systemGroupList = `0${sessionViewGroup}`;

  const dispatch = useDispatch<any>();
  const {
    [viewName]: viewSettings,
    fetchingViewSettings,
    copiedViewSettings,
  } = useSelector((state: any) => state.viewSettingsReducer);

  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({});

  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const [currentViewSettingsData, setCurrentViewSettingsData] = useState<
    IViewSettings[]
  >([]);

  const [showSystemSettings, setShowSystemSettings] = useState<boolean>(false);
  const [isGlobalSetting, setIsGlobalSetting] = useState<boolean>(false);

  const [sortModel, setSortModel] = useState<GridSortModel>([]);

  const [columnWidths, setColumnWidths] = useState<Record<string, number>>({});

  const [viewSettingsNameValidationError, setViewSettingsNameValidationError] =
    useState<boolean>(false);

  const [showCopyDialog, setShowCopyDialog] = useState<boolean>(false);

  const [showColumnsDialog, setShowColumnsDialog] = useState<boolean>(false);

  const [showCopyViewSettingsSnackbar, setShowCopyViewSettingsSnackbar] =
    useState<boolean>(false);

  const [confirmDialogProps, setConfirmDialogProps] = useState<{
    open: boolean;
    title: string;
    message: JSX.Element | null;
    dialogType: 'update' | 'delete' | undefined;
    onConfirm: () => void;
    onClose: () => void;
  }>({
    open: false,
    title: 'Are you sure?',
    message: null,
    dialogType: undefined,
    onConfirm: () => {},
    onClose: () => {},
  });

  const [snackbarObj, setSnackbarObj] = useState<ISnackbarProps>({
    message: '',
    onClose: () => {},
    open: false,
    type: 'info',
    title: '',
  });

  const updateSnackbarObj = ({type, title, open, message}: ISnackbarProps) => {
    setSnackbarObj({type, title, open, message, onClose: () => {}});
  };
  const handleCloseSnackbar = () => {
    setSnackbarObj(prev => ({...prev, open: false}));
  };

  useEffect(() => {
    if (showCopyViewSettingsSnackbar) {
      updateSnackbarObj({
        message: constants.COPIED_VIEW_SETTINGS_SUCCESSFULLY,
        open: true,
        type: 'success',
        title: constants.SUCCESS,
      });
      setShowCopyViewSettingsSnackbar(false);
    }
  }, [showCopyViewSettingsSnackbar]);

  const removeAlertsFromViewSettingsData = (settingId: string) => {
    return (
      settingId?.charAt(0) !== reservedChar.LEADING_RESERVED_CHAR_FOR_ALERTS
    );
  };

  const setViewSettingsGridData = () => {
    const sortedList = sortViewSettingsByReactView(viewSettings).filter(
      setting => removeAlertsFromViewSettingsData(setting.settingId),
    );
    if (showSystemSettings) {
      setCurrentViewSettingsData(sortedList);
    } else {
      setCurrentViewSettingsData(filterSystemViewSettings(sortedList));
    }
  };

  useEffect(() => {
    if (copiedViewSettings) {
      setShowCopyViewSettingsSnackbar(true);
      setShowCopyDialog(false);
      setViewSettingsGridData();
    }
  }, [copiedViewSettings]);

  useEffect(() => {
    if (viewSettings) {
      setViewSettingsGridData();
    }
  }, [viewSettings, showSystemSettings]);

  const onRefresh = () => {
    setViewSettingsGridData();
  };

  const onGridColumnValueChanged = (
    field: string,
    value: string | number | boolean,
    settingsKey: string,
  ) => {
    const updatedData = currentViewSettingsData
      .map(row => {
        if (row.settingsKey !== settingsKey) {
          return row;
        }
        return {
          ...row,
          [field]: value,
        };
      })
      .filter(setting => removeAlertsFromViewSettingsData(setting.settingId));
    setCurrentViewSettingsData(updatedData);
  };

  /**
   * Helper function to save current view settings or copy and update view settings
   * API decides it based on availability of settingsKey in payload.
   * @param payload ICreateOrUpdateViewSettings
   * @returns void
   */
  const saveHelperFunc = (payload: ICreateOrUpdateViewSettings) => {
    setLoading(true);
    createOrUpdateViewSettings(payload)
      .then(res => {
        const {data} = res;
        if (payload.settingsKey) {
          dispatch(updateViewSettingsState(data, viewName));
        } else {
          dispatch(addViewSettingsState(data, viewName));
        }
        loadViewSetting(data);
        handleCloseViewSettingsPopup();
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        updateSnackbarObj({
          message: constants.SOMETHING_WENT_WRONG,
          open: true,
          type: 'error',
          title: constants.ERROR,
        });
      });
  };

  /**
   * Construct payload to current view settings
   * @returns void
   */
  const saveCurrentViewSettings = () => {
    if (
      isEmpty(currentViewSettingsName) ||
      currentViewSettingsName.charAt(0) ===
        reservedChar.LEADING_RESERVED_CHAR_FOR_VIEW_SETTINGS
    ) {
      setViewSettingsNameValidationError(true);
      return;
    }
    if (
      currentViewSettingsName.charAt(0) ===
      reservedChar.LEADING_RESERVED_CHAR_FOR_ALERTS
    ) {
      setViewSettingsNameValidationError(true);
      return;
    }

    const payload = {
      settingID: currentViewSettingsName,
      settingsKey: '',
      userId,
      userKey,
      groupKey,
      viewName,
      systemGroupList,
      filterSqlText,
      columnSettingJson,
      sortSettingJson,
      mode,
    };
    saveHelperFunc(payload);
    setCurrentViewSettingsData(
      currentViewSettingsData.filter(setting =>
        removeAlertsFromViewSettingsData(setting.settingId),
      ),
    );
  };

  const onSortChange = (args: GridSortModel) => {
    setSortModel(args);
  };

  const onPageChange = (args: GridPaginationModel) => {
    setPaginationModel({
      pageSize: args.pageSize,
      page: args.pageSize !== paginationModel.pageSize ? 0 : args.page,
    });
  };

  /**
   * Changes the row mode to edit
   * @param settingsKey string
   */
  const handleEditClick = (settingsKey: string) => {
    setRowModesModel(old => ({
      ...old,
      [settingsKey]: {mode: GridRowModes.Edit},
    }));
  };
  /**
   * Reverse the row mode to view
   * @param settingsKey string
   */
  const onCancel = (settingsKey: string) => {
    setRowModesModel({
      ...rowModesModel,
      [settingsKey]: {mode: GridRowModes.View, ignoreModifications: true},
    });

    const editedRow = currentViewSettingsData?.find(
      row => row.settingsKey === settingsKey,
    );
    if (editedRow?.isNew) {
      setCurrentViewSettingsData(
        currentViewSettingsData
          ?.filter(row => row.settingsKey !== settingsKey)
          .filter(setting =>
            removeAlertsFromViewSettingsData(setting.settingId),
          ),
      );
    }
  };

  /**
   * Method called on confirming the delete action.
   * Filters the current view settings data and removes the row with the settingsKey.
   * Dispatches the delete action to the store.
   * On successful deletion, loads the next view settings data.
   * @param settingsKey string
   */
  const onRowDelete = async (settingsKey: string) => {
    let nextItem = null;
    const currentIndex = currentViewSettingsData?.findIndex(
      row => row.settingsKey === settingsKey,
    );
    if (currentIndex !== -1) {
      if (currentIndex === currentViewSettingsData.length - 1) {
        [nextItem] = currentViewSettingsData;
      } else {
        nextItem = currentViewSettingsData[currentIndex + 1];
      }
    }
    setCurrentViewSettingsData(
      currentViewSettingsData
        ?.filter(row => row.settingsKey !== settingsKey)
        .filter(setting => removeAlertsFromViewSettingsData(setting.settingId)),
    );
    dispatch(
      deleteViewSettingsBySettingsKey(settingsKey, updateSnackbarObj, viewName),
    );
    if (nextItem) loadViewSetting(nextItem);
  };

  /**
   * Method called on confirming the copy current setting to an existing view setting action.
   * Constructs the payload to save and calls save helper.
   * @param settingsKey string
   */
  const onCopyAndSaveViewSettings = (settingsKey: string) => {
    const viewSettingsData = [...currentViewSettingsData];
    const currentRow = viewSettingsData.find(
      row => row.settingsKey === settingsKey,
    );

    viewSettingsData.forEach(row => {
      if (
        row.settingsKey === settingsKey &&
        JSON.stringify(row) === JSON.stringify(currentRow)
      ) {
        const payload = {
          settingID: '',
          settingsKey,
          userId,
          userKey,
          groupKey,
          viewName,
          filterSqlText,
          columnSettingJson,
          sortSettingJson,
          systemGroupList,
          mode,
        };
        saveHelperFunc(payload);
      }
    });
  };

  /**
   * Update the view settings configuration on row edit save.
   * @param row IViewSetting
   */
  const onRowEditSave = async (row: IViewSetting) => {
    setRowModesModel({
      ...rowModesModel,
      [row.settingsKey]: {mode: GridRowModes.View},
    });
    setLoading(true);
    const payload = {
      settingsKey: row.settingsKey,
      isDefault: row.isDefault,
      show: row.show,
      sortOrder: Number(row.sortOrder),
      viewname: viewName,
      mode,
    };
    try {
      await updateViewSettingsConfiguration(payload);
      dispatch(updateViewSettingsState(row, viewName));
    } catch (error) {
      updateSnackbarObj({
        message: constants.SOMETHING_WENT_WRONG,
        open: true,
        type: 'error',
        title: constants.ERROR,
      });
    } finally {
      setLoading(false);
      onRefresh();
    }
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (
    params,
    event,
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      Object.defineProperty(event, 'defaultMuiPrevented', {
        value: true,
        writable: true,
        enumerable: true,
        configurable: true,
      });
    }
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const processRowUpdate = (newRow: IViewSettings) => {
    const updatedRow = {...newRow, isNew: false};
    const currentViewSettingsDataTemp = currentViewSettingsData
      .map(row => (row.settingsKey === newRow.settingsKey ? updatedRow : row))
      .filter(setting => removeAlertsFromViewSettingsData(setting.settingId));
    setCurrentViewSettingsData(currentViewSettingsDataTemp);
    return updatedRow;
  };

  const onShowSystemSettingsChange = (e: ChangeEvent<HTMLInputElement>) => {
    setShowSystemSettings(e.target.checked);
    setPaginationModel({page: 0, pageSize: PAGE_SIZE});
  };

  const onGlobalSettingsChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsGlobalSetting(e.target.checked);
    let updatedValue = currentViewSettingsName;

    if (e.target.checked) {
      if (currentViewSettingsName.startsWith('*')) {
        updatedValue = currentViewSettingsName.replace(/^\*+/, '*');
      } else {
        updatedValue = `*${currentViewSettingsName}`;
      }
    } else if (currentViewSettingsName.startsWith('*')) {
      updatedValue = currentViewSettingsName.replace(/^\*+/, '');
    }
    setCurrentViewSettingsName(updatedValue);
  };

  const calculatePage = (paramData: any[]) => {
    return paramData.slice(
      paginationModel.page * paginationModel.pageSize,
      (paginationModel.page + 1) * paginationModel.pageSize,
    );
  };

  /**
   * Set current view setting to state and enable copy dialog flag to true
   * @param settingsKey string
   */
  const handleCopyClick = (settingsKey: string) => {
    setCurrentViewSettingsKey(settingsKey);
    setShowCopyDialog(true);
  };

  /**
   * Make a call to callback method received as props to load the view settings and close the dialog
   * @param row IViewSettings
   */
  const handleLoadClick = (row: IViewSettings) => {
    loadViewSetting(row);
    handleCloseViewSettingsPopup();
  };

  /**
   * Set current view setting to state and enable columns edit dialog flag to true.
   * Also removes duplicates from column settings created because of legacy.
   * @param row IViewSetting
   */
  const handleColumnsClick = (row: IViewSetting) => {
    setCurrentViewSettingsKey(row.settingsKey);
    const columnSettings = [...row.columnSetting];
    const uniqueColumnSettings =
      handleDuplicatesInColumnSettings(columnSettings);
    setSelectedColumnSettings(uniqueColumnSettings);
    setShowColumnsDialog(true);
  };
  const closeConfirmDialogProps = () => {
    setConfirmDialogProps({
      ...confirmDialogProps,
      open: false,
    });
  };
  /**
   * Shows confirmation dialog to copy and save the view settings before proceeding.
   * @param settingsKey string
   * @param settingId string
   */
  const handleCopyAndSaveViewSettings = (
    settingsKey: string,
    settingId: string,
  ) => {
    setConfirmDialogProps({
      open: true,
      title: constants.ARE_YOU_SURE,
      message: (
        <Typography variant="h6">
          {constants.updateViewSettingConfirm(settingId)}
        </Typography>
      ),
      dialogType: 'update',
      onConfirm: () => {
        onCopyAndSaveViewSettings(settingsKey);
        closeConfirmDialogProps();
      },
      onClose: () => {
        closeConfirmDialogProps();
      },
    });
  };
  /**
   * Shows confirmation dialog to delete view settings before proceeding.
   * @param settingsKey string
   * @param settingId string
   */
  const handleDeleteClick = (settingsKey: string, settingId: string) => {
    setConfirmDialogProps({
      open: true,
      title: constants.ARE_YOU_SURE,
      message: (
        <Typography variant="h6">
          {constants.deleteViewSettingConfirm(
            settingId,
            constants.VIEW_SETTING.toLocaleLowerCase(),
          )}
        </Typography>
      ),
      dialogType: 'delete',
      onConfirm: () => {
        onRowDelete(settingsKey);
        closeConfirmDialogProps();
      },
      onClose: () => {
        closeConfirmDialogProps();
      },
    });
  };

  const adjustedColumns = ViewSettingsColumns({
    onCancel,
    onRowEditSave,
    handleEditClick,
    rowModesModel,
    columnAlignments,
    handleCopyClick,
    handleLoadClick,
    handleColumnsClick,
    handleCopyAndSaveViewSettings,
    handleDeleteClick,
    onGridColumnValueChanged,
  }).map(
    (column: {
      field: string;
      width: number;
      [key: string]: any; // for any other properties that might be in the column objects
    }) => ({
      ...column,
      width: columnWidths[column.field] || column.width,
    }),
  );

  function getHelperText() {
    if (viewSettingsNameValidationError) {
      if (isEmpty(currentViewSettingsName)) {
        return constants.SETTING_NAME_IS_REQUIRED;
      }
      if (
        currentViewSettingsName.charAt(0) ===
        reservedChar.LEADING_RESERVED_CHAR_FOR_ALERTS
      ) {
        return constants.LEADING_TILDE_RESERVED_FOR_ALERT_NAMES;
      }
      if (
        currentViewSettingsName.charAt(0) ===
        reservedChar.LEADING_RESERVED_CHAR_FOR_VIEW_SETTINGS
      ) {
        return constants.LEADING_TILDE_RESERVED_FOR_SYSTEM_SETTING_NAMES;
      }
    }
    return '';
  }

  const paginatedData = calculatePage(currentViewSettingsData).filter(
    setting => {
      return removeAlertsFromViewSettingsData(setting.settingId);
    },
  );

  const rowCount = currentViewSettingsData.length;

  return (
    <Box padding={2}>
      <Snackbar
        open={snackbarObj.open}
        onClose={handleCloseSnackbar}
        message={snackbarObj.message}
        title={snackbarObj.title}
        type={snackbarObj.type}
      />
      <Dialog
        open={showColumnsDialog}
        title={constants.VIEW_COLUMN_SETTINGS}
        onClose={() => {
          setCurrentViewSettingsKey('');
          setShowColumnsDialog(false);
        }}
        maxWidth="sm"
      >
        <RenderViewSettingsColumns
          settingsKey={currentViewSettingsKey}
          columnSettings={selectedColumnSettings}
          onClose={() => {
            setShowColumnsDialog(false);
          }}
          updateParentSnackbarObj={updateSnackbarObj}
          updateTableOnColumnSettingsChange={updateTableOnColumnSettingsChange}
          viewName={viewName}
        />
      </Dialog>
      <Dialog
        draggable
        open={showCopyDialog}
        title={constants.COPY_VIEW_SETTINGS}
        onClose={() => {
          setCurrentViewSettingsKey('');
          setShowCopyDialog(false);
        }}
        maxWidth="sm"
        height={500}
        fullWidth
      >
        <RenderViewSettingsCopy
          settingsKey={currentViewSettingsKey}
          viewName={viewName}
          mode={mode}
        />
      </Dialog>
      <Dialog
        showCancelButton
        showConfirmButton
        title="Are you sure?"
        open={confirmDialogProps.open}
        onClose={confirmDialogProps.onClose}
        onConfirm={confirmDialogProps.onConfirm}
      >
        {confirmDialogProps.message}
      </Dialog>
      <Stack
        sx={{
          background: '#fafbfc',
        }}
        direction="row"
        marginBottom={2}
        padding={1}
        borderRadius={2}
        alignItems="center"
      >
        <TextField
          label={constants.SAVE_CURRENT_SETTINGS_AS}
          value={currentViewSettingsName}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setCurrentViewSettingsName(e.target.value);
            setViewSettingsNameValidationError(false);
          }}
          sx={{minWidth: 300}}
          error={viewSettingsNameValidationError}
          helperText={getHelperText()}
          margin="none"
          fullWidth={false}
        />
        <Button
          variant="contained"
          onClick={saveCurrentViewSettings}
          sx={{alignSelf: 'center'}}
        >
          {constants.SAVE}
        </Button>
        <Stack>
          <Typography variant="subtitle1" fontWeight={700}>
            {constants.INCLUDE_ALL_USERS}
          </Typography>
          <Checkbox
            className="p-0"
            checked={isGlobalSetting}
            onChange={onGlobalSettingsChange}
          />
        </Stack>
      </Stack>
      <Stack
        direction="column"
        sx={{background: '#fafbfc', padding: 1, marginY: 2, borderRadius: 2}}
      >
        <Stack>
          <Typography variant="subtitle1" fontWeight={700}>
            {constants.SHOW_SYSTEM_SETTINGS}
          </Typography>
          <Checkbox
            className="p-0"
            disabled={fetchingViewSettings}
            checked={showSystemSettings}
            onChange={onShowSystemSettingsChange}
          />
        </Stack>

        <DataGrid
          data-testid="view-settings-grid"
          disableVirtualization
          columns={adjustedColumns}
          onColumnResize={params =>
            handleColumnResize(params, setColumnWidths, columnWidths)
          }
          rowCount={rowCount}
          rows={paginatedData}
          loading={loading || fetchingViewSettings}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityChange={visibleData =>
            setColumnVisibilityModel(visibleData)
          }
          disableColumnFilter
          onSortChange={onSortChange}
          onPageChange={onPageChange}
          sortModel={sortModel}
          customToolbarMethods={{
            onRefresh,
          }}
          disableMultipleRowSelection
          disableColumnMenu
          paginationModel={paginationModel}
          editMode="row"
          rowSelection={false}
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          disableColumnReorder
          disableColumnResize
          height={400}
          getRowId={row => row.settingsKey}
        />
      </Stack>
    </Box>
  );
}

export default RenderViewSettings;
