import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DataGrid} from '../../../ui/data';
import {Typography} from '../../../ui/displays';
import {Button} from '../../../ui/inputs';
import {UserTypes, constants} from '../../../constants/common';
import {
  copyViewSettings,
  getAccountUsers,
} from '../../../store/actions/viewSettingsActions';
import {useSessionStorage} from '../../../hooks/useSessionStorage';
import {useQueryKeys} from '../../../hooks/useQueryKeys';
import {getSelectedRowsByKey} from '../../../lib/commonTableHelpers';
import Snackbar, {ISnackbarProps} from '../../../ui/feedback/snackbar/Snackbar';

const RenderViewSettingsCopy = ({
  settingsKey,
  viewName,
  mode,
}: {
  settingsKey: string;
  viewName: string;
  mode: string;
}) => {
  const dispatch = useDispatch<any>();

  const {accountUsers, fetchingAccountUsers, copingViewSettings} = useSelector(
    (state: any) => state.viewSettingsReducer,
  );
  const {userKey} = useQueryKeys();
  const [{userId, groupKey}] = useSessionStorage('profile');
  const [snackbarObj, setSnackbarObj] = useState<ISnackbarProps>({
    message: '',
    onClose: () => {},
    open: false,
    type: 'info',
    title: '',
  });

  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  const updateSnackbarObj = ({type, title, open, message}: ISnackbarProps) => {
    setSnackbarObj({type, title, open, message, onClose: () => {}});
  };
  const handleCloseSnackbar = () => {
    setSnackbarObj(prev => ({...prev, open: false}));
  };

  const loadAccountUsers = () => {
    const payload = {
      userKey,
      groupKey,
      updateSnackbarObj,
    };
    dispatch(getAccountUsers(payload));
  };

  const onColumnRowSelect = (newSelectionModel: any) => {
    const selectedRowsObject = getSelectedRowsByKey(
      newSelectionModel,
      accountUsers,
      'id',
    );
    setSelectedRows(selectedRowsObject);
  };

  const copySettings = () => {
    const getUsersAccountKey = selectedRows.map(row => row.userAccountKey);
    dispatch(
      copyViewSettings({
        userId,
        settingsKey,
        userKey,
        usersList: getUsersAccountKey,
        ViewName: viewName,
        updateSnackbarObj,
        mode,
      }),
    );
  };

  useEffect(() => {
    loadAccountUsers();
  }, []);

  return (
    <>
      <Snackbar
        open={snackbarObj.open}
        onClose={handleCloseSnackbar}
        message={snackbarObj.message}
        title={snackbarObj.title}
        type={snackbarObj.type}
      />
      <Typography fontWeight={700}>
        {constants.CHOOSE_USERS_TO_COPY_SETTINGS_TO}
      </Typography>

      <DataGrid
        disableColumnMenu
        loading={fetchingAccountUsers || copingViewSettings}
        hidePagination
        checkboxSelection
        disableColumnResize
        columns={[
          {
            field: 'userID',
            headerName: 'User ID',
            width: 200,
            flex: 1,
            sortable: false,
          },
          {
            field: 'userType',
            headerName: 'User Type',
            width: 200,
            flex: 1,
            sortable: false,
            valueFormatter: (params: {value?: number}) =>
              params?.value !== null
                ? UserTypes[params?.value as keyof typeof UserTypes]
                : '',
          },
        ]}
        disableColumnReorder
        hideFooterPagination
        rowsSelected={selectedRows.map(row => row.id)}
        onColumnRowSelect={onColumnRowSelect}
        height={550}
        rows={accountUsers}
        getRowId={row => row.id}
      />

      <Button
        className="mt-4"
        style={{left: 'calc(100% - 64px)'}}
        variant="contained"
        onClick={copySettings}
      >
        {constants.SAVE}
      </Button>
    </>
  );
};

export default RenderViewSettingsCopy;
