import {actionTypes} from '../constants/actionTypes';

const initialState = {
  expectedPaymentsData: [],
  expectedPaymentsViewSettings: null,
  fetchingExpectedPayments: false,
  fetchingExpectedPaymentsFailed: false,
  fetchedExpectedPayments: false,
  expectedPaymentsLoading: false,
  expectedPaymentsCount: 0,
  expectedPaymentsSkip: 0,
  expectedPaymentsNextLink: '',
  expectedPaymentsSelectedRows: [],
  exportingToExcel: false,
  exportedToExcel: false,
  exportingToExcelFailed: false,
  expectedPaymentsSettingId: null,
  isBrokenPromise: true,
  alertDescription: '',
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const expectedPaymentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_EXPECTED_PAYMENTS:
      return {
        ...state,
        fetchingExpectedPayments: true,
        fetchingExpectedPaymentsFailed: false,
        expectedPaymentsLoading: true,
      };
    case actionTypes.FETCHED_EXPECTED_PAYMENTS:
      return {
        ...state,
        fetchingExpectedPayments: false,
        fetchingExpectedPaymentsFailed: false,
        fetchedExpectedPayments: true,
        expectedPaymentsData: action.expectedPaymentsData,
        selectedSchedulePaymentsPageSize:
          action.selectedSchedulePaymentsPageSize || 10,
        expectedPaymentsLoading: false,
        expectedPaymentsCount: action.expectedPaymentsCount,
        expectedPaymentsNextLink: action.expectedPaymentsNextLink,
        expectedPaymentsSkip: action.expectedPaymentsSkip,
      };
    case actionTypes.FETCHING_EXPECTED_PAYMENTS_FAILED:
      return {
        ...state,
        fetchingExpectedPayments: false,
        fetchingExpectedPaymentsFailed: true,
        fetchedExpectedPayments: true,
        expectedPaymentsLoading: false,
        expectedPaymentsData: [],
        expectedPaymentsCount: 0,
      };
    case actionTypes.EXPORTING_EXPECTED_PAYMENTS_TO_EXCEL:
      return {
        ...state,
        expectedPaymentsLoading: true,
        exportingToExcel: true,
        exportedToExcel: false,
        exportingToExcelFailed: false,
      };
    case actionTypes.EXPORTED_EXPECTED_PAYMENTS_TO_EXCEL:
      return {
        ...state,
        expectedPaymentsLoading: false,
        exportingToExcel: false,
        exportedToExcel: true,
        exportingToExcelFailed: false,
      };
    case actionTypes.EXPORTING_EXPECTED_PAYMENTS_TO_EXCEL_FAILED:
      return {
        ...state,
        expectedPaymentsLoading: false,
        exportingToExcel: false,
        exportedToExcel: false,
        exportingToExcelFailed: true,
      };
    case actionTypes.SET_EXPECTED_PAYMENTS_VIEW_SETTING_KEY:
      return {
        ...state,
        expectedPaymentsSettingId: action.expectedPaymentsSettingId,
      };
    case actionTypes.SET_IS_BROKEN_PROMISE:
      return {
        ...state,
        isBrokenPromise: action.isBrokenPromise,
      };
    case actionTypes.SET_EXPECTED_PAYMENTS_ALERT_DESCRIPTION:
      return {...state, alertDescription: action.alertDescription};

    case actionTypes.SET_EXPECTED_PAYMENTS_VIEW_SETTING_OBJECT:
      return {
        ...state,
        expectedPaymentsViewSettings: {
          ...action.payload.viewSettings,
          settingsKey: action.payload.settingId,
          sortSetting: null,
        },
        expectedPaymentsSettingId: action.payload.settingId,
      };

    case actionTypes.CLEANUP_EXPECTED_PAYMENTS:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default expectedPaymentsReducer;
