import {isEmpty} from '../../../lib/utils';
import {constants} from '../../../constants/common';
import {Button, TextField} from '../../../ui/inputs';
import {IDisputeUDFs, IUDFs} from '../../account-overview/interface';
import {Typography} from '../../../ui/displays';

interface IUdfTabProps {
  udfInvoiceList: IUDFs | null;
  isEditingInvoiceUdf: boolean;
  setIsEditingInvoiceUdf: (value: boolean) => void;
  saveInvoiceUdf: () => void;
  onUDfChange: (key: string, value: any) => void;
  disputeUDFList: IDisputeUDFs | null;
  isEditingDisputeUdf: boolean;
  onDisputeUDfChange: (key: string, value: any) => void;
  setIsEditingDisputeUdf: (value: boolean) => void;
}
function UdfTab({
  udfInvoiceList,
  isEditingInvoiceUdf,
  setIsEditingInvoiceUdf,
  onUDfChange,
  saveInvoiceUdf,
  disputeUDFList,
  isEditingDisputeUdf,
  onDisputeUDfChange,
  setIsEditingDisputeUdf,
}: IUdfTabProps) {
  return (
    <div className="d-flex justify-content-between">
      <div className="mx-1 border p-2 light-grey min-width-400-px">
        <div className="mb-2">
          <Typography variant="body1" fontWeight={800}>
            {constants.INVOICE_UDF}
          </Typography>
        </div>
        {!isEmpty(udfInvoiceList) &&
          udfInvoiceList &&
          Object.keys(udfInvoiceList)?.map(key => {
            return (
              <div
                key={udfInvoiceList[key].label}
                className="py-2 d-flex align-items-center justify-content-between"
              >
                <Typography variant="body1" className="me-2">
                  {udfInvoiceList[key].label}:
                </Typography>{' '}
                {isEditingInvoiceUdf && udfInvoiceList[key].editable ? (
                  <div className="w-50">
                    <TextField
                      variant="outlined"
                      value={udfInvoiceList[key].value}
                      onChange={e => onUDfChange(key, e.target.value)}
                      margin="normal"
                    />
                  </div>
                ) : (
                  <Typography
                    variant="body1"
                    title={udfInvoiceList[key].value}
                    padding={1}
                    width="50%"
                  >
                    {udfInvoiceList[key].value}
                  </Typography>
                )}
              </div>
            );
          })}
        <div className="mt-2">
          {!isEditingInvoiceUdf ? (
            <Button
              variant="contained"
              onClick={() => setIsEditingInvoiceUdf(true)}
            >
              {constants.EDIT}
            </Button>
          ) : (
            <div>
              <Button
                variant="contained"
                className="me-3"
                onClick={saveInvoiceUdf}
              >
                {constants.SAVE}
              </Button>
              <Button
                variant="contained"
                onClick={() => setIsEditingInvoiceUdf(false)}
              >
                {constants.CANCEL}
              </Button>
            </div>
          )}
        </div>
      </div>
      <div className="mx-1 border p-2 light-grey min-width-400-px flex-start me-5">
        <div className="mb-2">
          <span className="text-bold">{constants.DISPUTE_INVOICE_UDF}</span>
        </div>
        <div className="my-4">
          {!isEmpty(disputeUDFList) &&
            Object.keys({...disputeUDFList}).map(key => {
              return (
                <div
                  key={disputeUDFList && disputeUDFList[key].label}
                  className="py-2 d-flex align-items-center justify-content-between"
                >
                  <span className="me-2">
                    {disputeUDFList && disputeUDFList[key].label}:
                  </span>{' '}
                  {isEditingDisputeUdf &&
                  disputeUDFList &&
                  disputeUDFList[key].editable ? (
                    <div className="w-50">
                      <TextField
                        variant="outlined"
                        value={disputeUDFList[key].value}
                        onChange={(e: any) =>
                          onDisputeUDfChange(key, e.target.value)
                        }
                        margin="normal"
                      />
                    </div>
                  ) : (
                    <Typography
                      variant="body1"
                      title={disputeUDFList && disputeUDFList[key].value}
                      padding={1}
                      width="50%"
                    >
                      {disputeUDFList && disputeUDFList[key].value}
                    </Typography>
                  )}
                </div>
              );
            })}
          {!isEmpty(disputeUDFList) &&
            Object.keys({...disputeUDFList}).length > 0 && (
              <div className="mt-2">
                {!isEditingDisputeUdf ? (
                  <Button
                    variant="contained"
                    onClick={() => setIsEditingDisputeUdf(true)}
                  >
                    {constants.EDIT}
                  </Button>
                ) : (
                  <div>
                    <Button
                      variant="contained"
                      className="me-3"
                      onClick={saveInvoiceUdf}
                    >
                      {constants.SAVE}
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => setIsEditingDisputeUdf(false)}
                    >
                      {constants.CANCEL}
                    </Button>
                  </div>
                )}
              </div>
            )}
        </div>
      </div>
    </div>
  );
}

export default UdfTab;
