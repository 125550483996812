import {useSelector} from 'react-redux';
import {useState} from 'react';
import {constants} from '../../../../constants/common';
import {TRAN_TYPE} from '../../../../utils/constants';
import {getUserData} from '../../../../store/actions/userActions';
import {useShowCustomerDocs} from '../../../account-overview/hooks/useShowCustomerDocs';
import {downloadInvoice} from '../../../../utils/fileUtils';
import {checkIsTranTypePayment} from '../../../account-overview/statement-of-account/helper';
import ApplicationDetailTemplate from '../../../messages/application-details/ApplicationDetailTemplate';
import {OpenDocs} from '../../../communicate/open-docs/OpenDocs';
import {ISnackbarProps} from '../../../../ui/feedback/snackbar/Snackbar';
import {Dialog} from '../../../../ui';
import {Snackbar} from '../../../../ui/feedback';
import {createHandleSetShowCustomerDocs} from '../../../common/helper';

const ShowCustomerDocs = ({
  invoiceType,
  selectedInvoice,
  showCustomerDocs,
  setShowCustomerDocs,
  custName,
  custId,
  custKey,
}: {
  invoiceType: boolean;
  selectedInvoice: any;
  showCustomerDocs: boolean;
  setShowCustomerDocs: (val: boolean) => void;
  custName: any;
  custId: any;
  custKey: any;
}) => {
  const groupKey = getUserData()?.groupKey;
  if (!showCustomerDocs) {
    return null;
  }
  return (
    <OpenDocs
      showCustomerDocs={showCustomerDocs}
      setShowCustomerDocs={setShowCustomerDocs}
      groupKey={groupKey}
      custName={custName}
      custKey={custKey}
      isInvoice={invoiceType}
      selectedInvoice={selectedInvoice}
      custId={custId}
    />
  );
};

export const DisplayColumn = ({values}: {values: any}) => {
  const {row} = values;
  const [snackbarObj, setSnackbarObj] = useState<ISnackbarProps>({
    message: '',
    onClose: () => {},
    open: false,
    title: '',
    type: 'success',
  });

  const toast = useSelector((store: any) => store.generalReducer?.toastRef);
  const groupKey = getUserData()?.groupKey;
  const {
    showCustomerDocs,
    setShowCustomerDocs,
    openDocsProp,
    handleSetShowCustomerDocs,
  } = useShowCustomerDocs();
  const updateSnackbarObj = ({type, title, open, message}: ISnackbarProps) => {
    setSnackbarObj({type, title, open, message, onClose: () => {}});
  };

  const onPDFDownloadIconClick = (
    columnInvoiceKey: string,
    message: string = '',
  ) => {
    downloadInvoice(
      groupKey,
      columnInvoiceKey,
      null,
      updateSnackbarObj,
      message,
    );
  };

  return (
    <>
      <Snackbar
        open={snackbarObj.open}
        onClose={() => {
          setSnackbarObj((prev: ISnackbarProps) => ({
            ...prev,
            open: false,
          }));
        }}
        message={snackbarObj.message}
        title={snackbarObj.title}
        type={snackbarObj.type}
      />
      <span className="mx-1">
        {(row.tranType.toLowerCase() === TRAN_TYPE.IN.toLowerCase() ||
          row.tranType.toLowerCase() === TRAN_TYPE.CM.toLowerCase()) && (
          <>
            {row?.copyPDFToBlob === 99 ? (
              <i
                className="pi pi-pdf p-2 cursor-pointer"
                aria-hidden="true"
                onClick={() =>
                  onPDFDownloadIconClick(
                    row.invcKey,
                    constants.PDF_DOES_NOT_EXISTS_FOR_THIS_INVOICE,
                  )
                }
              />
            ) : (
              <i
                className="pi pi-pdf-warning p-2 cursor-pointer"
                aria-hidden="true"
                onClick={() => {
                  toast?.current.show({
                    severity: 'error',
                    summary: constants.FILE_NOT_FOUND,
                    detail: constants.PDF_DOES_NOT_EXISTS_FOR_THIS_INVOICE,
                  });
                }}
              />
            )}
            <i
              className="pi pi-folder p-2 ms-1 cursor-pointer"
              aria-hidden="true"
              onClick={() => handleSetShowCustomerDocs(row)}
            />
          </>
        )}
      </span>
      <ShowCustomerDocs
        invoiceType={openDocsProp?.invoiceType}
        selectedInvoice={openDocsProp?.selectedInvoice}
        showCustomerDocs={showCustomerDocs}
        setShowCustomerDocs={setShowCustomerDocs}
        custId={row.custId}
        custName={row.custName}
        custKey={row.custKey}
      />
    </>
  );
};

export const ViewApplication = ({values}: {values: any}) => {
  const {row} = values;
  const groupKey = getUserData()?.groupKey;
  const [showApplicationDialog, setShowApplicationDialog] =
    useState<boolean>(false);
  const [docKey, setDocKey] = useState<string>('');
  const [tranType, setTranType] = useState<string>('');
  const [tranId, setTranId] = useState<string>('');
  const [snackbarObj, setSnackbarObj] = useState<ISnackbarProps>({
    message: '',
    onClose: () => {},
    open: false,
    title: '',
    type: 'success',
  });

  const {
    showCustomerDocs,
    setShowCustomerDocs,
    openDocsProp,
    handleSetShowCustomerDocs,
  } = useShowCustomerDocs();

  const updateSnackbarObj = ({type, title, open, message}: ISnackbarProps) => {
    setSnackbarObj({type, title, open, message, onClose: () => {}});
  };

  const onPDFDownloadIconClick = (columnInvoiceKey?: string) => {
    downloadInvoice(groupKey, columnInvoiceKey, null, updateSnackbarObj);
  };

  const showApplicationsDialog = (
    tranTypeParam: string,
    docKeyParam: string,
    tranIdParam: string,
  ) => {
    setShowApplicationDialog(true);
    setTranId(tranIdParam);
    setDocKey(docKeyParam);
    setTranType(tranTypeParam);
  };
  const onApplicationsClick = (rowData: any) => {
    if (rowData.pmtCount > 0) {
      showApplicationsDialog(rowData.tranType, rowData.invcKey, rowData.tranId);
    }
  };
  const dialogTitle = checkIsTranTypePayment(tranType)
    ? constants.invoiceApplicationForPayment(tranId)
    : constants.applicationDetailsForInvoice(tranId);

  return (
    <>
      <Snackbar
        open={snackbarObj.open}
        onClose={() => {
          setSnackbarObj((prev: ISnackbarProps) => ({
            ...prev,
            open: false,
          }));
        }}
        message={snackbarObj.message}
        title={snackbarObj.title}
        type={snackbarObj.type}
      />
      <Dialog
        open={!!tranId && !!docKey && !!tranType && showApplicationDialog}
        title={dialogTitle}
        onClose={() => {
          setTranId('');
          setDocKey('');
          setTranType('');
          setShowApplicationDialog(false);
        }}
        draggable
        maxWidth="md"
        fullWidth
      >
        <ApplicationDetailTemplate
          tranType={tranType}
          custPmtKey={docKey}
          invcKey={docKey}
          onPDFDownloadIconClick={onPDFDownloadIconClick}
          handleSetShowCustomerDocs={() =>
            createHandleSetShowCustomerDocs(handleSetShowCustomerDocs)
          }
          checkIsTranTypePayment={checkIsTranTypePayment}
        />
      </Dialog>
      <span
        aria-hidden="true"
        className={`${row.pmtCount > 0 && 'blueLink cursor-pointer'}`}
        onClick={() => onApplicationsClick(row)}
      >
        {`${row.pmtCount} ${constants.APPLICATIONS}`}{' '}
      </span>
      <ShowCustomerDocs
        invoiceType={openDocsProp?.invoiceType}
        selectedInvoice={openDocsProp?.selectedInvoice}
        showCustomerDocs={showCustomerDocs}
        setShowCustomerDocs={setShowCustomerDocs}
        custId={row.custId}
        custName={row.custName}
        custKey={row.custKey}
      />
    </>
  );
};
