import {constants, gridModeTypes} from '../constants/common';
import data from '../lib/data';
import {SURCHARGE_STATUS_CODE} from '../utils/constants';
import {isEmpty} from '../lib/utils';
import {downloadBlobFilePostApi} from './download';

export const getCurrencies = ({customerKey, groupKey}) => {
  const endpoint = `v2/api/payments/for/atc/${groupKey}/${customerKey}/currencies`;
  return data.get(endpoint);
};

export const getPaymentMethods = ({groupKey, customerKey}) => {
  const endpoint = `v2/api/payments/paymentmethods/for/${groupKey}/${customerKey}`;
  return data.get(endpoint);
};

export const addOrEditPaymentMethodService = (isEdit, options) => {
  let endpoint = 'v2/api/payments/paymentmethod/for/';
  if (isEdit) {
    endpoint += `${options.groupKey}/${options.custKey}/${options.selectedPaymentMethodKey}`;
    return data.put(endpoint, {
      IsDefault: options.IsDefault,
      PaymentMethodId: options.PaymentMethodId,
    });
  }

  endpoint += `${options.groupKey}/${options.custKey}`;
  return data.post(endpoint, options);
};

export const deletePaymentMethodService = ({
  groupKey,
  custKey,
  pmtMethodKey,
}) => {
  const endpoint = `v2/api/payments/from/${groupKey}/to/${custKey}/methods/${pmtMethodKey}`;
  return data.delete(endpoint);
};

export const viewPaymentMethodService = ({
  groupKey,
  custKey,
  selectedPaymentMethodKey,
}) => {
  const endpoint = `v2/api/payments/paymentmethod/for/${groupKey}/${custKey}/${selectedPaymentMethodKey}`;
  return data.get(endpoint);
};

const getTotalTransactionFee = transactionFeeResponses => {
  let totalTransactionFee = 0;
  transactionFeeResponses.forEach(feeResponse => {
    totalTransactionFee += feeResponse.transactionFee;
  });
  return totalTransactionFee;
};

export const interPaymentService = dataToSend => {
  const endpoint = 'v2/api/interpayment/fee';
  let interPaymentObj = {};
  return data
    .post(endpoint, dataToSend)
    .then(res => {
      const {
        statusCode,
        transactionFeeResponses,
        isSurchargeApplicable,
        message: errMsg,
      } = res.data;

      const totalFee = getTotalTransactionFee(transactionFeeResponses);
      interPaymentObj = {
        surchargeStatusCode: statusCode,
      };
      if (statusCode === SURCHARGE_STATUS_CODE.SUCCESS) {
        if (
          errMsg
            .toLocaleLowerCase()
            .includes(
              constants.NOT_ENOUGH_REGION_OR_COUNTRY_INFORMATION.toLocaleLowerCase(),
            )
        ) {
          interPaymentObj = {
            ...interPaymentObj,
            surchargeErrorMessage: errMsg,
            isSurchargeApplicable,
            totalFee: 0,
          };
        } else {
          interPaymentObj = {
            ...interPaymentObj,
            isSurchargeApplicable,
            transactionFeeResponses,
            transactionFeeResponsesLoaded: !!transactionFeeResponses.length > 0,
            totalFee,
            surchargeErrorMessage: null,
          };
        }
      } else if (
        statusCode === SURCHARGE_STATUS_CODE.NO_INVOICE_TO_GET_SURCHARGE_FEE ||
        statusCode === SURCHARGE_STATUS_CODE.NOT_SUPPORTED_FOR_ACTIVE_MERCHANT
      ) {
        interPaymentObj = {
          ...interPaymentObj,
          surchargeErrorMessage: null,
        };
      } else if (
        statusCode === SURCHARGE_STATUS_CODE.ONE_OR_MORE_NEGATIVE_TRANSACTION
      ) {
        interPaymentObj = {
          ...interPaymentObj,
          surchargeErrorMessage: errMsg,
          isSurchargeApplicable,
          ReminderForOneOrMoreNegativeTransaction: data.message,
          transactionFeeResponses,
          transactionFeeResponsesLoaded: true,
        };
      } else if (statusCode === SURCHARGE_STATUS_CODE.Warning) {
        interPaymentObj = {
          ...interPaymentObj,
          surchargeErrorMessage: null,
          isSurchargeApplicable,
          surchargeWarning: errMsg,
          transactionFeeResponses,
          transactionFeeResponsesLoaded: true,
        };
      } else {
        interPaymentObj = {
          ...interPaymentObj,
          surchargeErrorMessage: errMsg,
          isSurchargeApplicable,
          transactionFeeResponses,
          transactionFeeResponsesLoaded: true,
        };
      }
      return interPaymentObj;
    })
    .catch(err => console.warn(err));
};

export const makePaymentService = ({groupKey, payload}) => {
  const endpoint = `v2/api/payments/for/atc/${groupKey}/payment`;
  return data.post(endpoint, payload);
};

export const getMakePaymentButtonStatus = ({
  groupKey,
  custKey,
  userType,
  selectedCurrency,
}) => {
  const selectedCurrencyInfo = selectedCurrency || 'NotSelected';
  const endpoint = `v2/api/payments/for/${groupKey}/${custKey}/${userType}/${selectedCurrencyInfo}`;
  return data.get(endpoint);
};

export const fetchUserPayments = ({
  userKey,
  groupKey,
  settingKey,
  sortQuery,
  globalFilterQuery,
  pageSize,
  skip,
  sqlQuery,
}) => {
  let endpoint = `v4/api/payments/history/for/${groupKey}/${userKey}/?`;
  const sortJson =
    sortQuery?.length === 0
      ? null
      : {
          ViewSetting: {
            SortSetting: sortQuery.map((item, index) => {
              return {
                SortIndex: index + 1,
                SortCol: item.field,
                SortOrder: item.sort,
              };
            }),
          },
        };
  const payload = {
    countAll: true,
    mode: gridModeTypes.UserPaymentHistory,
    sortJson: sortJson ? JSON.stringify(sortJson) : null,
    settingKey,
    columSetting: '',
    filterJson: globalFilterQuery ? JSON.stringify(globalFilterQuery) : null,
    filterSql: sqlQuery,
    source: 'react',
  };

  if (!isEmpty(skip)) {
    endpoint += `&%24skip=${skip}`;
  }

  if (!isEmpty(pageSize)) {
    endpoint += `&%24top=${pageSize}`;
  }

  return data.post(endpoint, payload);
};

export const exportUserPayments = ({
  groupKey,
  userKey,
  settingKey,
  custKey,
  sqlQuery,
  filterQuery,
  skip,
  pageSize,
}) => {
  let endpoint = `v4/api/export/UserPaymentHistory?`;
  const payload = {
    countAll: true,
    mode: gridModeTypes.UserPaymentHistory,
    settingKey,
    columnSetting: '',
    groupKey,
    userKey,
    custKey,
    filterJson: filterQuery ? JSON.stringify(filterQuery) : null,
    filterSql: sqlQuery,
    source: 'react',
  };

  if (!isEmpty(skip)) {
    endpoint = `${endpoint}&%24skip=${skip}`;
  }

  if (!isEmpty(pageSize)) {
    endpoint += `&%24top=${pageSize}`;
  }

  return downloadBlobFilePostApi(endpoint, payload);
};

export const pendingPaymentsApproveOrReject = (options, groupKey) => {
  const endpoint = `v4/api/payments/for/${groupKey}/paymentsapproval`;
  return data.put(endpoint, options);
};

export const purgePendingPayments = (options, groupKey) => {
  const endpoint = `v4/api/payments/for/${groupKey}/purgependingpayments`;

  return data.post(endpoint, options);
};

export const fetchCompanySettings = ({companyId, groupKey}) => {
  const endpoint = `v4/api/payments/for/${groupKey}/${companyId}/companyinfo`;
  return data.get(endpoint);
};

export const fetchExpectedPayments = ({
  userKey,
  assignedUserKey,
  groupKey,
  isBrokenPromise,
  settingKey,
  sortQuery,
  globalFilterQuery,
  pageSize,
  skip,
  sqlQuery,
}) => {
  let endpoint = `v4/api/payments/for/expectedpayments/${groupKey}?`;
  const sortJson =
    sortQuery?.length === 0
      ? null
      : {
          ViewSetting: {
            SortSetting: sortQuery.map((item, index) => {
              return {
                SortIndex: index + 1,
                SortCol: item.field,
                SortOrder: item.sort,
              };
            }),
          },
        };

  const payload = {
    countAll: true,
    sortJson: sortJson ? JSON.stringify(sortJson) : null,
    settingKey,
    userKey,
    assignedUserKey,
    groupKey,
    getCount: true,
    fetchDays: 1500,
    isReturnCount: true,
    mode: gridModeTypes.ExpectedPayments,
    isBrokenPromise,
    filterJson: globalFilterQuery ? JSON.stringify(globalFilterQuery) : null,
    filterSql: sqlQuery,
    source: 'react',
  };
  if (!isEmpty(skip)) {
    endpoint += `&%24skip=${skip}`;
  }

  if (!isEmpty(pageSize)) {
    endpoint += `&%24top=${pageSize}`;
  }

  return data.post(endpoint, payload);
};

export const exportExpectedPayments = ({
  userKey,
  groupKey,
  settingKey,
  sortQuery,
  globalFilterQuery,
  sqlQuery,
  isBrokenPromise,
  assignedUserKey,
}) => {
  const sortJson =
    sortQuery?.length === 0
      ? null
      : {
          ViewSetting: {
            SortSetting: sortQuery.map((item, index) => {
              return {
                SortIndex: index + 1,
                SortCol: item.field,
                SortOrder: item.sort,
              };
            }),
          },
        };

  const options = {
    countAll: true,
    sortJson: sortJson ? JSON.stringify(sortJson) : null,
    mode: gridModeTypes.ExpectedPayments,
    settingKey,
    userKey,
    groupKey,
    isBrokenPromise,
    filterJson: globalFilterQuery ? JSON.stringify(globalFilterQuery) : null,
    filterSql: sqlQuery,
    source: 'react',
    assignedUserKey,
  };

  const endpoint = `v4/api/export/ExpectedPayments?`;

  return downloadBlobFilePostApi(endpoint, options);
};

export const fetchTaggedInvoices = options => {
  const endpoint = `v4/api/payments/for/taggedinvoices`;

  return data.post(endpoint, options);
};
