import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {useState} from 'react';
import {constants} from '../../../../constants/common';
import {getUserData} from '../../../../store/actions/userActions';
import {useShowCustomerDocs} from '../../../account-overview/hooks/useShowCustomerDocs';
import {downloadInvoice} from '../../../../utils/fileUtils';
import {checkIsTranTypePayment} from '../../../account-overview/statement-of-account/helper';
import ApplicationDetailTemplate from '../../../messages/application-details/ApplicationDetailTemplate';
import {useCustKey} from '../../../../hooks/useCustKey';
import {OpenDocs} from '../../../communicate/open-docs/OpenDocs';
import {Dialog} from '../../../../ui';
import {createHandleSetShowCustomerDocs} from '../../../common/helper';

const ShowCustomerDocs = ({
  invoiceType,
  selectedInvoice,
  showCustomerDocs,
  setShowCustomerDocs,
}: {
  invoiceType: boolean;
  selectedInvoice: any;
  showCustomerDocs: boolean;
  setShowCustomerDocs: (val: boolean) => void;
}) => {
  const groupKey = getUserData()?.groupKey;
  const location = useLocation();
  const {state} = location;
  const {custKey} = useCustKey();
  const {custName, custId} = state || {};
  if (!showCustomerDocs) {
    return null;
  }
  return (
    <OpenDocs
      showCustomerDocs={showCustomerDocs}
      setShowCustomerDocs={setShowCustomerDocs}
      groupKey={groupKey}
      custName={custName}
      custKey={custKey}
      isInvoice={invoiceType}
      selectedInvoice={selectedInvoice}
      custId={custId}
    />
  );
};

export const ViewApplication = ({values}: {values: any}) => {
  const {row} = values;
  const [showApplicationDialog, setShowApplicationDialog] =
    useState<boolean>(false);
  const [customerPmtKey, setCustomerPmtKey] = useState<string>('');
  const [tranType, setTranType] = useState<string>('');
  const [tranId, setTranId] = useState<string>('');

  const toast = useSelector((store: any) => store.generalReducer?.toastRef);
  const groupKey = getUserData()?.groupKey;

  const {
    showCustomerDocs,
    setShowCustomerDocs,
    openDocsProp,
    handleSetShowCustomerDocs,
  } = useShowCustomerDocs();

  const onPDFDownloadIconClick = (columnInvoiceKey?: any) => {
    downloadInvoice(
      groupKey,
      columnInvoiceKey?.invcKey,
      toast,
      undefined,
      true,
    );
  };

  const showApplicationsDialog = (
    tranTypeParam: string,
    customerPmtKeyParam: string,
    tranIdParam: string,
  ) => {
    setShowApplicationDialog(true);
    setTranId(tranIdParam);
    setCustomerPmtKey(customerPmtKeyParam);
    setTranType(tranTypeParam);
  };
  const onApplicationsClick = (rowData: any) => {
    if (rowData.invcCount > 0) {
      showApplicationsDialog(
        rowData.tranType,
        rowData.custPmtKey,
        rowData.tranId,
      );
    }
  };
  const dialogTitle = checkIsTranTypePayment(tranType)
    ? constants.invoiceApplicationForPayment(tranId)
    : constants.applicationDetailsForInvoice(tranId);
  return (
    <>
      <Dialog
        open={
          !!tranId && !!customerPmtKey && !!tranType && showApplicationDialog
        }
        title={dialogTitle}
        onClose={() => {
          setTranId('');
          setCustomerPmtKey('');
          setTranType('');
          setShowApplicationDialog(false);
        }}
        draggable
        maxWidth="md"
        fullWidth
      >
        <ApplicationDetailTemplate
          tranType={tranType}
          custPmtKey={customerPmtKey}
          invcKey={customerPmtKey}
          onPDFDownloadIconClick={onPDFDownloadIconClick}
          handleSetShowCustomerDocs={() =>
            createHandleSetShowCustomerDocs(handleSetShowCustomerDocs)
          }
          checkIsTranTypePayment={checkIsTranTypePayment}
        />
      </Dialog>
      <span
        aria-hidden="true"
        className={`${row.invcCount > 0 && 'blueLink cursor-pointer'}`}
        onClick={() => onApplicationsClick(row)}
      >
        {`${row.invcCount} ${constants.APPLICATIONS}`}{' '}
      </span>
      <ShowCustomerDocs
        invoiceType={openDocsProp?.invoiceType}
        selectedInvoice={openDocsProp?.selectedInvoice}
        showCustomerDocs={showCustomerDocs}
        setShowCustomerDocs={setShowCustomerDocs}
      />
    </>
  );
};
