import dayjs from 'dayjs';
import moment from 'moment';
import {useEffect, useState} from 'react';
import {constants} from '../../../constants/common';
import {formatCurrency, isEmpty} from '../../../lib/utils';
import {Button} from '../../../ui/inputs';
import Checkbox from '../../../ui/inputs/checkbox/Checkbox';
import DatePicker from '../../../ui/inputs/date-picker/DatePicker';
import Select from '../../../ui/inputs/select/Select';
import TextField from '../../../ui/inputs/textfield/TextField';
import {
  DoNotShareWithCreditBureauStyle,
  LockstepCreditScoreLabelStyle,
  LockstepCreditScoreValueStyle,
} from '../helper';
import {editableFieldClassName} from './classNameConstants';
import EditSaveAndCancelTemplate from './EditSaveAndCancelTemplate';
import {Dialog} from '../../../ui';
import IconAlertCircle from '../../images/custom-icons/IconAlertCircle';
import CreditInfoDialog from './CreditInfoDialog';
import {getCreditScoreScale} from '../../../services/customerInfo';
import {getUserData} from '../../../store/actions/userActions';
import {getScoreColorAndDescription} from './customerInfohelper';
import {ColoredSpan} from './ColoredSpan';

function CreditTabColumnsTemplate({
  handleOnCancel,
  isEditMode,
  creditDetails,
  creditClass,
  selectedCreditClass,
  setSelectedCreditClass,
  onEditPaymentMethodClicked,
  onlinePaymentOption,
  setOnlinePaymentOption,
  creditHold,
  setCreditHold,
  nacmDoNotShare,
  setNACMDoNotShare,
  creditReviewDate,
  setCreditReviewDate,
  creditScore,
  setCreditScore,
  handleOnEdit,
  handleSaveCreditDetails,
}) {
  const dateEstablishedValue = () => {
    return isEmpty(creditDetails?.DateEstab)
      ? ''
      : moment.utc(creditDetails?.DateEstab).format('M/D/YYYY');
  };

  const oldestDueDateValue = () => {
    return isEmpty(creditDetails?.OldestDueDate)
      ? ''
      : moment.utc(creditDetails?.OldestDueDate).format('M/D/YYYY');
  };

  const creditReviewMinDate = '1980-01-01';
  const creditReviewMaxDate = '2099-12-31';
  const {groupKey} = getUserData();
  const [creditScoreScaleList, setCreditScoreScaleList] = useState([]);
  const [scoreColorAndDescription, setScoreColorAndDescription] = useState({
    scaleColor: '',
    description: '',
  });
  const [creditReviewDateError, setCreditReviewDateError] = useState(false);
  const [showCreditReviewDateErrorDialog, setShowCreditReviewDateErrorDialog] =
    useState(false);
  const [showCreditScoreDialog, setShowCreditScoreDialog] = useState(false);

  const onlinePaymentOptionsDropdownValues = [
    {value: 0, label: constants.NONE},
    {value: 2, label: constants.EFT_ONLY},
    {value: 1, label: constants.CREDIT_CARD_ACH},
    {value: 3, label: constants.CREDIT_CARD_ONLY},
  ];

  const highestBalanceDate = () => {
    return (
      `${
        creditDetails?.HighBalance
          ? formatCurrency(creditDetails?.HighBalance?.toFixed(2))
          : 0
      } (${
        isEmpty(creditDetails?.HighestBalanceDate)
          ? ''
          : moment.utc(creditDetails?.HighestBalanceDate).format('M/D/YYYY') ||
            null
      })` || ''
    );
  };
  const fetchCreditScoreScale = async () => {
    try {
      const res = await getCreditScoreScale(groupKey);
      setCreditScoreScaleList(res.data);
      setScoreColorAndDescription(
        getScoreColorAndDescription(
          res.data,
          Number(creditDetails?.ATCCreditScore),
        ),
      );
    } catch (error) {
      // Do nothing
    }
  };
  useEffect(() => {
    if (creditDetails?.ATCCreditScore) {
      fetchCreditScoreScale();
    }
  }, [creditDetails?.ATCCreditScore]);

  useEffect(() => {
    setCreditScore(creditDetails?.CreditScore);
    setOnlinePaymentOption(creditDetails?.OnlinePmtOption);
  }, [creditDetails]);

  const onSave = () => {
    if (creditReviewDateError) {
      setShowCreditReviewDateErrorDialog(true);
    } else {
      handleSaveCreditDetails();
    }
  };

  return (
    <>
      <Dialog
        open={showCreditReviewDateErrorDialog}
        title={constants.ERROR}
        onClose={() => setShowCreditReviewDateErrorDialog(false)}
      >
        <div className="p-2">
          <IconAlertCircle height={18} className="me-2" />{' '}
          {constants.dateShouldBeBetween(
            creditReviewMinDate,
            creditReviewMaxDate,
          )}
        </div>
      </Dialog>
      <div className="tab-content-column">
        <div>
          <TextField
            value={dateEstablishedValue()}
            label={constants.DATE_ESTABLISHED}
            disabled
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className={isEditMode ? `p-1 ${editableFieldClassName}` : ''}>
          <Select
            label={constants.CREDIT_CLASS}
            value={
              isEditMode
                ? selectedCreditClass
                : creditDetails?.CreditClassKey || constants.NONE
            }
            shrinkLabel={isEditMode ? undefined : true}
            notched={isEditMode ? undefined : true}
            disabled={!isEditMode}
            items={creditClass}
            onChange={e => {
              setSelectedCreditClass(e.target.value);
            }}
          />
        </div>
        <div>
          <TextField
            value={creditDetails?.AssignedUserName}
            label={constants.ASSIGNED_USER}
            disabled
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div>
          <TextField
            value={formatCurrency(creditDetails?.COCBalance?.toFixed(2))}
            label={constants.COST_OF_CREDIT}
            disabled
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div>
          <TextField
            value={creditDetails?.CurrID}
            label={constants.CURRENCY}
            disabled
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div>
          <TextField
            value={creditDetails?.SalesTerritory}
            label={constants.SALES_TERRITORY}
            disabled
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div>
          <TextField
            value={highestBalanceDate()}
            label={constants.HIGHEST_BALANCE}
            disabled
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      </div>
      <div className="tab-content-column">
        <div>
          <TextField
            value={creditDetails?.PmtTermsID}
            label={constants.TERMS}
            disabled
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div>
          <TextField
            value={creditDetails?.DaysPastDue}
            label={constants.DAYS_PAST_DUE}
            disabled
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div>
          <TextField
            value={formatCurrency(creditDetails?.CreditLimit?.toFixed(2))}
            label={constants.CREDIT_LIMIT}
            disabled
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div>
          <TextField
            value={
              formatCurrency(creditDetails?.AvailableCredit?.toFixed(2)) ||
              parseFloat(0).toFixed(2)
            }
            label={constants.AVAILABLE_CREDIT}
            disabled
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className={isEditMode ? `p-1 ${editableFieldClassName}` : ''}>
          <Select
            disabled={!isEditMode}
            label={constants.ALLOW_ONLINE_PMT}
            value={
              isEditMode
                ? `${onlinePaymentOption}`
                : `${creditDetails?.OnlinePmtOption}`
            }
            shrinkLabel={isEditMode ? undefined : true}
            notched={isEditMode ? undefined : true}
            items={onlinePaymentOptionsDropdownValues}
            onChange={e => {
              setOnlinePaymentOption(e.target.value);
            }}
          />
        </div>
        <div>
          <TextField
            value={oldestDueDateValue()}
            label={constants.OLDEST_DUE_DATE}
            disabled
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className="d-flex align-items-center">
          {!isEditMode && (
            <Button
              variant="contained"
              className={`${!isEditMode && 'm-2'}`}
              onClick={onEditPaymentMethodClicked}
            >
              {constants.EDIT_PAYMENT_METHODS}
            </Button>
          )}
          <div
            className={`d-flex align-items-center ${!isEditMode && 'p-2 m-2'}`}
          >
            <label className="me-2">{constants.CREDIT_HOLD}</label>
            <Checkbox
              disabled
              className="p-1"
              defaultChecked={creditHold}
              onChange={e => setCreditHold(e.target.checked)}
            />
          </div>
        </div>
      </div>
      <div className="tab-content-column">
        <DoNotShareWithCreditBureauStyle className="d-flex align-items-center">
          <label className="me-2">
            {constants.DO_NOT_SHARE_WITH_CREDIT_BUREAU}
          </label>
          <div className={`${isEditMode && editableFieldClassName}`}>
            <Checkbox
              disabled={!isEditMode}
              checked={
                isEditMode ? nacmDoNotShare : creditDetails?.NACMDoNotShare
              }
              onChange={e => {
                setNACMDoNotShare(e.target.checked);
              }}
            />
          </div>
        </DoNotShareWithCreditBureauStyle>
        <div>
          <div className={isEditMode ? `p-1 ${editableFieldClassName}` : ''}>
            <DatePicker
              disabled={!isEditMode}
              label={constants.CREDIT_REVIEW_DATE}
              value={
                isEditMode
                  ? creditReviewDate && dayjs(creditReviewDate)
                  : creditDetails?.CreditReviewDate &&
                    dayjs(creditDetails?.CreditReviewDate)
              }
              minDate={dayjs('1980-01-01')}
              maxDate={dayjs('2099-12-31')}
              onError={e => setCreditReviewDateError(e)}
              onChange={value => {
                setCreditReviewDate(dayjs(value));
              }}
              slotProps={{
                textField: {
                  size: 'small',
                  InputLabelProps: {
                    shrink: isEditMode ? undefined : true,
                  },
                },
              }}
            />
          </div>
        </div>
        <div className={isEditMode ? `p-1 ${editableFieldClassName}` : ''}>
          <TextField
            type="number"
            value={isEditMode ? creditScore : creditDetails?.CreditScore}
            label={constants.CREDIT_SCORE}
            disabled={!isEditMode}
            onChange={e => {
              const inputValue = e.target.value;
              if (inputValue === '' || Number(inputValue) <= 100) {
                setCreditScore(inputValue);
              }
            }}
            InputLabelProps={{
              shrink: !isEditMode || (isEditMode && !isEmpty(creditScore)),
            }}
            InputProps={{inputProps: {min: 0, max: 100}}}
          />
        </div>

        {!isEditMode && (
          <div className="d-flex align-items-center my-4">
            <LockstepCreditScoreLabelStyle className="me-2">
              {constants.LOCKSTEP_CREDIT_SCORE}
            </LockstepCreditScoreLabelStyle>
            <LockstepCreditScoreValueStyle
              aria-hidden="true"
              title={creditDetails?.ATCCreditScore}
              onClick={() => setShowCreditScoreDialog(true)}
            >
              {creditDetails?.ATCCreditScore}
            </LockstepCreditScoreValueStyle>
            <CreditInfoDialog
              infoDialogOpen={showCreditScoreDialog}
              closeInfoDialog={() => setShowCreditScoreDialog(false)}
              score={creditDetails?.ATCCreditScore}
              creditScoreScaleList={creditScoreScaleList}
              scoreColorAndDescription={scoreColorAndDescription}
            />
            <ColoredSpan
              color={scoreColorAndDescription.scaleColor}
              sx={{marginLeft: '8px'}}
            >
              {scoreColorAndDescription.description}
            </ColoredSpan>
          </div>
        )}
      </div>
      <div>
        <EditSaveAndCancelTemplate
          isEditMode={isEditMode}
          onEdit={handleOnEdit}
          onSave={onSave}
          onCancel={handleOnCancel}
        />
      </div>
    </>
  );
}

export default CreditTabColumnsTemplate;
