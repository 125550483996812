import {constants} from '../../../../constants/common';

export const TemplateHeader = () => {
  return (
    <div className="d-flex px-2 py-1 bg-light-grey">
      <div className="px-3 w-50">{constants.TEMPLATE_ID}</div>
      <div className="w-50">{constants.DESCRIPTION}</div>
    </div>
  );
};
