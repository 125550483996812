import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getTemplateDropDownByType} from '../store/actions/activitiesActions';

export const useGetTemplateList = ({type, groupKey}) => {
  const dispatch = useDispatch();
  const {loading: templateLoading, templateDropdown} = useSelector(
    store => store.activitiesReducer,
  );
  useEffect(() => {
    dispatch(getTemplateDropDownByType({type, groupKey}));
  }, [dispatch, type, groupKey]);

  return {templateLoading, templateDropdown};
};
