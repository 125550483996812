import {useContext} from 'react';
import {NavbarContext} from '../components/navbar/NavbarContext';

export const useNavBar = () => {
  const context = useContext(NavbarContext);
  if (context === undefined) {
    throw new Error('useNavBar must be used within a NavBarProvider');
  }
  return context.value;
};
