import {constants} from '../../constants/common';
import {
  fetchContactsService,
  fetchCreditClassService,
  fetchCreditDetailsService,
  fetchHistoryService,
  fetchInfoService,
  fetchNACMService,
  fetchPortalAccessService,
  fetchRecentPaymentsService,
  fetchReportInfoService,
  fetchRolledUpHistoryService,
  fetchTransactionsService,
  saveCreditDetailsService,
  saveInfoService,
} from '../../services/customerInfo';
import {actionTypes} from '../constants/actionTypes';
import {getUserData} from './userActions';

export const fetchPortalAccess =
  ({custKey}) =>
  (dispatch, getState) => {
    const {portalAccess} = getState().customerInfoReducer;

    dispatch({type: actionTypes.FETCHING_PORTAL_ACCESS});
    fetchPortalAccessService(custKey, portalAccess)
      .then(res => {
        const {value, count} = res.data;
        dispatch({
          type: actionTypes.FETCHED_PORTAL_ACCESS,
          portalAccessData: value,
          count,
        });
        return res.data;
      })
      .catch(() => {
        dispatch({type: actionTypes.FETCHING_PORTAL_ACCESS_FAILED});
      });
  };

export const updatePortalAccessSkip = skip => dispatch => {
  dispatch({
    type: actionTypes.UPDATE_PORTAL_ACCESS_SKIP,
    skip,
  });
};

export const updatePortalAccessPageSize = pageSize => dispatch => {
  dispatch({type: actionTypes.UPDATING_PORTAL_ACCESS_PAGE_SIZE});
  dispatch({
    type: actionTypes.UPDATED_PORTAL_ACCESS_PAGE_SIZE,
    pageSize,
  });
};

export const updatePortalAccessSort =
  ({field, direction}) =>
  dispatch => {
    dispatch({
      type: actionTypes.UPDATE_PORTAL_ACCESS_SORT,
      sortBy: field,
      sortDirection: direction,
    });
  };

export const fetchCreditDetails = custKey => dispatch => {
  dispatch({type: actionTypes.FETCHING_CREDIT_DETAILS});
  return fetchCreditDetailsService(custKey)
    .then(result => {
      dispatch({
        type: actionTypes.FETCHED_CREDIT_DETAILS,
        creditDetails: result.data,
      });
    })
    .catch(() => {
      dispatch({type: actionTypes.FETCHING_CREDIT_DETAILS_FAILED});
    });
};

export const fetchInfo = custKey => dispatch => {
  dispatch({type: actionTypes.FETCHING_INFO});
  return fetchInfoService(custKey)
    .then(result => {
      dispatch({
        type: actionTypes.FETCHED_INFO,
        info: result.data,
      });
    })
    .catch(() => {
      dispatch({type: actionTypes.FETCHING_INFO_FAILED});
    });
};

export const fetchHistory = custKey => dispatch => {
  dispatch({type: actionTypes.FETCHING_HISTORY});
  return fetchHistoryService(custKey)
    .then(result => {
      dispatch({
        type: actionTypes.FETCHED_HISTORY,
        history: result.data,
      });
    })
    .catch(() => {
      dispatch({type: actionTypes.FETCHING_HISTORY_FAILED});
    });
};

export const fetchRolledUpHistory = custKey => dispatch => {
  dispatch({type: actionTypes.FETCHING_ROLLED_UP_HISTORY});
  return fetchRolledUpHistoryService(custKey)
    .then(result => {
      dispatch({
        type: actionTypes.FETCHED_ROLLED_UP_HISTORY,
        rolledUpHistory: result.data,
      });
    })
    .catch(() => {
      dispatch({type: actionTypes.FETCHING_ROLLED_UP_HISTORY_FAILED});
    });
};

export const fetchNacm = custKey => dispatch => {
  dispatch({type: actionTypes.FETCHING_NACM});
  return fetchNACMService(custKey)
    .then(result => {
      dispatch({
        type: actionTypes.FETCHED_NACM,
        nacm: result.data,
      });
    })
    .catch(() => {
      dispatch({type: actionTypes.FETCHING_NACM_FAILED});
    });
};

export const fetchTransactions = custKey => dispatch => {
  dispatch({type: actionTypes.FETCHING_TRANSACTIONS});
  return fetchTransactionsService(custKey)
    .then(result => {
      dispatch({
        type: actionTypes.FETCHED_TRANSACTIONS,
        transactions: result.data.value,
      });
    })
    .catch(() => {
      dispatch({type: actionTypes.FETCHING_TRANSACTIONS_FAILED});
    });
};

export const fetchRecentPayments = custKey => dispatch => {
  dispatch({type: actionTypes.FETCHING_RECENT_PAYMENTS});
  return fetchRecentPaymentsService(custKey)
    .then(result => {
      dispatch({
        type: actionTypes.FETCHED_RECENT_PAYMENTS,
        recentPayments: result.data.value,
      });
    })
    .catch(() => {
      dispatch({type: actionTypes.FETCHING_RECENT_PAYMENTS_FAILED});
    });
};

export const fetchCreditClass = groupKey => dispatch => {
  dispatch({type: actionTypes.FETCHING_CREDIT_CLASS});
  return fetchCreditClassService(groupKey)
    .then(({data}) => {
      const updatedKeys = data?.map(({creditClassId, creditClassKey}) => ({
        label: creditClassId,
        value: creditClassKey,
      }));
      dispatch({
        type: actionTypes.FETCHED_CREDIT_CLASS,
        creditClass: updatedKeys,
      });
    })
    .catch(() => {
      dispatch({type: actionTypes.FETCHING_CREDIT_CLASS_FAILED});
    });
};

export const fetchInvoiceForm = groupKey => dispatch => {
  dispatch({type: actionTypes.FETCHING_INVOICE_FORM});
  return fetchReportInfoService(groupKey)
    .then(({data}) => {
      const updatedKeys = data?.map(({reportKey, reportName}) => ({
        label: reportName,
        value: reportKey,
      }));
      const invoiceFormDropdownValues = [
        {
          label: constants.NONE,
          value: constants.NONE,
        },
        ...updatedKeys,
      ];
      dispatch({
        type: actionTypes.FETCHED_INVOICE_FORM,
        invoiceForm: invoiceFormDropdownValues,
      });
    })
    .catch(() => {
      dispatch({type: actionTypes.FETCHING_INVOICE_FORM_FAILED});
    });
};

export const saveCreditDetails = (custKey, payload) => dispatch => {
  const groupKey = getUserData()?.groupKey;
  dispatch({type: actionTypes.SAVING_CREDIT_DETAILS});
  return saveCreditDetailsService(custKey, payload)
    .then(() => {
      dispatch({type: actionTypes.SAVED_CREDIT_DETAILS});
      dispatch(fetchCreditDetails(custKey));
      dispatch(fetchCreditClass(groupKey));
    })
    .catch(() => {
      dispatch({type: actionTypes.SAVING_CREDIT_DETAILS_FAILED});
    });
};
export const saveInfo = (custKey, payload) => dispatch => {
  dispatch({type: actionTypes.SAVING_INFO});
  return saveInfoService(custKey, payload)
    .then(() => {
      dispatch({type: actionTypes.SAVED_INFO});
      dispatch(fetchInfo(custKey));
    })
    .catch(() => {
      dispatch({type: actionTypes.SAVING_INFO_FAILED});
    });
};

export const updateCreditDetailsEditMode = isEditMode => dispatch => {
  dispatch({type: actionTypes.UPDATE_CREDIT_DETAILS_EDIT_MODE, isEditMode});
};

export const updateInfoEditMode = isEditMode => dispatch => {
  dispatch({type: actionTypes.UPDATE_INFO_EDIT_MODE, isEditMode});
};

export const updateContactsEditMode = isEditMode => dispatch => {
  dispatch({type: actionTypes.UPDATE_CONTACTS_EDIT_MODE, isEditMode});
};

export const fetchContacts = (groupKey, custKey) => (dispatch, getState) => {
  const {contacts: contactsState} = getState().customerInfoReducer;
  dispatch({type: actionTypes.FETCHING_CONTACTS});
  return fetchContactsService(groupKey, custKey, contactsState)
    .then(({data}) => {
      let sortedData = [...data].sort((a, b) => {
        if (a?.primaryContactFlag === 1 && b?.primaryContactFlag === 0) {
          return -1;
        }
        if (a?.primaryContactFlag === 0 && b?.primaryContactFlag === 1) {
          return 1;
        }
        return 0;
      });
      // Sort Alphabetically
      if (sortedData[0]?.primaryContactFlag === 1) {
        sortedData = [
          sortedData[0],
          ...sortedData
            .slice(1)
            .sort((a, b) => a?.contactName?.localeCompare(b?.contactName)),
        ];
      } else {
        sortedData = sortedData.sort((a, b) =>
          a?.contactName?.localeCompare(b?.contactName),
        );
      }

      const updatedData = sortedData?.map(d => {
        return {
          ...d,
          selectedContact: false,
        };
      });
      dispatch({
        type: actionTypes.FETCHED_CONTACTS,
        contacts: updatedData,
      });
    })
    .catch(() => {
      dispatch({type: actionTypes.FETCHING_CONTACTS_FAILED});
    });
};

export const updateCustomerInfoContactSkip = contactsSkip => dispatch => {
  dispatch({
    type: actionTypes.UPDATE_CUSTOMER_INFO_CONTACTS_SKIP,
    contactsSkip,
  });
};

export const updateCustomerInfoContactPageSize =
  contactPageSize => dispatch => {
    dispatch({
      type: actionTypes.UPDATE_CUSTOMER_INFO_CONTACTS_PAGE_SIZE,
      contactPageSize,
    });
  };

export const updateContactsDialog =
  ({open, title, content, showCancelButton, showConfirmButton, onConfirm}) =>
  dispatch => {
    dispatch({
      type: actionTypes.UPDATE_CONTACTS_DIALOG,
      open,
      title,
      content,
      showCancelButton,
      showConfirmButton,
      onConfirm,
    });
  };
