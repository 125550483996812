import {actionTypes} from '../constants/actionTypes';

const initialState = {
  followUpData: [],
  followUpDataViewSettings: null,
  fetchingFollowUpData: false,
  fetchingFollowUpDataFailed: false,
  fetchedFollowUpData: false,
  followUpDataLoading: false,
  followUpDataCount: 0,
  exportingToExcel: false,
  exportedToExcel: false,
  exportingToExcelFailed: false,
  followUpSettingId: null,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const followUpReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_FOLLOW_UP:
      return {
        ...state,
        fetchingFollowUpData: true,
        fetchingFollowUpDataFailed: false,
        fetchedFollowUpData: false,
        followUpDataLoading: true,
      };
    case actionTypes.FETCHED_FOLLOW_UP:
      return {
        ...state,
        fetchingFollowUpData: false,
        fetchingFollowUpDataFailed: false,
        fetchedFollowUpData: true,
        followUpData: action.followUpData,
        followUpDataLoading: false,
        followUpDataCount: action.followUpDataCount,
      };
    case actionTypes.FETCHING_FOLLOW_UP_FAILED:
      return {
        ...state,
        fetchingFollowUpData: false,
        fetchingFollowUpDataFailed: true,
        fetchedFollowUpData: true,
        followUpDataLoading: false,
        followUpData: [],
        followUpDataCount: 0,
      };

    case actionTypes.UPDATE_FOLLOW_UP_SETTING_KEY:
      return {
        ...state,
        followUpSettingId: action.followUpSettingId,
      };
    case actionTypes.SET_FOLLOWUP_DASHBOARD_VIEW_SETTING_OBJECT:
      return {
        ...state,
        followUpDataViewSettings: {
          ...action.payload.viewSettings,
          settingsKey: action.payload.settingId,
          sortSetting: null,
        },
        followUpSettingId: action.payload.settingId,
      };
    case actionTypes.CLEAN_UP_FOLLOW_UP:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default followUpReducer;
