import {Button} from '@mui/material';
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid-pro';
import CachedIcon from '@mui/icons-material/Cached';
import SettingsIcon from '@mui/icons-material/Settings';
import ErrorIcon from '@mui/icons-material/Error';
import EditIcon from '@mui/icons-material/Edit';
import SummarizeIcon from '@mui/icons-material/Summarize';
import DescriptionIcon from '@mui/icons-material/Description';
import {FaArrowsAltH} from 'react-icons/fa';
import {constants} from '../../../constants/common';
import {CustomFilterButton} from '../../../ui/data';

export default function CommunicationToolbar({
  onRefresh,
  onEditCommunication,
  exportToExcel,
  applyBestFit,
  onReportClicked,
  onViewSettings,
  handleOpenAlertsPopup,
}: {
  onRefresh: () => void;
  onEditCommunication: () => void;
  exportToExcel: () => void;
  applyBestFit: () => void;
  onReportClicked: () => void;
  onViewSettings: () => void;
  handleOpenAlertsPopup: () => void;
}) {
  return (
    <GridToolbarContainer>
      <CustomFilterButton />
      <GridToolbarColumnsButton />
      <Button startIcon={<DescriptionIcon />} onClick={exportToExcel}>
        {constants.EXPORT_TO_EXCEL}
      </Button>
      <Button startIcon={<CachedIcon />} onClick={onRefresh}>
        {constants.REFRESH}
      </Button>
      <Button startIcon={<FaArrowsAltH />} onClick={applyBestFit}>
        {constants.BEST_FIT}
      </Button>
      <GridToolbarDensitySelector />
      <Button startIcon={<SettingsIcon />} onClick={onViewSettings}>
        {constants.VIEW_SETTINGS}
      </Button>
      <Button startIcon={<ErrorIcon />} onClick={handleOpenAlertsPopup}>
        {constants.ALERT}
      </Button>
      <Button startIcon={<EditIcon />} onClick={onEditCommunication}>
        {constants.EDIT}
      </Button>
      <Button startIcon={<SummarizeIcon />} onClick={onReportClicked}>
        {constants.REPORTS}
      </Button>
    </GridToolbarContainer>
  );
}
