const formatArrayEmailAddresses = value => {
  if (!value) return null;
  if (Array.isArray(value)) {
    return value.join(',').replace(/ /g, '');
  }
  return value;
};

export const formatSaveActivity = ({
  mail,
  customerTaskActivity,
  expectedPayment,
  groupKey,
  userKey,
  newActivity,
  customerTask,
  mailKey,
  taskKey,
  taskActKey,
  IsReply,
  IsReplyAll,
  IsForward,
  custKey,
  ModeType,
  closePriorActivity,
  disputes,
  activityKey,
  customerKeys,
  IsDraftActivity,
  DraftMode,
}) => {
  return {
    CommunicationInfo: {
      FromAddress: mail?.fromAddress,
      ToAddress: formatArrayEmailAddresses(mail?.toAddress),
      CcAddress: formatArrayEmailAddresses(mail?.ccAddress),
      BodyText: mail?.bodyText,
      BodyHTML: mail?.bodyHTML,
      Subject: mail?.subject,
      VoiceMessageTo: mail?.voiceMessageTo,
      VoiceMessageBody: mail?.voiceMessageBody ? mail?.voiceMessageBody : '',
      VoiceMessageStatus: mail?.voiceMessageTo ? 0 : mail?.voiceMessageStatus,
      SmsTo: mail?.smsTo,
      SmsBody: mail?.smsBody,
      SMSStatus: mail?.smsTo ? 0 : mail?.smsStatus,
      IsUrgent: mail?.isUrgent ?? false,
    },
    CustomerTaskActivityInfo: {
      UserKey: customerTaskActivity?.userKey,
      Notes: customerTaskActivity?.notes,
      ToDo: customerTaskActivity?.toDo,
      ToDoDate: customerTaskActivity?.toDoDate,
      ToDoDesc: customerTaskActivity?.toDoDesc,
      ToDoComplete: customerTaskActivity?.toDoComplete,
      ActivityKey: activityKey,
      DaysToRespond: customerTaskActivity?.daysToRespond,
      FollowUpRespondBy: customerTaskActivity?.followUpRespondBy,
    },
    PaymentInfo: {
      PaymentAmount: expectedPayment?.paymentAmount,
      PayByDate: expectedPayment?.payByDate,
      Promised: expectedPayment?.promised,
    },
    WorkSessionID: customerTaskActivity?.workSessionID,
    GroupKey: groupKey,
    IsBulk: customerKeys && customerKeys.length > 0,
    UserKey: userKey,
    IsDraftActivity,
    DraftMode,
    TransactionRequest: {
      workSessionId: customerTaskActivity?.workSessionID,
      updateDispute: false,
      inDispute: false,
      updateResolved: false,
      resolved: false,
      updateExclude: false,
      exclude: false,
      updateDisputeDate: false,
      disputeDate: new Date().toISOString(),
      updateResolvedDate: false,
      resolvedDate: new Date().toISOString(),
      updateReason: false,
      reasonCode: 0,
      userKey,
      groupKey,
      updateDisputeNote: false,
      disputeNote: '',
      taskActivityKey: ModeType === 0 ? -1 : taskActKey,
      communicateDispute: new Date().toISOString(),
      communicateResolvedDate: new Date().toISOString(),
      disputes,
    },
    ModeType,
    AllowZeroBalanceDue: true,
    Description: customerTask?.description,
    SpokeWithCustomer: customerTaskActivity?.successful || false,
    CustomerKeys:
      customerKeys && customerKeys.length > 0 ? customerKeys : [custKey],
    Status: customerTask?.status,
    IsArchived: customerTask?.isArchived,
    ClosePriorActivity: closePriorActivity,
    TaskKey: ModeType === 0 ? -1 : taskKey,
    TaskActKey: ModeType === 0 ? -1 : taskActKey,
    MailKey: mailKey,
    IsReply,
    IsReplyAll,
    IsForward,
    disputes,
  };
};
