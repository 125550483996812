import dayjs from 'dayjs';
import {constants} from '../../../../../constants/common';

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

export const columns = [
  {
    field: 'balance',
    headerName: 'Balance',
    minWidth: 180,
    type: 'decimal',
  },
  {
    field: 'balanceHC',
    headerName: 'Balance HC',
    minWidth: 180,
    type: 'decimal',
  },
  {
    field: 'paymentAmt',
    headerName: 'Payment Amount',
    minWidth: 180,
    type: 'decimal',
  },
  {
    field: 'tranID',
    headerName: 'Invoice',
    minWidth: 180,
    type: 'string',
  },
  {
    field: 'dueDate',
    headerName: 'Due Date',
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 180,
    type: 'date',
  },
];
