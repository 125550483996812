import {constants} from '../../constants/common';
import data from '../../lib/data';
import {decodeURLQueryString} from '../../lib/utils';
import {
  addOrEditPaymentMethodService,
  deletePaymentMethodService,
  getCurrencies,
  getPaymentMethods,
  makePaymentService,
  viewPaymentMethodService,
} from '../../services/payment';
import {PAYMENT_ERROR_CODES} from '../../utils/constants';
import {actionTypes} from '../constants/actionTypes';
import {updateStatementOfAccountAllRowsSelected} from './statementOfAccountActions';

export const updateSelectedCurrency = selectedCurrency => dispatch => {
  dispatch({
    type: actionTypes.UPDATING_SELECTED_CURRENCY,
  });
  setTimeout(() => {
    dispatch({
      type: actionTypes.UPDATE_SELECTED_CURRENCY,
      selectedCurrency,
    });
  }, 100);
};
export const fetchCurrencies = option => dispatch => {
  dispatch({type: actionTypes.FETCHING_CURRENCIES});
  getCurrencies(option)
    .then(res => {
      dispatch({type: actionTypes.FETCHED_CURRENCIES, currencies: res.data});
      if (res.data.length === 1) {
        dispatch(updateSelectedCurrency(res.data[0]));
      }
    })
    .catch(() => {
      dispatch({type: actionTypes.FETCHING_CURRENCIES_FAILED});
    });
};

export const updateRedirectURL = redirectUrl => dispatch => {
  dispatch({type: actionTypes.UPDATING_REDIRECT_URL});
  dispatch({type: actionTypes.UPDATED_REDIRECT_URL, redirectUrl});
};

export const updateSelectedPaymentMethod =
  selectedPaymentMethod => dispatch => {
    dispatch({
      type: actionTypes.UPDATING_SELECTED_PAYMENT_METHOD,
    });
    setTimeout(() => {
      dispatch({
        type: actionTypes.UPDATE_SELECTED_PAYMENT_METHOD,
        selectedPaymentMethod,
      });
    }, 100);
  };

export const updatePaymentInfoSkip = paymentInfoSkip => {
  return {
    type: actionTypes.UPDATE_PAYMENT_INFO_SKIP,
    paymentInfoSkip,
  };
};

export const makePayment = options => dispatch => {
  dispatch({type: actionTypes.MAKING_PAYMENT});
  makePaymentService(options)
    .then(response => {
      const {redirectUrl} = response.data;
      if (response.data.redirectUrl.includes('/payment/failure')) {
        const errorMessage1 = redirectUrl.slice(
          redirectUrl.lastIndexOf('/') + 1,
        );
        const idx = errorMessage1.indexOf('-');
        let errorMessage = errorMessage1;
        if (idx >= 0 && idx < 5) {
          errorMessage = errorMessage1.slice(2);
        }
        let errorCode = '';
        if (errorMessage[0] === PAYMENT_ERROR_CODES.CARD_DECLINED) {
          errorCode = PAYMENT_ERROR_CODES.CARD_DECLINED;
        } else {
          errorCode = PAYMENT_ERROR_CODES.OTHER;
        }
        const paymentErrorObj = {
          paymentErrorCode: errorCode,
          paymentErrorMessage: decodeURLQueryString(errorMessage),
        };
        dispatch({
          type: actionTypes.UPDATE_PAYMENT_ERROR_OBJ,
          paymentErrorObj,
        });
      } else {
        dispatch({
          type: actionTypes.MADE_PAYMENT,
        });
        dispatch(updateStatementOfAccountAllRowsSelected([]));
      }
      dispatch(updateRedirectURL(redirectUrl));
      return response.data;
    })
    .catch(e => {
      dispatch({
        type: actionTypes.MAKE_PAYMENT_FAILED,
        errorMessage: e.response.data,
      });
    });
};

export const addOrUpdatePaymentMethod = (isEdit, options) => dispatch => {
  if (isEdit) {
    dispatch({type: actionTypes.UPDATING_PAYMENT_METHOD});
  } else {
    dispatch({type: actionTypes.ADDING_PAYMENT_METHOD});
  }
  addOrEditPaymentMethodService(isEdit, options)
    .then(response => {
      if (isEdit) {
        dispatch({type: actionTypes.UPDATED_PAYMENT_METHOD});
      } else {
        dispatch({type: actionTypes.ADDED_PAYMENT_METHOD});
        dispatch(updateRedirectURL(response.data.redirectUrl));
      }
      return response.data;
    })
    .catch(e => {
      let dispatchOptions = {
        type: actionTypes.ADDING_PAYMENT_METHOD_FAILED,
      };

      dispatchOptions = {
        ...dispatchOptions,
        errorMessage: e.response.data,
      };
      dispatch(dispatchOptions);
    });
};

export const deletePaymentMethod = options => dispatch => {
  dispatch({type: actionTypes.DELETING_PAYMENT_METHOD});
  deletePaymentMethodService(options)
    .then(response => {
      dispatch({
        type: actionTypes.DELETED_PAYMENT_METHOD,
      });
      return response;
    })
    .catch(e => {
      let dispatchOptions = {
        type: actionTypes.DELETING_PAYMENT_METHOD_FAILED,
      };
      if (e.response.data?.includes(constants.PAYMENT_METHOD_NOT_FOUND)) {
        dispatchOptions = {
          ...dispatchOptions,
          paymentMethodNotFoundError: true,
          paymentMethodNotFoundErrorMessage: e.response.data,
        };
      }
      dispatch(dispatchOptions);
    });
};

export const fetchPaymentMethods = options => dispatch => {
  dispatch({type: actionTypes.FETCHING_PAYMENT_METHODS});
  getPaymentMethods(options)
    .then(res => {
      dispatch({
        type: actionTypes.FETCHED_PAYMENT_METHODS,
        paymentMethods: res.data,
      });
    })
    .catch(() => {
      dispatch({
        type: actionTypes.FETCHING_PAYMENT_METHODS_FAILED,
      });
    });
};

export const fetchPaymentInfo =
  (companyId, withCompanyId) => (dispatch, getState) => {
    const state = getState().statements;
    if (state.isFetchingPaymentInfo) {
      return;
    }

    dispatch({type: actionTypes.FETCHING_PAYMENT_INFO});

    data
      .get(`v2/api/payments/from/${companyId}/to/${withCompanyId}/info`)
      .then(res => {
        dispatch({
          type: actionTypes.FETCHED_PAYMENT_INFO,
          paymentInfo: res.data,
          withCompanyId,
        });
        return res;
      })
      .catch(error => {
        dispatch({type: actionTypes.FETCH_PAYMENT_INFO_FAILED});
        throw error;
      });
  };

export const viewPaymentMethod = options => dispatch => {
  dispatch({type: actionTypes.VIEWING_PAYMENT_INFO});
  viewPaymentMethodService(options)
    .then(res => dispatch(updateRedirectURL(res.data.redirectUrl)))
    .catch(e => {
      let dispatchOptions = {
        type: actionTypes.VIEWING_PAYMENT_INFO_FAILED,
      };

      dispatchOptions = {
        ...dispatchOptions,
        errorMessage: e?.response?.data,
      };
      dispatch(dispatchOptions);
    });
};

export const updatePaymentErrorObject = paymentErrorObj => dispatch => {
  dispatch({type: actionTypes.UPDATE_PAYMENT_ERROR_OBJ, paymentErrorObj});
};

export const updateErrorMessage = errorMessage => dispatch => {
  dispatch({type: actionTypes.UPDATE_ERROR_MESSAGE, errorMessage});
};

export const resetNoPaymentMethodFoundError = () => dispatch => {
  dispatch({
    type: actionTypes.RESET_NO_PAYMENT_METHOD_FOUND_ERROR,
  });
};

export const updatePaymentInfoLoadedPage = paymentInformationLoadedPage => {
  return {
    type: actionTypes.UPDATE_PAYMENT_INFO_LOADED_PAGE,
    paymentInformationLoadedPage,
  };
};

export const updatePaymentInfoCurrentPage = paymentInformationCurrentPage => {
  return {
    type: actionTypes.UPDATE_PAYMENT_INFO_CURRENT_PAGE,
    paymentInformationCurrentPage,
  };
};
