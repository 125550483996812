import {
  Button as MuiButton,
  ButtonProps,
  CircularProgress,
} from '@mui/material';

interface Props extends ButtonProps {
  // Change button text style uppercase | lowercase | capitalize etc
  textTransform?: string;
  /**
   * onClick event
   */
  onClick?: (value: any | null) => void;
  /**
   * loading indicator
   */
  loading?: boolean;
}

/**
 * Button component to display Button.
 * More information available here https://mui.com/material-ui/react-button/
 */

const Button: React.FC<Props> = ({
  onClick,
  textTransform = 'capitalize',
  sx = {},
  loading,
  ...otherProps
}) => {
  return (
    <MuiButton
      onClick={onClick}
      {...otherProps}
      sx={{...sx, textTransform: textTransform as 'capitalize' | 'none'}}
    >
      {loading && (
        <CircularProgress
          color="inherit"
          size={24}
          style={{marginRight: '4px'}}
        />
      )}
      {otherProps.children}
    </MuiButton>
  );
};

export default Button;
