import {useState} from 'react';
import {useIdleTimer as useReactIdleTimer} from 'react-idle-timer';
import {getConfig} from '../lib/utils';

const timeout = getConfig().sessionTimeout;
const promptBeforeIdle = getConfig().promptBeforeIdleTimeout;

export const useIdleTimer = logout => {
  const [open, setOpen] = useState(false);
  const remaining = Math.floor(
    (Number(timeout) - Number(promptBeforeIdle)) / (1000 * 60),
  );

  const onIdle = () => {
    setOpen(false);
    logout();
  };

  const onActive = () => {
    setOpen(false);
  };

  const onPrompt = () => {
    setOpen(true);
  };
  const {activate} = useReactIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
  });

  const onStillActive = () => {
    activate();
  };

  return {showIdleDialog: open, onStillActive, remainingTime: remaining};
};
