import {Checkbox} from '../../../ui/inputs';

export const AddRecipientColumns = onChkChanged => {
  return [
    {
      field: 'selectedRecipient',
      headerName: 'Selected Recipient',
      renderCell: ({row}) => (
        <Checkbox
          checked={row.selectedRecipient}
          onChange={e => {
            onChkChanged(e, 'selectedRecipient', row.id);
          }}
        />
      ),
      width: 150,
      sortable: false,
      type: 'boolean',
    },
    {
      field: 'contactName',
      headerName: 'Contact Name',
      filter: false,
      sortable: false,
      minWidth: 150,
      type: 'string',
    },
    {
      field: 'emailAddr',
      headerName: 'Contact Email',
      sortable: false,
      minWidth: 180,
      type: 'string',
    },
    {
      field: 'includeInEmailYN',
      headerName: 'Include In Email',
      renderCell: ({row}) => (
        <Checkbox
          disabled
          checked={row.includeInEmailYN}
          onChange={e => onChkChanged(e, 'includeInEmail', row.id)}
        />
      ),
      sortable: false,
      type: 'boolean',
    },
    {
      field: 'personal',
      headerName: 'Personal Contact',
      renderCell: ({row}) => (
        <Checkbox
          disabled
          checked={row.personalContact}
          onChange={e => onChkChanged(e, 'personalContact', row.id)}
        />
      ),
      sortable: false,
      type: 'boolean',
    },
  ];
};
