import {Button} from '@mui/material';
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid-pro';
import CachedIcon from '@mui/icons-material/Cached';
import {FaArrowsAltH} from 'react-icons/fa';
import {constants} from '../../../constants/common';

export default function InvoiceLinesToolbar({
  onRefresh,
  applyGridBestFit,
}: {
  onRefresh: any;
  applyGridBestFit: any;
}) {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      <Button startIcon={<CachedIcon />} onClick={onRefresh}>
        {constants.REFRESH}
      </Button>
      <Button startIcon={<FaArrowsAltH />} onClick={applyGridBestFit}>
        {constants.BEST_FIT}
      </Button>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}
