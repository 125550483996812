import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import dayjs from 'dayjs';
import {constants} from '../../constants/common';
import {editAllTaggedInvoice} from '../../store/actions/activitiesActions';
import {ReasonCodeHeader} from '../communicate/reason-code/ReasonCodeHeader';
import {ReasonCodeOptions} from '../communicate/reason-code/ReasonCodeOptions';
import Backdrop from './Backdrop';
import {SelectWithHeader} from './select/select-with-header/SelectWithHeader';
import {Button, Checkbox} from '../../ui/inputs';
import {isEmpty} from '../../lib/utils';
import DateTimePicker from '../../ui/inputs/date-time-picker/DateTimePicker';
import TextField from '../../ui/inputs/textfield/TextField';

function EditAllTaggedInvoicesTemplate({
  handleSetShowEditAllTaggedInvoicesModal = () => {},
  workSessionId,
  userKey,
  groupKey,
  taskActivityKey,
  taskKey,
  custKey,
  onRefresh,
  editedList,
  setActivityEditTransactionsObj,
  modeType,
}) {
  const [editTaggedInvoiceObj, setEditTaggedInvoiceObj] = useState({
    UpdateDispute: false,
    InDispute: '',
    updateResolved: false,
    Resolved: '',
    UpdateExclude: false,
    Exclude: '',
    UpdateDisputeDate: false,
    DisputeDate: '',
    UpdateResolvedDate: false,
    ResolvedDate: '',
    UpdateReason: false,
    ReasonCode: '',
    UpdateDisputeNote: false,
    DisputeNote: '',
  });

  const dispatch = useDispatch();

  const {disputeCodes, loading} = useSelector(store => store.activitiesReducer);

  const onHandleChange = (name, value) => {
    let tempTaggedObj = {...editTaggedInvoiceObj};
    tempTaggedObj = {...tempTaggedObj, [name]: value};
    if (!value) {
      if (name === 'UpdateExclude') {
        tempTaggedObj = {...tempTaggedObj, Exclude: ''};
      }
      if (name === 'UpdateDisputeNote') {
        tempTaggedObj = {...tempTaggedObj, DisputeNote: ''};
      }
      if (name === 'UpdateDispute') {
        tempTaggedObj = {...tempTaggedObj, InDispute: ''};
      }
      if (name === 'UpdateDisputeDate') {
        tempTaggedObj = {...tempTaggedObj, DisputeDate: ''};
      }
      if (name === 'updateResolved') {
        tempTaggedObj = {...tempTaggedObj, Resolved: ''};
      }
      if (name === 'UpdateResolvedDate') {
        tempTaggedObj = {...tempTaggedObj, ResolvedDate: ''};
      }
    }

    setEditTaggedInvoiceObj(tempTaggedObj);
  };

  const onSubmit = () => {
    let request = editTaggedInvoiceObj;
    if (request.Exclude === '') {
      request = {...request, Exclude: false};
    }
    if (request.InDispute === '') {
      request = {...request, InDispute: false};
    }
    if (request.Resolved === '') {
      request = {...request, Resolved: false};
    }
    if (request.DisputeDate === '') {
      request = {...request, DisputeDate: new Date()};
    }
    if (request.ResolvedDate === '') {
      request = {...request, ResolvedDate: new Date()};
    }
    const getAllRequest = {
      WorkSessionID: workSessionId,
      CustKey: custKey,
      TaskKey: taskKey,
      TaskActKey: taskActivityKey,
      RestrictBy: 1,
      RandomNumber: 0,
      ShowAllAccounts: 0,
      AllowZeroBalanceDue: true,
    };
    const disputes = editedList.map(
      ({
        invcKey,
        tranType,
        balanceDue,
        balanceDueHC,
        currExchRate,
        tagged,
        dispute,
        resolved,
        excludeFromAging,
      }) => {
        return {
          invcKey,
          tranType,
          balanceDue,
          balanceDueHC,
          currencyExchangeRate: currExchRate,
          tag: tagged,
          dispute,
          resolved,
          exclude: excludeFromAging,
          disputeCode: editTaggedInvoiceObj.ReasonCode,
        };
      },
    );
    request = {
      ...request,
      workSessionId,
      userKey,
      groupKey,
      taskActivityKey,
      disputes,
      UpdateInvoiceOnly: false,
      ModeType: modeType,
    };
    dispatch(
      editAllTaggedInvoice({
        listRequest: getAllRequest,
        editRequest: request,
        handleSetShowEditAllTaggedInvoicesModal,
        onRefresh,
      }),
    );
    setActivityEditTransactionsObj([]);
  };

  return (
    <>
      {loading && <Backdrop />}
      <div className=" d-flex justify-content-center flex-wrap">
        <div className="light-grey card p-2  mt-1 me-2 w-40">
          <span>{constants.UPDATE}</span>
          <div className=" d-flex flex-column justify-content-around">
            <div className="d-flex justify-content-between align-items-center ">
              <div className="d-flex align-items-center">
                <Checkbox
                  checked={editTaggedInvoiceObj.UpdateReason}
                  onChange={e =>
                    onHandleChange('UpdateReason', e.target.checked)
                  }
                />
                <label className="me-3 ps-2">{constants.REASON}</label>
              </div>
              <SelectWithHeader
                placeholder={constants.SELECT_REASON_CODE}
                options={disputeCodes}
                Header={<ReasonCodeHeader />}
                components={{Option: ReasonCodeOptions}}
                onChange={e => onHandleChange('ReasonCode', e.disputeCodeKey)}
                getOptionLabel={option => option.disputeCode}
                isDisabled={!editTaggedInvoiceObj.UpdateReason}
              />
            </div>
            <div className="mt-2 d-flex align-items-center">
              <div className="d-flex mt-1 align-items-center">
                <Checkbox
                  checked={editTaggedInvoiceObj.UpdateExclude}
                  onChange={e =>
                    onHandleChange('UpdateExclude', e.target.checked)
                  }
                />
                <label className="me-3 ps-2">
                  {constants.EXCLUDE_FROM_AGING}
                </label>
              </div>
              <div className="d-flex align-items-center">
                <div className="me-3 mt-1">
                  <Checkbox
                    name="exclude"
                    checked={editTaggedInvoiceObj.Exclude}
                    disabled={!editTaggedInvoiceObj.UpdateExclude}
                    onChange={e =>
                      onHandleChange('Exclude', e.target.checked ? true : '')
                    }
                  />
                  <span className="ms-1">{constants.YES}</span>
                </div>
                <div className="mt-1">
                  <Checkbox
                    name="exclude"
                    checked={editTaggedInvoiceObj.Exclude === false}
                    disabled={!editTaggedInvoiceObj.UpdateExclude}
                    onChange={e =>
                      onHandleChange('Exclude', e.target.checked ? false : '')
                    }
                  />
                  <span className="ms-1">{constants.NO}</span>
                </div>
              </div>
            </div>
            <div className="mt-2  ">
              <div className="mt-1">
                <Checkbox
                  checked={editTaggedInvoiceObj.UpdateDisputeNote}
                  onChange={e =>
                    onHandleChange('UpdateDisputeNote', e.target.checked)
                  }
                />
                <span className="ms-1">{constants.DISPUTE_NOTE}</span>
              </div>
              <TextField
                fullWidth
                margin="normal"
                label={constants.DISPUTE_NOTE}
                value={editTaggedInvoiceObj.DisputeNote}
                InputProps={{
                  multiline: true,
                  rows: 3,
                }}
                InputLabelProps={
                  !editTaggedInvoiceObj.UpdateDisputeNote && {shrink: true}
                }
                onChange={e => onHandleChange('DisputeNote', e.target.value)}
                disabled={!editTaggedInvoiceObj.UpdateDisputeNote}
              />
              <br />
            </div>
          </div>
        </div>
        <div className="light-grey card p-2  mt-1 me-2 w-40">
          <span>{constants.UPDATE}</span>
          <div className=" d-flex flex-column justify-content-around">
            <div className="d-flex justify-content-between align-items-center ">
              <div className="d-flex align-items-center">
                <Checkbox
                  checked={editTaggedInvoiceObj.UpdateDispute}
                  onChange={e =>
                    onHandleChange('UpdateDispute', e.target.checked)
                  }
                />
                <label className="me-3 ps-2">{constants.IN_DISPUTE}</label>
              </div>
              <div className="d-flex align-items-center">
                <div className="me-3 mt-1">
                  <Checkbox
                    checked={editTaggedInvoiceObj.InDispute}
                    disabled={!editTaggedInvoiceObj.UpdateDispute}
                    onChange={e =>
                      onHandleChange('InDispute', e.target.checked ? true : '')
                    }
                  />
                  <span className="ms-1">{constants.YES}</span>
                </div>
                <div className="mt-1">
                  <Checkbox
                    checked={editTaggedInvoiceObj.InDispute === false}
                    disabled={!editTaggedInvoiceObj.UpdateDispute}
                    onChange={e =>
                      onHandleChange('InDispute', e.target.checked ? false : '')
                    }
                  />
                  <span className="ms-1">{constants.NO}</span>
                </div>
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-between my-2 ">
              <div className="d-flex me-1 align-items-center">
                <Checkbox
                  id="dispute-date"
                  checked={editTaggedInvoiceObj.UpdateDisputeDate}
                  onChange={e =>
                    onHandleChange('UpdateDisputeDate', e.target.checked)
                  }
                />
                <label className="ps-2" htmlFor="dispute-date">
                  {constants.DISPUTE_DATE}
                </label>
              </div>
              <DateTimePicker
                label=""
                orientation="landscape"
                yearsPerRow={3}
                slotProps={{
                  textField: {
                    size: 'small',
                  },
                }}
                disabled={!editTaggedInvoiceObj.UpdateDisputeDate}
                value={
                  !isEmpty(editTaggedInvoiceObj.DisputeDate)
                    ? dayjs.utc(editTaggedInvoiceObj.DisputeDate)
                    : null
                }
                onChange={value => {
                  onHandleChange('DisputeDate', dayjs.utc(value));
                }}
              />
            </div>
          </div>
          <div className=" d-flex flex-column justify-content-around">
            <div className="d-flex justify-content-between align-items-center ">
              <div className="d-flex align-items-center">
                <Checkbox
                  id="dispute-resolved"
                  checked={editTaggedInvoiceObj.updateResolved}
                  onChange={e =>
                    onHandleChange('updateResolved', e.target.checked)
                  }
                />
                <label className="me-3 ps-2" htmlFor="dispute-resolved">
                  {constants.DISPUTE_RESOLVED}
                </label>
              </div>
              <div className="d-flex align-items-center">
                <div className="me-3 mt-1">
                  <Checkbox
                    checked={editTaggedInvoiceObj.Resolved}
                    onChange={e =>
                      onHandleChange('Resolved', e.target.checked ? true : '')
                    }
                    disabled={!editTaggedInvoiceObj.updateResolved}
                  />
                  <span className="ms-1">{constants.YES}</span>
                </div>
                <div className="mt-1">
                  <Checkbox
                    checked={editTaggedInvoiceObj.Resolved === false}
                    onChange={e =>
                      onHandleChange('Resolved', e.target.checked ? false : '')
                    }
                    disabled={!editTaggedInvoiceObj.updateResolved}
                  />
                  <span className="ms-1">{constants.NO}</span>
                </div>
              </div>
            </div>
            <div className="mt-2 d-flex align-items-center">
              <div className="d-flex align-items-center justify-content-between my-2 ">
                <div className="d-flex me-1 align-items-center">
                  <Checkbox
                    id="resolved-date"
                    checked={editTaggedInvoiceObj.UpdateResolvedDate}
                    onChange={e =>
                      onHandleChange('UpdateResolvedDate', e.target.checked)
                    }
                  />
                  <label className="ps-2" htmlFor="resolved-date">
                    {constants.RESOLVED_DATE}
                  </label>
                </div>
                <DateTimePicker
                  label=""
                  orientation="landscape"
                  yearsPerRow={3}
                  slotProps={{
                    textField: {
                      size: 'small',
                    },
                  }}
                  disabled={!editTaggedInvoiceObj.UpdateResolvedDate}
                  value={
                    !isEmpty(editTaggedInvoiceObj.ResolvedDate)
                      ? dayjs.utc(editTaggedInvoiceObj.ResolvedDate)
                      : null
                  }
                  onChange={value => {
                    onHandleChange('ResolvedDate', dayjs.utc(value));
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center mt-3">
        <Button
          variant="contained"
          size="small"
          className="py-2 px-4"
          onClick={onSubmit}
        >
          {constants.UPDATE}
        </Button>
      </div>
    </>
  );
}
export default EditAllTaggedInvoicesTemplate;
