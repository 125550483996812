import {actionTypes} from '../constants/actionTypes';

const initialState = {
  applicationDetailCount: 0,
  applicationDetailNextLink: '',
  applicationDetail: [],
  applicationDetailLoading: false,
  fetchingApplicationDetail: false,
  fetchedApplicationDetail: false,
  fetchingApplicationDetailFailed: false,
  applicationDetailPageSize: 10,
  applicationDetailSkip: 0,
  applicationPaymentList: [],
  applicationDetailTotal: 0,
};

const applicationDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_APPLICATION_DETAIL:
      return {
        ...state,
        applicationDetailLoading: true,
        fetchingApplicationDetail: true,
        fetchedApplicationDetail: false,
        fetchingApplicationDetailFailed: false,
      };
    case actionTypes.FETCHED_APPLICATION_DETAIL:
      return {
        ...state,
        applicationDetailLoading: false,
        fetchingApplicationDetail: false,
        fetchedApplicationDetail: true,
        fetchingApplicationDetailFailed: false,
        applicationDetail: action.applicationDetail,
        applicationDetailCount: action.applicationDetailCount,
        applicationDetailNextLink: action.applicationDetailNextLink,
        applicationDetailSelectedRows: action.applicationDetailSelectedRows,
      };
    case actionTypes.FETCHING_APPLICATION_DETAIL_FAILED:
      return {
        ...state,
        applicationDetailLoading: false,
        fetchingApplicationDetail: false,
        fetchedApplicationDetail: false,
        fetchingApplicationDetailFailed: true,
      };
    case actionTypes.UPDATE_APPLICATION_DETAIL_SKIP:
      return {
        ...state,
        applicationDetailSkip: action.applicationDetailSkip,
      };

    case actionTypes.UPDATING_APPLICATION_DETAIL_PAGE_SIZE:
      return {
        ...state,
        applicationDetailLoading: true,
      };
    case actionTypes.UPDATE_APPLICATION_DETAIL_PAGE_SIZE:
      return {
        ...state,
        applicationDetailPageSize: action.applicationDetailPageSize,
        applicationDetailLoading: false,
      };
    case actionTypes.FETCHED_APPLICATION_DETAIL_PAYMENT: {
      return {
        ...state,
        applicationPaymentList: action.data?.value,
        applicationDetailCount: action.data?.count,
        applicationDetailTotal: action.data?.totalAmount,
        applicationDetailNextLink: action.data?.nextLink,
      };
    }
    default:
      return state;
  }
};
export default applicationDetailReducer;
