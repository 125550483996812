import {Button} from 'primereact';
import React from 'react';
import {constants} from '../../constants/common';

function IssueCompleteTemplate({
  onIssueResolved = () => {},
  onCancel = () => {},
}) {
  return (
    <div className="d-flex">
      <div className="d-flex align-items-center jusify-content-center me-3">
        <i className="pi pi-question-mark-rounded-blue" />
      </div>
      <div className="d-flex  flex-column justify-content-center">
        <span>
          {constants.ISSUE_RESOLVED}. {constants.REOPEN}?
        </span>
        <div className="d-flex my-2">
          <Button className="bg-purple me-3" onClick={() => onIssueResolved()}>
            {constants.OK}
          </Button>
          <Button className="bg-purple" onClick={() => onCancel()}>
            {constants.CANCEL}
          </Button>
        </div>
      </div>
    </div>
  );
}
export default IssueCompleteTemplate;
