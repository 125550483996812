import {ChangeEvent, useEffect, useState} from 'react';
import {
  GridPaginationModel,
  GridSortModel,
  GridColumnVisibilityModel,
  GridRowModesModel,
} from '@mui/x-data-grid';
import {useDispatch, useSelector} from 'react-redux';

import {Button, TextField} from '../../../ui/inputs';
import {Box, Stack} from '../../../ui/layouts';
import {constants, reservedChar} from '../../../constants/common';
import {Typography} from '../../../ui/displays';
import {DataGrid} from '../../../ui/data';
import {AlertsColumns} from './columns';
import {Dialog} from '../../../ui';
import {
  addViewSettingsState,
  deleteAlertsOrScheduleSettingsKey,
  updateViewSettingsState,
} from '../../../store/actions/viewSettingsActions';
import {Snackbar} from '../../../ui/feedback';
import {ISnackbarProps} from '../../../ui/feedback/snackbar/Snackbar';
import {isEmpty} from '../../../lib/utils';
import {useSessionStorage} from '../../../hooks/useSessionStorage';

import {
  columnAlignments,
  handleColumnResize,
  handleDuplicatesInColumnSettings,
} from '../../../lib/commonTableHelpers';
import {PAGE_SIZE} from '../../../utils/constants';

import RenderViewSettingsColumns from '../view-settings/RenderViewSettingsColumns';
import {sortViewSettingsByReactView} from '../view-settings/view-settings-dropdown/viewSettingsHelper';
import {ColumnSetting, IViewSettings} from '../view-settings/interface';
import {createOrUpdateAlerts, processAlerts} from '../../../services/alerts';
import {ICreateOrUpdateAlerts, IProcessEmailDialogProps} from './interface';
import EditAlertsModal from './EditAlertsModal';
import {useQueryKeys} from '../../../hooks/useQueryKeys';
import ScheduleAlertsModal from './ScheduleAlertsModal';
import {IScheduleTask} from '../../inquiries/by-user/scheduled-paymnets/schedulePaymentInterface';

const alertDefaultValues = {
  DEFAULT_SORT_ORDER: 999,
  DEFAULT_ALERT_PRIORITY: 1,
  DEFAULT_IS_ALERT_DISPLAY: false,
  DEFAULT_ALERT_DESCRIPTION: '',
};

const defaultPostProcessDialogObj: IProcessEmailDialogProps = {
  open: false,
  message: '',
  title: constants.SUCCESS,
};

function RenderAlerts({
  viewName,
  loadViewSetting,
  handleCloseAlertsPopup,
  filterSqlText,
  sortSettingJson,
  columnSettingJson,
  updateTableOnColumnSettingsChange,
}: {
  viewName: string;
  loadViewSetting: (row: IViewSettings) => void;
  handleCloseAlertsPopup: () => void;
  filterSqlText: string;
  sortSettingJson: string;
  columnSettingJson: string;
  updateTableOnColumnSettingsChange: (
    updatedColumns: ColumnSetting[],
    settingKey: string,
  ) => void;
}) {
  const [currentViewSettingsName, setCurrentViewSettingsName] =
    useState<string>('');
  const [currentViewSettingsKey, setCurrentViewSettingsKey] =
    useState<string>('');
  const [selectedColumnSettings, setSelectedColumnSettings] = useState<any[]>(
    [],
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: PAGE_SIZE,
  });
  const [{groupKey, userId}] = useSessionStorage('profile');

  const [showEditAlertModal, setShowEditAlertModal] = useState<boolean>(false);
  const [rowForEdit, setRowForEdit] = useState<any>(null);
  const [showScheduleModal, setShowScheduleModal] = useState<boolean>(false);

  const dispatch = useDispatch<any>();
  const {[viewName]: viewSettings, fetchingViewSettings} = useSelector(
    (state: any) => state.viewSettingsReducer,
  );

  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({});

  const [rowModesModel] = useState<GridRowModesModel>({});

  const [currentViewSettingsData, setCurrentViewSettingsData] = useState<
    IViewSettings[]
  >([]);

  const [sortModel, setSortModel] = useState<GridSortModel>([]);

  const [columnWidths, setColumnWidths] = useState<Record<string, number>>({});

  const [viewSettingsNameValidationError, setViewSettingsNameValidationError] =
    useState<boolean>(false);

  const [showColumnsDialog, setShowColumnsDialog] = useState<boolean>(false);

  const [processEmailDialogObj, setProcessEmailDialogObj] =
    useState<IProcessEmailDialogProps>(defaultPostProcessDialogObj);
  const [alertDescription, setAlertDescription] = useState<string>('');

  const {userKey} = useQueryKeys();

  const [scheduleTask, setScheduleTask] = useState<IScheduleTask>({
    scheduleKey: null,
    entity: 'Alert',
    databaseName: null,
    lastRunMessage: null,
    entityKey: '',
    scheduleDescription: `Alert--${alertDescription}`,
    timeZone: '',
    nextRun: '',
    frequency: 1,
    frequencyType: 0,
    startTime: '00:00:00',
    endTime: '23:59:00',
    lastRun: '',
    enabled: true,
  });

  const [confirmDialogProps, setConfirmDialogProps] = useState<{
    open: boolean;
    title: string;
    message: JSX.Element | null;
    dialogType: 'update' | 'delete' | undefined;
    onConfirm: () => void;
    onClose: () => void;
  }>({
    open: false,
    title: 'Are you sure?',
    message: null,
    dialogType: undefined,
    onConfirm: () => {},
    onClose: () => {},
  });

  const [snackbarObj, setSnackbarObj] = useState<ISnackbarProps>({
    message: '',
    onClose: () => {},
    open: false,
    type: 'info',
    title: '',
  });

  const [addedNewAlert, setAddedNewAlert] = useState<boolean>(false);

  const updateSnackbarObj = ({type, title, open, message}: ISnackbarProps) => {
    setSnackbarObj({type, title, open, message, onClose: () => {}});
  };
  const handleCloseSnackbar = () => {
    setSnackbarObj(prev => ({...prev, open: false}));
  };

  const showOnlyAlertsDataFilter = (settingId: string) => {
    return (
      settingId?.charAt(0) === reservedChar.LEADING_RESERVED_CHAR_FOR_ALERTS
    );
  };

  const setViewSettingsGridData = () => {
    const sortedList = sortViewSettingsByReactView(viewSettings).filter(
      ({settingId}) => showOnlyAlertsDataFilter(settingId),
    );
    setCurrentViewSettingsData(sortedList);
  };

  useEffect(() => {
    if (viewSettings) {
      setViewSettingsGridData();
    }
  }, [viewSettings]);

  const onGridColumnValueChanged = (
    field: string,
    value: string | number | boolean,
    settingsKey: string,
  ) => {
    const updatedData = currentViewSettingsData
      .map(row => {
        if (row.settingsKey !== settingsKey) {
          return row;
        }
        return {
          ...row,
          [field]: value,
        };
      })
      .filter(({settingId}) => showOnlyAlertsDataFilter(settingId));
    setCurrentViewSettingsData(updatedData);
  };

  /**
   * Helper function to save current view settings or copy and update view settings
   * API decides it based on availability of settingsKey in payload.
   * @param payload ICreateOrUpdateAlerts
   * @returns void
   */
  const saveOrUpdateAlert = () => {
    let payload: ICreateOrUpdateAlerts = {
      GroupKey: groupKey,
      ViewName: viewName,
      filterSqlText,
      columnSettingJson,
      sortSettingJson,
    };

    if (isEmpty(rowForEdit)) {
      payload = {
        ...payload,
        AlertText: currentViewSettingsName,
        AlertDescription: alertDefaultValues.DEFAULT_ALERT_DESCRIPTION,
        AlertPriority: alertDefaultValues.DEFAULT_ALERT_PRIORITY,
        SortOrder: alertDefaultValues.DEFAULT_SORT_ORDER,
        IsAlertDisplay: alertDefaultValues?.DEFAULT_IS_ALERT_DISPLAY,
      };
    } else {
      payload = {
        ...payload,
        AlertText: rowForEdit.settingId,
        AlertDescription: rowForEdit?.alertDesc,
        AlertPriority: rowForEdit?.alertPriority,
        SortOrder:
          rowForEdit?.sortOrder || alertDefaultValues.DEFAULT_SORT_ORDER,
        IsAlertDisplay: rowForEdit?.show,
        settingsKey: rowForEdit?.settingsKey ?? null,
      };
    }
    setLoading(true);
    setAddedNewAlert(false);
    createOrUpdateAlerts(payload)
      .then(res => {
        const {data} = res;
        if (payload.settingsKey) {
          dispatch(updateViewSettingsState(data, viewName));
          setRowForEdit(null);
          setShowEditAlertModal(false);
          // This is to confirm if new settings added and then close the dialog after edit
          if (addedNewAlert) {
            handleCloseAlertsPopup();
          }
        } else {
          dispatch(addViewSettingsState(data, viewName));
          setRowForEdit(data);
          setShowEditAlertModal(true);
          setAddedNewAlert(true);
        }
        loadViewSetting(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        updateSnackbarObj({
          message: 'Failed to add/update alerts',
          open: true,
          type: 'error',
          title: constants.ERROR,
        });
      });
  };

  const handleRowSave = (row: any) => {
    setRowForEdit(row);
    saveOrUpdateAlert();
  };

  /**
   * Construct payload to current view settings
   * @returns void
   */
  const saveCurrentViewSettings = () => {
    if (
      isEmpty(currentViewSettingsName) ||
      currentViewSettingsName.charAt(0) ===
        reservedChar.LEADING_RESERVED_CHAR_FOR_VIEW_SETTINGS
    ) {
      setViewSettingsNameValidationError(true);
      return;
    }
    if (
      currentViewSettingsName.charAt(0) ===
      reservedChar.LEADING_RESERVED_CHAR_FOR_ALERTS
    ) {
      setViewSettingsNameValidationError(true);
      return;
    }
    saveOrUpdateAlert();
    setCurrentViewSettingsData(
      currentViewSettingsData.filter(({settingId}) =>
        showOnlyAlertsDataFilter(settingId),
      ),
    );
  };

  const onSortChange = (args: GridSortModel) => {
    setSortModel(args);
  };

  const onPageChange = (args: GridPaginationModel) => {
    setPaginationModel({
      pageSize: args.pageSize,
      page: args.pageSize !== paginationModel.pageSize ? 0 : args.page,
    });
  };

  const handleRowForEditChange = (field: string, value: any) => {
    setRowForEdit({...rowForEdit, [field]: value});
  };

  /**
   * Changes the row mode to edit
   * @param row any
   */
  const handleEditClick = (row: any) => {
    setRowForEdit(row);
    setShowEditAlertModal(true);
  };

  const handleScheduleClick = async (row: any) => {
    setAlertDescription(row?.alertDesc);
    setScheduleTask({
      ...scheduleTask,
      scheduleDescription: `Alert--${row?.alertDesc}`,
    });
    setCurrentViewSettingsKey(row.settingsKey);
    setShowScheduleModal(true);
  };

  /**
   * Method called on confirming the delete action.
   * Filters the current view settings data and removes the row with the settingsKey.
   * Dispatches the delete action to the store.
   * On successful deletion, loads the next view settings data.
   * @param settingsKey string
   */
  const onRowDelete = async (settingsKey: string) => {
    let nextItem = null;
    const currentIndex = currentViewSettingsData?.findIndex(
      row => row.settingsKey === settingsKey,
    );
    if (currentIndex !== -1) {
      if (currentIndex === currentViewSettingsData.length - 1) {
        [nextItem] = currentViewSettingsData;
      } else {
        nextItem = currentViewSettingsData[currentIndex + 1];
      }
    }
    setCurrentViewSettingsData(
      currentViewSettingsData
        ?.filter(row => row.settingsKey !== settingsKey)
        .filter(({settingId}) => showOnlyAlertsDataFilter(settingId)),
    );
    const payload = {
      SettingsKey: settingsKey,
      IsAlertDelete: true,
      UserKey: userKey,
      GroupKey: groupKey,
    };
    dispatch(
      deleteAlertsOrScheduleSettingsKey({
        payload,
        updateSnackbarObj,
        viewName,
        type: constants.ALERT,
      }),
    );
    if (nextItem) loadViewSetting(nextItem);
  };

  const calculatePage = (paramData: any[]) => {
    return paramData.slice(
      paginationModel.page * paginationModel.pageSize,
      (paginationModel.page + 1) * paginationModel.pageSize,
    );
  };

  /**
   * Make a call to callback method received as props to load the view settings and close the dialog
   * @param row any
   */
  const handleLoadClick = (row: any) => {
    loadViewSetting(row);
    setRowForEdit(null);
    setShowEditAlertModal(false);
    handleCloseAlertsPopup();
  };

  /**
   * Set current view setting to state and enable columns edit dialog flag to true.
   * Also removes duplicates from column settings created because of legacy.
   * @param row any
   */
  const handleColumnsClick = (row: any) => {
    setCurrentViewSettingsKey(row.settingsKey);
    const columnSettings = [...row.columnSetting];
    const uniqueColumnSettings =
      handleDuplicatesInColumnSettings(columnSettings);
    setSelectedColumnSettings(uniqueColumnSettings);
    setShowColumnsDialog(true);
  };
  const closeConfirmDialogProps = () => {
    setConfirmDialogProps({
      ...confirmDialogProps,
      open: false,
    });
  };

  /**
   * Shows confirmation dialog to delete view settings before proceeding.
   * @param settingsKey string
   * @param settingId string
   */
  const handleDeleteClick = (settingsKey: string, settingId: string) => {
    setConfirmDialogProps({
      open: true,
      title: constants.ARE_YOU_SURE,
      message: (
        <Typography variant="h6">
          {constants.deleteViewSettingConfirm(
            settingId,
            constants.ALERT.toLowerCase(),
          )}
        </Typography>
      ),
      dialogType: 'delete',
      onConfirm: () => {
        onRowDelete(settingsKey);
        closeConfirmDialogProps();
      },
      onClose: () => {
        closeConfirmDialogProps();
      },
    });
  };

  const handleProcessClick = async (settingsKey: string) => {
    const payload = {
      ENTITYKEY: settingsKey,
      USERID: userId,
      ACCOUNTKEY: groupKey,
    };
    setLoading(true);
    try {
      await processAlerts(payload);
      setProcessEmailDialogObj({
        open: true,
        message: 'Emails Successfully Queued',
        title: constants.SUCCESS,
      });
    } catch (error) {
      setProcessEmailDialogObj({
        open: false,
        message: 'Failed To Add Emails in Queue',
        title: constants.ERROR,
      });
    }
    setLoading(false);
  };

  const handleEditAlertScheduleModalClose = () => {
    setShowScheduleModal(false);
    setScheduleTask({
      scheduleKey: null,
      entity: 'Alert',
      databaseName: null,
      lastRunMessage: null,
      entityKey: '',
      scheduleDescription: `Alert--`,
      timeZone: '',
      nextRun: '',
      frequency: 1,
      frequencyType: 0,
      startTime: '00:00:00',
      endTime: '23:59:00',
      lastRun: '00:00:00',
      enabled: true,
    });
  };

  const adjustedColumns = AlertsColumns({
    handleEditClick,
    columnAlignments,
    handleLoadClick,
    handleColumnsClick,
    handleRowSave,
    handleDeleteClick,
    handleProcessClick,
    handleScheduleClick,
    onGridColumnValueChanged,
  }).map(
    (column: {
      field: string;
      width: number;
      [key: string]: any; // for any other properties that might be in the column objects
    }) => ({
      ...column,
      width: columnWidths[column.field] || column.width,
    }),
  );

  function getHelperText() {
    if (viewSettingsNameValidationError) {
      if (isEmpty(currentViewSettingsName)) {
        return constants.SETTING_NAME_IS_REQUIRED;
      }
      if (
        currentViewSettingsName.charAt(0) ===
        reservedChar.LEADING_RESERVED_CHAR_FOR_ALERTS
      ) {
        return constants.LEADING_TILDE_RESERVED_FOR_ALERT_NAMES;
      }
      if (
        currentViewSettingsName.charAt(0) ===
        reservedChar.LEADING_RESERVED_CHAR_FOR_VIEW_SETTINGS
      ) {
        return constants.LEADING_TILDE_RESERVED_FOR_SYSTEM_SETTING_NAMES;
      }
    }
    return '';
  }

  const paginatedData = calculatePage(currentViewSettingsData).filter(setting =>
    showOnlyAlertsDataFilter(setting.settingId),
  );

  const rowCount = currentViewSettingsData.length;

  return (
    <Box padding={2}>
      <Snackbar
        open={snackbarObj.open}
        onClose={handleCloseSnackbar}
        message={snackbarObj.message}
        title={snackbarObj.title}
        type={snackbarObj.type}
      />
      <Dialog
        open={showColumnsDialog}
        title={constants.VIEW_COLUMN_SETTINGS}
        onClose={() => {
          setCurrentViewSettingsKey('');
          setShowColumnsDialog(false);
        }}
        maxWidth="sm"
      >
        <RenderViewSettingsColumns
          settingsKey={currentViewSettingsKey}
          columnSettings={selectedColumnSettings}
          onClose={() => {
            setShowColumnsDialog(false);
          }}
          updateParentSnackbarObj={updateSnackbarObj}
          updateTableOnColumnSettingsChange={updateTableOnColumnSettingsChange}
          viewName={viewName}
        />
      </Dialog>

      <Dialog
        showCancelButton
        showConfirmButton
        title="Are you sure?"
        open={confirmDialogProps.open}
        onClose={confirmDialogProps.onClose}
        onConfirm={confirmDialogProps.onConfirm}
        maxWidth="xs"
        fullWidth
      >
        {confirmDialogProps.message}
      </Dialog>
      <Dialog
        title={constants.SUCCESS}
        open={processEmailDialogObj.open}
        onClose={() => {
          setProcessEmailDialogObj({
            open: false,
            message: '',
            title: constants.SUCCESS,
          });
        }}
        maxWidth="xs"
        fullWidth
      >
        {processEmailDialogObj.message}
      </Dialog>

      {rowForEdit?.settingsKey && (
        <EditAlertsModal
          showEditAlertModal={showEditAlertModal}
          rowForEdit={rowForEdit}
          handleRowForEditChange={handleRowForEditChange}
          onEditAlertModalClose={() => {
            setShowEditAlertModal(false);
            setRowForEdit(null);
          }}
          loading={loading}
          saveOrUpdateAlert={saveOrUpdateAlert}
        />
      )}

      <ScheduleAlertsModal
        showScheduleModal={showScheduleModal}
        currentViewSettingsKey={currentViewSettingsKey}
        scheduleTask={scheduleTask}
        setScheduleTask={setScheduleTask}
        onEditAlertScheduleModalClose={handleEditAlertScheduleModalClose}
      />

      <Stack
        sx={{
          background: '#fafbfc',
        }}
        direction="row"
        marginBottom={2}
        padding={1}
        borderRadius={2}
        alignItems="center"
      >
        <TextField
          label={constants.SAVE_CURRENT_SETTINGS_AS}
          value={currentViewSettingsName}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setCurrentViewSettingsName(e.target.value);
            setViewSettingsNameValidationError(false);
          }}
          sx={{minWidth: 300}}
          error={viewSettingsNameValidationError}
          helperText={getHelperText()}
          margin="none"
          fullWidth={false}
        />
        <Button
          variant="contained"
          onClick={saveCurrentViewSettings}
          sx={{alignSelf: 'center'}}
        >
          {constants.SAVE}
        </Button>
      </Stack>
      <Stack
        direction="column"
        sx={{background: '#fafbfc', padding: 1, marginY: 2, borderRadius: 2}}
      >
        <Stack>
          <Typography variant="h6" fontWeight={800}>
            {constants.ALERTS.toUpperCase()}
          </Typography>
        </Stack>

        <DataGrid
          data-testid="alerts-grid"
          disableVirtualization
          columns={adjustedColumns}
          onColumnResize={params =>
            handleColumnResize(params, setColumnWidths, columnWidths)
          }
          rowCount={rowCount}
          rows={paginatedData}
          loading={loading || fetchingViewSettings}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityChange={visibleData =>
            setColumnVisibilityModel(visibleData)
          }
          disableColumnFilter
          onSortChange={onSortChange}
          onPageChange={onPageChange}
          sortModel={sortModel}
          disableMultipleRowSelection
          disableColumnMenu
          paginationModel={paginationModel}
          editMode="row"
          rowSelection={false}
          rowModesModel={rowModesModel}
          disableColumnReorder
          disableColumnResize
          height={400}
          getRowId={row => row.settingsKey}
        />
      </Stack>
    </Box>
  );
}

export default RenderAlerts;
