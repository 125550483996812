import {useEffect, useState} from 'react';
import {Tabs as MuiTabs, Tab as MuiTab, TabsProps, Box} from '@mui/material';

export interface ITab {
  /**
   * Tab label
   */
  label: string;
  /**
   * Tab panel content
   */
  content: React.ReactNode;
  /**
   * Tab Disabled
   */
  disabled?: boolean;
}

interface Props extends TabsProps {
  /**
   * List of tabs
   */
  tabs: ITab[];
  /**
   * Initial active tab
   */
  initialTab?: number;
  /**
   * Callback on tab change
   */
  onTabChange?: (index: number) => void;
}

/**
 * Tabs  component .
 * More information available here https://mui.com/material-ui/react-tabs
 */

const Tabs: React.FC<Props> = ({
  tabs,
  initialTab = 0,
  onTabChange = () => {},
  ...otherProps
}) => {
  const [activeTab, setActiveTab] = useState<number>(initialTab);

  useEffect(() => {
    setActiveTab(initialTab);
  }, [initialTab]);

  const handleTabChange = (event: React.SyntheticEvent, index: number) => {
    setActiveTab(index);
    onTabChange(index);
  };

  return (
    <Box sx={{width: '100%'}}>
      <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
        <MuiTabs
          className="tabs-header"
          value={activeTab}
          onChange={handleTabChange}
          {...otherProps}
        >
          {tabs.map(tab => (
            <MuiTab
              label={tab.label}
              key={tab.label}
              disabled={tab?.disabled || false}
            />
          ))}
        </MuiTabs>
      </Box>
      <div className="mui-tabs-content">{tabs[activeTab].content}</div>
    </Box>
  );
};

export default Tabs;
