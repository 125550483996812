import moment from 'moment';
import {Checkbox} from '../../../../../ui/inputs';

export const transactionsColumns = () => {
  return [
    {
      field: 'TranID',
      header: 'Invoice',
      body: rowData => <span aria-hidden="true">{rowData?.TranID}</span>,
    },
    {
      field: 'TranDate',
      header: 'Tran date',
      body: rowData => (
        <span>
          {rowData.TranDate
            ? moment.utc(rowData?.TranDate).format('M/D/YYYY')
            : ''}
        </span>
      ),
    },
    {
      field: 'DueDate',
      header: 'Due Date',
      body: rowData => (
        <span>
          {rowData.DueDate
            ? moment.utc(rowData?.DueDate).format('M/D/YYYY')
            : ''}
        </span>
      ),
    },
    {
      field: 'TranAmt',
      header: 'Amount',
      align: 'right',
      headerStyle: {minWidth: '5rem'},
      body: rowData => <span>{rowData.TranAmt?.toFixed(2)}</span>,
    },
    {
      field: 'Balance',
      header: 'Balance',
      align: 'right',
      headerStyle: {minWidth: '100px'},
      body: rowData => <span>{rowData.Balance?.toFixed(2)}</span>,
    },
    {
      field: 'DueDateDays',
      header: 'Days Overdue',
      align: 'right',
      headerStyle: {minWidth: '100px'},
      body: rowData => <span>{rowData.DueDateDays}</span>,
    },
    {
      field: 'paySched',
      header: 'Pay Sched',
      align: 'center',
      body: rowData => (
        <Checkbox
          disabled
          checked={rowData.PaySched}
          onClick={e => e.preventDefault()}
        />
      ),
    },
  ];
};

export const transactionsRecentPaymentsColumns = () => {
  return [
    {
      field: 'TranID',
      header: 'Ref#',
      body: rowData => <span aria-hidden="true">{rowData?.TranID}</span>,
    },
    {
      field: 'TranDate',
      header: 'Tran Date',
      body: rowData => (
        <span>
          {rowData.TranDate
            ? moment.utc(rowData?.TranDate).format('M/DD/YYYY')
            : ''}
        </span>
      ),
    },
    {
      field: 'TranAmt',
      header: 'Amount',
      align: 'right',
      body: rowData => <span>{rowData.TranAmt?.toFixed(2)}</span>,
    },
  ];
};
