import {useDispatch, useSelector} from 'react-redux';
import {useState} from 'react';
import {ViewSettingsDropDown} from '../../common/view-settings/view-settings-dropdown/ViewSettingsDropDown';
import {
  changeMailStatus,
  handleDeleteAllSelected,
  updateMailBoxViewSettingObject,
} from '../../../store/actions/mailboxActions';
import {constants, gridModeTypes} from '../../../constants/common';
import {getUserData} from '../../../store/actions/userActions';
import {Button} from '../../../ui/inputs';
import {Dialog} from '../../../ui';
import EmailTemplate from '../EmailTemplate';
import RenderMailboxDropdown from '../RenderMailboxDropdown';
import {Box} from '../../../ui/layouts';

export const ViewSettings = ({
  handleOnDelete,
  isMailNotFiled,
}: {
  handleOnDelete: any;
  isMailNotFiled: boolean;
}) => {
  const {
    settingId,
    viewName,
    mailboxSelectedRows,
    userOrMail,
    sortQuery,
    viewSettings,
  } = useSelector((store: any) => store.mailboxReducer);
  const [groupKey] = useState(getUserData()?.groupKey);
  const [showSelectionRequired, setShowSelectionRequired] = useState(false);
  const [showEmailTemplate, setShowEmailTemplate] = useState<boolean>(false);
  const dispatch = useDispatch();

  return (
    <div>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginTop="12px"
      >
        <div className="width-250-px">
          <ViewSettingsDropDown
            settingId={settingId}
            viewName={viewName}
            onViewSettingSelected={(selectedSettingId, selectedViewSettings) =>
              dispatch(
                updateMailBoxViewSettingObject({
                  settingId: selectedSettingId,
                  viewSettings: selectedViewSettings,
                }),
              )
            }
            activityType={gridModeTypes.MailNotFiledActivities}
            currentViewSettings={viewSettings}
            isQueryParamDefault
          />
        </div>
        <Box display="flex" gap={1}>
          <Button
            aria-hidden="true"
            variant="contained"
            size="medium"
            sx={{alignSelf: 'start'}}
            onClick={() => {
              if (mailboxSelectedRows.length === 0) {
                setShowSelectionRequired(true);
              } else {
                dispatch(
                  // @ts-ignore
                  changeMailStatus(mailboxSelectedRows, groupKey, sortQuery),
                );
              }
            }}
          >
            {constants.MARK_ALL_SELECTED_AS_READ_UNREAD}
          </Button>

          <Button
            variant="contained"
            size="medium"
            sx={{alignSelf: 'start'}}
            onClick={() => {
              if (mailboxSelectedRows.length === 0) {
                setShowSelectionRequired(true);
              } else {
                handleOnDelete(true);
                const mailKeys = mailboxSelectedRows?.map(
                  ({mailKey}: {mailKey: number}) => mailKey,
                );
                // @ts-ignore
                dispatch(handleDeleteAllSelected({mailKeys}));
              }
            }}
          >
            {constants.DELETE_ALL_SELECTED}
          </Button>

          {userOrMail === '1' && (
            <Button
              sx={{alignSelf: 'start'}}
              variant="contained"
              size="medium"
              onClick={() => {
                if (mailboxSelectedRows.length === 0) {
                  setShowSelectionRequired(true);
                } else {
                  setShowEmailTemplate(true);
                }
              }}
            >
              {constants.ASSIGN_ALL_SELECTED}
            </Button>
          )}
        </Box>
        <Dialog
          title={constants.SELECTION_REQUIRED}
          open={showSelectionRequired}
          onClose={() => setShowSelectionRequired(false)}
        >
          <div className="d-flex align-items-center">
            <i className="pi pi-warning me-3" /> {constants.SELECTION_REQUIRED}
          </div>
        </Dialog>
        <Dialog
          title={constants.ASSIGN_MESSAGES}
          open={showEmailTemplate}
          onClose={() => setShowEmailTemplate(false)}
          maxWidth="md"
          fullWidth
        >
          <EmailTemplate
            selectedRows={mailboxSelectedRows}
            isAssignMessagesTemplate
            isAssignAllSelected
            onReload={() => {}}
            navigateBack={() => setShowEmailTemplate(false)}
          />
        </Dialog>
      </Box>
      <RenderMailboxDropdown isMailNotFiled={isMailNotFiled} />
    </div>
  );
};
