import {styled} from '@mui/material';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {constants} from '../../constants/common';
import {isEmpty} from '../../lib/utils';
import {
  addOrUpdatePaymentMethod,
  deletePaymentMethod,
  fetchPaymentMethods,
  resetNoPaymentMethodFoundError,
  updatePaymentInfoCurrentPage,
  updatePaymentInfoLoadedPage,
  updateRedirectURL,
  viewPaymentMethod,
} from '../../store/actions/paymentsCurrenciesActions';
import Dialog from '../../ui/feedback/dialog/Dialog';
import {Button} from '../../ui/inputs';
import {DEFAULT_PAYMENT_METHOD} from '../../utils/constants';
import {buildPage} from '../../utils/pagingUtils';
import AddEditPaymentMethodTemplate from '../account-overview/AddEditPaymentMethodTemplate';
import RenderRedirectUrlTemplate from '../account-overview/RenderRedirectUrlTemplate';
import MainLoader from '../common/MainLoader';
import {paymentInfoColumns} from '../common/table/columns/paymentInfoColumns';
import CustomDataTable from '../common/table/CustomDataTable';
import {useSessionStorage} from '../../hooks/useSessionStorage';
import {useQueryKeys} from '../../hooks/useQueryKeys';

function EditPaymentMethods() {
  const {
    paymentMethods,
    fetchingPaymentMethods,
    deletedPaymentMethods,
    addedPaymentMethod,
    redirectUrl,
    updatedPaymentMethod,
    deletingPaymentMethod,
    showLoader,
    paymentMethodNotFoundError,
    paymentMethodNotFoundErrorMessage,
    paymentInformationLoadedPage,
    paymentInformationCurrentPage,
  } = useSelector(store => store.paymentsCurrenciesReducer);
  const [isEdit, setIsEdit] = useState(false);
  const [isDefaultMethod, setIsDefaultMethod] = useState(false);
  const [paymentInfoSkip, setPaymentInfoSkip] = useState(0);
  const [paymentInfoPageSize, setPaymentInfoPageSize] = useState(10);
  const [paymentMethodForEdit, setPaymentMethodForEdit] = useState();
  const [paymentMethod, setPaymentMethod] = useState('');
  const [paymentMethodsObj, setPaymentMethodsObj] = useState([]);
  const [showAddEditPaymentMethodForm, setShowAddEditPaymentMethodForm] =
    useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isRequireFieldErrorMessage, setIsRequireFieldErrorMessage] =
    useState(false);
  const [showDeletedPaymentMethodDialog, setShowDeletedPaymentMethodDialog] =
    useState(false);

  const dispatch = useDispatch();

  const [{groupKey}] = useSessionStorage('profile');
  const {custKey} = useQueryKeys();

  const IframeLoaderWrapper = styled('div')(() => ({
    minHeight: '500px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));

  const onReload = () => setRefresh(true);
  const onHideIframe = () => {
    dispatch(updateRedirectURL(''));
    onReload();
  };

  const onResetNoPaymentMethodFoundError = () => {
    dispatch(resetNoPaymentMethodFoundError(false));
  };

  const onEditClick = rowData => {
    if (rowData.defaultMethod === DEFAULT_PAYMENT_METHOD) {
      setIsDefaultMethod(true);
    } else {
      setIsDefaultMethod(false);
    }
    setPaymentMethod(rowData.pmtMethodID);
    setPaymentMethodForEdit(rowData);
    setIsEdit(true);
    setShowAddEditPaymentMethodForm(true);
  };

  const onAddNewRecordClick = () => {
    setIsEdit(false);
    setPaymentMethod('');
    setIsDefaultMethod(false);
    setShowAddEditPaymentMethodForm(true);
    setPaymentMethodForEdit({});
  };

  const onPaymentInfoClick = pmtMethodKey => {
    dispatch(
      viewPaymentMethod({
        groupKey,
        custKey,
        selectedPaymentMethodKey: pmtMethodKey,
      }),
    );
  };

  const handleDeletePaymentMethod = pmtMethodKey =>
    dispatch(deletePaymentMethod({groupKey, custKey, pmtMethodKey}));

  const onEditOrAddClose = () => {
    setIsEdit(false);
    setShowAddEditPaymentMethodForm(false);
    setPaymentMethodForEdit({});
  };

  const handleUpdateCurrentPage = page => {
    dispatch(updatePaymentInfoCurrentPage(page));
  };

  const handleUpdateLoadedPage = page => {
    dispatch(updatePaymentInfoLoadedPage(page));
  };

  const onPaymentInfoLoadMore = pageToLoad => {
    const skip = pageToLoad * paymentInfoPageSize - paymentInfoPageSize;
    handleUpdateLoadedPage(pageToLoad);
    handleUpdateCurrentPage(pageToLoad);
    setPaymentInfoSkip(skip);
  };

  const updatePageRowCount = pageSize => {
    setPaymentInfoPageSize(pageSize);
  };

  const onPaymentMethodChange = ({target: {value}}) => {
    setIsRequireFieldErrorMessage(false);
    setPaymentMethod(value);
  };

  const onAddEditPaymentMethodClick = () => {
    let options = {
      custKey,
      groupKey,
      IsDefault: isDefaultMethod,
      PaymentMethodId: paymentMethod,
    };
    if (isEdit) {
      options = {
        ...options,
        selectedPaymentMethodKey: paymentMethodForEdit.pmtMethodKey,
      };
    }
    if (isEmpty(paymentMethod)) {
      setIsRequireFieldErrorMessage(true);
    } else {
      setShowAddEditPaymentMethodForm(false);
      dispatch(addOrUpdatePaymentMethod(isEdit, options));
    }
  };

  useEffect(() => {
    const resultArray = buildPage(
      paymentInformationLoadedPage,
      paymentInfoPageSize,
      paymentMethods,
    );
    setPaymentMethodsObj(resultArray);
  }, [paymentInfoPageSize, paymentInfoSkip, paymentMethods]);

  useEffect(() => {
    if (deletedPaymentMethods) {
      setShowDeletedPaymentMethodDialog(true);
    }
    if (!refresh || deletedPaymentMethods) {
      dispatch(fetchPaymentMethods({groupKey, customerKey: custKey}));
    }
  }, [
    custKey,
    deletedPaymentMethods,
    addedPaymentMethod,
    updatedPaymentMethod,
  ]);

  useEffect(() => {
    if (refresh) {
      dispatch(fetchPaymentMethods({groupKey, customerKey: custKey}));
      setRefresh(false);
    }
  }, [refresh]);

  return (
    <div className="card p-2">
      <Dialog
        open={paymentMethodNotFoundError}
        title={constants.INFO}
        onClose={onResetNoPaymentMethodFoundError}
      >
        <div className="d-flex">
          <i className="pi pi-alert me-3" /> {paymentMethodNotFoundErrorMessage}
        </div>
      </Dialog>
      <Dialog
        open={showLoader || (!showLoader && !isEmpty(redirectUrl))}
        title={constants.PAYMENT_INFO}
        onClose={() => {
          onHideIframe();
        }}
        maxWidth="md"
        fullWidth
      >
        {showLoader ? (
          <IframeLoaderWrapper>
            <MainLoader />
          </IframeLoaderWrapper>
        ) : (
          <RenderRedirectUrlTemplate redirectUrl={redirectUrl} />
        )}
      </Dialog>
      <Dialog
        open={showDeletedPaymentMethodDialog}
        title={constants.PAYMENT_INFO}
        onClose={() => {
          setShowDeletedPaymentMethodDialog(false);
        }}
      >
        <div className="p-2">
          {constants.PAYMENT_METHOD_DELETED_SUCCESSFULLY}
        </div>
      </Dialog>
      <div className="d-flex justify-content-between w-100 margin-bottom-5-px">
        <Button
          startIcon={<i className="pi pi-plus margin-right-5-px" />}
          variant="contained"
          onClick={() => {
            onAddNewRecordClick();
          }}
        >
          {constants.ADD_NEW_RECORD}
        </Button>
        <Button
          startIcon={<i className="pi pi-refresh margin-right-5-px" />}
          variant="contained"
          onClick={onReload}
        >
          {constants.REFRESH}
        </Button>
      </div>
      {showAddEditPaymentMethodForm && (
        <AddEditPaymentMethodTemplate
          isEdit={isEdit}
          isDefaultMethod={isDefaultMethod}
          setIsDefaultMethod={setIsDefaultMethod}
          paymentMethodForEdit={paymentMethodForEdit}
          paymentMethod={paymentMethod}
          onPaymentMethodChange={onPaymentMethodChange}
          onAddEditPaymentMethodClick={onAddEditPaymentMethodClick}
          isRequireFieldErrorMessage={isRequireFieldErrorMessage}
          setIsRequireFieldErrorMessage={setIsRequireFieldErrorMessage}
          onEditOrAddClose={onEditOrAddClose}
        />
      )}

      <CustomDataTable
        columns={paymentInfoColumns({
          handleDeletePaymentMethod,
          onEditClick,
          deletingPaymentMethod,
          onPaymentInfoClick,
        })}
        header={false}
        selectionMode={false}
        selectionModeType="single"
        selection
        pageRowCount={paymentInfoPageSize}
        totalRecords={paymentMethods?.length || 0}
        loading={fetchingPaymentMethods}
        setCurrentPage={handleUpdateCurrentPage}
        setLoadedPage={handleUpdateLoadedPage}
        loadedPage={paymentInformationLoadedPage}
        currentPage={paymentInformationCurrentPage}
        skip={paymentInfoSkip}
        onLoadMore={onPaymentInfoLoadMore}
        selectedRows={paymentMethodForEdit}
        updatePageRowCount={updatePageRowCount}
        tableData={paymentMethodsObj}
      />
    </div>
  );
}

export default EditPaymentMethods;
