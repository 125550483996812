import {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  useMemo,
} from 'react';

interface FilterPopoverContextType {
  anchorEl: null | HTMLElement;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLElement>>;
}

const defaultContext: FilterPopoverContextType = {
  anchorEl: null,
  setAnchorEl: () => {},
};

const FilterPopoverContext = createContext<
  FilterPopoverContextType | undefined
>(defaultContext);

export const useFilterPopoverContext = () => {
  const context = useContext(FilterPopoverContext);
  if (!context) {
    return defaultContext;
  }
  return context;
};

export const FilterPopoverProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const value = useMemo(
    () => ({anchorEl, setAnchorEl}),
    [anchorEl, setAnchorEl],
  );

  return (
    <FilterPopoverContext.Provider value={value}>
      {children}
    </FilterPopoverContext.Provider>
  );
};

export default FilterPopoverContext;
