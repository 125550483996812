import {constants, fileNames} from '../../constants/common';
import {
  exportPendingPaymentDetails,
  fetchPendingPaymentDetails,
} from '../../services/pendingPayment';
import {downloadExcel} from '../../utils/fileUtils';
import {actionTypes} from '../constants/actionTypes';

export const getPendingPaymentsDetails = options => (dispatch, getState) => {
  const toast = getState().generalReducer?.toastRef;
  const pendingPaymentDetailsState = getState().pendingPaymentDetailsReducer;
  const {
    userKey,
    groupKey,
    settingKey,
    sortQuery,
    filterQuery,
    pageSize,
    skip,
    isRestricted,
    mode,
    sqlQuery,
  } = options;
  const filterQueryVal =
    settingKey ===
    pendingPaymentDetailsState?.pendingPaymentDetailsViewSettings?.settingsKey
      ? filterQuery
      : '';

  dispatch({type: actionTypes.FETCHING_PENDING_PAYMENTS_DETAILS});
  fetchPendingPaymentDetails({
    pendingPaymentDetailsState,
    userKey,
    groupKey,
    settingKey,
    sortQuery,
    globalFilterQuery: filterQueryVal,
    pageSize,
    skip,
    isRestricted,
    mode,
    sqlQuery,
  })
    .then(res => {
      dispatch({
        type: actionTypes.FETCHED_PENDING_PAYMENTS_DETAILS,
        pendingPaymentDetailsData: res.data.value,
        pendingPaymentDetailsCount: res.data.count,
        pendingPaymentDetailsNextLink: res.data.nextLink,
      });
    })
    .catch(() => {
      toast?.current.show({
        severity: 'warn',
        summary: constants.SOMETHING_WENT_WRONG,
        detail: '',
      });
      dispatch({type: actionTypes.FETCHING_PENDING_PAYMENTS_DETAILS_FAILED});
    });
};

export const updatePaymentDetailsDashboardViewSettingObject = ({
  settingId,
  viewSettings,
}) => {
  return {
    type: actionTypes.SET_PENDING_PAYMENTS_DETAILS_DASHBOARD_VIEW_SETTING_OBJECT,
    payload: {settingId, viewSettings},
  };
};

export const pendingPaymentsDetailsExportToExcel =
  ({
    userKey,
    groupKey,
    modeType,
    settingKey,
    sortQuery,
    filterQuery,
    isRestricted,
    mode,
    sqlQuery,
  }) =>
  (dispatch, getState) => {
    const toast = getState().generalReducer?.toastRef;

    dispatch({type: actionTypes.EXPORTING_PENDING_PAYMENTS_DETAILS_TO_EXCEL});
    exportPendingPaymentDetails({
      userKey,
      groupKey,
      settingKey,
      sortQuery,
      filterQuery,
      isRestricted,
      mode,
      sqlQuery,
    })
      .then(res => {
        downloadExcel(
          res.data,
          fileNames[modeType] ?? 'ExportFile',
          toast,
          constants.PENDING_PAYMENTS_DETAILS_EXPORTED_TO_EXCEL,
        );
        dispatch({
          type: actionTypes.EXPORTED_PENDING_PAYMENTS_DETAILS_TO_EXCEL,
        });
      })
      .catch(() => {
        dispatch({
          type: actionTypes.EXPORTING_PENDING_PAYMENTS_DETAILS_TO_EXCEL_FAILED,
        });
      });
  };

export const updatePendingPaymentDetailsIsRestricted = isRestricted => {
  return {
    type: actionTypes.SET_PENDING_PAYMENT_IS_RESTRICTED,
    isRestricted,
  };
};

export const updatePaymentsDetailsAlertDescription = alertDescription => {
  return {
    type: actionTypes.SET_PENDING_PAYMENTS_ALERT_DESCRIPTION,
    alertDescription,
  };
};

export const cleanUpPendingPaymentsDetails = () => {
  return {
    type: actionTypes.CLEAN_UP_PENDING_PAYMENTS_DETAILS,
  };
};
