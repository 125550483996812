import {useDispatch, useSelector} from 'react-redux';
import {useState} from 'react';
import {ViewSettingsDropDown} from '../../../common/view-settings/view-settings-dropdown/ViewSettingsDropDown';
import {updateScheduledPaymentsViewSettingObject} from '../../../../store/actions/scheduledPaymentsActions';
import {Button, CheckboxLabel} from '../../../../ui/inputs';
import {constants, gridModeTypes} from '../../../../constants/common';
import {Box} from '../../../../ui/layouts';

export const ViewSettings = ({
  isIncludePastDuePayments,
  setIsIncludePastDuePayments,
  setShowDeclinePayments,
  handleActionClick,
  selectedPayments,
  onAutoPaymentScheduleClick,
}: {
  isIncludePastDuePayments: boolean;
  setIsIncludePastDuePayments: (val: boolean) => void;
  setShowDeclinePayments: any;
  handleActionClick: (actionType: string, note?: string) => void;
  selectedPayments: any[];
  onAutoPaymentScheduleClick: () => void;
}) => {
  const dispatch = useDispatch();
  const [hideViewSettingsDropdown, setHideviewSettingsDropdown] =
    useState(false);

  const {scheduledPaymentsSettingId, scheduledPaymentsViewSettings} =
    useSelector((store: any) => store.scheduledPaymentsReducer);

  const onViewSettingSelected = (
    selectedSettingId: string,
    viewSettings: any,
  ) => {
    if (selectedSettingId === constants.EMPTY_GUID_VALUE) {
      setHideviewSettingsDropdown(true);
    }
    dispatch(
      updateScheduledPaymentsViewSettingObject({
        settingId: selectedSettingId,
        viewSettings,
      }),
    );
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      marginY="12px"
    >
      <Box display="flex" gap={1}>
        {!hideViewSettingsDropdown && (
          <Box width="250px">
            <ViewSettingsDropDown
              settingId={scheduledPaymentsSettingId}
              viewName="ScheduledPayments"
              isQueryParamDefault={false}
              onViewSettingSelected={(selectedSettingId, viewSettings) =>
                onViewSettingSelected(selectedSettingId, viewSettings)
              }
              activityType={gridModeTypes.ScheduledPayments}
              currentViewSettings={scheduledPaymentsViewSettings}
            />
          </Box>
        )}
        <CheckboxLabel
          checked={isIncludePastDuePayments}
          label={constants.INCLUDE_PAST_DUE_PAYMENTS}
          onChange={e => setIsIncludePastDuePayments(e.target.checked)}
        />
      </Box>
      <Box display="flex" gap={1}>
        <Button
          variant="contained"
          disabled={selectedPayments.length === 0}
          onClick={() => {
            handleActionClick(constants.APPROVE.toLocaleLowerCase());
          }}
        >
          {constants.APPROVE}
        </Button>
        <Button
          variant="contained"
          disabled={selectedPayments.length === 0}
          onClick={() => {
            handleActionClick(constants.APPROVEANDPROCESS);
          }}
        >
          {constants.APPROVE_AND_PROCESS}
        </Button>
        <Button
          variant="contained"
          disabled={selectedPayments.length === 0}
          onClick={setShowDeclinePayments}
        >
          {constants.DECLINE}
        </Button>
        <Button
          variant="contained"
          disabled={selectedPayments.length === 0}
          onClick={() => {
            handleActionClick(constants.CANCEL.toLocaleLowerCase());
          }}
        >
          {constants.CANCEL}
        </Button>
        <Button variant="contained" onClick={onAutoPaymentScheduleClick}>
          {constants.AUTO_PAYMENT_SCHEDULE}
        </Button>
      </Box>
    </Box>
  );
};
