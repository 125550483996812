export const StorageType = {
  private: 2,
  public: 1,
};

export const CommunicationDropDownType = {
  email: 1,
  text: 2,
  voice: 3,
};
