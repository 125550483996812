import {FiPaperclip} from 'react-icons/fi';
import {useSelector} from 'react-redux';
import {isEmpty} from '../../../lib/utils';
import {getUserData} from '../../../store/actions/userActions';
import {downloadFile} from '../../../utils/fileUtils';
import {downloadActivityAttachment} from '../../../services/activities';
import {constants} from '../../../constants/common';
import {onMessageReadUnread} from '../helper';
import {getFileNameFromUrl} from '../../common/helper';

/** File has custom components for view-message grid */

// Component to change read and unread message status
export const ReadUnreadMessage = ({values}: {values: any}) => {
  const {messageStatus, eventKey} = values.row;
  const onRefresh = values?.onRefresh;
  const toast = useSelector((store: any) => store.generalReducer?.toastRef);
  return (
    <span
      role="button"
      aria-hidden="true"
      className="btn btn-purple py-1 d-flex align-items-center justify-content-center h-30-px "
      onClick={() => onMessageReadUnread(eventKey, false, onRefresh, toast)}
    >
      <span className={`pi pi-${messageStatus === 2 ? 'read' : 'unread'}`} />
    </span>
  );
};

// Component to download single attachment which has only blob path e.g Blob://....
export const ViewAttachment = ({values}: {values: any}) => {
  const {url} = values.row;
  const toast = useSelector((store: any) => store.generalReducer?.toastRef);
  const fName = getFileNameFromUrl(url);
  const {groupKey} = getUserData();

  const downloadAttachment = async (fileName: string, fileUrl: string) => {
    try {
      const {data} = await downloadActivityAttachment(groupKey, {
        fileName,
        fileUrl,
      });
      downloadFile(data, fileName);
    } catch (error) {
      toast?.current.show({
        severity: constants.ERROR.toLowerCase(),
        summary: constants.ERROR,
        detail: constants.FAILED_TO_DOWNLOAD_ATTACHMENT,
      });
    }
  };
  return (
    !isEmpty(url) && (
      <FiPaperclip
        color="#178087"
        size={22}
        className="cursor-pointer"
        onClick={() => downloadAttachment(`${fName}`, url)}
      />
    )
  );
};
