import {createContext, memo, useContext, useMemo, useState} from 'react';
import {constants} from '../../constants/common';
import {getUserData} from '../../store/actions/userActions';
import Dialog from '../../ui/feedback/dialog/Dialog';
import Tabs from '../../ui/utils/tabs/Tabs';
import {SESSION_NACM_SUPPORT} from '../../utils/constants';
import FeatureNotAvailable from '../common/FeatureNotAvailable';
import Contacts from './customer-info-tabs/Contacts';
import Credit from './customer-info-tabs/Credit';
import History from './customer-info-tabs/History';
import Info from './customer-info-tabs/Info';
import Nacm from './customer-info-tabs/Nacm';
import PortalAccess from './customer-info-tabs/PortalAccess';
import RolledUpHistory from './customer-info-tabs/RolledUpHistory';
import Transactions from './customer-info-tabs/Transactions';
import {AccountNotes} from '../account-overview/account-notes/AccountNotes';

const customerInfoContainerContext = createContext({});
const useCustomerInfoContext = () => {
  return useContext(customerInfoContainerContext);
};
export const CustomerInfoContainer = memo(() => {
  const [showFeatureNotAvailable, setShowFeatureNotAvailable] = useState(false);
  const sessionNACMSupport = getUserData()?.SessionNACMSupport;
  const customerInfoTabs = [
    {
      label: constants.CREDIT,
      content: (
        <div className="tabs-content-wrapper">
          <Credit />
        </div>
      ),
    },
    {
      label: constants.INFO,
      content: (
        <div className="tabs-content-wrapper">
          <Info />
        </div>
      ),
    },
    {
      label: constants.HISTORY,
      content: (
        <div className="tabs-content-wrapper">
          <History />
        </div>
      ),
    },
    {
      label: constants.ROLLED_UP_HISTORY,
      content: (
        <div className="tabs-content-wrapper">
          <RolledUpHistory />
        </div>
      ),
    },
    {
      label: constants.CONTACTS,
      content: (
        <div className="tabs-content-wrapper">
          <Contacts />
        </div>
      ),
    },
    {
      label: constants.TRANSACTIONS,
      content: (
        <div className="tabs-content-wrapper">
          {' '}
          <Transactions />
        </div>
      ),
    },
    {
      label:
        sessionNACMSupport === SESSION_NACM_SUPPORT.NACM
          ? constants.NACM
          : constants.CREDIT_SAFE,
      content: (
        <div className="tabs-content-wrapper">
          <Nacm />
        </div>
      ),
    },
    {
      label: constants.ACCOUNT_NOTES,
      content: (
        <div className="tabs-content-wrapper">
          <AccountNotes showTitle={false} accountOverview={false} />
        </div>
      ),
    },
    {
      label: constants.PORTAL_ACCESS,
      content: (
        <div className="tabs-content-wrapper">
          <PortalAccess />
        </div>
      ),
    },
  ];

  const providerValues = useMemo(
    () => ({
      setShowFeatureNotAvailable,
    }),
    [],
  );

  return (
    <>
      <Dialog
        open={showFeatureNotAvailable}
        title={constants.FEATURE_NOT_AVAILABLE_TITLE}
        onClose={() => setShowFeatureNotAvailable(false)}
      >
        <FeatureNotAvailable />
      </Dialog>
      <customerInfoContainerContext.Provider value={providerValues}>
        <Tabs variant="scrollable" tabs={customerInfoTabs} />
      </customerInfoContainerContext.Provider>
    </>
  );
});

export {useCustomerInfoContext};
