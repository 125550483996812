import {constants} from '../../constants/common';
import {exportUserContacts, fetchUserContacts} from '../../services/contacts';
import {downloadExcel} from '../../utils/fileUtils';
import {actionTypes} from '../constants/actionTypes';

export const getUserContacts = options => (dispatch, getState) => {
  const {contactsViewSettings} = getState().contactsReducer;
  const toast = getState().generalReducer?.toastRef;

  const filterQueryVal =
    options.settingKey === contactsViewSettings?.settingsKey
      ? options.filterQuery
      : '';
  const updatedOptions = {...options, globalFilterQuery: filterQueryVal};
  dispatch({type: actionTypes.FETCHING_USER_CONTACTS});
  fetchUserContacts(updatedOptions)
    .then(response => {
      dispatch({
        type: actionTypes.FETCHED_USER_CONTACTS,
        contactsData: response.data.value,
        contactsCount: response.data.count,
      });
    })
    .catch(() => {
      dispatch({type: actionTypes.FETCHING_USER_CONTACTS_FAILED});
      toast?.current.show({
        severity: 'warn',
        summary: constants.FAILED_TO_LOAD_DATA,
        detail: constants.FETCHING_CONTACTS_FAILED,
      });
    });
};

export const updateViewSettingObject = ({settingId, viewSettings}) => {
  return {
    type: actionTypes.SET_CONTACTS_VIEW_SETTING_OBJECT,
    payload: {settingId, viewSettings},
  };
};

export const userContactsExportToExcel = options => (dispatch, getState) => {
  const {contactsViewSettings} = getState().contactsReducer;
  const toast = getState().generalReducer?.toastRef;
  const filterQueryVal =
    options.settingKey === contactsViewSettings?.settingsKey
      ? options.filterQuery
      : '';

  dispatch({type: actionTypes.EXPORTING_USER_CONTACTS_TO_EXCEL});
  toast?.current.show({
    severity: 'info',
    summary: constants.EXPORTING,
  });
  exportUserContacts({...options, globalFilterQuery: filterQueryVal})
    .then(res => {
      downloadExcel(
        res.data,
        constants.CONTACTS,
        toast,
        constants.CONTACTS_EXPORTED_TO_EXCEL,
      );
      toast?.current.show({
        severity: 'success',
        summary: constants.EXPORTED_SUCCESSFULLY,
      });
      dispatch({type: actionTypes.EXPORTED_USER_CONTACTS_TO_EXCEL});
    })
    .catch(() => {
      dispatch({type: actionTypes.EXPORTING_USER_CONTACTS_TO_EXCEL_FAILED});
      toast?.current.show({
        severity: 'warn',
        summary: constants.FAILED_TO_EXPORT,
      });
    });
};

export const cleanUpUserContactsData = () => {
  return {
    type: actionTypes.CLEAN_UP_USER_CONTACTS_DATA,
  };
};
