import {actionTypes} from '../constants/actionTypes';

const initialState = {
  treeNodes: [],
  treeLoading: false,
  fetchingTreeNodes: false,
  fetchingTreeNodesFailed: false,
  fetchedTreeNodes: false,
};

const treeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_TREE_NODES:
      return {
        ...state,
        treeLoading: true,
        fetchingTreeNodes: true,
        fetchingTreeNodesFailed: false,
        fetchedTreeNodes: false,
      };
    case actionTypes.FETCHED_TREE_NODES:
      return {
        ...state,
        treeNodes: action.treeNodes,
        treeLoading: false,
        fetchingTreeNodes: false,
        fetchingTreeNodesFailed: false,
        fetchedTreeNodes: true,
      };
    case actionTypes.FETCHING_TREE_NODES_FAILED:
      return {
        ...state,
        treeLoading: false,
        fetchingTreeNodes: false,
        fetchingTreeNodesFailed: true,
        fetchedTreeNodes: false,
      };
    default:
      return state;
  }
};
export default treeReducer;
