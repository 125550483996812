import {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {isEmpty} from 'lodash';

import DOMPurify from 'dompurify';
import Popover from '../../../ui/utils/popover/Popover';
import TextField from '../../../ui/inputs/textfield/TextField';
import {Button, Checkbox, TextEditor} from '../../../ui/inputs';
import {CommunicationDropDownType} from '../../../constants/typeConstants';
import {useGetTemplateList} from '../../../hooks/useGetTemplateList';
import {constants} from '../../../constants/common';
import {
  fetchTemplateDetailsByKey,
  markMailAsUnread,
  resendMail,
} from '../../../services/activities';
import {loadMailByMailkey, downloadAttachment} from '../../../services/mailbox';
import {hideDialog} from '../../../store/actions/generalActions';
import {
  MODE_TYPES,
  REPLY_TYPE,
  ISSUE_STATUS,
  MAIL_STATUS,
} from '../../../utils/constants';
import {snippetList} from '../../../utils/codeSnippets';
import {SelectWithHeader} from '../../common/select/select-with-header/SelectWithHeader';
import AddressBook from '../address-book/AddressBook';
import {EditEmailSignature} from './EditEmailSignature';
import {TemplateHeader} from './template-dropdown/TemplateHeader';
import {TemplateOptions} from './template-dropdown/TemplateOptions';

interface CommunicateEmailProps {
  activityInfo: any;
  newActivity: boolean;
  handleTableFilterDisplayType: (stringValue: string) => void;
  setShowAddContactDialog: (booleanValue: boolean) => void;
  setShowAddPersonalContactsDialog: (booleanValue: boolean) => void;
  groupKey: string;
  custKey: number;
  userKey: string;
  mailKey: string;
  setSaveAndSendButtonText: (stringValue: string) => void;
  unChangedMailProp: any;
  setIsReplyBtnClicked: (booleanValue: boolean) => void;
  setIsReplyAllBtnClicked: (booleanValue: boolean) => void;
  setIsForwardBtnClicked: (booleanValue: boolean) => void;
  onNewActivityButtonClicked: (
    replyTypeParam: number,
    isSaveNext: boolean,
    nextProp: any,
    hideSaveAndNextParam: boolean,
  ) => void;
  isEmailEnabled: boolean;
  ModeType: number;
  setLoadingCommunicate: (booleanValue: boolean) => void;
  setShowCopyAttachmentsToCustDocsDialog: (booleanValue: boolean) => void;
  attachments: any[];
  setAttachments: (anyList: any) => void;
  copyNotesEmailBtnClicked: boolean;
  taskActKey: number;
  activityRef: any;
  updateActivityInfoState: (anyValue: any) => void;
}

function CommunicateEmail(props: CommunicateEmailProps) {
  const {
    activityInfo,
    newActivity,
    handleTableFilterDisplayType,
    setShowAddContactDialog,
    setShowAddPersonalContactsDialog,
    groupKey,
    custKey,
    userKey,
    mailKey,
    setSaveAndSendButtonText,
    unChangedMailProp,
    setIsReplyBtnClicked,
    setIsReplyAllBtnClicked,
    setIsForwardBtnClicked,
    onNewActivityButtonClicked,
    isEmailEnabled,
    ModeType,
    setLoadingCommunicate,
    setShowCopyAttachmentsToCustDocsDialog,
    attachments,
    setAttachments,
    copyNotesEmailBtnClicked,
    taskActKey,
    activityRef,
    updateActivityInfoState,
  } = props;
  const [toAnchorEl, setToAnchorEl] = useState<null | HTMLElement>(null);
  const [ccAnchorEl, setCcAnchorEl] = useState<null | HTMLElement>(null);
  const [isMailLoading, setIsMailLoading] = useState(false);
  const [showEmailSignature, setShowEmailSignature] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<any>();
  const dispatch = useDispatch();
  const toast = useSelector((store: any) => store.generalReducer?.toastRef);
  const {templateDropdown} = useGetTemplateList({
    groupKey,
    type: CommunicationDropDownType.email,
  });

  const [emailTextIssue, setEmailTextIssue] = useState(
    activityInfo?.mail?.bodyHTML,
  );
  useEffect(() => {
    setEmailTextIssue(activityInfo?.mail?.bodyHTML);
    activityRef.current = activityInfo;
  }, [activityInfo]);

  /**
   * Asynchronously loads mail details by mail key using the provided `loadMailByMailkey` function.
   * Optionally sets the HTML content of the mail and updates attachments list if specified.
   *
   * @param {boolean} setHtml - Flag to indicate whether to set the HTML content of the mail.
   * @returns {Promise<void>} - A promise resolving once mail details are loaded and processed.
   */
  const loadMail = async (setHtml = false) => {
    try {
      setIsMailLoading(true);
      const data = await loadMailByMailkey(groupKey, mailKey);
      setIsMailLoading(false);
      if (setHtml) {
        const mailHTML = isEmpty(data.bodyHtml) ? data.bodyText : data.bodyHtml;
        setEmailTextIssue(mailHTML);
      }

      const attachmentsList = data.emailAttachments;
      const list = attachmentsList.map((att: any) => {
        return {...att, selected: false};
      });
      setAttachments(list);
    } catch (error) {
      setIsMailLoading(false);
    }
  };

  /**
   * useEffect hook that handles the initialization and updates related to email text content.
   * It ensures proper handling of email text content based on conditions like ModeType and emailTextIssue.
   */
  useEffect(() => {
    handleTableFilterDisplayType('menu');
    if (!emailTextIssue) {
      if (ModeType && ModeType === MODE_TYPES.activityOnEnquiry) {
        setEmailTextIssue(activityInfo?.mail?.bodyHTML);
      } else if (!isEmpty(activityInfo) && mailKey) {
        loadMail(true);
      }
    }
  }, [mailKey, emailTextIssue]);

  /**
   * Updates recipient values in the activityInfo state based on the selected recipients and the specified field.
   * Additionally, sets the Save and Send button text to indicate changes.
   */
  const updateRecipientValues = (selectedRecipient: any[], field: string) => {
    let activityInfoTemp = {...activityInfo};
    activityInfoTemp = {
      ...activityInfoTemp,
      mail: {
        ...activityInfoTemp.mail,
        [field]: selectedRecipient.map(res => ` ${res?.emailAddr}`),
      },
    };
    updateActivityInfoState(activityInfoTemp);
    setSaveAndSendButtonText(constants.SAVE_AND_SEND);
  };

  /**
   * On input change update main activity object
   */
  const onInputChange = (value: string | boolean, field: string) => {
    let tempActivityInfo = {...activityRef.current};
    tempActivityInfo = {
      ...tempActivityInfo,
      mail: {...tempActivityInfo.mail, [field]: value},
    };
    updateActivityInfoState(tempActivityInfo);
    const OtherField = field === 'toAddress' ? 'ccAddress' : 'toAddress';
    if (
      (field === 'toAddress' || field === 'ccAddress') &&
      typeof value === 'string' &&
      isEmpty(value) &&
      isEmpty(tempActivityInfo.mail[OtherField])
    ) {
      setSaveAndSendButtonText(constants.SAVE);
    } else if (
      field !== 'subject' &&
      field !== 'isUrgent' &&
      tempActivityInfo.mail[field] !== unChangedMailProp[field]
    ) {
      setSaveAndSendButtonText(constants.SAVE_AND_SEND);
    } else {
      setSaveAndSendButtonText(constants.SAVE_AND_SEND);
    }

    activityRef.current = tempActivityInfo;
  };

  /**
   * On mail body is changed update activity with body text and body html
   */
  const onEditorChange = (e: any) => {
    let tempActivityObj = {...activityRef.current};
    tempActivityObj = {
      ...tempActivityObj,
      mail: {
        ...tempActivityObj.mail,
        bodyText: e.textValue,
        bodyHTML: e.htmlValue,
      },
    };
    updateActivityInfoState(tempActivityObj);
  };

  /**
   * Asynchronously retrieves template details by the provided templateKey using the fetchTemplateDetailsByKey function.
   * Updates the activityInfo state with the fetched template data, and sets Save and Send button text accordingly.
   *
   * @param {string} templateKey - The key identifying the template to be fetched.
   * @returns {Promise<void>} - A promise resolving once template details are retrieved and processed.
   */
  const getTemplateByKey = async (templateKey: string) => {
    const {data} = await fetchTemplateDetailsByKey({
      EmailTemplateKey: templateKey,
      CommunicationType: CommunicationDropDownType.email,
      GroupKey: groupKey,
      CustomerKey: custKey,
      UserKey: userKey,
      ModeType: 0,
    });
    const {TemplateInfo} = data;
    const combinedHtml = `${data.TemplateBody}  <br/> <br/> ${activityInfo.mail.bodyHTML}`;

    if (TemplateInfo) {
      let activityInfoTemp = {...activityInfo};
      activityInfoTemp = {
        ...activityInfoTemp,
        mail: {
          ...activityInfoTemp.mail,
          toAddress: isEmpty(activityInfo.mail?.toAddress)
            ? TemplateInfo.toAddress
            : activityInfo.mail?.toAddress,
          ccAddress: isEmpty(activityInfo.mail?.ccAddress)
            ? TemplateInfo.ccAddress
            : activityInfo.mail?.ccAddress,
          subject: isEmpty(activityInfo.mail?.subject)
            ? TemplateInfo.subject
            : activityInfo.mail?.subject,
          bodyHTML: combinedHtml,
        },
      };
      activityRef.current = activityInfoTemp;
      setEmailTextIssue(combinedHtml);
      setSaveAndSendButtonText(constants.SAVE_AND_SEND);
      updateActivityInfoState(activityInfoTemp);
    }
  };

  const onEmailTemplateSelect = async (e: any) => {
    setSelectedTemplate(e);
    getTemplateByKey(e.emailTemplateKey);
  };

  const onSaveOrDeleteSignature = () => {
    setShowEmailSignature(false);
    if (selectedTemplate) {
      getTemplateByKey(selectedTemplate.emailTemplateKey);
    }
  };

  const onDownloadAttachment = (att: any) => {
    downloadAttachment(mailKey, att);
  };

  const onForwardClick = () => {
    onNewActivityButtonClicked(REPLY_TYPE.forward, false, {}, true);
    setSaveAndSendButtonText(constants.SAVE);
    setIsForwardBtnClicked(true);
  };

  const onReplyClick = () => {
    onNewActivityButtonClicked(REPLY_TYPE.reply, false, {}, true);
    setSaveAndSendButtonText(constants.SAVE_AND_SEND);
    setIsReplyBtnClicked(true);
  };

  const onReplyAllClick = () => {
    onNewActivityButtonClicked(REPLY_TYPE.replyAll, false, {}, true);
    setSaveAndSendButtonText(constants.SAVE_AND_SEND);
    setIsReplyAllBtnClicked(true);
  };

  const onMarkAsUnreadClick = async () => {
    setLoadingCommunicate(true);
    try {
      await markMailAsUnread({
        worksessionId: activityInfo?.customerTaskActivity?.workSessionID,
        mailKey,
        issueStatus:
          activityInfo?.customerTask?.status === 1
            ? ISSUE_STATUS.complete
            : ISSUE_STATUS.inComplete,
        emailStatus: MAIL_STATUS.unread,
      });
      let activityInfoTemp = {...activityInfo};
      activityInfoTemp = {
        ...activityInfoTemp,
        customerTask: {
          ...activityInfoTemp.customerTask,
          status: ISSUE_STATUS.inComplete,
        },
      };

      updateActivityInfoState(activityInfoTemp);
      setLoadingCommunicate(false);
    } catch {
      toast?.current.show({
        severity: 'warn',
        summary: constants.SOMETHING_WENT_WRONG,
        detail: '',
      });
      setLoadingCommunicate(false);
    }
  };

  const onResendClicked = async () => {
    setLoadingCommunicate(true);
    const resendRequest = {
      taskActivityKey: taskActKey,
      customerKey: custKey,
      groupKey,
      userKey,
      mailKey,
      useLSTemplateStyle: 0,
      workSessionId: activityInfo?.customerTaskActivity?.workSessionID,
    };
    try {
      await resendMail(resendRequest);
      setLoadingCommunicate(false);
      dispatch<any>(hideDialog());
    } catch (error) {
      toast?.current.show({
        severity: 'warn',
        summary: constants.SOMETHING_WENT_WRONG,
        detail: '',
      });
      setLoadingCommunicate(false);
    }
  };

  const onCopyAttachmentsToCustDocsBtnClick = async () => {
    setShowCopyAttachmentsToCustDocsDialog(true);
    loadMail();
  };

  const getMailStatus = (status: number) => {
    switch (status) {
      case MAIL_STATUS.error:
        return constants.ERROR_SENDING;
      case MAIL_STATUS.unread:
      case MAIL_STATUS.read:
        return constants.RECEIVED_MAIL;
      case MAIL_STATUS.queued:
        return constants.EMAIL_QUEUED;
      default:
        return constants.SENT_MAIL;
    }
  };

  const handleClickToAnchorEl = (event: React.MouseEvent<HTMLElement>) => {
    setToAnchorEl(event.currentTarget);
  };
  const handleCloseToAnchorEl = () => {
    setToAnchorEl(null);
  };
  const handleClickCcAnchorEl = (event: React.MouseEvent<HTMLElement>) => {
    setCcAnchorEl(event.currentTarget);
  };
  const handleCloseCcAnchorEl = () => {
    setCcAnchorEl(null);
  };
  const openToAddressBook = Boolean(toAnchorEl);
  const openCcAddressBook = Boolean(ccAnchorEl);

  const renderButtons = () => {
    if (newActivity) return null;
    return (
      <div className="d-flex justify-content-between align-items-center">
        <div>
          {(activityInfo?.mail?.status === MAIL_STATUS.unread ||
            activityInfo?.mail?.status === MAIL_STATUS.read) && (
            <Button
              variant="contained"
              size="small"
              className="m-1"
              onClick={onReplyClick}
            >
              {constants.REPLY}
            </Button>
          )}
          {(activityInfo?.mail?.status === MAIL_STATUS.unread ||
            activityInfo?.mail?.status === MAIL_STATUS.read) && (
            <Button
              variant="contained"
              size="small"
              className="m-1"
              onClick={onReplyAllClick}
            >
              {constants.REPLY_ALL}
            </Button>
          )}
          {isEmailEnabled && (
            <Button
              variant="contained"
              size="small"
              className="m-1"
              onClick={onForwardClick}
            >
              {constants.FORWARD}
            </Button>
          )}
          {(activityInfo?.mail?.status === MAIL_STATUS.unread ||
            activityInfo?.mail?.status === MAIL_STATUS.read) && (
            <Button
              variant="contained"
              size="small"
              className="m-1"
              onClick={onMarkAsUnreadClick}
            >
              {constants.MARK_AS_UNREAD}
            </Button>
          )}
        </div>
        {activityInfo?.mail?.status && (
          <div className="d-flex align-items-center">
            <span className="py-1 px-2">
              {getMailStatus(activityInfo?.mail?.status)}
            </span>
            {activityInfo?.mail?.status === MAIL_STATUS.error && (
              <Button
                variant="contained"
                size="small"
                className="ms-3"
                onClick={onResendClicked}
              >
                {constants.RESEND}
              </Button>
            )}
          </div>
        )}
      </div>
    );
  };

  const renderFromAddressField = () => {
    if (newActivity) return null;
    return (
      <div className="mt-3">
        <TextField
          id="from-address"
          label={constants.FROM}
          value={activityInfo?.mail?.fromAddress}
          onChange={e => onInputChange(e.target.value, 'fromAddress')}
          disabled={!newActivity}
          InputLabelProps={!newActivity && {shrink: true}}
          size="small"
          margin="normal"
        />
      </div>
    );
  };
  const renderAddressFields = (label: string, value: string, field: string) => (
    <div
      className={`d-flex justify-content-between ${
        newActivity && 'my-3'
      } h-auto`}
    >
      {newActivity && (
        <Button
          className="me-2"
          variant="contained"
          size="small"
          onClick={
            field === 'toAddress'
              ? handleClickToAnchorEl
              : handleClickCcAnchorEl
          }
          style={{alignSelf: 'center'}}
        >
          {label}
        </Button>
      )}
      <TextField
        id={`${field}-address`}
        label={newActivity ? '' : label}
        value={value}
        disabled={!newActivity}
        onChange={e => onInputChange(e.target.value, field)}
        size="small"
        margin="dense"
      />
    </div>
  );

  const renderSubjectField = () => (
    <div className={`${newActivity && 'd-flex justify-content-between my-3'}`}>
      {newActivity && (
        <Button
          variant="text"
          className="me-2"
          disabled
          style={{color: 'black'}}
        >
          {constants.SUBJECT}
        </Button>
      )}

      <TextField
        id="subject"
        label={newActivity ? '' : constants.SUBJECT}
        value={activityInfo?.mail?.subject}
        disabled={!newActivity}
        onChange={e => onInputChange(e.target.value, 'subject')}
        size="small"
        margin="dense"
      />
    </div>
  );

  const renderIsUrgentField = () => (
    <div className={`${newActivity && 'd-flex my-3'}`}>
      <Button variant="text" className="me-2" disabled style={{color: 'black'}}>
        {constants.ISURGENT}
      </Button>

      <Checkbox
        checked={activityInfo?.mail?.isUrgent}
        disabled={!newActivity}
        onChange={e => onInputChange(e.target.checked, 'isUrgent')}
      />
    </div>
  );

  const renderTemplateSection = () =>
    newActivity && (
      <div className="d-flex my-2 align-items-center justify-content-between">
        <Button className="me-2" disabled style={{color: 'black'}}>
          {constants.TEMPLATE}
        </Button>
        <div className="d-flex w-100 gap-2">
          <div className="w-50">
            <SelectWithHeader
              placeholder={constants.SELECT_EMAIL_TEMPLATE_TO_INSERT}
              options={templateDropdown}
              Header={<TemplateHeader />}
              components={{Option: TemplateOptions}}
              onChange={(e: any) => onEmailTemplateSelect(e)}
              value={selectedTemplate}
              getOptionLabel={(option: {emailTemplateID: any}) =>
                option.emailTemplateID
              }
            />
          </div>
          <Button
            variant="contained"
            size="small"
            onClick={() => setShowEmailSignature(true)}
            style={{alignSelf: 'center'}}
          >
            {constants.EDIT_EMAIL_SIGNATURE}
          </Button>
          {showEmailSignature && (
            <EditEmailSignature
              setShowEmailSignature={setShowEmailSignature}
              showEmailSignature={showEmailSignature}
              groupKey={groupKey}
              userKey={userKey}
              custKey={custKey}
              onSaveOrDeleteSignature={onSaveOrDeleteSignature}
            />
          )}
        </div>
      </div>
    );

  const renderTextEditor = () =>
    newActivity && (
      <div className="mt-4 my-2">
        <TextEditor
          className="h-450-px"
          textEditorValue={emailTextIssue}
          onTextEditorValueChange={e => {
            setEmailTextIssue(e.htmlValue);
            onEditorChange(e);
          }}
          customDropdownOption={snippetList}
        />
      </div>
    );
  const renderAttachmentsSection = () => {
    if (newActivity) return null;
    return (
      <>
        <div className="mt-3 d-flex align-items-center justify-content-start">
          <Button className="me-2" disabled style={{color: 'black'}}>
            {constants.ATTACHMENTS}
          </Button>
          <div className="ps-4 py-2 d-flex flex-wrap">
            {attachments?.length ? (
              attachments.map(attachment => (
                <Button
                  variant="text"
                  size="small"
                  type="button"
                  key={attachment.fileName}
                  className="mx-2 my-2"
                  onClick={() => onDownloadAttachment(attachment)}
                >
                  {attachment.fileName};
                </Button>
              ))
            ) : (
              <p className="text-muted my-2 text-center">
                {constants.NO_ATTACHMENTS_FOUND}
              </p>
            )}
          </div>
        </div>
        {attachments.length > 0 && (
          <div className="mt-2">
            <Button
              variant="contained"
              size="small"
              onClick={() => onCopyAttachmentsToCustDocsBtnClick()}
            >
              {constants.COPY_ATTACHMENTS_TO_CUST_DOCS}
            </Button>
          </div>
        )}
      </>
    );
  };
  const renderReadyOnlyMail = () => {
    if (newActivity) return null;
    return (
      <div className="mt-3 border-1">
        <div className="mt-2 p-3 text-editor-email-body h-480-px">
          <div
            id="mailContent"
            // No fix required as we are using DOMPurify lib
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                emailTextIssue?.replace(/href/g, "target='_blank' href"),
              ),
            }}
          />
        </div>
      </div>
    );
  };
  return (
    <div>
      {ModeType === MODE_TYPES.enquiry && isEmpty(emailTextIssue) ? (
        <p>{isMailLoading ? 'Mail loading' : constants.THERE_ARE_NO_EMAILS}</p>
      ) : (
        <>
          {renderButtons()}
          {renderFromAddressField()}
          {renderAddressFields(
            constants.TO,
            activityInfo?.mail?.toAddress,
            'toAddress',
          )}
          {renderAddressFields(
            constants.CC,
            activityInfo?.mail?.ccAddress,
            'ccAddress',
          )}
          {renderSubjectField()}

          {renderTemplateSection()}
          {renderIsUrgentField()}
          {renderTextEditor()}
          {renderAttachmentsSection()}
          {renderReadyOnlyMail()}
        </>
      )}
      <Popover
        open={openToAddressBook}
        anchorEl={toAnchorEl}
        onClose={handleCloseToAnchorEl}
      >
        <AddressBook
          setShowAddPersonalContactsDialog={setShowAddPersonalContactsDialog}
          setShowAddContactDialog={setShowAddContactDialog}
          updateRecipientValues={(selectedRecipient: any[]) =>
            updateRecipientValues(selectedRecipient, 'toAddress')
          }
          textFieldValueArray={activityInfo?.mail?.toAddress}
          fieldKey="emailAddr"
        />
      </Popover>

      <Popover
        open={openCcAddressBook}
        anchorEl={ccAnchorEl}
        onClose={handleCloseCcAnchorEl}
      >
        <AddressBook
          setShowAddPersonalContactsDialog={setShowAddPersonalContactsDialog}
          setShowAddContactDialog={setShowAddContactDialog}
          updateRecipientValues={(selectedRecipient: any[]) =>
            updateRecipientValues(selectedRecipient, 'ccAddress')
          }
          textFieldValueArray={activityInfo?.mail?.ccAddress}
          fieldKey="emailAddr"
        />
      </Popover>
    </div>
  );
}
export default CommunicateEmail;
