import {constants} from '../../constants/common';
import {StorageType} from '../../constants/typeConstants';
import {
  createFileDirectory,
  deleteFileDirectory,
  fetchFileList,
  uploadFilesToDirectory,
  uploadToAttachments,
} from '../../services/files';
import {actionTypes} from '../constants/actionTypes';

export const getFileList = options => dispatch => {
  dispatch({type: actionTypes.FETCHING_FILE_LIST});
  return fetchFileList(options)
    .then(res => {
      const {data} = res;
      dispatch({type: actionTypes.FETCHED_FILE_LIST, filesTree: data.children});
      return data.children;
    })
    .catch(() => {
      dispatch({type: actionTypes.FETCHING_FILE_LIST_FAILED});
    });
};

export const createDirectory = (options, rootPath) => (dispatch, getState) => {
  const toast = getState().generalReducer?.toastRef;
  dispatch({type: actionTypes.CREATING_FILE_DIRECTORY});

  createFileDirectory(options)
    .then(() => {
      dispatch(
        getFileList({
          storageType: StorageType.public,
          blobPath: rootPath,
          groupKey: options.GroupKey,
        }),
      );
    })
    .catch(() => {
      toast?.current.show({
        severity: 'warn',
        summary: constants.SOMETHING_WENT_WRONG,
        detail: '',
      });
      dispatch({type: actionTypes.CREATING_FILE_DIRECTORY_FAILED});
    });
};
export const deleteDirectoryOrFile =
  (options, rootPath) => (dispatch, getState) => {
    const toast = getState().generalReducer?.toastRef;
    dispatch({type: actionTypes.DELETING_FILE_DIRECTORY});

    deleteFileDirectory(options)
      .then(() => {
        dispatch(
          getFileList({
            storageType: StorageType.public,
            blobPath: rootPath,
            groupKey: options.GroupKey,
          }),
        );
      })
      .catch(() => {
        toast?.current.show({
          severity: 'warn',
          summary: constants.SOMETHING_WENT_WRONG,
          detail: '',
        });
        dispatch({type: actionTypes.DELETING_FILE_DIRECTORY_FAILED});
      });
  };

export const uploadFiles =
  (options, rootPath, updateSnackbarObj = () => {}) =>
  dispatch => {
    dispatch({type: actionTypes.UPLOADING_FILES});
    uploadFilesToDirectory(options)
      .then(() => {
        if (options.hasDuplicateFiles) {
          updateSnackbarObj({
            type: 'error',
            title: 'File exists',
            open: true,
            message: constants.FILE_ALREADY_EXISTS_ON_UPLOADING_FILE,
          });
        }
        dispatch({type: actionTypes.UPLOADED_FILES});
        dispatch(
          getFileList({
            storageType: StorageType.public,
            blobPath: rootPath,
            groupKey: options.GroupKey,
          }),
        );
      })
      .catch(() => {
        updateSnackbarObj({
          type: 'warning',
          title: constants.SOMETHING_WENT_WRONG,
          open: true,
          message: '',
        });
        dispatch({type: actionTypes.UPLOADING_FILES_FAILED});
      });
  };

export const uploadFileToAttachments =
  (options, updateSnackbarObj) => dispatch => {
    dispatch({type: actionTypes.UPLOADING_FILES_ATTACHMENTS});
    uploadToAttachments(options)
      .then(res => {
        dispatch({type: actionTypes.UPLOADED_FILES_ATTACHMENTS});
        dispatch({
          type: actionTypes.FETCHED_ACTIVITY_ATTACHMENTS,
          activityAttachments: res.data,
        });
      })
      .catch(() => {
        updateSnackbarObj({
          type: 'info',
          title: constants.INFO,
          open: true,
          message: constants.THE_FILE_ALREADY_EXISTS_IN_THE_DIRECTORY,
        });
        dispatch({type: actionTypes.UPLOADING_FILES_ATTACHMENTS_FAILED});
      });
  };
