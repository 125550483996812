import dayjs from 'dayjs';
import {filterOperators} from '../../../../lib/commonTableHelpers';
import {CustomerMenu} from '../../../common/CommonRowComponents';
import {Checkbox} from '../../../../ui/inputs';
import {constants} from '../../../../constants/common';
import {CheckUnCheckFilter} from '../../../common/filter/FilterComponents';

const utc = require('dayjs/plugin/utc');

const columnAlignment = {center: 'center', left: 'left', right: 'right'};
dayjs.extend(utc);
export const userContactsColumns = [
  {
    field: 'custId',
    headerName: 'Cust ID',
    sortable: true,
    minWidth: 120,
    filter: true,

    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'custName',
    headerName: 'Cust Name',
    sortable: true,
    filter: true,
    renderCell: cellValues => {
      return <CustomerMenu values={cellValues} />;
    },
    minWidth: 180,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'contactName',
    headerName: 'Contact Name',
    filter: true,
    sortable: true,
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'title',
    headerName: 'Title',
    filter: false,
    sortable: true,
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
    renderHeaderFilter: () => null,
  },
  {
    field: 'primaryContactFlag',
    headerName: 'Primary',
    filter: true,
    sortable: true,
    minWidth: 150,
    renderCell: ({row}) => (
      <Checkbox disabled checked={row.primaryContactFlag} />
    ),
    renderHeaderFilter: params => <CheckUnCheckFilter {...params} />,
    align: columnAlignment.center,
    headerAlign: columnAlignment.center,
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
  {
    field: 'keepCntctInSync',
    headerName: 'Keep In Sync With ERP',
    filter: true,
    sortable: true,
    minWidth: 150,
    renderCell: ({row}) => (
      <Checkbox
        disabled
        checked={row.keepCntctInSync}
        // onChange={e => onChkChanged(e, 'includeInEmail', row.id)}
      />
    ),
    renderHeaderFilter: params => <CheckUnCheckFilter {...params} />,
    align: columnAlignment.center,
    headerAlign: columnAlignment.center,
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
  {
    field: 'includeInEmailYN',
    headerName: 'Include In Email YN',
    renderCell: ({row}) => (
      <Checkbox
        disabled
        checked={row.includeInEmailYN}
        // onChange={e => onChkChanged(e, 'includeInEmail', row.id)}
      />
    ),
    renderHeaderFilter: params => <CheckUnCheckFilter {...params} />,
    align: columnAlignment.center,
    headerAlign: columnAlignment.center,
    filter: true,
    sortable: true,
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
  {
    field: 'includeInEmailYN2',
    headerName: 'Email 2',
    renderCell: ({row}) => (
      <Checkbox
        disabled
        checked={row.includeInEmailYN2}
        // onChange={e => onChkChanged(e, 'includeInEmail', row.id)}
      />
    ),
    renderHeaderFilter: params => <CheckUnCheckFilter {...params} />,
    align: columnAlignment.center,
    headerAlign: columnAlignment.center,
    filter: true,
    sortable: true,
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },

  {
    field: 'statementContactFlag',
    headerName: 'Statement',
    renderCell: ({row}) => (
      <Checkbox
        disabled
        checked={row.statementContactFlag}
        // onChange={e => onChkChanged(e, 'includeInEmail', row.id)}
      />
    ),
    renderHeaderFilter: params => <CheckUnCheckFilter {...params} />,
    align: columnAlignment.center,
    headerAlign: columnAlignment.center,
    filter: true,
    sortable: true,
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
  {
    field: 'invoiceContactFlag',
    headerName: 'Invoice',
    renderCell: ({row}) => (
      <Checkbox
        disabled
        checked={row.invoiceContactFlag}
        // onChange={e => onChkChanged(e, 'includeInEmail', row.id)}
      />
    ),
    renderHeaderFilter: params => <CheckUnCheckFilter {...params} />,
    align: columnAlignment.center,
    headerAlign: columnAlignment.center,
    filter: true,
    sortable: true,
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
  {
    field: 'includeInTextYN',
    headerName: 'Text',
    renderCell: ({row}) => (
      <Checkbox
        disabled
        checked={row.includeInTextYN}
        // onChange={e => onChkChanged(e, 'includeInEmail', row.id)}
      />
    ),
    renderHeaderFilter: params => <CheckUnCheckFilter {...params} />,
    align: columnAlignment.center,
    headerAlign: columnAlignment.center,
    filter: true,
    sortable: true,
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
  {
    field: 'includeInVoiceYN',
    headerName: 'Voice',
    renderCell: ({row}) => (
      <Checkbox
        disabled
        checked={row.includeInVoiceYN}
        // onChange={e => onChkChanged(e, 'includeInEmail', row.id)}
      />
    ),
    renderHeaderFilter: params => <CheckUnCheckFilter {...params} />,
    align: columnAlignment.center,
    headerAlign: columnAlignment.center,
    filter: true,
    sortable: true,
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },

  {
    field: 'emailAddr',
    headerName: 'Email Addr',
    sortable: true,
    filter: true,
    minWidth: 180,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'emailAddr2',
    headerName: 'Email Addr 2',
    sortable: true,
    filter: true,
    minWidth: 180,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'phone',
    headerName: 'Phone',
    sortable: true,
    filter: true,
    minWidth: 180,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'phoneExt',
    headerName: constants.PHONE_EXT,
    minWidth: 120,
    sortable: true,
    filter: false,
    renderHeaderFilter: () => null,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'fax',
    headerName: constants.FAX,
    minWidth: 120,
    sortable: true,
    filter: false,
    renderHeaderFilter: () => null,

    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'faxExt',
    headerName: constants.FAX_EXT,
    minWidth: 120,
    sortable: true,
    filter: false,
    renderHeaderFilter: () => null,

    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'addrLine1',
    headerName: 'Addr Line1',
    minWidth: 250,
    sortable: true,
    filter: false,
    renderHeaderFilter: () => null,

    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'addrLine2',
    headerName: 'Addr Line2',
    minWidth: 250,
    sortable: true,
    filter: false,
    renderHeaderFilter: () => null,

    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'addrLine3',
    headerName: 'Addr Line3',
    minWidth: 250,
    sortable: true,
    filter: false,
    renderHeaderFilter: () => null,

    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'addrLine4',
    headerName: 'Addr Line4',
    minWidth: 250,
    sortable: true,
    filter: false,
    renderHeaderFilter: () => null,

    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'addrLine5',
    headerName: 'Addr Line5',
    minWidth: 250,
    sortable: true,
    filter: false,
    renderHeaderFilter: () => null,

    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'city',
    headerName: constants.CITY,
    minWidth: 120,
    sortable: true,
    filter: false,
    renderHeaderFilter: () => null,

    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'state',
    headerName: constants.STATE,
    minWidth: 120,
    sortable: true,
    filter: false,
    renderHeaderFilter: () => null,

    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'postalCode',
    headerName: constants.POSTAL_CODE,
    minWidth: 120,
    sortable: true,
    filter: false,
    renderHeaderFilter: () => null,

    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'country',
    headerName: constants.COUNTRY,
    minWidth: 120,
    sortable: true,
    filter: false,
    renderHeaderFilter: () => null,

    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'comment',
    headerName: constants.COMMENT,
    minWidth: 220,
    sortable: true,
    filter: false,
    renderHeaderFilter: () => null,

    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'companyId',
    headerName: 'Company ID',
    sortable: true,
    minWidth: 120,
    filter: true,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'insertDate',
    headerName: 'InsertDate',
    sortable: true,
    filter: false,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_TIME_FORMAT)
        : '',
    minWidth: 120,
    renderHeaderFilter: () => null,

    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'updateDate',
    headerName: 'UpdateDate',
    sortable: true,
    filter: false,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_TIME_FORMAT)
        : '',
    minWidth: 120,
    renderHeaderFilter: () => null,

    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'assignedUserId',
    headerName: 'AssignedUserID',
    sortable: true,
    filter: false,
    minWidth: 120,
    renderHeaderFilter: () => null,

    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'creditClassId',
    headerName: 'CreditClassID',
    sortable: true,
    filter: false,
    minWidth: 80,
    renderHeaderFilter: () => null,

    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'udf1',
    headerName: 'Cntct UDF 1',
    sortable: true,
    filter: false,
    minWidth: 80,
    renderHeaderFilter: () => null,

    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'udf2',
    headerName: 'Cntct UDF 2',
    sortable: true,
    filter: false,
    minWidth: 80,
    renderHeaderFilter: () => null,

    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'udf3',
    headerName: 'Cntct UDF 3',
    sortable: true,
    filter: false,
    minWidth: 80,
    renderHeaderFilter: () => null,

    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'udf4',
    headerName: 'Cntct UDF 4',
    sortable: true,
    filter: false,
    minWidth: 80,
    renderHeaderFilter: () => null,

    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'udf5',
    headerName: 'Cntct UDF 5',
    sortable: true,
    filter: false,
    minWidth: 80,
    renderHeaderFilter: () => null,

    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'udf6',
    headerName: 'Cntct UDF 6',
    sortable: true,
    filter: false,
    renderHeaderFilter: () => null,

    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'udf7',
    headerName: 'Cntct UDF 7',
    sortable: true,
    filter: false,
    minWidth: 80,
    renderHeaderFilter: () => null,

    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'udf8',
    headerName: 'Cntct UDF 8',
    sortable: true,
    filter: false,
    minWidth: 80,
    type: 'string',
    renderHeaderFilter: () => null,

    filterOperators: filterOperators.string,
  },
  {
    field: 'udf9',
    headerName: 'Cntct UDF 9',
    sortable: true,
    filter: false,
    minWidth: 80,
    renderHeaderFilter: () => null,

    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'udf10',
    headerName: 'Cntct UDF 10',
    sortable: true,
    filter: false,
    renderHeaderFilter: () => null,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'isInactive',
    headerName: 'IsInactive',
    renderCell: ({row}) => <Checkbox disabled checked={row.isInActive} />,
    renderHeaderFilter: params => <CheckUnCheckFilter {...params} />,
    align: columnAlignment.center,
    headerAlign: columnAlignment.center,
    filter: true,
    sortable: true,
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
];
