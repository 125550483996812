import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import {GridPaginationModel, GridSortModel} from '@mui/x-data-grid-pro';
import {constants} from '../../../constants/common';
import {cleanHtml, isEmpty} from '../../../lib/utils';
import {DataGrid} from '../../../ui/data';
import {Button} from '../../../ui/inputs';
import {accountNotesColumns} from './columns';
import {getAllAccountNotes} from '../../../store/actions/accountNotesActions';
import {getUserData} from '../../../store/actions/userActions';
import {addToNote, deleteAccountNotes} from '../../../services/communications';
import {Dialog} from '../../../ui/feedback';
import AddNoteTemplate from '../AddNoteTemplate';
import {PAGE_SIZE} from '../../../utils/constants';
import {Typography} from '../../../ui/displays';
import {Box} from '../../../ui/layouts';

export const AccountNotes = ({
  showTitle = true,
  custKey,
  userKey,
  custName,
  accountOverview = false,
}: {
  showTitle?: boolean;
  userKey: string;
  custKey: number;
  custName: string;
  accountOverview: boolean;
}) => {
  const dispatch = useDispatch<any>();
  const [showAddNoteDialog, setShowAddNoteDialog] = useState(false);
  const [noteTxt, setNoteTxt] = useState('');
  const [skip, setSkip] = useState(0);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: PAGE_SIZE,
  });
  const [sortModel, setSortModel] = useState<any>({
    sortBy: '',
    sortDirection: '',
  });
  const toast = useSelector((store: any) => store.generalReducer?.toastRef);
  const {accountNotes, accountNotesCount, accountNotesLoading} = useSelector(
    (store: any) => store.accountNotesReducer,
  );
  const selectedCustomer = useSelector(
    (store: any) => store.generalReducer?.selectedCustomer,
  );
  const {groupKey, UserType, sessionUserName: userName} = getUserData();
  const loadNotes = ({
    pageSizeParam = PAGE_SIZE,
    skipParam = 0,
    sortByParam = '',
    sortDirectionParam = 0,
  }: {
    pageSizeParam: number;
    skipParam: number;
    sortByParam: string;
    sortDirectionParam: number;
  }) => {
    if (
      !isEmpty(custKey) ||
      !isEmpty(userKey) ||
      !isEmpty(selectedCustomer?.custKey) ||
      !isEmpty(selectedCustomer?.userKey)
    ) {
      dispatch(
        getAllAccountNotes({
          custKey: custKey || selectedCustomer?.custKey,
          userKey: userKey || selectedCustomer?.userKey,
          pageSize: pageSizeParam,
          skip: skipParam,
          sortBy: sortByParam,
          sortDirection: sortDirectionParam,
        }),
      );
    }
  };

  // reset pagination
  const resetPagination = () => {
    setPaginationModel({
      pageSize: paginationModel.pageSize,
      page: 0,
    });
  };
  const onAddNote = async () => {
    setShowAddNoteDialog(false);
    const custKeyPayload = custKey || selectedCustomer?.custKey;
    const userKeyPayload = userKey || selectedCustomer?.userKey;
    const custNamePayload = custName || selectedCustomer?.custName;

    const request = {
      custKey: custKeyPayload,
      userKey: userKeyPayload,
      groupKey,
      payload: {
        IsAccountNote: true,
        TaskDescription: constants.defaultIssueDescription(
          userName,
          custNamePayload,
        ),
        UserType,
        Note: cleanHtml(noteTxt),
        ActivityType: 7,
      },
    };
    try {
      await addToNote(request);
      loadNotes({
        pageSizeParam: paginationModel.pageSize,
        skipParam: 0,
        sortByParam: sortModel.sortBy,
        sortDirectionParam: sortModel.sortDirection,
      });
      resetPagination();
      setNoteTxt('');
    } catch (error) {
      toast?.current.show({
        severity: 'warn',
        summary: constants.SOMETHING_WENT_WRONG,
        detail: '',
      });
    }
  };
  const handleDeleteAccountNotes = async (rowData: any) => {
    try {
      await deleteAccountNotes(rowData.taskKey, rowData.taskActKey);
      loadNotes({
        pageSizeParam: paginationModel.pageSize,
        skipParam: 0,
        sortByParam: sortModel.sortBy,
        sortDirectionParam: sortModel.sortDirection,
      });
      resetPagination();
    } catch (error) {
      toast?.current.show({
        severity: 'warn',
        summary: constants.SOMETHING_WENT_WRONG,
        detail: '',
      });
    }
  };
  useEffect(() => {
    if (custKey === selectedCustomer?.custKey) {
      loadNotes({
        pageSizeParam: paginationModel.pageSize,
        skipParam: skip,
        sortByParam: sortModel.sortBy,
        sortDirectionParam: sortModel.sortDirection,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [custKey, selectedCustomer?.custKey]);

  const onPageChange = (args: GridPaginationModel) => {
    const pageSkip =
      args.pageSize !== paginationModel.pageSize
        ? 0
        : (args.page + 1) * args.pageSize - args.pageSize;
    setPaginationModel({
      pageSize: args.pageSize,
      page: args.pageSize !== paginationModel.pageSize ? 0 : args.page,
    });
    setSkip(pageSkip);
    loadNotes({
      pageSizeParam: args.pageSize,
      skipParam: pageSkip,
      sortByParam: sortModel.sortBy,
      sortDirectionParam: sortModel.sortDirection,
    });
  };

  const onSortChange = (args: GridSortModel) => {
    const {field, sort} = args[0] || {field: '', sort: null};
    // TODO: Remove this ternary operator when we fix service layer
    const sortDirection = sort === 'asc' ? 1 : 0;
    setSortModel({sortBy: field, sortDirection});
    loadNotes({
      pageSizeParam: paginationModel.pageSize,
      skipParam: skip,
      sortByParam: field,
      sortDirectionParam: sortDirection,
    });
  };

  const onHideDialog = () => {
    setNoteTxt('');
    setShowAddNoteDialog(false);
  };

  const columns = accountNotesColumns(handleDeleteAccountNotes);
  return (
    // {`${accountOverview ? 'margin-top-40-px' : 'pt-2'}`}
    <Box marginTop={accountOverview ? 3 : 1}>
      <div
        className={`d-flex flex-wrap mb-3  ${
          showTitle ? 'justify-content-between' : 'justify-content-end pe-2'
        }`}
      >
        {showTitle && (
          <Typography
            variant="h4"
            fontWeight={500}
            fontSize="24px"
            style={{color: '#0F3044'}}
          >
            {constants.ACCOUNT_NOTES}
          </Typography>
        )}

        <Button variant="contained" onClick={() => setShowAddNoteDialog(true)}>
          <i className="pi pi-add-note pi-custom" /> {constants.ADD_A_NOTE}
        </Button>
      </div>
      <div className="my-1">
        <DataGrid
          height={600}
          columns={columns}
          rows={accountNotes}
          loading={accountNotesLoading}
          rowCount={accountNotesCount}
          onPageChange={onPageChange}
          getRowId={row => row.taskActKey}
          onSortChange={onSortChange}
          paginationModel={paginationModel}
        />
      </div>
      <Dialog
        title={constants.ADD_ACCOUNT_NOTE}
        open={showAddNoteDialog}
        onClose={onHideDialog}
        fullWidth
      >
        <AddNoteTemplate
          onAddNote={onAddNote}
          // @ts-ignore
          setNoteTxt={setNoteTxt}
          noteTxt={noteTxt}
          onHideDialog={onHideDialog}
        />
      </Dialog>
    </Box>
  );
};
