import {Link} from 'react-router-dom';
import {filterOperators} from '../../../../lib/commonTableHelpers';
import {ActivityByUser, ActivityDetail} from './summaryTypes';

export const byUsersColumns = [
  {
    field: 'userId',
    headerName: 'UserID',
    sortable: true,
    width: 200,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'priority',
    headerName: 'Priority',
    sortable: true,
    flex: 0.3,
    minWidth: 50,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'alertDesc',
    headerName: 'AlertDesc',
    sortable: true,
    flex: 1,
    type: 'string',
    filterOperators: filterOperators.string,
    renderCell: (cellValues: {row: ActivityByUser}) => {
      return (
        <Link className="blueLink cursor-pointer" to={cellValues.row.link}>
          {cellValues.row.alertDesc}
        </Link>
      );
    },
  },
  {
    field: 'count',
    headerName: 'Count',
    sortable: true,
    flex: 0.3,
    minWidth: 50,
    type: 'number',
    filterOperators: filterOperators.number,
  },
];

export const activityDetailColumns = [
  {
    field: 'userID',
    headerName: 'UserID',
    sortable: true,
    width: 100,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'priority',
    headerName: 'Priority',
    sortable: true,
    minWidth: 50,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'alertID',
    headerName: 'ActivityID',
    width: 150,
  },
  {
    field: 'issue',
    headerName: 'Issue',
    width: 250,
    renderCell: (cellValues: {row: ActivityDetail}) => {
      return (
        <Link className="blueLink cursor-pointer" to={cellValues.row.link}>
          {cellValues.row.issue}
        </Link>
      );
    },
  },
  {
    field: 'activityNote',
    headerName: 'Note',
    width: 250,
  },
  {
    field: 'count',
    headerName: 'Count',
    filterOperators: filterOperators.number,
  },
  {
    field: 'creditClass',
    headerName: 'CreditClass',
  },
  {
    field: 'activityID',
    headerName: 'ActivityType',
  },
];
