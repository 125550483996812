import {Grid} from '@mui/material';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Dialog} from '../../../ui/feedback';
import {Checkbox, CheckboxLabel, Select} from '../../../ui/inputs';
import {constants} from '../../../constants/common';
import {getManagedUsers, getUserData} from '../../../store/actions/userActions';
import {updateCommunications} from '../../../store/actions/communicationActions';
import {useQueryKeys} from '../../../hooks/useQueryKeys';

export const EditCommunication = ({
  open,
  onClose,
  custActivityUpdateKeys,
  onCommunicationUpdate,
  onRefresh,
}: {
  open: boolean;
  onClose: () => void;
  custActivityUpdateKeys: any[];
  onCommunicationUpdate: () => void;
  onRefresh: () => void;
}) => {
  const {groupKey} = getUserData();
  const {userKey} = useQueryKeys();
  const [enableFields, setEnableFields] = useState<{
    enableAssignedUser: boolean;
    enableMarkAsComplete: boolean;
    enableArchiveUser: boolean;
    enableMarkAsRead: boolean;
  }>({
    enableAssignedUser: false,
    enableMarkAsComplete: false,
    enableArchiveUser: false,
    enableMarkAsRead: false,
  });

  const [selectedEditValues, setSelectedEditValues] = useState<{
    selectedUser: number | undefined;
    isMarkAsComplete: boolean;
    isArchiveUser: boolean;
    isMarkAsUnread: boolean;
  }>({
    selectedUser: undefined,
    isArchiveUser: false,
    isMarkAsComplete: false,
    isMarkAsUnread: false,
  });

  const [updatedValues, setUpdatedValues] = useState({});
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const dispatch = useDispatch();
  const userList = useSelector((store: any) => {
    const {users} = store.usersReducer;
    return users.map((user: {userName: string; mgdUserKey: number}) => {
      return {label: user.userName, value: user.mgdUserKey};
    });
  });

  useEffect(() => {
    // @ts-ignore
    dispatch(getManagedUsers({userKey}));
  }, [dispatch, userKey]);

  const setEnableDisableFields = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {checked, name} = e.target;
    setEnableFields({...enableFields, [name]: Boolean(checked)});
  };

  const updateEditedValues = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {checked, name} = e.target;
    setSelectedEditValues({...selectedEditValues, [name]: checked});
  };

  const onUpdate = () => {
    const {selectedUser, isArchiveUser, isMarkAsComplete, isMarkAsUnread} =
      selectedEditValues;
    const {enableArchiveUser, enableMarkAsComplete, enableMarkAsRead} =
      enableFields;
    const options = {
      CustActivityUpdateKeys: custActivityUpdateKeys,
      iUserKey: selectedUser,
      iUpdateUserYN: selectedUser ? 1 : 0,
      iComplete: enableMarkAsComplete ? 1 : 0,
      iUpdateCompleteYN: isMarkAsComplete ? 1 : 0,
      iMarkAsArchived: enableArchiveUser ? 1 : 0,
      iUpdateArchiveYN: isArchiveUser ? 1 : 0,
      iMarkAsReadUnread: isMarkAsUnread ? 1 : 0,
      iUpdateReadUnreadYN: enableMarkAsRead ? 1 : 0,
      iActivityNotesYN: true,
      activityNotes: '',
      _iUserId: groupKey,
    };
    setUpdatedValues(options);
    setShowConfirmDialog(true);
  };

  const updateCommunication = () => {
    // @ts-ignore
    dispatch(updateCommunications(updatedValues));
    setShowConfirmDialog(false);
    onClose();
    onCommunicationUpdate();
    onRefresh();
  };

  return (
    <Dialog
      title={constants.UPDATE_SELECTED_RECORDS}
      showConfirmButton
      showCancelButton
      confirmButtonText="Update"
      open={open}
      onClose={onClose}
      onConfirm={() => onUpdate()}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <CheckboxLabel
            label="Assigned User"
            name="enableAssignedUser"
            onChange={e => setEnableDisableFields(e)}
            checked={enableFields.enableAssignedUser}
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            label="Select user to assign"
            items={userList}
            fullWidth
            value={selectedEditValues.selectedUser as unknown as string}
            onChange={e => {
              if (typeof e.target.value === 'number') {
                setSelectedEditValues({
                  ...selectedEditValues,
                  selectedUser: e.target.value,
                });
              }
            }}
            disabled={!enableFields.enableAssignedUser}
          />
        </Grid>
        <Grid item xs={6}>
          <CheckboxLabel
            label="Mark as complete"
            name="enableMarkAsComplete"
            onChange={e => setEnableDisableFields(e)}
            checked={enableFields.enableMarkAsComplete}
          />
        </Grid>
        <Grid item xs={6}>
          <Checkbox
            onChange={updateEditedValues}
            name="isMarkAsComplete"
            disabled={!enableFields.enableMarkAsComplete}
            checked={selectedEditValues.isMarkAsComplete}
          />
        </Grid>
        <Grid item xs={6}>
          <CheckboxLabel
            label="Archive issue"
            name="enableArchiveUser"
            checked={enableFields.enableArchiveUser}
            onChange={e => setEnableDisableFields(e)}
          />
        </Grid>
        <Grid item xs={6}>
          <Checkbox
            onChange={updateEditedValues}
            name="isArchiveUser"
            disabled={!enableFields.enableArchiveUser}
            checked={selectedEditValues.isArchiveUser}
          />
        </Grid>
        <Grid item xs={6}>
          <CheckboxLabel
            label="Mark as Read or Unread"
            name="enableMarkAsRead"
            checked={enableFields.enableMarkAsRead}
            onChange={e => setEnableDisableFields(e)}
          />
        </Grid>
        <Grid item xs={6}>
          <Checkbox
            onChange={updateEditedValues}
            name="isMarkAsUnread"
            disabled={!enableFields.enableMarkAsRead}
            checked={selectedEditValues.isMarkAsUnread}
          />
        </Grid>
      </Grid>
      <Dialog
        title={constants.UPDATE}
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        showConfirmButton
        confirmButtonText={constants.UPDATE}
        onConfirm={() => updateCommunication()}
      >
        <span>
          {constants.THIS_UPDATE_CANNOT_BE_UNDONE}, {constants.CONTINUE}?
        </span>
      </Dialog>
    </Dialog>
  );
};
