import {constants, fileNames} from '../../constants/common';
import {pendingPaymentsApproveOrReject} from '../../services/payment';
import {
  exportScheduledPayments,
  fetchSchedulePaymentsInvoicesDetails,
  fetchScheduledPayments,
} from '../../services/schedulePayments';
import {downloadExcel} from '../../utils/fileUtils';
import {actionTypes} from '../constants/actionTypes';

export const getScheduledPayments = options => (dispatch, getState) => {
  const toast = getState().generalReducer?.toastRef;
  const scheduledPaymentsState = getState().scheduledPaymentsReducer;
  const {
    userKey,
    groupKey,
    settingKey,
    sortQuery,
    filterQuery,
    pageSize,
    skip,
    includePastDue,
    sqlQuery,
  } = options;
  const filterQueryVal =
    settingKey ===
    scheduledPaymentsState?.scheduledPaymentsViewSettings?.settingsKey
      ? filterQuery
      : '';

  dispatch({type: actionTypes.FETCHING_SCHEDULED_PAYMENTS});
  fetchScheduledPayments({
    scheduledPaymentsState,
    userKey,
    groupKey,
    settingKey,
    sortQuery,
    globalFilterQuery: filterQueryVal,
    pageSize,
    skip,
    includePastDue,
    sqlQuery,
  })
    .then(res => {
      dispatch({
        type: actionTypes.FETCHED_SCHEDULED_PAYMENTS,
        scheduledPaymentsData: res.data.value,
        scheduledPaymentsCount: res.data.count,
        scheduledPaymentsNextLink: res.data.nextLink,
      });
    })
    .catch(() => {
      toast?.current.show({
        severity: 'warn',
        summary: constants.SOMETHING_WENT_WRONG,
        detail: '',
      });
      dispatch({type: actionTypes.FETCHING_SCHEDULED_PAYMENTS_FAILED});
    });
};

export const updateScheduledPaymentsViewSettingObject = ({
  settingId,
  viewSettings,
}) => {
  return {
    type: actionTypes.SET_SCHEDULED_PAYMENTS_VIEW_SETTING_OBJECT,
    payload: {settingId, viewSettings},
  };
};

export const scheduledPaymentsExportToExcel =
  ({
    userKey,
    groupKey,
    modeType,
    settingKey,
    sortQuery,
    filterQuery,
    includePastDue,
    sqlQuery,
    skip,
    pageSize,
  }) =>
  (dispatch, getState) => {
    const toast = getState().generalReducer?.toastRef;
    const scheduledPaymentsState = getState().scheduledPaymentsReducer;

    const filterQueryVal =
      settingKey ===
      scheduledPaymentsState?.scheduledPaymentsViewSettings?.settingsKey
        ? filterQuery
        : '';

    dispatch({type: actionTypes.EXPORTING_SCHEDULED_PAYMENTS_TO_EXCEL});
    exportScheduledPayments({
      userKey,
      groupKey,
      settingKey,
      sortQuery,
      globalFilterQuery: filterQueryVal,
      includePastDue,
      sqlQuery,
      skip,
      pageSize,
    })
      .then(res => {
        downloadExcel(
          res.data,
          fileNames[modeType] ?? 'ExportFile',
          toast,
          constants.SCHEDULED_PAYMENTS_EXPORTED_TO_EXCEL,
        );
        dispatch({type: actionTypes.EXPORTED_SCHEDULED_PAYMENTS_TO_EXCEL});
      })
      .catch(() => {
        dispatch({
          type: actionTypes.EXPORTING_SCHEDULED_PAYMENTS_TO_EXCEL_FAILED,
        });
      });
  };

export const pendingPaymentsActions =
  (options, onRefresh, setShowDuePaymentsNotification) =>
  (dispatch, getState) => {
    const toast = getState().generalReducer?.toastRef;
    const {actionType} = options;

    dispatch({type: actionTypes.APPROVING_SCHEDULED_PAYMENTS});
    pendingPaymentsApproveOrReject(options)
      .then(res => {
        dispatch({type: actionTypes.APPROVED_SCHEDULED_PAYMENTS});
        if (
          actionType === constants.APPROVEANDPROCESS &&
          res.data.duePaymentsCount > 0
        ) {
          setShowDuePaymentsNotification(true);
        }
        onRefresh();
      })
      .catch(() => {
        toast?.current.show({
          severity: 'error',
          summary: `Unable to ${actionType.toLocaleUpperCase()} Scheduled Payments`,
          detail: '',
        });
        dispatch({type: actionTypes.APPROVING_SCHEDULED_PAYMENTS_FAILED});
      });
  };

export const getSchedulePaymentInvoicesDetails =
  (groupKey, pendPmtKey) => (dispatch, getState) => {
    const toast = getState().generalReducer?.toastRef;

    fetchSchedulePaymentsInvoicesDetails(groupKey, pendPmtKey)
      .then(res => {
        dispatch({
          type: actionTypes.FETCHED_SCHEDULED_PAYMENTS_INVOICES_DETAILS,
          scheduledPaymentsInvoicesData: res.data,
          pendPmtKey,
        });
      })
      .catch(() => {
        toast?.current.show({
          severity: 'warn',
          summary: constants.SOMETHING_WENT_WRONG,
          detail: '',
        });
        dispatch({
          type: actionTypes.FETCHING_SCHEDULED_PAYMENTS_INVOICES_DETAILS_FAILED,
        });
      });
  };

export const cleanUpScheduledPayments = () => {
  return {
    type: actionTypes.CLEAN_UP_SCHEDULED_PAYMENTS,
  };
};
