import {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {constants} from '../../../constants/common';
import {updateTableFilterDisplayType} from '../../../store/actions/generalActions';
import {AddRecipientColumns} from './AddRecipientColumns';
import Backdrop from '../../common/Backdrop';
import {VoiceTextColumns} from './VoiceTextColumns';
import {Button} from '../../../ui/inputs';
import {DataGrid} from '../../../ui/data';

interface IAddressBook {
  isTextTab?: boolean;
  isVoiceTab?: boolean;
  setShowAddContactDialog: (val: boolean) => void;
  setShowAddPersonalContactsDialog: (val: boolean) => void;
  updateRecipientValues: (options: any) => void;
  textFieldValueArray: string[] | null;
  fieldKey: 'phone' | 'emailAddr';
}

function AddressBook({
  isTextTab = false,
  isVoiceTab = false,
  setShowAddContactDialog = () => {},
  setShowAddPersonalContactsDialog = () => {},
  updateRecipientValues = () => {},
  textFieldValueArray = null,
  fieldKey,
}: IAddressBook) {
  const dispatch = useDispatch();

  const addedContact = useSelector(
    (store: any) => store.activitiesReducer.addedContact,
  );
  const contactsList = useSelector(
    (store: any) => store.activitiesReducer.contactsList,
  );
  const fetchingActivitiesContactsList = useSelector(
    (store: any) => store.activitiesReducer.fetchingActivitiesContactsList,
  );
  const [tableData, setTableData] = useState<any[]>([]);

  useEffect(() => {
    dispatch(updateTableFilterDisplayType('menu'));
  }, [addedContact]);

  useEffect(() => {
    if (contactsList === undefined) {
      return;
    }
    const contactsListTemp = contactsList?.map((cList: any) => {
      let updatedCList = {
        ...cList,
        selectedRecipient: cList.selectedRecipient || false,
      };
      const fieldType = fieldKey === 'phone' ? cList.phone : cList.emailAddr;
      if (Array.isArray(textFieldValueArray)) {
        textFieldValueArray?.forEach(field => {
          if (field.trim() === fieldType + ''.trim()) {
            updatedCList = {...cList, selectedRecipient: true};
          }
        });
      }
      return updatedCList;
    });
    let selectedRecipientTemp = contactsListTemp.length
      ? contactsListTemp.filter(
          (data: any) => data?.selectedRecipient === true && data,
        )
      : [];
    selectedRecipientTemp = selectedRecipientTemp.filter((d: any) => d);
    updateRecipientValues(selectedRecipientTemp);

    setTableData(contactsListTemp);
  }, [contactsList]);

  const onChkChanged = (e: any, field: string, rowIndex: number) => {
    const tableDataTemp = tableData.map((data, index) => {
      if (index === rowIndex) {
        return {...data, [field]: e.target.checked};
      }
      return data;
    });
    const selectedRecipientTemp =
      tableDataTemp.length &&
      tableDataTemp.filter(data => data.selectedRecipient === true && data);
    updateRecipientValues(selectedRecipientTemp);
    setTableData(tableDataTemp);
  };

  return (
    <>
      {isVoiceTab || isTextTab ? (
        ' '
      ) : (
        <>
          {fetchingActivitiesContactsList && <Backdrop />}
          <div className="d-flex justify-content-between border-0">
            <div className="m-1">
              <Button
                size="medium"
                variant="contained"
                className="me-3"
                onClick={() => setShowAddContactDialog(true)}
              >
                {constants.ADD_CONTACT}
              </Button>
              <Button
                size="medium"
                variant="contained"
                onClick={() => setShowAddPersonalContactsDialog(true)}
              >
                {constants.PERSONAL_CONTACTS}
              </Button>
            </div>
          </div>
        </>
      )}
      <DataGrid
        disableColumnMenu
        loading={false}
        hidePagination
        height={250}
        columns={
          isTextTab || isVoiceTab
            ? VoiceTextColumns(onChkChanged)
            : AddRecipientColumns(onChkChanged) || []
        }
        rows={
          tableData?.length
            ? tableData.map(row => ({...row, id: row.rowIndex}))
            : []
        }
        getRowId={row => row.rowIndex}
        density="compact"
      />
    </>
  );
}

AddressBook.propTypes = {
  setShowAddContactDialog: PropTypes.func,
  setShowAddPersonalContactsDialog: PropTypes.func,
  updateRecipientValues: PropTypes.func,
  isVoiceTab: PropTypes.bool,
  isTextTab: PropTypes.bool,
  textFieldValueArray: PropTypes.arrayOf(PropTypes.string) || null,
  fieldKey: PropTypes.string,
};

export default AddressBook;
