import {constants} from '../../../../../constants/common';
import Button from '../../../../../ui/inputs/button/Button';
import Checkbox from '../../../../../ui/inputs/checkbox/Checkbox';

export const contactsColumns = ({
  onSelectedContactChanged,
  handleDeleteContact,
}) => {
  return [
    {
      headerName: 'Select Contact',
      field: 'selectedContact',
      align: 'center',
      headerAlign: 'center',
      renderCell: ({row}) => (
        <Checkbox
          checked={row?.selectedContact}
          onChange={e =>
            onSelectedContactChanged(
              e.target.checked,
              'selectedContact',
              row?.contactKey,
            )
          }
        />
      ),
      minWidth: 80,
    },
    {
      field: 'contactName',
      headerName: 'Contact Name',
      renderCell: ({row}) => (
        <span title={row?.contactName}>{row?.contactName}</span>
      ),
      minWidth: 180,
    },
    {
      field: 'phone',
      headerName: 'Phone',
      renderCell: ({row}) => <span title={row?.phone}>{row?.phone}</span>,
      minWidth: 180,
    },
    {
      field: 'primaryContactFlag',
      align: 'center',
      headerAlign: 'center',
      headerName: 'Primary',
      renderCell: ({row}) => {
        return <Checkbox checked={row?.primaryContactFlag} disabled />;
      },
      minWidth: 80,
    },
    {
      field: 'includeInEmailYN',
      align: 'center',
      headerAlign: 'center',
      headerName: 'Email',
      renderCell: ({row}) => {
        return <Checkbox checked={row?.includeInEmailYN} disabled />;
      },
      minWidth: 80,
    },

    {
      field: 'includeInEmailYN2',
      align: 'center',
      headerAlign: 'center',
      headerName: 'Email2',
      renderCell: ({row}) => {
        return <Checkbox checked={row?.includeInEmailYN2} disabled />;
      },
      minWidth: 80,
    },
    {
      field: 'actionDelete',
      headerAlign: 'center',
      headerName: '',
      renderCell: ({row}) => {
        return (
          <Button
            size="small"
            variant={row?.editDisabled ? 'text' : 'contained'}
            onClick={() => handleDeleteContact(row)}
          >
            {constants.DELETE}
          </Button>
        );
      },
      minWidth: 120,
    },
  ];
};
