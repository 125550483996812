import {useState} from 'react';
import {useSelector} from 'react-redux';
import {Outlet} from 'react-router-dom';
import {constants} from '../../../../../constants/common';
import {Button, CheckboxLabel} from '../../../../../ui/inputs';
import TextField from '../../../../../ui/inputs/textfield/TextField';
import {getUserData} from '../../../../../store/actions/userActions';
import {purgePendingPayments} from '../../../../../services/payment';
import Backdrop from '../../../../../ui/utils/backdrop/Backdrop';
import {Box} from '../../../../../ui/layouts';

export const PendingPaymentsPurge = () => {
  const toast = useSelector((store: any) => store.generalReducer?.toastRef);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [purgeDeclined, setPurgeDeclined] = useState<boolean>(true);
  const [purgeNoResponse, setPurgeNoResponse] = useState<boolean>(true);
  const [purgeReconciled, setPurgeReconciled] = useState<boolean>(false);
  const [purgeDaysCount, setPurgeDaysCount] = useState<number>(30);

  const {groupKey} = getUserData();

  const onRefresh = () => {
    setPurgeNoResponse(true);
    setPurgeReconciled(false);
    setPurgeDeclined(true);
    setPurgeDaysCount(30);
  };

  const handlePurge = () => {
    setIsLoading(true);
    const options = {
      groupKey,
      purgeDays: purgeDaysCount,
      isPurgeDeclined: purgeDeclined,
      isPurgeNoResponse: purgeNoResponse,
      isPurgeReconciled: purgeReconciled,
    };

    purgePendingPayments(options, groupKey)
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        toast?.current.show({
          severity: 'warn',
          summary: constants.SOMETHING_WENT_WRONG,
          detail: '',
        });
      });
  };
  return (
    <Box marginTop="12px">
      <Outlet context={{onReload: onRefresh}} />
      <div>
        <Backdrop isLoading={isLoading} open={isLoading} />
        <div className="d-flex gap-2 align-items-center border-1-px-solid">
          <div className="d-flex gap-2 align-items-center mx-5">
            Purge Records More Than
            <div className="width-100-px ">
              <TextField
                type="number"
                margin="dense"
                InputLabelProps={{
                  shrink: true,
                }}
                value={purgeDaysCount.toString()}
                onChange={e => {
                  const inputValue = e.target.value;
                  if (Number(inputValue) > constants.MAX_PURGE_DAYS) {
                    setPurgeDaysCount(constants.MAX_PURGE_DAYS);
                  } else {
                    setPurgeDaysCount(Number(inputValue));
                  }
                }}
              />
            </div>
            Days Old
          </div>

          <CheckboxLabel
            checked={purgeDeclined}
            label={constants.PURGE_DECLINED}
            onChange={e => setPurgeDeclined(e.target.checked)}
          />
          <CheckboxLabel
            checked={purgeNoResponse}
            label={constants.PURGE_NO_RESPONSE}
            onChange={e => setPurgeNoResponse(e.target.checked)}
          />
          <CheckboxLabel
            checked={purgeReconciled}
            label={constants.PURGE_RECONCILED}
            onChange={e => setPurgeReconciled(e.target.checked)}
          />
          <Button variant="contained" onClick={handlePurge}>
            {constants.RUN_PURGE}
          </Button>
        </div>
      </div>
    </Box>
  );
};
