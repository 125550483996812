import {useDispatch, useSelector} from 'react-redux';
import {Outlet, useLocation} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {
  GridColDef,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridLogicOperator,
  GridPaginationModel,
  GridSortModel,
} from '@mui/x-data-grid-pro';
import {
  DataGrid,
  FilterPopoverProvider,
  QueryReturnValue,
  convertMUIToQueryBuilder,
  generateJsonAndSql,
  generateJsonFromSql,
} from '../../../ui/data';
import {ViewSettings} from './ViewSettings';
import {communicationColumns} from './columns';
import {getUserData} from '../../../store/actions/userActions';
import {
  cleanUpCommunication,
  communicationExportToExcel,
  getAllCommunicationsAndSettings,
  updateAlertDescription,
  updateIsMailNotRead,
  updateIsShowMailArchived,
  updateViewSettingObject,
} from '../../../store/actions/communicationActions';
import {getPageQueryParams} from '../communicationHelper';
import CommunicationToolbar from './CommunicationToolbar';
import {EditCommunication} from './EditCommunication';
import {
  applyDatatableSettings,
  convertAndAppendExistingFilters,
  getGridColumnsSettings,
  getSelectedRowsByKey,
  hasFilterValue,
  updateColumnSettings,
} from '../../../lib/commonTableHelpers';
import {useQueryKeys} from '../../../hooks/useQueryKeys';
import {
  MAX_EXCEL_DOWNLOAD,
  PAGE_SIZE,
  REPORT_TYPE,
} from '../../../utils/constants';
import {calculateColumnWidth} from '../../header/helper';
import {initialQuery} from '../../../ui/data/query-builder/queryHelper';
import {
  ColumnSetting,
  IViewSettings,
} from '../../common/view-settings/interface';
import {ISnackbarProps} from '../../../ui/feedback/snackbar/Snackbar';
import RenderDialogAndSnackbarForReports from '../../common/reports/RenderDialogAndSnackbarForReports';
import ViewSettingsModal from '../../common/view-settings/ViewSettingsModal';
import {useComponentMountStatus} from '../../../hooks/useComponentMountStatus';
import {shouldResetFilterModel} from '../../../utils/filterUtils';
import AlertsModal from '../../common/alerts/AlertsModal';
import {ViewNames} from '../../../constants/common';

interface GetAllActivitiesParams {
  SettingKey: string;
  isArchived: boolean;
  isRestricted: boolean;
  isHighlighted: boolean;
  sortQuery: GridSortModel;
  filterQuery: QueryReturnValue['json'] | undefined;
  sqlQuery: string | null;
  skipParam: number;
  pageSizeParam: number;
}

const Communication = () => {
  const dispatch = useDispatch<any>();

  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({});
  const [selectedActivities, setSelectedActivities] = useState<any[]>([]);
  const [showEditCommunication, setShowEditCommunication] = useState(false);
  const [showSelectRecordError, setShowSelectRecordError] = useState(false);
  const [sortColumn, setSortColumn] = useState<GridSortModel>([]);
  const {groupKey} = getUserData();
  const {userKey, assignedUserKey} = useQueryKeys();
  const location = useLocation();
  const {mode, activityType, alertDesc, ruleKey} = getPageQueryParams(location);
  const [bestFit, setBestFit] = useState<boolean>(false);
  const [bestFitColumns, setBestFitColumns] = useState<GridColDef[]>([]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    logicOperator: GridLogicOperator.And,
    items: [],
  });
  const [customFilterSqlValue, setCustomFilterSqlValue] = useState<
    string | null
  >(null);
  const [customFilterJsonValue, setCustomFilterJsonValue] =
    useState<QueryReturnValue['json']>();
  const [skip, setSkip] = useState(0);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: PAGE_SIZE,
  });
  const [showReportsPopup, setShowReportsPopup] = useState<boolean>(false);
  const [showViewSettingsPopup, setShowViewSettingsPopup] =
    useState<boolean>(false);
  const [showAlertsPopup, setShowAlertsPopup] = useState<boolean>(false);
  const [settingsKey, setSettingsKey] = useState<string | null>(null);

  const [snackbarObj, setSnackbarObj] = useState<ISnackbarProps>({
    message: '',
    onClose: () => {},
    open: false,
    title: '',
    type: 'success',
  });
  const isComponentMounted = useComponentMountStatus(cleanUpCommunication);

  const {
    communications,
    communicationsCount,
    communicationsLoading,
    isMailNotRead,
    isShowArchived,
    isShowActivitiesForHighlightedRow,
    viewSettings,
    settingId,
  } = useSelector((store: any) => store.communicationsReducer);

  const {toastRef: toast} = useSelector((store: any) => store.generalReducer);

  /**
   * Api call to get list of communications
   * @param isArchived  If show archived activities is enabled
   * @param isHighlighted If Show all activities for highlighted row is enabled
   * @param isRestricted If Restricted to Alert Mail Not Read is enabled
   * * @param sortQuery If sort filter is applied
   * @SettingKey Setting key selected from view setting. Setting key is mandatory
   */

  const getAllActivities = ({
    isArchived = false,
    isHighlighted = false,
    isRestricted = true,
    sortQuery = [],
    filterQuery = undefined,
    sqlQuery = null,
    SettingKey,
    pageSizeParam = PAGE_SIZE,
    skipParam = 0,
  }: {
    isArchived: boolean;
    isHighlighted: boolean;
    isRestricted: boolean;
    sortQuery: any[];
    filterQuery: QueryReturnValue['json'] | undefined;
    sqlQuery: string | null;
    SettingKey: string;
    pageSizeParam: number;
    skipParam: number;
  }) => {
    const taskKey =
      isShowActivitiesForHighlightedRow && selectedActivities.length === 1
        ? selectedActivities[0].taskKey
        : null;
    dispatch(
      // @ts-ignore
      getAllCommunicationsAndSettings({
        userKey,
        assignedUserKey,
        isShowingArchivedActivities: isArchived,
        groupKey,
        activityType,
        ruleKey,
        taskKey,
        isHighlighted,
        isRestricted,
        SettingKey,
        sortQuery,
        defaultLogicOperator: {logicOperator: GridLogicOperator.And},
        skip: skipParam,
        pageSize: pageSizeParam,
        filterQuery,
        sqlQuery,
      }),
    );
    setBestFit(false);
  };

  // Reset filter model
  const resetFilterModel = () => {
    setFilterModel({
      logicOperator: GridLogicOperator.And,
      items: [],
    });
  };

  // reset pagination
  const resetPagination = () => {
    setPaginationModel({
      pageSize: paginationModel.pageSize,
      page: 0,
    });
  };
  // Create a function to handle the repeated code
  const handleGetAllActivities = (
    filterQuery: QueryReturnValue['json'],
    sqlQuery: string | null,
  ) => {
    const params: GetAllActivitiesParams = {
      SettingKey: settingId,
      isArchived: isShowArchived,
      isRestricted: isMailNotRead,
      isHighlighted: isShowActivitiesForHighlightedRow,
      sortQuery: sortColumn,
      filterQuery,
      sqlQuery,
      skipParam: 0,
      pageSizeParam: paginationModel.pageSize,
    };

    getAllActivities(params);
    resetPagination();
  };

  /** Use Effect to fetch list of communications when filter,
   * sort and other properties are selected */
  useEffect(() => {
    if (settingsKey) {
      getAllActivities({
        SettingKey: settingsKey,
        isArchived: isShowArchived,
        isRestricted: isMailNotRead,
        isHighlighted: isShowActivitiesForHighlightedRow,
        sortQuery: sortColumn,
        skipParam: 0,
        pageSizeParam: paginationModel.pageSize,
        filterQuery: customFilterJsonValue,
        sqlQuery: customFilterSqlValue,
      });
      resetFilterModel();
      resetPagination();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isMailNotRead,
    isShowActivitiesForHighlightedRow,
    isShowArchived,
    userKey,
    settingsKey,
  ]);
  const updateTableOnColumnSettingsChange = (
    updatedColumns: ColumnSetting[],
    settingKey: string,
  ) => {
    if (settingKey === viewSettings?.settingsKey) {
      updateColumnSettings(
        updatedColumns,
        columns,
        setColumns,
        setColumnVisibilityModel,
      );
    }
  };

  /** Use effect to update and set columns based on view settings response */
  useEffect(() => {
    if (viewSettings && viewSettings.settingsKey && isComponentMounted) {
      applyDatatableSettings(
        viewSettings,
        columns,
        communicationColumns,
        undefined,
        setColumns,
        setColumnVisibilityModel,
        setSortColumn,
      );
      const jsonData =
        viewSettings?.filter?.length > 0
          ? generateJsonFromSql(viewSettings?.filter)
          : initialQuery;

      setSettingsKey(viewSettings.settingsKey);
      setCustomFilterSqlValue(viewSettings?.filter);
      setCustomFilterJsonValue(jsonData);
    }
  }, [viewSettings?.settingsKey]);

  /** On user selection reset selected activities */
  useEffect(() => {
    if (userKey) {
      setSelectedActivities([]);
    }
  }, [userKey]);

  /** Use effect to update alert description value from query params */
  useEffect(() => {
    dispatch(updateAlertDescription(alertDesc));
  }, [alertDesc]);

  /** Use effect to update isMailNotRead, IsShowMailArchived and
   * fetch grid data when navigated to different communication page */
  useEffect(() => {
    if (location.pathname.includes('/allactivities')) {
      dispatch(updateIsMailNotRead(false));
      dispatch(updateIsShowMailArchived(false));
    } else if (!location.pathname.includes('/activity')) {
      dispatch(updateIsMailNotRead(true));
      dispatch(updateIsShowMailArchived(false));
    }
  }, [location.pathname]);

  /** Communication grid props method */

  /**
   * onPageChange is prop set for communication grid to handle pagination
   * @param args GridPaginationModel object which has page which starts from 0 and skip properties
   */
  const onPageChange = (args: GridPaginationModel) => {
    const pageSkip =
      args.pageSize !== paginationModel.pageSize
        ? 0
        : (args.page + 1) * args.pageSize - args.pageSize;
    setPaginationModel({
      pageSize: args.pageSize,
      page: args.pageSize !== paginationModel.pageSize ? 0 : args.page,
    });
    setSkip(pageSkip);
    getAllActivities({
      SettingKey: settingId,
      isArchived: isShowArchived,
      isRestricted: isMailNotRead,
      isHighlighted: isShowActivitiesForHighlightedRow,
      sortQuery: sortColumn,
      pageSizeParam: args.pageSize,
      skipParam: pageSkip,
      filterQuery: customFilterJsonValue,
      sqlQuery: customFilterSqlValue,
    });
  };

  /**
   * onSortChange is prop set for communication grid to sorting
   * @param args GridPaginationModel array of object which field and sort properties
   */
  const onSortChange = (args: GridSortModel) => {
    setSortColumn(args);
    getAllActivities({
      SettingKey: settingId,
      isArchived: isShowArchived,
      isRestricted: isMailNotRead,
      isHighlighted: isShowActivitiesForHighlightedRow,
      sortQuery: args,
      skipParam: skip,
      pageSizeParam: paginationModel.pageSize,
      filterQuery: customFilterJsonValue,
      sqlQuery: customFilterSqlValue,
    });
  };

  /**
   * onColumnRowSelect Update state on checkbox selection
   * @param newSelectionModel is selected row object
   */
  const onColumnRowSelect = (newSelectionModel: any) => {
    const selectedRowsObject = getSelectedRowsByKey(
      newSelectionModel,
      communications,
      'id',
    );
    setSelectedActivities(selectedRowsObject);
  };

  /** All Toolbar methods */

  /**
   * On Refresh refetch list of activities
   */
  const onRefresh = () => {
    getAllActivities({
      SettingKey: settingId,
      isArchived: isShowArchived,
      isRestricted: isMailNotRead,
      isHighlighted: isShowActivitiesForHighlightedRow,
      sortQuery: sortColumn,
      skipParam: skip,
      pageSizeParam: paginationModel.pageSize,
      filterQuery: customFilterJsonValue,
      sqlQuery: customFilterSqlValue,
    });
  };

  /**
   * onEditCommunication method will show error modal if no column is selected and user tries to bulk edit
   * else will show modal where user can update values
   */
  const onEditCommunication = () => {
    if (selectedActivities.length === 0) {
      setShowSelectRecordError(true);
    } else {
      setShowEditCommunication(true);
    }
  };

  /**
   * onFilterChange is prop set for communication grid to filter
   * @param args GridFilterModel array of object which field, value and operator properties
   */
  const onFilterChange = (args: GridFilterModel) => {
    const queryBuilderQuery = convertMUIToQueryBuilder(args);
    const valueToConvert = convertAndAppendExistingFilters(
      {...customFilterJsonValue},
      queryBuilderQuery,
      filterModel,
    );
    const {json, sql} = generateJsonAndSql(valueToConvert);
    setFilterModel(args);
    setCustomFilterSqlValue(json.rules.length > 0 ? sql : null);
    setCustomFilterJsonValue(json);
    if (hasFilterValue(args)) {
      handleGetAllActivities(json, json.rules.length > 0 ? sql : null);
    }
  };

  const onQueryFilterApply = (data: QueryReturnValue) => {
    // if filter is empty then reset filter model
    if (shouldResetFilterModel(data)) {
      resetFilterModel();
    }

    setCustomFilterSqlValue(data.sql);
    setCustomFilterJsonValue(data.json);
    handleGetAllActivities(
      data.json,
      data.json.rules.length > 0 ? data.sql : null,
    );
  };

  /**
   * Download excel report
   */
  const exportToExcel = () => {
    if (Number(communicationsCount) > MAX_EXCEL_DOWNLOAD) {
      toast?.current.show({
        severity: 'warn',
        summary: '',
        detail: `Maximum of ${MAX_EXCEL_DOWNLOAD} records will be downloaded`,
      });
    }
    dispatch(
      // @ts-ignore
      communicationExportToExcel({
        userKey,
        groupKey,
        isShowingArchivedActivities: isShowArchived,
        modeType: mode,
        activityType,
        ruleKey,
        taskKey:
          selectedActivities.length > 0 && isShowActivitiesForHighlightedRow
            ? selectedActivities.map(item => item.taskKey)[0]
            : null,
        isHighlighted: isShowActivitiesForHighlightedRow,
        SettingKey: settingId,
        isRestricted: isMailNotRead,
        sortQuery: sortColumn,
        pageSize: paginationModel.pageSize,
        filterQuery: customFilterJsonValue,
        sqlQuery: customFilterSqlValue,
        assignedUserKey,
        skip,
      }),
    );
  };

  const applyBestFit = () => {
    const bestFitColumnsTemp = columns.map(column => {
      const minWidth = calculateColumnWidth(column.field);
      return {
        ...column,
        minWidth,
      };
    });
    setBestFit(!bestFit);
    setBestFitColumns(bestFitColumnsTemp);
  };

  const onReportClicked = () => {
    if (selectedActivities.length === 0) {
      setShowSelectRecordError(true);
    } else {
      setShowReportsPopup(true);
    }
  };

  const onViewSettings = () => {
    setShowViewSettingsPopup(true);
  };
  const handleCloseViewSettingsPopup = () => {
    setShowViewSettingsPopup(false);
  };

  const handleOpenAlertsPopup = () => {
    setShowAlertsPopup(true);
  };
  const handleCloseAlertsPopup = () => {
    setShowAlertsPopup(false);
  };

  const updateSnackbarObj = ({type, title, open, message}: ISnackbarProps) => {
    setSnackbarObj({type, title, open, message, onClose: () => {}});
  };

  const handleOnSnackbarClose = () => {
    setSnackbarObj(prev => ({
      ...prev,
      open: false,
    }));
  };

  const {columnSettingJson, sortSettingJson} = getGridColumnsSettings(
    viewSettings?.columnSetting,
    columnVisibilityModel,
    sortColumn,
  );

  return (
    <>
      <RenderDialogAndSnackbarForReports
        handleOnSnackbarClose={handleOnSnackbarClose}
        selectedRows={selectedActivities}
        setShowReportsPopup={setShowReportsPopup}
        setShowSelectRecordError={setShowSelectRecordError}
        showReportsPopup={showReportsPopup}
        showSelectRecordError={showSelectRecordError}
        snackbarObj={snackbarObj}
        updateSnackbarObj={updateSnackbarObj}
        reportType={REPORT_TYPE.COMMUNICATION}
        keyName="taskActKey"
      />
      <Outlet context={{onReload: onRefresh}} />
      <ViewSettingsModal
        viewName={ViewNames.COMMUNICATION}
        mode={activityType}
        updateTableOnColumnSettingsChange={updateTableOnColumnSettingsChange}
        handleCloseViewSettingsPopup={handleCloseViewSettingsPopup}
        customFilterSqlValue={customFilterSqlValue || ''}
        sortSettingJson={JSON.stringify(sortSettingJson) || ''}
        columnSettingJson={JSON.stringify(columnSettingJson) || ''}
        loadViewSetting={(row: IViewSettings) => {
          dispatch(
            updateViewSettingObject({
              settingId: row.settingsKey,
              viewSettings: row,
            }),
          );
        }}
        showViewSettingsPopup={showViewSettingsPopup}
      />
      <AlertsModal
        viewName={ViewNames.COMMUNICATION}
        updateTableOnColumnSettingsChange={updateTableOnColumnSettingsChange}
        handleCloseAlertsPopup={handleCloseAlertsPopup}
        customFilterSqlValue={customFilterSqlValue || ''}
        sortSettingJson={JSON.stringify(sortSettingJson) || ''}
        columnSettingJson={JSON.stringify(columnSettingJson) || ''}
        loadViewSetting={(row: any) => {
          dispatch(
            updateViewSettingObject({
              settingId: row.settingsKey,
              viewSettings: row,
            }),
          );
        }}
        showAlertsPopup={showAlertsPopup}
      />
      <ViewSettings
        isRowSelected={selectedActivities.length > 0}
        isSingleRowSelected={selectedActivities.length === 1}
        activityType={activityType}
      />
      <FilterPopoverProvider>
        <DataGrid
          data-testid="communication-grid"
          disableVirtualization
          columns={bestFit ? bestFitColumns : columns}
          rows={communications}
          loading={communicationsLoading}
          checkboxSelection
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityChange={data => setColumnVisibilityModel(data)}
          rowCount={communicationsCount}
          onPageChange={onPageChange}
          onSortChange={onSortChange}
          onFilterChange={onFilterChange}
          sortModel={sortColumn}
          rowsSelected={selectedActivities.map(item => item.id)}
          onColumnRowSelect={onColumnRowSelect}
          CustomToolbar={CommunicationToolbar}
          customToolbarMethods={{
            onRefresh,
            onEditCommunication,
            exportToExcel,
            applyBestFit,
            onReportClicked,
            onViewSettings,
            handleOpenAlertsPopup,
          }}
          headerFilters
          filterModel={filterModel}
          paginationModel={paginationModel}
          showCustomFilters
          customFilterSqlValue={customFilterSqlValue}
          onQueryFilterApply={data => onQueryFilterApply(data)}
        />
      </FilterPopoverProvider>
      {showEditCommunication && (
        <EditCommunication
          open={showEditCommunication}
          onClose={() => setShowEditCommunication(false)}
          custActivityUpdateKeys={selectedActivities.map(({taskActKey}) => {
            return {tblKey: taskActKey};
          })}
          onCommunicationUpdate={() => setSelectedActivities([])}
          onRefresh={onRefresh}
        />
      )}
    </>
  );
};
export default Communication;
