import {createTheme} from '@mui/material';

export const theme = createTheme({
  typography: {
    fontFamily: "'Work Sans', sans-serif, 'Segoe UI', 'Roboto', 'Oxygen'",
  },
  components: {
    MuiInputLabel: {
      defaultProps: {
        sx: {
          fontSize: '14px',
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        sx: {
          fontSize: '13px',
          '& .MuiOutlinedInput-input': {height: 'auto'},
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#7D4ED8',
    },
    secondary: {
      main: '#BBE9EC',
    },
  },
});
