import {constants} from '../constants/common';
import {isEmpty} from '../lib/utils';
import {downloadInvoiceFile} from '../services/activities';

export const downloadExcel = (tableData, fileName, toast, toastInfo = '') => {
  const url = URL.createObjectURL(new Blob([tableData]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName}.xlsx`);
  document.body.appendChild(link);
  link.click();
  link.remove();
  toast?.current?.show({
    severity: 'success',
    summary: constants.EXPORTED_SUCCESSFULLY,
    detail: toastInfo,
  });
};

export const downloadFile = (tableData, fileName, toast, toastInfo = '') => {
  const url = URL.createObjectURL(
    new Blob([tableData], {type: 'application/octet-stream'}),
  );
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
  toast?.current?.show({
    severity: 'success',
    summary: constants.EXPORTED_SUCCESSFULLY,
    detail: toastInfo,
  });
};

export const downloadFileFromUrl = (url, fileName) => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const downloadInvoice = async (
  groupKey,
  invoiceKey,
  toast,
  updateSnackbarObj,
  message,
) => {
  const handleError = (summary, detail) => {
    toast?.current.show({
      severity: 'error',
      summary,
      detail,
    });
    if (updateSnackbarObj) {
      updateSnackbarObj({
        type: 'error',
        title: summary,
        open: true,
        message: detail,
      });
    }
  };

  downloadInvoiceFile(groupKey, invoiceKey)
    .then(response => {
      const contentDisposition = response.headers['content-disposition'];
      const filename = contentDisposition
        ? contentDisposition.split("''")[1]
        : constants.INVOICE_FILE;
      downloadFile(response.data, filename);
    })
    .catch(error => {
      if (!isEmpty(message)) {
        handleError(constants.FILE_NOT_FOUND, message);
      } else if (error?.response?.status === 404) {
        handleError(constants.FILE_NOT_FOUND, '');
      } else {
        handleError(constants.FAILED_TO_DOWNLOAD_INVOICE, '');
      }
    });
};

export const validateDirForDelete = (selectedDirectory, fileSelected) => {
  return (
    (selectedDirectory !== 'External' &&
      selectedDirectory !== 'Upload' &&
      selectedDirectory !== 'Internal') ||
    !isEmpty(fileSelected)
  );
};

export const getFileExtensionByFileName = fileName => {
  return fileName.split('.').pop();
};
