import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import {ViewSettingsDropDown} from '../../common/view-settings/view-settings-dropdown/ViewSettingsDropDown';
import {updateAccountViewSettingObject} from '../../../store/actions/statementOfAccountActions';
import {CheckboxLabel} from '../../../ui/inputs';
import {gridModeTypes, constants} from '../../../constants/common';
import {showDialog} from '../../../store/actions/generalActions';
import {checkSiteOptionValueForShowAllAccount} from '../../../services/communications';
import {Box} from '../../../ui/layouts';

export const ViewSettings = ({
  isShowInvoiceLinesChecked,
  showInvoiceLines,
  isInvoicesSelected,
  groupKey,
  custKey,
  onAllAccountsChange,
  showAllAccounts,
}: {
  isShowInvoiceLinesChecked: boolean;
  showInvoiceLines: (val: boolean) => void;
  isInvoicesSelected: boolean;
  groupKey: string;
  custKey: number;
  onAllAccountsChange: (val: boolean) => void;
  showAllAccounts: boolean;
}) => {
  const dispatch = useDispatch();
  const [showAllAccountCheckboxVisible, setShowAllAccountCheckboxVisible] =
    useState(false);
  const {settingId, viewSettings} = useSelector(
    (store: any) => store.statementOfAccountReducer,
  );

  const showAllAccountCheckboxShow = async () => {
    const res = await checkSiteOptionValueForShowAllAccount({
      groupKey,
      custKey,
    });
    if (res.data) {
      setShowAllAccountCheckboxVisible(true);
    }
  };

  useEffect(() => {
    showAllAccountCheckboxShow();
  }, []);

  const onShowInvoiceChanged = (value: boolean) => {
    if (isInvoicesSelected) {
      showInvoiceLines(value);
    } else {
      dispatch(
        // @ts-ignore
        showDialog({
          width: '50vw',
          title: constants.SELECT_INVOICE,
          headerIcon: 'pi pi-info',
          dialogContent: (
            <span>
              {constants.PLEASE_SELECT_AN_INVOICE_TO_SHOW_THE_LINES_FOR}
            </span>
          ),
        }),
      );
    }
  };
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      marginY="12px"
      gap={2}
    >
      <Box width="250px">
        <ViewSettingsDropDown
          settingId={settingId}
          viewName="AccountOverview"
          isQueryParamDefault
          onViewSettingSelected={(selectedSettingId, selectedViewSettings) =>
            dispatch(
              updateAccountViewSettingObject({
                settingId: selectedSettingId,
                viewSettings: selectedViewSettings,
              }),
            )
          }
          currentViewSettings={viewSettings}
          activityType={gridModeTypes.StatementOfAccount}
        />
      </Box>
      <div>
        <CheckboxLabel
          checked={isShowInvoiceLinesChecked}
          label="Show Invoice Lines"
          onChange={e => onShowInvoiceChanged(e.target.checked)}
        />
        {showAllAccountCheckboxVisible && (
          <CheckboxLabel
            checked={showAllAccounts}
            label={constants.SHOW_ALL_ACCOUNTS}
            onChange={e => onAllAccountsChange(e.target.checked)}
          />
        )}
      </div>
    </Box>
  );
};
