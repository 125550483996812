import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {TabView, TabPanel} from 'primereact/tabview';
import {setCurrentRoute} from '../../store/actions/generalActions';
import {constants} from '../../constants/common';
import {usePrevious} from '../../hooks/usePrevious';
import CustomDataTable from '../common/table/CustomDataTable';
import {activitiesColumns} from '../common/table/columns/activitiesColumns';
import {
  getAllActivities,
  setActivitiesSkip,
} from '../../store/actions/activitiesActions';
import CustomCalendar from '../common/calendar/CustomCalendar';
import {getUserData} from '../../store/actions/userActions';
import {useQueryKeys} from '../../hooks/useQueryKeys';

function Activities() {
  const [activitiesData, setActivitiesData] = useState([]);
  const dispatch = useDispatch();
  const {
    activities,
    count: totalRecords,
    activitiesSkip,
    loading,
  } = useSelector(store => store.activitiesReducer);
  const pageRowCount = useSelector(store => store.generalReducer?.pageRowCount);
  const bestFit = useSelector(store => store.generalReducer?.bestFit);
  const {userKey} = useQueryKeys();
  const [groupKey] = useState(getUserData()?.groupKey);
  const [refresh, setRefresh] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const prevUserKey = usePrevious(userKey);
  const options = {userKey, groupKey};

  useEffect(() => {
    dispatch(setActivitiesSkip(0));
  }, [prevUserKey, userKey]);

  const onLoadMore = pageToLoad => {
    const skip = pageToLoad * pageRowCount - pageRowCount;
    dispatch(setActivitiesSkip(skip));
  };

  useEffect(() => {
    dispatch(getAllActivities(options));
    dispatch(setCurrentRoute(constants.ACTIVITIES.toLocaleLowerCase()));
  }, []);

  useEffect(() => {
    setActivitiesData(activities);
  }, [activities]);

  useEffect(() => {
    dispatch(getAllActivities(options));
  }, [refresh, activitiesSkip, pageRowCount, userKey]);

  return (
    <div className="p-2">
      <TabView
        activeIndex={activeIndex}
        onTabChange={e => {
          setActiveIndex(e.index);
          setRefresh(true);
        }}
      >
        <TabPanel header="List">
          <CustomDataTable
            loading={loading}
            columns={activitiesColumns(bestFit, dispatch)}
            totalRecords={totalRecords}
            onLoadMore={onLoadMore}
            skip={activitiesSkip}
            tableData={activitiesData}
          />
        </TabPanel>
        <TabPanel header="Calendar">
          <CustomCalendar />
        </TabPanel>
      </TabView>
    </div>
  );
}
export default Activities;
