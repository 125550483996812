import dayjs from 'dayjs';
import {Button} from '@mui/material';
import {constants} from '../../../../constants/common';

export const messageActivitiesOverviewColumns = (
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  onAssignAll = rowData => {},
) => {
  const sortable = true;

  return [
    {
      field: 'Action',
      headerName: '',
      sortable: false,
      renderCell: ({row}) => (
        <Button variant="contained" onClick={() => onAssignAll(row)}>
          {constants.ASSOCIATE}
        </Button>
      ),
      minWidth: 120,
      align: 'center',
      type: 'actions',
    },
    {
      field: 'dateEntered',
      headerName: 'ACTIVITY DATE',
      sortable,
      valueFormatter: params =>
        params?.value
          ? dayjs(params?.value).utc().format(constants.DATE_TIME_FORMAT)
          : '',
      type: 'date',
      minWidth: 180,
    },
    {
      field: 'description',
      headerName: 'ISSUE',
      sortable,
      renderCell: ({row}) => (
        <span title={row.description}>{row.description}</span>
      ),
      minWidth: 180,
    },
    {
      sortable,
      field: 'activityId',
      headerName: 'ACTIVITY TYPE',
      renderCell: ({row}) => (
        <span title={row.activityId}>{row.activityId}</span>
      ),
      align: 'center',
      minWidth: 120,
    },
    {
      sortable,
      field: 'notes',
      headerName: 'NOTES',
      renderCell: ({row}) => (
        <span
          title={row.notes}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{__html: row?.notes}}
        />
      ),
      flex: 1,
      minWidth: 300,
    },
  ];
};
