import {ChangeEvent, FocusEvent, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import {useLocation, useNavigate, useOutletContext} from 'react-router-dom';
import dayjs, {Dayjs} from 'dayjs';
import {TextField as MuiTextField} from '@mui/material';
import {constants} from '../../constants/common';
import ActivityViewContactsTemplate from '../communicate/view-contacts/ActivityViewContactsTemplate';
import {
  getActivityInfo,
  getActivityTypeList,
  getAllActivityContactsList,
} from '../../store/actions/activitiesActions';
import {getUserData, getUsersByKey} from '../../store/actions/userActions';
import FeatureNotAvailable from './FeatureNotAvailable';
import {
  cleanUpWorkTables,
  getprocedureNote,
  saveActivity,
} from '../../services/activities';
import {
  updateSideNavRefresh,
  updateTableFilterDisplayType,
} from '../../store/actions/generalActions';
import CommunicateTransaction from '../communicate/tabs/communicate-transaction/CommunicateTransaction';
import CommunicateEmail from '../communicate/tabs/CommunicateEmail';
import CommunicateVoiceMessage from '../communicate/tabs/CommunicateVoiceMessage';
import AddContactTemplate from '../messages/AddContactTemplate';
import AddPersonalContactTemplate from '../messages/AddPersonalContactTemplate';
import CommunicateText from '../communicate/tabs/CommunicateText';
import CommunicateAttachments from '../communicate/tabs/CommunicateAttachments';
import CommunicatePayment from '../communicate/tabs/CommunicatePayment';
import CommunicateNotes from '../communicate/tabs/CommunicateNotes';
import {OpenDocs} from '../communicate/open-docs/OpenDocs';
import {SelectWithHeader} from './select/select-with-header/SelectWithHeader';
import {ActivityTypeHeader} from '../communicate/activity-type/ActivityTypeHeader';
import {ActivityTypeOptions} from '../communicate/activity-type/ActivityTypeOptions';
import {formatSaveActivity} from '../../utils/formatJson';
import EmailTemplate from '../messages/EmailTemplate';
import IssueCompleteTemplate from '../communicate/IssueCompleteTemplate';
import {onlyNumbers} from '../../constants/regex';
import {addDaysToDate} from '../../utils/dataTimeUtils';
import MarkedIssueAsCompleteTemplate from '../communicate/MarkedIssueAsCompleteTemplate';
import {isEmpty} from '../../lib/utils';
import {MODE_TYPES, REPLY_TYPE} from '../../utils/constants';
import CopyAttachmentsToCustDocsTemplate from '../messages/CopyAttachmentsToCustDocsTemplate';
import {DEFAULT_ACTIVITY_INFO} from '../../lib/defaults';
import {useUserSettings} from '../../hooks/useUserSettings';
import {updateIsEmailTemplateActivities} from '../../store/actions/activitiesOverviewActions';
import {resetActivitiesOverviewPaginationAndSort} from '../../utils/mailUtils';
import {checkSiteOptionValueForShowAllAccount} from '../../services/communications';
import {useSessionStorage} from '../../hooks/useSessionStorage';
import {Dialog} from '../../ui/feedback';
import {Button, Checkbox, Select} from '../../ui/inputs';
import TextField from '../../ui/inputs/textfield/TextField';
import DateTimePicker from '../../ui/inputs/date-time-picker/DateTimePicker';
import Backdrop from '../../ui/utils/backdrop/Backdrop';
import Tabs from '../../ui/utils/tabs/Tabs';

const mapTabIndex: {[key: string]: number} = {
  notes: 0,
  transactions: 1,
  email: 2,
  voiceMessage: 3,
  text: 4,
  attachments: 5,
  payment: 6,
};

interface IDataProps {
  from: any;
  tabName: string;
  taskKey: number;
  taskActKey: number;
  custKey: number;
  groupKey: string;
  newActivity: boolean;
  custName: string;
  replyType: number;
  custId: string;
  selectedInvoice: any; // Todo: define type for selected Invoices
  customerKeys: number[];
  selectedAccountsCount: number;
  source: string;
}

interface IUserSettings {
  userSettings: {
    user: any;
    options: any;
    features: any[];
    userId: string;
    timeZoneID: any | null;
  };
}

interface IActivityType {
  activityID: string;
  activityKey: number;
  activityType: number;
  buttonText: string;
  description: string;
  excludeFromDashboard: boolean;
  groupKey: string;
  hasProcedureNotes: boolean;
  systemActivity: number;
}

interface IContactListOptions {
  bulkActivity: boolean;
  custKey: number;
  groupKey: string;
  personal: boolean;
  totalsOnly: boolean;
  userKey: number;
  userId: string;
  isVoiceTab: boolean;
  isTextTab: boolean;
  filterQuery?: string;
}

interface IInfoForListByGroupKey {
  timeZoneID: any | null;
  timezoneOffset: number;
  userID: string;
  userKey: number;
  userName: string;
  userType: number;
}

interface IUseOutletContext {
  onReload: () => void;
}

function Communicate({...props}) {
  const {
    tabName,
    from,
    taskKey,
    taskActKey,
    custKey,
    groupKey,
    newActivity,
    custName,
    custId,
    selectedInvoice,
    customerKeys,
    selectedAccountsCount,
    source,
  }: IDataProps = props.data;
  const {userSettings}: IUserSettings = useUserSettings();
  const [activeIndex, setActiveIndex] = useState<number>(
    mapTabIndex[tabName] || 0,
  );
  const [showError, setShowError] = useState<boolean>(false);
  const [activityType, setActivityType] = useState<IActivityType | null>();
  const [showActivityViewContactsModal, setShowActivityViewContactsModal] =
    useState<boolean>(false);
  const [showFeatureNotAvailableDialog, setShowFeatureNotAvailableDialog] =
    useState<boolean>(false);
  const [showAddContactDialog, setShowAddContactDialog] =
    useState<boolean>(false);
  const [showAddPersonalContactsDialog, setShowAddPersonalContactsDialog] =
    useState<boolean>(false);
  const [isAmountEdited, setIsAmountEdited] = useState(false);
  const {activityInfo, activityLoading} = useSelector(
    (store: any) => store.activitiesReducer,
  );
  const [activityInfoObj, setActivityInfoObj] = useState<any>(
    DEFAULT_ACTIVITY_INFO,
  );
  const [showCustomerDocs, setShowCustomerDocs] = useState<boolean>(false);
  const {activityId} = props;
  const customerInfo =
    selectedAccountsCount && selectedAccountsCount > 0
      ? ` ${selectedAccountsCount} Accounts`
      : custName;
  const [showBulkMailConfirmation, setShowBulkMailConfirmation] =
    useState<boolean>(false);
  const {isAdmin, userType} = getUserData();
  const [{sessionUserKey, sessionUserId}] = useSessionStorage('profile');
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const toast = useSelector((store: any) => store.generalReducer?.toastRef);
  const [showReassignModal, setShowReassignModal] = useState<boolean>(false);
  const [showIssueReopenDialog, setShowIssueReopenDialog] =
    useState<boolean>(false);
  const [saveAndSendButtonText, setSaveAndSendButtonText] = useState<string>(
    constants.SAVE,
  );
  const unChangedMailProp = activityInfo?.mail;
  const [isReplyBtnClicked, setIsReplyBtnClicked] = useState<boolean>(false);
  const [isReplyAllBtnClicked, setIsReplyAllBtnClicked] =
    useState<boolean>(false);
  const [isForwardBtnClicked, setIsForwardBtnClicked] =
    useState<boolean>(false);
  const [showMarkedIssueAsCompleteDialog, setShowMarkedIssueAsCompleteDialog] =
    useState<boolean>(false);
  const [issueCompleted, setIssueCompleted] = useState<boolean>(false);
  const [closePriorActivity, setClosePriorActivity] = useState<boolean>(false);
  const [activityEditTransactionsObj, setActivityEditTransactionsObj] =
    useState([]);
  const [loadingCommunicate, setLoadingCommunicate] = useState(false);
  const [copyNotesEmailBtnClicked, setCopyNotesEmailBtnClicked] =
    useState<boolean>(false);
  const communicationsActivityList =
    useSelector((store: any) => store.communicationsReducer.communications) ||
    [];
  const homepageActivityList =
    useSelector((store: any) => store.homeReducer.bestActivityData) || [];
  const activityList =
    source === constants.HOMEPAGE
      ? homepageActivityList
      : communicationsActivityList;
  const addedContact = useSelector(
    (store: any) => store.activitiesReducer.addedContact,
  );
  const custKeyToLoadContactsList = useSelector(
    (store: any) => store.activitiesReducer.activityInfo?.customerTask?.custKey,
  );
  const userKeyToLoadContactsList = useSelector(
    (store: any) =>
      store.activitiesReducer.activityInfo?.customerTaskActivity?.userKey,
  );
  const hideSaveAndNext = props.data.hideSaveAndNext || false;
  const [
    showCopyAttachmentsToCustDocsDialog,
    setShowCopyAttachmentsToCustDocsDialog,
  ] = useState(false);
  const [attachments, setAttachments] = useState<any[]>([]);
  const [showProcedureDialog, setShowProcedureDialog] = useState(false);
  const [precedureDialogText, setProcedureDialogText] = useState('');
  const [showAllAccountsCheckbox, setShowAllAccountsCheckbox] = useState(false);
  const [isDraftView, setIsDraftView] = useState(false);
  const [activeIndexToLoadContactsList, setActiveIndexToLoadContactsList] =
    useState<number>(0);

  const activityTypeListDropDown = useSelector((store: any) => {
    return store.activitiesReducer.activityTypeList;
  });
  const activityRef = useRef(activityInfo);
  const userListDropDown = useSelector((store: any) => {
    const {listByGroupKey} = store.usersReducer;
    return listByGroupKey.map((info: IInfoForListByGroupKey) => {
      return {label: info.userID, value: info.userKey};
    });
  });

  const handleTableFilterDisplayType = (type: string) => {
    dispatch(updateTableFilterDisplayType(type?.toLocaleLowerCase()));
  };

  const {search, pathname, state} = useLocation();
  const {onReload}: IUseOutletContext = useOutletContext();
  const handleGetInfo = (
    ModeType: number,
    reply_type: number,
    copyNotesEmailBtnClickedParam: boolean,
    isSaveNext: boolean = false,
    nextProp: any = {},
  ) => {
    if (copyNotesEmailBtnClickedParam) {
      setCopyNotesEmailBtnClicked(true);
    }
    let request = {
      WorkSessionID: copyNotesEmailBtnClickedParam
        ? activityInfoObj?.customerTaskActivity?.workSessionID
        : null,
      GroupKey: groupKey,
      UserKey: sessionUserKey,
      CustKey: custKey,
      InvcKeys: null,
      PmtKeys: null,
      BulkWorkSessionID: null,
      IsBulk: customerKeys && customerKeys.length > 0,
      TaskKey: taskKey || -1,
      TaskActKey: taskActKey || -1,
      ModeType,
      IsReply: reply_type === REPLY_TYPE.reply,
      IsReplyAll: reply_type === REPLY_TYPE.replyAll,
      IsForward: reply_type === REPLY_TYPE.forward,
      UserType: userType || '2',
      MailKey: props.data.mailKey || 0,
    };

    if (isSaveNext) {
      request = {
        ...request,
        TaskKey: nextProp?.taskKey ? nextProp?.taskKey : -1,
        TaskActKey: nextProp?.taskActKey ? nextProp?.taskActKey : -1,
      };
    }
    dispatch(getActivityInfo(request, copyNotesEmailBtnClickedParam));
  };

  const showAllAccountCheckboxShow = async () => {
    const res = await checkSiteOptionValueForShowAllAccount({
      groupKey,
      custKey,
    });
    if (res.data) {
      setShowAllAccountsCheckbox(true);
    }
  };

  const handleTabRedirection = ({
    isPaymentEnabled,
    isEmailEnabled,
    isAttachmentsEnabled,
    isTextMessageEnabled,
  }: {
    isPaymentEnabled: boolean;
    isEmailEnabled: boolean;
    isAttachmentsEnabled: boolean;
    isTextMessageEnabled: boolean;
  }) => {
    if (isPaymentEnabled && tabName === constants.PAYMENTS_TAB) {
      setActiveIndex(mapTabIndex[tabName]);
    } else if (isEmailEnabled && tabName === constants.EMAIL_TAB) {
      setActiveIndex(mapTabIndex[tabName]);
    } else if (isAttachmentsEnabled && tabName === constants.ATTACHMENTS_TAB) {
      setActiveIndex(mapTabIndex[tabName]);
    } else if (
      isTextMessageEnabled &&
      (tabName === constants.VOICE_MESSAGE_TAB ||
        tabName === constants.TEXT_TAB)
    ) {
      setActiveIndex(mapTabIndex[tabName]);
    } else {
      setActiveIndex(0);
    }
  };

  useEffect(() => {
    showAllAccountCheckboxShow();
  }, []);

  interface IRequest {
    GroupKey: string;
    UserKey: string;
    CustKey: number;
    InvcKeys: number[] | null;
    PmtKeys: number[] | null;
    WorkSessionID?: string | null;
    BulkWorkSessionID: string | null;
    IsBulk: boolean;
    TaskKey?: number;
    TaskActKey?: number;
    ModeType?: number;
    TaggedPmts?: any[];
    TaggedInvoices?: any[];
    IsDraftActivity?: boolean | false;
    DraftMode?: boolean | false;
  }

  useEffect(() => {
    handleTableFilterDisplayType('row');
    // Api call to get activity info
    let request: IRequest = {
      GroupKey: groupKey,
      UserKey: sessionUserKey,
      CustKey: custKey,
      InvcKeys: null,
      PmtKeys: null,
      BulkWorkSessionID: null,
      IsBulk: customerKeys && customerKeys.length > 0,
    };
    if (newActivity) {
      request = {
        ...request,
        WorkSessionID: null,
        TaskKey: taskKey || -1,
        TaskActKey: taskActKey || -1,
        ModeType: props.data.ModeType,
      };
    } else {
      request = {
        ...request,
        WorkSessionID: null,
        TaskKey: taskKey,
        TaskActKey: taskActKey,
        ModeType: props.data.ModeType,
      };
    }
    // Add invoices selected if new activity is created from account overview
    if (selectedInvoice) {
      if (selectedInvoice.taggedPmts && selectedInvoice.taggedPmts.length > 0) {
        request = {
          ...request,
          TaggedPmts: selectedInvoice.taggedPmts,
        };
      }
      if (
        selectedInvoice.taggedInvoices &&
        selectedInvoice.taggedInvoices.length > 0
      ) {
        request = {
          ...request,
          TaggedInvoices: selectedInvoice.taggedInvoices,
        };
      }
    }
    if (from?.pathname?.includes('draft-activity')) {
      setIsDraftView(true);
      request = {
        ...request,
        IsDraftActivity: true,
        DraftMode: false,
        ModeType: 0,
      };
    }
    dispatch(getActivityTypeList(groupKey));
    dispatch(getActivityInfo(request));
    dispatch(getUsersByKey(groupKey));
    return () => {
      sessionStorage.removeItem('customFilterQuery');
      handleTableFilterDisplayType('row');
    };
  }, [activityId]);

  const getFollowupDaysAddedDate = (days: string, date: Dayjs) => {
    if (days) {
      const addedDaysoDate = addDaysToDate(days, dayjs(date));
      if (!isEmpty(days) && date) {
        return addedDaysoDate;
      }
      return null;
    }
    return null;
  };

  const updateActivityInfoState = (activityInfoObjParam: any) => {
    setActivityInfoObj(activityInfoObjParam);
    activityRef.current = activityInfoObjParam;
  };

  useEffect(() => {
    let activityInfoTemp = {...activityInfo};
    activityInfoTemp = {
      ...activityInfoTemp,
      customerTaskActivity: {
        ...activityInfoTemp.customerTaskActivity,
        daysToRespond: activityInfo?.customerTaskActivity?.daysToRespond,
        followUpRespondBy: getFollowupDaysAddedDate(
          activityInfo?.customerTaskActivity?.daysToRespond,
          activityInfo?.customerTaskActivity?.toDoDate,
        ),
      },
    };
    updateActivityInfoState(activityInfoTemp);
    setClosePriorActivity(activityInfoTemp?.closePriorActivity);
    setActivityType(activityInfoTemp?.activity);
    if (
      props.data.ModeType === MODE_TYPES.activityOnEnquiry &&
      activityInfoTemp?.customerTask?.status === 1
    ) {
      setShowIssueReopenDialog(true);
    }
    if (activityInfoTemp) {
      // If new activity is clicked and if no to address reset button text to save
      let saveButtonText = constants.SAVE;
      if (
        (isReplyAllBtnClicked || isReplyBtnClicked) &&
        activityInfoTemp.mail?.toAddress.length > 0
      ) {
        saveButtonText = constants.SAVE_AND_SEND;
      } else {
        saveButtonText = saveAndSendButtonText;
      }
      setSaveAndSendButtonText(saveButtonText);
      handleTabRedirection(activityInfoTemp);
    }
  }, [activityInfo]);

  const handleShowActivityViewContactsModal = (val: boolean) => {
    setShowActivityViewContactsModal(val);
  };

  const onOpenDocs = () => {
    setShowCustomerDocs(true);
  };

  const loadContactsList = (options: IContactListOptions) => {
    if (!isEmpty(options)) {
      dispatch(getAllActivityContactsList(options));
    }
  };

  useEffect(() => {
    const groupKeyForContactsLoading = getUserData()?.groupKey;
    if (custKeyToLoadContactsList) {
      const options = {
        groupKey: groupKeyForContactsLoading,
        custKey: custKeyToLoadContactsList,
        userKey: userKeyToLoadContactsList,
        userId: sessionUserId,
        bulkActivity: false,
        totalsOnly: true,
        personal: false,
        isVoiceTab:
          activeIndexToLoadContactsList ===
          mapTabIndex[constants.VOICE_MESSAGE_TAB],
        isTextTab:
          activeIndexToLoadContactsList === mapTabIndex[constants.TEXT_TAB],
      };
      loadContactsList(options);
    }
  }, [activeIndexToLoadContactsList, addedContact, custKeyToLoadContactsList]);

  const onCustomerTaskActivityChange = (
    value: any,
    field: string,
    description?: string | null,
  ) => {
    let activityInfoTempObj = {...activityInfoObj};

    activityInfoTempObj = {
      ...activityInfoTempObj,
      customerTask: {
        ...activityInfoTempObj.customerTask,
        description: isEmpty(description)
          ? activityInfoTempObj?.customerTask?.description
          : description,
      },
      customerTaskActivity: {
        ...activityInfoTempObj.customerTaskActivity,
        [field]: value,
      },
    };
    updateActivityInfoState(activityInfoTempObj);
  };

  const onActivityTypeChange = (e: IActivityType) => {
    setActivityType(e);
    switch (e.activityType) {
      case 1:
      case 2:
        setActiveIndex(2);
        break;
      case 3:
      case 6:
      case 7:
        setActiveIndex(0);
        break;
      case 4:
        setActiveIndex(6);
        break;
      case 5:
        setActiveIndex(1);
        break;
      default:
        setActiveIndex(0);
        break;
    }
    let description = activityInfoObj?.customerTask?.description;
    if (
      props.data.ModeType === MODE_TYPES.new &&
      isEmpty(activityInfoObj?.customerTask?.description)
    ) {
      description = e.description;
    }
    onCustomerTaskActivityChange(e.activityKey, 'activityKey', description);
  };

  const onCustomerTaskChange = (value: any, field: string) => {
    if (field === 'description' && value.trim().length > 0) {
      setShowError(false);
    }
    let activityInfoTempObj = {...activityInfoObj};
    activityInfoTempObj = {
      ...activityInfoTempObj,
      customerTask: {...activityInfoTempObj.customerTask, [field]: value},
    };
    updateActivityInfoState(activityInfoTempObj);
  };

  const navigateBack = () => {
    if (from && typeof from === 'object' && !isEmpty(from)) {
      const params = new URLSearchParams(from.search);
      params.delete('activityId');
      const pathName = from.pathname.replace('/activity', '');
      const url =
        params.toString().length > 0
          ? `${pathName}?${params.toString()}`
          : pathName;
      navigate(url, {state: from.state});
      dispatch(updateSideNavRefresh());
      onReload();
      return;
    }
    if (pathname.includes('/activity')) {
      const searchParams = new URLSearchParams(search);
      searchParams.delete('activityId');
      const navigateUrl = pathname.replace('/activity', '');
      navigate(navigateUrl, {state});
      dispatch(updateSideNavRefresh());
      onReload();
    }
  };

  const showCommunicate = (communicateProps: any) => {
    const params = new URLSearchParams(search);
    params.set('activityId', communicateProps.id);
    navigate(`${pathname}?${params.toString()}`, {
      state: {...communicateProps, from},
    });
  };

  const onNewActivityButtonClicked = (
    replyTypeParam: number,
    isSaveNext: boolean = false,
    nextProp: any = {},
    hideSaveAndNextParam: boolean = false,
  ) => {
    if (isSaveNext) {
      showCommunicate({
        ...nextProp,
        newActivity: true,
        ModeType: MODE_TYPES.activityOnEnquiry,
        tabName:
          replyTypeParam !== 0 ? constants.EMAIL_TAB : constants.NOTES_TAB,
        replyType: replyTypeParam || 0,
        activityList,
        hideSaveAndNext: hideSaveAndNextParam,
        source,
      });
      handleGetInfo(MODE_TYPES.activityOnEnquiry, 0, false, true, nextProp);
    } else {
      showCommunicate({
        ...props.data,
        newActivity: true,
        ModeType: MODE_TYPES.activityOnEnquiry,
        tabName:
          replyTypeParam !== 0 ? constants.EMAIL_TAB : constants.NOTES_TAB,
        replyType: replyTypeParam || 0,
        activityList,
        hideSaveAndNext: hideSaveAndNextParam,
      });
      const saveButtonText =
        replyTypeParam === 1 || replyTypeParam === 2
          ? constants.SAVE_AND_SEND
          : saveAndSendButtonText;
      setSaveAndSendButtonText(saveButtonText);
      handleGetInfo(MODE_TYPES.activityOnEnquiry, replyTypeParam, false);
    }
    onCustomerTaskActivityChange(0, 'toDoComplete', null);
  };

  const onHandleSaveActivity = async (
    createNextActivity = false,
    IsDraftActivity = false,
    DraftMode = false,
  ) => {
    setLoadingCommunicate(true);
    const {mail, customerTaskActivity, expectedPayment, customerTask} =
      activityInfoObj;
    if (
      !customerTask.description ||
      !(customerTask.description.trim().length > 0)
    ) {
      setShowError(true);
      setLoadingCommunicate(false);
      return;
    }
    const disputes = activityEditTransactionsObj.map(
      ({
        invcKey,
        tranType,
        balanceDue,
        balanceDueHC,
        currExchRate,
        tagged,
        dispute,
        resolved,
        excludeFromAging,
        reasonCode,
      }) => {
        return {
          invcKey,
          tranType,
          balanceDue,
          balanceDueHC,
          currencyExchangeRate: currExchRate,
          tag: tagged,
          dispute,
          resolved,
          exclude: excludeFromAging,
          disputeCode: reasonCode || 0,
        };
      },
    );

    try {
      let request = formatSaveActivity({
        mail,
        customerTaskActivity,
        expectedPayment,
        groupKey,
        userKey: sessionUserKey,
        newActivity,
        customerTask,
        mailKey: props.data.mailKey || 0,
        taskKey,
        taskActKey,
        IsReply: isReplyBtnClicked,
        IsReplyAll: isReplyAllBtnClicked,
        IsForward: isForwardBtnClicked,
        custKey,
        ModeType: props.data.ModeType,
        closePriorActivity,
        disputes,
        activityKey: activityType?.activityKey,
        customerKeys,
        IsDraftActivity,
        DraftMode,
      });

      if (from?.pathname?.includes('draft-activity')) {
        request = {
          ...request,
          DraftMode: true,
          ModeType: 0,
        };
      }

      await saveActivity(request);
      setLoadingCommunicate(false);

      if (onReload) {
        onReload();
        setLoadingCommunicate(false);
      }
      if (!createNextActivity) {
        navigateBack();
        return;
      }
      if (createNextActivity) {
        setCopyNotesEmailBtnClicked(false);
        const index = activityList.findIndex(
          (x: any) => x.taskActKey === props.data?.taskActKey,
        );
        if (index + 1 < activityList.length) {
          onNewActivityButtonClicked(0, true, activityList[index + 1]);
        } else {
          navigateBack();
        }
      }
    } catch (error) {
      setLoadingCommunicate(false);
      toast?.current.show({
        severity: 'warn',
        summary: constants.SOMETHING_WENT_WRONG,
        detail: '',
      });
    }
  };

  const onHandleSave = (
    createNextActivity = false,
    IsDraftActivity = false,
  ) => {
    if (
      selectedAccountsCount &&
      selectedAccountsCount > 0 &&
      saveAndSendButtonText === constants.SAVE_AND_SEND
    ) {
      setShowBulkMailConfirmation(true);
    } else {
      onHandleSaveActivity(createNextActivity, IsDraftActivity);
    }
  };

  const onHandleCancelSave = () => {
    setShowBulkMailConfirmation(false);
  };

  const onHandleConfirmSave = () => {
    setShowBulkMailConfirmation(false);
    onHandleSaveActivity();
  };

  const onHandleSaveNext = () => {
    onHandleSave(true, false);
  };

  const onIssueResolved = () => {
    onCustomerTaskChange(0, 'status');
    setShowIssueReopenDialog(false);
  };

  const onCopyNotesAndEmailClicked = () => {
    handleGetInfo(MODE_TYPES.activityOnEnquiry, REPLY_TYPE.other, true);
  };

  const onProcedureBtnClicked = async (activityKey: number) => {
    const result = await getprocedureNote(activityKey);
    setProcedureDialogText(result.data);
    setShowProcedureDialog(true);
  };

  const onResponseDaysValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (onlyNumbers.test(e.target.value)) {
      onCustomerTaskActivityChange(e.target.value, 'daysToRespond', null);
    }
  };

  const onResponseDaysBlur = (e: FocusEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      // Todo: define type for activityInfo
      let activityInfoTempObj = {...activityInfoObj};
      activityInfoTempObj = {
        ...activityInfoTempObj,
        customerTaskActivity: {
          ...activityInfoTempObj.customerTaskActivity,
          daysToRespond: null,
          followUpRespondBy: '',
        },
      };
      updateActivityInfoState(activityInfoTempObj);
    } else if (
      activityInfoObj?.customerTaskActivity?.toDo === 0 ||
      !onlyNumbers.test(e.target.value) ||
      !activityInfoObj?.customerTaskActivity?.toDoDate
    ) {
      onCustomerTaskActivityChange('', 'daysToRespond', null);
    } else {
      // Todo: define type for activityInfo
      let activityInfoTempObj = {...activityInfoObj};
      activityInfoTempObj = {
        ...activityInfoTempObj,
        customerTaskActivity: {
          ...activityInfoTempObj.customerTaskActivity,
          daysToRespond: e.target.value,
          followUpRespondBy: getFollowupDaysAddedDate(
            e.target.value,
            activityInfoObj?.customerTaskActivity?.toDoDate,
          ),
        },
      };
      updateActivityInfoState(activityInfoTempObj);
    }
  };

  const onScheduleFollowupChkChange = (e: {target: {checked: boolean}}) => {
    if (e.target.checked) {
      // Todo: define type for activityInfo
      let activityInfoTempObj = {...activityInfoObj};
      activityInfoTempObj = {
        ...activityInfoTempObj,
        customerTaskActivity: {
          ...activityInfoTempObj.customerTaskActivity,
          toDoDate: activityInfoTempObj.customerTaskActivity.toDoDate
            ? activityInfoTempObj.customerTaskActivity.toDoDate
            : new Date(),
          toDo: 1,
        },
      };
      updateActivityInfoState(activityInfoTempObj);
    } else {
      // Todo: define type for activityInfo
      let activityInfoTempObj = {...activityInfoObj};
      activityInfoTempObj = {
        ...activityInfoTempObj,
        customerTaskActivity: {
          ...activityInfoTempObj.customerTaskActivity,
          daysToRespond: '',
          followUpRespondBy: '',
          toDoDate: null,
          toDo: 0,
        },
      };
      updateActivityInfoState(activityInfoTempObj);
    }
  };

  const onIssueCompleteChkChanged = (e: {target: {checked: boolean}}) => {
    if (!issueCompleted && activityInfoObj?.customerTask?.status === 0) {
      setShowMarkedIssueAsCompleteDialog(true);
      setIssueCompleted(true);
    }
    // Todo: define type for activityInfo
    let activityInfoTempObj = {...activityInfoObj};
    activityInfoTempObj = {
      ...activityInfoTempObj,
      customerTask: {
        ...activityInfoTempObj.customerTask,
        status: e.target.checked ? 1 : 0,
      },
      customerTaskActivity: {
        ...activityInfoTempObj.customerTaskActivity,
        toDoComplete: e.target.checked
          ? 1
          : activityInfoTempObj.customerTaskActivity.toDoComplete,
      },
    };
    updateActivityInfoState(activityInfoTempObj);
  };

  const onMarkAcivityAsCompleteChkChanged = (e: {
    target: {checked: boolean};
  }) => {
    if (!e.target.checked && activityInfoObj?.customerTask?.status === 0) {
      setIssueCompleted(false);
    }
    onCustomerTaskActivityChange(
      e.target.checked ? 1 : 0,
      'toDoComplete',
      null,
    );
  };

  const handleOnCancel = () => {
    if (!isDraftView) {
      cleanUpWorkTables(activityInfoObj?.customerTaskActivity?.workSessionID);
    }
    navigateBack();
  };

  const flexStartProperty = 'flex-start';

  const onFollowUpDateChange = (value: Dayjs | null) => {
    let activityInfoTempObj = {...activityInfoObj};
    activityInfoTempObj = {
      ...activityInfoTempObj,
      customerTaskActivity: {
        ...activityInfoTempObj.customerTaskActivity,
        toDoDate: dayjs(value),
        followUpRespondBy: getFollowupDaysAddedDate(
          activityInfoObj?.customerTaskActivity?.daysToRespond,
          dayjs(value),
        ),
      },
    };
    updateActivityInfoState(activityInfoTempObj);
  };

  return (
    <div className="card p-3 mb-3">
      <Backdrop
        isLoading={loadingCommunicate || activityLoading}
        open={loadingCommunicate || activityLoading}
      />
      <Dialog
        title={constants.WARNING}
        showConfirmButton
        showCancelButton
        confirmButtonText={constants.OK}
        open={showBulkMailConfirmation}
        onClose={onHandleCancelSave}
        onConfirm={onHandleConfirmSave}
      >
        <span>
          {' '}
          <p className="d-flex align-items-center">
            {constants.BULK_ACTIVITY_MAIL_CONFIRMATION_PART1 +
              selectedAccountsCount +
              constants.BULK_ACTIVITY_MAIL_CONFIRMATION_PART2}{' '}
          </p>
        </span>
      </Dialog>
      <Dialog
        title={constants.FEATURE_NOT_AVAILABLE_TITLE}
        open={showFeatureNotAvailableDialog}
        showCancelButton
        onClose={() => setShowFeatureNotAvailableDialog(false)}
      >
        {' '}
        <FeatureNotAvailable />{' '}
      </Dialog>
      <Dialog
        title={constants.ADD_CONTACT}
        open={showAddContactDialog}
        onClose={() => setShowAddContactDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <AddContactTemplate
          setShowOnContactDialog={setShowAddContactDialog}
          CustKey={custKey}
        />{' '}
      </Dialog>
      <Dialog
        title={
          <h2 className="d-flex align-items-center">
            {' '}
            <i className="pi pi-lockstep me-2" /> {constants.ISSUE_COMPLETE}
          </h2>
        }
        open={showIssueReopenDialog}
        onClose={() => {
          navigateBack();
          setShowIssueReopenDialog(false);
        }}
      >
        <IssueCompleteTemplate
          onIssueResolved={onIssueResolved}
          onCancel={() => {
            navigateBack();
            setShowIssueReopenDialog(false);
          }}
        />
      </Dialog>
      <Dialog
        title={
          <h2 className="d-flex align-items-center">
            {' '}
            <i className="pi pi-lockstep me-2" /> {constants.ISSUE_COMPLETE}
          </h2>
        }
        open={showMarkedIssueAsCompleteDialog}
        onClose={() => setShowMarkedIssueAsCompleteDialog(false)}
      >
        <MarkedIssueAsCompleteTemplate
          onHide={() => {
            setShowMarkedIssueAsCompleteDialog(false);
          }}
        />
      </Dialog>
      <Dialog
        title={constants.PERSONAL_CONTACTS}
        open={showAddPersonalContactsDialog}
        onClose={() => {
          loadContactsList({
            groupKey,
            custKey,
            userKey: sessionUserKey,
            userId: sessionUserId,
            bulkActivity: false,
            totalsOnly: true,
            personal: false,
            isTextTab: false,
            isVoiceTab: false,
          });
          setShowAddPersonalContactsDialog(false);
        }}
      >
        <AddPersonalContactTemplate loadContactsList={loadContactsList} />{' '}
      </Dialog>
      <Dialog
        title={constants.VIEW_CONTACTS}
        open={showActivityViewContactsModal}
        onClose={() => handleShowActivityViewContactsModal(false)}
        maxWidth="md"
        fullWidth
      >
        <ActivityViewContactsTemplate
          handleShowActivityViewContactsModal={
            handleShowActivityViewContactsModal
          }
          activityRef={activityRef}
          updateActivityInfoState={updateActivityInfoState}
        />
      </Dialog>
      <Dialog
        title=""
        open={showCopyAttachmentsToCustDocsDialog}
        onClose={() => setShowCopyAttachmentsToCustDocsDialog(false)}
      >
        <CopyAttachmentsToCustDocsTemplate
          attachments={attachments}
          setAttachments={setAttachments}
          groupKey={groupKey}
          custKey={custKey}
          mailKey={props?.data.mailKey || 0}
        />
      </Dialog>
      <Dialog
        title={constants.REASSIGN_CUSTOMER}
        open={showReassignModal}
        onClose={() => {
          resetActivitiesOverviewPaginationAndSort({
            dispatch,
          });
          dispatch(updateIsEmailTemplateActivities(false));
          setShowReassignModal(false);
        }}
        maxWidth="md"
      >
        <EmailTemplate
          isAssignMessagesTemplate
          {...props.data}
          fromAddress={activityInfo?.mail?.fromAddress}
          toAddress={activityInfo?.mail?.toAddress}
          ccAddress={activityInfo?.mail?.ccAddress}
          subject={activityInfo?.mail?.subject}
          CommunicateReassignClicked
          custKey={custKey}
          isAssignAllSelected={false}
          taskActKey={taskActKey}
          existingTaskKey={taskKey}
          setShowReassignModal={setShowReassignModal}
          navigateBack={navigateBack}
          onReload={onReload}
          status={activityInfo?.mail?.status}
        />
      </Dialog>

      <Dialog
        title={constants.PROCEDURE}
        open={showProcedureDialog}
        onClose={() => setShowProcedureDialog(false)}
      >
        <div className="p-2 border">{precedureDialogText}</div>
      </Dialog>
      <h1 className="subtitle2 w-100 overflow-hidden">
        {' '}
        {newActivity
          ? `${constants.NEW_ACTIVITY} ${constants.FOR.toLocaleLowerCase()}`
          : constants.ACTIVITY_FOR}{' '}
        {customerInfo}
      </h1>
      <div className="d-flex align-items-center">
        {/* TODO: SaveNext */}
        <Button
          sx={{alignSelf: flexStartProperty}}
          className="me-2 mt-2 mb-2"
          size="medium"
          variant="contained"
          onClick={() => onHandleSave(false, false)}
        >
          {saveAndSendButtonText}
        </Button>
        <Button
          sx={{alignSelf: flexStartProperty}}
          className="me-2 mt-2 mb-2"
          size="medium"
          variant="contained"
          onClick={() => onHandleSave(false, true)}
          disabled={!(newActivity || isDraftView)}
        >
          {constants.SAVE_AS_DRAFT}
        </Button>
        {props.data.ModeType === MODE_TYPES.activityOnEnquiry &&
          !hideSaveAndNext && (
            <Button
              sx={{alignSelf: flexStartProperty}}
              size="medium"
              variant="contained"
              className="me-2 mt-2 mb-2"
              onClick={onHandleSaveNext}
            >
              {constants.SAVE_AND_NEXT_ITEM}
            </Button>
          )}
        <Button
          sx={{alignSelf: flexStartProperty}}
          size="medium"
          variant="contained"
          className="me-2 mt-2 mb-2"
          onClick={handleOnCancel}
        >
          {constants.CANCEL}
        </Button>
        {props.data.ModeType === MODE_TYPES.enquiry && (
          <Button
            sx={{alignSelf: flexStartProperty}}
            size="medium"
            variant="contained"
            className="me-2 mt-2 mb-2"
            onClick={() => onNewActivityButtonClicked(0, false, {}, true)}
            disabled={isDraftView}
          >
            {constants.NEW_ACTIVITY}
          </Button>
        )}
        {props.data.ModeType === MODE_TYPES.activityOnEnquiry &&
          !copyNotesEmailBtnClicked && (
            <Button
              sx={{alignSelf: flexStartProperty}}
              size="medium"
              variant="contained"
              className="me-2 mt-2 mb-2"
              onClick={() => onCopyNotesAndEmailClicked()}
            >
              {constants.COPY_NOTES_EMAIL}
            </Button>
          )}
        {isAdmin &&
          activityInfoObj?.canReassign &&
          props.data.ModeType === MODE_TYPES.enquiry && (
            <Button
              sx={{alignSelf: flexStartProperty}}
              size="medium"
              variant="contained"
              className="me-2 mt-2 mb-2"
              onClick={() => setShowReassignModal(true)}
              disabled={isDraftView}
            >
              {constants.REASSIGN}
            </Button>
          )}
        {(props.data.ModeType === MODE_TYPES.activityOnEnquiry ||
          copyNotesEmailBtnClicked) &&
          activityInfo?.closePriorActivity && (
            <div className="py-1 px-2 me-2 mt-2 mb-2">
              <Checkbox
                checked={closePriorActivity}
                onChange={e => setClosePriorActivity(e.target.checked)}
              />{' '}
              <label>{constants.CLOSE_PRIOR_ACTIVITY}</label>
            </div>
          )}
      </div>
      <div className="d-flex align-items-center my-3">
        <div
          className="me-5"
          style={{flex: 1, width: '50%', maxWidth: '700px'}}
        >
          <TextField
            label={constants.ISSUE}
            multiline
            InputProps={{
              rows: 4,
              readOnly: !newActivity && !isDraftView,
              inputProps: {maxLength: 100},
            }}
            InputLabelProps={{
              ...(!newActivity && {shrink: true}),
            }}
            value={activityInfoObj?.customerTask?.description ?? ''}
            onChange={e => onCustomerTaskChange(e.target.value, 'description')}
            error={showError}
            helperText={showError && `* ${constants.ISSUE_DESC_IS_REQUIRED}`}
          />
        </div>
        <div
          className="d-flex align-items-center flex-wrap"
          style={{width: '45%'}}
        >
          <div className="d-flex justify-content-between align-items-center m-2">
            <label className="me-2">{constants.ISSUE_COMPLETE}</label>
            <Checkbox
              checked={activityInfoObj?.customerTask?.status === 1}
              onChange={onIssueCompleteChkChanged}
            />
          </div>
          {isAdmin && props.data.ModeType === MODE_TYPES.enquiry && (
            <div className="d-flex justify-content-start align-items-center m-2">
              <label className="me-2">{constants.ARCHIVE_ISSUE}</label>
              <Checkbox
                checked={activityInfoObj?.customerTask?.isArchived}
                onChange={e =>
                  onCustomerTaskChange(e.target.checked, 'isArchived')
                }
              />
            </div>
          )}
          <div className="m-2">
            <Button
              size="medium"
              variant="contained"
              onClick={() => onOpenDocs()}
            >
              {props.data.ModeType === MODE_TYPES.enquiry
                ? constants.OPEN_CUSTOMER_DOCS
                : constants.CUSTOMER_DOCS}
            </Button>
            {showCustomerDocs && (
              // Todo: define type of PRop for OpenDoc
              <OpenDocs
                groupKey={groupKey}
                setShowCustomerDocs={setShowCustomerDocs}
                showCustomerDocs={showCustomerDocs}
                custName={custName}
                custKey={custKey}
                workSessionID={
                  activityInfoObj?.customerTaskActivity?.workSessionID
                }
                custId={custId}
                selectedInvoice={selectedInvoice}
              />
            )}
          </div>
          <div className="m-2">
            <MuiTextField
              id="created"
              size="small"
              label={constants.CREATED}
              value={moment
                .utc(activityInfoObj?.customerTask?.entryDate)
                .format(constants.DATE_TIME_FORMAT)}
              onChange={e => onCustomerTaskChange(e.target.value, 'entryDate')}
              disabled
              InputLabelProps={{shrink: true}}
            />
          </div>
        </div>
      </div>
      <div className="d-flex mt-4">
        <div className="p-3 pb-0" style={{flex: '0', width: '40%'}}>
          <h1 className="subtitle2">{constants.ACTIVITY}</h1>
          <div className="d-flex my-4 align-items-center">
            <Checkbox
              disabled={
                activityInfoObj?.customerTaskActivity?.toDoComplete &&
                activityInfoObj?.customerTask?.status === 1
              }
              checked={
                activityInfoObj?.customerTaskActivity?.toDoComplete === 1
              }
              onChange={onMarkAcivityAsCompleteChkChanged}
            />
            <label className="ms-2">
              {' '}
              {constants.MARK_ACTIVITY_AS_COMPLETE}
            </label>
          </div>
          <div className="mt-3 mb-2">
            <label className="mb-2">{constants.ACTIVITY_TYPE}</label>
            <SelectWithHeader
              options={activityTypeListDropDown}
              Header={<ActivityTypeHeader />}
              components={{Option: ActivityTypeOptions}}
              onChange={(e: any) => onActivityTypeChange(e)}
              value={activityType}
              // Todo: define type
              getOptionLabel={(option: any) => option.activityID}
              isDisabled={!newActivity}
            />
          </div>
          {activityType?.hasProcedureNotes && (
            <Button
              size="medium"
              variant="contained"
              className="my-3"
              onClick={() => {
                onProcedureBtnClicked(activityType?.activityKey);
              }}
            >
              {constants.PROCEDURE}{' '}
            </Button>
          )}
          <TextField
            id="timestamp"
            label={constants.TIME_STAMP}
            margin="dense"
            value={moment
              .utc(activityInfoObj?.customerTaskActivity?.dateEntered)
              .format(constants.DATE_TIME_FORMAT)}
            onChange={e =>
              onCustomerTaskActivityChange(e.target.value, 'dateEntered', null)
            }
            disabled
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="tagged-amount"
            margin="dense"
            label={constants.TAGGED_AMT}
            disabled
            InputLabelProps={{
              shrink: true,
            }}
            value={(activityInfoObj?.taggedAmount || 0).toLocaleString(
              undefined,
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              },
            )}
          />

          <div className="d-flex align-items-center mb-2">
            <Checkbox
              id="spoke-with-customer-checkbox"
              checked={activityInfoObj?.customerTaskActivity?.successful}
              onChange={e =>
                onCustomerTaskActivityChange(
                  e.target.checked,
                  'successful',
                  null,
                )
              }
            />
            <label htmlFor="spoke-with-customer-checkbox" className="ms-2">
              {constants.SPOKE_WITH_CUSTOMER}
            </label>
          </div>
          <Button
            size="medium"
            variant="contained"
            onClick={handleShowActivityViewContactsModal}
            className="my-2"
          >
            {constants.VIEW_CONTACTS}{' '}
          </Button>
          <div className="card mt-4 px-2 d-flex position-relative">
            <span
              className="position-absolute px-2 ms-3 bg-white"
              style={{marginTop: '-10px'}}
            >
              {constants.FOLLOWUP.toLocaleUpperCase()}
            </span>
            <div className="d-flex mt-4 mb-1 align-items-center">
              <Checkbox
                disabled={
                  (props.data.followUp &&
                    props.data.activityComplete &&
                    props.data.ModeType === MODE_TYPES.enquiry) ||
                  activityInfoObj?.customerTask?.status === 1 ||
                  activityInfoObj?.customerTaskActivity?.toDoComplete === 1
                }
                checked={activityInfoObj?.customerTaskActivity?.toDo === 1}
                onChange={onScheduleFollowupChkChange}
              />{' '}
              <label>{constants.SCHEDULE_FOLLOWUP}</label>
            </div>
            <Select
              label={constants.ASSIGN_TO}
              margin="dense"
              disabled={
                activityInfoObj?.customerTaskActivity?.toDoComplete === 1 &&
                props.data.ModeType === MODE_TYPES.enquiry
              }
              value={activityInfoObj?.customerTaskActivity?.userKey}
              items={userListDropDown}
              onChange={e =>
                onCustomerTaskActivityChange(e.target.value, 'userKey', null)
              }
              placeholder={constants.SELECT_USER}
            />
            <TextField
              label={constants.USERS_TIME_ZONE}
              margin="dense"
              id="users-time-zone"
              value={userSettings?.timeZoneID}
              disabled
              InputLabelProps={{
                shrink: true,
              }}
            />
            <DateTimePicker
              fullWidth
              margin="dense"
              label={constants.FOLLOWUP_DATE}
              orientation="landscape"
              yearsPerRow={3}
              slotProps={{
                textField: {
                  size: 'small',
                  InputLabelProps: {
                    ...((props.data.followUp &&
                      props.data.activityComplete &&
                      props.data.ModeType === MODE_TYPES.enquiry) ||
                      activityInfoObj?.customerTask?.status === 1 ||
                      (activityInfoObj?.customerTaskActivity?.toDoComplete ===
                        1 && {shrink: true})),
                  },
                },
              }}
              disabled={
                (props.data.followUp &&
                  props.data.activityComplete &&
                  props.data.ModeType === MODE_TYPES.enquiry) ||
                activityInfoObj?.customerTask?.status === 1 ||
                activityInfoObj?.customerTaskActivity?.toDoComplete === 1
              }
              value={
                isEmpty(activityInfoObj?.customerTaskActivity?.toDoDate)
                  ? activityInfoObj?.customerTaskActivity?.toDoDate
                  : dayjs(activityInfoObj?.customerTaskActivity?.toDoDate) || ''
              }
              onChange={onFollowUpDateChange}
            />
            <TextField
              label={constants.RESPONSE_DAYS}
              margin="dense"
              disabled={
                (props.data.followUp &&
                  props.data.activityComplete &&
                  props.data.ModeType === MODE_TYPES.enquiry) ||
                activityInfoObj?.customerTask?.status === 1 ||
                activityInfoObj?.customerTaskActivity?.toDoComplete === 1
              }
              InputLabelProps={
                (props.data.followUp &&
                  props.data.activityComplete &&
                  props.data.ModeType === MODE_TYPES.enquiry) ||
                activityInfoObj?.customerTask?.status === 1 ||
                (activityInfoObj?.customerTaskActivity?.toDoComplete === 1 && {
                  shrink: true,
                })
              }
              value={
                activityInfoObj?.customerTaskActivity?.daysToRespond
                  ? activityInfoObj?.customerTaskActivity?.daysToRespond
                  : ''
              }
              onChange={onResponseDaysValueChange}
              onBlur={onResponseDaysBlur}
            />
            <TextField
              label={constants.RESPONSE_DATE}
              margin="dense"
              id="response-date"
              value={
                isEmpty(
                  activityInfoObj?.customerTaskActivity?.followUpRespondBy,
                )
                  ? ''
                  : dayjs(
                      activityInfoObj?.customerTaskActivity?.followUpRespondBy,
                    ).format('M/DD/YYYY h:mm A')
              }
              disabled
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              label={constants.DESCRIPTION}
              margin="dense"
              multiline
              InputProps={{
                rows: 5,
                inputProps: {maxLength: 300},
              }}
              value={
                activityInfoObj?.customerTaskActivity?.toDoDesc
                  ? activityInfoObj?.customerTaskActivity?.toDoDesc
                  : ''
              }
              onChange={e =>
                onCustomerTaskActivityChange(e.target.value, 'toDoDesc', null)
              }
              disabled={
                (props.data.followUp &&
                  props.data.activityComplete &&
                  props.data.ModeType === MODE_TYPES.enquiry) ||
                activityInfoObj?.customerTask?.status === 1 ||
                activityInfoObj?.customerTaskActivity?.toDoComplete === 1
              }
              InputLabelProps={{
                ...((props.data.followUp &&
                  props.data.activityComplete &&
                  props.data.ModeType === MODE_TYPES.enquiry) ||
                  activityInfoObj?.customerTask?.status === 1 ||
                  (activityInfoObj?.customerTaskActivity?.toDoComplete ===
                    1 && {shrink: true})),
              }}
            />
          </div>
        </div>
        <div className="p-3 pb-0" style={{flex: '1', width: '60%'}}>
          {activityInfoObj && (
            <Tabs
              variant="scrollable"
              initialTab={activeIndex}
              tabs={[
                {
                  label: constants.NOTES,
                  content: (
                    <div className="tabs-content-wrapper">
                      <CommunicateNotes
                        updateActivityInfoState={updateActivityInfoState}
                        activityInfo={activityInfoObj}
                        modeType={props.data.ModeType}
                        originalNotes={
                          activityInfo?.customerTaskActivity?.notes
                        }
                        activityRef={activityRef}
                        isDraftView={isDraftView}
                      />
                    </div>
                  ),
                },
                {
                  label: constants.TRANSACTIONS,
                  content: (
                    <div className="tabs-content-wrapper">
                      <CommunicateTransaction
                        handleTableFilterDisplayType={
                          handleTableFilterDisplayType
                        }
                        activityInfo={activityInfoObj}
                        custKey={custKey}
                        taskKey={taskKey}
                        taskActKey={taskActKey}
                        groupKey={groupKey}
                        setActivityEditTransactionsObj={
                          setActivityEditTransactionsObj
                        }
                        activityEditTransactionsObj={
                          activityEditTransactionsObj
                        }
                        custName={custName}
                        custId={custId}
                        modeType={props.data.ModeType}
                        showAllAccountsCheckbox={showAllAccountsCheckbox}
                        updateActivityInfoState={updateActivityInfoState}
                      />
                    </div>
                  ),
                },
                {
                  label: constants.EMAIL,
                  disabled:
                    props.data.ModeType === MODE_TYPES.enquiry &&
                    !activityInfoObj?.isEmailEnabled &&
                    !isDraftView,
                  content: (
                    <div className="tabs-content-wrapper">
                      <CommunicateEmail
                        unChangedMailProp={unChangedMailProp}
                        activityRef={activityRef}
                        activityInfo={activityInfoObj}
                        newActivity={isDraftView ? true : newActivity}
                        mailKey={props.data.mailKey || 0}
                        handleTableFilterDisplayType={
                          handleTableFilterDisplayType
                        }
                        updateActivityInfoState={updateActivityInfoState}
                        setShowAddContactDialog={setShowAddContactDialog}
                        setShowAddPersonalContactsDialog={
                          setShowAddPersonalContactsDialog
                        }
                        groupKey={groupKey}
                        custKey={custKey}
                        userKey={sessionUserKey}
                        taskActKey={taskActKey}
                        setSaveAndSendButtonText={setSaveAndSendButtonText}
                        setIsReplyBtnClicked={setIsReplyBtnClicked}
                        setIsReplyAllBtnClicked={setIsReplyAllBtnClicked}
                        setIsForwardBtnClicked={setIsForwardBtnClicked}
                        onNewActivityButtonClicked={onNewActivityButtonClicked}
                        isEmailEnabled={activityInfoObj?.isEmailEnabled}
                        copyNotesEmailBtnClicked={copyNotesEmailBtnClicked}
                        ModeType={props.data.ModeType}
                        setLoadingCommunicate={setLoadingCommunicate}
                        setShowCopyAttachmentsToCustDocsDialog={
                          setShowCopyAttachmentsToCustDocsDialog
                        }
                        attachments={attachments}
                        setAttachments={setAttachments}
                      />
                    </div>
                  ),
                },
                {
                  label: constants.VOICE_MESSAGE,
                  disabled: !activityInfoObj?.isTextMessageEnabled,
                  content: (
                    <div className="tabs-content-wrapper">
                      <CommunicateVoiceMessage
                        activityInfo={activityInfoObj}
                        newActivity={isDraftView ? true : newActivity}
                        updateActivityInfoState={updateActivityInfoState}
                        groupKey={groupKey}
                        custKey={custKey}
                        userKey={sessionUserKey}
                        modeType={props.data.ModeType}
                      />
                    </div>
                  ),
                },
                {
                  label: constants.TEXT,
                  content: (
                    <div className="tabs-content-wrapper">
                      <CommunicateText
                        activityInfo={activityInfoObj}
                        newActivity={isDraftView ? true : newActivity}
                        updateActivityInfoState={updateActivityInfoState}
                        groupKey={groupKey}
                        custKey={custKey}
                        userKey={sessionUserKey}
                        modeType={props.data.ModeType}
                      />
                    </div>
                  ),
                  disabled: !activityInfoObj?.isTextMessageEnabled,
                },
                {
                  label: constants.ATTACHMENTS,
                  disabled:
                    !isDraftView &&
                    props.data.ModeType === MODE_TYPES.enquiry &&
                    !activityInfoObj?.isAttachmentsEnabled,
                  content: (
                    <div className="tabs-content-wrapper">
                      <CommunicateAttachments
                        newActivity={isDraftView ? true : newActivity}
                        activityInfo={activityInfoObj}
                        groupKey={groupKey}
                        custKey={custKey}
                      />
                    </div>
                  ),
                },
                {
                  label: constants.PAYMENT,
                  disabled:
                    props.data.ModeType === MODE_TYPES.enquiry &&
                    !activityInfoObj?.isPaymentEnabled,
                  content: (
                    <div className="tabs-content-wrapper">
                      <CommunicatePayment
                        activityInfo={activityInfoObj}
                        updateActivityInfoState={updateActivityInfoState}
                        newActivity={isDraftView ? true : newActivity}
                        taggedAmount={activityInfoObj?.taggedAmount ?? 0}
                        isAmountEdited={isAmountEdited}
                        setIsAmountEdited={setIsAmountEdited}
                      />
                    </div>
                  ),
                },
              ]}
              onTabChange={index => setActiveIndexToLoadContactsList(index)}
            />
          )}
        </div>
      </div>
    </div>
  );
}
export default Communicate;
