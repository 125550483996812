import {actionTypes} from '../constants/actionTypes';

const initialState = {
  pendingPaymentDetailsData: [],
  pendingPaymentDetailsViewSettings: null,
  fetchingPendingPaymentDetailsFailed: false,
  fetchedPendingPaymentDetails: false,
  fetchingPendingPaymentDetails: false,
  pendingPaymentDetailsCount: 0,
  pendingPaymentDetailsNextLink: '',
  pendingPaymentDetailsSelectedRows: [],
  exportingToExcel: false,
  exportedToExcel: false,
  exportingToExcelFailed: false,
  pendingPaymentDetailsSettingId: null,
  isRestricted: true,
  alertDescription: '',
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const pendingPaymentDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_PENDING_PAYMENTS_DETAILS:
      return {
        ...state,
        fetchingPendingPaymentDetailsFailed: false,
        fetchingPendingPaymentDetails: true,
        fetchedPendingPaymentDetails: false,
      };
    case actionTypes.FETCHED_PENDING_PAYMENTS_DETAILS:
      return {
        ...state,
        fetchingPendingPaymentDetailsFailed: false,
        scheduledPaymentsAccounts: true,
        pendingPaymentDetailsData: action.pendingPaymentDetailsData,
        selectedSchedulePaymentsPageSize:
          action.selectedSchedulePaymentsPageSize || 10,
        fetchingPendingPaymentDetails: false,
        pendingPaymentDetailsCount: action.pendingPaymentDetailsCount,
        pendingPaymentDetailsNextLink: action.pendingPaymentDetailsNextLink,
      };
    case actionTypes.FETCHING_PENDING_PAYMENTS_DETAILS_FAILED:
      return {
        ...state,
        fetchingPendingPaymentDetailsFailed: true,
        fetchedPendingPaymentDetails: true,
        fetchingPendingPaymentDetails: false,
        pendingPaymentDetailsData: [],
        pendingPaymentDetailsCount: 0,
      };
    case actionTypes.EXPORTING_PENDING_PAYMENTS_DETAILS_TO_EXCEL:
      return {
        ...state,
        fetchingPendingPaymentDetails: true,
        exportingToExcel: true,
        exportedToExcel: false,
        exportingToExcelFailed: false,
      };
    case actionTypes.EXPORTED_PENDING_PAYMENTS_DETAILS_TO_EXCEL:
      return {
        ...state,
        fetchingPendingPaymentDetails: false,
        exportingToExcel: false,
        exportedToExcel: true,
        exportingToExcelFailed: false,
      };
    case actionTypes.EXPORTING_PENDING_PAYMENTS_DETAILS_TO_EXCEL_FAILED:
      return {
        ...state,
        fetchingPendingPaymentDetails: false,
        exportingToExcel: false,
        exportedToExcel: false,
        exportingToExcelFailed: true,
      };
    case actionTypes.UPDATE_PENDING_PAYMENTS_DETAILS_VIEW_SETTING_KEY:
      return {
        ...state,
        pendingPaymentDetailsSettingId: action.pendingPaymentDetailsSettingId,
      };
    case actionTypes.SET_PENDING_PAYMENT_IS_RESTRICTED:
      return {
        ...state,
        isRestricted: action.isRestricted,
      };
    case actionTypes.SET_PENDING_PAYMENTS_ALERT_DESCRIPTION:
      return {...state, alertDescription: action.alertDescription};

    case actionTypes.SET_PENDING_PAYMENTS_DETAILS_DASHBOARD_VIEW_SETTING_OBJECT:
      return {
        ...state,
        pendingPaymentDetailsViewSettings: {
          ...action.payload.viewSettings,
          settingsKey: action.payload.settingId,
          sortSetting: null,
        },
        pendingPaymentDetailsSettingId: action.payload.settingId,
      };

    case actionTypes.CLEAN_UP_PENDING_PAYMENTS_DETAILS:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default pendingPaymentDetailsReducer;
