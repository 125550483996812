import {Paginator} from 'primereact';
import {useState, useEffect} from 'react';
import {FcFolder} from 'react-icons/fc';
import {downloadFileFromUrl} from '../../../../utils/fileUtils';
import {useFileManager} from '../FileManager';
import {FileItem} from './FileItem';
import {constants} from '../../../../constants/common';

const PAGESIZE = 10;

export const FileViewer = ({
  selectedFilesAndFolder,
  onFileSelected,
  fileSelected,
}) => {
  const {isListView, onTreeSelected, updateTreeSelection} = useFileManager();

  const [combinedList, setCombinedList] = useState(null);
  const [paginatedList, setPaginatedList] = useState(null);
  const [first, setFirst] = useState(0);

  const itemClass = `d-inline-flex h-fit-content m-2 cursor-pointer ${
    isListView ? 'w-fit-content' : 'flex-column width-100-px '
  } `;
  const size = isListView ? 28 : 60;

  const folderListItemClassName = child => {
    return `cursor-pointer ${
      isListView &&
      'ps-2 d-flex justify-content-between py-2 align-items-center'
    } ${!isListView && 'm-2 w-h-110-px'} ${
      paginatedList.length > 9 ? 'pe-2' : 'pe-4'
    } ${
      fileSelected?.isFolder && fileSelected?.path === child?.path
        ? 'active'
        : ''
    }`;
  };

  const paginate = (array, pageNumber) => {
    return array.slice((pageNumber - 1) * PAGESIZE, pageNumber * PAGESIZE);
  };
  useEffect(() => {
    if (selectedFilesAndFolder) {
      const folderList =
        selectedFilesAndFolder.children?.map(folder => {
          return {...folder, isFolder: true};
        }) || [];
      const filesList =
        selectedFilesAndFolder.files
          ?.filter(file => file.fileName !== 'directory.exists')
          .map(file => {
            return {...file, isFolder: false};
          }) || [];
      setFirst(0);
      setCombinedList([...folderList, ...filesList]);
      setPaginatedList(paginate([...folderList, ...filesList], 1));
    }
  }, [selectedFilesAndFolder]);

  const updatePaginationList = pageNumber => {
    setPaginatedList(paginate(combinedList, pageNumber));
  };

  const onFolderClicked = folder => {
    onTreeSelected(folder);
    updateTreeSelection(folder.key);
    onFileSelected(null);
  };

  const onDownloadFile = file => {
    downloadFileFromUrl(file.fileUrl, file.fileName);
  };

  const onFileSelect = file => {
    onFileSelected(file);
  };

  if (!paginatedList || paginatedList.length === 0)
    return <p className="m-2">{constants.NO_RECORDS_TO_DISPLAY}</p>;

  return (
    <div className="h-100">
      {isListView && (
        <div className="d-flex justify-content-between bg-light p-3 pe-5">
          <div>{constants.FILE_NAME}</div>
          <div>{constants.SIZE}</div>
        </div>
      )}

      <div
        className={`m-2 d-flex ${
          isListView ? 'h-380-px' : 'h-412-px'
        } ps-2 pe-3 overflow-y-auto ${
          isListView ? 'flex-column' : 'flex-row flex-wrap'
        }`}
      >
        {paginatedList.map(child => {
          return child.isFolder ? (
            <div
              aria-hidden="true"
              key={child.key}
              className={folderListItemClassName(child)}
              onDoubleClick={() => onFolderClicked(child)}
              onClick={() => onFileSelect(child)}
            >
              <div
                className={`d-flex align-items-center ${
                  isListView
                    ? 'align-self-center'
                    : 'flex-column text-center px-3'
                }`}
              >
                <FcFolder size={isListView ? size : 71} />{' '}
                <span
                  title={child.label}
                  className={`${!isListView && 'm-1 overflow-ellipse'}`}
                >
                  {child.label}
                </span>
              </div>
            </div>
          ) : (
            <div
              className={`${!isListView && 'm-2 w-h-110-px'} ${
                isListView &&
                'd-flex justify-content-between align-items-center'
              }  ${paginatedList.length > 9 ? 'pe-2' : 'pe-4'} ${
                fileSelected && fileSelected.fileName === child.fileName
                  ? 'active'
                  : ''
              }`}
              aria-hidden="true"
              onDoubleClick={() => onDownloadFile(child)}
              onClick={() => onFileSelect(child)}
            >
              <FileItem
                key={child.fileName}
                className={itemClass}
                name={child.fileName}
                fileUrl={child.fileUrl}
                size={size}
                isListView={isListView}
              />
              {isListView && <div>{child.fileSize}</div>}
            </div>
          );
        })}
      </div>
      <Paginator
        className="custom-pagination align-bottom"
        first={first}
        rows={10}
        totalRecords={combinedList.length}
        onPageChange={e => {
          setFirst(e.first);
          updatePaginationList(e.page + 1);
        }}
      />
    </div>
  );
};
