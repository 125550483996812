import {constants, fileNames} from '../../constants/common';
import {
  exportAllAccounts,
  fetchAllAccounts,
  fetchAllAccountsTotals,
} from '../../services/accounts';
import {downloadExcel} from '../../utils/fileUtils';
import {stripHTML} from '../../utils/stringUtils';
import {actionTypes} from '../constants/actionTypes';

export const getAllAccountsAndViewSettings =
  options => (dispatch, getState) => {
    const toast = getState().generalReducer?.toastRef;
    const allAccountsState = getState().allAccountsReducer;
    allAccountsState.isAllAccountSelected = options.isSelectAll;
    const {
      userKey,
      groupKey,
      SettingKey,
      sortQuery,
      pageSize,
      skip,
      files,
      isBulkUpload,
      filterQuery,
      sqlQuery,
    } = options;
    const filterQueryVal =
      SettingKey === allAccountsState?.viewSettings?.settingsKey
        ? filterQuery
        : '';

    dispatch({type: actionTypes.FETCHING_ALL_ACCOUNTS});
    fetchAllAccounts({
      allAccountsState,
      userKey,
      groupKey,
      SettingKey,
      sortQuery,
      globalFilterQuery: filterQueryVal,
      pageSize,
      skip,
      files,
      isBulkUpload,
      sqlQuery,
    })
      .then(res => {
        if (res.data.value && res.data.value.length) {
          res.data.value.forEach(x => {
            // eslint-disable-next-line no-param-reassign
            x.lastActivityNote = stripHTML(x.lastActivityNote);
          });
        }

        const tableObj = {
          allAccountsData: res.data.value,
          allAccountsSkip: allAccountsState.allAccountsSkip,
          allAccountsCount: res.data.count,
          selectedAllAccountsPageSize: res.data.count,
          allAccountsNextLink: res.data.nextLink,
          allAccountsSelectedRows: allAccountsState.allAccountsSelectedRows,
          viewSettings: res.data.viewSetting,
          isAllAccountSelected: options.isSelectAll,
          selectAllAccountsData:
            options.isSelectAll || isBulkUpload
              ? res.data.value
              : allAccountsState.selectAllAccountsData,
        };
        dispatch({
          type: actionTypes.FETCHED_ALL_ACCOUNTS,
          ...tableObj,
        });

        return res.data;
      })
      .catch(e => {
        let allAccountsErrorMessage = '';

        if (e.response?.status === 400) {
          allAccountsErrorMessage = e.response?.data;
        } else {
          toast?.current.show({
            severity: 'warn',
            summary: constants.SOMETHING_WENT_WRONG,
            detail: '',
          });
        }

        dispatch({
          type: actionTypes.FETCHING_ALL_ACCOUNTS_FAILED,
          allAccountsErrorMessage,
        });
      });
  };

export const setAllAccountsSkip = allAccountsSkip => {
  return {
    type: actionTypes.SET_ALL_ACCOUNTS_SKIP,
    allAccountsSkip,
  };
};

export const updateAllAccountsAllRowsSelected = selectedRows => dispatch => {
  dispatch({
    type: actionTypes.UPDATED_ALL_ACCOUNTS_SELECTED_ROWS,
    allAccountsSelectedRows: selectedRows,
  });
};

export const updateAllAccountsPageSize = allAccountsPageSize => dispatch => {
  dispatch({type: actionTypes.UPDATING_ALL_ACCOUNTS_PAGE_SIZE});
  dispatch({
    type: actionTypes.UPDATE_ALL_ACCOUNTS_PAGE_SIZE,
    allAccountsPageSize,
  });
};

export const updateAllAccountsFilterQuery = allAccountsFilterQuery => {
  return {
    type: actionTypes.SET_ALL_ACCOUNTS_FILTER_QUERY,
    allAccountsFilterQuery,
  };
};

export const updateSettingKey = settingId => {
  return {
    type: actionTypes.SET_ALL_ACCOUNTS_VIEW_SETTING_KEY,
    settingId,
  };
};

export const updateAllAccountsViewSettingObject = ({
  settingId,
  viewSettings,
}) => {
  return {
    type: actionTypes.SET_ALL_ACCOUNTS_VIEW_SETTING_OBJECT,
    payload: {settingId, viewSettings},
  };
};

export const updateAllAccountsLoading = allAccountsLoading => {
  return {
    type: actionTypes.SET_ALL_ACCOUNTS_LOADING,
    allAccountsLoading,
  };
};

export const allAccountsExportToExcel =
  ({
    userKey,
    groupKey,
    modeType,
    SettingKey,
    sortQuery,
    filterQuery,
    sqlQuery,
  }) =>
  (dispatch, getState) => {
    const {allAccountsReducer} = getState();
    const toast = getState().generalReducer?.toastRef;

    dispatch({type: actionTypes.EXPORTING_ALL_ACCOUNTS_TO_EXCEL});
    exportAllAccounts(
      allAccountsReducer,
      userKey,
      groupKey,
      SettingKey,
      sortQuery,
      filterQuery,
      sqlQuery,
    )
      .then(res => {
        downloadExcel(
          res.data,
          fileNames[modeType] ?? 'ExportFile',
          toast,
          constants.ALL_ACCOUNTS_EXPORTED_TO_EXCEL,
        );
        dispatch({type: actionTypes.EXPORTED_ALL_ACCOUNTS_TO_EXCEL});
      })
      .catch(() => {
        dispatch({type: actionTypes.EXPORTING_ALL_ACCOUNTS_TO_EXCEL_FAILED});
      });
  };

export const getAllAccountsTotals = options => (dispatch, getState) => {
  const toast = getState().generalReducer?.toastRef;
  const allAccountsState = getState().allAccountsReducer;
  allAccountsState.isAllAccountSelected = options.isSelectAll;
  const {
    userKey,
    groupKey,
    SettingKey,
    sortQuery,
    filterQuery,
    isBulkUpload,
    sqlQuery,
  } = options;

  dispatch({type: actionTypes.FETCHING_ALL_ACCOUNTS_TOTALS});
  fetchAllAccountsTotals({
    allAccountsState,
    userKey,
    groupKey,
    SettingKey,
    sortQuery,
    globalFilterQuery: filterQuery,
    isAllAccountsTotal: true,
    isBulkUpload,
    sqlQuery,
  })
    .then(res => {
      dispatch({
        type: actionTypes.FETCHED_ALL_ACCOUNTS_TOTALS,
        allAccountsTotalsData: res.data.value,
      });

      return res.data.value;
    })
    .catch(() => {
      toast?.current.show({
        severity: 'warn',
        summary: constants.SOMETHING_WENT_WRONG,
        detail: '',
      });
      dispatch({type: actionTypes.FETCHING_ALL_ACCOUNTS_TOTALS_FAILED});
    });
};

export const cleanUpAllAccountsData = () => {
  return {
    type: actionTypes.CLEAN_UP_ALL_ACCOUNTS_DATA,
  };
};
