import {actionTypes} from '../constants/actionTypes';

const initialState = {
  accountNotesCount: 0,
  accountNotesNextLink: '',
  accountNotes: [],
  accountNotesLoading: false,
  fetchingAccountNotes: false,
  fetchedAccountNotes: false,
  fetchingAccountNotesFailed: false,
  accountNotesExportingToExcel: false,
  accountNotesExportedToExcel: false,
  accountNotesExportingToExcelFailed: false,
  accountNotesFilter: '',
  accountNotesSelectedRows: [],
  accountNotesAllRowsSelected: false,
  deletingAccountNotes: false,
  deletedAccountNotes: false,
  deletingAccountNotesFailed: false,
};

const accountNotesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_ACCOUNT_NOTES:
      return {
        ...state,
        accountNotesLoading: true,
        fetchingccountNotes: true,
        fetchedAccountNotes: false,
        fetchingAccountNotesFailed: false,
      };
    case actionTypes.FETCHED_ACCOUNT_NOTES:
      return {
        ...state,
        accountNotesLoading: false,
        fetchingAccountNotes: false,
        fetchedAccountNotes: true,
        fetchingAccountNotesFailed: false,
        accountNotes: action.accountNotes,
        accountNotesCount: action.accountNotesCount,
        accountNotesNextLink: action.accountNotesNextLink,
        accountNotesSelectedRows: action.accountNotesSelectedRows,
      };
    case actionTypes.FETCHING_ACCOUNT_NOTES_FAILED:
      return {
        ...state,
        accountNotesLoading: false,
        fetchingAccountNotes: false,
        fetchedAccountNotes: false,
        fetchingAccountNotesFailed: true,
      };

    case actionTypes.DELETING_ACCOUNT_NOTES:
      return {
        ...state,
        accountNotesLoading: true,
        deletingAccountNotes: true,
        deletingAccountNotesFailed: false,
        deletedAccountNotes: false,
      };
    case actionTypes.DELETED_ACCOUNT_NOTES:
      return {
        ...state,
        accountNotesLoading: false,
        deletedAccountNotes: false,
        deletingAccountNotes: false,
        deletingAccountNotesFailed: false,
      };
    case actionTypes.DELETING_ACCOUNT_NOTES_FAILED:
      return {
        ...state,
        accountNotesLoading: false,
        deletingAccountNotesFailed: true,
        deletingAccountNotes: false,
        deletedAccountNotes: false,
      };
    default:
      return state;
  }
};
export default accountNotesReducer;
