import {InputText, Button} from 'primereact';
import {useState} from 'react';
import {constants} from '../../../../constants/common';
import {useFileManager} from '../FileManager';

export const NewFolder = ({onHide}) => {
  const [folderName, setFolderName] = useState();
  const {onNewFolderCreate} = useFileManager();
  return (
    <div className="d-flex flex-column">
      <label htmlFor="folder-name">{constants.ENTER_THE_NEW_FOLDER_NAME}</label>
      <InputText
        id="folder-name"
        className="input-text-custom mt-1"
        onChange={e => setFolderName(e.target.value)}
        autoFocus
      />
      <div className="mt-4 align-self-end">
        <Button
          className="bg-purple me-2"
          onClick={() => {
            onNewFolderCreate(folderName);
            onHide();
          }}
        >
          {constants.SAVE}
        </Button>
        <Button className="p-button p-button-outlined" onClick={onHide}>
          {constants.CANCEL}
        </Button>
      </div>
    </div>
  );
};
