import {useState, useEffect, useRef} from 'react';
import {FileUpload} from 'primereact';
import {useDispatch, useSelector} from 'react-redux';
import {Box} from '@mui/material';
import {constants} from '../../../constants/common';
import {downloadFile} from '../../../utils/fileUtils';
import {downloadActivityAttachment} from '../../../services/activities';
import {
  attachReport,
  attachTaggedInvoice,
  checkUnCheckEmail,
  deleteAttachment,
  getActivityAttachments,
  getActivityReportInfo,
  updateShowNoDataForReport,
  uploadFileAttachments,
} from '../../../store/actions/activitiesActions';
import {StorageType} from '../../../constants/typeConstants';
import Backdrop from '../../common/Backdrop';
import {isEmpty} from '../../../lib/utils';
import {Dialog, Snackbar} from '../../../ui/feedback';
import {Button, Checkbox, Select} from '../../../ui/inputs';

function CommunicateAttachments({
  newActivity,
  groupKey,
  activityInfo,
  custKey,
}) {
  const {
    activityAttachments,
    isAttachmentLoaded,
    loading,
    showNotDataForReportDialog,
  } = useSelector(store => store.activitiesReducer);
  const {isLoading} = useSelector(store => store.fileReducer);

  const fileUploadRef = useRef();

  const dispatch = useDispatch();

  const [showNoTaggedInvoiceFoundDialog, setShowNoTaggedInvoiceFoundDialog] =
    useState(false);

  const loadActivityAttachments = () => {
    dispatch(getActivityAttachments(activityInfo.customerTask.workSessionID));
  };

  useEffect(() => {
    if (newActivity) {
      dispatch(getActivityReportInfo(groupKey));
    }
    if (activityInfo?.customerTask?.workSessionID) {
      loadActivityAttachments();
    }
  }, []);

  const reportInfoDropDown = useSelector(store => {
    const {activityReportInfo} = store.activitiesReducer;
    const mappedList = activityReportInfo.map(info => {
      return {label: info.reportName, value: info.reportKey};
    });
    return [{label: constants.NONE, value: constants.NONE}, ...mappedList];
  });

  const [selectedReportInfo, setSelectedReportInfo] = useState(
    reportInfoDropDown[0].value,
  );
  const reportDefaultOutputOptions = [
    {
      label: constants.PDF.toLocaleUpperCase(),
      value: constants.PDF.toLocaleUpperCase(),
    },
    {
      label: constants.TEXT.toLocaleUpperCase(),
      value: constants.TEXT.toLocaleUpperCase(),
    },
    {
      label: constants.CSV.toLocaleUpperCase(),
      value: constants.CSV.toLocaleUpperCase(),
    },
    {
      label: constants.XLS.toLocaleUpperCase(),
      value: constants.XLS.toLocaleUpperCase(),
    },
    {
      label: constants.XLSX.toLocaleUpperCase(),
      value: constants.XLSX.toLocaleUpperCase(),
    },
    {
      label: constants.RTF.toLocaleUpperCase(),
      value: constants.RTF.toLocaleUpperCase(),
    },
    {
      label: constants.BMP.toLocaleUpperCase(),
      value: constants.BMP.toLocaleUpperCase(),
    },
    {
      label: constants.GIF.toLocaleUpperCase(),
      value: constants.GIF.toLocaleUpperCase(),
    },
    {
      label: constants.JPEG.toLocaleUpperCase(),
      value: constants.JPEG.toLocaleUpperCase(),
    },
    {
      label: constants.PNG.toLocaleUpperCase(),
      value: constants.PNG.toLocaleUpperCase(),
    },
    {
      label: constants.TIFF.toLocaleUpperCase(),
      value: constants.TIFF.toLocaleUpperCase(),
    },
  ];
  const [selectedReportDefaultOption, setSelectedReportDefaultOption] =
    useState(reportDefaultOutputOptions[0].value);
  function getExtension(filename) {
    const ext = filename.split('.').pop();
    if (ext === filename) return '';
    return ext;
  }
  const onDownloadAttachment = async att => {
    const ext = getExtension(att.attachFileName);
    const fileName =
      ext.length > 0 ? att.attachFileName : `${att.attachFileName}.pdf`;
    const fileUrl = ext.length > 0 ? att.attachPath : `${att.attachPath}.pdf`;
    try {
      const {data} = await downloadActivityAttachment(groupKey, {
        fileName,
        fileUrl,
      });
      downloadFile(data, att.attachFileName);
    } catch (error) {
      console.log(error);
    }
  };

  const onHandleFileUpload = event => {
    const {files} = event;
    if (activityInfo?.customerTask?.workSessionID) {
      const workSessionID = activityInfo?.customerTask?.workSessionID;
      const request = {
        StorageType: StorageType.public,
        BlobPath: `${groupKey}/temp/attachments/${workSessionID}`,
        GroupKey: groupKey,
        Files: files,
        workSessionId: workSessionID,
      };
      dispatch(uploadFileAttachments(request));
      fileUploadRef.current.clear();
    }
  };

  const onHandleAttachTaggedInvoice = () => {
    if (activityInfo?.customerTask?.workSessionID) {
      const workSessionID = activityInfo?.customerTask?.workSessionID;
      dispatch(attachTaggedInvoice({workSessionID, groupKey}));
    }
  };

  const onHandleDelete = attachment => {
    const request = {
      fileName: attachment.attachFileName,
      workSessionId: attachment.workSessionID,
    };
    dispatch(deleteAttachment(request));
  };

  const onHandleEmailCheck = (value, attachment, index) => {
    const request = {
      fileName: attachment.attachFileName,
      workSessionId: attachment.workSessionID,
      excludeFromEmail: value,
    };
    dispatch(checkUnCheckEmail(request, index));
  };

  const handleOnAttachReportClicked = () => {
    if (selectedReportInfo !== constants.NONE) {
      const options = {
        groupKey,
        custKey,
        workSessionId: activityInfo?.customerTask?.workSessionID,
        reportKey: selectedReportInfo,
        reportOutputType: selectedReportDefaultOption,
      };
      dispatch(attachReport(options));
    }
  };

  useEffect(() => {
    if (isAttachmentLoaded && activityAttachments?.length === 0) {
      setShowNoTaggedInvoiceFoundDialog(true);
    } else {
      setShowNoTaggedInvoiceFoundDialog(false);
    }
  }, [activityAttachments, isAttachmentLoaded]);
  return (
    <>
      {(loading || isLoading) && <Backdrop />}
      <Snackbar
        open={showNoTaggedInvoiceFoundDialog}
        type="error"
        message={constants.No_TaggedInvoice_Found}
        title={constants.PLEASE_SELECT_TAGGED_INVOICE}
        onClose={() => setShowNoTaggedInvoiceFoundDialog(false)}
      />
      <Dialog
        title={constants.ALERT}
        open={showNotDataForReportDialog}
        onClose={() => dispatch(updateShowNoDataForReport(false))}
        showCancelButton={false}
        showConfirmButton={false}
      >
        <div className="p-2">{constants.NO_DATA_TO_GENERATE_REPORT}</div>
      </Dialog>
      <label className="my-2 p-1">
        {constants.CHECK_ITEMS_BELOW_TO_EXECUTE_THEM_FROM_EMAILS}
      </label>
      <div className="border overflow-scroll h-300-px">
        {activityAttachments?.length === 0 ? (
          constants.NO_RECORDS_TO_DISPLAY
        ) : (
          <>
            {activityAttachments.map((attachment, index) => (
              <div
                key={attachment.id}
                className="d-flex m-4 justify-content-between align-items-center"
              >
                <div className="w-50">
                  <Checkbox
                    checked={attachment.excludeFromEmail}
                    className="me-2"
                    onChange={e =>
                      onHandleEmailCheck(e.target.checked, attachment, index)
                    }
                  />
                  {attachment.attachFileName}
                </div>
                <Button
                  variant="text"
                  className="blueLink"
                  onClick={() => onDownloadAttachment(attachment)}
                >
                  {constants.PREVIEW}
                </Button>
                <div>
                  <Button
                    variant="outlined"
                    onClick={() => onHandleDelete(attachment)}
                    size="small"
                  >
                    {constants.DELETE}
                  </Button>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
      {newActivity && (
        <>
          <div className="d-flex align-items-center me-5 my-3">
            <label className="me-5">
              {constants.TO_ATTACH_FILE_CLICK_BROWSE}
            </label>
            <FileUpload
              ref={fileUploadRef}
              mode="basic"
              name="upload"
              chooseLabel={constants.BROWSE}
              accept=".xls,.xlsx,.csv,.doc,.docx,.txt,.zip,.pdf,image/*"
              maxFileSize={25000000}
              customUpload
              uploadHandler={onHandleFileUpload}
            />
          </div>

          <div className="my-2 d-flex align-items-center">
            <Select
              label={constants.SELECT_REPORT}
              className="me-3"
              value={selectedReportInfo}
              items={reportInfoDropDown}
              onChange={e => setSelectedReportInfo(e.target.value)}
              formMargin="dense"
              size="small"
            />

            <Select
              label={constants.REPORT_DEFAULT_OUTPUT}
              className="me-3"
              value={selectedReportDefaultOption}
              items={reportDefaultOutputOptions}
              onChange={e => setSelectedReportDefaultOption(e.target.value)}
              formMargin="dense"
              size="small"
            />
            <Box sx={{width: '300px'}}>
              <Button
                variant="contained"
                onClick={handleOnAttachReportClicked}
                disabled={
                  isEmpty(selectedReportInfo) ||
                  selectedReportInfo === constants.NONE
                }
                style={{
                  alignSelf: 'center',
                }}
                size="medium"
              >
                {constants.ATTACH_REPORT}
              </Button>
            </Box>
          </div>

          <div className="my-2 d-flex align-items-center">
            <Button
              variant="contained"
              onClick={onHandleAttachTaggedInvoice}
              size="medium"
            >
              {constants.ATTACH_TAGGED_INVOICES}
            </Button>
          </div>
        </>
      )}
    </>
  );
}
export default CommunicateAttachments;
