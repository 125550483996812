import {constants} from '../../constants/common';
import {Dialog} from '../../ui';
import {Button} from '../../ui/inputs';

export const PaymentErrorDialog = ({
  errorMessage,
  onClose,
  headerMessage = constants.INFO,
}: {
  errorMessage: string;
  onClose: () => void;
  headerMessage?: string;
}) => {
  return (
    <Dialog
      open
      title={headerMessage}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
    >
      <p className="py-2">{errorMessage}</p>
      <Button
        variant="contained"
        size="medium"
        className="my-2"
        onClick={onClose}
      >
        {constants.OK}
      </Button>
    </Dialog>
  );
};
