import {constants, fileNames} from '../../constants/common';
import {
  deleteDraftActivity,
  exportDraftDashboardData,
  fetchingDraftDashboard,
} from '../../services/draft';
import {MODE_TYPES} from '../../utils/constants';
import {downloadExcel} from '../../utils/fileUtils';
import {actionTypes} from '../constants/actionTypes';

export const getDraftDashboard = options => (dispatch, getState) => {
  const {toast} = getState().generalReducer;
  const draftState = getState().draftReducer;
  const {userKey, sortQuery, filterQuery, settingKey, sqlQuery} = options;
  const filterQueryVal =
    settingKey === draftState?.draftDataViewSettings?.settingsKey
      ? filterQuery
      : '';

  dispatch({type: actionTypes.FETCHING_DRAFT_DASHBOARD});
  fetchingDraftDashboard({
    userKey,
    sortQuery,
    globalFilterQuery: filterQueryVal,
    settingKey,
    sqlQuery,
  })
    .then(res => {
      dispatch({
        type: actionTypes.FETCHED_DRAFT_DASHBOARD,
        draftDashboardData: res.data.value,
        draftDataCount: res.data.count,
      });
    })
    .catch(() => {
      toast?.current.show({
        severity: 'warn',
        summary: constants.SOMETHING_WENT_WRONG,
        detail: '',
      });
      dispatch({type: actionTypes.FETCHING_DRAFT_DASHBOARD_FAILED});
    });
};

export const updateDraftDashboardViewSettingObject = ({
  settingId,
  viewSettings,
}) => {
  return {
    type: actionTypes.SET_DRAFT_DASHBOARD_VIEW_SETTING_OBJECT,
    payload: {settingId, viewSettings},
  };
};

export const draftsDataExportToExcel =
  ({
    userKey,
    sortQuery,
    filterQuery,
    settingKey,
    skipRows,
    rowCount,
    sqlQuery,
  }) =>
  (dispatch, getState) => {
    const toast = getState().generalReducer?.toastRef;

    dispatch({type: actionTypes.EXPORTING_DRAFTS_TO_EXCEL});
    exportDraftDashboardData({
      userKey,
      sortQuery,
      globalFilterQuery: filterQuery,
      settingKey,
      skipRows,
      rowCount,
      filterQuery,
      sqlQuery,
    })
      .then(res => {
        downloadExcel(
          res.data,
          fileNames[MODE_TYPES] ?? 'ExportFile',
          toast,
          constants.EXPORT_TO_EXCEL_SUCCESSFUL,
        );
        dispatch({type: actionTypes.EXPORTED_DRAFTS_TO_EXCEL});
      })
      .catch(() => {
        dispatch({
          type: actionTypes.EXPORTING_TO_EXCEL_DRAFTS_FAILED,
        });
      });
  };

export const deleteDraftsActivity = options => (dispatch, getState) => {
  const toast = getState().generalReducer?.toastRef;
  dispatch({type: actionTypes.DELETING_DRAFTS_ACTIVITY});
  deleteDraftActivity(options)
    .then(() => {
      dispatch({type: actionTypes.DELETED_DRAFTS_ACTIVITY});
      toast?.current.show({
        severity: 'success',
        summary: constants.DELETE_ACTIVITY_SUCCESSFUL,
        detail: '',
      });
    })
    .catch(() => {
      dispatch({type: actionTypes.DELETING_DRAFTS_ACTIVITY_FAILED});
      toast?.current.show({
        severity: 'warn',
        summary: constants.SOMETHING_WENT_WRONG,
        detail: '',
      });
    });
};

export const cleanUpDraftDashboard = () => {
  return {
    type: actionTypes.CLEAN_UP_DRAFT_DASHBOARD,
  };
};
