import {useEffect, useState} from 'react';
import DOMPurify from 'dompurify';
import {MODE_TYPES} from '../../../utils/constants';
import {TextEditor} from '../../../ui/inputs';

interface ICommunicateNotes {
  activityInfo: any;
  originalNotes: string;
  modeType: number;
  activityRef: any;
  isDraftView: boolean;
  updateActivityInfoState: (val: any) => void;
}

function CommunicateNotes({
  activityInfo,
  originalNotes = '',
  modeType,
  activityRef,
  isDraftView,
  updateActivityInfoState,
}: ICommunicateNotes) {
  const [notesText, setNotesText] = useState(
    activityInfo?.customerTaskActivity?.notes?.replaceAll('\n', '<br>'),
  );
  const onHandleChange = (value: string) => {
    let tempActivityInfo = {...activityRef.current};
    tempActivityInfo = {
      ...tempActivityInfo,
      customerTaskActivity: {
        ...tempActivityInfo.customerTaskActivity,
        notes: value,
      },
    };
    updateActivityInfoState(tempActivityInfo);
  };

  useEffect(() => {
    setNotesText(
      activityInfo?.customerTaskActivity?.notes?.replaceAll('\n', '<br>'),
    );
  }, [activityInfo]);

  return (
    <div>
      {originalNotes && modeType === MODE_TYPES.enquiry && !isDraftView ? (
        <div className="mt-3 border-1">
          <div className="mt-2 p-3 h-480-px">
            <div
              id="mailContent"
              // No fix required as we are using DOMPurify lib
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  activityInfo?.customerTaskActivity?.notes?.replace(
                    /href/g,
                    "target='_blank' href",
                  ),
                ),
              }}
            />
          </div>
        </div>
      ) : (
        <TextEditor
          className="h-450-px"
          textEditorValue={notesText}
          onTextEditorValueChange={e => {
            setNotesText(e.htmlValue);
            onHandleChange(e.textValue);
          }}
        />
      )}
    </div>
  );
}
export default CommunicateNotes;
