import {gridModeTypes} from '../constants/common';
import data from '../lib/data';
import {isEmpty} from '../lib/utils';
import {downloadBlobFilePostApi} from './download';

export const fetchReportingGroupLogs = ({
  userKey,
  groupKey,
  settingKey,
  sortQuery,
  globalFilterQuery,
  pageSize,
  skip,
  sqlQuery,
}) => {
  let endpoint = `v4/api/accountoverview/for/reportinggrouplogs/${groupKey}?`;
  const sortJson =
    sortQuery?.length === 0
      ? null
      : {
          ViewSetting: {
            SortSetting: sortQuery.map((item, index) => {
              return {
                SortIndex: index + 1,
                SortCol: item.field,
                SortOrder: item.sort,
              };
            }),
          },
        };

  const payload = {
    countAll: true,
    sortJson: sortJson ? JSON.stringify(sortJson) : null,
    settingKey,
    userKey,
    getCount: true,
    fetchDays: 1500,
    isReturnCount: true,
    mode: gridModeTypes.ReportingGroupLog,
    filterJson: globalFilterQuery ? JSON.stringify(globalFilterQuery) : null,
    filterSql: sqlQuery,
    source: 'react',
  };
  if (!isEmpty(skip)) {
    endpoint += `&%24skip=${skip}`;
  }

  if (!isEmpty(pageSize)) {
    endpoint += `&%24top=${pageSize}`;
  }

  return data.post(endpoint, payload);
};

export const exportReportingGroupLogs = ({
  userKey,
  groupKey,
  settingKey,
  sortQuery,
  globalFilterQuery,
  sqlQuery,
}) => {
  const sortJson =
    sortQuery?.length === 0
      ? null
      : {
          ViewSetting: {
            SortSetting: sortQuery.map((item, index) => {
              return {
                SortIndex: index + 1,
                SortCol: item.field,
                SortOrder: item.sort,
              };
            }),
          },
        };

  const options = {
    countAll: true,
    sortJson: sortJson ? JSON.stringify(sortJson) : null,
    mode: 'ReportingGroupLog',
    settingKey,
    userKey,
    groupKey,
    filterJson: globalFilterQuery ? JSON.stringify(globalFilterQuery) : null,
    filterSql: sqlQuery,
    source: 'react',
  };

  const endpoint = `v4/api/export/ReportingGroupLog?`;

  return downloadBlobFilePostApi(endpoint, options);
};
