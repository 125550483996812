import {Dialog, Snackbar} from '../../../ui/feedback';
import {RenderReportsContent} from '../../messages/communication/RenderReportsContent';
import {constants} from '../../../constants/common';
import {Typography} from '../../../ui/displays';

function RenderDialogAndSnackbarForReports({
  snackbarObj,
  handleOnSnackbarClose,
  showReportsPopup,
  setShowReportsPopup,
  selectedRows,
  updateSnackbarObj,
  showSelectRecordError,
  setShowSelectRecordError,
  keyName,
  reportType,
}: {
  snackbarObj: any;
  handleOnSnackbarClose: any;
  showReportsPopup: any;
  setShowReportsPopup: any;
  selectedRows: any;
  updateSnackbarObj: any;
  showSelectRecordError: any;
  setShowSelectRecordError: any;
  keyName: string;
  reportType: string;
}) {
  return (
    <>
      <Snackbar
        open={snackbarObj.open}
        onClose={handleOnSnackbarClose}
        message={snackbarObj.message}
        title={snackbarObj.title}
        type={snackbarObj.type}
      />
      <Dialog
        title={constants.REPORTS}
        open={showReportsPopup}
        onClose={() => setShowReportsPopup(false)}
        maxWidth="sm"
        fullWidth
      >
        <RenderReportsContent
          reportType={reportType}
          onClose={() => setShowReportsPopup(false)}
          selectedRows={selectedRows}
          updateSnackbarObj={updateSnackbarObj}
          keyName={keyName}
        />
      </Dialog>
      <Dialog
        open={showSelectRecordError}
        title={constants.PLEASE_SELECT_A_RECORD}
        onClose={() => setShowSelectRecordError(false)}
        maxWidth="xs"
        fullWidth
      >
        <Typography variant="body2">
          {constants.A_ROW_MUST_BE_SELECTED_TO_USE_THIS_OPTION}
        </Typography>
      </Dialog>
    </>
  );
}

export default RenderDialogAndSnackbarForReports;
