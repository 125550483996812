import {GridActionsCellItem} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import Load from '@mui/icons-material/Refresh';
import Columns from '@mui/icons-material/ViewColumn';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import {Checkbox} from '../../../ui/inputs';

export const AlertsColumns = ({
  handleEditClick,
  columnAlignments,
  handleLoadClick,
  handleColumnsClick,
  handleRowSave,
  handleDeleteClick,
  handleProcessClick,
  handleScheduleClick,
  onGridColumnValueChanged,
}) => {
  const sortable = false;
  return [
    {
      field: 'edit',
      type: 'actions',
      headerName: 'Edit',
      width: 100,
      cellClassName: 'actions',
      getActions: ({row}) => {
        return row.isSystem || row.isReactView === 0
          ? []
          : [
              <GridActionsCellItem
                icon={<EditIcon color="action" />}
                label="Edit"
                title="Edit"
                className="textPrimary"
                onClick={() => handleEditClick(row)}
                color="inherit"
              />,
            ];
      },
    },
    {
      field: 'load',
      headerName: 'Load',
      align: columnAlignments.center,
      headerAlign: columnAlignments.center,
      width: 100,
      getActions: ({row}) => {
        return [
          <GridActionsCellItem
            icon={<Load color="action" style={{fontSize: 20}} />}
            label="Load"
            title="Load"
            className="textPrimary"
            onClick={() => handleLoadClick(row)}
            color="inherit"
          />,
        ];
      },
      type: 'actions',
    },
    {
      field: 'columns',
      headerName: 'Columns',
      align: columnAlignments.center,
      headerAlign: columnAlignments.center,
      width: 100,
      getActions: ({row}) => {
        return row.isSystem || row.isReactView === 0
          ? []
          : [
              <GridActionsCellItem
                icon={<Columns color="action" style={{fontSize: 20}} />}
                title="Columns"
                label="Columns"
                className="textPrimary"
                onClick={() => handleColumnsClick(row)}
                color="inherit"
                settingID
              />,
            ];
      },

      type: 'actions',
    },
    {
      field: 'save',
      headerName: 'Save',
      align: columnAlignments.center,
      headerAlign: columnAlignments.center,
      width: 100,
      getActions: ({row}) => {
        return row.isSystem || row.isReactView === 0
          ? []
          : [
              <GridActionsCellItem
                icon={<SaveIcon color="primary" style={{fontSize: 20}} />}
                title="Save"
                label="Save"
                className="textPrimary"
                onClick={() => handleRowSave(row)}
                color="inherit"
              />,
            ];
      },
      type: 'actions',
    },
    {
      field: 'delete',
      headerName: 'Delete',
      align: columnAlignments.center,
      headerAlign: columnAlignments.center,
      width: 100,
      getActions: ({row}) => {
        return row.isSystem || row.isReactView === 0
          ? []
          : [
              <GridActionsCellItem
                icon={<DeleteIcon color="warning" style={{fontSize: 20}} />}
                label="Delete"
                title="Delete"
                className="textPrimary"
                onClick={() =>
                  handleDeleteClick(row.settingsKey, row.settingId)
                }
                color="inherit"
              />,
            ];
      },
      type: 'actions',
    },
    {
      field: 'schedule',
      headerName: 'Schedule',
      align: columnAlignments.center,
      headerAlign: columnAlignments.center,
      width: 100,
      getActions: ({row}) => {
        return row.isSystem || row.isReactView === 0
          ? []
          : [
              <GridActionsCellItem
                icon={
                  <CalendarTodayOutlinedIcon
                    color="action"
                    style={{fontSize: 20}}
                  />
                }
                label="Schedule"
                title="Schedule"
                className="textPrimary"
                onClick={() => {
                  handleScheduleClick(row);
                }}
                color="inherit"
              />,
            ];
      },
      type: 'actions',
    },
    {
      field: 'process',
      headerName: 'Process',
      align: columnAlignments.center,
      headerAlign: columnAlignments.center,
      width: 100,
      getActions: ({row}) => {
        return row.isSystem
          ? []
          : [
              <GridActionsCellItem
                icon={
                  <ErrorOutlineOutlinedIcon
                    color="info"
                    style={{fontSize: 20}}
                  />
                }
                label="Process"
                title="Process"
                className="textPrimary"
                onClick={() => {
                  handleProcessClick(row.settingsKey);
                }}
                color="inherit"
              />,
            ];
      },
      type: 'actions',
    },
    {
      field: 'settingId',
      headerName: 'Alert Name',
      align: columnAlignments.left,
      headerAlign: columnAlignments.left,
      sortable,
      width: 180,
    },
    {
      field: 'alertDesc',
      headerName: 'Description',
      align: columnAlignments.left,
      headerAlign: columnAlignments.left,
      sortable,
      width: 180,
    },
    {
      field: 'alertPriority',
      headerName: 'Priority',
      align: columnAlignments.center,
      headerAlign: columnAlignments.center,
      sortable,
      width: 180,
      type: 'number',
    },
    {
      field: 'sortOrder',
      headerName: 'Sort Order',
      align: columnAlignments.center,
      headerAlign: columnAlignments.center,
      width: 100,
      type: 'number',
      sortable,
    },

    {
      field: 'show',
      headerName: 'Show',
      align: columnAlignments.center,
      headerAlign: columnAlignments.center,
      width: 100,
      renderCell: ({row}) => {
        return (
          <Checkbox
            disabled
            checked={row.show}
            onChange={e =>
              onGridColumnValueChanged(
                'show',
                e.target.checked,
                row.settingsKey,
              )
            }
          />
        );
      },

      sortable,
      type: 'boolean',
    },
  ];
};
