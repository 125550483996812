import {
  AiOutlineFilePdf,
  AiOutlineFileImage,
  AiOutlineFileWord,
  AiOutlineFileText,
  AiOutlineFileZip,
} from 'react-icons/ai';
import {RiFileExcel2Line} from 'react-icons/ri';
import {getFileExtensionByFileName} from '../../../../utils/fileUtils';

const FileIconByType = ({fileType, size = 15, isListView = true, fileUrl}) => {
  switch (fileType) {
    case 'jpg':
    case 'jpeg':
    case 'png':
      return isListView ? (
        <AiOutlineFileImage size={size} />
      ) : (
        <img height="60" src={fileUrl} alt="" />
      );

    case 'pdf':
      return <AiOutlineFilePdf size={size} />;

    case 'xls':
    case 'xlsx':
    case 'csv':
      return <RiFileExcel2Line size={size} />;
    case 'doc':
    case 'docx':
      return <AiOutlineFileWord size={size} />;

    case 'txt':
      return <AiOutlineFileText size={size} />;

    case 'zip':
      return <AiOutlineFileZip size={size} />;

    default:
      return <AiOutlineFileImage size={size} />;
  }
};

export const FileItem = ({name, className, size, isListView, fileUrl}) => {
  const fileType = getFileExtensionByFileName(name).toLowerCase();
  return (
    <div className={`${className} ${!isListView && 'align-items-center'}`}>
      <FileIconByType
        fileType={fileType}
        size={size}
        isListView={isListView}
        fileUrl={fileUrl}
      />
      <span
        title={name}
        className={`align-self-center ${
          !isListView && 'm-2 text-center overflow-ellipse'
        }`}
      >
        {name}
      </span>
    </div>
  );
};
