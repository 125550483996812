import {constants, gridModeTypes} from '../../constants/common';
import {
  exportProjectedCashReceipts,
  fetchProjectedCashReceipts,
} from '../../services/projectedCashReceipts';
import {downloadExcel} from '../../utils/fileUtils';
import {actionTypes} from '../constants/actionTypes';

export const getProjectedCashReceipts = options => (dispatch, getState) => {
  const toast = getState().generalReducer?.toastRef;
  const projectedCashReceiptsState = getState().projectedCashReceiptsReducer;
  const {
    userKey,
    groupKey,
    settingKey,
    sortQuery,
    filterQuery,
    pageSize,
    skip,
    sqlQuery,
  } = options;
  const filterQueryVal =
    settingKey ===
    projectedCashReceiptsState?.projectedCashReceiptsViewSettings?.settingsKey
      ? filterQuery
      : '';

  dispatch({type: actionTypes.FETCHING_PROJECTED_CASH_RECEIPTS});
  fetchProjectedCashReceipts({
    projectedCashReceiptsState,
    userKey,
    groupKey,
    settingKey,
    sortQuery,
    globalFilterQuery: filterQueryVal,
    pageSize,
    skip,
    sqlQuery,
  })
    .then(res => {
      dispatch({
        type: actionTypes.FETCHED_PROJECTED_CASH_RECEIPTS,
        projectedCashReceiptsData: res.data.value,
        projectedCashReceiptsCount: res.data.count,
        projectedCashReceiptsNextLink: res.data.nextLink,
        totalBalanceHC: res.data.totalBalanceHC,
        totalPredicted: res.data.totalPredicted,
      });
    })
    .catch(() => {
      toast?.current.show({
        severity: 'warn',
        summary: constants.SOMETHING_WENT_WRONG,
        detail: '',
      });
      dispatch({type: actionTypes.FETCHING_PROJECTED_CASH_RECEIPTS_FAILED});
    });
};

export const updateProjectedCashReceiptsViewSettingObject = ({
  settingId,
  viewSettings,
}) => {
  return {
    type: actionTypes.SET_PROJECTED_CASH_RECEIPTS_VIEW_SETTING_OBJECT,
    payload: {settingId, viewSettings},
  };
};

export const projectedCashReceiptsExportToExcel =
  ({
    userKey,
    groupKey,
    settingKey,
    sortQuery,
    filterQuery,
    sqlQuery,
    skip,
    pageSize,
  }) =>
  (dispatch, getState) => {
    const toast = getState().generalReducer?.toastRef;
    const projectedCashReceiptsState = getState().projectedCashReceiptsReducer;
    const filterQueryVal =
      settingKey ===
      projectedCashReceiptsState?.projectedCashReceiptsViewSettings?.settingsKey
        ? filterQuery
        : '';

    dispatch({type: actionTypes.EXPORTING_PROJECTED_CASH_RECEIPTS_TO_EXCEL});
    exportProjectedCashReceipts({
      userKey,
      groupKey,
      settingKey,
      sortQuery,
      globalFilterQuery: filterQueryVal,
      sqlQuery,
      skip,
      pageSize,
    })
      .then(res => {
        downloadExcel(
          res.data,
          gridModeTypes.ProjectedCashReceipts,
          toast,
          constants.PROJECTED_CASH_RECEIPTS_EXPORTED_TO_EXCEL,
        );
        dispatch({type: actionTypes.EXPORTED_PROJECTED_CASH_RECEIPTS_TO_EXCEL});
      })
      .catch(() => {
        dispatch({
          type: actionTypes.EXPORTING_PROJECTED_CASH_RECEIPTS_TO_EXCEL_FAILED,
        });
      });
  };

export const cleanUpProjectedCashReceipts = () => {
  return {
    type: actionTypes.CLEAN_UP_PROJECTED_CASH_RECEIPTS,
  };
};
