import {useEffect, useState} from 'react';
import CustomDataTable from '../common/table/CustomDataTable';
import {constants} from '../../constants/common';
import {paymentInfoColumns} from '../common/table/columns/paymentInfoColumns';
import AddEditPaymentMethodTemplate from './AddEditPaymentMethodTemplate';
import {DEFAULT_PAYMENT_METHOD} from '../../utils/constants';
import {buildPage} from '../../utils/pagingUtils';
import {Button} from '../../ui/inputs';
import {Dialog} from '../../ui';

function PaymentInformation({
  paymentMethods,
  onReload = () => {},
  loading,
  handleAddEdit = () => {},
  handleDeletePaymentMethod = () => {},
  showAddEditPaymentMethodForm,
  setShowAddEditPaymentMethodForm = () => {},
  deletingPaymentMethod,
  showDeletedPaymentMethodsDialog,
  setShowDeletedPaymentMethodsDialog = () => {},
  onPaymentInfoClick = () => {},
  isRequireFieldErrorMessage,
  setIsRequireFieldErrorMessage = () => {},
  paymentMethodNotFoundError,
  paymentMethodNotFoundErrorMessage,
  onResetNoPaymentMethodFoundError = () => {},
  paymentInformationLoadedPage,
  paymentInformationCurrentPage,
  handleUpdateCurrentPage = () => {},
  handleUpdateLoadedPage = () => {},
  paymentMethodsObj,
  setPaymentMethodObj = () => {},
}) {
  const [isEdit, setIsEdit] = useState(false);
  const [isDefaultMethod, setIsDefaultMethod] = useState(false);
  const [paymentMethodForEdit, setPaymentMethodForEdit] = useState();
  const [paymentMethod, setPaymentMethod] = useState('');
  const [paymentInfoSkip, setPaymentInfoSkip] = useState(0);
  const [paymentInfoPageSize, setPaymentInfoPageSize] = useState(10);

  const onPaymentMethodChange = ({target: {value}}) => {
    setIsRequireFieldErrorMessage(false);
    setPaymentMethod(value);
  };

  const onEditClick = rowData => {
    if (rowData.defaultMethod === DEFAULT_PAYMENT_METHOD) {
      setIsDefaultMethod(true);
    } else {
      setIsDefaultMethod(false);
    }
    setPaymentMethod(rowData.pmtMethodID);
    setPaymentMethodForEdit(rowData);
    setIsEdit(true);
    setShowAddEditPaymentMethodForm(true);
  };
  const onAddNewRecordClick = () => {
    setIsEdit(false);
    setPaymentMethod('');
    setIsDefaultMethod(false);
    setShowAddEditPaymentMethodForm(true);
    setPaymentMethodForEdit({});
  };

  const onAddEditPaymentMethodClick = () => {
    handleAddEdit({
      isDefaultMethod,
      isEdit,
      paymentMethod,
      paymentMethodForEdit,
    });
  };

  const onEditOrAddClose = () => {
    setIsEdit(false);
    setShowAddEditPaymentMethodForm(false);
    setPaymentMethodForEdit({});
  };

  const onPaymentInfoLoadMore = pageToLoad => {
    const skip = pageToLoad * paymentInfoPageSize - paymentInfoPageSize;
    handleUpdateLoadedPage(pageToLoad);
    handleUpdateCurrentPage(pageToLoad);
    setPaymentInfoSkip(skip);
  };

  useEffect(() => {
    const resultArray = buildPage(
      paymentInformationLoadedPage,
      paymentInfoPageSize,
      paymentMethods,
    );
    setPaymentMethodObj(resultArray);
  }, [paymentInfoPageSize, paymentInfoSkip, paymentMethods]);

  const updatePageRowCount = pageSize => {
    setPaymentInfoPageSize(pageSize);
  };

  return (
    <div className="my-1">
      <Dialog
        open={showDeletedPaymentMethodsDialog}
        title={constants.INFO}
        onClose={() => setShowDeletedPaymentMethodsDialog(false)}
        maxWidth="xs"
        fullWidth
      >
        <div className="d-flex">
          <i className="pi pi-alert me-3" />{' '}
          {constants.PAYMENT_METHOD_DELETED_SUCCESSFULLY}
        </div>
      </Dialog>
      <Dialog
        open={paymentMethodNotFoundError}
        title={constants.INFO}
        onClose={onResetNoPaymentMethodFoundError}
        maxWidth="xs"
        fullWidth
      >
        <div className="d-flex">
          <i className="pi pi-alert me-3" /> {paymentMethodNotFoundErrorMessage}
        </div>
      </Dialog>
      <div className="card p-2">
        <div className="d-flex justify-content-between w-100 margin-bottom-5-px">
          <Button
            variant="contained"
            startIcon={<i className="pi pi-plus" />}
            size="medium"
            onClick={() => {
              onAddNewRecordClick();
            }}
          >
            {constants.ADD_NEW_RECORD}
          </Button>
          <Button
            variant="contained"
            startIcon={<i className="pi pi-refresh" />}
            size="medium"
            onClick={onReload}
          >
            {constants.REFRESH}
          </Button>
        </div>
        {showAddEditPaymentMethodForm && (
          <AddEditPaymentMethodTemplate
            isEdit={isEdit}
            isDefaultMethod={isDefaultMethod}
            setIsDefaultMethod={setIsDefaultMethod}
            paymentMethodForEdit={paymentMethodForEdit}
            paymentMethod={paymentMethod}
            onPaymentMethodChange={onPaymentMethodChange}
            onAddEditPaymentMethodClick={onAddEditPaymentMethodClick}
            isRequireFieldErrorMessage={isRequireFieldErrorMessage}
            setIsRequireFieldErrorMessage={setIsRequireFieldErrorMessage}
            onEditOrAddClose={onEditOrAddClose}
          />
        )}

        <CustomDataTable
          columns={paymentInfoColumns({
            handleDeletePaymentMethod,
            onEditClick,
            deletingPaymentMethod,
            onPaymentInfoClick,
          })}
          header={false}
          selectionMode={false}
          selectionModeType="single"
          selection
          tableData={paymentMethodsObj}
          pageRowCount={paymentInfoPageSize}
          totalRecords={paymentMethods.length}
          loading={loading}
          setCurrentPage={handleUpdateCurrentPage}
          setLoadedPage={handleUpdateLoadedPage}
          loadedPage={paymentInformationLoadedPage}
          currentPage={paymentInformationCurrentPage}
          skip={paymentInfoSkip}
          onLoadMore={onPaymentInfoLoadMore}
          selectedRows={paymentMethodForEdit}
          updatePageRowCount={updatePageRowCount}
        />
      </div>
    </div>
  );
}
export default PaymentInformation;
