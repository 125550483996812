import {useState} from 'react';
import {constants} from '../../../../constants/common';
import {Dialog} from '../../../../ui';
import {Button} from '../../../../ui/inputs';
import TaggedInvoices from './TaggedInvoices';

export const ViewTaggedInvoices = ({values}: {values: any}) => {
  const [viewTaggedInvoices, setViewTaggedInvoices] = useState<boolean>(false);
  const {row} = values;

  return (
    <>
      {row.tagged && (
        <Button
          className="py-1 d-flex align-items-center justify-content-center h-30-px"
          onClick={() => {
            setViewTaggedInvoices(true);
          }}
          variant="text"
          startIcon={<span className="pi pi-tagged-invoice" />}
          size="small"
        />
      )}
      <Dialog
        open={viewTaggedInvoices}
        width={600}
        height={500}
        draggable
        resizable
        title={constants.TAGGED_INVOICES}
        onClose={() => {
          setViewTaggedInvoices(false);
        }}
      >
        <TaggedInvoices taskActKey={row.taskActKey} />
      </Dialog>
    </>
  );
};
