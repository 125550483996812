import {actionTypes} from '../constants/actionTypes';

const initialState = {
  draftDashboardData: [],
  draftDataViewSettings: null,
  fetchingDraftData: false,
  fetchingDraftDataFailed: false,
  fetchedDraftData: false,
  draftDataLoading: false,
  draftDataCount: 0,
  exportingToExcel: false,
  exportedToExcel: false,
  exportingToExcelFailed: false,
  draftSettingId: null,
};

// Define the draftReducer function
// eslint-disable-next-line @typescript-eslint/default-param-last
const draftReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_DRAFT_DASHBOARD:
      return {
        ...state,
        fetchingDraftData: true,
        draftDashboardData: [],
        fetchingDraftDataFailed: false,
        fetchedDraftData: false,
        draftDataLoading: true,
      };
    case actionTypes.FETCHED_DRAFT_DASHBOARD:
      return {
        ...state,
        fetchingDraftData: false,
        fetchingDraftDataFailed: false,
        fetchedDraftData: true,
        draftDashboardData: action.draftDashboardData,
        draftDataLoading: false,
        draftDataCount: action.draftDataCount,
      };
    case actionTypes.FETCHING_DRAFT_DASHBOARD_FAILED:
      return {
        ...state,
        fetchingDraftData: false,
        fetchingDraftDataFailed: true,
        fetchedDraftData: true,
        draftDataLoading: false,
        draftDashboardData: [],
        draftDataCount: 0,
      };

    case actionTypes.UPDATE_DRAFT_SETTING_KEY:
      return {
        ...state,
        draftSettingId: action.draftSettingId,
      };
    case actionTypes.SET_DRAFT_DASHBOARD_VIEW_SETTING_OBJECT:
      return {
        ...state,
        draftDataViewSettings: {
          ...action.payload.viewSettings,
          settingsKey: action.payload.settingId,
          sortSetting: null,
        },
        draftSettingId: action.payload.settingId,
      };

    case actionTypes.CLEAN_UP_DRAFT_DASHBOARD:
      return {...initialState};

    default:
      return state;
  }
};

export default draftReducer;
