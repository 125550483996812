import {ChangeEvent} from 'react';

import {
  FormControl,
  TextField as MuiTextField,
  TextFieldProps,
} from '@mui/material';

type Props = {
  /**
   * text field value
   */
  value?: string;
  /**
   * onChange event
   */
  fullWidth?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
} & TextFieldProps;

const TextField: React.FC<Props> = ({
  value,
  onChange,
  size = 'small',
  margin = 'normal',
  fullWidth = true,
  ...otherProps
}) => {
  return (
    <FormControl margin={margin} fullWidth={fullWidth}>
      <MuiTextField
        value={value || ''}
        onChange={onChange}
        autoComplete="off"
        size={size}
        {...otherProps}
      />
    </FormControl>
  );
};

export default TextField;
