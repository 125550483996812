import dayjs from 'dayjs';
import {filterOperators} from '../../../../lib/commonTableHelpers';
import {constants} from '../../../../constants/common';

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

export const taggedInvoiceColumns = [
  {
    field: 'documentNo',
    headerName: 'Invoice',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'documentDate',
    headerName: 'Invoice Date',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_TIME_FORMAT)
        : '',
    minWidth: 150,
    type: 'date',
  },
  {
    field: 'amount',
    headerName: 'Amount',
    sortable: true,
    valueFormatter: params => params?.value?.toFixed(2),
    minWidth: 120,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'amountHC',
    headerName: 'Balance Due',
    sortable: true,
    valueFormatter: params => params?.value?.toFixed(2),
    minWidth: 150,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'currID',
    headerName: 'Currency',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
];
