import {gridModeTypes} from '../constants/common';
import data from '../lib/data';
import {isEmpty} from '../lib/utils';
import {downloadBlobFilePostApi} from './download';

const fetchAllAccountsData = async ({
  allAccountsState,
  userKey,
  groupKey,
  SettingKey,
  sortQuery,
  globalFilterQuery,
  endpointURL,
  isAllAccountsTotal,
  pageSize,
  skip,
  files,
  isBulkUpload,
  sqlQuery,
}) => {
  const sortJson =
    sortQuery?.length === 0
      ? null
      : {
          ViewSetting: {
            SortSetting: sortQuery.map((item, index) => {
              return {
                SortIndex: index + 1,
                SortCol: item.field,
                SortOrder: item.sort,
              };
            }),
          },
        };

  let endpoint = endpointURL;

  if (!isEmpty(skip)) {
    endpoint += `&%24skip=${skip}`;
  }

  if (
    !allAccountsState.isAllAccountSelected &&
    !isEmpty(pageSize) &&
    !isBulkUpload
  ) {
    endpoint = `${endpoint}&%24top=${pageSize}`;
  }

  if (allAccountsState.isAllAccountSelected && pageSize > 10) {
    endpoint = `${endpoint}&%24top=${pageSize}`;
  }

  if (isAllAccountsTotal) {
    const options = {
      countAll: true,
      sortJson: sortJson ? JSON.stringify(sortJson) : null,
      getCount: true,
      fetchDays: 1500,
      isReturnCount: true,
      mode: gridModeTypes.AllAccountsTotal,
      SettingKey,
      userKey,
      groupKey,
      filterJson: globalFilterQuery ? JSON.stringify(globalFilterQuery) : null,
      filterSql: sqlQuery,
      source: 'react',
    };
    return data.post(endpoint, options);
  }

  const formData = new FormData();

  // Append non-file data to FormData
  formData.append('CountAll', 'true');
  formData.append('SortJson', sortJson ? JSON.stringify(sortJson) : '');
  formData.append('FetchDays', '1500');
  formData.append('IsReturnCount', 'true');
  formData.append('SettingKey', SettingKey);
  formData.append('Mode', gridModeTypes.AllAccounts);
  formData.append('source', 'react');
  formData.append('filterSql', sqlQuery);
  formData.append(
    'filterJson',
    globalFilterQuery ? JSON.stringify(globalFilterQuery) : null,
  );
  // Append file to FormData
  if (files?.length > 0) {
    files.forEach(file => {
      formData.append(`Files`, file);
    });
  }

  const headers = {'Content-Type': 'multipart/form-data'};

  return data.post(endpoint, formData, headers);
};

export const fetchAllAccounts = ({
  allAccountsState,
  userKey,
  groupKey,
  SettingKey,
  sortQuery,
  globalFilterQuery,
  pageSize,
  skip,
  files,
  isBulkUpload,
  sqlQuery,
}) => {
  const endpointURL = `v4/api/accountoverview/${groupKey}/${userKey}/allaccounts?`;
  return fetchAllAccountsData({
    allAccountsState,
    userKey,
    groupKey,
    SettingKey,
    sortQuery,
    globalFilterQuery,
    endpointURL,
    isAllAccountsTotal: false,
    pageSize,
    skip,
    files,
    isBulkUpload,
    sqlQuery,
  });
};

export const fetchAllAccountsTotals = ({
  allAccountsState,
  userKey,
  groupKey,
  SettingKey,
  sortQuery,
  globalFilterQuery,
  isAllAccountsTotal,
  isBulkUpload,
  sqlQuery,
}) => {
  const endpointURL = `v4/api/accountoverview/${groupKey}/${userKey}/allaccounts/totals?`;
  return fetchAllAccountsData({
    allAccountsState,
    userKey,
    groupKey,
    SettingKey,
    sortQuery,
    globalFilterQuery,
    endpointURL,
    isAllAccountsTotal,
    isBulkUpload,
    sqlQuery,
  });
};

export const exportAllAccounts = (
  allAccountsState,
  userKey,
  groupKey,
  SettingKey,
  sortQuery,
  globalFilterQuery,
  sqlQuery,
) => {
  const sortJson =
    sortQuery?.length === 0
      ? null
      : {
          ViewSetting: {
            SortSetting: sortQuery.map((item, index) => {
              return {
                SortIndex: index + 1,
                SortCol: item.field,
                SortOrder: item.sort,
              };
            }),
          },
        };

  const options = {
    countAll: true,
    sortJson: sortJson ? JSON.stringify(sortJson) : null,
    getCount: true,
    fetchDays: 1500,
    isReturnCount: true,
    mode: 'AllAccounts',
    SettingKey,
    userKey,
    groupKey,
    filterJson: globalFilterQuery ? JSON.stringify(globalFilterQuery) : null,
    filterSql: sqlQuery,
    source: 'react',
  };

  let endpoint = `v4/api/export/AllAccounts?`;
  if (!isEmpty(allAccountsState.allAccountsSkip)) {
    endpoint += `&%24skip=${allAccountsState.allAccountsSkip}`;
  }

  if (!isEmpty(allAccountsState.allAccountsPageSize)) {
    endpoint = `${endpoint}&%24top=${allAccountsState.allAccountsPageSize}`;
  }

  return downloadBlobFilePostApi(endpoint, options);
};
