import {constants} from '../../../constants/common';
import {Button} from '../../../ui/inputs';

export const activityViewContactsColumns = (makeACall = val => {}) => {
  const actionBody = rowData => (
    <Button variant="contained" size="small" onClick={() => makeACall(rowData)}>
      {constants.MAKE_A_CALL}
    </Button>
  );
  return [
    {field: 'action', renderCell: ({row}) => actionBody(row), type: 'actions'},
    {field: 'contactName', headerName: constants.CONTACT_NAME, minWidth: 180},
    {field: 'phone', headerName: constants.PHONE, minWidth: 120},
    {field: 'phoneExt', headerName: constants.PHONE_EXT, minWidth: 120},
    {field: 'emailAddr', headerName: constants.EMAIL_ADDR, minWidth: 180},
    {
      field: 'emailAddr2',
      headerName: `${constants.EMAIL_ADDR}2`,
      minWidth: 180,
    },
    {field: 'fax', headerName: constants.FAX, minWidth: 120},
    {field: 'faxExt', headerName: constants.FAX_EXT, minWidth: 120},
    {field: 'comment', headerName: constants.COMMENT, minWidth: 220},
    {field: 'title', headerName: constants.TITLE, minWidth: 120},
    {field: 'addrLine1', headerName: 'Addr Line1', minWidth: 250},
    {field: 'addrLine2', headerName: 'Addr Line2', minWidth: 250},
    {field: 'city', headerName: constants.CITY, minWidth: 120},
    {field: 'state', headerName: constants.STATE, minWidth: 120},
    {field: 'postalCode', headerName: constants.POSTAL_CODE, minWidth: 120},
    {field: 'country', headerName: constants.COUNTRY, minWidth: 120},
  ];
};
