import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchHistory} from '../../../store/actions/customerInfoActions';
import {labelValueForHistoryAndRolledUpHistory} from '../helper';
import HistoryAndRolledUpHistoryTemplate from './HistoryAndRolledUpHistoryTemplate';

function History() {
  const {history, fetchingHistory} = useSelector(
    store => store.customerInfoReducer.history,
  );

  const dispatch = useDispatch();
  const selectedCustomer = useSelector(
    store => store.generalReducer?.selectedCustomer,
  );
  const [firstColumnFieldsAndValues, setFirstColumnFieldsAndValues] = useState(
    [],
  );

  useEffect(() => {
    const result = labelValueForHistoryAndRolledUpHistory(history);
    setFirstColumnFieldsAndValues(result);
  }, [history]);

  useEffect(() => {
    dispatch(fetchHistory(selectedCustomer?.custKey));
  }, [selectedCustomer]);

  return (
    <HistoryAndRolledUpHistoryTemplate
      data={history}
      loading={fetchingHistory}
      firstColumnFieldsAndValues={firstColumnFieldsAndValues}
    />
  );
}

export default History;
