import React, {ErrorInfo} from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import ErrorFallback, {ErrorFallbackProps} from './ErrorFallback';
import {isLocalHost} from '../../lib/utils';

type ErrorBoundaryProps = {
  children: React.ReactNode;
  onReset?: () => void;
  FallbackUI?: React.FC<ErrorFallbackProps>;
} & ErrorFallbackProps;

const ErrorBoundaryWrapper: React.FC<ErrorBoundaryProps> = ({
  children,
  onReset,
  FallbackUI = ErrorFallback,
  ...restProps
}) => {
  const handleReset = () => {
    if (onReset) {
      onReset();
    } else {
      window.location.reload();
    }
  };

  const onHandleError = async (e: Error, info: ErrorInfo) => {
    // Call API to log error to Azure file storage
    if (!isLocalHost() && window.newrelic) {
      // Set custom attributes for error
      window.newrelic.setCustomAttribute('url', window.location.href);
      window.newrelic.setCustomAttribute('componentStack', info.componentStack);
      window.newrelic.setCustomAttribute('userAgent', navigator.userAgent);
      window.newrelic.setCustomAttribute('timestamp', new Date().toISOString());

      // Notice the error with additional context
      window.newrelic.noticeError(e, {
        extraInfo: info.componentStack,
        stackTrace: e.stack,
        message: e.message,
      });
    }
  };

  return (
    <ErrorBoundary
      // eslint-disable-next-line react/no-unstable-nested-components
      fallbackRender={props => <FallbackUI {...props} {...restProps} />}
      onReset={handleReset}
      onError={onHandleError}
    >
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryWrapper;
