import {useDispatch, useSelector} from 'react-redux';
import React, {useEffect, useState} from 'react';
import {
  DataGridProProps,
  GridColDef,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridLogicOperator,
  GridPaginationModel,
  GridSortModel,
} from '@mui/x-data-grid-pro';

import {Outlet, useLocation} from 'react-router-dom';
import {getUserData} from '../../../../../store/actions/userActions';
import {useQueryKeys} from '../../../../../hooks/useQueryKeys';
import {
  applyDatatableSettings,
  convertAndAppendExistingFilters,
  getGridColumnsSettings,
  hasFilterValue,
  updateColumnSettings,
} from '../../../../../lib/commonTableHelpers';
import {calculateColumnWidth} from '../../../../header/helper';
import {ViewSettings} from './ViewSettings';
import {
  DataGrid,
  FilterPopoverProvider,
  generateJsonFromSql,
  QueryReturnValue,
} from '../../../../../ui/data';
import {summaryColumns} from './summaryColumns';
import SummaryToolbar from './SummaryToolbar';
import {
  cleanUpPendingPaymentsSummary,
  getPendingPaymentSummary,
  getPendingPaymentsInvoicesDetails,
  getWebhookSetting,
  paymentsSummaryExportToExcel,
  updatePaymentSummaryDashboardViewObject,
  updatePaymentsSummaryAlertDescription,
} from '../../../../../store/actions/pendingPaymentSummaryActions';
import {DisplayInvoicePanel} from './DisplayInvoicePanel/DisplayInvoicePanel';
import {getPageQueryParams} from '../../../../messages/communicationHelper';
import {
  gridModeTypes,
  pendingPaymentSummaryTypes,
} from '../../../../../constants/common';
import {PAGE_SIZE} from '../../../../../utils/constants';
import {
  convertMUIToQueryBuilder,
  generateJsonAndSql,
  initialQuery,
} from '../../../../../ui/data/query-builder/queryHelper';
import {useComponentMountStatus} from '../../../../../hooks/useComponentMountStatus';
import {
  ColumnSetting,
  IViewSettings,
} from '../../../../common/view-settings/interface';
import ViewSettingsModal from '../../../../common/view-settings/ViewSettingsModal';
import {shouldResetFilterModel} from '../../../../../utils/filterUtils';

interface ISelectedPayment {
  id: string;
}
export const PendingPaymentsSummary = () => {
  const dispatch = useDispatch<any>();
  const [columns, setColumns] = useState<any[]>([]);
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({});
  const [selectedPayments, setSelectedPayments] = useState<ISelectedPayment[]>(
    [],
  );
  const [sortColumn, setSortColumn] = useState<GridSortModel>([]);
  const {groupKey} = getUserData();
  const {assignedUserKey: userKey} = useQueryKeys();
  const location = useLocation();
  const {mode, alertDesc} = getPageQueryParams(location);
  const modeType = mode ?? 'PendingPaymentSummary';

  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
    logicOperator: GridLogicOperator.And,
  });
  const [bestFit, setBestFit] = useState<boolean>(false);

  const [bestFitColumns, setBestFitColumns] = useState<GridColDef[]>([]);
  const [skip, setSkip] = useState(0);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: PAGE_SIZE,
  });
  const [showViewSettingsPopup, setShowViewSettingsPopup] =
    useState<boolean>(false);
  const [currentExpandedIds, setCurrentExpandedIds] = useState<string[]>([]);
  const {
    paymentSummarySettingId,
    paymentSummaryData,
    paymentSummaryLoading,
    paymentSummaryViewSettings,
    paymentSummaryCount,
    resendPaymentsStatus,
    reconcileStatus,
    updatedReconcileStatus,
    isRestricted,
  } = useSelector((store: any) => store.pendingPaymentSummaryReducer);

  const [customFilterSqlValue, setCustomFilterSqlValue] = useState<
    string | null
  >(null);
  const [customFilterJsonValue, setCustomFilterJsonValue] =
    useState<QueryReturnValue['json']>();

  const [settingsKey, setSettingsKey] = useState<string | null>(null);

  const isComponentMounted = useComponentMountStatus(
    cleanUpPendingPaymentsSummary,
  );

  const loadPaymentSummary = ({
    sortQuery = [],
    settingKey,
    pageSizeParam = PAGE_SIZE,
    skipParam = 0,
    filterQuery = undefined,
    sqlQuery = null,
  }: {
    sortQuery: any[];
    settingKey: string;
    pageSizeParam: number;
    skipParam: number;
    filterQuery: QueryReturnValue['json'] | undefined;
    sqlQuery: string | null;
  }) => {
    setSelectedPayments([]);
    const options = {
      userKey,
      groupKey,
      settingKey,
      sortQuery,
      skip: skipParam,
      pageSize: pageSizeParam,
      isRestricted: alertDesc != null ? isRestricted : false,
      mode: pendingPaymentSummaryTypes[modeType],
      filterQuery,
      sqlQuery,
    };
    dispatch(getPendingPaymentSummary(options));
    dispatch(getWebhookSetting(groupKey));
  };

  // Reset filter model
  const resetFilterModel = () => {
    setFilterModel({
      logicOperator: GridLogicOperator.And,
      items: [],
    });
  };

  // reset pagination
  const resetPagination = () => {
    setPaginationModel({
      pageSize: paginationModel.pageSize,
      page: 0,
    });
  };

  useEffect(() => {
    if (settingsKey) {
      loadPaymentSummary({
        settingKey: settingsKey,
        sortQuery: sortColumn,
        skipParam: 0,
        pageSizeParam: paginationModel.pageSize,
        filterQuery: customFilterJsonValue,
        sqlQuery: customFilterSqlValue,
      });
      resetFilterModel();
      resetPagination();
      setSelectedPayments([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsKey, isRestricted]);

  const handleGetPendingPaymentSummaryData = (
    filterQuery: QueryReturnValue['json'],
    sqlQuery: string | null,
  ) => {
    loadPaymentSummary({
      settingKey: paymentSummarySettingId,
      sortQuery: sortColumn,
      skipParam: 0,
      pageSizeParam: paginationModel.pageSize,
      filterQuery,
      sqlQuery,
    });
    resetPagination();
  };

  /** Use effect to update and set columns based on view settings response */
  useEffect(() => {
    if (
      paymentSummaryViewSettings &&
      paymentSummaryViewSettings.settingsKey &&
      isComponentMounted
    ) {
      setCurrentExpandedIds([]);
      applyDatatableSettings(
        paymentSummaryViewSettings,
        columns,
        summaryColumns,
        undefined,
        setColumns,
        setColumnVisibilityModel,
        setSortColumn,
      );

      const jsonData =
        paymentSummaryViewSettings?.filter?.length > 0
          ? generateJsonFromSql(paymentSummaryViewSettings?.filter)
          : initialQuery;
      setSettingsKey(paymentSummaryViewSettings?.settingsKey);
      setCustomFilterSqlValue(paymentSummaryViewSettings?.filter);
      setCustomFilterJsonValue(jsonData);
    }
  }, [paymentSummaryViewSettings?.settingsKey]);

  /** Use effect to update alert description value from query params */
  useEffect(() => {
    dispatch(updatePaymentsSummaryAlertDescription(alertDesc));
  }, [alertDesc]);

  /**
   * onPageChange is prop set for scheduled payments grid to handle pagination
   * @param args GridPaginationModel object which has page which starts from 0 and skip properties
   */
  const onPageChange = (args: GridPaginationModel) => {
    setCurrentExpandedIds([]);
    const pageSkip =
      args.pageSize !== paginationModel.pageSize
        ? 0
        : (args.page + 1) * args.pageSize - args.pageSize;
    setPaginationModel({
      pageSize: args.pageSize,
      page: args.pageSize !== paginationModel.pageSize ? 0 : args.page,
    });
    setSkip(pageSkip);
    loadPaymentSummary({
      settingKey: paymentSummarySettingId,
      sortQuery: sortColumn,
      filterQuery: customFilterJsonValue,
      sqlQuery: customFilterSqlValue,
      pageSizeParam: args.pageSize,
      skipParam: pageSkip,
    });
  };

  /**
   * onSortChange is prop set for scheduled payments grid to sorting
   * @param args GridPaginationModel array of object which field and sort properties
   */
  const onSortChange = (args: GridSortModel) => {
    setSortColumn(args);
    loadPaymentSummary({
      settingKey: paymentSummarySettingId,
      sortQuery: args,
      filterQuery: customFilterJsonValue,
      sqlQuery: customFilterSqlValue,
      pageSizeParam: paginationModel.pageSize,
      skipParam: skip,
    });
  };

  /**
   * onColumnRowSelect Update state on checkbox selection
   * @param newSelectionModel is selected row object
   */
  const onColumnRowSelect = (newSelectionModel: any) => {
    setSelectedPayments(newSelectionModel);
  };

  /** All Toolbar methods */
  /**
   * onFilterChange is prop set for scheduled payments grid to filter
   * @param args GridFilterModel array of object which field, value and operator properties
   */
  const onFilterChange = (args: GridFilterModel) => {
    const queryBuilderQuery = convertMUIToQueryBuilder(args);
    const valueToConvert = convertAndAppendExistingFilters(
      {...customFilterJsonValue},
      queryBuilderQuery,
      filterModel,
    );
    const {json, sql} = generateJsonAndSql(valueToConvert);

    setFilterModel(args);
    setCustomFilterSqlValue(json.rules.length > 0 ? sql : null);
    setCustomFilterJsonValue(json);
    if (hasFilterValue(args)) {
      handleGetPendingPaymentSummaryData(
        json,
        json.rules.length > 0 ? sql : null,
      );
    }
  };

  const onQueryFilterApply = (data: QueryReturnValue) => {
    // if filter is empty then reset filter model
    if (shouldResetFilterModel(data)) {
      resetFilterModel();
    }
    const {json, sql} = data;
    setCustomFilterSqlValue(sql);
    setCustomFilterJsonValue(json);
    handleGetPendingPaymentSummaryData(
      json,
      json.rules.length > 0 ? sql : null,
    );
  };

  /**
   * Download Excel report
   */
  const exportToExcel = () => {
    const options = {
      userKey,
      groupKey,
      settingKey: paymentSummarySettingId,
      sortQuery: sortColumn,
      isRestricted: alertDesc != null ? isRestricted : false,
      mode: pendingPaymentSummaryTypes[modeType],
      filterQuery: customFilterJsonValue,
      sqlQuery: customFilterSqlValue,
      rowCount: paginationModel.pageSize,
    };
    dispatch(
      // @ts-ignore
      paymentsSummaryExportToExcel(options),
    );
  };

  /**
   * On Refresh refetch list of scheduled payments
   */
  const onRefresh = () => {
    setCurrentExpandedIds([]);
    loadPaymentSummary({
      settingKey: paymentSummarySettingId,
      sortQuery: sortColumn,
      skipParam: skip,
      pageSizeParam: paginationModel.pageSize,
      filterQuery: customFilterJsonValue,
      sqlQuery: customFilterSqlValue,
    });
  };

  // When API send success response refresh data table
  useEffect(() => {
    if (resendPaymentsStatus === true || updatedReconcileStatus === true) {
      onRefresh();
    }
  }, [resendPaymentsStatus, reconcileStatus]);

  // Handling best-fit (Column resizing)
  const applyBestFit = () => {
    const bestFitColumnsTemp = columns.map(column => {
      const minWidth = calculateColumnWidth(column.field);
      return {
        ...column,
        minWidth,
      };
    });
    setBestFit(!bestFit);
    setBestFitColumns(bestFitColumnsTemp);
  };

  const getDetailPanelContent = React.useCallback<
    NonNullable<DataGridProProps['getDetailPanelContent']>
  >(({row}) => {
    return (
      <DisplayInvoicePanel
        paymentSummaryInvoicesData={row.summary ? row.summary : undefined}
      />
    );
  }, []);

  const handleDetailPanelExpandedRowIdsChange = React.useCallback(
    (newIds: any[]) => {
      if (
        newIds.length !== currentExpandedIds.length &&
        newIds.length > currentExpandedIds.length
      ) {
        const current = newIds[newIds.length - 1];
        dispatch(getPendingPaymentsInvoicesDetails(groupKey, current));
      }
      setCurrentExpandedIds(newIds);
    },
    [currentExpandedIds],
  );

  const updateTableOnColumnSettingsChange = (
    updatedColumnsList: ColumnSetting[],
    settingKey: string,
  ) => {
    if (settingKey === paymentSummaryViewSettings?.settingsKey) {
      updateColumnSettings(
        updatedColumnsList,
        columns,
        setColumns,
        setColumnVisibilityModel,
      );
    }
  };

  const onViewSettings = () => {
    setShowViewSettingsPopup(true);
  };

  const handleCloseViewSettingsPopup = () => {
    setShowViewSettingsPopup(false);
  };

  const {columnSettingJson, sortSettingJson} = getGridColumnsSettings(
    paymentSummaryViewSettings?.columnSetting,
    columnVisibilityModel,
    sortColumn,
  );

  return (
    <>
      <Outlet context={{onReload: onRefresh}} />
      <ViewSettingsModal
        viewName="PendingPayments"
        mode={gridModeTypes.PendingPaymentSummary}
        updateTableOnColumnSettingsChange={updateTableOnColumnSettingsChange}
        handleCloseViewSettingsPopup={handleCloseViewSettingsPopup}
        customFilterSqlValue={customFilterSqlValue || ''}
        sortSettingJson={JSON.stringify(sortSettingJson) || ''}
        columnSettingJson={JSON.stringify(columnSettingJson) || ''}
        loadViewSetting={(row: IViewSettings) => {
          dispatch(
            updatePaymentSummaryDashboardViewObject({
              settingId: row.settingsKey,
              viewSettings: row,
            }),
          );
        }}
        showViewSettingsPopup={showViewSettingsPopup}
      />
      <ViewSettings
        selectedPayments={selectedPayments}
        mode={pendingPaymentSummaryTypes[modeType]}
      />
      <FilterPopoverProvider>
        <DataGrid
          disableVirtualization
          columns={bestFit ? bestFitColumns : columns}
          rows={paymentSummaryData}
          loading={paymentSummaryLoading}
          rowCount={paymentSummaryCount}
          checkboxSelection
          sortModel={sortColumn}
          onSortChange={onSortChange}
          onPageChange={onPageChange}
          onFilterChange={onFilterChange}
          filterModel={filterModel}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityChange={data => setColumnVisibilityModel(data)}
          rowsSelected={selectedPayments}
          onColumnRowSelect={onColumnRowSelect}
          CustomToolbar={SummaryToolbar}
          disableMultipleColumnsSorting
          customToolbarMethods={{
            exportToExcel,
            onRefresh,
            applyBestFit,
            onViewSettings,
          }}
          getRowId={row => row.pendPmtKey}
          headerFilters
          paginationModel={paginationModel}
          getDetailPanelContent={getDetailPanelContent}
          getDetailPanelHeight={() => 'auto'}
          detailPanelExpandedRowIds={currentExpandedIds}
          onDetailPanelExpandedRowIdsChange={
            handleDetailPanelExpandedRowIdsChange
          }
          showCustomFilters
          customFilterSqlValue={customFilterSqlValue}
          onQueryFilterApply={data => onQueryFilterApply(data)}
        />
      </FilterPopoverProvider>
    </>
  );
};
