import {useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useQueryKeys} from '../../hooks/useQueryKeys';
import {redirectToActivityOrAccountOverView} from '../messages/communication/helper';
import {constants} from '../../constants/common';
import Menu from '../../ui/navigation/menu/Menu';

/** File has all the custom components for communication grid and handles navigation */

// Component to show on Customer Name column which displays Link with context menu which has
// two options Account Details and Account details/New Activity
export const CustomerMenu = ({values}: {values: any}) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const {userKey} = useQueryKeys();

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleMenuItemClick = (value: any, tab: string) => {
    handleMenuClose();
    redirectToActivityOrAccountOverView(
      tab,
      value,
      navigate,
      location,
      userKey,
    );
  };
  const menuItems = [
    {
      label: constants.ACCOUNT_DETAILS,
      onClick: () => handleMenuItemClick(values.row, 'accountDetails'),
    },
    {
      label: constants.ACCOUNT_DETAILS_OR_NEW_ACTIVITY,
      onClick: () =>
        handleMenuItemClick(values.row, 'accountDetailsAndNewActivity'),
    },
  ];
  return (
    <>
      <span
        role="presentation"
        className="blueLink cursor-pointer"
        onClick={handleMenuOpen}
        title={values.row[values.field]}
      >
        {values.row[values.field]}
      </span>
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
        items={menuItems}
      />
    </>
  );
};
