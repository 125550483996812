import {useState} from 'react';
import AllAccounts from './all-accounts/AllAccounts';
import AllAccountsTotals from './all-accounts-totals/AllAccountsTotals';
import {Button} from '../../ui/inputs';

const Accounts = () => {
  const [showTotalsGrid, setShowTotalsGrid] = useState<boolean>(false);
  return (
    <>
      {' '}
      <AllAccounts showTotalsGrid={showTotalsGrid} />
      {!showTotalsGrid && (
        <Button onClick={() => setShowTotalsGrid(true)}>Show Totals</Button>
      )}
      {showTotalsGrid && <AllAccountsTotals />}
      {showTotalsGrid && (
        <Button onClick={() => setShowTotalsGrid(false)}>
          Back to Accounts
        </Button>
      )}
    </>
  );
};
export default Accounts;
