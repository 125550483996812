import {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {GridPaginationModel} from '@mui/x-data-grid';
import {constants} from '../../../constants/common';
import {getActivityDetailForInvoice} from '../../../store/actions/activityDetailActions';
import {activityDetailColumns} from '../../common/table/columns/activityDetailColumns';
import {hideDialog} from '../../../store/actions/generalActions';
import {DataGrid} from '../../../ui/data';
import {PAGE_SIZE} from '../../../utils/constants';

function ActivityDetailTemplate({
  docKey,
  userKey,
  showExistingActivity,
}: {
  docKey: string;
  userKey: string;
  showExistingActivity: (data: any) => void;
}) {
  const {activityDetail, activityDetailCount, activityDetailLoading} =
    useSelector((store: any) => store.activityDetailReducer);
  const [skip, setSkip] = useState<number>(0);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: PAGE_SIZE,
  });
  const dispatch = useDispatch<any>();

  const loadActivities = ({
    pageSizeParam,
    skipParam,
  }: {
    pageSizeParam: number;
    skipParam: number;
  }) => {
    dispatch(
      getActivityDetailForInvoice({
        docKey,
        userKey,
        pageSize: pageSizeParam,
        skip: skipParam,
      }),
    );
  };

  useEffect(() => {
    loadActivities({pageSizeParam: paginationModel.pageSize, skipParam: skip});
  }, []);

  const handleViewActivityDetails = (rowData?: any) => {
    dispatch(hideDialog());
    showExistingActivity(rowData);
  };

  const onPageChange = (args: GridPaginationModel) => {
    const pageSkip =
      args.pageSize !== paginationModel.pageSize
        ? 0
        : (args.page + 1) * args.pageSize - args.pageSize;
    setPaginationModel({
      pageSize: args.pageSize,
      page: args.pageSize !== paginationModel.pageSize ? 0 : args.page,
    });
    setSkip(pageSkip);
    loadActivities({
      pageSizeParam: args.pageSize,
      skipParam: pageSkip,
    });
  };

  const onRefresh = () => {
    loadActivities({pageSizeParam: paginationModel.pageSize, skipParam: skip});
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <span
          aria-hidden="true"
          className="px-1 py-2 d-flex align-items-center cursor-pointer"
          onClick={onRefresh}
        >
          <i className="pi pi-custom-refresh" />
          <span className="ps-3">{constants.REFRESH_LIST}</span>
        </span>
      </div>

      <div className="my-1">
        <DataGrid
          height={550}
          columns={activityDetailColumns(handleViewActivityDetails)}
          rows={activityDetail}
          loading={activityDetailLoading}
          rowCount={activityDetailCount}
          onPageChange={onPageChange}
          paginationModel={paginationModel}
          disableColumnMenu
          disableColumnFilter
          disableColumnResize
          getRowId={row => row.uniqueKey}
        />
      </div>
    </>
  );
}
export default ActivityDetailTemplate;
