import {Paper, Typography, Avatar, styled} from '@mui/material';

import PersonIcon from '@mui/icons-material/Person';

import {useEffect, useState} from 'react';
// TODO: fix image paths for typescript
// @ts-ignore
import {useLocation, useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import Logo from '../../images/icons/logo.png';
import {Button, TextField} from '../../../ui/inputs';
import {getProfileByToken, postLogin} from '../../../services/auth';
import {loginReturnUrl} from '../../../utils/constants';
import {constants} from '../../../constants/common';

interface IAuthorizeResponse {
  accessToken: any;
  accessTokenLifetime: number;
  code: string;
  error: any;
  errorDescription: any;
  identityToken: string;
  isError: boolean;
  redirectUri: string;
  scope: string;
}

interface IProfile {
  sessionId: any;
  groupKey: string;
  accountName: string;
  windowName: any;
  userId: string;
  userKey: number;
  sessionUserKey: number;
  sessionUserId: string;
  sessionUserName: string;
  isAdmin: number;
  sessionUserType: number;
  sessionOemName: string;
  sessionMenuVersion: string;
  sessionAppVersion: string;
  sessionSupportUserGuidID: string;
  hostName: any;
  sessionViewGroup: string;
  sessionNACMSupport: string;
  isReactLogin: boolean;
}

const MyContainer = styled('div')(() => ({
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  background: 'linear-gradient(to bottom, #0f3044, #163c4f)',
}));

const LogoContainer = styled('img')(() => ({
  width: '445px',
  marginBottom: '36px',
  marginLeft: '48px',
}));

const MyPaper = styled(Paper)(({theme}) => ({
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxWidth: '400px',
}));

const MyAvatar = styled(Avatar)(({theme}) => ({
  margin: theme.spacing(1),
  backgroundColor: theme.palette.primary.main,
  width: '60px',
  height: '60px',
}));

const MyForm = styled('form')(({theme}) => ({
  width: '100%',
  marginTop: theme.spacing(1),
}));

const MyButton = styled(Button)(({theme}) => ({
  margin: theme.spacing(3, 0, 2),
}));

// Your login attempt was not successful. Please try again.
export const Login = () => {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });
  const [formErrors, setFormErrors] = useState({
    username: '',
    password: '',
  });
  const [loading, setLoading] = useState(false);
  const {toastRef: toast} = useSelector((store: any) => store.generalReducer);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const isLoggedIn = Boolean(sessionStorage.getItem('profile'));
    if (isLoggedIn) {
      const {state} = location;
      if (state && state.from) {
        navigate(state.from); // Redirect to the previous location
      } else {
        navigate('/home'); // Redirect to home if no previous location is found
      }
    }
  }, [navigate, location]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    setFormData({...formData, [name]: value});
    setFormErrors({...formErrors, [name]: ''});
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newErrors = {...formErrors};

    if (!formData.username) {
      newErrors.username = 'Username is required';
    }

    if (!formData.password) {
      newErrors.password = 'Password is required';
    }

    if (formData.username && formData.username.length < 3) {
      newErrors.username = 'Username must be at least 3 characters';
    }

    if (formData.password && formData.password.length < 6) {
      newErrors.password = 'Password must be at least 6 characters';
    }

    setFormErrors(newErrors);

    if (!newErrors.username && !newErrors.password) {
      setLoading(true);
      try {
        const {data}: {data: IAuthorizeResponse} = await postLogin({
          username: formData.username,
          password: formData.password,
          returnUrl: loginReturnUrl,
        });
        if (data.identityToken) {
          window.sessionStorage.setItem('idToken', data.identityToken);
          const response = await getProfileByToken();
          const profile: IProfile = response.data;
          profile.isReactLogin = true;
          window.sessionStorage.setItem('profile', JSON.stringify(profile));
          navigate('/home');
        }
      } catch (error) {
        setLoading(false);
        toast?.current.show({
          severity: 'error',
          summary: '',
          detail: `${constants.LOGIN_ERROR}`,
        });
      }
    }
  };

  return (
    <MyContainer>
      <LogoContainer src={Logo} alt="Lockstep logo" />
      <MyPaper elevation={3}>
        <MyAvatar>
          <PersonIcon fontSize="large" />
        </MyAvatar>
        <Typography component="h1" variant="h5">
          Sign In
        </Typography>
        <MyForm noValidate onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            value={formData.username}
            onChange={handleInputChange}
            error={!!formErrors.username}
            helperText={formErrors.username}
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            value={formData.password}
            onChange={handleInputChange}
            autoComplete="current-password"
            error={!!formErrors.password}
            helperText={formErrors.password}
          />
          <MyButton
            loading={loading}
            disabled={loading}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            Sign In
          </MyButton>
        </MyForm>
      </MyPaper>
    </MyContainer>
  );
};
