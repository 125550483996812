import {ChangeEvent} from 'react';
import {
  RadioGroup as MuiRadioGroup,
  FormControlLabel,
  RadioGroupProps,
  Radio,
} from '@mui/material';

interface RadioItem {
  id: string;
  checked?: boolean;
  disabled?: boolean;
  value: number | string;
  label: number | string;
  hidden?: boolean;
}

interface Props extends RadioGroupProps {
  /**
   * List of Radio  items. Should have id, label, value, disabled and checked(react component)
   */
  items: RadioItem[];
  /**
   * onChange event
   */
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  /**
   * default value of radio group
   */
  defaultValue?: number | string;
  /**
   * value of selected radio button
   */
  value?: number | string;
  /**
   * style to display radio buttons in a row or column direction
   */
  row?: boolean;
}

/**
 * Radio group component to display radio buttons.
 * More information available here https://mui.com/material-ui/react-radio-button/
 */

const RadioGroup: React.FC<Props> = ({
  items,
  defaultValue,
  value,
  row,
  onChange,
  ...otherProps
}) => {
  return (
    <MuiRadioGroup
      row={row}
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      {...otherProps}
    >
      {items.map(item => (
        <FormControlLabel
          key={item.id}
          value={item.value}
          control={<Radio />}
          label={item.label}
          disabled={item.disabled}
          hidden={item.hidden}
        />
      ))}
    </MuiRadioGroup>
  );
};

export default RadioGroup;
