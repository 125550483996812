import {actionTypes} from '../constants/actionTypes';

const initialState = {
  scheduledPaymentsData: [],
  scheduledPaymentsViewSettings: null,
  fetchingSchedulePayments: false,
  fetchingSchedulePaymentsFailed: false,
  fetchedSchedulePayments: false,
  scheduledPaymentsLoading: false,
  scheduledPaymentsCount: 0,
  scheduledPaymentsNextLink: '',
  scheduledPaymentsSelectedRows: [],
  scheduledPaymentsInvoicesData: [],
  exportingToExcel: false,
  exportedToExcel: false,
  exportingToExcelFailed: false,
  scheduledPaymentsSettingId: null,
  approvingSchedulePayments: false,
  approvingSchedulePaymentsFailed: false,
  approvedSchedulePayments: false,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const scheduledPaymentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_SCHEDULED_PAYMENTS:
      return {
        ...state,
        fetchingSchedulePayments: true,
        fetchingSchedulePaymentsFailed: false,
        scheduledPaymentsLoading: true,
      };
    case actionTypes.FETCHED_SCHEDULED_PAYMENTS:
      return {
        ...state,
        fetchingSchedulePayments: false,
        fetchingSchedulePaymentsFailed: false,
        scheduledPaymentsAccounts: true,
        scheduledPaymentsData: action.scheduledPaymentsData,
        selectedSchedulePaymentsPageSize:
          action.selectedSchedulePaymentsPageSize || 10,
        scheduledPaymentsLoading: false,
        scheduledPaymentsCount: action.scheduledPaymentsCount,
        scheduledPaymentsNextLink: action.scheduledPaymentsNextLink,
        scheduledPaymentsSkip: action.scheduledPaymentsSkip,
      };
    case actionTypes.FETCHING_SCHEDULED_PAYMENTS_FAILED:
      return {
        ...state,
        fetchingSchedulePayments: false,
        fetchingSchedulePaymentsFailed: true,
        fetchedSchedulePayments: true,
        scheduledPaymentsLoading: false,
        scheduledPaymentsData: [],
        scheduledPaymentsCount: 0,
      };
    case actionTypes.EXPORTING_SCHEDULED_PAYMENTS_TO_EXCEL:
      return {
        ...state,
        scheduledPaymentsLoading: true,
        exportingToExcel: true,
        exportedToExcel: false,
        exportingToExcelFailed: false,
      };
    case actionTypes.EXPORTED_SCHEDULED_PAYMENTS_TO_EXCEL:
      return {
        ...state,
        scheduledPaymentsLoading: false,
        exportingToExcel: false,
        exportedToExcel: true,
        exportingToExcelFailed: false,
      };
    case actionTypes.EXPORTING_SCHEDULED_PAYMENTS_TO_EXCEL_FAILED:
      return {
        ...state,
        scheduledPaymentsLoading: false,
        exportingToExcel: false,
        exportedToExcel: false,
        exportingToExcelFailed: true,
      };
    case actionTypes.SET_SCHEDULED_PAYMENTS_VIEW_SETTING_OBJECT:
      return {
        ...state,
        scheduledPaymentsViewSettings: {
          ...action.payload.viewSettings,
          settingsKey: action.payload.settingId,
          sortSetting: null,
        },
        scheduledPaymentsSettingId: action.payload.settingId,
      };
    case actionTypes.APPROVING_SCHEDULED_PAYMENTS:
      return {
        ...state,
        approvingSchedulePayments: true,
        approvingSchedulePaymentsFailed: false,
        scheduledPaymentsLoading: true,
      };
    case actionTypes.APPROVED_SCHEDULED_PAYMENTS:
      return {
        ...state,
        approvingSchedulePayments: false,
        approvingSchedulePaymentsFailed: false,
        approvedSchedulePayments: true,
        scheduledPaymentsLoading: false,
      };
    case actionTypes.APPROVING_SCHEDULED_PAYMENTS_FAILED:
      return {
        ...state,
        approvingSchedulePayments: false,
        approvingSchedulePaymentsFailed: true,
        approvedSchedulePayments: false,
        scheduledPaymentsLoading: false,
      };
    case actionTypes.FETCHED_SCHEDULED_PAYMENTS_INVOICES_DETAILS: {
      const updatedPayments = state.scheduledPaymentsData.map(item =>
        item.pendPmtKey === action.pendPmtKey
          ? {...item, summary: action.scheduledPaymentsInvoicesData}
          : item,
      );
      return {
        ...state,
        scheduledPaymentsInvoicesData: action.scheduledPaymentsInvoicesData,
        scheduledPaymentsData: updatedPayments,
      };
    }

    case actionTypes.CLEAN_UP_SCHEDULED_PAYMENTS:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default scheduledPaymentsReducer;
