import {useState} from 'react';
import {checkIsTranTypePayment} from '../statement-of-account/helper';

export const useShowCustomerDocs = () => {
  const [showCustomerDocs, setShowCustomerDocs] = useState(false);
  const [openDocsProp, setOpenDocsProp] = useState({
    invoiceType: false,
    selectedInvoice: {},
  });

  const handleSetShowCustomerDocs = ({
    invcKey,
    tranId,
    tranType,
  }: {
    invcKey?: string;
    tranId?: string;
    tranType?: string;
  }) => {
    const invoiceType = !checkIsTranTypePayment(tranType);
    setOpenDocsProp({
      invoiceType,
      selectedInvoice: {invcKey, documentNo: tranId},
    });
    setShowCustomerDocs(true);
  };
  return {
    showCustomerDocs,
    setShowCustomerDocs,
    openDocsProp,
    handleSetShowCustomerDocs,
  };
};
