import {useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import Menu from '../../../ui/navigation/menu/Menu';
import {constants} from '../../../constants/common';
import {redirectToActivityOrAccountOverView, showCommunicate} from './helper';
import {MODE_TYPES} from '../../../utils/constants';
import {useQueryKeys} from '../../../hooks/useQueryKeys';
import {isEmpty} from '../../../lib/utils';
import {updateSelectedCustomer} from '../../../store/actions/generalActions';

/** File has all the custom components for communication grid and handles navigation */

// Component to show on Activity column which will display icon context menu which has
// two options New Activity and Account details/New Activity
export const ActivityMenu = ({values}: {values: any}) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const {userKey} = useQueryKeys();

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleMenuItemClick = (value: any, tab: string) => {
    handleMenuClose();
    redirectToActivityOrAccountOverView(
      tab,
      value,
      navigate,
      location,
      userKey,
    );
  };
  const menuItems = [
    {
      label: constants.NEW_ACTIVITY,
      onClick: () => handleMenuItemClick(values.row, 'newActivity'),
    },
    {
      label: constants.ACCOUNT_DETAILS_OR_NEW_ACTIVITY,
      onClick: () =>
        handleMenuItemClick(values.row, 'accountDetailsOrNewActivity'),
    },
  ];
  return (
    <>
      <span>
        <i
          role="presentation"
          className="pi pi-activity cursor-pointer"
          onClick={handleMenuOpen}
        />
      </span>
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
        items={menuItems}
      />
    </>
  );
};

// Component to show on Issue column which displays link which navigates to activity on enquiry mode
export const SingleLink = ({values}: {values: any}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<any>();
  return (
    <span
      role="presentation"
      className="blueLink cursor-pointer"
      title={values.row[values.field]}
      onClick={() => {
        dispatch(updateSelectedCustomer(values.row));
        showCommunicate(
          {
            ...values.row,
            tabName: constants.EMAIL_TAB,
            ModeType: MODE_TYPES.enquiry,
          },
          navigate,
          location,
        );
      }}
    >
      {isEmpty(values.row[values.field])
        ? constants.BLANK_ISSUE
        : values.row[values.field]}
    </span>
  );
};

// Component to Icons on columns where required and on click it navigates to activity
export const IconLink = ({
  values,
  tabName = constants.EMAIL_TAB,
  children,
}: {
  values: any;
  tabName: string;
  children: React.ReactNode;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <span
      role="presentation"
      className="blueLink cursor-pointer"
      onClick={() =>
        showCommunicate(
          {
            ...values.row,
            tabName,
            ModeType: MODE_TYPES.enquiry,
          },
          navigate,
          location,
        )
      }
    >
      {children}
    </span>
  );
};
