// useSessionStorage.js
import {useEffect, useState} from 'react';

const createStorageChangeEvent = () => new Event('storageChange');

export const useSessionStorage = key => {
  const [storedValue, setStoredValue] = useState(() => {
    const item = sessionStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  });

  useEffect(() => {
    const handleStorageChange = () => {
      setStoredValue(() => {
        const item = sessionStorage.getItem(key);
        return item ? JSON.parse(item) : null;
      });
    };

    window.addEventListener('storageChange', handleStorageChange);

    return () => {
      window.removeEventListener('storageChange', handleStorageChange);
    };
  }, [key]);

  const setSessionStorage = value => {
    sessionStorage.setItem(key, JSON.stringify(value));
    window.dispatchEvent(createStorageChangeEvent());
  };

  return [storedValue, setSessionStorage];
};
