import {useEffect, useState} from 'react';

import dayjs from 'dayjs';
import {TimeRangeValidationError} from '@mui/x-date-pickers-pro';
import {FieldChangeHandlerContext} from '@mui/x-date-pickers/internals';
import {AxiosResponse} from 'axios';
import utc from 'dayjs/plugin/utc';
import {Dialog} from '../../../ui';
import {Box, Stack} from '../../../ui/layouts';
import {constants} from '../../../constants/common';
import {
  Button,
  CheckboxLabel,
  DateTimePicker,
  MultiInputTimeRangeField,
  Select,
  TextField,
} from '../../../ui/inputs';
import {
  createOrUpdateAlertsSchedule,
  deleteAlertsSchedule,
  getAlertsSchedule,
} from '../../../services/alerts';
import {getUserData} from '../../../store/actions/userActions';
import {
  IScheduleTask,
  RunTimeValue,
} from '../../inquiries/by-user/scheduled-paymnets/schedulePaymentInterface';
import {
  convertDateTimeToString,
  convertDateTimeToTimeString,
  convertStringToTime,
  getMonthlyDropDown,
  hourlyDropDown,
  runDropDown,
  runDropDownValues,
} from '../../inquiries/by-user/scheduled-paymnets/helper';
import {Typography} from '../../../ui/displays';
import {IAlertSchedule, IDeleteSchedule, IGetAlertSchedule} from './interface';
import {getTimeZoneList} from '../../../services/application';
import {isEmpty} from '../../../lib/utils';
import Snackbar, {ISnackbarProps} from '../../../ui/feedback/snackbar/Snackbar';
import {useSessionStorage} from '../../../hooks/useSessionStorage';

dayjs.extend(utc);
interface ScheduleAlertsModalProps {
  showScheduleModal: boolean;
  currentViewSettingsKey: string;
  onEditAlertScheduleModalClose: () => void;
  setScheduleTask: React.Dispatch<React.SetStateAction<IScheduleTask>>;
  scheduleTask: IScheduleTask;
}
function ScheduleAlertsModal({
  showScheduleModal,
  onEditAlertScheduleModalClose,
  currentViewSettingsKey,
  setScheduleTask,
  scheduleTask,
}: ScheduleAlertsModalProps) {
  const groupKey = getUserData()?.groupKey;
  const [{userId}] = useSessionStorage('profile');
  const initialErrors = {timeZone: false, startDate: false};
  const [scheduleTaskErrors, setScheduleTaskErrors] = useState(initialErrors);
  const MonthlyDropdown = getMonthlyDropDown();
  const [timeRangeError, setTimeRangeError] =
    useState<FieldChangeHandlerContext<TimeRangeValidationError>>();
  const [timeZoneDropdown, setTimeZoneDropDown] = useState<
    {label: string; value: string}[]
  >([]);

  const [snackbarObj, setSnackbarObj] = useState<ISnackbarProps>({
    message: '',
    onClose: () => {},
    open: false,
    type: 'info',
    title: '',
  });

  const schedulePayload: IGetAlertSchedule = {
    AccountKey: groupKey,
    EntityKey: currentViewSettingsKey,
  };

  const updateSnackbarObj = ({type, title, open, message}: ISnackbarProps) => {
    setSnackbarObj({type, title, open, message, onClose: () => {}});
  };
  const handleCloseSnackbar = () => {
    setSnackbarObj(prev => ({...prev, open: false}));
  };

  useEffect(() => {
    const fetchTimeZones = async () => {
      const timeZoneResponse = await getTimeZoneList();
      const timeZones = timeZoneResponse.data?.map(
        (item: {DisplayName: string; Id: string}) => ({
          label: item.DisplayName,
          value: item.Id,
        }),
      );
      setTimeZoneDropDown(timeZones || []);
    };
    const fetchScheduleInfo = async () => {
      const scheduleInfoResponse: AxiosResponse<IScheduleTask> =
        await getAlertsSchedule(schedulePayload);
      const scheduleInfo: IScheduleTask = scheduleInfoResponse.data;
      setScheduleTask(scheduleInfo);
    };
    if (showScheduleModal) {
      fetchTimeZones();
      fetchScheduleInfo();
    }
  }, [showScheduleModal, currentViewSettingsKey]);

  const handleOnFrequencyTypeChange = (value: RunTimeValue) => {
    setScheduleTask({
      ...scheduleTask,
      frequencyType: value,
      runMon: value === 1,
      runTue: value === 1,
      runWed: value === 1,
      runThu: value === 1,
      runFri: value === 1,
      enabled: value === 1,
    });
  };
  const onScheduleDelete = async () => {
    const payload: IDeleteSchedule = {
      GroupKey: groupKey,
      SettingsKey: currentViewSettingsKey,
      IsAlertDelete: false,
    };
    await deleteAlertsSchedule(payload);
    onEditAlertScheduleModalClose();
  };

  const saveOrUpdateAlertSchedule = () => {
    const hasError =
      !scheduleTask.timeZone ||
      !scheduleTask.nextRun ||
      Boolean(timeRangeError?.validationError[0]) ||
      Boolean(timeRangeError?.validationError[1]);

    setScheduleTaskErrors({
      timeZone: !scheduleTask.timeZone,
      startDate: !scheduleTask.nextRun,
    });

    let payload: IAlertSchedule = {
      accountKey: groupKey,
      entity: constants.ALERT,
      entityKey: currentViewSettingsKey,
      userId,
      runMon: false,
      runTue: false,
      runWed: false,
      runThu: false,
      runFri: false,
      runSat: false,
      runSun: false,
      scheduleDescription: '',
      timeZone: '',
      nextRun: '',
      frequency: 1,
      frequencyType: 0,
      startTime: '00:00:00',
      endTime: '23:59:00',
      lastRun: '00:00:00',
      enabled: true,
    };

    if (isEmpty(scheduleTask?.scheduleKey)) {
      payload = {
        ...payload,
        scheduleDescription: scheduleTask?.scheduleDescription,
        frequencyType: scheduleTask?.frequencyType,
        frequency: scheduleTask?.frequency,
        startTime: scheduleTask?.startTime,
        nextRun: scheduleTask?.nextRun,
        timeZone: scheduleTask?.timeZone,
        enabled: scheduleTask?.enabled,
        runMon: scheduleTask?.runMon,
        runTue: scheduleTask?.runTue,
        runWed: scheduleTask?.runWed,
        runThu: scheduleTask?.runThu,
        runFri: scheduleTask?.runFri,
        runSat: scheduleTask?.runSat,
        runSun: scheduleTask?.runSun,
      };
    } else {
      payload = {
        ...payload,
        scheduleKey: scheduleTask?.scheduleKey,
        scheduleDescription: scheduleTask?.scheduleDescription,
        frequencyType: scheduleTask?.frequencyType,
        frequency: scheduleTask?.frequency,
        startTime: scheduleTask?.startTime,
        nextRun: scheduleTask?.nextRun,
        timeZone: scheduleTask?.timeZone,
        enabled: scheduleTask?.enabled,
        runMon: scheduleTask?.runMon,
        runTue: scheduleTask?.runTue,
        runWed: scheduleTask?.runWed,
        runThu: scheduleTask?.runThu,
        runFri: scheduleTask?.runFri,
        runSat: scheduleTask?.runSat,
        runSun: scheduleTask?.runSun,
      };
    }
    if (!hasError) {
      setScheduleTaskErrors(initialErrors);
      createOrUpdateAlertsSchedule(payload)
        .then(res => {
          setScheduleTask(scheduleTask);
          if (res) onEditAlertScheduleModalClose();
        })
        .catch(() => {
          updateSnackbarObj({
            message: constants.ALERT_UPDATE_MESSAGE,
            open: true,
            type: 'error',
            title: constants.ERROR,
          });
        });
    }
  };

  const handleSaveClick = () => {
    saveOrUpdateAlertSchedule();
  };

  const handleRowForEditAlertChange = (field: string, value: any) => {
    setScheduleTask({...scheduleTask, [field]: value});
  };

  return (
    <Box padding={2}>
      <Snackbar
        open={snackbarObj.open}
        onClose={handleCloseSnackbar}
        message={snackbarObj.message}
        title={snackbarObj.title}
        type={snackbarObj.type}
      />
      <Dialog
        title={constants.SCHEDULE_TASKS}
        open={showScheduleModal}
        onClose={onEditAlertScheduleModalClose}
        draggable
        maxWidth="sm"
        fullWidth
      >
        <Stack spacing={{xs: 1}} direction="column" useFlexGap flexWrap="wrap">
          <TextField
            label={constants.SCHEDULE_DESCRIPTION}
            margin="dense"
            value={scheduleTask?.scheduleDescription}
            onChange={e =>
              handleRowForEditAlertChange('scheduleDescription', e.target.value)
            }
          />
          <Select
            label={constants.TIME_ZONE}
            items={timeZoneDropdown}
            margin="dense"
            value={scheduleTask?.timeZone}
            onChange={e =>
              handleRowForEditAlertChange('timeZone', e.target.value as string)
            }
            error={scheduleTaskErrors.timeZone}
          />
          <DateTimePicker
            label={
              scheduleTask?.id ? constants.NEXT_RUN_DATE : constants.START_DATE
            }
            orientation="landscape"
            value={
              scheduleTask?.nextRun
                ? dayjs.utc(scheduleTask?.nextRun)
                : undefined
            }
            onChange={e => {
              if (e) {
                const dateTimeString = convertDateTimeToString(e);
                handleRowForEditAlertChange('nextRun', dateTimeString);
              }
            }}
            margin="dense"
          />
          <Box display="flex" gap={1}>
            <Box display="flex" gap={1} sx={{width: '450px'}}>
              <div className="w-100-px">
                <Select
                  label={constants.RUN}
                  items={runDropDown}
                  value={scheduleTask?.frequencyType}
                  onChange={e => {
                    handleOnFrequencyTypeChange(e.target.value as RunTimeValue);
                  }}
                />
              </div>
              {(scheduleTask?.frequencyType === runDropDownValues.HOURLY ||
                scheduleTask?.frequencyType === runDropDownValues.MONTHLY) && (
                <div className="w-150-px">
                  <Select
                    label=""
                    value={scheduleTask?.frequency}
                    onChange={e =>
                      handleRowForEditAlertChange(
                        'frequency',
                        e.target.value as number,
                      )
                    }
                    items={
                      scheduleTask?.frequencyType === runDropDownValues.MONTHLY
                        ? MonthlyDropdown
                        : hourlyDropDown
                    }
                  />
                </div>
              )}
            </Box>
            {scheduleTask?.frequencyType === runDropDownValues.HOURLY && (
              <MultiInputTimeRangeField
                startLabel="Between"
                endLabel="And"
                margin="normal"
                value={[
                  convertStringToTime(scheduleTask?.startTime),
                  convertStringToTime(scheduleTask?.endTime),
                ]}
                onChange={(time, error) => {
                  const newTime = convertDateTimeToTimeString(time);
                  setScheduleTask({
                    ...scheduleTask,
                    startTime: newTime.startTime,
                    endTime: newTime.endTime,
                  });
                  setTimeRangeError(error);
                }}
              />
            )}
          </Box>
          {scheduleTask?.frequencyType === runDropDownValues.DAILY && (
            <>
              <Typography variant="subtitle1">On These Days</Typography>
              <Box>
                <CheckboxLabel
                  checked={scheduleTask.runMon}
                  label={constants.MONDAY}
                  onChange={e =>
                    handleRowForEditAlertChange('runMon', e.target.checked)
                  }
                />
                <CheckboxLabel
                  checked={scheduleTask.runTue}
                  label={constants.TUESDAY}
                  onChange={e =>
                    handleRowForEditAlertChange('runTue', e.target.checked)
                  }
                />
                <CheckboxLabel
                  checked={scheduleTask.runWed}
                  label={constants.WEDNESDAY}
                  onChange={e =>
                    handleRowForEditAlertChange('runWed', e.target.checked)
                  }
                />
                <CheckboxLabel
                  checked={scheduleTask.runThu}
                  label={constants.THURSDAY}
                  onChange={e =>
                    handleRowForEditAlertChange('runThu', e.target.checked)
                  }
                />
                <CheckboxLabel
                  checked={scheduleTask.runFri}
                  label={constants.FRIDAY}
                  onChange={e =>
                    handleRowForEditAlertChange('runFri', e.target.checked)
                  }
                />
                <CheckboxLabel
                  checked={scheduleTask.runSat}
                  label={constants.SATURDAY}
                  onChange={e =>
                    handleRowForEditAlertChange('runSat', e.target.checked)
                  }
                />
                <CheckboxLabel
                  checked={scheduleTask.runSun}
                  label={constants.SUNDAY}
                  onChange={e =>
                    handleRowForEditAlertChange('runSun', e.target.checked)
                  }
                />
              </Box>
            </>
          )}
          {scheduleTask?.id && (
            <TextField
              label={constants.LAST_RUN_DATE}
              margin="dense"
              disabled
              InputLabelProps={{
                shrink: true,
              }}
              value={
                scheduleTask.lastRun
                  ? dayjs
                      .utc(scheduleTask?.lastRun)
                      .local()
                      .format('M/D/YYYY h:mm:ss A')
                  : '12:00 AM'
              }
            />
          )}
          {(scheduleTask?.frequencyType === runDropDownValues.DAILY ||
            scheduleTask?.id) && (
            <CheckboxLabel
              checked={scheduleTask.enabled}
              label={constants.ENABLED}
              onChange={e =>
                handleRowForEditAlertChange('enabled', e.target.checked)
              }
            />
          )}

          <Box display="flex" gap={1}>
            <Button variant="contained" onClick={handleSaveClick}>
              {constants.SAVE}
            </Button>
            <Button
              variant="contained"
              onClick={() => onEditAlertScheduleModalClose()}
            >
              {constants.CANCEL}
            </Button>
            {scheduleTask?.id && (
              <Button variant="contained" onClick={onScheduleDelete}>
                {constants.DELETE}
              </Button>
            )}
          </Box>
        </Stack>
      </Dialog>
    </Box>
  );
}

export default ScheduleAlertsModal;
