import {useEffect, useState} from 'react';
import {GridColDef} from '@mui/x-data-grid-pro';
import {useSelector} from 'react-redux';
import {DataGrid} from '../../../ui/data';
import AllAccountsToolbar from './AllAccountsTotalsToolbar';
import {applyBestFit} from '../../../lib/commonTableHelpers';
import {allAccountsTotalsColumns} from './column';
import {aggregateData} from './helper';
import {Box} from '../../../ui/layouts';

const AllAccountsTotals = () => {
  const [bestFit, setBestFit] = useState<boolean>(false);
  const [totalsData, setTotalsData] = useState<any>({});
  const [bestFitColumns, setBestFitColumns] = useState<GridColDef[]>([]);
  const {allAccountsTotalsLoading, allAccountsTotalsData} = useSelector(
    (store: any) => store.allAccountsReducer,
  );

  useEffect(() => {
    const aggregatedRow = aggregateData(
      allAccountsTotalsData,
      allAccountsTotalsColumns,
    );
    const dataWithAggregates = [...allAccountsTotalsData, aggregatedRow];
    setTotalsData(dataWithAggregates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allAccountsTotalsData]);

  const applyGridBestFit = () => {
    applyBestFit(
      allAccountsTotalsColumns,
      bestFit,
      setBestFit,
      setBestFitColumns,
    );
  };

  return (
    <Box marginTop={3}>
      <DataGrid
        height={650}
        columns={bestFit ? bestFitColumns : allAccountsTotalsColumns}
        rows={totalsData}
        loading={allAccountsTotalsLoading}
        checkboxSelection={false}
        hidePagination
        CustomToolbar={AllAccountsToolbar}
        customToolbarMethods={{
          applyGridBestFit,
        }}
        getRowId={row => row.companyID}
        disableColumnFilter
      />
    </Box>
  );
};

export default AllAccountsTotals;
