import {
  ICopyViewSettingsPayload,
  ICreateOrUpdateViewSettings,
} from '../components/common/view-settings/interface';
import data from '../lib/data';

export const getViewSettingList = (options: {
  GroupKey: string;
  UserKey: string;
  SettingId: string;
  ViewName: string;
  ViewGroup: string;
  Culture: string;
  Mode: string;
}) => {
  const endpoint = `v3/api/viewSettings/for/${options.GroupKey}/${options.UserKey}`;
  return data.post(endpoint, options);
};

export const updateViewSettings = ({
  settingsKey,
  payload,
}: {
  settingsKey: string;
  payload: any;
}) => {
  const endpoint = `v3/api/viewSettings/save/${settingsKey}`;
  return data.post(endpoint, payload);
};

export const updateViewSettingAll = ({
  settingsKey,
  payload,
}: {
  settingsKey: string;
  payload: any;
}) => {
  const endpoint = `v3/api/viewSettings/save/${settingsKey}`;
  return data.post(endpoint, payload);
};

export const deleteViewSettingsBySettingsKeyService = ({
  settingsKey,
}: {
  settingsKey: string;
}) => {
  const endpoint = `v3/api/for/${settingsKey}`;
  return data.delete(endpoint);
};

export const updateViewSettingsConfiguration = (payload: any) => {
  const endpoint = `v3/api/viewsettingsconfig`;
  return data.put(endpoint, payload);
};

export const updateColumnSettingsBySettingsKey = (payload: any) => {
  const endpoint = `v3/api/updatecolumnsettings`;
  return data.put(endpoint, payload);
};

export const getUsersForViewSetting = ({
  groupKey,
  userKey,
}: {
  groupKey: string;
  userKey: number;
}) => {
  const endpoint = `v4/api/manageduser/for/users/${groupKey}/${userKey}`;
  return data.get(endpoint);
};

export const copySettingsService = (payload: ICopyViewSettingsPayload) => {
  const endpoint = `v3/api/copyviewsettings`;
  return data.post(endpoint, payload);
};

export const createOrUpdateViewSettings = (
  payload: ICreateOrUpdateViewSettings,
) => {
  const endpoint = `v3/api/createorupdate`;
  return data.post(endpoint, payload);
};
