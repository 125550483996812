import {Menu as MUIMenu, MenuItem, MenuProps} from '@mui/material';

interface MenuItemProps {
  label: string;
  onClick: () => void;
  disabled?: boolean;
  disableScrollLock?: boolean;
}

interface Props extends MenuProps {
  items: MenuItemProps[];
}

const Menu: React.FC<Props> = ({
  items,
  disableScrollLock = true,
  ...menuProps
}) => {
  return (
    <MUIMenu disableScrollLock={disableScrollLock} {...menuProps}>
      {items.map(item => (
        <MenuItem
          key={item.label}
          onClick={item.onClick}
          disabled={item.disabled}
        >
          {item.label}
        </MenuItem>
      ))}
    </MUIMenu>
  );
};

export default Menu;
