import {isEmpty} from '../lib/utils';
import {interPaymentService} from '../services/payment';
import {fetchPaymentMethods} from '../store/actions/paymentsCurrenciesActions';
import {ONLINE_CM_APPLICATION_TYPE, TRAN_TYPE} from './constants';

export const getCMApplicationsForInvoices = ({selectedStatement, groupKey}) => {
  const invoices = selectedStatement
    .filter(statement => statement.tranType !== TRAN_TYPE.CM)
    .map(statement => {
      const ledgerHash = `atc-invoice-${groupKey}-${statement.invcKey}`;
      return {
        invoiceId: statement.tranId,
        amount: Number.parseFloat(statement.paymentAmount).toFixed(2),
        ledgerHash,
        transactionType: statement.tranType,
        invoiceCreationDate: statement.tranDate,
        discountAmount: Number.parseFloat(statement?.discAmt),
        discountDate: statement?.discDate,
        invoiceTranAmount: Number.parseFloat(statement?.tranAmt).toFixed(2),
      };
    });

  const CMApplications = selectedStatement
    .filter(statement => statement.tranType === TRAN_TYPE.CM)
    .map(statement => {
      const ledgerHash = `atc-invoice-${groupKey}-${statement.invcKey}`;
      return {
        Amount: Number.parseFloat(statement.paymentAmount).toFixed(2),
        CMId: statement.invcKey,
        ledgerHash,
        CMInvoiceKey: statement.invcKey,
      };
    });
  return {CMApplications, invoices};
};

export const getCountAndTotalAmount = ({
  paymentAmount,
  groupKey,
  allowDiscount,
  selectedInvoicesParam = [],
  allowOnlineCMApplication,
}) => {
  let invoiceCount = 0;
  let creditMemoCount = 0;
  let invoiceSum = 0;
  let creditMemoSum = 0;
  let totalAmount;
  let totalDiscount = 0;
  if (isEmpty(selectedInvoicesParam)) {
    totalAmount = Number.parseFloat(paymentAmount || 0);
  } else {
    totalAmount = selectedInvoicesParam.reduce((accum, curr) => {
      curr.paymentAmount = isEmpty(curr.paymentAmount) ? 0 : curr.paymentAmount;

      if (curr.tranType === TRAN_TYPE.IN) {
        invoiceCount += 1;
        invoiceSum += Number.parseFloat(curr.paymentAmount);
        totalDiscount +=
          new Date(curr.discDate).setHours(0, 0, 0, 0) <
            new Date().setHours(0, 0, 0, 0) ||
          Number.parseFloat(curr.paymentAmount || 0) !==
            Number.parseFloat(curr.tranAmt || 0)
            ? 0
            : Number.parseFloat(curr?.discAmt);
      } else if (curr.tranType === TRAN_TYPE.CM) {
        creditMemoCount += 1;
        creditMemoSum += Number.parseFloat(curr.paymentAmount);
      }
      const checkIsCMAllowed =
        curr.tranType === TRAN_TYPE.CM &&
        allowOnlineCMApplication !== ONLINE_CM_APPLICATION_TYPE.ALL;
      if (checkIsCMAllowed) {
        return accum;
      }

      return accum + Number.parseFloat(curr.paymentAmount);
    }, 0);

    if (
      allowOnlineCMApplication === ONLINE_CM_APPLICATION_TYPE.ALL &&
      Math.abs(creditMemoSum) > invoiceSum
    ) {
      creditMemoSum = -invoiceSum;
      totalAmount = 0;
    }
  }

  const {CMApplications} = getCMApplicationsForInvoices({
    selectedStatement: selectedInvoicesParam,
    groupKey,
  });
  totalDiscount = CMApplications.length ? 0 : totalDiscount;
  totalAmount = allowDiscount ? totalAmount - totalDiscount : totalAmount;
  return {
    totalAmount,
    invoiceCount,
    creditMemoCount,
    invoiceSum,
    creditMemoSum,
    totalDiscount,
  };
};

export const getInterPaymentDetails = async ({
  selectedPaymentMethod,
  custKey,
  groupKey,
  selectedInvoices,
}) => {
  if (
    selectedPaymentMethod.cardType &&
    selectedPaymentMethod.cardType.toLocaleLowerCase() !== 'ach'
  ) {
    const CustomerKey = custKey;
    const GroupKey = groupKey;
    const PaymentMethodId = selectedPaymentMethod.pmtMethodKey;
    const {invoices, CMApplications} = getCMApplicationsForInvoices({
      selectedStatement: selectedInvoices,
      groupKey,
    });

    const InvoiceDetails = invoices
      .filter(inv => inv.transactionType !== TRAN_TYPE.CM)
      .reduce((acc, curr) => {
        const invoiceKey = curr.ledgerHash.split('-').pop() || null;
        return [
          {
            InvoiceKey: invoiceKey,
            InvoiceAmountToPay: curr.amount,
            invoiceTranAmount: curr.invoiceTranAmount,
            discountAmount: curr.discountAmount,
            discountDate: curr.discountDate,
          },
          ...acc,
        ];
      }, []);

    const dataToSend = {
      CustomerKey,
      GroupKey,
      PaymentMethodId,
      InvoiceDetails,
      CMApplications,
    };

    try {
      return await interPaymentService(dataToSend);
    } catch (error) {
      console.error(error);
    }
  } else {
    return {
      isSurchargeApplicable: false,
      surchargeErrorMessage: null,
      totalFee: 0,
      transactionFeeResponses: [],
      transactionFeeResponsesLoaded: true,
    };
  }
};

export const loadPaymentMethods = ({dispatch, groupKey, custKey}) =>
  dispatch(fetchPaymentMethods({groupKey, customerKey: custKey}));
