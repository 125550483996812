import {gridModeTypes} from '../constants/common';
import data from '../lib/data';
import {isEmpty} from '../lib/utils';
import {downloadBlobFilePostApi} from './download';

export const fetchScheduledPayments = ({
  userKey,
  groupKey,
  settingKey,
  sortQuery,
  globalFilterQuery,
  pageSize,
  skip,
  includePastDue,
  sqlQuery,
}) => {
  let endpoint = `v4/api/payments/for/${groupKey}/scheduledpayments?`;
  const sortJson =
    sortQuery?.length === 0
      ? null
      : {
          ViewSetting: {
            SortSetting: sortQuery.map((item, index) => {
              return {
                SortIndex: index + 1,
                SortCol: item.field,
                SortOrder: item.sort,
              };
            }),
          },
        };

  const payload = {
    countAll: true,
    sortJson: sortJson ? JSON.stringify(sortJson) : null,
    settingKey,
    userKey,
    getCount: true,
    fetchDays: 1500,
    isReturnCount: true,
    mode: gridModeTypes.ScheduledPayments,
    includePastDue,
    filterJson: globalFilterQuery ? JSON.stringify(globalFilterQuery) : null,
    filterSql: sqlQuery,
    source: 'react',
  };
  if (!isEmpty(skip)) {
    endpoint += `&%24skip=${skip}`;
  }

  if (!isEmpty(pageSize)) {
    endpoint += `&%24top=${pageSize}`;
  }

  return data.post(endpoint, payload);
};

export const exportScheduledPayments = ({
  userKey,
  groupKey,
  settingKey,
  sortQuery,
  globalFilterQuery,
  includePastDue,
  sqlQuery,
  pageSize,
  skip,
}) => {
  const sortJson =
    sortQuery?.length === 0
      ? null
      : {
          ViewSetting: {
            SortSetting: sortQuery.map((item, index) => {
              return {
                SortIndex: index + 1,
                SortCol: item.field,
                SortOrder: item.sort,
              };
            }),
          },
        };

  const options = {
    countAll: true,
    sortJson: sortJson ? JSON.stringify(sortJson) : null,
    mode: gridModeTypes.ScheduledPayments,
    settingKey,
    userKey,
    groupKey,
    includePastDue,
    filterJson: globalFilterQuery ? JSON.stringify(globalFilterQuery) : null,
    filterSql: sqlQuery,
    source: 'react',
  };

  let endpoint = `v4/api/export/ScheduledPayments?`;
  if (!isEmpty(skip)) {
    endpoint = `${endpoint}&%24skip=${skip}`;
  }

  if (!isEmpty(pageSize)) {
    endpoint += `&%24top=${pageSize}`;
  }

  return downloadBlobFilePostApi(endpoint, options);
};

export const getAutoSchedulePayments = groupKey => {
  const endpoint = `v4/api/payments/for/${groupKey}/scheduledtaskinfo`;
  return data.get(endpoint);
};
export const deleteSchedulePayments = groupKey => {
  const endpoint = `v4/api/payments/for/${groupKey}/scheduledtask`;
  return data.delete(endpoint);
};

export const saveSchedulePayments = (groupKey, payload) => {
  const endpoint = `v4/api/payments/for/${groupKey}/savescheduledtask`;
  return data.post(endpoint, payload);
};

// Fetch Invoices data in schedule payments
export const fetchSchedulePaymentsInvoicesDetails = (
  groupKey,
  pendingPaymentKey,
) => {
  const endpoint = `v4/api/payments/for/${groupKey}/${pendingPaymentKey}/schedulepaymentinvoices`;
  return data.get(endpoint);
};
