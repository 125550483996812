import dayjs from 'dayjs';
import {Button, Checkbox, Select, TextField} from '../../../ui/inputs';
import {constants} from '../../../constants/common';
import {IInvoiceDetail} from '../../account-overview/interface';

export interface IDisputeCodeDropdownItems {
  label: string;
  value: string | number;
}
interface IDisputeReasonInformationProps {
  onHandleChange: (key: string, value: any) => void;
  invoiceDetails: IInvoiceDetail | undefined;
  isTaggedInvoice: boolean;
  disputeCodes: IDisputeCodeDropdownItems[];
  closeDialog: () => void;
}

function DisputeReasonInformation({
  onHandleChange,
  invoiceDetails,
  isTaggedInvoice = true,
  disputeCodes,
  closeDialog,
}: IDisputeReasonInformationProps) {
  return (
    <div className="d-flex flex-wrap justify-content-between">
      <div className="light-grey card p-2 d-flex w-350-px my-2">
        <Select
          disabled
          label={constants.REASON}
          value={
            invoiceDetails?.disputeCodeKey
              ? `${parseInt(invoiceDetails?.disputeCodeKey, 10)}`
              : ''
          }
          items={disputeCodes}
          placeholder={constants.SELECT_REASON}
          shrinkLabel
          notched
        />

        <TextField
          label={constants.DISPUTE_CODE_DATE}
          disabled
          id="disputeCodeDate"
          value={
            invoiceDetails?.disputeCodeDate
              ? dayjs
                  .utc(invoiceDetails?.disputeCodeDate)
                  .format(constants.DATE_FORMAT_1)
              : ''
          }
          InputLabelProps={{shrink: true}}
          margin="normal"
        />

        <div className="">
          <label htmlFor="excludeFromAging">
            {constants.EXCLUDE_FROM_AGING}
            <Checkbox
              disabled
              className="ms-2"
              id="excludeFromAging"
              checked={invoiceDetails?.excludeAging}
              onChange={() => {}}
            />
          </label>
        </div>
      </div>
      <div className="light-grey card p-2 d-flex w-350-px my-2  ">
        <div className="d-flex align-items-center">
          <Checkbox
            disabled
            id="inDispute"
            className="me-2"
            checked={invoiceDetails?.inDispute}
            onChange={() => {}}
          />
          <label htmlFor="inDispute"> {constants.IN_DISPUTE}</label>
        </div>
        <TextField
          label={constants.DISPUTE_DATE}
          disabled
          id="disputeDate"
          value={
            invoiceDetails?.disputeDate
              ? dayjs
                  .utc(invoiceDetails?.disputeDate)
                  .format(constants.DATE_FORMAT_1)
              : ''
          }
          InputLabelProps={{shrink: true}}
          margin="normal"
        />
        <TextField
          label={constants.DISPUTE_AMOUNT}
          disabled
          id="disputeAmount"
          value={
            invoiceDetails?.disputeAmt ? `${invoiceDetails?.disputeAmt}` : ''
          }
          InputLabelProps={{shrink: true}}
          margin="normal"
        />
        <div className="d-flex align-items-center">
          <Checkbox
            disabled
            id="disputeResolved"
            className="me-2"
            checked={invoiceDetails?.resolved}
            onChange={() => {}}
          />
          <label htmlFor="disputeResolved">{constants.DISPUTE_RESOLVED}</label>
        </div>
        <TextField
          label={constants.RESOLVED_DATE}
          disabled
          id="resolvedDate"
          value={
            invoiceDetails?.resolvedDate
              ? dayjs
                  .utc(invoiceDetails?.resolvedDate)
                  .format(constants.DATE_FORMAT_1)
              : ''
          }
          InputLabelProps={{shrink: true}}
          margin="normal"
        />
      </div>
      <TextField
        label={constants.NOTES}
        disabled={!isTaggedInvoice}
        id="notes"
        value={invoiceDetails?.disputeNote}
        onChange={e => onHandleChange('disputeNote', e.target.value)}
        InputProps={{
          multiline: true,
          rows: 4,
        }}
        InputLabelProps={{
          shrink: !isTaggedInvoice ? true : undefined,
        }}
        margin="normal"
        fullWidth
      />
      {isTaggedInvoice && (
        <Button variant="contained" onClick={closeDialog}>
          {constants.CLOSE}
        </Button>
      )}
    </div>
  );
}

export default DisputeReasonInformation;
