import {
  exportStatementsOverview,
  fetchStatementsOfAccounts,
} from '../../services/communications';
import {actionTypes} from '../constants/actionTypes';
import {constants} from '../../constants/common';
import {downloadExcel} from '../../utils/fileUtils';

export const getStatementOfAccount = options => (dispatch, getState) => {
  const statementOfAccountState = getState().statementOfAccountReducer;
  dispatch({type: actionTypes.FETCHING_STATEMENT_OF_ACCOUNTS});
  const filterQuery =
    options.SettingKey === statementOfAccountState?.viewSettings?.settingsKey
      ? options.filterQuery
      : '';
  const updatedOptions = {...options, globalFilterQuery: filterQuery};
  fetchStatementsOfAccounts(updatedOptions)
    .then(res => {
      if (res.data.value && res.data.value.length) {
        res.data.value.forEach(x => {
          // eslint-disable-next-line no-param-reassign
          x.paymentAmountDisabled = true;
          // eslint-disable-next-line no-param-reassign
          x.paymentAmount = '';
          // eslint-disable-next-line no-param-reassign
          x.overrideDiscount = false;
        });
      }
      dispatch({
        type: actionTypes.FETCHED_STATEMENT_OF_ACCOUNTS,
        statementOfAccounts: res.data.value,
        statementOfAccountsCount: res.data.count,
        statementOfAccountsNextLink: res.data.nextLink,
        statementOfAccountsSelectedRows:
          statementOfAccountState.statementOfAccountsSelectedRows,
        viewSettings: res.data.viewSetting,
      });
      return res.data;
    })
    .catch(() => {
      dispatch({type: actionTypes.FETCHING_STATEMENT_OF_ACCOUNTS_FAILED});
    });
};

export const updateStatementOfAccountAllRowsSelected =
  selectedRows => dispatch => {
    dispatch({
      type: actionTypes.UPDATED_STATEMENT_OF_ACCOUNT_SELECTED_ROWS,
      statementOfAccountsSelectedRows: selectedRows,
    });
  };

export const updateStatementOfAccountReset = () => dispatch => {
  dispatch({
    type: actionTypes.UPDATED_STATEMENT_OF_ACCOUNT_SELECTED_ROWS_RESET,
  });
};

export const statementOfAccountsExportToExcel =
  options => (dispatch, getState) => {
    const statementOfAccountState = getState().statementOfAccountReducer;
    const toast = getState().generalReducer?.toastRef;

    dispatch({type: actionTypes.EXPORTING_STATEMENTS_TO_EXCEL});
    exportStatementsOverview(statementOfAccountState, options)
      .then(res => {
        downloadExcel(
          res.data,
          'statementsOfAccounts',
          toast,
          constants.STATEMENTS_OF_ACCOUNTS_EXPORTED_TO_EXCEL,
        );
        dispatch({type: actionTypes.EXPORTED_STATEMENTS_TO_EXCEL});
      })
      .catch(() => {
        dispatch({type: actionTypes.EXPORTING_STATEMENTS_TO_EXCEL_FAILED});
      });
  };
export const updateStatementOfAccountSort = ({field, direction}) => {
  return {
    type: actionTypes.UPDATE_STATEMENT_OF_ACCOUNT_SORT,
    sortBy: field,
    sortDirection: direction,
  };
};

export const updateAccountViewSetting = settingId => {
  return {
    type: actionTypes.UPDATE_ACCOUNT_SETTING_KEY,
    settingId,
  };
};

export const updateAccountViewSettingObject = ({settingId, viewSettings}) => {
  return {
    type: actionTypes.UPDATE_ACCOUNT_SETTING_OBJECT,
    payload: {settingId, viewSettings},
  };
};

export const cleanUpStatementsOfAccountData = () => {
  return {
    type: actionTypes.CLEAN_UP_STATEMENTS_OF_ACCOUNT_DATA,
  };
};
