import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Dialog} from 'primereact/dialog';
import {hideDialog} from '../../store/actions/generalActions';

function CustomDialog() {
  const {
    isDialogOpen,
    dialogContent,
    title,
    width,
    className,
    headerIcon,
    position,
  } = useSelector(store => store.generalReducer.Dialog);
  const dispatch = useDispatch();
  return (
    <Dialog
      position={position}
      className={className && className}
      resizable
      blockScroll
      header={
        <h3 className="m-0">
          {' '}
          {headerIcon ? <i className={`pi ${headerIcon} me-2`} /> : ''} {title}
        </h3>
      }
      visible={isDialogOpen}
      closeOnEscape={false}
      style={{width}}
      onHide={() => dispatch(hideDialog())}
    >
      {dialogContent}
    </Dialog>
  );
}
export default CustomDialog;
