import {constants} from '../constants/common';
import {isEmpty} from '../lib/utils';

export const getFilteredQuery = filterFieldsValues => {
  let filterQuery = '';
  filterQuery = Object.entries(filterFieldsValues).map(entries => {
    const {value} = entries[1];
    const type = entries[1]?.type || 'string';
    if (
      !isEmpty(value) &&
      (type === 'boolean' || type === 'number' || type === 'date')
    ) {
      return `${entries[0]} = ${value}`;
    }
    if (!isEmpty(value) && type === 'string') {
      const val = value.includes(' ') ? `"${value}"` : value;
      return `${entries[0]} like ${val}`;
    }
  });
  filterQuery = filterQuery.filter(query => !isEmpty(query));
  filterQuery = filterQuery.join(` ${constants.AND.toLocaleLowerCase()} `);

  return filterQuery;
};

const booleanTypeOperator = [
  {name: 'EqualTo', label: 'EqualTo'},
  {name: 'NotEqualTo', label: 'NotEqualTo'},
  {name: 'null', label: 'is null'},
  {name: 'notNull', label: 'is not null'},
];

const numberTypeOperator = [
  ...booleanTypeOperator,
  {name: 'LessThan', label: 'LessThan'},
  {name: 'GreaterThan', label: 'GreaterThan'},
  {name: 'LessThanOrEqualTo', label: 'LessThanOrEqualTo'},
  {name: 'GreaterThanOrEqualTo', label: 'GreaterThanOrEqualTo'},
];

const stringTypeOperator = [
  ...booleanTypeOperator,
  {name: 'contains', label: 'contains'},
  {name: 'beginsWith', label: 'begins with'},
  {name: 'endsWith', label: 'ends with'},
  {name: 'in', label: 'in'},
  {name: 'notIn', label: 'not in'},
];

const dateTypeOperator = [...booleanTypeOperator, ...numberTypeOperator];

export const getOperators = type => {
  switch (type.toLocaleLowerCase()) {
    case 'number':
      return numberTypeOperator;
    case 'string':
      return stringTypeOperator;
    case 'date':
      return dateTypeOperator;
    case 'boolean':
      return booleanTypeOperator;
    default:
      return [
        ...stringTypeOperator,
        ...numberTypeOperator,
        ...booleanTypeOperator,
        ...dateTypeOperator,
      ];
  }
};

// Reset header filter while applying clear from query builder
export const shouldResetFilterModel = data => {
  return data.sql === '(1 = 1)' && data.json.rules.length === 0;
};
