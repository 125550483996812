import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-pro';
import CachedIcon from '@mui/icons-material/Cached';
import SettingsIcon from '@mui/icons-material/Settings';
import ErrorIcon from '@mui/icons-material/Error';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import SummarizeIcon from '@mui/icons-material/Summarize';
import DescriptionIcon from '@mui/icons-material/Description';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import {FaArrowsAltH} from 'react-icons/fa';
import TuneIcon from '@mui/icons-material/Tune';
import {constants} from '../../../constants/common';
import {Button} from '../../../ui/inputs';
import Menu from '../../../ui/navigation/menu/Menu';
import {CustomFilterButton} from '../../../ui/data';

export default function AllAccountsToolbar({
  onRefresh,
  exportToExcel,
  selectAllRows,
  applyGridBestFit,
  setopenMenuOptions,
  openMenuOptions,
  bulkActivityDisabled,
  showNewActivity,
  setShowFileUploadDialog,
  onReportClicked,
  onViewSettings,
}: {
  onRefresh: any;
  exportToExcel: any;
  selectAllRows: any;
  applyGridBestFit: any;
  setopenMenuOptions: any;
  openMenuOptions: any;
  bulkActivityDisabled: boolean;
  showNewActivity: any;
  setShowFileUploadDialog: any;
  onReportClicked: any;
  onViewSettings: () => void;
}) {
  const csvData: Array<Array<string | number>> = [['CompanyID', 'CustomerID']];
  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setopenMenuOptions(event.currentTarget);
  };
  const open = Boolean(openMenuOptions);
  const handleCloseMenu = () => {
    setopenMenuOptions(null);
  };

  const downloadCSV = () => {
    const csvContent = `${csvData.map(row => row.join(',')).join('\n')}`;
    const csvBlob = new Blob([csvContent], {type: 'text/csv'});
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(csvBlob);
    downloadLink.download = 'BulkActivity-Template.csv';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    // Clean up
    document.body.removeChild(downloadLink);
    setopenMenuOptions(null);
  };
  const displayDialogOnClick = () => {
    setShowFileUploadDialog(true);
    setopenMenuOptions(null);
  };

  return (
    <GridToolbarContainer>
      <Button
        startIcon={<ChatBubbleOutlineIcon />}
        disabled={bulkActivityDisabled}
        onClick={showNewActivity}
      >
        New Bulk Activity
      </Button>
      <Button startIcon={<TuneIcon />} disabled>
        Inquiry View
      </Button>
      <Button startIcon={<SummarizeIcon />} onClick={onReportClicked}>
        Reports
      </Button>
      <CustomFilterButton />
      <GridToolbarColumnsButton />
      <Button startIcon={<DescriptionIcon />} onClick={exportToExcel}>
        Export To Excel
      </Button>
      <Button startIcon={<CachedIcon />} onClick={() => onRefresh(true)}>
        Refresh
      </Button>
      <GridToolbarDensitySelector />
      <Button startIcon={<FaArrowsAltH />} onClick={applyGridBestFit}>
        BestFit
      </Button>
      <Button startIcon={<SettingsIcon />} onClick={onViewSettings}>
        View Settings
      </Button>
      <Button
        aria-controls="dropdown-menu"
        startIcon={<DoneOutlineIcon />}
        onClick={handleOpenMenu}
      >
        Select All
      </Button>
      <Menu
        id="dropdown-menu"
        anchorEl={openMenuOptions}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleCloseMenu}
        items={[
          {label: constants.SELECT_ALL, onClick: selectAllRows, disabled: true},
          {label: constants.DOWNLOAD_TEMPLATE, onClick: downloadCSV},
          {label: constants.UPLOAD, onClick: displayDialogOnClick},
        ]}
      />
      <Button startIcon={<ClearAllIcon />} disabled>
        Clear All
      </Button>

      <Button startIcon={<ErrorIcon />} disabled>
        Alert
      </Button>
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
}
