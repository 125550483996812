import {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {GridPaginationModel} from '@mui/x-data-grid';
import {constants} from '../../../../constants/common';
import {
  editAllTaggedInvoice,
  getActivityPayment,
  getDisputeCodeList,
  updateCommunicateTransactionAllRowsSelected,
} from '../../../../store/actions/activitiesActions';
import EditAllTaggedInvoicesTemplate from '../../../common/EditAllTaggedInvoicesTemplate';
import FeatureNotAvailable from '../../../common/FeatureNotAvailable';
import {activityTransactionsColumns} from './activityTransactionsColumns';
import PaymentDetailTemplate from '../../../messages/payment-details/PaymentDetailTemplate';
import TaggedInvoiceDetailTemplate from '../../../messages/tagged-invoice/TaggedInvoiceDetailTemplate';
import {OpenDocs} from '../../open-docs/OpenDocs';
import {downloadInvoice} from '../../../../utils/fileUtils';
import {useQueryKeys} from '../../../../hooks/useQueryKeys';
import {Dialog} from '../../../../ui';
import {DataGrid} from '../../../../ui/data';
import CommunicateTransactionToolbar from './CommunicateTransactionToolbar';
import {PAGE_SIZE, TRAN_TYPE} from '../../../../utils/constants';
import {Button, Checkbox, RadioGroup} from '../../../../ui/inputs';
import {
  IActivityTransaction,
  ICommunicateTransaction,
  IDisputeCodes,
  IDisputesList,
  ILoadActivityPaymentParam,
  IRequest,
  ISelectedInvoice,
} from './ICommunicateTransaction';

function CommunicateTransaction({
  handleTableFilterDisplayType = () => {},
  activityInfo,
  custKey,
  taskKey,
  taskActKey,
  groupKey,
  activityEditTransactionsObj,
  setActivityEditTransactionsObj,
  custName,
  custId,
  modeType,
  showAllAccountsCheckbox,
  updateActivityInfoState,
}: ICommunicateTransaction) {
  const dispatch = useDispatch<any>();
  const activityRef = useRef(activityInfo);

  const {
    activityTransactions,
    transactionCount,
    loading: transactionLoading,
    // transactionSkip,
  } = useSelector((store: any) => store.activitiesReducer);
  const toast = useSelector((store: any) => store.generalReducer?.toastRef);
  // const [transactionLoadedPage, setTransactionLoadedPage] = useState(1);
  // const [transactionCurrentPage, setTransactionCurrentPage] = useState(1);
  const transactionRadioOptions = [
    {
      id: constants.TAGGED_TO_ACTIVITY,
      label: constants.TAGGED_TO_ACTIVITY,
      value: 0,
    },
    {
      id: constants.TAGGED_TO_ISSUE,
      label: constants.TAGGED_TO_ISSUE,
      value: 1,
    },
    {id: constants.PAST_DUE, label: constants.PAST_DUE, value: 4},
    {id: constants.OPEN, label: constants.OPEN, value: 2},
    {id: constants.ALL, label: constants.ALL, value: 3},
  ];
  const [showEditAllTaggedInvoicesModal, setShowEditAllTaggedInvoicesModal] =
    useState<boolean>(false);
  const [showFeatureNotAvailable, setShowFeatureNotAvailable] =
    useState<boolean>(false);
  const [showAllAccounts, setShowAllAccounts] = useState<boolean>(false);
  const [transactionRadioValue, setTransactionRadioValue] = useState<number>(1);
  const [skip, setSkip] = useState<number>(0);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: PAGE_SIZE,
  });
  const [
    hideInvoiceWithZeroBalanceChecked,
    setHideInvoiceWithZeroBalanceChecked,
  ] = useState(false);
  const {userKey} = useQueryKeys();
  const [activityTransactionsObj, setActivityTransactionsObj] = useState<
    IActivityTransaction[]
  >([]);
  const [showCustomerDocs, setShowCustomerDocs] = useState<boolean>(false);
  const [selectedInvoice, setSelectedInvoice] =
    useState<ISelectedInvoice | null>(null);
  const [showPaymentDetailsDialog, setShowPaymentDetailsDialog] =
    useState<boolean>(false);
  const [showInvoiceDetailsDialog, setShowInvoiceDetailsDialog] =
    useState<boolean>(false);
  const [invcKey, setInvcKey] = useState<string>('');
  const [transactionType, setTransactionType] = useState<string>('');
  const disputeCodes: IDisputeCodes[] = useSelector((store: any) => {
    const list = store.activitiesReducer.disputeCodes;
    const formatted = list.map((item: IDisputeCodes) => {
      return {label: item.disputeCode, value: item.disputeCodeKey};
    });
    return [{label: constants.NONE, value: ''}, ...formatted];
  });

  const loadActivityPayment = (options: ILoadActivityPaymentParam) => {
    dispatch(
      getActivityPayment({
        WorkSessionID: activityInfo?.customerTask?.workSessionID,
        CustKey: custKey,
        TaskKey: taskKey,
        TaskActKey: taskActKey,
        RestrictBy: Number(options.RestrictByParam),
        RandomNumber: 0,
        ShowAllAccounts: options.showAllAccountsParam ? 1 : 0,
        AllowZeroBalanceDue: options.AllowZeroBalanceDueParam,
        GroupKey: groupKey,
        skip: options.skipParam,
        pageSize: options.pageSizeParam,
      }),
    );
  };

  // reset pagination
  const resetPagination = () => {
    setPaginationModel({
      pageSize: paginationModel.pageSize,
      page: 0,
    });
  };

  useEffect(() => {
    dispatch(getDisputeCodeList(groupKey));
    handleTableFilterDisplayType('row');
    loadActivityPayment({
      skipParam: skip,
      pageSizeParam: paginationModel.pageSize,
      AllowZeroBalanceDueParam: !hideInvoiceWithZeroBalanceChecked,
      RestrictByParam: transactionRadioValue,
      showAllAccountsParam: showAllAccounts ? 1 : 0,
    });
    return () => {
      setActivityTransactionsObj([]);
      resetPagination();
    };
  }, []);

  useEffect(() => {
    // Update list if already edited
    const updatedList = activityTransactions?.map(
      (item: IActivityTransaction) => {
        const index = activityEditTransactionsObj.findIndex(
          (el: IActivityTransaction) => el.invcKey === item.invcKey,
        );
        if (index > -1) {
          // Edited object
          return activityEditTransactionsObj[index];
        }
        // Return original object
        return item;
      },
    );
    setActivityTransactionsObj(updatedList);
  }, [activityTransactions]);

  const onRefresh = (val: boolean) => {
    if (val) {
      loadActivityPayment({
        skipParam: skip,
        pageSizeParam: paginationModel.pageSize,
        AllowZeroBalanceDueParam: !hideInvoiceWithZeroBalanceChecked,
        RestrictByParam: transactionRadioValue,
        showAllAccountsParam: showAllAccounts ? 1 : 0,
      });
    }
  };
  const handleUpdateSelectedRows = (selectedRows: any) => {
    dispatch(updateCommunicateTransactionAllRowsSelected(selectedRows));
  };

  const handleSetShowEditAllTaggedInvoicesModal = (val: boolean = true) => {
    setShowEditAllTaggedInvoicesModal(val);
  };

  const updateInvoices = (disputesList: IDisputesList[]) => {
    let request: IRequest = {
      UpdateDispute: false,
      InDispute: false,
      UpdateResolved: false,
      Resolved: false,
      UpdateExclude: false,
      Exclude: false,
      UpdateDisputeDate: false,
      DisputeDate: new Date(),
      UpdateResolvedDate: false,
      ResolvedDate: new Date(),
      UpdateReason: false,
      ReasonCode: null,
      UpdateDisputeNote: false,
      DisputeNote: '',
      WorkSessionID: activityInfo?.customerTask?.workSessionID,
      taskActivityKey: taskActKey,
      userKey,
      groupKey,
      UpdateInvoiceOnly: true,
      ModeType: modeType,
    };

    const disputes = disputesList.map(
      ({
        invcKey: invoiceKey,
        tranType,
        balanceDue,
        balanceDueHC,
        currExchRate,
        tagged,
        dispute,
        resolved,
        excludeFromAging,
        reasonCode,
      }: IDisputesList) => {
        return {
          invcKey: invoiceKey,
          tranType,
          balanceDue,
          balanceDueHC,
          currencyExchangeRate: currExchRate,
          tag: tagged,
          dispute,
          resolved,
          exclude: excludeFromAging,
          disputeCode: reasonCode ?? '',
        };
      },
    );
    request = {
      ...request,
      disputes,
    };

    dispatch(
      editAllTaggedInvoice({
        editRequest: request,
        handleSetShowEditAllTaggedInvoicesModal,
        onRefresh,
      }),
    );
    setActivityEditTransactionsObj([]);
  };

  const onTransactionObjChange = (key: string, val: any, rowIndex: number) => {
    let updatedTransObj: IActivityTransaction | null = null;
    const modifiedActivity = activityTransactionsObj.find(
      (obj, index) => index === rowIndex,
    );

    let tempActivityInfo = {...activityRef.current};

    let balanceDueAmount = modifiedActivity?.balanceDue ?? 0;
    if (key === constants.TAGGED_FIELD) {
      balanceDueAmount = val
        ? tempActivityInfo.taggedAmount + balanceDueAmount
        : tempActivityInfo.taggedAmount - balanceDueAmount;
    }

    tempActivityInfo = {
      ...tempActivityInfo,
      taggedAmount: balanceDueAmount,
    };
    activityRef.current = tempActivityInfo;
    updateActivityInfoState(tempActivityInfo);

    const activityTransactionsObjTemp = activityTransactionsObj.map(
      (obj, index) => {
        if (index === rowIndex) {
          const returnObj = {...obj, [key]: val};
          updatedTransObj = returnObj;
          return returnObj;
        }
        return obj;
      },
    );

    const editActivityEditTransactionsObj = [...activityEditTransactionsObj];
    const index = editActivityEditTransactionsObj.findIndex(
      _element => _element.invcKey === updatedTransObj?.invcKey,
    );
    if (index > -1) {
      editActivityEditTransactionsObj[index] = updatedTransObj;
    } else {
      editActivityEditTransactionsObj.push(updatedTransObj);
    }

    setActivityEditTransactionsObj(editActivityEditTransactionsObj);
    setActivityTransactionsObj(activityTransactionsObjTemp);
    updateInvoices(editActivityEditTransactionsObj);
  };

  const onPDFDownloadIconClick = async (request: {
    invcKey: number;
    groupKey: string;
  }) => {
    const {invcKey: invoiceKey, groupKey: GroupKey} = request;
    downloadInvoice(GroupKey, invoiceKey, toast, undefined);
  };

  const onFolderIconClicked = (data: IActivityTransaction) => {
    const {invcKey: invoiceKey, documentNo} = data;
    setSelectedInvoice({invcKey: invoiceKey, documentNo});
    setShowCustomerDocs(true);
  };

  const handleSelectAll = () => {
    let activityTransactionsObjTemp = [...activityTransactionsObj];

    activityTransactionsObjTemp = activityTransactionsObjTemp.map(obj => {
      return {...obj, tagged: true};
    });
    setActivityTransactionsObj(activityTransactionsObjTemp);
  };

  const onClearAll = () => {
    let activityTransactionsObjTemp = [...activityTransactionsObj];

    activityTransactionsObjTemp = activityTransactionsObjTemp.map(obj => {
      return {...obj, tagged: false};
    });
    setActivityTransactionsObj(activityTransactionsObjTemp);
  };

  const onShowAllAccountChange = (value: boolean) => {
    setShowAllAccounts(value);
    loadActivityPayment({
      skipParam: skip,
      pageSizeParam: paginationModel.pageSize,
      AllowZeroBalanceDueParam: !hideInvoiceWithZeroBalanceChecked,
      RestrictByParam: transactionRadioValue,
      showAllAccountsParam: value ? 1 : 0,
    });
    resetPagination();
  };

  const onHideInvoiceWithZeroBalanceChanged = (value: boolean) => {
    setHideInvoiceWithZeroBalanceChecked(value);
    loadActivityPayment({
      skipParam: skip,
      pageSizeParam: paginationModel.pageSize,
      AllowZeroBalanceDueParam: !value,
      RestrictByParam: transactionRadioValue,
      showAllAccountsParam: showAllAccounts ? 1 : 0,
    });
    resetPagination();
  };

  const onTransactionRadioValueChange = (e: any) => {
    setTransactionRadioValue(Number(e.target.value));
    loadActivityPayment({
      skipParam: skip,
      pageSizeParam: paginationModel.pageSize,
      AllowZeroBalanceDueParam: !hideInvoiceWithZeroBalanceChecked,
      RestrictByParam: e.target.value,
      showAllAccountsParam: showAllAccounts ? 1 : 0,
    });
    resetPagination();
  };

  const handleOnInvoiceClick = (tranType: string, invoiceKey: string) => {
    setInvcKey(invoiceKey);
    setTransactionType(tranType);
    if (
      tranType?.toLocaleLowerCase() === TRAN_TYPE.CR.toLocaleLowerCase() ||
      tranType?.toLocaleLowerCase() === TRAN_TYPE.RV.toLocaleLowerCase()
    ) {
      setShowPaymentDetailsDialog(true);
    } else {
      setShowInvoiceDetailsDialog(true);
    }
  };

  const closeInvoiceDialog = () => {
    setShowInvoiceDetailsDialog(false);
  };

  /**
   * onPageChange is prop set for transaction tab grid to handle pagination
   * @param args GridPaginationModel object which has page which starts from 0 and skip properties
   */
  const onPageChange = (args: GridPaginationModel) => {
    const pageSkip =
      args.pageSize !== paginationModel.pageSize
        ? 0
        : (args.page + 1) * args.pageSize - args.pageSize;
    setPaginationModel({
      pageSize: args.pageSize,
      page: args.pageSize !== paginationModel.pageSize ? 0 : args.page,
    });
    setSkip(pageSkip);
    loadActivityPayment({
      skipParam: pageSkip,
      pageSizeParam: args.pageSize,
      AllowZeroBalanceDueParam: !hideInvoiceWithZeroBalanceChecked,
      RestrictByParam: transactionRadioValue,
      showAllAccountsParam: showAllAccounts ? 1 : 0,
    });
  };

  return (
    <>
      <Dialog
        title={constants.FEATURE_NOT_AVAILABLE_TITLE}
        open={showFeatureNotAvailable}
        onClose={() => setShowFeatureNotAvailable(false)}
        maxWidth="sm"
        fullWidth
      >
        <FeatureNotAvailable />
      </Dialog>
      <Dialog
        title="Tagged Invoice"
        open={showInvoiceDetailsDialog}
        onClose={() => setShowInvoiceDetailsDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <TaggedInvoiceDetailTemplate
          invcKey={invcKey}
          closeInvoiceDialog={closeInvoiceDialog}
          workSessionId={activityInfo?.customerTask?.workSessionID}
          selectedRow={activityTransactions}
        />
      </Dialog>
      <Dialog
        title={constants.PAYMENT_DETAILS}
        open={showPaymentDetailsDialog}
        onClose={() => setShowPaymentDetailsDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <PaymentDetailTemplate
          custPmtKey={invcKey}
          tranType={transactionType}
        />
      </Dialog>
      <Dialog
        title={constants.EDIT_ALL_TAGGED_INVOICES}
        open={showEditAllTaggedInvoicesModal}
        onClose={() => handleSetShowEditAllTaggedInvoicesModal(false)}
        maxWidth="md"
        fullWidth
      >
        <EditAllTaggedInvoicesTemplate
          workSessionId={activityInfo?.customerTask?.workSessionID}
          userKey={userKey}
          groupKey={groupKey}
          taskActivityKey={taskActKey}
          custKey={custKey}
          taskKey={taskKey}
          handleSetShowEditAllTaggedInvoicesModal={
            handleSetShowEditAllTaggedInvoicesModal
          }
          onRefresh={onRefresh}
          editedList={activityEditTransactionsObj}
          setActivityEditTransactionsObj={setActivityEditTransactionsObj}
          modeType={modeType}
        />
      </Dialog>
      <RadioGroup
        items={transactionRadioOptions}
        name="transaction"
        onChange={onTransactionRadioValueChange}
        defaultValue={1}
        row
      />
      <div className="my-1 d-flex align-items-center pe-3 flex-wrap">
        <Button
          size="small"
          variant="contained"
          className="m-1"
          sx={{alignSelf: 'start'}}
          onClick={() => handleSetShowEditAllTaggedInvoicesModal(true)}
        >
          {constants.EDIT_ALL_TAGGED_INVOICES}
        </Button>

        <div className="inline-block m-1">
          <Checkbox
            checked={hideInvoiceWithZeroBalanceChecked}
            onChange={e => {
              onHideInvoiceWithZeroBalanceChanged(e.target.checked);
              handleUpdateSelectedRows([]);
            }}
          />
          {constants.HIDE_INVOICE_WITH_ZERO_BALANCE}
        </div>

        {showAllAccountsCheckbox && (
          <span className="inline-block m-1">
            <Checkbox
              disabled={transactionLoading}
              checked={showAllAccounts}
              onChange={e => {
                onShowAllAccountChange(e.target.checked);
              }}
            />
            {constants.SHOW_ALL_ACCOUNTS}
          </span>
        )}
      </div>
      <div>
        <DataGrid
          height={700}
          disableVirtualization
          rows={activityTransactionsObj}
          columns={activityTransactionsColumns(
            onTransactionObjChange,
            onPDFDownloadIconClick,
            onFolderIconClicked,
            disputeCodes,
            handleOnInvoiceClick,
          )}
          loading={transactionLoading}
          rowCount={transactionCount}
          checkboxSelection={false}
          onPageChange={onPageChange}
          CustomToolbar={CommunicateTransactionToolbar}
          customToolbarMethods={{
            handleSelectAll,
            onClearAll,
            handleSetShowEditAllTaggedInvoicesModal,
            onHideInvoiceWithZeroBalanceChanged,
            hideInvoiceWithZeroBalanceChecked,
          }}
          paginationModel={paginationModel}
          disableMultipleColumnsSorting
          getRowId={row => row.invcKey}
        />
      </div>
      {showCustomerDocs && (
        <OpenDocs
          groupKey={groupKey}
          setShowCustomerDocs={setShowCustomerDocs}
          showCustomerDocs={showCustomerDocs}
          custName={custName}
          custKey={custKey}
          isInvoice
          selectedInvoice={selectedInvoice}
          custId={custId}
        />
      )}
    </>
  );
}
export default CommunicateTransaction;
