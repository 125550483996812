import {GridFilterModel} from '@mui/x-data-grid';
import {
  RuleGroupTypeIC,
  formatQuery,
  ValueSource,
  RuleType,
  defaultOperators,
  Field,
  convertQuery,
} from 'react-querybuilder';
import {parseSQL} from 'react-querybuilder/dist/parseSQL';

export const initialQuery: RuleGroupTypeIC = {rules: []};

export const generateJsonAndSql = (query: RuleGroupTypeIC) => {
  const json: RuleGroupTypeIC = JSON.parse(formatQuery(query, 'json'));
  return {
    json,
    sql: formatQuery(query, 'sql'),
  };
};

export const convertMUIToQueryBuilder = (
  muiFilters: GridFilterModel,
): RuleGroupTypeIC => {
  const convertedFilters: (RuleType<string, string, any, string> | string)[] =
    [];

  muiFilters.items.forEach((item, index) => {
    convertedFilters.push({
      id: item.id ? item.id.toString() : '',
      field: item.field,
      operator: item.operator === 'is' ? '=' : item.operator,
      valueSource: 'value' as ValueSource,
      value: item.value,
    });

    // Add "and" operator if it's not the last item
    if (index < muiFilters.items.length - 1) {
      convertedFilters.push('and');
    }
  });

  const ruleGroup: Partial<RuleGroupTypeIC> = {};
  (ruleGroup as any).rules = convertedFilters;
  return ruleGroup as RuleGroupTypeIC;
};

export function convertToCamelCase(inputSql: string) {
  // This regex matches column names in the SQL string
  const regex = /\b(\w+)\b(?=\s*(?:[=<>]|IS|LIKE|BETWEEN|AND|NOT))/g;
  let match;
  let sql = inputSql;
  // Use regex.exec to find all matches in the SQL string
  // disabling this eslint error as it is common pattern when using regex.exec
  // eslint-disable-next-line no-cond-assign
  while ((match = regex.exec(sql)) !== null) {
    const [fullMatch, columnName] = match;

    // Convert the column name to camel case
    let camelCaseColumnName =
      columnName.charAt(0).toLowerCase() + columnName.slice(1);
    // If the column name ends with "ID", replace it with "Id"
    if (camelCaseColumnName.endsWith('ID')) {
      camelCaseColumnName = `${camelCaseColumnName.slice(0, -2)}Id`;
    }
    // Replace the column name in the SQL string
    sql = sql.replace(fullMatch, camelCaseColumnName);
  }

  return sql;
}

export const generateJsonFromSql = (sql: string) => {
  const query = parseSQL(`select * from t where ${convertToCamelCase(sql)}`);
  return convertQuery(query);
};

const operationLabels = {
  '=': 'equals to',
  '!=': 'not equals to',
  '<': 'less than',
  '<=': 'less than or equal to',
  '>': 'greater than',
  '>=': 'greater than or equal to',
} as const;

export const getOperators = (
  fieldName: string,
  {fieldData}: {fieldData: Field},
) => {
  switch (fieldData.inputType) {
    case 'number':
    case 'date':
      return [
        {name: '=', label: operationLabels['=']},
        {name: '!=', label: operationLabels['!=']},
        {name: '<', label: operationLabels['<']},
        {name: '<=', label: operationLabels['<=']},
        {name: '>', label: operationLabels['>']},
        {name: '>=', label: operationLabels['>=']},
        ...defaultOperators.filter(op =>
          ['null', 'notNull', 'between', 'notBetween'].includes(op.name),
        ),
      ];
    case 'boolean':
      return [
        {name: '=', label: operationLabels['=']},
        {name: '!=', label: operationLabels['!=']},
        ...defaultOperators.filter(op => ['null', 'notNull'].includes(op.name)),
      ];
    case 'string':
    default:
      return [
        {name: '=', label: operationLabels['=']},
        {name: '!=', label: operationLabels['!=']},
        {name: '<', label: operationLabels['<']},
        {name: '<=', label: operationLabels['<=']},
        {name: '>', label: operationLabels['>']},
        {name: '>=', label: operationLabels['>=']},
        ...defaultOperators,
      ];
  }
};
