import {components} from 'react-select';

export const SelectOptions = props => {
  const {custID, custName, companyID, assignUserKey} = props.data;
  return (
    <components.Option {...props}>
      <div className="d-flex justify-content-center w-100">
        <div className="w-300-px">
          {custID}: {custName}
        </div>
        <div className="px-2 width-100-px">{companyID}</div>
        <div className="px-2 width-100-px">{assignUserKey}</div>
      </div>
    </components.Option>
  );
};
