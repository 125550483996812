import {
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';

import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import {Button} from '../../../../ui/inputs';
import {constants} from '../../../../constants/common';

export default function CommunicateTransactionToolbar({
  handleSelectAll,
  onClearAll,
}: {
  handleSelectAll: () => void;
  onClearAll: () => void;
}) {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton componentsProps={{button: {disabled: true}}} />
      <Button
        aria-controls="dropdown-menu"
        startIcon={<DoneOutlineIcon />}
        onClick={handleSelectAll}
      >
        {constants.SELECT_ALL}
      </Button>
      <Button startIcon={<ClearAllIcon />} onClick={onClearAll}>
        {constants.CLEAR_ALL}
      </Button>
    </GridToolbarContainer>
  );
}
