import moment from 'moment';
import {Checkbox, Dropdown, InputText} from 'primereact';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {constants} from '../../../constants/common';
import {updateInvoiceDetails} from '../../../services/activities';
import {fetchInvoiceDetails} from '../../../services/communications';
import {getAllActiveContacts} from '../../../store/actions/activitiesActions';
import {
  getDisputeUDFInvoiceList,
  getUDFInvoiceList,
} from '../../../utils/udfList';
import Backdrop from '../../common/Backdrop';
import {getUserData} from '../../../store/actions/userActions';
import InvoiceDetailsTemplateTabs from '../InvoiceDetailsTemplateTabs';

function TaggedInvoiceDetailTemplate({
  invcKey,
  closeInvoiceDialog,
  workSessionId,
  selectedRow,
}) {
  const [invoiceDetails, setInvoiceDetails] = useState({});
  const toast = useSelector(store => store.generalReducer?.toastRef);
  const {options} = useSelector(store => store.usersReducer.userSettings);
  const [udfInvoiceList, setUdfInvoiceList] = useState([]);
  const [disputeUDFList, setDisputeUDFList] = useState({});
  const [loadingCommunicate, setLoadingCommunicate] = useState(false);
  const dispatch = useDispatch();
  const contactsDropDown = useSelector(store => {
    const {contactList} = store.activitiesReducer;
    return contactList.map(info => {
      return {label: info.contactName, value: info.contactKey};
    });
  });
  const {groupKey} = getUserData();
  const disputeCodes = useSelector(store => {
    const list = store.activitiesReducer.disputeCodes;
    const formatted = list.map(item => {
      return {label: item.disputeCode, value: item.disputeCodeKey};
    });
    return [{label: 'None', value: 'None'}, ...formatted];
  });
  const saveInvoiceDetail = keepInSync => {
    const request = {
      invcKey: invoiceDetails?.invcKey,
      balance: invoiceDetails?.balance?.toFixed(2),
      balanceHC: invoiceDetails?.balanceHc?.toFixed(2),
      closingTranDate: invoiceDetails?.closingTranDate,
      postTranDate: invoiceDetails?.postDate,
      invcContactKey: null,
      invcContactKey2: null,
      ovrdInvcContact1: invoiceDetails?.ovrdInvcContact1,
      ovrdInvcContact2: invoiceDetails?.ovrdInvcContact2,
      keepInSync,
      note: invoiceDetails?.disputeNote,
      UDF1: udfInvoiceList.udf1 ? udfInvoiceList.udf1.value : '',
      UDF2: udfInvoiceList.udf2 ? udfInvoiceList.udf2.value : '',
      UDF3: udfInvoiceList.udf3 ? udfInvoiceList.udf3.value : '',
      UDF4: udfInvoiceList.udf4 ? udfInvoiceList.udf4.value : '',
      UDF5: udfInvoiceList.udf5 ? udfInvoiceList.udf5.value : '',
      UDF6: udfInvoiceList.udf6 ? udfInvoiceList.udf6.value : '',
      UDF7: udfInvoiceList.udf7 ? udfInvoiceList.udf7.value : '',
      UDF8: udfInvoiceList.udf8 ? udfInvoiceList.udf8.value : '',
      UDF9: udfInvoiceList.udf9 ? udfInvoiceList.udf9.value : '',
      UDF10: udfInvoiceList.udf10 ? udfInvoiceList.udf10.value : '',
    };
    setLoadingCommunicate(true);
    updateInvoiceDetails(request)
      .then(() => {
        setLoadingCommunicate(false);
      })
      .catch(() => {
        setLoadingCommunicate(false);
        toast?.current.show({
          severity: 'warn',
          summary: constants.SOMETHING_WENT_WRONG,
          detail: '',
        });
      });
  };

  const handleOnInvoiceDetailsChanged = (key, value) => {
    setInvoiceDetails({...invoiceDetails, [key]: value});
  };

  const onHandleChange = (key, value) => {
    setInvoiceDetails(prevState => {
      return {...prevState, [key]: value};
    });
  };

  useEffect(() => {
    setLoadingCommunicate(true);
    fetchInvoiceDetails(invcKey, workSessionId)
      .then(response => {
        const resultObj = response;
        setInvoiceDetails(resultObj);
        const udfObject = getUDFInvoiceList(options, resultObj);
        setDisputeUDFList(getDisputeUDFInvoiceList(options, resultObj));
        setUdfInvoiceList(udfObject);

        const {custKey} = resultObj;
        dispatch(getAllActiveContacts({groupKey, custKey}));
        setLoadingCommunicate(false);
      })
      .catch(() => {
        setLoadingCommunicate(false);
        toast?.current.show({
          severity: 'warn',
          summary: constants.SOMETHING_WENT_WRONG,
          detail: '',
        });
      });
  }, []);

  return (
    <>
      <div className="mb-3 mt-1">
        {loadingCommunicate && <Backdrop />}
        <div>
          <span className="me-2">
            {constants.CUST_ID}: {`${invoiceDetails?.custId},`}
          </span>
          <span className="me-2">
            {constants.CUST_NAME}: {`${invoiceDetails?.custName},`}
          </span>
          <span>
            {constants.PO_NUMBER}: {invoiceDetails?.custPono}
          </span>
        </div>
      </div>
      <div className="border px-2">
        <div>
          <div className="mt-2 d-flex align-items-center ">
            <b className="me-3">
              {constants.INVOICE_INFORMATION.toLocaleUpperCase()}
            </b>
            <div>
              <span> {constants.EXCLUDE_SYNC}</span>
              <Checkbox
                className="ms-2"
                checked={invoiceDetails?.keepInSync}
                onChange={e => {
                  onHandleChange('keepInSync', e.checked);
                  saveInvoiceDetail(e.checked);
                }}
              />
            </div>
          </div>
          <div className="d-flex justify-content-between flex-wrap">
            <div className="d-flex justify-content-between align-items-center flex-column me-2 w-330-px">
              <div className="w-100 d-flex justify-content-between my-2">
                <label htmlFor="displayCompanyId" className="me-2">
                  {constants.COMPANY_ID}
                </label>
                <InputText
                  disabled
                  className="mx-2 w-120-px w-120-px input-text-custom"
                  id="displayCompanyId"
                  value={invoiceDetails?.displayCompanyId}
                />
              </div>

              <div className="w-100 d-flex justify-content-between my-2">
                <label htmlFor="tranId" className="me-2">
                  {constants.INVOICE_NUMBER}
                </label>
                <InputText
                  disabled
                  className="mx-2 w-120-px input-text-custom"
                  id="tranId"
                  value={invoiceDetails?.tranId}
                />
              </div>
              <div className="w-100 d-flex justify-content-between my-2">
                <label htmlFor="tranAmt" className="me-2">
                  {constants.AMOUNT}
                </label>
                <InputText
                  disabled
                  className="mx-2 w-120-px input-text-custom"
                  id="tranAmt"
                  value={invoiceDetails?.tranAmt?.toFixed(2)}
                />
              </div>
              <div className="w-100 d-flex justify-content-between my-2">
                <label htmlFor="dueDate" className="me-2">
                  {constants.DUE_DATE}
                </label>
                <InputText
                  disabled
                  className="mx-2 w-120-px input-text-custom"
                  id="dueDate"
                  value={
                    invoiceDetails?.dueDate
                      ? moment.utc(invoiceDetails?.dueDate).format('M/D/YYYY')
                      : ''
                  }
                />
              </div>
            </div>
            <div className=" d-flex justify-content-between align-items-center flex-column me-2 w-330-px">
              <div className="w-100 d-flex justify-content-between my-2">
                <label htmlFor="currId" className="me-2">
                  {constants.CURR_ID}
                </label>
                <InputText
                  disabled
                  className="mx-2 w-120-px input-text-custom"
                  id="currId"
                  value={invoiceDetails?.currId}
                />
              </div>
              <div className="w-100 d-flex justify-content-between my-2">
                <label htmlFor="tranDate" className="me-2">
                  {constants.INVOICE_DATE}
                </label>
                <InputText
                  disabled
                  className="mx-2 w-120-px input-text-custom"
                  id="tranDate"
                  value={
                    invoiceDetails?.tranDate
                      ? moment.utc(invoiceDetails?.tranDate).format('M/D/YYYY')
                      : ''
                  }
                />
              </div>
              <div className="w-100 d-flex justify-content-between my-2">
                <label htmlFor="balance" className="me-2">
                  {constants.BALANCE_DUE}
                </label>
                <InputText
                  disabled
                  className="mx-2 w-120-px input-text-custom"
                  id="balance"
                  value={invoiceDetails?.balance?.toFixed(2)}
                />
              </div>
              <div className="w-100 d-flex justify-content-between my-2">
                <label htmlFor="discDate" className="me-2">
                  {constants.DISC_DATE}
                </label>
                <InputText
                  disabled
                  className="mx-2 w-120-px input-text-custom"
                  id="discDate"
                  value={
                    invoiceDetails?.discDate
                      ? moment.utc(invoiceDetails?.discDate).format('M/D/YYYY')
                      : ''
                  }
                />
              </div>
            </div>
            <div className=" d-flex align-items-center flex-column me-2 w-330-px">
              <div className="w-100 d-flex justify-content-between my-2">
                <label htmlFor="status" className="me-2">
                  {constants.STATUS}
                </label>
                <InputText
                  disabled
                  className="mx-2 w-120-px input-text-custom"
                  id="status"
                  value={invoiceDetails?.status}
                />
              </div>
              <div className="w-100 d-flex justify-content-between my-2">
                <label htmlFor="closingTranDate" className="me-2">
                  {constants.CLOSING_DATE}
                </label>
                <InputText
                  disabled
                  className="mx-2 w-120-px input-text-custom"
                  id="closingTranDate"
                  value={
                    invoiceDetails?.closingTranDate
                      ? moment
                          .utc(invoiceDetails?.closingTranDate)
                          .format('M/D/YYYY')
                      : ''
                  }
                />
              </div>
              <div className="w-100 d-flex justify-content-between my-2">
                <label htmlFor="postDate" className="me-2">
                  {constants.POST_DATE}
                </label>
                <InputText
                  disabled
                  className="mx-2 w-120-px input-text-custom"
                  id="postDate"
                  value={
                    invoiceDetails?.postDate
                      ? moment.utc(invoiceDetails?.postDate).format('M/D/YYYY')
                      : ''
                  }
                />
              </div>
            </div>
          </div>

          <div className="row mt-2">
            <div className="col">
              <span className="my-1">
                {' '}
                {constants.overrideInvoiceContact(1)}{' '}
                <Checkbox
                  disabled
                  checked={invoiceDetails?.ovrdInvcContact1}
                  onChange={e =>
                    handleOnInvoiceDetailsChanged(
                      'ovrdInvcContact1',
                      e.target.value,
                    )
                  }
                />
              </span>
            </div>
            <div className="col">
              <span className="my-1">
                {' '}
                {constants.overrideInvoiceContact(2)}{' '}
                <Checkbox
                  disabled
                  checked={invoiceDetails?.ovrdInvcContact2}
                  onChange={e =>
                    handleOnInvoiceDetailsChanged(
                      'ovrdInvcContact2',
                      e.target.value,
                    )
                  }
                />
              </span>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col">
              <Dropdown
                disabled
                options={contactsDropDown}
                value={invoiceDetails?.invcContactKey}
                className="bg-purple w-25 me-4"
                placeholder={constants.SELECT}
              />
            </div>
            <div className="col">
              <Dropdown
                disabled
                options={contactsDropDown}
                value={invoiceDetails?.invcContactKey2}
                className="bg-purple w-25 me-4"
                placeholder={constants.SELECT}
              />
            </div>
          </div>
        </div>
        <div className="mt-2">
          <InvoiceDetailsTemplateTabs
            invoiceKey={invcKey}
            invoiceDetails={invoiceDetails}
            isTaggedInvoice
            udfInvoiceList={udfInvoiceList}
            setUdfInvoiceList={setUdfInvoiceList}
            saveInvoiceDetail={() =>
              saveInvoiceDetail(invoiceDetails?.keepInSync)
            }
            groupKey={groupKey}
            disputeCodes={disputeCodes}
            setInvoiceDetails={setInvoiceDetails}
            closeInvoiceDialog={closeInvoiceDialog}
            disputeUDFList={disputeUDFList}
            setDisputeUDFList={setDisputeUDFList}
            selectedRow={selectedRow[0]}
          />
        </div>
      </div>
    </>
  );
}
export default TaggedInvoiceDetailTemplate;
