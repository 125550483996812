import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  GridColumnVisibilityModel,
  GridFilterModel,
  GridLogicOperator,
  GridPaginationModel,
  GridSortModel,
} from '@mui/x-data-grid-pro';
import {
  activityOverviewExportToExcel,
  cleanUpActivitiesOverviewData,
  getAllActivities,
  updateAccountActivityViewSettingObject,
} from '../../../store/actions/activitiesOverviewActions';
import {
  DataGrid,
  FilterPopoverProvider,
  QueryReturnValue,
  convertMUIToQueryBuilder,
  generateJsonAndSql,
  generateJsonFromSql,
} from '../../../ui/data';
import {accountOverviewActivitiesColumns} from './columns';
import {constants, gridModeTypes} from '../../../constants/common';
import {ViewSettings} from './ViewSettings';
import {getUserData} from '../../../store/actions/userActions';
import {
  applyDatatableSettings,
  convertAndAppendExistingFilters,
  getGridColumnsSettings,
  getSelectedRowsByKey,
  hasFilterValue,
  updateColumnSettings,
} from '../../../lib/commonTableHelpers';
import ActivitiesToolbar from './ActivitiesToolbar';
import {useQueryKeys} from '../../../hooks/useQueryKeys';
import {useSessionStorage} from '../../../hooks/useSessionStorage';
import {PAGE_SIZE} from '../../../utils/constants';
import {Typography} from '../../../ui/displays';
import {Box} from '../../../ui/layouts';
import {initialQuery} from '../../../ui/data/query-builder/queryHelper';
import ViewSettingsModal from '../../common/view-settings/ViewSettingsModal';
import {
  ColumnSetting,
  IViewSettings,
} from '../../common/view-settings/interface';
import {useComponentMountStatus} from '../../../hooks/useComponentMountStatus';
import {shouldResetFilterModel} from '../../../utils/filterUtils';

interface IProps {
  refresh: boolean;
  setRefresh: (value: boolean) => void;
  showToolBars?: boolean;
}
interface GetActivitiesParams {
  settingKey: string;
  isArchived: boolean;
  sortQuery: GridSortModel;
  filterQuery: QueryReturnValue['json'] | undefined;
  sqlQuery: string | null;
  skipParam: number;
  pageSizeParam: number;
  isShowingAllHighlightedActivitiesParam: boolean;
}
export const ActivitiesOverview = ({
  refresh,
  setRefresh,
  showToolBars,
}: IProps) => {
  const dispatch = useDispatch();
  const {groupKey} = getUserData();
  const [sortColumn, setSortColumn] = useState<GridSortModel>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [isShowingArchivedActivities, setIsShowingArchivedActivities] =
    useState(false);
  const [isShowingAllHighlightedActivities, setShowAllHighlightedActivities] =
    useState(false);
  const [selectedActivities, setSelectedActivities] = useState<any[]>([]);
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({});
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    logicOperator: GridLogicOperator.And,
    items: [],
  });
  const [customFilterSqlValue, setCustomFilterSqlValue] = useState<
    string | null
  >(null);
  const [customFilterJsonValue, setCustomFilterJsonValue] =
    useState<QueryReturnValue['json']>();

  const [skip, setSkip] = useState(0);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: PAGE_SIZE,
  });
  const [settingsKey, setSettingsKey] = useState<string | null>(null);

  const isComponentMounted = useComponentMountStatus(
    cleanUpActivitiesOverviewData,
  );

  const {
    activitiesOverview,
    activitiesOverviewCount,
    activitiesOverviewLoading,
    fetchingActivitiesOverview,
    isEmailTemplateActivities,
    settingId,
    viewSettings,
  } = useSelector((store: any) => store.activitiesOverviewReducer);
  const selectedAccount = useSelector(
    (store: any) => store.generalReducer.selectedCustomer,
  );

  const {userKey, custKey: customerKey} = useQueryKeys();

  const custKey: number = customerKey ?? selectedAccount?.custKey;

  const [selectedCustomer, setSelectedCustomer] = useState(custKey);
  const [{userId}] = useSessionStorage('profile');
  const [showViewSettingsPopup, setShowViewSettingsPopup] =
    useState<boolean>(false);

  const getAccountOverViewActivities = ({
    isArchived = false,
    settingKey,
    sortQuery,
    pageSizeParam = PAGE_SIZE,
    skipParam = 0,
    isShowingAllHighlightedActivitiesParam = false,
    filterQuery = undefined,
    sqlQuery = null,
  }: {
    isArchived: boolean;
    settingKey: string;
    sortQuery: any[];
    pageSizeParam: number;
    skipParam: number;
    isShowingAllHighlightedActivitiesParam: boolean;
    filterQuery: QueryReturnValue['json'] | undefined;
    sqlQuery: string | null;
  }) => {
    dispatch(
      // @ts-ignore
      getAllActivities({
        custKey: selectedCustomer,
        taskKey: isShowingAllHighlightedActivitiesParam
          ? selectedActivities[0]?.taskKey
          : -1,
        userKey,
        settingKey,
        sortQuery,
        isArchived: isArchived ? 1 : 0,
        userId,
        groupKey,
        skip: skipParam,
        pageSize: pageSizeParam,
        isEmailTemplate: false,
        filterQuery,
        sqlQuery,
      }),
    );
  };

  // reset pagination
  const resetPagination = () => {
    setPaginationModel({
      pageSize: paginationModel.pageSize,
      page: 0,
    });
  };

  const handleGetAllActivities = (
    filterQuery: QueryReturnValue['json'],
    sqlQuery: string | null,
  ) => {
    const params: GetActivitiesParams = {
      settingKey: settingId,
      sortQuery: sortColumn,
      isArchived: isShowingArchivedActivities,
      filterQuery,
      sqlQuery,
      skipParam: skip,
      pageSizeParam: paginationModel.pageSize,
      isShowingAllHighlightedActivitiesParam: isShowingAllHighlightedActivities,
    };
    getAccountOverViewActivities(params);
    resetPagination();
  };

  /** Use effect to update and set columns based on view settings response */
  useEffect(() => {
    if (viewSettings && viewSettings.settingsKey && isComponentMounted) {
      applyDatatableSettings(
        viewSettings,
        columns,
        accountOverviewActivitiesColumns,
        undefined,
        setColumns,
        setColumnVisibilityModel,
        setSortColumn,
      );
      const jsonData =
        viewSettings?.filter?.length > 0
          ? generateJsonFromSql(viewSettings?.filter)
          : initialQuery;

      setSettingsKey(viewSettings.settingsKey);
      setCustomFilterSqlValue(viewSettings?.filter);
      setCustomFilterJsonValue(jsonData);
    }
  }, [viewSettings?.settingsKey]);

  // Reset filter model
  const resetFilterModel = () => {
    setFilterModel({
      logicOperator: GridLogicOperator.And,
      items: [],
    });
  };

  /** Use Effect to get list of activities */
  useEffect(() => {
    if (
      (!fetchingActivitiesOverview &&
        !isEmailTemplateActivities &&
        settingsKey) ||
      (refresh && settingsKey)
    ) {
      getAccountOverViewActivities({
        settingKey: settingsKey,
        sortQuery: sortColumn,
        isArchived: isShowingArchivedActivities,
        skipParam: 0,
        pageSizeParam: paginationModel.pageSize,
        isShowingAllHighlightedActivitiesParam:
          isShowingAllHighlightedActivities,
        filterQuery: customFilterJsonValue,
        sqlQuery: customFilterSqlValue,
      });
      resetPagination();
      resetFilterModel();
    }
    if (refresh) {
      setRefresh(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomer, refresh, settingsKey]);

  /** On customer select reset highlighted and archived activity filters */
  useEffect(() => {
    if (custKey) {
      setSelectedActivities([]);
      setShowAllHighlightedActivities(false);
      setIsShowingArchivedActivities(false);
      setSelectedCustomer(custKey);
    }
  }, [custKey]);

  /** Activities overview grid props method */

  /**
   * onPageChange is prop set for Activities overview grid to handle pagination
   * @param args GridPaginationModel object which has page which starts from 0 and skip properties
   */
  const onPageChange = (args: GridPaginationModel) => {
    const pageSkip =
      args.pageSize !== paginationModel.pageSize
        ? 0
        : (args.page + 1) * args.pageSize - args.pageSize;
    setPaginationModel({
      pageSize: args.pageSize,
      page: args.pageSize !== paginationModel.pageSize ? 0 : args.page,
    });
    setSkip(pageSkip);
    getAccountOverViewActivities({
      settingKey: settingId,
      sortQuery: sortColumn,
      isArchived: isShowingArchivedActivities,
      filterQuery: customFilterJsonValue,
      sqlQuery: customFilterSqlValue,
      skipParam: pageSkip,
      pageSizeParam: args.pageSize,
      isShowingAllHighlightedActivitiesParam: isShowingAllHighlightedActivities,
    });
  };
  /**
   * onSortChange is prop set for Account overview activities grid to sorting
   * @param args GridPaginationModel array of object which field and sort properties
   */
  const onSortChange = (args: GridSortModel) => {
    setSortColumn(args);
    getAccountOverViewActivities({
      settingKey: settingId,
      sortQuery: args,
      isArchived: isShowingArchivedActivities,
      skipParam: skip,
      pageSizeParam: paginationModel.pageSize,
      isShowingAllHighlightedActivitiesParam: isShowingAllHighlightedActivities,
      filterQuery: customFilterJsonValue,
      sqlQuery: customFilterSqlValue,
    });
  };

  const onHandleHighlightSelected = (flag: boolean) => {
    setShowAllHighlightedActivities(flag);
    getAccountOverViewActivities({
      settingKey: settingId,
      sortQuery: sortColumn,
      isArchived: isShowingArchivedActivities,
      filterQuery: customFilterJsonValue,
      sqlQuery: customFilterSqlValue,
      skipParam: 0,
      pageSizeParam: paginationModel.pageSize,
      isShowingAllHighlightedActivitiesParam: flag,
    });
    resetPagination();
  };

  /**
   * onColumnRowSelect Update state on checkbox selection
   * @param newSelectionModel is selected row object
   */
  const onColumnRowSelect = (newSelectionModel: any) => {
    const selectedRowsObject = getSelectedRowsByKey(
      newSelectionModel,
      activitiesOverview,
      'taskActKey',
    );
    if (selectedRowsObject.length === 0 && isShowingAllHighlightedActivities) {
      onHandleHighlightSelected(false);
    }
    setSelectedActivities(selectedRowsObject);
  };

  /**
   * View settings callback methods
   */

  const onHandleArchivedSelected = (flag: boolean) => {
    setIsShowingArchivedActivities(flag);
    getAccountOverViewActivities({
      settingKey: settingId,
      sortQuery: sortColumn,
      isArchived: flag,
      filterQuery: customFilterJsonValue,
      sqlQuery: customFilterSqlValue,
      skipParam: 0,
      pageSizeParam: paginationModel.pageSize,
      isShowingAllHighlightedActivitiesParam: isShowingAllHighlightedActivities,
    });
    resetPagination();
  };

  /** All Toolbar methods */

  const onRefresh = () => {
    getAccountOverViewActivities({
      settingKey: settingId,
      sortQuery: sortColumn,
      isArchived: isShowingArchivedActivities,
      filterQuery: customFilterJsonValue,
      sqlQuery: customFilterSqlValue,
      skipParam: skip,
      pageSizeParam: paginationModel.pageSize,
      isShowingAllHighlightedActivitiesParam: isShowingAllHighlightedActivities,
    });
  };

  /**
   * onFilterChange is prop set for communication grid to filter
   * @param args GridFilterModel array of object which field, value and operator properties
   */
  const onFilterChange = (args: GridFilterModel) => {
    const queryBuilderQuery = convertMUIToQueryBuilder(args);
    const valueToConvert = convertAndAppendExistingFilters(
      {...customFilterJsonValue},
      queryBuilderQuery,
      filterModel,
    );
    const {json, sql} = generateJsonAndSql(valueToConvert);
    setCustomFilterSqlValue(json.rules.length > 0 ? sql : null);
    setCustomFilterJsonValue(json);
    setFilterModel(args);
    if (hasFilterValue(args)) {
      handleGetAllActivities(json, json.rules.length > 0 ? sql : null);
    }
  };

  const onQueryFilterApply = (data: QueryReturnValue) => {
    // if filter is empty then reset filter model
    if (shouldResetFilterModel(data)) {
      resetFilterModel();
    }
    setCustomFilterSqlValue(data.sql);
    setCustomFilterJsonValue(data.json);
    handleGetAllActivities(
      data.json,
      data.json.rules.length > 0 ? data.sql : null,
    );
  };

  const exportToExcel = () => {
    dispatch(
      // @ts-ignore
      activityOverviewExportToExcel({
        custKey,
        taskKey: isShowingAllHighlightedActivities
          ? selectedActivities[0]?.taskKey
          : -1,
        userKey,
        groupKey,
        isArchived: isShowingArchivedActivities ? 1 : 0,
        settingKey: settingId,
      }),
    );
  };

  const updateTableOnColumnSettingsChange = (
    updatedColumnsList: ColumnSetting[],
    settingKey: string,
  ) => {
    if (settingKey === viewSettings?.settingsKey) {
      updateColumnSettings(
        updatedColumnsList,
        columns,
        setColumns,
        setColumnVisibilityModel,
      );
    }
  };

  const onViewSettings = () => {
    setShowViewSettingsPopup(true);
  };

  const handleCloseViewSettingsPopup = () => {
    setShowViewSettingsPopup(false);
  };

  const {columnSettingJson, sortSettingJson} = getGridColumnsSettings(
    viewSettings?.columnSetting,
    columnVisibilityModel,
    sortColumn,
  );

  return (
    <Box marginTop={3}>
      <Typography
        variant="h4"
        fontWeight={500}
        fontSize="24px"
        style={{color: '#0F3044'}}
      >
        {constants.ACTIVITIES}
      </Typography>
      <ViewSettingsModal
        viewName="Accounts/Communication"
        mode={gridModeTypes.ActivitiesForCustomerAndUser}
        updateTableOnColumnSettingsChange={updateTableOnColumnSettingsChange}
        handleCloseViewSettingsPopup={handleCloseViewSettingsPopup}
        customFilterSqlValue={customFilterSqlValue || ''}
        sortSettingJson={JSON.stringify(sortSettingJson) || ''}
        columnSettingJson={JSON.stringify(columnSettingJson) || ''}
        loadViewSetting={(row: IViewSettings) => {
          dispatch(
            updateAccountActivityViewSettingObject({
              settingId: row.settingsKey,
              viewSettings: row,
            }),
          );
        }}
        showViewSettingsPopup={showViewSettingsPopup}
      />
      <ViewSettings
        onHandleArchivedSelected={onHandleArchivedSelected}
        onHandleHighlightSelected={onHandleHighlightSelected}
        isShowingArchivedActivities={isShowingArchivedActivities}
        isShowingAllHighlightedActivities={isShowingAllHighlightedActivities}
        isRowSelected={selectedActivities.length > 0}
        isSingleRowSelected={selectedActivities.length === 1}
      />
      <FilterPopoverProvider>
        <DataGrid
          height={600}
          columns={columns}
          rows={activitiesOverview}
          rowCount={activitiesOverviewCount}
          onPageChange={onPageChange}
          loading={activitiesOverviewLoading}
          sortModel={sortColumn}
          onSortChange={onSortChange}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityChange={data => setColumnVisibilityModel(data)}
          getRowId={row => row.taskActKey}
          rowsSelected={selectedActivities.map(item => item.taskActKey)}
          checkboxSelection
          onColumnRowSelect={onColumnRowSelect}
          CustomToolbar={showToolBars ? ActivitiesToolbar : undefined}
          customToolbarMethods={
            showToolBars && {
              onRefresh,
              exportToExcel,
              onViewSettings,
            }
          }
          onFilterChange={onFilterChange}
          filterModel={filterModel}
          paginationModel={paginationModel}
          showCustomFilters
          customFilterSqlValue={customFilterSqlValue}
          onQueryFilterApply={data => onQueryFilterApply(data)}
        />
      </FilterPopoverProvider>
    </Box>
  );
};
