import React, {useState} from 'react';
import {constants} from '../../../constants/common';
import OutsideAlerter from '../../../hooks/OutsideAlerter';
import {TotalAmountTypography} from '../../common/css/Styles';

export function AccountSummary({accountSummary}: {accountSummary: any}) {
  const [showTooltip, setShowTooltip] = useState(false);
  return (
    <div className="position-relative">
      {showTooltip && (
        <div className="customTooltip">
          <span className="text">{accountSummary?.tooltipText}</span>
        </div>
      )}
      <TotalAmountTypography>
        <b>{constants.TOTAL_AMOUNT}</b>{' '}
        {(accountSummary?.totalAmountHC || 0).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </TotalAmountTypography>
      <TotalAmountTypography>
        <b>{constants.TOTAL_BALANCE}</b>{' '}
        {(accountSummary?.totalBalanceHC || 0).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </TotalAmountTypography>
      <TotalAmountTypography
        style={{
          textTransform: 'none',
        }}
      >
        {constants.USUALLY_PAYS} <b>{accountSummary?.avgDaystext}</b>{' '}
        <b>
          <OutsideAlerter
            setShowTooltip={setShowTooltip}
            showTooltip={showTooltip}
          >
            <i
              className="pi pi-info pi-custom"
              onClick={() => setShowTooltip(true)}
              role="presentation"
            />
          </OutsideAlerter>
        </b>
      </TotalAmountTypography>
    </div>
  );
}
