import './header.css';
import {Dropdown, FileUpload} from 'primereact';
import {FiInbox, FiStar, FiMessageSquare} from 'react-icons/fi';
import {RiArrowDownSLine} from 'react-icons/ri';
import {TbUpload} from 'react-icons/tb';
import {CgProfile} from 'react-icons/cg';
import {memo, useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import SummarizeIcon from '@mui/icons-material/Summarize';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import ArticleIcon from '@mui/icons-material/Article';
import InfoIcon from '@mui/icons-material/Info';
import {Tooltip} from '@mui/material';
import {constants} from '../../constants/common';
import {getConfig, isEmpty} from '../../lib/utils';
import {
  getManagedUsers,
  getUserData,
  getUserSettings,
} from '../../store/actions/userActions';
import {fetchCustomerInfoListByUserKey} from '../../services/customers';
import {SelectFooter} from '../common/select/SelectFooter';
import {AsyncSelect} from '../common/select/AsyncSelect';
import {SelectHeader} from '../common/select/SelectHeader';
import {SelectOptions} from './SelectOptions';
import {
  getJwtToken,
  handleAddOrRemoveFromFavorites,
  updateSelectedCustomer,
  updateShowCustomerInfo,
} from '../../store/actions/generalActions';
import {
  getMessagesCount,
  getNewNotificationDetails,
  updateNotificationAsRead,
} from '../../services/application';
import {MODE_TYPES} from '../../utils/constants';
import {CustomerInfoContainer} from './CustomerInfoContainer';
import {Dialog} from '../../ui/feedback';
import Button from '../../ui/inputs/button/Button';
import {uploadFilesToBlobStorage} from '../../services/files';
import {StorageType} from '../../constants/typeConstants';
import Backdrop from '../../ui/utils/backdrop/Backdrop';
import ViewMessages from './view-messages/ViewMessages';
import {useQueryKeys} from '../../hooks/useQueryKeys';
import {fetchUserByUserKey} from '../../services/users';
import {useSessionStorage} from '../../hooks/useSessionStorage';
import {OpenDocs} from '../communicate/open-docs/OpenDocs';
import {Checkbox, Select} from '../../ui/inputs';
import {Box} from '../../ui/layouts';

export const Header = memo(() => {
  const fileUploadRef = useRef();
  const {userKey} = useQueryKeys();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams(location.search);
  const [showCustomerDocs, setShowCustomerDocs] = useState(false);
  const {
    toastRef: toast,
    addingToFavorite,
    selectedCustomer,
    showCustomerInfo,
    jwtTokenData,
  } = useSelector(store => store.generalReducer);
  const custId = selectedCustomer?.custId ?? null;
  const custKey = selectedCustomer?.custKey ?? null;
  const custName = selectedCustomer?.custName ?? null;

  const [showFileUploadDialog, setShowFileUploadDialog] = useState(false);
  const [loadingUploadDialog, setLoadingUploadDialog] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [allowZeroBalance, setAllowZeroBalance] = useState(false);
  const [totalRecordCount, setTotalRecordCount] = useState();
  const [selectedUser, setSelectedUser] = useState('');
  const [users, setUsers] = useState([]);
  const [messagesCount, setMessagesCount] = useState(0);
  const [showViewMessageDialog, setShowViewMessageDialog] = useState(false);
  const {users: usersList} = useSelector(store => store.usersReducer);
  const baseRedirectUrl = getConfig().webClientRedirectUrl;
  const {sessionId} = getUserData() || {sessionId: ''};
  const [{groupKey, userId, sessionUserKey, isReactLogin}, setSessionStorage] =
    useSessionStorage('profile');
  const contactsUrl = `${baseRedirectUrl}/(S(${sessionId}))/Pages/Contacts.aspx`;
  const settingsOptions = [
    {
      label: constants.SETTINGS,
      value: `${baseRedirectUrl}/(S(${sessionId}))/Pages/MyProfile.aspx`,
    },
    {
      label: constants.LOGOUT,
      value: `${baseRedirectUrl}/(S(${sessionId}))/login/login.aspx?IsReactLogout=true`,
    },
  ];

  const newActivityDdOptions = [
    {label: constants.NEW_ACTIVITY, value: 'newActivity'},
    {
      label: constants.ACCOUNT_DETAILS_OR_NEW_ACTIVITY,
      value: 'accountDetailOrNewActivity',
    },
  ];
  const CreateQueryString = (tabTitle, customerData) => {
    let queryString = '';
    if (tabTitle === 'newActivity') {
      queryString = location.pathname.toString().includes('activity')
        ? `?CustKey=${customerData.custKey}&Mode=UnreadMailAlert&AssignedUserKey=${userKey}&AlertDesc=MailNotRead&SettingID=~All&OEM=LS&activityId=-1`
        : `/activity?CustKey=${customerData.custKey}&Mode=UnreadMailAlert&AssignedUserKey=${userKey}&AlertDesc=MailNotRead&SettingID=~All&OEM=LS&activityId=-1`;
    } else if (tabTitle === 'accountDetailOrNewActivity') {
      queryString = `/activity?CustKey=${customerData.custKey}&Mode=UnreadMailAlert&AssignedUserKey=${userKey}&AlertDesc=MailNotRead&SettingID=~All&OEM=LS&activityId=-1`;
    }
    return queryString;
  };
  const naviagateOnNewActivity = (tabTitle, customerData) => {
    if (tabTitle === 'newActivity') {
      const communicateProps = {
        ...customerData,
        tabName: 'activity',
        newActivity: true,
        ModeType: MODE_TYPES.new,
        activityList: [],
        taskKey: -1,
        taskActKey: -1,
      };
      navigate(
        `${location.pathname}${CreateQueryString(tabTitle, customerData)}`,
        {
          state: {
            custName: customerData.custName,
            groupKey,
            custId: customerData.custId || customerData.custID,
            companyId: customerData.companyId,
            selectCompanyId: customerData.displayCompanyID,
            from: location,
            ...communicateProps,
          },
        },
      );
    } else {
      const communicateProps = {
        ...customerData,
        newActivity: true,
        ModeType: MODE_TYPES.new,
        tabName: 'activity',
      };
      navigate(
        `/account-overview${CreateQueryString(tabTitle, customerData)}`,
        {
          state: {
            custName: customerData.custName,
            groupKey,
            custId: customerData.custId || customerData.custID,
            taskKey: -1,
            taskActKey: -1,
            companyId: customerData.companyId,
            selectCompanyId: customerData.displayCompanyID,
            ...communicateProps,
          },
        },
      );
    }
  };

  let checkForFavoriteIconFill = '#FFFFFF';
  const checkMailNotFiledRoute = window.location.pathname
    .replace('/activity', '')
    .includes('mail-not-filed');

  const handleOnProfileChanges = path => {
    if (path.includes('login/login.aspx?IsReactLogout=true')) {
      window.localStorage.clear();
      window.sessionStorage.clear();
      if (isReactLogin) {
        navigate('/login');
        return;
      }
    }
    window.location.href = path;
  };

  const loadOptions = useCallback(
    async (searchQuery, loadedOptions, {page}) => {
      const pageSize = 20;
      const request = {top: pageSize, skip: page};
      const options = {
        perspectiveId: groupKey,
        balance: allowZeroBalance,
        request: searchQuery,
      };
      const res = await fetchCustomerInfoListByUserKey(
        userKey,
        options,
        request,
      );
      const totalRecordsLoaded =
        Number(loadedOptions.length) + res.data.value[0].customers.length;

      setTotalRecordCount(res.data.count);
      setCurrentPage(() => totalRecordsLoaded);
      return {
        options: [...res.data.value[0].customers],
        hasMore: totalRecordsLoaded < res.data.count,
        additional: {
          page: Number(page) + pageSize,
        },
      };
    },
    [allowZeroBalance],
  );

  const handleBalanceOnChecked = value => {
    setAllowZeroBalance(value);
  };

  const fetchMessagesCount = () => {
    getMessagesCount(userId)
      .then(res => {
        setMessagesCount(res.data);
      })
      .catch(() => {
        toast?.current.show({
          severity: 'warn',
          summary: constants.SOMETHING_WENT_WRONG,
          detail: '',
        });
      });
  };

  const fetchNotificationInfo = async () => {
    try {
      const res = await getNewNotificationDetails(userId);
      if (res.data) {
        fetchMessagesCount();
        await updateNotificationAsRead(userId);
        toast?.current.show({
          severity: 'info',
          summary:
            res.data?.message ?? constants.NEW_ALERT_MESSAGE_NOTIFICATION,
          detail: '',
        });
      }
    } catch (error) {
      toast?.current.show({
        severity: 'warn',
        summary: constants.SOMETHING_WENT_WRONG,
        detail: '',
      });
    }
  };

  useEffect(() => {
    let intervalId;
    if (toast) {
      // Fetch data initially when the component mounts
      fetchNotificationInfo();

      // Set up the interval to fetch data every 10 seconds
      intervalId = setInterval(fetchNotificationInfo, 30000);
    }

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [toast]);

  useEffect(() => {
    if (usersList) {
      setUsers(usersList);
      const managedUser = usersList.find(obj => {
        return obj.mgdUserKey === Number(userKey);
      });
      setSelectedUser(managedUser ?? null);
    }
  }, [usersList, users, userKey]);

  useEffect(() => {
    dispatch(getManagedUsers({userKey: sessionUserKey}));
    dispatch(getUserSettings({userKey, groupKey}));
  }, []);

  useEffect(() => {
    fetchMessagesCount();
  }, [userId]);

  const displayDialogOnClick = () => {
    setShowFileUploadDialog(true);
  };

  const hideDialog = () => {
    setShowFileUploadDialog(false);
  };

  const onHandleFileUpload = async event => {
    const {files} = event;
    if (files) {
      const request = {
        StorageType: StorageType.private,
        BlobPath: `${groupKey}/invoices`,
        GroupKey: groupKey,
        Files: files,
        AddToQueue: true,
        UserId: userId,
      };
      setLoadingUploadDialog(true);
      try {
        await uploadFilesToBlobStorage(request);
        setLoadingUploadDialog(false);
        hideDialog();
        toast?.current.show({
          severity: 'success',
          summary: constants.SUCCESS,
          detail: constants.FILE_UPLOADED_SUCCESSFULLY,
        });
        fileUploadRef.current.clear();
      } catch (error) {
        setLoadingUploadDialog(false);
        toast?.current.show({
          severity: 'warn',
          summary: constants.FILE_UPLOADING_FAILED,
          detail: '',
        });
      }
    }
  };

  const addOrRemovePageFromFavorite = async () => {
    let requestObj = {userId};

    if (location.pathname.includes('account-overview')) {
      requestObj = {
        ...requestObj,
        payload: {SideNavItemURL: `AccountOverview.aspx${location.search}`},
      };
    }
    dispatch(handleAddOrRemoveFromFavorites(requestObj));
  };

  if (addingToFavorite) {
    checkForFavoriteIconFill = '#F38923';
  }
  const handleOnCustomerSelect = customer => {
    const queryString = `/account-overview?CustKey=${customer.custKey}&AssignedUserKey=${customer.userKey}&SettingID=~All&OEM=LS`;
    const communicateProps = {...customer, tabName: 'activity'};
    navigate(queryString, {
      state: {
        custName: customer.custName,
        custId: customer.custId || customer.custID,
        companyId: customer.companyId,
        selectCompanyId: customer.displayCompanyID,
        ...communicateProps,
      },
      replace: false,
    });
    dispatch(updateSelectedCustomer(customer));
  };

  const handleShowViewMessages = (val = true) => {
    setShowViewMessageDialog(val);
  };

  const handleDocumentNavigation = () => {
    setShowCustomerDocs(true);
  };

  const handleOverviewNavigation = () => {
    navigate(
      `account-overview?CustKey=${custKey}&AssignedUserKey=${userKey}&SettingID=~All&OEM=LS`,
    );
  };

  const updateUserQueryParam = async paramValue => {
    const {mgdUserKey} = paramValue;
    const {data} = await fetchUserByUserKey({userKey: mgdUserKey});
    const {userAccountKey, userName} = data.user;
    const profile = JSON.parse(sessionStorage.getItem('profile'));
    if (profile) {
      profile.userId = userAccountKey;
      profile.userKey = mgdUserKey;
      profile.username = userName;
      setSessionStorage(profile);
    }
    const newSearchParams = new URLSearchParams(location.search);
    newSearchParams.set('AssignedUserKey', mgdUserKey);
    setSearchParams(newSearchParams);
  };

  const RedirectToZendeskSupport = async event => {
    event.preventDefault();
    await dispatch(getJwtToken());

    if (jwtTokenData) {
      document.getElementById('jwtInput').value = jwtTokenData;
      document.forms.jwtForm.submit();
    }
  };
  return (
    <>
      <div className="bg-header header-com">
        <Dialog
          open={showViewMessageDialog}
          title={constants.VIEW_MESSAGES}
          onClose={() => handleShowViewMessages(false)}
          maxWidth="lg"
          fullWidth
        >
          <ViewMessages />
        </Dialog>
        <Dialog
          open={showFileUploadDialog}
          onClose={hideDialog}
          title={constants.MANUAL_PDF_UPLOAD}
          maxWidth="md"
          fullWidth
        >
          <div className="position-relative">
            <Backdrop open={loadingUploadDialog} />
            <FileUpload
              ref={fileUploadRef}
              mode="advanced"
              auto
              name="upload"
              chooseLabel={constants.BROWSE}
              accept=".pdf"
              maxFileSize={25000000}
              customUpload
              uploadHandler={onHandleFileUpload}
            />
          </div>
        </Dialog>
        <div className="d-flex justify-content-between align-items-center mb-2 flex-wrap">
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <div>
              <FiStar
                size={22}
                className={`gold-star-icon cursor-pointer ${
                  checkMailNotFiledRoute ? 'd-none' : ''
                }`}
                onClick={addOrRemovePageFromFavorite}
                title={constants.ADD_TO_FAVORITE}
                fill={checkForFavoriteIconFill}
              />
            </div>
            <div className="mx-3">
              <Checkbox
                title={constants.INCLUDE_CUSTOMERS_WITH_ZERO_BALANCE}
                checked={allowZeroBalance}
                onChange={e => handleBalanceOnChecked(e.target.checked)}
              />
            </div>
            <div>
              <RiArrowDownSLine
                className="mx-1 arrow-icon"
                color="#7D4ED8"
                onClick={() => {
                  if (!isEmpty(selectedCustomer)) {
                    dispatch(updateShowCustomerInfo(!showCustomerInfo));
                  }
                }}
              />
            </div>
            <div className="align-items-center position-relative mx-1">
              <AsyncSelect
                options={loadOptions}
                displayOnModal
                onChange={customer => {
                  handleOnCustomerSelect(customer);
                }}
                getOptionLabel={option =>
                  `${option.custID || option.custId}: ${option.custName}`
                }
                Header={<SelectHeader />}
                Footer={
                  <SelectFooter
                    page={currentPage}
                    totalRecord={totalRecordCount}
                  />
                }
                customComponents={{
                  Option: SelectOptions,
                }}
                cacheUniqs={[allowZeroBalance]}
                value={selectedCustomer}
              />
            </div>
            {selectedCustomer && (
              <div className="header-dropdown mx-2">
                <Dropdown
                  title={constants.MESSAGES}
                  dropdownIcon=""
                  optionLabel="label"
                  options={newActivityDdOptions}
                  className="bg-transparent border-0"
                  placeholder={<FiMessageSquare size={22} color="#178087" />}
                  onChange={e => {
                    naviagateOnNewActivity(e.target.value, selectedCustomer);
                  }}
                />
              </div>
            )}
            <div className="mx-2">
              <TbUpload
                title={constants.UPLOAD}
                size={22}
                className="mx-1 mb-2 green-icon"
                onClick={displayDialogOnClick}
              />
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center gap-2">
            <div className="centered-icon">
              <form
                id="jwtForm"
                method="post"
                action="https://lockstep.zendesk.com/access/jwt?&return_to=https://locktep.zendesk.com/hc/en-us"
                target="_blank"
              >
                <input id="jwtInput" type="hidden" name="jwt" />
              </form>
              <Tooltip title={constants.SUPPORT_FAQ}>
                <InfoIcon
                  className="info-icon"
                  onClick={RedirectToZendeskSupport}
                />
              </Tooltip>
            </div>
            {users.length !== 1 && (
              <div className="w-200-px">
                <Select
                  label={constants.SELECT_USER_TO_ASSIGN}
                  items={usersList.map(item => {
                    return {label: item.userName, value: item.mgdUserKey};
                  })}
                  value={selectedUser ? selectedUser.mgdUserKey : ''}
                  onChange={e => {
                    const newSelectedUser = usersList.find(
                      item => item.mgdUserKey === e.target.value,
                    );
                    setSelectedUser(newSelectedUser);
                    updateUserQueryParam(newSelectedUser);
                  }}
                  fullWidth
                />
              </div>
            )}
            <div className="mt-2">
              <FiInbox
                title={constants.INBOX}
                size={22}
                className="ms-2 me-1 green-icon"
                onClick={handleShowViewMessages}
              />
              <span className="count-badge">{messagesCount}</span>
            </div>
            <div className="header-dropdown">
              <Dropdown
                dropdownIcon=""
                title={constants.PROFILE}
                optionLabel="label"
                options={settingsOptions}
                className="bg-transparent border-0"
                placeholder={
                  <span className="black">
                    <CgProfile size={40} color="#3e5969" />
                  </span>
                }
                onChange={e => {
                  handleOnProfileChanges(e.value);
                }}
              />
            </div>
          </div>
        </div>
        <Box display="flex">
          {selectedCustomer && (
            <>
              <Button
                startIcon={<SummarizeIcon />}
                type="button"
                onClick={handleOverviewNavigation}
                sx={{
                  paddingBottom: '0',
                  fontWeight: 500,
                  fontSize: '14px',
                }}
              >
                {constants.OVERVIEW}
              </Button>
              <Button
                startIcon={<ContactPageIcon />}
                type="button"
                onClick={() => {
                  window.location.href = contactsUrl;
                }}
                sx={{
                  paddingBottom: '0',
                  fontWeight: 500,
                  fontSize: '14px',
                }}
              >
                {constants.CONTACTS}
              </Button>
              <Button
                startIcon={<ArticleIcon />}
                type="button"
                onClick={handleDocumentNavigation}
                sx={{
                  paddingBottom: '0',
                  fontWeight: 500,
                  fontSize: '14px',
                }}
              >
                {constants.DOCUMENTS}
              </Button>
            </>
          )}
        </Box>
      </div>
      {showCustomerInfo && <CustomerInfoContainer />}
      {showCustomerDocs && (
        <OpenDocs
          groupKey={groupKey}
          setShowCustomerDocs={setShowCustomerDocs}
          showCustomerDocs={showCustomerDocs}
          custName={custName}
          custKey={custKey}
          hideAttachToCommuicate
          workSessionID={null}
          custId={custId}
        />
      )}
    </>
  );
});
