import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {getUserData} from '../../../store/actions/userActions';
import Backdrop from '../../../ui/utils/backdrop/Backdrop';
import {
  fetchCreditClass,
  fetchCreditDetails,
  saveCreditDetails,
  updateCreditDetailsEditMode,
} from '../../../store/actions/customerInfoActions';

import CreditTabColumnsTemplate from './CreditTabColumnsTemplate';

function Credit() {
  const navigate = useNavigate();
  const groupKey = getUserData()?.groupKey;
  const dispatch = useDispatch();
  const {isEditMode, creditDetails, creditClass, fetchingCreditDetails} =
    useSelector(store => store.customerInfoReducer.creditDetails);
  const selectedCustomer = useSelector(
    store => store.generalReducer?.selectedCustomer,
  );
  const [creditReviewDate, setCreditReviewDate] = useState(
    creditDetails?.CreditReviewDate,
  );
  const [onlinePaymentOption, setOnlinePaymentOption] = useState(
    creditDetails?.OnlinePmtOption,
  );
  const [selectedCreditClass, setSelectedCreditClass] = useState(
    creditDetails?.CreditClassKey,
  );
  const [nacmDoNotShare, setNACMDoNotShare] = useState(
    creditDetails?.NACMDoNotShare,
  );
  const [creditHold, setCreditHold] = useState(false);
  const [creditScore, setCreditScore] = useState(creditDetails?.CreditScore);

  const onEditPaymentMethodClicked = () => {
    navigate(
      `/customer-info/edit-payment-method?CustKey=${selectedCustomer.custKey}`,
    );
  };

  const handleSaveCreditDetails = () => {
    const payload = {
      onlinePmtOption: onlinePaymentOption,
      creditReviewDate,
      creditClassKey: selectedCreditClass,
      nacmDoNotShare,
      creditScore: Math.round(creditScore),
    };
    dispatch(updateCreditDetailsEditMode(false));
    dispatch(saveCreditDetails(selectedCustomer?.custKey, payload));
  };

  const handleOnCancel = () => {
    dispatch(updateCreditDetailsEditMode(false));
  };

  const handleOnEdit = () => {
    dispatch(updateCreditDetailsEditMode(true));
  };

  useEffect(() => {
    dispatch(fetchCreditClass(groupKey));
  }, []);

  useEffect(() => {
    dispatch(fetchCreditDetails(selectedCustomer?.custKey));
  }, [selectedCustomer]);

  return (
    <div className="customer-info-item-tab-content-wrapper position-relative p-3">
      <Backdrop open={fetchingCreditDetails} />
      <CreditTabColumnsTemplate
        handleOnCancel={handleOnCancel}
        isEditMode={isEditMode}
        creditDetails={creditDetails}
        creditClass={creditClass}
        selectedCreditClass={selectedCreditClass}
        setSelectedCreditClass={setSelectedCreditClass}
        onEditPaymentMethodClicked={onEditPaymentMethodClicked}
        onlinePaymentOption={onlinePaymentOption}
        setOnlinePaymentOption={setOnlinePaymentOption}
        creditHold={creditHold}
        setCreditHold={setCreditHold}
        nacmDoNotShare={nacmDoNotShare}
        setNACMDoNotShare={setNACMDoNotShare}
        creditReviewDate={creditReviewDate}
        setCreditReviewDate={setCreditReviewDate}
        creditScore={creditScore}
        setCreditScore={setCreditScore}
        handleSaveCreditDetails={handleSaveCreditDetails}
        handleOnEdit={handleOnEdit}
      />
    </div>
  );
}

export default Credit;
