import {Button} from 'primereact';

export const ConfirmDeleteDialog = ({onHide, onConfirm}) => {
  return (
    <div className="d-flex flex-column">
      <p>
        Are you sure you want to delete the selected item? It might still be in
        use. If deleted, some pages will not be displayed properly. Press "OK"
        to confirm deletion.
      </p>
      <div className="mt-4 align-self-end ">
        <Button
          className="bg-purple mx-2"
          onClick={() => {
            onConfirm();
            onHide();
          }}
        >
          Ok
        </Button>
        <Button className="p-button p-button-outlined" onClick={onHide}>
          Cancel
        </Button>
      </div>
    </div>
  );
};
