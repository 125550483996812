import data from '../lib/data';
import {isEmpty} from '../lib/utils';

export const fetchBestActivities = ({
  userKey,
  groupKey,
  ruleKey,
  skipRows,
  rowCount,
}) => {
  let endpoint = `v4/api/home/activities/for/${groupKey}?`;
  const sortJson =
    '{"ViewSetting":{"SortSetting":{"SortSetting":[{"SortIndex":1,"SortCol":"Priority","SortOrder":"asc"},{"SortIndex":2,"SortCol":"RuleKey","SortOrder":"asc"}]}}}';

  const payload = {
    groupKey,
    userKey,
    mode: 'home',
    ruleKey,
    sortJson,
  };
  if (!isEmpty(skipRows)) {
    endpoint += `&%24skip=${skipRows}`;
  }

  if (!isEmpty(rowCount)) {
    endpoint += `&%24top=${rowCount}`;
  }

  return data.post(endpoint, payload);
};

export const fetchHomeDashboardStats = ({userKey, groupKey}) => {
  const endpoint = `v4/api/home/stats/for/${groupKey}`;
  const payload = {
    userKey,
  };
  return data.post(endpoint, payload);
};

export const fetchUserAlertsSummary = ({userKey, groupKey}) => {
  const endpoint = `v4/api/home/summary/for/${groupKey}`;
  const payload = {
    userKey,
  };
  return data.post(endpoint, payload);
};

export const fetchLastSyncData = ({groupKey}) => {
  const endpoint = `v4/api/home/syncInfo/for/${groupKey}`;
  return data.get(endpoint);
};
