import {
  QueryBuilder as ReactQueryBuilder,
  prepareRuleGroup,
  transformQuery,
} from 'react-querybuilder';
import {QueryBuilderMaterial} from '@react-querybuilder/material';
import type {Field, RuleGroupTypeIC, RuleType} from 'react-querybuilder';
import 'react-querybuilder/dist/query-builder.css';
import {Global, css} from '@emotion/react';
import {grey} from '@mui/material/colors';
import React, {useEffect, useState} from 'react';
import dayjs from 'dayjs';
import {Box} from '../../layouts';
import {Button} from '../../inputs';
import {
  generateJsonAndSql,
  generateJsonFromSql,
  getOperators,
  initialQuery,
} from './queryHelper';
import {constants} from '../../../constants/common';
import {Typography} from '../../displays';

export interface QueryReturnValue {
  sql: string;
  json: RuleGroupTypeIC;
}

interface QueryBuilderProps {
  fields: Field[];
  showCloneButtons?: boolean;
  onFilterApply: (query: QueryReturnValue) => void;
  customFilterSqlValue?: string;
  disabled?: boolean;
}

const QueryBuilder: React.FC<QueryBuilderProps> = ({
  fields,
  showCloneButtons = true,
  onFilterApply,
  customFilterSqlValue,
  disabled = false,
}) => {
  const [query, setQuery] = useState(initialQuery);
  const [isValidDateEntered, setIsValidDateEntered] = useState(true);

  // TODO: Remove this when we move query to json from sql
  const formatDate = (dateStr: string) => {
    return dayjs(dateStr).format('YYYY-MM-DD hh:mm:ss A');
  };
  const updatedFields = fields
    .filter(field => field.inputType !== 'actions')
    .map(field => {
      if (field.inputType === 'boolean') {
        return {...field, valueEditorType: 'checkbox'};
      }
      if (field.inputType === 'number') {
        return {...field, defaultValue: '0'};
      }
      return field;
    });

  const processDateRule = (
    r: RuleType<string, string, any, string>,
    isValidDate: boolean,
  ) => {
    const dates = r.value.split(',').map((date: string) => {
      return formatDate(date);
    });

    const areDatesValid = dates.every((date: any) => dayjs(date).isValid());

    return {
      isValidDate: isValidDate && areDatesValid,
      rule: {
        ...r,
        value: dates.join(','),
      },
    };
  };
  function reverseDateFormat(dateStr: string): string {
    return dayjs(dateStr, 'YYYY-MM-DD hh:mm:ss A').format('YYYY-MM-DDTHH:mm');
  }
  const processDateRuleReverse = (
    r: RuleType<string, string, any, string>,
    isValidDate: boolean,
  ) => {
    const dates = r.value.split(',').map((date: string) => {
      return reverseDateFormat(date);
    });

    const areDatesValid = dates.every((date: any) =>
      dayjs(date, 'YYYY-MM-DDTHH:mm').isValid(),
    );

    return {
      isValidDate: isValidDate && areDatesValid,
      rule: {
        ...r,
        value: dates.join(','),
      },
    };
  };

  const processRule = (
    r: RuleType<string, string, any, string>,
    isValidDate: boolean,
  ) => {
    const fieldInfo = updatedFields.find(field => field.name === r.field);
    if (
      fieldInfo?.inputType === 'date' ||
      fieldInfo?.inputType === 'dateTime' ||
      fieldInfo?.inputType === 'datetime-local'
    ) {
      if (r.operator === 'between' || r.operator === 'notBetween') {
        return processDateRule(r, isValidDate);
      }
      if (r.operator === 'null' || r.operator === 'notNull') {
        return {
          isValidDate: true,
          rule: r,
        };
      }
      const formattedDate = formatDate(r.value);
      const isDateValid = dayjs(formattedDate).isValid();

      return {
        isValidDate: isValidDate && isDateValid,
        rule: {...r, value: formattedDate},
      };
    }
    return {
      isValidDate,
      rule: r,
    };
  };

  const processRuleReverse = (
    r: RuleType<string, string, any, string>,
    isValidDate: boolean,
  ) => {
    const fieldInfo = updatedFields.find(field => field.name === r.field);
    if (
      fieldInfo?.inputType === 'date' ||
      fieldInfo?.inputType === 'dateTime' ||
      fieldInfo?.inputType === 'datetime-local'
    ) {
      if (r.operator === 'between' || r.operator === 'notBetween') {
        return processDateRuleReverse(r, isValidDate);
      }
      const formattedDate = formatDate(r.value);
      const isDateValid = dayjs(formattedDate).isValid();

      return {
        isValidDate: isValidDate && isDateValid,
        rule: {...r, value: reverseDateFormat(formattedDate)},
      };
    }
    return {
      isValidDate,
      rule: r,
    };
  };
  // Remove till here

  useEffect(() => {
    if (customFilterSqlValue) {
      try {
        const parsedQuery: any = generateJsonFromSql(customFilterSqlValue);
        const preparedQuery = transformQuery(parsedQuery, {
          ruleProcessor: r => {
            const {rule} = processRuleReverse(r, true);
            return rule;
          },
        });
        setQuery(prepareRuleGroup(preparedQuery));
      } catch (e) {
        setQuery(prepareRuleGroup(initialQuery));
      }
    }
  }, [customFilterSqlValue]);

  const onApplyQueryFilter = () => {
    let isValidDate = true;
    const preparedQuery = transformQuery(query, {
      ruleProcessor: r => {
        const {isValidDate: isRuleValid, rule} = processRule(r, isValidDate);
        isValidDate = isRuleValid;
        return rule;
      },
    });
    setIsValidDateEntered(isValidDate);
    if (isValidDate) {
      const {sql, json} = generateJsonAndSql(preparedQuery);
      onFilterApply({sql, json});
    }
  };

  const onApplyClearFilter = () => {
    setQuery(initialQuery);
    const {sql, json} = generateJsonAndSql(initialQuery);
    onFilterApply({sql, json});
  };

  return (
    <Box padding={1} data-testid="query-builder" maxHeight="550px">
      <Global
        styles={css`
          .queryBuilder .ruleGroup {
            background-color: ${grey.A100};
            border: 1px solid ${grey.A200};
          }
        `}
      />
      <QueryBuilderMaterial>
        <ReactQueryBuilder
          fields={updatedFields}
          controlClassnames={{queryBuilder: 'queryBuilder-branches'}}
          showCloneButtons={showCloneButtons}
          getOperators={getOperators}
          query={query}
          onQueryChange={setQuery}
          disabled={disabled}
        />
      </QueryBuilderMaterial>
      {query.rules.length > 0 && (
        <Box
          display="flex"
          alignItems="center"
          marginTop={4}
          gap={1}
          padding={1}
        >
          <Button
            variant="contained"
            size="small"
            onClick={onApplyQueryFilter}
            data-testid="apply-filter"
            disabled={disabled}
          >
            {constants.APPLY}
          </Button>
          <Button variant="outlined" size="small" onClick={onApplyClearFilter}>
            {constants.CLEAR}
          </Button>
          {!isValidDateEntered && (
            <Typography variant="caption" color="red">
              {constants.PLEASE_ENTER_VALID_DATE}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

export default QueryBuilder;
