import React from 'react';
import {
  Select as MuiSelect,
  MenuItem,
  InputLabel,
  SelectProps,
  FormControl,
  SelectChangeEvent,
  FormHelperText,
} from '@mui/material';

type Props = {
  /**
   * text field value
   */
  //  value?: string;
  label: string;
  items:
    | Array<{value: unknown; label: string}>
    | ReadonlyArray<{readonly value: unknown; readonly label: string}>;
  disabled?: boolean;
  formMargin?: 'normal' | 'dense' | 'none';
  fullWidth?: boolean;
  helperText?: string;
  onChange?: (event: SelectChangeEvent) => void;
  notched?: boolean;
  shrinkLabel?: boolean;
  disableScrollLock?: boolean;
} & SelectProps;

const Select: React.FC<Props> = ({
  value,
  label,
  items,
  onChange,
  disabled,
  size = 'small',
  formMargin = 'normal',
  fullWidth = true,
  helperText = '',
  shrinkLabel,
  notched,
  disableScrollLock = true,
  ...otherProps
}) => {
  return (
    <FormControl fullWidth={fullWidth} margin={formMargin} size={size}>
      <InputLabel shrink={shrinkLabel} id="select-label">
        {label}
      </InputLabel>
      <MuiSelect
        {...otherProps}
        labelId="select-label"
        disabled={disabled}
        defaultValue=""
        value={value}
        label={label}
        onChange={onChange}
        notched={notched}
        SelectDisplayProps={{style: {paddingTop: 8.5, paddingBottom: 8.5}}}
        MenuProps={{disableScrollLock}}
      >
        {items?.map(item => (
          <MenuItem key={item.value as string} value={item.value as string}>
            {item.label}
          </MenuItem>
        ))}
      </MuiSelect>
      {helperText && (
        <FormHelperText
          style={{...otherProps.style, ...(otherProps.error && {color: 'red'})}}
        >
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default Select;
