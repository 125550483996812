import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import {ViewSettingsDropDown} from '../../../../common/view-settings/view-settings-dropdown/ViewSettingsDropDown';
import {
  resetWebhookStatus,
  retryPendingPayments,
  updatePaymentSummaryDashboardViewObject,
  updatePendingPaymentIsRestricted,
  updatePendingPaymentReconcileStatus,
} from '../../../../../store/actions/pendingPaymentSummaryActions';
import {Button, CheckboxLabel} from '../../../../../ui/inputs';
import {constants} from '../../../../../constants/common';
import {getUserData} from '../../../../../store/actions/userActions';
import {Dialog} from '../../../../../ui';
import {Box} from '../../../../../ui/layouts';

export const ViewSettings = ({
  selectedPayments,
  mode,
}: {
  selectedPayments: any[];
  mode: string;
}) => {
  const dispatch = useDispatch<any>();
  const {groupKey, userId} = getUserData();

  const [displayButtons, SetDisplayButtons] = useState<boolean>(true);

  const [isResendButton, setIsResendButton] = useState<boolean>(false);
  const [webHookClear, setWebhookClear] = useState<boolean>(false);

  const [displayConfirmationDialog, setDisplayConfirmationDialog] =
    useState<boolean>(false);
  const RESEND_PAYMENT_BUTTON = 'ResendPayment';
  const CLEAR_WEBHOOK_BUTTON = 'ClearWebhook';
  const {
    paymentSummarySettingId,
    webhookSetting,
    alertDescription,
    isRestricted,
    paymentSummaryViewSettings,
  } = useSelector((store: any) => store.pendingPaymentSummaryReducer);

  // Interface for pending payment summary
  interface IRequest {
    OpenStatusKeys: string;
    ReconcileStatusKeys: string;
    UserId: string;
  }

  useEffect(() => {
    const isWebhookSettingValid = webhookSetting;
    SetDisplayButtons(isWebhookSettingValid);
  }, [webhookSetting]);

  const onClickPaymentsButtons = (clickedButton: string) => {
    setIsResendButton(clickedButton === RESEND_PAYMENT_BUTTON);
    setWebhookClear(clickedButton === CLEAR_WEBHOOK_BUTTON);
    setDisplayConfirmationDialog(true);
  };

  const updateWebhookStatus = () => {
    dispatch(resetWebhookStatus(groupKey));
    setDisplayConfirmationDialog(false);
  };

  const resendPaymentsConfirmation = () => {
    dispatch(retryPendingPayments({groupKey, userId}));
    setDisplayConfirmationDialog(false);
  };

  const updateReconcileStatus = () => {
    const reconciledStatusKeys = selectedPayments.join(',');
    const request: IRequest = {
      OpenStatusKeys: '',
      ReconcileStatusKeys: reconciledStatusKeys,
      UserId: userId,
    };
    dispatch(updatePendingPaymentReconcileStatus(request));
  };

  return (
    <>
      <Box display="flex" alignItems="center" marginTop="12px">
        {alertDescription != null && (
          <CheckboxLabel
            label={`Restricted to Alert ${alertDescription}`}
            checked={isRestricted}
            onChange={e => {
              dispatch(updatePendingPaymentIsRestricted(e.target.checked));
            }}
          />
        )}
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginY="12px"
      >
        <Box width="250px">
          <ViewSettingsDropDown
            settingId={paymentSummarySettingId}
            viewName="PendingPayments"
            isQueryParamDefault
            onViewSettingSelected={(selectedSettingId, viewSettings) =>
              dispatch(
                updatePaymentSummaryDashboardViewObject({
                  settingId: selectedSettingId,
                  viewSettings,
                }),
              )
            }
            currentViewSettings={paymentSummaryViewSettings}
            activityType={mode}
          />
        </Box>
        <Box display="flex" gap={1}>
          <Button variant="contained" onClick={() => updateReconcileStatus()}>
            {constants.UPDATE_SELECTED}
          </Button>
          <Button variant="contained" disabled>
            {constants.IMPORT_PAYMENTS}
          </Button>
          <Button
            variant="contained"
            disabled={!displayButtons}
            onClick={() => onClickPaymentsButtons(RESEND_PAYMENT_BUTTON)}
          >
            {constants.RESEND_PAYMENTS}
          </Button>
          <Button
            variant="contained"
            disabled={!displayButtons}
            onClick={() => onClickPaymentsButtons(CLEAR_WEBHOOK_BUTTON)}
          >
            {constants.CLEAR_FAILED_STATUS}
          </Button>
        </Box>
        {isResendButton && (
          <Dialog
            title={constants.RESEND_PAYMENTS}
            onConfirm={resendPaymentsConfirmation}
            open={displayConfirmationDialog}
            showConfirmButton
            showCancelButton
            maxWidth="xs"
            fullWidth
            onClose={() => setDisplayConfirmationDialog(false)}
          >
            <p className="d-flex text-align-center">
              <i className="pi pi-custom pi-warning mx-3" />
              {constants.RESEND_PAYMENTS_WARNING}
            </p>
          </Dialog>
        )}
        {webHookClear && (
          <Dialog
            title={constants.CLEAR_FAILED_STATUS}
            onConfirm={updateWebhookStatus}
            open={displayConfirmationDialog}
            showConfirmButton
            showCancelButton
            maxWidth="xs"
            fullWidth
            onClose={() => setDisplayConfirmationDialog(false)}
          >
            <p className="d-flex text-align-center">
              <i className="pi pi-custom pi-warning mx-3" />
              {constants.CLEAR_WEBHOOK_STATUS}
            </p>
          </Dialog>
        )}
      </Box>
    </>
  );
};
