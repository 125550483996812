import {useEffect, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {
  GridRowModes,
  GridRowEditStopReasons,
  GridAlignment,
  GridRowModesModel,
  GridEventListener,
} from '@mui/x-data-grid';
import {constants} from '../../../../constants/common';
import {
  deleteAllInvoiceInstallmentList,
  updateInvoiceInstallmentList,
} from '../../../../store/actions/invoiceActions';
import {Button} from '../../../../ui/inputs';
import {invoiceInstallmentColumns} from './column';
import {DataGrid} from '../../../../ui/data';
import {IInvoiceDetail} from '../../../account-overview/interface';
import {Snackbar} from '../../../../ui/feedback';
import {ISnackbarProps} from '../../../../ui/feedback/snackbar/Snackbar';

interface IInstallment {
  id?: string | number;
  isNew?: boolean;
  dueDate?: Date;
  balance?: number;
  tranAmt: number;
  invcSchedKey?: string | number;
  invcKey?: number | string;
}
interface IInvoiceInstallmentsProps {
  installments: IInstallment[];
  invcKey: number | string;
  invoiceDetails: IInvoiceDetail | undefined;
  groupKey: string;
  isLoading: boolean;
  isEditable: boolean;
}

export const InvoiceInstallments = ({
  installments,
  invcKey,
  invoiceDetails,
  groupKey,
  isLoading,
  isEditable,
}: IInvoiceInstallmentsProps) => {
  const originalInstallments = useRef(installments);
  const [instalmentList, setInstalmentList] = useState(installments);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [snackbarObj, setSnackbarObj] = useState<ISnackbarProps>({
    message: '',
    onClose: () => {},
    open: false,
    type: 'info',
    title: '',
  });

  const dispatch = useDispatch<any>();

  useEffect(() => {
    setInstalmentList(installments);
    originalInstallments.current = installments;
  }, [installments]);

  const getSumOfInstallment = (list: IInstallment[]) => {
    return list.reduce((acc, o) => acc + Number(o.tranAmt), 0);
  };
  const amount = invoiceDetails?.tranAmt || 0;

  const addInstallment = () => {
    const tempInstalmentList = [...instalmentList];
    const id = Math.floor(Math.random() * 100);
    const sum = getSumOfInstallment(tempInstalmentList);
    const newObject = {
      id,
      invcSchedKey: '',
      invcKey,
      tranAmt: amount - sum,
      isNew: true,
    };
    const newInstalmentList = [newObject, ...tempInstalmentList];
    setInstalmentList([...newInstalmentList]);
    setRowModesModel(old => ({
      ...old,
      [id]: {mode: GridRowModes.Edit, fieldToFocus: 'dueDate'},
    }));
  };

  const deleteInstallments = () => {
    if (installments.length > 0) {
      dispatch(
        deleteAllInvoiceInstallmentList({
          groupKey,
          invcKey,
          custKey: invoiceDetails?.custKey,
        }),
      );
      setInstalmentList([]);
    }
  };

  const onRowDelete = (id: string) => {
    setInstalmentList(instalmentList.filter(row => row.id !== id));
  };

  const handleCloseSnackbar = () => {
    setSnackbarObj(prev => ({...prev, open: false}));
  };

  const validateInstallments = ({
    instalmentListParam,
  }: {
    instalmentListParam: IInstallment[];
  }) => {
    const sum = getSumOfInstallment(instalmentListParam);
    if (instalmentListParam.some(o => o.tranAmt < 0)) {
      setSnackbarObj({
        type: 'error',
        title: constants.ERROR,
        open: true,
        message: constants.AMOUNT_MUST_BE_GREATER_THAN_ZERO,
        onClose: handleCloseSnackbar,
      });
      return false;
    }
    if (sum !== amount) {
      setSnackbarObj({
        type: 'error',
        title: constants.ERROR,
        open: true,
        message: constants.YOUR_AMOUNT_DO_NOT_TOTAL_THE_INVOICE_AMOUNT,
        onClose: handleCloseSnackbar,
      });
      return false;
    }
    const invalidDate =
      instalmentListParam.filter(o => {
        return (
          !Object.prototype.hasOwnProperty.call(o, 'dueDate') || !o.dueDate
        );
      }).length > 0;
    if (invalidDate) {
      setSnackbarObj({
        type: 'error',
        title: constants.ERROR,
        open: true,
        message: constants.PLEASE_ENTER_VALID_DUE_DATE,
        onClose: handleCloseSnackbar,
      });
      return false;
    }
    return true;
  };

  const onSave = ({
    loadApi = true,
    instalmentListParam,
  }: {
    loadApi: boolean;
    instalmentListParam: IInstallment[];
  }) => {
    const isRowEditMode = Object.values(rowModesModel).some(
      rowMode => rowMode.mode === GridRowModes.Edit,
    );
    if (isRowEditMode) {
      setSnackbarObj({
        type: 'error',
        title: constants.ERROR,
        open: true,
        message: constants.PLEASE_SAVE_THE_ROWS_BEFORE_SAVING_THE_INSTALLMENTS,
        onClose: handleCloseSnackbar,
      });
    } else {
      const validData = validateInstallments({instalmentListParam});
      if (validData) {
        const addOrUpdateList = instalmentListParam.map(item => {
          return {
            InvcSchedKey: item.isNew ? null : item.invcSchedKey,
            InvcKey: item.invcKey,
            DueDate: item.dueDate,
            TranAmt: item.tranAmt,
          };
        });
        const deleted = originalInstallments.current
          .filter(
            e =>
              !instalmentListParam.find(a => e.invcSchedKey === a.invcSchedKey),
          )
          .map(item => {
            return {
              InvcSchedKey: item.invcSchedKey,
              InvcKey: item.invcKey,
              DueDate: item.dueDate,
              TranAmt: item.tranAmt,
              IsDeleted: true,
            };
          });
        const request = [...addOrUpdateList, ...deleted];
        if (loadApi) {
          dispatch(updateInvoiceInstallmentList(request, {groupKey, invcKey}));
        }
      }
    }
  };

  const handleRowSaveClick = (id: string) => {
    setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.View}});
  };

  const onCancel = (id: string) => {
    setRowModesModel({
      ...rowModesModel,
      [id]: {mode: GridRowModes.View, ignoreModifications: true},
    });

    const editedRow = instalmentList.find(row => row.id === id);
    if (editedRow?.isNew) {
      setInstalmentList(instalmentList.filter(row => row.id !== id));
    }
  };

  const handleEditClick = (id: string) => {
    setRowModesModel(old => ({
      ...old,
      [id]: {mode: GridRowModes.Edit, fieldToFocus: 'dueDate'},
    }));
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (
    params,
    event,
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      Object.defineProperty(event, 'defaultMuiPrevented', {
        value: true,
        writable: true,
        enumerable: true,
        configurable: true,
      });
    }
  };
  const processRowUpdate = (newRow: IInstallment) => {
    const updatedRow = {...newRow, isNew: false};
    const instalmentListTemp = instalmentList.map(row =>
      row.id === newRow.id ? updatedRow : row,
    );

    setInstalmentList(instalmentListTemp);
    onSave({loadApi: false, instalmentListParam: instalmentListTemp});
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  // Todo: Add alignment in other column files
  const columnAlignment: GridAlignment = 'left' as GridAlignment;

  return (
    <>
      <Snackbar
        open={snackbarObj.open}
        onClose={handleCloseSnackbar}
        message={snackbarObj.message}
        title={snackbarObj.title}
        type={snackbarObj.type}
      />
      {isEditable && (
        <div className="d-flex mt-4 justify-content-between">
          <div>
            <Button
              onClick={addInstallment}
              disabled={isLoading}
              variant="contained"
              className="me-2"
            >
              {constants.ADD_INSTALLMENT}
            </Button>
            <Button
              variant="contained"
              onClick={deleteInstallments}
              disabled={isLoading}
            >
              {constants.DELETE_ALL_INSTALLMENTS}
            </Button>
          </div>
          <div>
            <Button
              className="me-3"
              variant="outlined"
              size="small"
              onClick={() =>
                onSave({loadApi: true, instalmentListParam: instalmentList})
              }
              disabled={isLoading}
            >
              {constants.SAVE}
            </Button>
            <Button
              className="me-3"
              variant="outlined"
              size="small"
              onClick={() => setInstalmentList(originalInstallments.current)}
              disabled={isLoading}
            >
              {constants.CANCEL}
            </Button>
          </div>
        </div>
      )}

      <div className="my-3 border">
        <DataGrid
          height={450}
          disableVirtualization
          columns={invoiceInstallmentColumns(
            onRowDelete,
            onCancel,
            handleRowSaveClick,
            handleEditClick,
            rowModesModel,
            columnAlignment,
          )}
          autoPageSize
          hidePagination
          rows={instalmentList}
          loading={isLoading}
          rowSelection={false}
          rowCount={instalmentList.length}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          getRowId={row => row.id}
        />
      </div>
    </>
  );
};
