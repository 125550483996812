import {CheckboxProps, FormControlLabel} from '@mui/material';
import {ChangeEvent} from 'react';
import Checkbox from './Checkbox';

interface Props extends CheckboxProps {
  /**
   * Label to be displayed
   */
  label: string;
  /**
   * onChange event
   */
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

/**
 * Checkbox component to show Checkbox with label.
 * More information available here https://mui.com/material-ui/react-checkbox/#label
 */

const CheckboxLabel: React.FC<Props> = ({label, ...checkboxProps}) => {
  return (
    <FormControlLabel control={<Checkbox {...checkboxProps} />} label={label} />
  );
};

export default CheckboxLabel;
