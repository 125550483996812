import {constants, fileNames} from '../../constants/common';
import {
  exportReportingGroupLogs,
  fetchReportingGroupLogs,
} from '../../services/reportingGroupLogs';
import {downloadExcel} from '../../utils/fileUtils';
import {actionTypes} from '../constants/actionTypes';

export const getReportingGroupLogs = options => (dispatch, getState) => {
  const toast = getState().generalReducer?.toastRef;
  const reportingGroupLogsState = getState().reportingGroupLogsReducer;
  const {
    userKey,
    groupKey,
    settingKey,
    sortQuery,
    filterQuery,
    pageSize,
    skip,
    sqlQuery,
  } = options;
  const filterQueryVal =
    settingKey ===
    reportingGroupLogsState?.reportingGroupLogsViewSettings?.settingsKey
      ? filterQuery
      : '';

  dispatch({type: actionTypes.FETCHING_REPORTING_GROUP_LOGS});
  fetchReportingGroupLogs({
    reportingGroupLogsState,
    userKey,
    groupKey,
    settingKey,
    sortQuery,
    globalFilterQuery: filterQueryVal,
    pageSize,
    skip,
    sqlQuery,
  })
    .then(res => {
      dispatch({
        type: actionTypes.FETCHED_REPORTING_GROUP_LOGS,
        reportingGroupLogsData: res.data.value,
        reportingGroupLogsCount: res.data.count,
        reportingGroupLogsNextLink: res.data.nextLink,
      });
    })
    .catch(() => {
      toast?.current.show({
        severity: 'warn',
        summary: constants.SOMETHING_WENT_WRONG,
        detail: '',
      });
      dispatch({type: actionTypes.FETCHING_REPORTING_GROUP_LOGS_FAILED});
    });
};

export const updateReportingGroupLogsDashboardViewObject = ({
  settingId,
  viewSettings,
}) => {
  return {
    type: actionTypes.SET_REPORTING_GROUP_LOGS_DASHBOARD_VIEW_SETTING_OBJECT,
    payload: {
      settingId,
      viewSettings,
    },
  };
};

export const reportingGroupLogsExportToExcel =
  ({
    userKey,
    groupKey,
    modeType,
    settingKey,
    sortQuery,
    filterQuery,
    sqlQuery,
  }) =>
  (dispatch, getState) => {
    const toast = getState().generalReducer?.toastRef;

    dispatch({type: actionTypes.EXPORTING_REPORTING_GROUP_LOGS_TO_EXCEL});
    exportReportingGroupLogs({
      userKey,
      groupKey,
      settingKey,
      sortQuery,
      filterQuery,
      sqlQuery,
    })
      .then(res => {
        downloadExcel(
          res.data,
          fileNames[modeType] ?? 'ExportFile',
          toast,
          constants.REPORTING_GROUP_LOGS_EXPORTED_TO_EXCEL,
        );
        dispatch({type: actionTypes.EXPORTED_REPORTING_GROUP_LOGS_TO_EXCEL});
      })
      .catch(() => {
        dispatch({
          type: actionTypes.EXPORTING_REPORTING_GROUP_LOGS_TO_EXCEL_FAILED,
        });
      });
  };

export const cleanUpReportingGroupLogs = () => {
  return {
    type: actionTypes.CLEAN_UP_REPORTING_GROUP_LOGS,
  };
};
