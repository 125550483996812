import {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {constants} from '../../constants/common';
import {getApplicationPaymentByInvoiceKey} from '../../store/actions/applicationDetailActions';
import {getInvoiceInstallmentList} from '../../store/actions/invoiceActions';
import {InvoiceInstallments} from './invoice-tabs/installments-tab/InvoiceInstallments';
import InvoiceLines from '../account-overview/invoice-lines/InvoiceLines';
// Todo: Resolve Eslint Error Dependency Cycle
// eslint-disable-next-line import/no-cycle
import ApplicationDetailTemplate from './application-details/ApplicationDetailTemplate';
import {checkIsTranTypePayment} from '../account-overview/statement-of-account/helper';
import {downloadInvoice} from '../../utils/fileUtils';
import {useShowCustomerDocs} from '../account-overview/hooks/useShowCustomerDocs';
import Tabs from '../../ui/utils/tabs/Tabs';
import DisputeReasonInformation, {
  IDisputeCodeDropdownItems,
} from './invoice-tabs/DisputeReasonInformation';
import UdfTab from './invoice-tabs/UdfTab';
import {
  IDisputeUDFs,
  IInvoiceDetail,
  IUDFs,
} from '../account-overview/interface';
import {ISnackbarProps} from '../../ui/feedback/snackbar/Snackbar';
import {IStatementsOfAccount} from '../account-overview/statement-of-account/interface';
import {Snackbar} from '../../ui/feedback';
import { createHandleSetShowCustomerDocs } from '../common/helper';

interface IInvoiceDetailsTabs {
  invoiceKey: string;
  isTaggedInvoice: boolean;
  invoiceDetails: IInvoiceDetail | undefined;
  udfInvoiceList: IUDFs | null;
  setUdfInvoiceList: (udfList: any) => void;
  saveInvoiceDetail: () => void;
  groupKey: string;
  disputeCodes: IDisputeCodeDropdownItems[];
  setInvoiceDetails: (invoiceDetail: any) => void;
  closeInvoiceDialog: () => void;
  disputeUDFList: IDisputeUDFs | null;
  setDisputeUDFList: (udfList: any) => void;
  selectedRow: IStatementsOfAccount | null;
}

function InvoiceDetailsTemplateTabs({
  invoiceKey,
  isTaggedInvoice = false,
  invoiceDetails,
  udfInvoiceList,
  setUdfInvoiceList,
  saveInvoiceDetail,
  groupKey,
  disputeCodes,
  setInvoiceDetails,
  closeInvoiceDialog,
  disputeUDFList,
  setDisputeUDFList,
  selectedRow,
}: IInvoiceDetailsTabs) {
  const [isEditingInvoiceUdf, setIsEditingInvoiceUdf] =
    useState<boolean>(false);
  const [isEditingDisputeUdf, setIsEditingDisputeUdf] =
    useState<boolean>(false);
  const [snackbarObj, setSnackbarObj] = useState<ISnackbarProps>({
    message: '',
    onClose: () => {},
    open: false,
    title: '',
    type: 'success',
  });
  const dispatch = useDispatch<any>();

  const {installments, isLoading} = useSelector(
    (store: any) => store.invoiceReducer,
  );
  const {handleSetShowCustomerDocs} = useShowCustomerDocs();

  const saveInvoiceUdf = () => {
    setIsEditingInvoiceUdf(false);
    setIsEditingDisputeUdf(false);
    saveInvoiceDetail();
  };

  const getApplicationPayment = () => {
    dispatch(getApplicationPaymentByInvoiceKey({invoiceKey}));
  };

  const onUDfChange = (key: string, value: any) => {
    const tempObject = {...udfInvoiceList};
    tempObject[key].value = value;
    setUdfInvoiceList(tempObject);
  };

  const onDisputeUDfChange = (key: string, value: any) => {
    const tempObject = {...disputeUDFList};
    tempObject[key].value = value;
    setDisputeUDFList(tempObject);
  };

  const getInstallmentList = () => {
    dispatch(getInvoiceInstallmentList({groupKey, invcKey: invoiceKey}));
  };

  const onHandleChange = (key: string, value: any) => {
    setInvoiceDetails(() => {
      return {...invoiceDetails, [key]: value};
    });
  };

  const closeDialog = () => {
    saveInvoiceDetail();
    closeInvoiceDialog();
  };

  useEffect(() => {
    getApplicationPayment();
    getInstallmentList();
  }, []);

  const handleOnSnackbarClose = () => {
    setSnackbarObj(prev => ({
      ...prev,
      open: false,
    }));
  };

  const updateSnackbarObj = ({type, title, open, message}: ISnackbarProps) => {
    setSnackbarObj({type, title, open, message, onClose: () => {}});
  };

  const onPDFDownloadIconClick = (columnInvoiceKey?: string) => {
    downloadInvoice(groupKey, columnInvoiceKey, null, updateSnackbarObj);
  };

  const checkTranTypePayment = () => {
    const isPaymentType: boolean = checkIsTranTypePayment(
      selectedRow?.tranType,
    );
    return isPaymentType;
  };

  interface ITabItems {
    label: string;
    content: JSX.Element;
  }

  const invoiceDetailsTabs: ITabItems[] = [
    {
      label: constants.INSTALLMENT_PLAN,
      content: (
        <div className="tabs-content-wrapper">
          <InvoiceInstallments
            installments={installments}
            invcKey={invoiceKey}
            invoiceDetails={invoiceDetails}
            groupKey={groupKey}
            isLoading={isLoading}
            isEditable={!isTaggedInvoice}
          />
        </div>
      ),
    },
    {
      label: constants.DISPUTE_REASON_INFORMATION,
      content: (
        <DisputeReasonInformation
          onHandleChange={(key, value) => onHandleChange(key, value)}
          isTaggedInvoice={isTaggedInvoice}
          closeDialog={closeDialog}
          invoiceDetails={invoiceDetails}
          disputeCodes={disputeCodes}
        />
      ),
    },
    {
      label: constants.UDFs,
      content: (
        <UdfTab
          disputeUDFList={disputeUDFList}
          isEditingDisputeUdf={isEditingDisputeUdf}
          isEditingInvoiceUdf={isEditingInvoiceUdf}
          onDisputeUDfChange={onDisputeUDfChange}
          onUDfChange={onUDfChange}
          saveInvoiceUdf={saveInvoiceUdf}
          setIsEditingDisputeUdf={setIsEditingDisputeUdf}
          setIsEditingInvoiceUdf={setIsEditingInvoiceUdf}
          udfInvoiceList={udfInvoiceList}
        />
      ),
    },
    {
      label: constants.INVOICE_LINES,
      content: <InvoiceLines selectedRowForInvoiceLines={[selectedRow]} />,
    },
    {
      label: constants.APPLICATIONS,
      content: (
        <ApplicationDetailTemplate
          custPmtKey={`${selectedRow?.custPmtKey}`}
          handleSetShowCustomerDocs={() =>
            createHandleSetShowCustomerDocs(handleSetShowCustomerDocs)
          }
          onPDFDownloadIconClick={onPDFDownloadIconClick}
          tranType={`${selectedRow?.tranType}`}
          checkIsTranTypePayment={checkTranTypePayment}
          invcKey={invoiceKey}
        />
      ),
    },
  ];

  return (
    <div className="mb-2">
      <Snackbar
        open={snackbarObj.open}
        onClose={handleOnSnackbarClose}
        message={snackbarObj.message}
        title={snackbarObj.title}
        type={snackbarObj.type}
      />
      <Tabs variant="scrollable" tabs={invoiceDetailsTabs} />
    </div>
  );
}
export default InvoiceDetailsTemplateTabs;
