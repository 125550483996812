import {ProgressSpinner} from 'primereact';
import {columns} from './Columns';
import {DataGrid} from '../../../../../ui/data';
import NoRows from '../../../../../ui/data/grid/NoRows';

export const DisplayInvoicePanel = ({
  scheduledPaymentsInvoicesData,
}: {
  scheduledPaymentsInvoicesData: any;
}) => {
  if (scheduledPaymentsInvoicesData === undefined) {
    return (
      <div>
        <ProgressSpinner />
      </div>
    );
  }
  return (
    <DataGrid
      height="auto"
      autoHeight
      columns={columns}
      rows={scheduledPaymentsInvoicesData}
      getRowId={row => row?.uniqueKey}
      slots={{noRowsOverlay: NoRows}}
    />
  );
};
