import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import {
  GridColDef,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridLogicOperator,
  GridPaginationModel,
  GridSortModel,
} from '@mui/x-data-grid-pro';

import {Outlet, useLocation} from 'react-router-dom';
import {getUserData} from '../../../../store/actions/userActions';
import {
  convertMUIToQueryBuilder,
  DataGrid,
  FilterPopoverProvider,
  generateJsonAndSql,
  QueryReturnValue,
} from '../../../../ui/data';
import {
  applyDatatableSettings,
  convertAndAppendExistingFilters,
  getGridColumnsSettings,
  hasFilterValue,
  updateColumnSettings,
} from '../../../../lib/commonTableHelpers';
import {calculateColumnWidth} from '../../../header/helper';
import {useQueryKeys} from '../../../../hooks/useQueryKeys';
import {
  cleanupExpectedPayments,
  expectedPaymentsExportToExcel,
  getExpectedPayments,
  updateExpectedPaymentsAlertDescription,
  updateExpectedPaymentsViewSettingObject,
} from '../../../../store/actions/expectedPaymentsActions';
import ExpectedPaymentToolbar from './ExpectedPaymentToolbar';
import {expectedPaymentColumns} from './expectedPaymentsColumns';
import {ViewSettings} from './ViewSettings';
import {getPageQueryParams} from '../../../messages/communicationHelper';
import {PAGE_SIZE} from '../../../../utils/constants';
import {
  generateJsonFromSql,
  initialQuery,
} from '../../../../ui/data/query-builder/queryHelper';
import ViewSettingsModal from '../../../common/view-settings/ViewSettingsModal';
import {gridModeTypes} from '../../../../constants/common';
import {
  ColumnSetting,
  IViewSettings,
} from '../../../common/view-settings/interface';
import {useComponentMountStatus} from '../../../../hooks/useComponentMountStatus';
import {shouldResetFilterModel} from '../../../../utils/filterUtils';

export const ExpectedPayments = () => {
  const dispatch = useDispatch<any>();
  const [columns, setColumns] = useState<any[]>([]);
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({});
  const [sortColumn, setSortColumn] = useState<GridSortModel>([]);

  const {groupKey} = getUserData();
  const {userKey, assignedUserKey} = useQueryKeys();
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
    logicOperator: GridLogicOperator.And,
  });
  const [bestFit, setBestFit] = useState<boolean>(false);
  const [bestFitColumns, setBestFitColumns] = useState<GridColDef[]>([]);
  const [skip, setSkip] = useState(0);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: PAGE_SIZE,
  });
  const [settingsKey, setSettingsKey] = useState<string>('');

  const [customFilterSqlValue, setCustomFilterSqlValue] = useState<
    string | null
  >(null);
  const [customFilterJsonValue, setCustomFilterJsonValue] =
    useState<QueryReturnValue['json']>();
  const location = useLocation();

  const {alertDesc} = getPageQueryParams(location);
  const [showViewSettingsPopup, setShowViewSettingsPopup] =
    useState<boolean>(false);

  const isComponentMounted = useComponentMountStatus(cleanupExpectedPayments);

  const {
    expectedPaymentsSettingId,
    expectedPaymentsData,
    expectedPaymentsLoading,
    expectedPaymentsViewSettings,
    expectedPaymentsCount,
    isBrokenPromise,
  } = useSelector((store: any) => store.expectedPaymentsReducer);

  const loadExpectedPayments = ({
    sortQuery = [],
    settingKey,
    filterQuery = undefined,
    sqlQuery = null,
    pageSizeParam = PAGE_SIZE,
    skipParam = 0,
  }: {
    sortQuery: any[];
    settingKey: string;
    filterQuery: QueryReturnValue['json'] | undefined;
    sqlQuery: string | null;
    pageSizeParam: number;
    skipParam: number;
  }) => {
    const options = {
      userKey,
      assignedUserKey,
      groupKey,
      settingKey,
      sortQuery,
      filterQuery,
      sqlQuery,
      skip: skipParam,
      pageSize: pageSizeParam,
      isBrokenPromise: alertDesc != null ? isBrokenPromise : false,
    };
    dispatch(getExpectedPayments(options));
  };

  // Reset filter model
  const resetFilterModel = () => {
    setFilterModel({
      logicOperator: GridLogicOperator.And,
      items: [],
    });
  };

  // reset pagination
  const resetPagination = () => {
    setPaginationModel({
      pageSize: paginationModel.pageSize,
      page: 0,
    });
  };

  useEffect(() => {
    if (settingsKey) {
      loadExpectedPayments({
        settingKey: settingsKey,
        sortQuery: sortColumn,
        filterQuery: customFilterJsonValue,
        sqlQuery: customFilterSqlValue,
        skipParam: 0,
        pageSizeParam: paginationModel.pageSize,
      });
      resetFilterModel();
      resetPagination();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsKey, isBrokenPromise]);

  /** Use effect to update and set columns based on view settings response */
  useEffect(() => {
    if (
      expectedPaymentsViewSettings &&
      expectedPaymentsViewSettings.settingsKey &&
      isComponentMounted
    ) {
      applyDatatableSettings(
        expectedPaymentsViewSettings,
        columns,
        expectedPaymentColumns,
        undefined,
        setColumns,
        setColumnVisibilityModel,
        setSortColumn,
      );
      const jsonData =
        expectedPaymentsViewSettings?.filter?.length > 0
          ? generateJsonFromSql(expectedPaymentsViewSettings?.filter)
          : initialQuery;
      setCustomFilterSqlValue(expectedPaymentsViewSettings?.filter || '');
      setCustomFilterJsonValue(jsonData);
      setSettingsKey(expectedPaymentsViewSettings.settingsKey);
    }
  }, [expectedPaymentsViewSettings?.settingsKey]);

  /** Use effect to update alert description value from query params */
  useEffect(() => {
    dispatch(updateExpectedPaymentsAlertDescription(alertDesc));
  }, [alertDesc]);

  /**
   * onPageChange is prop set for expected payments grid to handle pagination
   * @param args GridPaginationModel object which has page which starts from 0 and skip properties
   */
  const onPageChange = (args: GridPaginationModel) => {
    const pageSkip =
      args.pageSize !== paginationModel.pageSize
        ? 0
        : (args.page + 1) * args.pageSize - args.pageSize;
    setPaginationModel({
      pageSize: args.pageSize,
      page: args.pageSize !== paginationModel.pageSize ? 0 : args.page,
    });
    setSkip(pageSkip);
    loadExpectedPayments({
      settingKey: expectedPaymentsSettingId,
      sortQuery: sortColumn,
      filterQuery: customFilterJsonValue,
      sqlQuery: customFilterSqlValue,
      pageSizeParam: args.pageSize,
      skipParam: pageSkip,
    });
  };

  /**
   * onSortChange is prop set for expected payments grid to sorting
   * @param args GridPaginationModel array of object which field and sort properties
   */
  const onSortChange = (args: GridSortModel) => {
    setSortColumn(args);
    loadExpectedPayments({
      settingKey: expectedPaymentsSettingId,
      sortQuery: args,
      filterQuery: customFilterJsonValue,
      sqlQuery: customFilterSqlValue,
      pageSizeParam: paginationModel.pageSize,
      skipParam: skip,
    });
  };

  const handleGetExpectedPayments = (
    filterQuery: QueryReturnValue['json'],
    sqlQuery: string | null,
  ) => {
    loadExpectedPayments({
      settingKey: expectedPaymentsSettingId,
      sortQuery: sortColumn,
      filterQuery,
      sqlQuery,
      pageSizeParam: paginationModel.pageSize,
      skipParam: skip,
    });
    resetPagination();
  };

  /**
   * onFilterChange is prop set for expected payments grid to filter
   * @param args GridFilterModel array of object which field, value and operator properties
   */
  const onFilterChange = (args: GridFilterModel) => {
    const queryBuilderQuery = convertMUIToQueryBuilder(args);
    const valueToConvert = convertAndAppendExistingFilters(
      {...customFilterJsonValue},
      queryBuilderQuery,
      filterModel,
    );
    const {json, sql} = generateJsonAndSql(valueToConvert);
    setFilterModel(args);
    setCustomFilterSqlValue(json.rules.length > 0 ? sql : null);
    setCustomFilterJsonValue(json);
    if (hasFilterValue(args)) {
      handleGetExpectedPayments(json, json.rules.length > 0 ? sql : null);
    }
  };

  const onQueryFilterApply = (data: QueryReturnValue) => {
    // if filter is empty then reset filter model
    if (shouldResetFilterModel(data)) {
      resetFilterModel();
    }
    setCustomFilterSqlValue(data.sql);
    setCustomFilterJsonValue(data.json);
    handleGetExpectedPayments(
      data.json,
      data.json.rules.length > 0 ? data.sql : null,
    );
  };

  /**
   * Download Excel report
   */
  const exportToExcel = () => {
    const options = {
      userKey,
      groupKey,
      settingKey: expectedPaymentsSettingId,
      sortQuery: sortColumn,
      filterQuery: customFilterJsonValue,
      sqlQuery: customFilterSqlValue,
      isBrokenPromise: alertDesc != null ? isBrokenPromise : false,
      assignedUserKey,
    };
    dispatch(
      // @ts-ignore
      expectedPaymentsExportToExcel(options),
    );
  };

  /**
   * On Refresh refetch list of expected payments
   */
  const onRefresh = () => {
    loadExpectedPayments({
      settingKey: expectedPaymentsSettingId,
      sortQuery: sortColumn,
      filterQuery: customFilterJsonValue,
      sqlQuery: customFilterSqlValue,
      skipParam: skip,
      pageSizeParam: paginationModel.pageSize,
    });
  };

  // Handling best-fit (Column resizing)
  const applyBestFit = () => {
    const bestFitColumnsTemp = columns.map(column => {
      const minWidth = calculateColumnWidth(column.field);
      return {
        ...column,
        minWidth,
      };
    });
    setBestFit(!bestFit);
    setBestFitColumns(bestFitColumnsTemp);
  };

  const updateTableOnColumnSettingsChange = (
    updatedColumnsList: ColumnSetting[],
    settingKey: string,
  ) => {
    if (settingKey === expectedPaymentsViewSettings?.settingsKey) {
      updateColumnSettings(
        updatedColumnsList,
        columns,
        setColumns,
        setColumnVisibilityModel,
      );
    }
  };

  const onViewSettings = () => {
    setShowViewSettingsPopup(true);
  };

  const handleCloseViewSettingsPopup = () => {
    setShowViewSettingsPopup(false);
  };

  const {columnSettingJson, sortSettingJson} = getGridColumnsSettings(
    expectedPaymentsViewSettings?.columnSetting,
    columnVisibilityModel,
    sortColumn,
  );

  return (
    <>
      <Outlet context={{onReload: onRefresh}} />
      <ViewSettingsModal
        viewName="ExpectedPayment"
        mode={gridModeTypes.ExpectedPayments}
        updateTableOnColumnSettingsChange={updateTableOnColumnSettingsChange}
        handleCloseViewSettingsPopup={handleCloseViewSettingsPopup}
        customFilterSqlValue={customFilterSqlValue || ''}
        sortSettingJson={JSON.stringify(sortSettingJson) || ''}
        columnSettingJson={JSON.stringify(columnSettingJson) || ''}
        loadViewSetting={(row: IViewSettings) => {
          dispatch(
            updateExpectedPaymentsViewSettingObject({
              settingId: row.settingsKey,
              viewSettings: row,
            }),
          );
        }}
        showViewSettingsPopup={showViewSettingsPopup}
      />
      <ViewSettings />
      <FilterPopoverProvider>
        <DataGrid
          data-testid="expected-payments-grid"
          disableVirtualization
          columns={bestFit ? bestFitColumns : columns}
          rows={expectedPaymentsData}
          loading={expectedPaymentsLoading}
          rowCount={expectedPaymentsCount}
          sortModel={sortColumn}
          onSortChange={onSortChange}
          onPageChange={onPageChange}
          onFilterChange={onFilterChange}
          filterModel={filterModel}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityChange={data => setColumnVisibilityModel(data)}
          CustomToolbar={ExpectedPaymentToolbar}
          disableMultipleColumnsSorting
          customToolbarMethods={{
            exportToExcel,
            onRefresh,
            applyBestFit,
            onViewSettings,
          }}
          showCustomFilters
          customFilterSqlValue={customFilterSqlValue}
          onQueryFilterApply={data => onQueryFilterApply(data)}
          getRowId={row => row.id}
          headerFilters
          paginationModel={paginationModel}
        />
      </FilterPopoverProvider>
    </>
  );
};
