import {
  Backdrop as MuiBackdrop,
  BackdropProps,
  CircularProgress,
} from '@mui/material';

interface Props extends BackdropProps {
  /**
   * Show or hide loading backdrop
   */
  isLoading: boolean;
}

/**
 * Loading component to block the UI.
 * More information available here https://mui.com/material-ui/react-backdrop
 */

const Backdrop: React.FC<Props> = ({open, ...rest}) => {
  return (
    <MuiBackdrop
      sx={{
        color: '#fff',
        zIndex: theme => theme.zIndex.drawer + 1,
        position: 'absolute',
      }}
      open={open}
      {...rest}
    >
      <CircularProgress color="inherit" />
    </MuiBackdrop>
  );
};

export default Backdrop;
