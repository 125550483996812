import dayjs from 'dayjs';
import {filterOperators} from '../../../../lib/commonTableHelpers';
import {constants} from '../../../../constants/common';
import {DisplayColumn} from '../user-invoices/RowComponents';
import {Checkbox} from '../../../../ui/inputs';
import {CheckUnCheckFilter} from '../../../common/filter/FilterComponents';
import {CustomerMenu} from '../../../common/CommonRowComponents';
import {InvoiceLink, TagCount} from '../../../common/RowComponents';

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

export const projectedCashReceiptsColumns = [
  {
    field: 'companyId',
    headerName: 'CompanyID',
    sortable: true,
    minWidth: 200,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'custId',
    headerName: 'CustID',
    sortable: true,
    minWidth: 200,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'custName',
    headerName: 'CustName',
    sortable: true,
    renderCell: (cellValues: any) => {
      return <CustomerMenu values={cellValues} />;
    },
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'tranType',
    headerName: 'Tran Type',
    sortable: true,
    minWidth: 200,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'tranId',
    headerName: 'Trand ID',
    sortable: true,
    renderCell: (cellValues: any) => {
      return <InvoiceLink values={cellValues} />;
    },
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'copyPDFToBlob',
    headerName: 'Display',
    sortable: false,
    renderCell: (cellValues: any) => {
      return <DisplayColumn values={cellValues} />;
    },
    minWidth: 80,
    renderHeaderFilter: () => null,
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
  {
    field: 'tranDate',
    headerName: 'Tran Date',
    sortable: true,
    valueFormatter: (params: any) =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 150,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'tranAmtHC',
    headerName: 'Amount HC',
    sortable: true,
    valueFormatter: (params: any) =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 2,
      }),
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'balanceHC',
    headerName: 'Balance HC',
    sortable: true,
    valueFormatter: (params: any) =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 2,
      }),
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'dueDate',
    headerName: 'Due Date',
    sortable: true,
    valueFormatter: (params: any) =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 150,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'projPmtDate',
    headerName: 'Proj Pmt Date',
    sortable: true,
    valueFormatter: (params: any) =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 150,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'projPmtDays',
    headerName: 'Proj Pmt Days',
    sortable: true,
    minWidth: 120,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'pmtDueDays',
    headerName: 'Pmt Due Days',
    sortable: true,
    minWidth: 120,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'paySched',
    headerName: 'PaySched',
    sortable: true,
    renderCell: (cellValues: any) => {
      return (
        <Checkbox
          disabled
          checked={cellValues.row.paySched}
          onChange={() => {}}
        />
      );
    },
    minWidth: 50,
    renderHeaderFilter: (params: any) => <CheckUnCheckFilter {...params} />,
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
  {
    field: 'pmtTermsId',
    headerName: 'Pmt Terms ID',
    sortable: true,
    minWidth: 230,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'custPONo',
    headerName: 'Cust PO No',
    sortable: true,
    minWidth: 230,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'tranCmnt',
    headerName: 'Tran Cmnt',
    sortable: true,
    minWidth: 230,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'parentId',
    headerName: 'ParentID',
    sortable: true,
    minWidth: 230,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'soldToId',
    headerName: 'SoldToID',
    sortable: true,
    minWidth: 230,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'promiseToPayDate',
    headerName: 'Promise To Pay Date',
    sortable: true,
    valueFormatter: (params: any) =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 150,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'promiseToPayAmt',
    headerName: 'Promise To Pay Amt',
    sortable: true,
    valueFormatter: (params: any) =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 2,
      }),
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'resolvedDate',
    headerName: 'Resolved Date',
    sortable: true,
    valueFormatter: (params: any) =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 150,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'raesonCode',
    headerName: 'Reason Code',
    sortable: true,
    minWidth: 230,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'excludeAging',
    headerName: 'Exclude Aging',
    sortable: true,
    renderCell: (cellValues: any) => {
      return (
        <Checkbox
          disabled
          checked={cellValues.row.excludeAging}
          onChange={() => {}}
        />
      );
    },
    minWidth: 50,
    renderHeaderFilter: (params: any) => <CheckUnCheckFilter {...params} />,
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
  {
    field: 'inDispute',
    headerName: 'In Dispute',
    sortable: true,
    renderCell: (cellValues: any) => {
      return (
        <Checkbox
          disabled
          checked={cellValues.row.inDispute}
          onChange={() => {}}
        />
      );
    },
    minWidth: 50,
    renderHeaderFilter: (params: any) => <CheckUnCheckFilter {...params} />,
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
  {
    field: 'resolved',
    headerName: 'Resolved',
    sortable: true,
    renderCell: (cellValues: any) => {
      return (
        <Checkbox
          disabled
          checked={cellValues.row.resolved}
          onChange={() => {}}
        />
      );
    },
    minWidth: 50,
    renderHeaderFilter: (params: any) => <CheckUnCheckFilter {...params} />,
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
  {
    field: 'closed',
    headerName: 'Closed',
    sortable: true,
    renderCell: (cellValues: any) => {
      return (
        <Checkbox
          disabled
          checked={cellValues.row.closed}
          onChange={() => {}}
        />
      );
    },
    minWidth: 50,
    renderHeaderFilter: (params: any) => <CheckUnCheckFilter {...params} />,
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
  {
    field: 'closedDate',
    headerName: 'Closed Date',
    sortable: true,
    valueFormatter: (params: any) =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 150,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'disputeDate',
    headerName: 'Dispute Date',
    sortable: true,
    valueFormatter: (params: any) =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 150,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'disputeAmt',
    headerName: 'Dispute Amt',
    sortable: true,
    valueFormatter: (params: any) =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 2,
      }),
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'disputeCodeDate',
    headerName: 'Dispute Code Date',
    sortable: true,
    valueFormatter: (params: any) =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 150,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'pmtCount',
    headerName: 'Pmt Count',
    sortable: true,
    minWidth: 120,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'tagCount',
    headerName: 'Tag Count',
    sortable: true,
    minWidth: 120,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'lastActivityDate',
    headerName: 'Last Activity Date',
    sortable: true,
    valueFormatter: (params: any) =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 150,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'lastActivityId',
    headerName: 'Last Activity',
    sortable: true,
    minWidth: 230,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'lastActivityNote',
    headerName: 'Last Activity Note',
    sortable: true,
    minWidth: 230,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'lastActivityUserId',
    headerName: 'Last Activity User',
    sortable: true,
    minWidth: 230,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'daysSinceOpenActivity',
    headerName: 'Days Since Open Activity',
    sortable: true,
    minWidth: 120,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'daysSinceClosedActivity',
    headerName: 'Days Since Closed Activity',
    sortable: true,
    minWidth: 120,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'lastActivityDays',
    headerName: 'Last Activity Days',
    sortable: true,
    minWidth: 120,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'currId',
    headerName: 'Curr ID',
    sortable: true,
    minWidth: 230,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'tranAmt',
    headerName: 'Amount',
    sortable: true,
    valueFormatter: (params: any) =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 2,
      }),
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'balance',
    headerName: 'Balance',
    sortable: true,
    valueFormatter: (params: any) =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 2,
      }),
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'viewActivities',
    headerName: 'View Activities',
    sortable: true,
    renderCell: (cellValues: any) => {
      return <TagCount values={cellValues} />;
    },
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'origDueDate',
    headerName: 'OrigDueDate',
    sortable: true,
    valueFormatter: (params: any) =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 150,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'origTranAmt',
    headerName: 'OrigTranAmt',
    sortable: true,
    valueFormatter: (params: any) =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 2,
      }),
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'origTranAmtHC',
    headerName: 'OrigTranAmtHC',
    sortable: true,
    valueFormatter: (params: any) =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 2,
      }),
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'invUDF1',
    headerName: 'UDF1',
    sortable: true,
    minWidth: 230,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'invUDF2',
    headerName: 'UDF2',
    sortable: true,
    minWidth: 230,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'invUDF3',
    headerName: 'UDF3',
    sortable: true,
    minWidth: 230,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'invUDF4',
    headerName: 'UDF4',
    sortable: true,
    minWidth: 230,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'invUDF5',
    headerName: 'UDF5',
    sortable: true,
    minWidth: 230,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'invUDF6',
    headerName: 'UDF6',
    sortable: true,
    minWidth: 230,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'invUDF7',
    headerName: 'UDF7',
    sortable: true,
    minWidth: 230,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'invUDF8',
    headerName: 'UDF8',
    sortable: true,
    minWidth: 230,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'invUDF9',
    headerName: 'UDF9',
    sortable: true,
    minWidth: 230,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'invUDF10',
    headerName: 'UDF10',
    sortable: true,
    minWidth: 230,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'userId',
    headerName: 'User',
    sortable: true,
    minWidth: 230,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'assignedUserId',
    headerName: 'Assigned User ID',
    sortable: true,
    minWidth: 230,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'primarySperName',
    headerName: 'Primary Sper Name',
    sortable: true,
    minWidth: 230,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'percentPaid',
    headerName: 'Percent Paid',
    sortable: true,
    valueFormatter: (params: any) =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 2,
      }),
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'disputeUDF1',
    headerName: 'DisputeUDF1',
    sortable: true,
    minWidth: 230,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'disputeUDF2',
    headerName: 'DisputeUDF2',
    sortable: true,
    minWidth: 230,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'disputeUDF3',
    headerName: 'DisputeUDF3',
    sortable: true,
    minWidth: 230,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'disputeUDF4',
    headerName: 'DisputeUDF4',
    sortable: true,
    minWidth: 230,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'disputeUDF5',
    headerName: 'DisputeUDF5',
    sortable: true,
    minWidth: 230,
    type: 'string',
    filterOperators: filterOperators.string,
  },
];
