export function findByKey(key, array) {
  for (const parent of array) {
    if (parent.key === key) return parent;

    if ('children' in parent) {
      const result = findByKey(key, parent.children);
      if (result) return result;
    }
  }

  return null;
}
