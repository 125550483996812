import {CardContent} from '@mui/material';
import CountUp from 'react-countup';
import {Typography} from '../../ui/displays';
import {getCurrencySymbol} from '../../lib/utils';
import {Box} from '../../ui/layouts';

interface StatCardProps {
  label: string;
  value: number;
  currency?: string | null;
}

export function StatCard({label, value, currency = null}: StatCardProps) {
  return (
    <CardContent>
      <Box display="flex" alignItems="center" flexDirection="column">
        <Typography
          color="textSecondary"
          gutterBottom
          sx={{fontSize: '1.15rem', fontWeight: 'bold'}}
        >
          {label}
        </Typography>
        <Typography variant="h5" sx={{fontSize: '2.5rem'}}>
          {currency === null ? '' : getCurrencySymbol('USD')}
          <CountUp end={value} duration={2.75} />
        </Typography>
      </Box>
    </CardContent>
  );
}
