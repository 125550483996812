import {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {StorageType} from '../../../constants/typeConstants';
import {
  getFileList,
  createDirectory,
  uploadFiles,
  deleteDirectoryOrFile,
  uploadFileToAttachments,
} from '../../../store/actions/fileActions';
import Backdrop from '../../common/Backdrop';
import {FileManager} from '../../common/file-manager/FileManager';
import {findByKey} from './helper';
import {downloadInvoice} from '../../../utils/fileUtils';
import {Dialog} from '../../../ui';
import {Snackbar} from '../../../ui/feedback';

export const OpenDocs = ({
  showCustomerDocs,
  setShowCustomerDocs,
  groupKey,
  custName,
  custKey,
  isInvoice = false,
  hideAttachToCommuicate = false,
  selectedInvoice,
  workSessionID = null,
  custId = '',
}) => {
  const dispatch = useDispatch();
  const [selectedDirectory, setSelectedDirectory] = useState('External');
  const [selectedPath, setSelectedPath] = useState('/External');
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFilesAndFolder, setSelectedFilesAndFolder] = useState();
  const [snackbarObj, setSnackbarObj] = useState({});
  const {filesTree, isLoading} = useSelector(store => store.fileReducer);

  const rootPath = isInvoice
    ? `uploads/${groupKey}/invoicedocs/${selectedInvoice.invcKey}`
    : `uploads/${groupKey}/custdocs/${custKey}`;

  const getList = ({storageType, blobPath, groupKeyParam}) => {
    dispatch(getFileList({storageType, blobPath, groupKey: groupKeyParam}));
  };

  useEffect(() => {
    getList({
      storageType: StorageType.public,
      blobPath: `${rootPath}/`,
      groupKeyParam: groupKey,
      custKey,
    });
  }, [groupKey]);

  useEffect(() => {
    if (filesTree) {
      const selFiles = findByKey(selectedDirectory, filesTree);
      setSelectedFilesAndFolder(selFiles);
    }
  }, [filesTree]);

  const onNewFolderCreate = folderName => {
    const request = {
      StorageType: StorageType.public,
      BlobPath: `${rootPath}${selectedPath}/${folderName}/directory.exists`,
      GroupKey: groupKey,
    };
    dispatch(createDirectory(request, `${rootPath}/`));
  };

  const onFileOrFolderDelete = () => {
    let blobPath = '';
    if (selectedFile) {
      blobPath = selectedFile.fileName
        ? `${rootPath}${selectedPath}/${selectedFile.fileName}`
        : `${rootPath}${selectedFile.path}`;
    } else {
      blobPath = `${rootPath}${selectedPath}/`;
    }

    const request = {
      StorageType: StorageType.public,
      BlobPath: blobPath,
      GroupKey: groupKey,
    };
    dispatch(deleteDirectoryOrFile(request, rootPath));
  };

  const updateSelectedDirectory = filesAndFolders => {
    const {key, path} = filesAndFolders;
    setSelectedDirectory(key);
    setSelectedPath(path);
    setSelectedFilesAndFolder(filesAndFolders);
    setSelectedFile(null);
  };

  const updateSnackbarObj = ({type, title, open, message}) => {
    setSnackbarObj({type, title, open, message});
  };

  const onFilesUpload = (files, overWrite) => {
    let hasDuplicateFiles = false;
    let requestFiles = [...files];
    if (!overWrite) {
      requestFiles = files.filter(
        x => !selectedFilesAndFolder.files.some(y => y.fileName === x.name),
      );
      hasDuplicateFiles = requestFiles.length !== files.length;
    }
    const request = {
      StorageType: StorageType.public,
      BlobPath: `${rootPath}${selectedPath}`,
      GroupKey: groupKey,
      Files: requestFiles,
      hasDuplicateFiles,
    };
    dispatch(uploadFiles(request, `${rootPath}/`, updateSnackbarObj));
  };

  const onFileSelected = file => {
    setSelectedFile(file);
  };

  const onAttachToCommunicate = () => {
    if (selectedFile) {
      const request = {
        FileName: selectedFile.fileName,
        BlobPath: selectedFile.fileUrl,
        GroupKey: groupKey,
        WorkSessionID: workSessionID,
        StorageType: StorageType.public,
      };
      dispatch(uploadFileToAttachments(request, updateSnackbarObj));
    }
  };
  const onPDFDownloadIconClick = async () => {
    downloadInvoice(groupKey, selectedInvoice.invcKey, null, updateSnackbarObj);
  };

  let header = '';
  if (isInvoice) {
    header = 'Invoice Documents';
  } else if (custName) {
    header = `Customer Documents -:${custName}`;
  } else {
    header = '';
  }

  const handleOnSnackbarClose = () => {
    setSnackbarObj(prev => ({
      ...prev,
      open: false,
    }));
  };

  return (
    <Dialog
      title={header}
      open={showCustomerDocs}
      onClose={() => {
        setShowCustomerDocs(false);
      }}
      maxWidth="md"
      draggable
      fullWidth
    >
      <>
        <Snackbar
          open={snackbarObj.open}
          onClose={handleOnSnackbarClose}
          message={snackbarObj.message}
          title={snackbarObj.title}
          type={snackbarObj.type}
        />
        {isLoading && <Backdrop />}

        <FileManager
          filesTree={filesTree}
          selectedDirectory={selectedDirectory}
          onFilesUpload={onFilesUpload}
          onNewFolderCreate={onNewFolderCreate}
          onTreeSelected={updateSelectedDirectory}
          onFileOrFolderDelete={onFileOrFolderDelete}
          selectedFilesAndFolder={selectedFilesAndFolder}
          fileSelected={selectedFile}
          onFileSelected={onFileSelected}
          onRefresh={() =>
            getList({
              storageType: StorageType.public,
              blobPath: `${rootPath}/`,
              groupKeyParam: groupKey,
            })
          }
          isInvoice={isInvoice}
          hideAttachToCommuicate={hideAttachToCommuicate}
          selectedInvoice={selectedInvoice}
          custName={custName}
          onAttachToCommunicate={onAttachToCommunicate}
          custId={custId}
          onPDFDownloadIconClick={onPDFDownloadIconClick}
          updateSelectedDirectory={updateSelectedDirectory}
        />
      </>
    </Dialog>
  );
};
