import {constants} from '../../../constants/common';
import {formatCurrency} from '../../../lib/utils';
import TextField from '../../../ui/inputs/textfield/TextField';
import Backdrop from '../../../ui/utils/backdrop/Backdrop';

function HistoryAndRolledUpHistoryTemplate({
  data,
  firstColumnFieldsAndValues,
  isRolledUpHistory = false,
  loading = false,
}) {
  let secondColumn = [
    {
      label: constants.LATE_DAYS_OVERALL,
      value: data?.AvgDaysLateTotal,
    },
    {
      label: constants.LATE_DAYS_WEIGHTED_BY_AMOUNT,
      value: data?.AvgDaysLateWeighted,
    },
    {
      label: constants.DIFFERENCE,
      value: data?.AvgDaysLateDiff,
    },
  ];

  if (isRolledUpHistory) {
    const appendObject = [
      {
        label: constants.CREDIT_LIMIT,
        value: formatCurrency(
          data?.NACreditLimit?.toFixed(2) || parseFloat(0).toFixed(2),
        ),
      },
      {
        label: constants.AVAILABLE_CREDIT,
        value: formatCurrency(
          data?.AvailableCredit?.toFixed(2) || parseFloat(0).toFixed(2),
        ),
      },
    ];
    secondColumn = [...secondColumn, ...appendObject];
  }

  const fourthColumn = [
    {label: constants.DUE_FUTURE, value: data?.AgeFuture},
    {label: constants.DUE_NOW, value: data?.AgeCurrent},
    {label: `${constants.LATE_31_TO_60_DAYS}:`, value: data?.Age1},
    {label: `${constants.LATE_61_TO_90_DAYS}:`, value: data?.Age2},
    {label: `${constants.LATE_91_TO_120_DAYS}:`, value: data?.Age3},
    {label: `${constants.LATE_121_PLUS_DAYS}:`, value: data?.Age4},
    {label: constants.TOTAL, value: data?.TotalAge},
  ];

  return (
    <div className="customer-info-item-tab-content-wrapper p-3">
      <Backdrop open={loading} />
      <div className="tab-content-column">
        <h5>
          <strong>{constants.AVERAGE_DAYS_LATE}</strong>
        </h5>
        {firstColumnFieldsAndValues?.length &&
          firstColumnFieldsAndValues.map((fCol, index) => {
            return (
              <div key={fCol.id || index}>
                <TextField
                  InputProps={{
                    inputProps: {style: {textAlign: 'right'}},
                  }}
                  disabled
                  value={fCol.value}
                  label={fCol.label}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            );
          })}
      </div>
      <div className="tab-content-column mt-4">
        {secondColumn.map((sCol, index) => {
          return (
            <div key={sCol?.id || index}>
              <TextField
                InputProps={{
                  inputProps: {style: {textAlign: 'right'}},
                }}
                value={sCol?.value}
                label={sCol?.label}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          );
        })}
        <div className="d-flex align-items-center py-2">
          <div className="m-1">
            <label>{`* ${constants.TOTAL_INVOICE}`}</label>
            <label className="m-1 ms-2">{data?.AvgDaysCountTotal}</label>
          </div>

          <div className="m-1">
            <label>{`** ${constants.TOTAL_AMOUNT}`}</label>
            <label className="m-1 ms-2">
              {formatCurrency(data?.AvgDaysAmtTotal?.toFixed(2)) || null}
            </label>
          </div>
        </div>
      </div>
      <div className="tab-content-column min-width-190-px me-0">
        <table>
          <thead>
            <tr>
              <th className="d-flex">{constants.ISSUE}</th>
              <td>{constants.BROKEN_PROMISE}</td>
              <td>{constants.DISPUTES}</td>
            </tr>
          </thead>
          <tbody>
            <tr className="mb-2">
              <td>{constants.NINETY_DAYS}</td>
              <td>
                <TextField
                  className="px-1"
                  value={
                    isRolledUpHistory
                      ? data?.NABrokenPromise1
                      : data?.BrokenPromise1 || 0
                  }
                  InputProps={{
                    inputProps: {style: {textAlign: 'right'}},
                  }}
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </td>
              <td>
                <TextField
                  className="px-1"
                  value={
                    isRolledUpHistory
                      ? data?.NA3MthsDispute
                      : data?._3MthsDispute || 0
                  }
                  InputProps={{
                    inputProps: {style: {textAlign: 'right'}},
                  }}
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </td>
            </tr>
            <tr className="mb-2">
              <td>{constants.SIX_MONTHS}</td>
              <td>
                <TextField
                  className="px-1"
                  value={
                    isRolledUpHistory
                      ? data?.NABrokenPromise2
                      : data?.BrokenPromise2 || 0
                  }
                  InputProps={{
                    inputProps: {style: {textAlign: 'right'}},
                  }}
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </td>
              <td>
                <TextField
                  className="px-1"
                  value={
                    isRolledUpHistory
                      ? data?.NA6MthsDispute
                      : data?._6MthsDispute || 0
                  }
                  InputProps={{
                    inputProps: {style: {textAlign: 'right'}},
                  }}
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </td>
            </tr>
            <tr className="mb-2">
              <td>{constants.ONE_YEAR}</td>
              <td>
                <TextField
                  className="px-1"
                  value={
                    isRolledUpHistory
                      ? data?.NABrokenPromise3
                      : data?.BrokenPromise3 || 0
                  }
                  InputProps={{
                    inputProps: {style: {textAlign: 'right'}},
                  }}
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </td>
              <td>
                <TextField
                  className="px-1"
                  value={
                    isRolledUpHistory
                      ? data?.NA12MthsDispute
                      : data?._12MthsDispute || 0
                  }
                  InputProps={{
                    inputProps: {style: {textAlign: 'right'}},
                  }}
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </td>
            </tr>
            <tr className="mb-2">
              <td>{constants.TWO_YEAR}</td>
              <td>
                <TextField
                  className="px-1"
                  value={
                    isRolledUpHistory
                      ? data?.NABrokenPromise4
                      : data?.BrokenPromise4 || 0
                  }
                  InputProps={{
                    inputProps: {style: {textAlign: 'right'}},
                  }}
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </td>
              <td>
                <TextField
                  className="px-1"
                  value={
                    isRolledUpHistory
                      ? data?.NA24MthsDispute
                      : data?._24MthsDispute || 0
                  }
                  InputProps={{
                    inputProps: {style: {textAlign: 'right'}},
                  }}
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="tab-content-column ms-3">
        <h5 className="pb-2">
          <strong>{constants.AGING_BY_DUE_DATE}</strong>
        </h5>

        {fourthColumn.map((forthCol, index) => {
          return (
            <div key={forthCol.id || index}>
              <TextField
                label={forthCol?.label}
                value={formatCurrency(forthCol?.value, '', false, false)}
                InputProps={{
                  inputProps: {style: {textAlign: 'right'}},
                }}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default HistoryAndRolledUpHistoryTemplate;
