import {Button} from 'primereact';
import {constants} from '../../../../constants/common';

export const personalContactsColumns = (
  onEditPersonalContact = () => {},
  onDeletePersonalContact = () => {},
  setIsEditMode = () => {},
) => {
  return [
    {
      field: 'actionEdit',
      align: 'center',
      headerStyle: {width: '60px'},
      body: rowData => (
        <Button
          className=" bg-transparent p-0 border-0"
          onClick={() => {
            onEditPersonalContact(rowData);
            setIsEditMode(true);
          }}
        >
          <i className="pi pi-edit" />
        </Button>
      ),
    },
    {
      field: 'actionDelete',
      align: 'center',
      headerStyle: {width: '60px'},
      body: rowData => (
        <Button
          className="bg-transparent p-0 border-0"
          onClick={() => {
            onDeletePersonalContact(rowData.emailAddr);
          }}
        >
          <i className="pi pi-trash" />
        </Button>
      ),
    },
    {
      field: 'contactName',
      headerStyle: {width: '200px'},
      header: 'Contact Name',
      body: rowData => (
        <span
          className="p-treenode-label width-100-px"
          title={rowData?.contactName}
        >
          {rowData?.contactName}
        </span>
      ),
    },
    {
      field: 'emailAddr',
      header: 'Email Addr',
      headerStyle: {width: '200px'},
      body: rowData => (
        <span
          className="p-treenode-label width-100-px"
          title={rowData?.emailAddr}
        >
          {rowData?.emailAddr}
        </span>
      ),
    },
  ];
};
