import dayjs from 'dayjs';
import {constants} from '../../../../constants/common';
import {Checkbox} from '../../../../ui/inputs';
import {TRAN_TYPE} from '../../../../utils/constants';
import {CheckUnCheckFilter} from '../../../common/filter/FilterComponents';
import {ViewApplication, DisplayColumn} from './RowComponents';
import {filterOperators} from '../../../../lib/commonTableHelpers';
import {CustomerMenu} from '../../../common/CommonRowComponents';
import {InvoiceLink, TagCount} from '../../../common/RowComponents';

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

export const userInvoicesColumns = [
  {
    field: 'companyId',
    headerName: 'Company ID',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'custId',
    headerName: 'Cust ID',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'custName',
    headerName: 'Cust Name',
    sortable: true,
    renderCell: cellValues => {
      return <CustomerMenu values={cellValues} />;
    },
    minWidth: 180,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'tranId',
    headerName: 'Invoice',
    sortable: true,
    renderCell: cellValues => {
      return <InvoiceLink values={cellValues} />;
    },
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'tranType',
    headerName: 'TranType',
    sortable: true,
    minWidth: 50,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'copyPDFToBlob',
    headerName: 'Display',
    sortable: false,
    renderCell: cellValues => {
      return <DisplayColumn enablePdfDownloadLink values={cellValues} />;
    },
    minWidth: 80,
    renderHeaderFilter: () => null,
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
  {
    field: 'tranDate',
    headerName: 'Invoice Date',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 120,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'dueDate',
    headerName: 'Due Date',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 120,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'tranAmt',
    headerName: 'Amount',
    sortable: true,
    valueFormatter: params =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 2,
      }),
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'balance',
    headerName: 'Balance',
    sortable: true,
    valueFormatter: params =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 2,
      }),
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'discAmt',
    headerName: 'DiscAmt',
    sortable: true,
    valueFormatter: params =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 2,
      }),
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'discDate',
    header: 'Disc Date',
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_TIME_FORMAT)
        : '',
    minWidth: 120,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'copyPDFMessage',
    headerName: 'CopyPDFMessage',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'tranAmtSC',
    headerName: 'Amount SC',
    sortable: true,
    valueFormatter: params =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 2,
      }),
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'balanceSC',
    headerName: 'Balance SC',
    sortable: true,
    valueFormatter: params =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 2,
      }),
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'assignedUserId',
    headerName: 'AssignedUserID',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'viewActivities',
    headerName: 'View Activities',
    sortable: true,
    renderCell: cellValues => {
      return <TagCount values={cellValues} />;
    },
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'lastAutoActionId',
    headerName: 'Last Auto Action ID',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'viewApplications',
    headerName: 'View Applications',
    sortable: true,
    renderCell: cellValues => {
      return <ViewApplication values={cellValues} />;
    },
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'tagCount',
    headerName: 'Tag Count',
    sortable: true,
    minWidth: 80,
  },
  {
    field: 'pmtCount',
    headerName: 'Pmt Count',
    sortable: true,
    minWidth: 80,
  },
  {
    field: 'lastAutoActionDate',
    headerName: 'Last Auto Action Date',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_TIME_FORMAT)
        : '',
    minWidth: 120,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'lastAutoActionDays',
    headerName: 'Last Auto Action Days',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'lastActivityNote',
    headerName: 'LastActivityNote',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'pmtTermsDesc',
    headerName: 'PmtTermsDesc',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'status',
    headerName: 'Status',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'lastActivityDate',
    headerName: 'LastActivityDate',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_TIME_FORMAT)
        : '',
    minWidth: 120,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'postDate',
    headerName: 'PostDate',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_TIME_FORMAT)
        : '',
    minWidth: 120,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'closingTranDate',
    headerName: 'ClosingTranDate',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_TIME_FORMAT)
        : '',
    minWidth: 120,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'currId',
    headerName: 'CurrID',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'parentId',
    headerName: 'ParentID',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'soldToId',
    headerName: 'SoldToID',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'promiseToPayDate',
    headerName: 'PromiseToPayDate',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_TIME_FORMAT)
        : '',
    minWidth: 120,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'promiseToPayAmt',
    headerName: 'PromiseToPayAmt',
    valueFormatter: params =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 2,
      }),
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'resolvedDate',
    headerName: 'ResolvedDate',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_TIME_FORMAT)
        : '',
    minWidth: 120,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'invoiceCmnt',
    headerName: 'InvoiceCmnt',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'paySched',
    headerName: 'PaySched',
    sortable: true,
    renderCell: cellValues => {
      return <Checkbox disabled checked={cellValues.row.paySched} />;
    },
    minWidth: 50,
    renderHeaderFilter: params => <CheckUnCheckFilter {...params} />,
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
  {
    field: 'pmtTermsId',
    headerName: 'PmtTermsID',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'custPONo',
    headerName: 'CustPONo',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'closed',
    headerName: 'Closed',
    sortable: true,
    renderCell: ({row}) => {
      return <Checkbox disabled checked={row.closed} />;
    },
    minWidth: 50,
    renderHeaderFilter: params => <CheckUnCheckFilter {...params} />,
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
  {
    field: 'closedDate',
    headerName: 'ClosedDate',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_TIME_FORMAT)
        : '',
    minWidth: 120,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'raesonCode',
    headerName: 'Reason Code',
    sortable: false,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'inDispute',
    headerName: 'InDispute',
    sortable: true,
    renderCell: ({row}) => {
      return <Checkbox disabled checked={row.inDispute} />;
    },
    minWidth: 80,
    renderHeaderFilter: params => <CheckUnCheckFilter {...params} />,
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
  {
    field: 'excludeAging',
    headerName: 'ExcludeAging',
    sortable: true,
    renderCell: ({row}) => {
      return <Checkbox disabled checked={row.excludeAging} />;
    },
    minWidth: 80,
    renderHeaderFilter: params => <CheckUnCheckFilter {...params} />,
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
  {
    field: 'resolved',
    headerName: 'Resolved',
    sortable: true,
    renderCell: ({row}) => {
      return (
        <Checkbox
          disabled={!row.tagged || row.tranType === TRAN_TYPE.CR}
          checked={row.resolved}
        />
      );
    },
    minWidth: 80,
    renderHeaderFilter: params => <CheckUnCheckFilter {...params} />,
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
  {
    field: 'disputeDate',
    headerName: 'DisputeDate',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_TIME_FORMAT)
        : '',
    minWidth: 120,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'lastActivityId',
    headerName: 'LastActivity',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'disputeAmt',
    headerName: 'DisputeAmt',
    valueFormatter: params =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 2,
      }),
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'disputeCodeDate',
    headerName: 'DisputeCodeDate',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_TIME_FORMAT)
        : '',
    minWidth: 120,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'daysSinceOpenActivity',
    headerName: 'DaysSinceOpenActivity',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'daysSinceClosedActivity',
    headerName: 'DaysSinceClosedActivity',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'disputeNote',
    headerName: 'Dispute Note',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'resolvedDateDays',
    headerName: 'ResolvedDateDays',
    sortable: true,
    minWidth: 120,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'lastActivityUserId',
    headerName: 'Last Activity UserID',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'lastActivityDays',
    headerName: 'LastActivityDays',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'disputeDateDays',
    headerName: 'DisputeDateDays',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'tranAmtHC',
    headerName: 'AmountHC',
    valueFormatter: params =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 2,
      }),
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'balanceHC',
    headerName: 'BalanceHC',
    valueFormatter: params =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 2,
      }),
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'disputeCodeDateDays',
    headerName: 'DisputeCodeDateDays',
    sortable: true,
    valueFormatter: params =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 2,
      }),
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'lastPmtDateDays',
    headerName: 'LastPmtDateDays',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'invUDF1',
    headerName: 'InvUDF1',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'invUDF2',
    headerName: 'InvUDF2',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'invUDF3',
    headerName: 'InvUDF3',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'invUDF4',
    headerName: 'InvUDF4',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'invUDF5',
    headerName: 'InvUDF5',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'invUDF6',
    headerName: 'InvUDF6',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'invUDF7',
    headerName: 'InvUDF7',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'invUDF8',
    headerName: 'InvUDF8',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'invUDF9',
    headerName: 'InvUDF9',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'invUDF10',
    headerName: 'InvUDF10',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'disputeUDF1',
    headerName: 'DisputeUDF1',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'disputeUDF2',
    headerName: 'DisputeUDF2',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'disputeUDF3',
    headerName: 'DisputeUDF3',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'disputeUDF4',
    headerName: 'DisputeUDF4',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'disputeUDF5',
    headerName: 'DisputeUDF5',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'userId',
    headerName: 'UserID',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'dueDateDays',
    headerName: 'DueDateDays',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'tranDateDays',
    headerName: 'TranDateDays',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'daysDueToPay',
    headerName: 'DaysDueToPay',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'daysDueTo1stCom',
    headerName: 'DaysDueTo1stCom',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'daysDueToLastCom',
    headerName: 'DaysDueToLastCom',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'days1stComToPay',
    headerName: 'Days1stComToPay',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'daysLastComToPay',
    headerName: 'DaysLastComToPay',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'primarySperName',
    headerName: 'PrimarySperName',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'percentPaid',
    headerName: 'PercentPaid',
    sortable: true,
    valueFormatter: params =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 2,
      }),
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'daysPaidLate',
    headerName: 'DaysPaidLate',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'lastPmtDate',
    headerName: 'LastPmtDate',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 120,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'lastPmtAmtHC',
    headerName: 'LastPmtAmtHC',
    sortable: true,
    valueFormatter: params =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 2,
      }),
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'lastPmtAmt',
    headerName: 'LastPmtAmt',
    sortable: true,
    valueFormatter: params =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 2,
      }),
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'invcAgeCurrent',
    headerName: 'Due Now',
    sortable: true,
    valueFormatter: params =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 2,
      }),
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'invcAgeFuture',
    headerName: 'Due Future',
    sortable: true,
    valueFormatter: params =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 2,
      }),
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'invcAge1',
    headerName: 'Late 31-60 Days',
    sortable: true,
    valueFormatter: params =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 2,
      }),
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'invcAge2',
    headerName: 'Late 61-90 Days',
    sortable: true,
    valueFormatter: params =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 2,
      }),
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'invcAge3',
    headerName: 'Late 91-120 Days',
    sortable: true,
    valueFormatter: params =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 2,
      }),
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'invcAge4',
    headerName: 'Late 121+ Days',
    sortable: true,
    valueFormatter: params =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 2,
      }),
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'customerSperName',
    headerName: 'CustomerSperName',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'invcContactName',
    headerName: 'InvcContactName',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'invcContactName2',
    headerName: 'InvcContactName2',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'ovrdInvcContact1',
    headerName: 'OvrdInvcContact',
    sortable: true,
    renderCell: ({row}) => {
      return <Checkbox disabled checked={row.ovrdInvcContact1} />;
    },
    minWidth: 80,
    renderHeaderFilter: params => <CheckUnCheckFilter {...params} />,
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
  {
    field: 'ovrdInvcContact2',
    headerName: 'OvrdInvcContact2',
    sortable: true,
    renderCell: ({row}) => {
      return <Checkbox disabled checked={row.ovrdInvcContact2} />;
    },
    minWidth: 80,
    renderHeaderFilter: params => <CheckUnCheckFilter {...params} />,
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
  {
    field: 'insertDate',
    headerName: 'Insert Date',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 120,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'insertDateDays',
    headerName: 'Insert Date Days',
    sortable: true,
    valueFormatter: params =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 2,
      }),
    minWidth: 120,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'staxAmt',
    headerName: 'StaxAmt',
    sortable: true,
    valueFormatter: params =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 2,
      }),
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'updateDate',
    headerName: 'Update Date',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 120,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'staxAmtHC',
    headerName: 'StaxAmtHC',
    sortable: true,
    valueFormatter: params =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 2,
      }),
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'shipToAddrName',
    headerName: 'ShipToAddrName',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'shipToAddrLine1',
    headerName: 'ShipToAddrLine1',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'shipToAddrLine2',
    headerName: 'ShipToAddrLine2',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'shipToAddrLine3',
    headerName: 'ShipToAddrLine3',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'shipToAddrLine4',
    headerName: 'ShipToAddrLine4',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'shipToAddrLine5',
    headerName: 'ShipToAddrLine5',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'shipToCity',
    headerName: 'ShipToCity',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'shipToState',
    headerName: 'ShipToState',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'shipToPostalCode',
    headerName: 'ShipToPostalCode',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'shipToCountry',
    headerName: 'ShipToCountry',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'keepInSync',
    headerName: 'Do Not Sync',
    sortable: true,
    renderCell: ({row}) => {
      return <Checkbox disabled checked={row.keepInSync} />;
    },
    minWidth: 80,
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
  {
    field: 'custInvcForm',
    headerName: 'CustInvcForm',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'custClassId',
    headerName: 'CustClassID',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'reference',
    headerName: 'Reference',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'customerUDF1',
    headerName: 'customerUDF1',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'customerUDF2',
    headerName: 'CustomerUDF2',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'customerUDF3',
    headerName: 'CustomerUDF3',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'customerUDF4',
    headerName: 'CustomerUDF4',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'customerUDF5',
    headerName: 'CustomerUDF5',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'customerUDF6',
    headerName: 'CustomerUDF6',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'customerUDF7',
    headerName: 'CustomerUDF7',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'customerUDF8',
    headerName: 'CustomerUDF8',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'customerUDF9',
    headerName: 'CustomerUDF9',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'customerUDF10',
    headerName: 'CustomerUDF10',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },

  {
    field: 'amount01',
    headerName: 'Amount01',
    sortable: true,
    valueFormatter: params =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 3,
      }),
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'amount02',
    headerName: 'Amount02',
    sortable: true,
    valueFormatter: params =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 3,
      }),
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'amount03',
    headerName: 'Amount03',
    sortable: true,
    valueFormatter: params =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 3,
      }),
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'amount04',
    headerName: 'Amount04',
    sortable: true,
    valueFormatter: params =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 3,
      }),
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'amount05',
    headerName: 'Amount05',
    sortable: true,
    valueFormatter: params =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 3,
      }),
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'amount06',
    headerName: 'Amount06',
    sortable: true,
    valueFormatter: params =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 3,
      }),
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'amount07',
    headerName: 'Amount07',
    sortable: true,
    valueFormatter: params =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 3,
      }),
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'amount08',
    headerName: 'Amount08',
    sortable: true,
    valueFormatter: params =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 3,
      }),
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'amount09',
    headerName: 'Amount09',
    sortable: true,
    valueFormatter: params =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 3,
      }),
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'amount10',
    headerName: 'Amount10',
    sortable: true,
    valueFormatter: params =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 3,
      }),
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'date01',
    headerName: 'Date01',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 120,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'date02',
    headerName: 'Date02',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 120,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'date03',
    headerName: 'Date03',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 120,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'date04',
    headerName: 'Date04',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 120,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'date05',
    headerName: 'Date05',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 120,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'date06',
    headerName: 'Date06',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 120,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'date07',
    headerName: 'Date07',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 120,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'date08',
    headerName: 'Date08',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 120,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'date09',
    headerName: 'Date09',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 120,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'date10',
    headerName: 'Date10',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 120,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'totalPendPmt',
    headerName: 'TotalPendPmt',
    sortable: true,
    valueFormatter: params =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 2,
      }),
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'copyExternalPDFMessage',
    headerName: 'CopyExternalPDFMessage',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'consolidatedInvcNo',
    headerName: 'Consolidated Invc No',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'creditClassId',
    headerName: 'CreditClassID',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'predictedPayDate',
    headerName: 'Predicted Payment Date',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 120,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'predictedPayAmt',
    headerName: 'Predicted Payment Amount',
    sortable: true,
    valueFormatter: params =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 2,
      }),
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'predictedPayReason',
    headerName: 'Predicted Payment Reason',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'pdfFilename',
    headerName: 'PDFFilename',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'internalPDFFilename',
    headerName: 'InternalPDFFilename',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'externalPDFFilename',
    headerName: 'ExternalPDFFilename',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
];
