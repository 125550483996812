import {Button} from '@mui/material';
import {GridToolbarQuickFilter} from '@mui/x-data-grid';
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid-pro';
import CachedIcon from '@mui/icons-material/Cached';
import SettingsIcon from '@mui/icons-material/Settings';
import DescriptionIcon from '@mui/icons-material/Description';
import {FaArrowsAltH} from 'react-icons/fa';
import SummarizeIcon from '@mui/icons-material/Summarize';
import {constants} from '../../../../constants/common';
import {CustomFilterButton} from '../../../../ui/data';

export default function UserInvoicesToolbar({
  onRefresh,
  exportToExcel,
  applyBestFit,
  onReportClicked,
  showReportsButton,
  onViewSettings,
}: {
  onRefresh: () => void;
  exportToExcel: () => void;
  applyBestFit: () => void;
  onReportClicked: () => void;
  showReportsButton: boolean;
  onViewSettings: () => void;
}) {
  return (
    <GridToolbarContainer>
      <CustomFilterButton />
      <GridToolbarColumnsButton />
      <Button startIcon={<DescriptionIcon />} onClick={exportToExcel}>
        {constants.EXPORT_TO_EXCEL}
      </Button>
      <Button startIcon={<CachedIcon />} onClick={onRefresh}>
        {constants.REFRESH}
      </Button>
      <Button startIcon={<FaArrowsAltH />} onClick={applyBestFit}>
        {constants.BEST_FIT}
      </Button>
      <GridToolbarDensitySelector />
      <Button startIcon={<SettingsIcon />} onClick={onViewSettings}>
        {constants.VIEW_SETTINGS}
      </Button>
      {showReportsButton ? (
        <Button startIcon={<SummarizeIcon />} onClick={onReportClicked}>
          {constants.REPORTS}
        </Button>
      ) : null}

      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
}
