import {LocalizationProvider} from '@mui/x-date-pickers';
import {Dayjs} from 'dayjs';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {FormControl} from '@mui/material';
import {
  MobileDateTimePicker,
  MobileDateTimePickerProps,
} from '@mui/x-date-pickers/MobileDateTimePicker';

interface IDateTimePicker extends MobileDateTimePickerProps<Dayjs> {
  /**
   * onChange event
   */
  onChange: (value: Dayjs | null) => void;
  /**
   * default value for date picker
   */
  defaultValue?: Dayjs;
  /**
   * selected date value
   */
  value?: Dayjs;
  /**
   * date picker label
   */
  label?: string;
  /**
   * available max date
   */
  maxDate?: Dayjs;
  /**
   * available min date
   */
  minDate?: Dayjs;
  /**
   * disable date picker
   */
  disabled?: boolean;

  margin?: 'none' | 'normal' | 'dense';

  fullWidth?: boolean;
  /**
   * readOnly datepicker
   */
  readOnly?: boolean;
}

/**
 * Date Picker component to display Date picker.
 * More information available here https://mui.com/x/react-date-pickers/date-picker/
 */

const DateTimePicker: React.FC<IDateTimePicker> = ({
  label,
  value,
  onChange,
  disabled,
  readOnly,
  fullWidth = false,
  margin = 'none',
  ...otherProps
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <FormControl fullWidth={fullWidth} margin={margin}>
        <MobileDateTimePicker
          label={label}
          value={value}
          onChange={onChange}
          disabled={disabled}
          readOnly={readOnly}
          {...otherProps}
        />
      </FormControl>
    </LocalizationProvider>
  );
};

export default DateTimePicker;
