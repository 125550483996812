import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import {useEffect, useRef, useState} from 'react';
import {
  GridColDef,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridLogicOperator,
  GridPaginationModel,
  GridSortModel,
} from '@mui/x-data-grid-pro';
import {useDispatch, useSelector} from 'react-redux';
import {FileUpload} from 'primereact';
import {
  DataGrid,
  FilterPopoverProvider,
  QueryReturnValue,
  convertMUIToQueryBuilder,
  generateJsonAndSql,
  generateJsonFromSql,
} from '../../../ui/data';
import {
  applyBestFit,
  applyDatatableSettings,
  convertAndAppendExistingFilters,
  getGridColumnsSettings,
  getSelectedRowsByKey,
  hasFilterValue,
  hasQuickFilterValue,
  updateColumnSettings,
} from '../../../lib/commonTableHelpers';
import {
  allAccountsExportToExcel,
  cleanUpAllAccountsData,
  getAllAccountsAndViewSettings,
  getAllAccountsTotals,
  updateAllAccountsViewSettingObject,
} from '../../../store/actions/allAccountsActions';
import {getUserData} from '../../../store/actions/userActions';
import {
  MAX_EXCEL_DOWNLOAD,
  MODE_TYPES,
  PAGE_SIZE,
  REPORT_TYPE,
} from '../../../utils/constants';
import AllAccountsToolbar from './AllAccountsToolbar';
import {allAccountsColumns} from './column';
import {ViewSettings} from './ViewSettings';
import {isEmpty} from '../../../lib/utils';
import {useUserSettings} from '../../../hooks/useUserSettings';
import {constants, gridModeTypes} from '../../../constants/common';
import {Dialog} from '../../../ui';
import Backdrop from '../../../ui/utils/backdrop/Backdrop';
import {Typography} from '../../../ui/displays';
import {initialQuery} from '../../../ui/data/query-builder/queryHelper';
import {ISnackbarProps} from '../../../ui/feedback/snackbar/Snackbar';
import RenderDialogAndSnackbarForReports from '../../common/reports/RenderDialogAndSnackbarForReports';
import {
  ColumnSetting,
  IViewSettings,
} from '../../common/view-settings/interface';
import ViewSettingsModal from '../../common/view-settings/ViewSettingsModal';
import {useComponentMountStatus} from '../../../hooks/useComponentMountStatus';
import {shouldResetFilterModel} from '../../../utils/filterUtils';

const AllAccounts = ({showTotalsGrid}: {showTotalsGrid: boolean}) => {
  const dispatch = useDispatch<any>();
  const location = useLocation();
  const {search} = location;
  const navigate = useNavigate();
  const {userKey, groupKey} = getUserData();
  const [columns, setColumns] = useState<any[]>([]);
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({});
  const [bestFit, setBestFit] = useState<boolean>(false);
  const [bulkActivityDisabled, setBulkActivityDisabled] =
    useState<boolean>(false);
  const [bestFitColumns, setBestFitColumns] = useState<GridColDef[]>([]);
  const [selectedAccounts, setSelectedAccounts] = useState<any[]>([]);
  const [sortColumn, setSortColumn] = useState<GridSortModel>([]);
  const [loadingUploadDialog, setLoadingUploadDialog] = useState(false);
  const [showFileUploadDialog, setShowFileUploadDialog] = useState(false);
  const [showReportsPopup, setShowReportsPopup] = useState<boolean>(false);
  const [snackbarObj, setSnackbarObj] = useState<ISnackbarProps>({
    message: '',
    onClose: () => {},
    open: false,
    title: '',
    type: 'success',
  });
  const [files, setFiles] = useState<any[]>([]);
  const fileUploadRef = useRef(null);

  const {toastRef: toast} = useSelector((store: any) => store.generalReducer);
  const [openMenuOptions, setopenMenuOptions] = useState<null | HTMLElement>(
    null,
  );
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    logicOperator: GridLogicOperator.And,
    items: [],
  });
  const [skip, setSkip] = useState(0);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: PAGE_SIZE,
  });
  const [settingsKey, setSettingsKey] = useState<string | null>(null);
  const [isBulkUpload, setIsBulkUpload] = useState(false);
  const [customFilterSqlValue, setCustomFilterSqlValue] = useState<
    string | null
  >(null);
  const [customFilterJsonValue, setCustomFilterJsonValue] =
    useState<QueryReturnValue['json']>();
  const [quickFilterValue, setQuickFilterValue] = useState<string>('');
  const {userSettings, userFeatures} = useUserSettings();
  const [showViewSettingsPopup, setShowViewSettingsPopup] =
    useState<boolean>(false);

  const isComponentMounted = useComponentMountStatus(cleanUpAllAccountsData);

  const enhancedAdminFeature = userFeatures.find(
    (obj: any) => obj.featureId === constants.ENABLE_ENHANCED_ADMIN,
  );

  /**
   * Generates a SQL-like query string for filtering customer data.
   *
   * @param {string} value - The value to filter the customer data.
   * @param {string | null} filterQuery - The existing query string.
   * @returns {string} The generated query string.
   */
  const getQuickFilterValue = (value: string, filterQuery: string | null) => {
    let queryString = filterQuery || '';
    if (value) {
      if (!isEmpty(queryString)) {
        queryString += ' and ';
      }
      queryString += `(custId LIKE '%${value}%' or custName LIKE '%${value}%') `;
      return queryString;
    }
    return filterQuery || '';
  };

  const {
    allAccountsData,
    allAccountsCount,
    allAccountsLoading,
    viewSettings,
    settingId,
    selectAllAccountsData,
    fetchingAllAccounts,
    fetchedAllAccounts,
    selectedAllAccountsPageSize,
    allAccountsErrorMessage,
  } = useSelector((store: any) => store.allAccountsReducer);

  const [showEmptyFileUploadedErrorPopup, setShowEmptyFileUploadedErrorPopup] =
    useState(false);

  const [allSelected, setAllSelected] = useState(false);
  const [showSelectRecordError, setShowSelectRecordError] = useState(false);

  useEffect(() => {
    const showPopup = Boolean(allAccountsErrorMessage);
    if (showPopup) setShowEmptyFileUploadedErrorPopup(showPopup);
  }, [allAccountsErrorMessage]);

  const getUpdatedSqlAndJson = (
    quickSearchValue: string,
    sql: string | null,
    json: QueryReturnValue['json'] | undefined,
  ) => {
    const updatedSql = getQuickFilterValue(quickSearchValue, sql);
    const updatedJson = updatedSql ? generateJsonFromSql(updatedSql) : json;
    return {updatedSql, updatedJson};
  };

  const getAllAccounts = ({
    sortQuery = [],
    SettingKey,
    isSelectAll,
    pageSizeParam = PAGE_SIZE,
    skipParam = 0,
    filesParam = [],
    isBulkUploadParam = false,
    filterQuery = undefined,
    sqlQuery = null,
    quickSearchValue = '',
  }: {
    sortQuery: any[];
    SettingKey: string;
    isSelectAll: boolean;
    pageSizeParam: number;
    skipParam: number;
    filesParam?: any[];
    isBulkUploadParam?: boolean;
    filterQuery: QueryReturnValue['json'] | undefined;
    sqlQuery: string | null;
    quickSearchValue: string;
  }) => {
    const {updatedSql, updatedJson} = getUpdatedSqlAndJson(
      quickSearchValue,
      sqlQuery,
      filterQuery,
    );

    dispatch(
      // @ts-ignore
      getAllAccountsAndViewSettings({
        userKey,
        groupKey,
        SettingKey,
        sortQuery,
        isSelectAll,
        skip: skipParam,
        pageSize: pageSizeParam,
        files: filesParam,
        isBulkUpload: isBulkUploadParam,
        filterQuery: updatedJson,
        sqlQuery: updatedSql,
      }),
    );
    if (showTotalsGrid) {
      dispatch(
        getAllAccountsTotals({
          userKey,
          groupKey,
          SettingKey,
          sortQuery,
          isSelectAll,
          isBulkUploadParam: isBulkUpload,
          filterQuery: updatedJson,
          sqlQuery: updatedSql,
        }),
      );
    }
  };

  const getAllAccountsTotalsData = () => {
    const {updatedSql, updatedJson} = getUpdatedSqlAndJson(
      quickFilterValue,
      customFilterSqlValue,
      customFilterJsonValue,
    );
    dispatch(
      // @ts-ignore
      getAllAccountsTotals({
        userKey,
        groupKey,
        SettingKey: settingId,
        sortQuery: sortColumn,
        isSelectAll: false,
        isBulkUploadParam: isBulkUpload,
        filterQuery: updatedJson,
        sqlQuery: updatedSql,
        quickSearchValue: quickFilterValue,
      }),
    );
  };

  // Reset filter model
  const resetFilterModel = () => {
    setFilterModel({
      logicOperator: GridLogicOperator.And,
      items: [],
    });
  };

  // reset pagination
  const resetPagination = () => {
    setPaginationModel({
      pageSize: paginationModel.pageSize,
      page: 0,
    });
  };

  /** Use Effect to fetch list of all accounts when filter,
   * sort and other properties are selected */
  useEffect(() => {
    if (settingsKey) {
      getAllAccounts({
        SettingKey: settingsKey,
        sortQuery: sortColumn,
        isSelectAll: false,
        skipParam: 0,
        pageSizeParam: paginationModel.pageSize,
        isBulkUploadParam: false,
        filterQuery: customFilterJsonValue,
        sqlQuery: customFilterSqlValue,
        quickSearchValue: quickFilterValue,
      });
      setIsBulkUpload(false);
      resetFilterModel();
      resetPagination();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsKey]);

  const fetchAccounts = (
    json: QueryReturnValue['json'],
    sql: string | null,
    quickSearchValue: string,
  ) => {
    getAllAccounts({
      SettingKey: settingId,
      sortQuery: sortColumn,
      isSelectAll: false,
      skipParam: 0,
      pageSizeParam: paginationModel.pageSize,
      isBulkUploadParam: false,
      filterQuery: json,
      sqlQuery: json.rules.length > 0 ? sql : null,
      quickSearchValue,
    });

    setIsBulkUpload(false);
    setAllSelected(false);
    resetPagination();
  };

  /** Use effect to update and set columns based on view settings response */
  useEffect(() => {
    if (viewSettings && viewSettings.settingsKey && isComponentMounted) {
      applyDatatableSettings(
        viewSettings,
        columns,
        allAccountsColumns,
        undefined,
        setColumns,
        setColumnVisibilityModel,
        setSortColumn,
      );
      const jsonData =
        viewSettings?.filter?.length > 0
          ? generateJsonFromSql(viewSettings?.filter)
          : initialQuery;

      setSettingsKey(viewSettings.settingsKey);
      setCustomFilterSqlValue(viewSettings?.filter || '');
      setCustomFilterJsonValue(jsonData);
    }
  }, [viewSettings?.settingsKey]);

  const determinePageSize = (count: number) => {
    if (count <= 10) return 10;
    if (count <= 20) return 20;
    if (count <= 50) return 50;
    if (count <= 100) return 100;
    return 500;
  };

  const resetUploadDialog = () => {
    setLoadingUploadDialog(false);
    setShowFileUploadDialog(false);
    setPaginationModel({
      page: 0,
      pageSize: determinePageSize(selectedAllAccountsPageSize),
    });
    setFiles([]);
  };

  useEffect(() => {
    if (!fetchingAllAccounts && fetchedAllAccounts && showFileUploadDialog) {
      resetUploadDialog();
    }
  }, [fetchingAllAccounts, fetchedAllAccounts]);

  /** Use effect to fetch the data for totals grid */
  useEffect(() => {
    if (showTotalsGrid) {
      getAllAccountsTotalsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showTotalsGrid]);

  /** All accounts grid props method */

  /**
   * onPageChange is prop set for All accounts grid to handle pagination
   * @param args GridPaginationModel object which has page which starts from 0 and skip properties
   */
  const onPageChange = (args: GridPaginationModel) => {
    const pageSkip =
      args.pageSize !== paginationModel.pageSize
        ? 0
        : (args.page + 1) * args.pageSize - args.pageSize;
    setPaginationModel({
      pageSize: args.pageSize,
      page: args.pageSize !== paginationModel.pageSize ? 0 : args.page,
    });
    setSkip(pageSkip);
    getAllAccounts({
      SettingKey: settingId,
      sortQuery: sortColumn,
      isSelectAll: false,
      skipParam: pageSkip,
      pageSizeParam: args.pageSize,
      isBulkUploadParam: false,
      filterQuery: customFilterJsonValue,
      sqlQuery: customFilterSqlValue,
      quickSearchValue: quickFilterValue,
    });
    if (!isBulkUpload) {
      setSelectedAccounts([]);
    }
  };

  /**
   * onSortChange is prop set for all accounts grid to sorting
   * @param args GridPaginationModel array of object which field and sort properties
   */
  const onSortChange = (args: GridSortModel) => {
    setSortColumn(args);
    getAllAccounts({
      SettingKey: settingId,
      sortQuery: args,
      isSelectAll: false,
      skipParam: skip,
      pageSizeParam: paginationModel.pageSize,
      isBulkUploadParam: isBulkUpload,
      filterQuery: customFilterJsonValue,
      sqlQuery: customFilterSqlValue,
      quickSearchValue: quickFilterValue,
    });
  };

  /**
   * onColumnRowSelect Update state on checkbox selection
   * @param newSelectionModel is selected row object
   */
  const onColumnRowSelect = (newSelectionModel: any) => {
    const selectedRowData =
      allSelected || isBulkUpload ? selectAllAccountsData : allAccountsData;
    const selectedRowsObject = getSelectedRowsByKey(
      newSelectionModel,
      selectedRowData,
      'id',
    ).filter((obj: any) => obj !== undefined);

    setSelectedAccounts(selectedRowsObject);
  };

  /** All Toolbar methods */

  /**
   * On Refresh refetch list of activities
   */
  const onRefresh = (isGridRefresh: boolean) => {
    if (!isGridRefresh) {
      setSelectedAccounts([]);
    }
    getAllAccounts({
      SettingKey: settingId,
      sortQuery: sortColumn,
      isSelectAll: false,
      skipParam: isBulkUpload ? 0 : skip,
      pageSizeParam: paginationModel.pageSize,
      filesParam: files,
      isBulkUploadParam: isBulkUpload,
      filterQuery: customFilterJsonValue,
      sqlQuery: customFilterSqlValue,
      quickSearchValue: quickFilterValue,
    });
  };

  const selectAllRows = () => {
    getAllAccounts({
      SettingKey: settingId,
      sortQuery: sortColumn,
      isSelectAll: true,
      skipParam: skip,
      pageSizeParam: paginationModel.pageSize,
      filterQuery: customFilterJsonValue,
      sqlQuery: customFilterSqlValue,
      quickSearchValue: quickFilterValue,
    });
    setAllSelected(true);
    setopenMenuOptions(null);
  };

  useEffect(() => {
    if (allSelected) {
      setSelectedAccounts(selectAllAccountsData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectAllAccountsData]);

  useEffect(() => {
    const isBulkActivityDisabled =
      selectedAccounts.length === 0 ||
      (userSettings.adminUser !== 1 && enhancedAdminFeature.featureValue !== 1);
    setBulkActivityDisabled(isBulkActivityDisabled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccounts]);

  /**
   * onFilterChange is prop set for all accounts grid to filter
   * @param args GridFilterModel array of object which field, value and operator properties
   */
  const onFilterChange = (args: GridFilterModel) => {
    setQuickFilterValue(args.quickFilterValues?.[0] || '');
    if (!hasFilterValue(args) && !hasQuickFilterValue(args)) {
      return;
    }
    const queryBuilderQuery = convertMUIToQueryBuilder(args);
    const valueToConvert = convertAndAppendExistingFilters(
      {...customFilterJsonValue},
      queryBuilderQuery,
      filterModel,
    );
    const {json, sql} = generateJsonAndSql(valueToConvert);
    setCustomFilterSqlValue(json.rules.length > 0 ? sql : null);
    setCustomFilterJsonValue(json);
    setFilterModel(args);
    fetchAccounts(json, sql, args.quickFilterValues?.[0] || '');
  };

  const onQueryFilterApply = (data: QueryReturnValue) => {
    // if filter is empty then reset filter model
    if (shouldResetFilterModel(data)) {
      resetFilterModel();
    }
    setCustomFilterSqlValue(data.json.rules.length > 0 ? data.sql : null);
    setCustomFilterJsonValue(data.json);
    fetchAccounts(data.json, data.sql, quickFilterValue);
  };

  /**
   * Download excel report
   */
  const exportToExcel = () => {
    if (Number(allAccountsCount) > MAX_EXCEL_DOWNLOAD) {
      toast?.current.show({
        severity: 'warn',
        summary: '',
        detail: `Maximum of ${MAX_EXCEL_DOWNLOAD} records will be downloaded`,
      });
    }

    const {updatedSql, updatedJson} = getUpdatedSqlAndJson(
      quickFilterValue,
      customFilterSqlValue,
      customFilterJsonValue,
    );
    dispatch(
      // @ts-ignore
      allAccountsExportToExcel({
        userKey,
        groupKey,
        modeType: 'AllAccounts',
        SettingKey: settingId,
        sortQuery: sortColumn,
        filterQuery: updatedJson,
        sqlQuery: updatedSql,
      }),
    );
  };

  const showNewActivity = () => {
    const params = new URLSearchParams(search);
    params.set('activityId', '-1');

    const customerKeys: number[] = selectedAccounts.map(obj => {
      return obj.custKey;
    });

    const props = {
      taskActKey: -1,
      custKey: selectedAccounts[0].custKey,
      groupKey,
      custName: selectedAccounts[0].custName,
      taskKey: -1,
      newActivity: true,
      ModeType: MODE_TYPES.new,
      taggedPmts: [],
      taggedInvoices: [],
      customerKeys,
      selectedAccountsCount: selectedAccounts.length,
    };

    navigate(`activity?${params.toString()}`, {
      state: {...props, from: location},
    });
  };

  const applyGridBestFit = () => {
    applyBestFit(columns, bestFit, setBestFit, setBestFitColumns);
  };

  const onHandleFileUpload = async (event: any) => {
    const {files: selectedFiles} = event;
    setFiles(selectedFiles);
    setLoadingUploadDialog(true);
    getAllAccounts({
      SettingKey: settingId,
      sortQuery: sortColumn,
      isSelectAll: false,
      skipParam: 0,
      pageSizeParam: paginationModel.pageSize,
      filesParam: selectedFiles,
      isBulkUploadParam: true,
      filterQuery: customFilterJsonValue,
      sqlQuery: customFilterSqlValue,
      quickSearchValue: quickFilterValue,
    });
    setSelectedAccounts([]);
    setIsBulkUpload(true);
    setAllSelected(false);
    resetFilterModel();
    resetPagination();
  };

  const onEmptyFileUploadedErrorClose = () => {
    getAllAccounts({
      SettingKey: settingId,
      sortQuery: sortColumn,
      isSelectAll: false,
      skipParam: 0,
      pageSizeParam: 10,
      filesParam: files,
      isBulkUploadParam: false,
      filterQuery: customFilterJsonValue,
      sqlQuery: customFilterSqlValue,
      quickSearchValue: quickFilterValue,
    });
    setIsBulkUpload(false);
    setShowEmptyFileUploadedErrorPopup(false);
    resetFilterModel();
    setAllSelected(false);
    setPaginationModel({page: 0, pageSize: 10});
  };

  const onReportClicked = () => {
    if (selectedAccounts.length === 0) {
      setShowSelectRecordError(true);
    } else {
      setShowReportsPopup(true);
    }
  };

  const handleOnSnackbarClose = () => {
    setSnackbarObj(prev => ({
      ...prev,
      open: false,
    }));
  };
  const updateSnackbarObj = ({type, title, open, message}: ISnackbarProps) => {
    setSnackbarObj({type, title, open, message, onClose: () => {}});
  };

  const updateTableOnColumnSettingsChange = (
    updatedColumns: ColumnSetting[],
    settingKey: string,
  ) => {
    if (settingKey === viewSettings?.settingsKey) {
      updateColumnSettings(
        updatedColumns,
        columns,
        setColumns,
        setColumnVisibilityModel,
      );
    }
  };

  const onViewSettings = () => {
    setShowViewSettingsPopup(true);
  };

  const handleCloseViewSettingsPopup = () => {
    setShowViewSettingsPopup(false);
  };

  const {columnSettingJson, sortSettingJson} = getGridColumnsSettings(
    viewSettings?.columnSetting,
    columnVisibilityModel,
    sortColumn,
  );
  return (
    <>
      <RenderDialogAndSnackbarForReports
        handleOnSnackbarClose={handleOnSnackbarClose}
        selectedRows={selectedAccounts}
        setShowReportsPopup={setShowReportsPopup}
        setShowSelectRecordError={setShowSelectRecordError}
        showReportsPopup={showReportsPopup}
        showSelectRecordError={showSelectRecordError}
        snackbarObj={snackbarObj}
        updateSnackbarObj={updateSnackbarObj}
        reportType={REPORT_TYPE.ACCOUNT}
        keyName="custKey"
      />
      <Dialog
        open={showFileUploadDialog}
        onClose={() => setShowFileUploadDialog(false)}
        title={constants.BULK_FILE_UPLOAD}
        maxWidth="md"
        fullWidth
      >
        <div className="position-relative">
          <Backdrop
            open={loadingUploadDialog}
            isLoading={loadingUploadDialog}
          />
          <FileUpload
            ref={fileUploadRef}
            mode="advanced"
            multiple
            name="upload[]"
            chooseLabel={constants.UPLOAD}
            accept=".csv,.xlsx"
            maxFileSize={25000000}
            customUpload
            uploadHandler={onHandleFileUpload}
          />
        </div>
      </Dialog>
      <Outlet context={{onReload: onRefresh}} />
      <Dialog
        open={showEmptyFileUploadedErrorPopup}
        title={constants.INFO}
        showCancelButton={false}
        showConfirmButton
        onConfirm={onEmptyFileUploadedErrorClose}
        onClose={onEmptyFileUploadedErrorClose}
        maxWidth="xs"
        fullWidth
      >
        <Typography>{allAccountsErrorMessage}</Typography>
      </Dialog>
      <ViewSettingsModal
        viewName="Accounts"
        mode={gridModeTypes.AllAccounts}
        updateTableOnColumnSettingsChange={updateTableOnColumnSettingsChange}
        handleCloseViewSettingsPopup={handleCloseViewSettingsPopup}
        customFilterSqlValue={customFilterSqlValue || ''}
        sortSettingJson={JSON.stringify(sortSettingJson) || ''}
        columnSettingJson={JSON.stringify(columnSettingJson) || ''}
        loadViewSetting={(row: IViewSettings) => {
          dispatch(
            updateAllAccountsViewSettingObject({
              settingId: row.settingsKey,
              viewSettings: row,
            }),
          );
        }}
        showViewSettingsPopup={showViewSettingsPopup}
      />
      <ViewSettings />
      <FilterPopoverProvider>
        <DataGrid
          {...(showTotalsGrid && {height: 700})}
          columns={bestFit ? bestFitColumns : columns}
          rows={allAccountsData}
          loading={allAccountsLoading}
          checkboxSelection
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityChange={data => setColumnVisibilityModel(data)}
          rowCount={allAccountsCount}
          onPageChange={onPageChange}
          onSortChange={onSortChange}
          onFilterChange={onFilterChange}
          sortModel={sortColumn}
          rowsSelected={selectedAccounts.map(item => item.id)}
          onColumnRowSelect={onColumnRowSelect}
          CustomToolbar={AllAccountsToolbar}
          customToolbarMethods={{
            onRefresh,
            exportToExcel,
            selectAllRows,
            onColumnRowSelect,
            applyGridBestFit,
            setopenMenuOptions,
            openMenuOptions,
            bulkActivityDisabled,
            showNewActivity,
            onHandleFileUpload,
            setShowFileUploadDialog,
            onReportClicked,
            onViewSettings,
          }}
          headerFilters
          filterModel={filterModel}
          paginationModel={paginationModel}
          showCustomFilters
          customFilterSqlValue={customFilterSqlValue}
          onQueryFilterApply={data => onQueryFilterApply(data)}
        />
      </FilterPopoverProvider>
    </>
  );
};

export default AllAccounts;
