import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

export const useComponentMountStatus = (cleanupAction?: any) => {
  const dispatch = useDispatch();
  const [isComponentMounted, setIsComponentMounted] = useState(false);

  useEffect(() => {
    setIsComponentMounted(true);
    return () => {
      if (cleanupAction) {
        dispatch(cleanupAction());
      }

      setIsComponentMounted(false);
    };
  }, [dispatch, cleanupAction]);

  return isComponentMounted;
};
