import {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {isEmpty} from '../lib/utils';

export const useCustKey = () => {
  const [searchParams] = useSearchParams();
  const [custKey, setCustKey] = useState(Number(searchParams.get('CustKey')));
  const selectedCustomer = useSelector(
    (store: any) => store.generalReducer?.selectedCustomer,
  );

  useEffect(() => {
    if (!isEmpty(selectedCustomer)) {
      setCustKey(selectedCustomer.custKey);
    }
  }, [selectedCustomer]);

  return {custKey};
};
