import dayjs from 'dayjs';
import {filterOperators} from '../../../../lib/commonTableHelpers';
import {constants} from '../../../../constants/common';
import {ViewEmail} from '../../../common/RowComponents';

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

export const reportingGroupLogsColumns = [
  {
    field: 'reportingGroupId',
    headerName: 'Reporting Group',
    sortable: true,
    minWidth: 200,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'userId',
    headerName: 'User ID',
    sortable: true,
    minWidth: 200,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'action',
    headerName: 'Action',
    sortable: true,
    minWidth: 200,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'view',
    headerName: 'View',
    sortable: false,
    renderCell: (cellValues: any) => {
      return <ViewEmail values={cellValues} />;
    },
    minWidth: 60,
    type: 'actions',
  },
  {
    field: 'fromAddress',
    headerName: 'From Address',
    sortable: true,
    minWidth: 230,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'sentDate',
    headerName: 'Sent Date',
    sortable: true,
    valueFormatter: (params: any) =>
      params?.value
        ? dayjs(params?.value).format(constants.DATE_TIME_FORMAT)
        : '',
    minWidth: 150,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'toAddress',
    headerName: 'To Address',
    sortable: true,
    minWidth: 230,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'numberOfCustomers',
    headerName: 'Customers',
    sortable: true,
    minWidth: 120,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'numberOfPages',
    headerName: 'Total Pages',
    sortable: true,
    minWidth: 120,
    type: 'number',
    filterOperators: filterOperators.number,
  },
];
