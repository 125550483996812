import {useDispatch, useSelector} from 'react-redux';
import {useState} from 'react';
import {CheckboxLabel} from '../../../ui/inputs';
import {ViewSettingsDropDown} from '../../common/view-settings/view-settings-dropdown/ViewSettingsDropDown';
import {updateAccountActivityViewSettingObject} from '../../../store/actions/activitiesOverviewActions';
import {Dialog} from '../../../ui/feedback';
import Typography from '../../../ui/displays/typography/Typography';
import {Box} from '../../../ui/layouts';
import {gridModeTypes} from '../../../constants/common';

export const ViewSettings = ({
  onHandleArchivedSelected,
  onHandleHighlightSelected,
  isShowingArchivedActivities,
  isShowingAllHighlightedActivities,
  isRowSelected,
  isSingleRowSelected,
}: {
  onHandleArchivedSelected: (flag: boolean) => void;
  onHandleHighlightSelected: (flag: boolean) => void;
  isShowingArchivedActivities: boolean;
  isShowingAllHighlightedActivities: boolean;
  isRowSelected: boolean;
  isSingleRowSelected: boolean;
}) => {
  const dispatch = useDispatch();
  const {settingId, viewSettings} = useSelector(
    (store: any) => store.activitiesOverviewReducer,
  );
  const [showSelectActivityError, setShowSelectActivityError] = useState(false);

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginY="12px"
      >
        <Box width="250px">
          <ViewSettingsDropDown
            settingId={settingId}
            viewName="Accounts/Communication"
            onViewSettingSelected={(selectedSettingId, selectedViewSettings) =>
              dispatch(
                updateAccountActivityViewSettingObject({
                  settingId: selectedSettingId,
                  viewSettings: selectedViewSettings,
                }),
              )
            }
            activityType={gridModeTypes.ActivitiesForCustomerAndUser}
            currentViewSettings={viewSettings}
            isQueryParamDefault={false}
          />
        </Box>
        <div>
          <CheckboxLabel
            label="Show all activities for highlighted row"
            checked={isShowingAllHighlightedActivities}
            onChange={e => {
              if (isRowSelected && isSingleRowSelected) {
                onHandleHighlightSelected(e.target.checked);
              } else {
                setShowSelectActivityError(true);
              }
            }}
          />
          <CheckboxLabel
            label="Show archived activities"
            checked={isShowingArchivedActivities}
            onChange={e => {
              onHandleArchivedSelected(e.target.checked);
            }}
          />
        </div>
      </Box>
      <Dialog
        title="Please select only one record"
        open={showSelectActivityError}
        onClose={() => setShowSelectActivityError(false)}
      >
        <Typography variant="body2">Please Select Only One Record</Typography>
      </Dialog>
    </>
  );
};
