/* eslint-disable no-nested-ternary */
import dayjs from 'dayjs';
import {constants} from '../../../constants/common';
import {filterOperators} from '../../../lib/commonTableHelpers';
import {SingleLink} from '../communication/RowComponents';

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

export const draftDashboardColumns = [
  {
    field: 'dateEntered',
    headerName: 'Activity Date',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_TIME_FORMAT)
        : '',
    minWidth: 150,
    type: 'dateTime',
    filterOperators: filterOperators.date,
  },
  {
    field: 'custName',
    headerName: 'Cust Name',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'custID',
    headerName: 'Cust ID',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'parentID',
    headerName: 'Parent ID',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'description',
    headerName: 'Issue',
    sortable: true,
    renderCell: cellValues => {
      return <SingleLink values={cellValues} />;
    },
    minWidth: 180,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'activityID',
    headerName: 'Activity ID',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'userID',
    headerName: 'UserID',
    minWidth: 120,
    sortable: true,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'priority',
    headerName: 'Priority',
    sortable: true,
    minWidth: 120,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'alertID',
    headerName: 'Activity Template',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'followupDate',
    headerName: 'FollowupDate',
    sortable: true,
    minWidth: 120,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_TIME_FORMAT)
        : '',
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'followupDesc',
    headerName: 'FollowupDesc',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'issueCompleteDate',
    headerName: 'IssueCompleteDate',
    sortable: true,
    minWidth: 120,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_TIME_FORMAT)
        : '',
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'activityCompleteDate',
    headerName: 'ActivityCompleteDate',
    sortable: true,
    minWidth: 120,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_TIME_FORMAT)
        : '',
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'taggedAmtHC',
    headerName: 'TaggedAmtHC',
    sortable: true,
    minWidth: 120,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'openActivityCount',
    headerName: 'OpenActivityCount',
    sortable: true,
    minWidth: 120,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'companyID',
    headerName: 'CompanyID',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'totalAge',
    headerName: 'TotalAge',
    sortable: true,
    minWidth: 120,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'taskKey',
    headerName: 'TaskKey',
    minWidth: 120,
    sortable: true,
    type: 'number',
    filterOperators: filterOperators.number,
  },

  {
    field: 'taskActKey',
    headerName: 'TaskActKey',
    minWidth: 120,
    sortable: true,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'fromAddress',
    headerName: 'From Address',
    minWidth: 120,
    sortable: true,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'toAddress',
    headerName: 'To Address',
    minWidth: 120,
    sortable: false,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'ccAddress',
    headerName: 'Cc Address',
    sortable: false,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'mailSubject',
    headerName: 'Mail Subject',
    sortable: true,
    type: 'string',
    filterOperators: filterOperators.string,
    minWidth: 120,
  },
  {
    field: 'customerClass',
    headerName: 'Cust Class ID',
    sortable: true,
    type: 'string',
    filterOperators: filterOperators.string,
    minWidth: 120,
  },
  {
    field: 'timeZoneId',
    headerName: 'Timezone ID',
    minWidth: 120,
    sortable: false,
    type: 'string',
    filterOperators: filterOperators.string,
  },
];
