import {gridModeTypes} from '../constants/common';
import data from '../lib/data';
import {isEmpty} from '../lib/utils';
import {downloadBlobFilePostApi} from './download';

export const fetchingDraftDashboard = ({
  userKey,
  sortQuery,
  globalFilterQuery,
  settingKey,
  skipRows,
  rowCount,
  sqlQuery,
}) => {
  let endpoint = `v4/api/activity/drafts?`;

  if (!isEmpty(skipRows)) {
    endpoint += `&%24skip=${skipRows}`;
  }

  if (!isEmpty(rowCount)) {
    endpoint += `&%24top=${rowCount}`;
  }

  const sortJson =
    isEmpty(sortQuery) || sortQuery?.length === 0
      ? null
      : {
          ViewSetting: {
            SortSetting: sortQuery.map((item, index) => {
              return {
                SortIndex: index + 1,
                SortCol: item.field,
                SortOrder: item.sort,
              };
            }),
          },
        };

  const options = {
    UserKey: userKey,
    SkipRows: 0,
    RowCount: 10,
    sortJson: sortJson ? JSON.stringify(sortJson) : null,
    mode: gridModeTypes.DraftActivity,
    SettingKey: settingKey,
    filterJson: globalFilterQuery ? JSON.stringify(globalFilterQuery) : null,
    filterSql: sqlQuery,
    source: 'react',
  };
  return data.post(endpoint, options);
};

export const deleteDraftActivity = options => {
  const endpoint = `v4/api/activity/drafts`;
  return data.delete(endpoint, {
    data: options,
    headers: {'Content-Type': 'application/json'},
  });
};

export const exportDraftDashboardData = ({
  userKey,
  sortQuery,
  globalFilterQuery,
  settingKey,
  sqlQuery,
  skipRows,
  rowCount,
}) => {
  const sortJson =
    isEmpty(sortQuery) || sortQuery?.length === 0
      ? null
      : {
          ViewSetting: {
            SortSetting: sortQuery.map((item, index) => {
              return {
                SortIndex: index + 1,
                SortCol: item.field,
                SortOrder: item.sort,
              };
            }),
          },
        };

  const options = {
    UserKey: userKey,
    SkipRows: 0,
    RowCount: 10,
    sortJson: sortJson ? JSON.stringify(sortJson) : null,
    mode: 'DraftActivity',
    SettingKey: settingKey,
    filterJson: globalFilterQuery ? JSON.stringify(globalFilterQuery) : null,
    filterSql: sqlQuery,
    source: 'react',
  };

  let endpoint = `v4/api/export/DraftActivity?`;

  if (!isEmpty(skipRows)) {
    endpoint += `&%24skip=${skipRows}`;
  }

  if (!isEmpty(rowCount)) {
    endpoint += `&%24top=${rowCount}`;
  }

  return downloadBlobFilePostApi(endpoint, options);
};
