import {useState, useEffect, ChangeEvent} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {
  clearMailboxData,
  loadMailBoxDropdownItems,
  setAllMail,
  updateMailBoxUserKey,
  updateMailboxAllRowsSelected,
  updateMailboxSkip,
  updateUserOrMail,
  updateViewName,
} from '../../store/actions/mailboxActions';
import {usePrevious} from '../../hooks/usePrevious';
import {getUserData} from '../../store/actions/userActions';
import {useSessionStorage} from '../../hooks/useSessionStorage';
import {RadioGroup, Select} from '../../ui/inputs';
import {constants} from '../../constants/common';
import {Box} from '../../ui/layouts';

interface IMailboxDropdownItem {
  key: string;
  label: string;
  name: string;
  value: string;
}

const RenderMailboxDropdown = ({isMailNotFiled}: {isMailNotFiled: boolean}) => {
  const dispatch = useDispatch<any>();
  const [selectedNodeKey, setSelectedNodeKey] = useState('');
  const {viewName, userKey, mailBoxDropdownItems} = useSelector(
    (store: any) => store.mailboxReducer,
  );
  const prevViewName = usePrevious(viewName);
  const prevSelectedNodeKey = usePrevious(selectedNodeKey);
  const unchangedGroupKey = getUserData()?.groupKey;
  const [{userKey: unChangedUserkey}] = useSessionStorage('profile');
  const defaultMailBoxDropdownValue = {
    label: '',
    name: '',
    value: '',
  };
  const [selectedMailBoxDropdownValue, setSelectedMailBoxDropdownValue] =
    useState(defaultMailBoxDropdownValue);
  const [mailboxRadioValue, setMailboxRadioValue] = useState('');

  const handleSetViewName = (newViewName: string) => {
    dispatch(updateViewName(newViewName));
  };
  const handleSetUserOrMail = (userOrMail: string) => {
    dispatch(updateUserOrMail(userOrMail));
  };
  const handleSetAllMail = (val: string) => {
    dispatch(setAllMail(val));
  };

  const setUserKey = (newUserKey: string) => {
    dispatch(updateMailBoxUserKey(newUserKey));
  };

  const updateMailBoxStateForUserOrMailZero = ({
    label,
    key,
    mailNotFiledUserKey,
  }: {
    label: string;
    key: string;
    mailNotFiledUserKey: string;
  }) => {
    handleSetUserOrMail('0');
    if (label?.toLocaleLowerCase() === 'All Mail'.toLocaleLowerCase()) {
      handleSetAllMail('00');
      setUserKey(unChangedUserkey);
      handleSetViewName('Mailbox_All');
    } else if (label === 'Inbox') {
      if (key.includes('_')) {
        handleSetAllMail('01');
        setUserKey(mailNotFiledUserKey.split('\\')[0]);
        handleSetViewName('Mailbox_Inbox');
      } else {
        handleSetAllMail('00');
        handleSetViewName('Mailbox_AllInbox');
        setUserKey(unChangedUserkey);
      }
    } else if (label === constants.SENT_ITEMS) {
      if (key.includes('_')) {
        setUserKey(mailNotFiledUserKey.split('\\')[0]);
        handleSetViewName('Mailbox_SentItems');
        handleSetAllMail('01');
      } else {
        handleSetViewName('Mailbox_AllSentItems');
        setUserKey(unChangedUserkey);
        handleSetAllMail('00');
      }
    } else if (label === 'Drafts') {
      if (key.includes('_')) {
        handleSetAllMail('01');
        setUserKey(mailNotFiledUserKey.split('\\')[0]);
        handleSetViewName('Mailbox_Drafts');
      } else {
        handleSetAllMail('00');
        handleSetViewName('Mailbox_AllDrafts');
        setUserKey(unChangedUserkey);
      }
    } else {
      handleSetAllMail('00');
      handleSetViewName('Mailbox_User');
      setUserKey(mailNotFiledUserKey);
    }
  };

  const updateMailBoxStateForUserOrMailOne = ({
    mailNotFiledUserKey,
    label,
  }: {
    mailNotFiledUserKey: string;
    label: string;
  }) => {
    handleSetUserOrMail('1');
    handleSetAllMail('01');
    const userKeyTemp = mailNotFiledUserKey.split('\\')[0];
    if (label === 'Inbox') {
      handleSetViewName('Mailbox_Inbox');
      setUserKey(userKeyTemp);
    } else if (label === 'Drafts') {
      handleSetViewName('Mailbox_Drafts');
      setUserKey(userKeyTemp);
    } else {
      handleSetViewName('Mailbox_User');
      setUserKey(userKeyTemp);
    }
  };

  const onSelect = (event: {
    node: {
      children: IMailboxDropdownItem[];
      value: string;
      label: string;
      key: string;
      name: string;
    };
  }) => {
    if (!event?.node && !event?.node?.value) return;
    const {label, key: childNodeKey} = event.node;
    const key = event.node.value?.split(':')[0] || childNodeKey;
    const mailNotFiledUserKey = key.includes('_') ? key.split('_')[1] : userKey;
    const userOrMailTemp = key.includes('_') ? key.split('_')[0] : '0';
    if (userOrMailTemp === '0') {
      updateMailBoxStateForUserOrMailZero({
        key,
        label,
        mailNotFiledUserKey,
      });
    } else if (userOrMailTemp === '1') {
      updateMailBoxStateForUserOrMailOne({
        label,
        mailNotFiledUserKey,
      });
    }
  };

  useEffect(() => {
    dispatch(
      loadMailBoxDropdownItems({
        userKey: unChangedUserkey,
        groupKey: unchangedGroupKey,
      }),
    );
  }, []);

  useEffect(() => {
    if (mailBoxDropdownItems.length) {
      const compareLabel = isMailNotFiled ? 'Not_Filed' : 'All Mail';
      const selectedNodeKeyTemp = mailBoxDropdownItems.length
        ? mailBoxDropdownItems.filter(
            (result: IMailboxDropdownItem) =>
              result?.name.toLocaleLowerCase() ===
              compareLabel.toLocaleLowerCase(),
          )
        : {};
      onSelect({node: selectedNodeKeyTemp[0]});
      setSelectedNodeKey(selectedNodeKeyTemp[0]?.key);
      setSelectedMailBoxDropdownValue({
        label: selectedNodeKeyTemp[0].label,
        name: selectedNodeKeyTemp[0].name,
        value: selectedNodeKeyTemp[0].value,
      });
    }
  }, [isMailNotFiled, mailBoxDropdownItems]);

  useEffect(() => {
    dispatch(updateMailboxSkip(0));
    dispatch(updateMailboxAllRowsSelected([]));
  }, [prevSelectedNodeKey, selectedNodeKey, viewName, prevViewName]);

  const onMailBoxDropdownChange = (e: any) => {
    setMailboxRadioValue('');
    const matchItem = mailBoxDropdownItems.find(
      (item: IMailboxDropdownItem) => item.value === e.target.value,
    );
    onSelect({node: matchItem});
    setSelectedMailBoxDropdownValue(matchItem);
  };

  const onMailboxRadioChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedDropdownKey = selectedMailBoxDropdownValue.value;
    const selectedRadioValue = e.target.value;
    const matchItem = mailBoxDropdownItems.find(
      (item: IMailboxDropdownItem) => item.value === selectedDropdownKey,
    );

    const selectedChild = matchItem.children.find(
      (child: IMailboxDropdownItem) => child.label === selectedRadioValue,
    );
    setMailboxRadioValue(selectedRadioValue);
    if (selectedChild) {
      onSelect({node: selectedChild});
    } else {
      dispatch(clearMailboxData());
    }
  };
  return (
    <Box display="flex" marginBottom="12px">
      <Select
        className="me-3"
        items={mailBoxDropdownItems}
        label="MailBox"
        onChange={onMailBoxDropdownChange}
        value={selectedMailBoxDropdownValue?.value}
        autoWidth
        fullWidth={false}
        sx={{minWidth: '250px'}}
      />
      <RadioGroup
        name="mailboxRadioButtons"
        items={[
          {label: 'Inbox', value: 'Inbox', id: '1', checked: false},
          {
            label: constants.SENT_ITEMS,
            value: constants.SENT_ITEMS,
            id: '2',
            checked: false,
          },
        ]}
        value={mailboxRadioValue}
        onChange={onMailboxRadioChange}
        row
      />
    </Box>
  );
};
export default RenderMailboxDropdown;
