import Select, {components} from 'react-select';

const CustomMenuList = props => {
  const {Header = null, Footer = null} = props.selectProps.components;
  return (
    <>
      {props.children.length && Header}
      <components.MenuList {...props}>{props.children}</components.MenuList>
      {props.children.length && Footer}
    </>
  );
};

export const SelectWithHeader = ({
  options,
  components,
  Header,
  onChange,
  value,
  getOptionLabel,
  ...props
}) => {
  const styles = {
    control: css => ({
      ...css,
    }),
    menu: ({width, ...css}) => ({
      ...css,
      width: 'max-content',
      minWidth: '20%',
      zIndex: 9999,
    }),
    // Add padding to account for width of Indicators Container plus padding
    option: css => ({...css, width: 500}),
  };
  return (
    <Select
      styles={styles}
      options={options}
      onChange={onChange}
      components={{...components, Header, MenuList: CustomMenuList}}
      value={value}
      getOptionValue={option => option}
      getOptionLabel={getOptionLabel}
      {...props}
    />
  );
};
