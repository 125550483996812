import {actionTypes} from '../constants/actionTypes';

const initialState = {
  users: [],
  fetchingManagedUsers: false,
  fetchedManagedUsers: false,
  fetchingManagedUsersFailed: false,
  count: 0,
  nextLink: '',
  loading: false,
  listByGroupKey: [],
  userSettings: {
    user: {},
    options: {},
    features: [],
  },
  udfDetails: null,
  fetchingUdfDetails: false,
  fetchedUdfDetails: false,
  fetchingUdfDetailsFailed: false,
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_MANAGED_USERS:
      return {
        ...state,
        fetchingManagedUsers: true,
        fetchingManagedUsersFailed: false,
        loading: true,
      };
    case actionTypes.FETCHED_MANAGED_USERS:
      return {
        ...state,
        users: action.users,
        fetchingManagedUsers: false,
        fetchedManagedUsers: true,
        fetchingManagedUsersFailed: false,
        count: action.count,
        nextLink: action.nextLink,
        loading: false,
      };
    case actionTypes.FETCHING_MANAGED_USERS_FAILED:
      return {
        ...state,
        fetchingManagedUsers: false,
        fetchingManagedUsersFailed: true,
        loading: false,
      };
    case actionTypes.FETCHED_USER_LIST: {
      return {
        ...state,
        listByGroupKey: action.userListByGroup,
      };
    }
    case actionTypes.FETCHED_USER_SETTINGS: {
      return {
        ...state,
        userSettings: action.userSettings,
      };
    }
    case actionTypes.FETCHING_USER_SETTINGS_FAILED: {
      return {
        ...state,
        userSettings: initialState.userSettings,
      };
    }

    case actionTypes.FETCHING_UDF_DETAILS: {
      return {
        ...state,
        fetchingUdfDetails: true,
        fetchedUdfDetails: false,
        fetchingUdfDetailsFailed: false,
      };
    }

    case actionTypes.FETCHED_UDF_DETAILS: {
      return {
        ...state,
        fetchingUdfDetails: false,
        fetchedUdfDetails: true,
        fetchingUdfDetailsFailed: false,
        udfDetails: action.udfDetails,
      };
    }

    case actionTypes.FETCHING_UDF_DETAILS_FAILED: {
      return {
        ...state,
        fetchingUdfDetails: false,
        fetchedUdfDetails: false,
        fetchingUdfDetailsFailed: true,
      };
    }

    default:
      return state;
  }
};
export default usersReducer;
