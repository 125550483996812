import {useEffect} from 'react';
import jwt_decode from 'jwt-decode';
import {Navigate, useNavigate, useSearchParams} from 'react-router-dom';
import {setConfigData} from '../../../lib/utils';

export const SetAuth = () => {
  const [searchParams] = useSearchParams();
  const context = searchParams.get('context');
  const redirect = searchParams.get('redirect');
  const navigate = useNavigate();

  function processContextData(contextData: string | null) {
    if (!contextData) return null;

    const decodedContext = JSON.parse(decodeURIComponent(contextData));

    if (!decodedContext.idToken) {
      return null;
    }

    const dateNow = new Date();
    const jwt: any = jwt_decode(decodedContext.idToken);

    if (jwt.exp >= dateNow.getTime() / 1000) {
      return {
        idToken: decodedContext.idToken,
        profile: JSON.stringify({...decodedContext, idToken: undefined}),
      };
    }

    return null;
  }

  useEffect(() => {
    const processedData = processContextData(context);

    if (processedData) {
      window.sessionStorage.setItem('idToken', processedData.idToken);
      window.sessionStorage.setItem('profile', processedData.profile);
      navigate(redirect ? decodeURIComponent(redirect) : '/home');
      setConfigData();
    } else {
      navigate('/unauthorized-user');
      window.sessionStorage.clear();
    }
  }, [context, navigate, redirect]);

  if (!context) {
    return <Navigate to="/unauthorized-user" />;
  }
  return null;
};
