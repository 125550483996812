import {constants} from '../../constants/common';
import {isEmpty} from '../../lib/utils';
import {
  attachReportToCommunicateService,
  checkUnCheckFile,
  deleteContact,
  deleteFile,
  fetActivityInfo,
  fetchActivities,
  fetchActivitiesContacts,
  fetchActivityAttachments,
  fetchActivityReportInfo,
  fetchActivityTypeList,
  fetchAllActiveContacts,
  fetchAllContacts,
  fetchDisputeCode,
  fetchDropDownTemplate,
  fetchEmailSignature,
  fetchTaggedInvoices,
  saveActivitiesContact,
  saveActivitiesPersonalContact,
  updateAllTaggedInvoice,
  updatePersonalContact,
  uploadAttachments,
  uploadTaggedInvoice,
} from '../../services/activities';
import {actionTypes} from '../constants/actionTypes';

const {
  FETCHED_ACTIVITIES,
  FETCHING_ACTIVITIES_FAILED,
  FETCHING_ACTIVITIES,
  SET_ACTIVITIES_SKIP,
} = actionTypes;

export const actions = {
  FETCHED_ACTIVITIES,
  FETCHING_ACTIVITIES_FAILED,
  FETCHING_ACTIVITIES,
  SET_ACTIVITIES_SKIP,
};

export const getAllActivities = options => (dispatch, getState) => {
  const activitiesState = getState().activitiesReducer;
  const top = getState().generalReducer.pageRowCount;
  const {
    userKey,
    groupKey,
    label,
    filter,
    maxrecordcount,
    lastfetched,
    allAccountsSelected,
  } = options;

  // change API for activities
  let endpoint = `v4/api/communication/${groupKey}/${userKey}/activities?`;

  if (label) {
    endpoint += `&%24label=${label}`;
  }
  if (filter) {
    endpoint += `&%24filter=${filter}`;
  }
  if (!isEmpty(activitiesState.orderBy)) {
    endpoint += `&%24orderby=${activitiesState.orderBy}`;
  }
  if (!isEmpty(activitiesState.activitiesSkip)) {
    endpoint += `&%24skip=${activitiesState.activitiesSkip}`;
  }
  if (!isEmpty(top)) {
    endpoint += `&%24top=${top}`;
  }
  if (maxrecordcount) {
    endpoint += `&%24maxrecordcount=${maxrecordcount}`;
  }
  if (lastfetched) {
    endpoint = `${endpoint}&%24lastfetched=${lastfetched}`;
  }
  if (allAccountsSelected) {
    endpoint = `${endpoint}&%24allAccountsSelected=${allAccountsSelected}`;
  }

  dispatch({type: FETCHING_ACTIVITIES});

  fetchActivities(endpoint)
    .then(data => {
      dispatch({
        type: FETCHED_ACTIVITIES,
        activities: data.value,
        activitiesSkip: activitiesState.activitiesSkip,
        count: data.count,
        nextLink: data.nextLink,
        loading: false,
      });
      return data;
    })
    .catch(() => dispatch({type: FETCHING_ACTIVITIES_FAILED}));
};

export const setActivitiesSkip = skip => {
  return {
    type: SET_ACTIVITIES_SKIP,
    activitiesSkip: skip,
  };
};

export const getActivityInfo =
  (options, copyNotesEmailBtnClicked) => dispatch => {
    dispatch({type: actionTypes.FETCHING_ACTIVITY_INFO});
    fetActivityInfo(options, copyNotesEmailBtnClicked)
      .then(res => {
        dispatch({
          type: actionTypes.FETCHED_ACTIVITY_INFO,
          activityInfo: res.data,
        });
        return res.data;
      })
      .catch(() => {
        dispatch({type: actionTypes.FETCHING_ACTIVITY_INFO_FAILED});
      });
  };

export const getActivityReportInfo = options => dispatch => {
  dispatch({type: actionTypes.FETCHING_ACTIVITY_REPORT_INFO});
  fetchActivityReportInfo(options)
    .then(res => {
      dispatch({
        type: actionTypes.FETCHED_ACTIVITY_REPORT_INFO,
        activityReportInfo: res.data,
      });
      return res.data;
    })
    .catch(() => {
      dispatch({type: actionTypes.FETCHING_ACTIVITY_REPORT_INFO_FAILED});
    });
};
export const getActivityAttachments = options => dispatch => {
  dispatch({type: actionTypes.FETCHING_ACTIVITY_ATTACHMENTS});
  fetchActivityAttachments(options)
    .then(res => {
      dispatch({
        type: actionTypes.FETCHED_ACTIVITY_ATTACHMENTS,
        activityAttachments: res.data,
      });
      dispatch({type: actionTypes.ATTACHED_REPORT});
      return res.data;
    })
    .catch(() => {
      dispatch({type: actionTypes.FETCHING_ACTIVITY_ATTACHMENTS_FAILED});
    });
};

export const getActivityTypeList = options => dispatch => {
  dispatch({type: actionTypes.FETCHING_ACTIVITY_TYPE_LIST});
  fetchActivityTypeList(options)
    .then(res => {
      dispatch({
        type: actionTypes.FETCHED_ACTIVITY_TYPE_LIST,
        activityTypeList: res.data,
      });
      return res.data;
    })
    .catch(() => {
      dispatch({type: actionTypes.FETCHING_ACTIVITY_TYPE_LIST_FAILED});
    });
};

export const getActivityPayment = options => (dispatch, getState) => {
  const toast = getState().generalReducer?.toastRef;
  dispatch({type: actionTypes.FETCHING_ACTIVITY_TRANSACTIONS});
  const activitiesState = getState().activitiesReducer;
  fetchTaggedInvoices(options, activitiesState)
    .then(res => {
      const updatedDataValue = res.data.value.map((d, index) => {
        const updatedRec = {...d};
        updatedRec.rowIndex = index;
        return updatedRec;
      });
      dispatch({
        type: actionTypes.FETCHED_ACTIVITY_TRANSACTIONS,
        activityTransactions: updatedDataValue,
        transactionCount: res.data.count,
        loading: false,
      });
      return updatedDataValue;
    })
    .catch(() => {
      toast?.current?.show({
        severity: 'warn',
        summary: constants.SOMETHING_WENT_WRONG,
        detail: '',
      });
      dispatch({type: actionTypes.FETCHING_ACTIVITY_TRANSACTIONS_FAILED});
    });
};

export const updateCommunicateTransactionAllRowsSelected =
  selectedRows => dispatch => {
    dispatch({
      type: actionTypes.UPDATED_COMMUNICATION_TRANSACTION_SELECTED_ROWS,
      transactionSelectedRows: selectedRows,
    });
  };

export const getAllActivityContactsList = options => (dispatch, getState) => {
  const toast = getState().generalReducer?.toastRef;

  dispatch({type: actionTypes.FETCHING_ACTIVITIES_CONTACTS});
  return fetchActivitiesContacts(options)
    .then(res => {
      if (res.data.value && res.data.value.length) {
        res.data.value.forEach((d, index) => {
          // eslint-disable-next-line no-param-reassign
          d.selectedRecipient = false;
          // eslint-disable-next-line no-param-reassign
          d.rowIndex = index;
        });
      }
      const contactList = options.personal
        ? {personalContactList: res.data.value}
        : {contactsList: res.data.value};
      dispatch({type: actionTypes.FETCHED_ACTIVITIES_CONTACTS, ...contactList});
      return res.data.value;
    })
    .catch(() => {
      toast?.current?.show({
        severity: 'warn',
        summary: constants.SOMETHING_WENT_WRONG,
        detail: '',
      });
      dispatch({type: actionTypes.FETCHING_ACTIVITIES_CONTACTS_FAILED});
    });
};

export const addActivitiesContact = options => (dispatch, getState) => {
  const toast = getState().generalReducer?.toastRef;
  const addedContactTypeObj = isEmpty(options.contactType)
    ? {addedContact: true, addedPersonalContact: false}
    : options.contactType.toLocaleLowerCase() ===
        constants.PERSONAL.toLocaleLowerCase() && {
        addedPersonalContact: true,
        addedContact: false,
      };

  dispatch({type: actionTypes.ADDING_CONTACT});
  return saveActivitiesContact(options)
    .then(() => {
      dispatch({type: actionTypes.ADDED_CONTACT, ...addedContactTypeObj});
      toast?.current?.show({
        severity: 'success',
        summary: constants.CONTACT_ADDED_SUCCESSFULLY,
        detail: '',
      });
    })
    .catch(e => {
      dispatch({
        type: actionTypes.ADDING_CONTACT_FAILED,
        ...addedContactTypeObj,
      });
      toast?.current?.show({
        severity: 'warn',
        summary: e?.response?.data
          .toLocaleLowerCase()
          .includes(constants.VIOLATION_OF_UNIQUE_KEY.toLocaleLowerCase())
          ? constants.CONTACT_ALREADY_EXISTS
          : constants.SOMETHING_WENT_WRONG,
        detail: '',
      });
    });
};

export const addActivitiesPersonalContact = options => (dispatch, getState) => {
  const toast = getState().generalReducer?.toastRef;
  const addedContactTypeObj = isEmpty(options.contactType)
    ? {addedContact: true, addedPersonalContact: false}
    : options.contactType.toLocaleLowerCase() ===
        constants.PERSONAL.toLocaleLowerCase() && {
        addedPersonalContact: true,
        addedContact: false,
      };
  dispatch({type: actionTypes.ADDING_CONTACT});
  return saveActivitiesPersonalContact(options)
    .then(() => {
      dispatch({type: actionTypes.ADDED_CONTACT, ...addedContactTypeObj});
      toast?.current?.show({
        severity: 'success',
        summary: constants.CONTACT_ADDED_SUCCESSFULLY,
        detail: '',
      });
    })
    .catch(() => {
      dispatch({
        type: actionTypes.ADDING_CONTACT_FAILED,
        ...addedContactTypeObj,
      });
      toast?.current?.show({
        severity: 'warn',
        summary: constants.SOMETHING_WENT_WRONG,
        detail: '',
      });
    });
};

export const updateActivitiesPersonalContact =
  options => (dispatch, getState) => {
    const toast = getState().generalReducer?.toastRef;
    dispatch({type: actionTypes.UPDATING_CONTACT});
    return updatePersonalContact(options)
      .then(() => {
        dispatch({type: actionTypes.UPDATED_CONTACT});
        toast?.current?.show({
          severity: 'success',
          summary: constants.CONTACT_UPDATED_SUCCESSFULLY,
          detail: '',
        });
      })
      .catch(() => {
        dispatch({type: actionTypes.UPDATING_CONTACT_FAILED});
        toast?.current?.show({
          severity: 'warn',
          summary: constants.SOMETHING_WENT_WRONG,
          detail: '',
        });
      });
  };

export const deleteActivitiesContact = options => (dispatch, getState) => {
  const toast = getState().generalReducer?.toastRef;
  dispatch({type: actionTypes.DELETING_CONTACT});
  return deleteContact(options)
    .then(() => {
      dispatch({type: actionTypes.DELETED_CONTACT});
      toast?.current?.show({
        severity: 'info',
        summary: constants.CONTACT_DELETED,
        detail: '',
      });
    })
    .catch(() => {
      dispatch({type: actionTypes.DELETING_CONTACT_FAILED});
      toast?.current?.show({
        severity: 'warn',
        summary: constants.SOMETHING_WENT_WRONG,
        detail: '',
      });
    });
};

export const viewAllContacts = options => (dispatch, getState) => {
  const toast = getState().generalReducer?.toastRef;
  dispatch({type: actionTypes.FETCHING_CONTACTS});
  return fetchAllContacts(options)
    .then(res => {
      dispatch({
        type: actionTypes.FETCHED_CONTACTS,
        contacts: res.data.value,
        contactsCount: res.data.count,
      });
      return res.data;
    })
    .catch(() => {
      toast?.current?.show({
        severity: 'warn',
        summary: constants.SOMETHING_WENT_WRONG,
        detail: '',
      });
      dispatch({type: actionTypes.FETCHING_CONTACTS_FAILED});
    });
};

export const getTemplateDropDownByType = options => dispatch => {
  dispatch({type: actionTypes.FETCHING_TEMPLATE_DROPDOWN_BY_TYPE});
  fetchDropDownTemplate(options)
    .then(res => {
      dispatch({
        type: actionTypes.FETCHED_TEMPLATE_DROPDOWN_BY_TYPE,
        list: res.data,
        loading: false,
      });
      return res.data;
    })
    .catch(() =>
      dispatch({type: actionTypes.FETCHING_TEMPLATE_DROPDOWN_BY_TYPE_FAILED}),
    );
};

export const addContactForNote = contactsForNote => dispatch => {
  dispatch({type: actionTypes.ADDING_CONTACT_TO_NOTE});
  dispatch({type: actionTypes.ADDED_CONTACT_TO_NOTE, contactsForNote});
};

export const getEmailSignature = options => dispatch => {
  dispatch({type: actionTypes.FETCHING_EMAIL_SIGNATURE});
  fetchEmailSignature(options)
    .then(res => {
      dispatch({
        type: actionTypes.FETCHED_EMAIL_SIGNATURE,
        signature: res.data,
        loading: false,
      });
      return res.data;
    })
    .catch(() => dispatch({type: actionTypes.FETCHING_EMAIL_SIGNATURE_FAILED}));
};

export const uploadFileAttachments = options => (dispatch, getState) => {
  const toast = getState().generalReducer?.toastRef;
  dispatch({type: actionTypes.UPLOADING_FILES});
  uploadAttachments(options)
    .then(() => {
      dispatch({type: actionTypes.UPLOADED_FILES});
      dispatch(getActivityAttachments(options.workSessionId));
    })
    .catch(error => {
      let errorMessage = constants.SOMETHING_WENT_WRONG;
      if (error?.response?.data) {
        errorMessage = error.response.data;
      }
      toast?.current.show({
        severity: 'warn',
        summary: errorMessage,
        detail: '',
      });
      dispatch({type: actionTypes.UPLOADING_FILES_FAILED});
    });
};

export const deleteAttachment = options => (dispatch, getState) => {
  const toast = getState().generalReducer?.toastRef;
  dispatch({type: actionTypes.DELETING_FILES});
  deleteFile(options)
    .then(res => {
      dispatch({type: actionTypes.DELETED_FILES, data: res.data});
    })
    .catch(() => {
      toast?.current.show({
        severity: 'warn',
        summary: constants.SOMETHING_WENT_WRONG,
        detail: '',
      });
      dispatch({type: actionTypes.UPLOADING_FILES_FAILED});
    });
};

export const attachTaggedInvoice = options => (dispatch, getState) => {
  const toast = getState().generalReducer?.toastRef;
  dispatch({type: actionTypes.ATTACHING_TAGGED_INVOICE});
  uploadTaggedInvoice(options)
    .then(res => {
      dispatch({type: actionTypes.ATTACHED_TAGGED_INVOICE, data: res.data});
    })
    .catch(() => {
      toast?.current.show({
        severity: 'warn',
        summary: constants.SOMETHING_WENT_WRONG,
        detail: '',
      });
      dispatch({type: actionTypes.ATTACHING_TAGGED_INVOICE_FAILED});
    });
};

export const editAllTaggedInvoice = options => (dispatch, getState) => {
  const {editRequest, handleSetShowEditAllTaggedInvoicesModal, onRefresh} =
    options;
  const toast = getState().generalReducer?.toastRef;
  dispatch({type: actionTypes.UPDATING_ALL_EDITED_TAGS});
  updateAllTaggedInvoice(editRequest)
    .then(() => {
      dispatch({type: actionTypes.UPDATED_ALL_EDITED_TAGS});
      handleSetShowEditAllTaggedInvoicesModal(false);
      if (editRequest?.UpdateInvoiceOnly) {
        onRefresh(false);
      } else {
        onRefresh(true);
      }
    })
    .catch(() => {
      toast?.current?.show({
        severity: 'warn',
        summary: constants.SOMETHING_WENT_WRONG,
        detail: '',
      });
      dispatch({type: actionTypes.UPDATING_ALL_EDITED_TAGS_FAILED});
    });
};

export const getDisputeCodeList = options => dispatch => {
  dispatch({type: actionTypes.FETCHING_DISPUTE_CODES});
  fetchDisputeCode(options)
    .then(res => {
      dispatch({type: actionTypes.FETCHED_DISPUTE_CODES, data: res.data});
    })
    .catch(() => {
      dispatch({type: actionTypes.FETCHING_DISPUTE_CODES_FAILED});
    });
};

export const checkUnCheckEmail = (options, index) => (dispatch, getState) => {
  const toast = getState().generalReducer?.toastRef;
  dispatch({type: actionTypes.UPDATING_CHECK_UNCHECK_ATTACHMENT, data: index});
  checkUnCheckFile(options)
    .then(res => {
      dispatch({
        type: actionTypes.UPDATED_CHECK_UNCHECK_ATTACHMENT,
        data: res.data,
      });
    })
    .catch(() => {
      toast?.current.show({
        severity: 'warn',
        summary: constants.SOMETHING_WENT_WRONG,
        detail: '',
      });
      dispatch({
        type: actionTypes.UPDATING_CHECK_UNCHECK_ATTACHMENT_FAILED,
        data: index,
      });
    });
};

export const getAllActiveContacts = options => dispatch => {
  dispatch({type: actionTypes.FETCHING_ALL_ACTIVE_CONTACTS});
  fetchAllActiveContacts(options)
    .then(res => {
      dispatch({
        type: actionTypes.FETCHED_ALL_ACTIVE_CONTACTS,
        contacts: res.data,
      });
      return res.data;
    })
    .catch(() => {
      dispatch({type: actionTypes.FETCHING_CONTACTS_FAILED});
    });
};

export const updateShowNoDataForReport =
  showNotDataForReportDialog => dispatch => {
    dispatch({
      type: actionTypes.UPDATE_SHOW_NO_DATA_FOR_REPORT,
      showNotDataForReportDialog,
    });
  };

export const attachReport = options => dispatch => {
  dispatch({type: actionTypes.ATTACHING_REPORT});

  return attachReportToCommunicateService(options)
    .then(() => {
      dispatch({type: actionTypes.ATTACHED_REPORT});
      dispatch(getActivityAttachments(options.workSessionId));
    })
    .catch(e => {
      if (
        e?.response?.data
          ?.toLocaleLowerCase()
          ?.includes(constants.NO_DATA_TO_GENERATE_REPORT?.toLocaleLowerCase())
      ) {
        dispatch(updateShowNoDataForReport(true));
      }
      dispatch({type: actionTypes.ATTACHING_REPORT_FAILED});
    });
};
