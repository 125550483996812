import dayjs from 'dayjs';
import {filterOperators} from '../../../../../lib/commonTableHelpers';
import {CustomerMenu} from '../../../../common/CommonRowComponents';
import {constants} from '../../../../../constants/common';
import {Checkbox} from '../../../../../ui/inputs';

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

export const summaryColumns = [
  {
    field: 'statusDesc',
    headerName: 'Status',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'companyId',
    headerName: 'Company ID',
    sortable: true,
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'custId',
    headerName: 'Cust ID',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'custName',
    headerName: 'Cust Name',
    sortable: true,
    renderCell: cellValues => {
      return <CustomerMenu values={cellValues} />;
    },
    minWidth: 180,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'pendPmtId',
    headerName: 'OnlinePmtID',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'paymentAmt',
    headerName: 'Payment Amount',
    sortable: true,
    minWidth: 120,
    type: 'number',
    renderCell: ({row}) => (
      <span>
        {Number(row.paymentAmt || 0).toLocaleString('en-US', {
          maximumFractionDigits: 2,
        })}
      </span>
    ),
    filterOperators: filterOperators.number,
  },
  {
    field: 'currId',
    headerName: 'Curr ID',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'createDate',
    headerName: 'Create Date',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_TIME_FORMAT)
        : '',
    minWidth: 150,
    type: 'date',
    filterOperators: filterOperators.date,
  },

  {
    field: 'createDateDays',
    headerName: 'Create Date Days',
    sortable: true,
    minWidth: 120,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'reconcileDate',
    headerName: 'Reconcile Date',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 150,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'note',
    headerName: 'Notes',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'entryUserId',
    headerName: 'EntryUserID',
    sortable: true,
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'entryUserName',
    headerName: 'EntryUserName',
    sortable: true,
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'assignedUserId',
    headerName: 'AssignedUserID',
    sortable: true,
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'procRefNo',
    headerName: 'ProcRefNo',
    sortable: true,
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'procStatus',
    headerName: 'ProcStatus',
    sortable: true,
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'procStatusMsg',
    headerName: 'ProcStatusMsg',
    sortable: true,
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'procAcctName',
    headerName: 'ProcAcctName',
    sortable: true,
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'procAcctType',
    headerName: 'ProcAcctType',
    sortable: true,
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'parentId',
    headerName: 'ParentID',
    sortable: true,
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'procAcctId',
    headerName: 'ProcAcctID',
    sortable: true,
    minWidth: 150,
    type: 'string',
  },
  {
    field: 'webhookStatusDesc',
    headerName: 'WebhookStatus',
    sortable: true,
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'webhookMessage',
    headerName: 'WebhookMessage',
    sortable: true,
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'webhookSentDate',
    headerName: 'WebhookSentDate',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_TIME_FORMAT)
        : '',
    minWidth: 150,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'totalSurchargeAmount',
    headerName: 'Total Surcharge Amount',
    sortable: true,
    minWidth: 120,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'isSchpmt',
    headerName: 'Is Schedule Payment',
    sortable: true,
    renderCell: cellValues => {
      return <Checkbox disabled checked={cellValues.row.isSchpmt} />;
    },
    minWidth: 80,
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
];
