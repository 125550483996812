import {
  Button,
  Dialog as MUIDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  DialogProps,
  PaperProps,
  Paper,
  DialogContentText,
} from '@mui/material';
import {GridCloseIcon} from '@mui/x-data-grid';
import Draggable from 'react-draggable';
import {Resizable} from 'react-resizable';
import 'react-resizable/css/styles.css';
import {useState} from 'react';
import {constants} from '../../../constants/common';
import Typography from '../../displays/typography/Typography';

export interface IDialogProps extends DialogProps {
  title: any; // It can be string or JSX.Element
  open: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  cancelButtonText?: string;
  confirmButtonText?: string;
  showCancelButton?: boolean;
  showConfirmButton?: boolean;
  closable?: boolean;
  draggable?: boolean;
  resizable?: boolean;
  height?: number;
  width?: number;
  children: React.ReactNode;
}

function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const ResizableDialog = ({
  children,
  height,
  width,
  onResize,
}: {
  children: React.ReactNode;
  height: number;
  width: number;
  onResize: (x: number, y: number) => void;
}) => {
  return (
    <Resizable
      width={width}
      height={height}
      onResize={(event: React.SyntheticEvent) => {
        const syntheticEvent = event as React.MouseEvent<Element, MouseEvent>;
        onResize(syntheticEvent.movementX, syntheticEvent.movementY);
      }}
    >
      {children}
    </Resizable>
  );
};

const Dialog: React.FC<IDialogProps> = props => {
  const {
    title,
    open,
    onClose,
    onConfirm,
    children,
    height,
    width,
    cancelButtonText = constants.CANCEL,
    confirmButtonText = constants.OK,
    showCancelButton = false,
    showConfirmButton = false,
    closable = true,
    draggable = false,
    resizable = false,
    ...otherProps
  } = props;

  if (resizable && (height === undefined || width === undefined)) {
    throw new Error(
      'height and width props are required when resizable is true',
    );
  }
  const [dialogHeight, setDialogHeight] = useState(height || 0);
  const [dialogWidth, setDialogWidth] = useState(width || 0);

  const onResize = (x: number, y: number) => {
    setDialogHeight(dialogHeight + y);
    setDialogWidth(dialogWidth + x);
  };
  const dialog = (
    <MUIDialog
      open={open}
      onClose={onClose}
      PaperComponent={draggable ? PaperComponent : undefined}
      aria-labelledby="draggable-dialog-title"
      maxWidth={resizable ? false : otherProps.maxWidth || 'sm'}
      {...otherProps}
    >
      <>
        <DialogTitle
          className="p-0"
          id="draggable-dialog-title"
          style={{cursor: draggable ? 'move' : 'default'}}
        >
          <div className="dialog-title d-flex justify-content-between align-items-center flex-wrap p-3 pe-1">
            <div className="dialog-title__text m-2">
              <Typography variant="h6">{title}</Typography>
            </div>
            <div className="dialog-title__dialog-close-button m-1">
              {closable && (
                <IconButton
                  title={constants.CLOSE}
                  aria-label="close"
                  onClick={onClose}
                >
                  <GridCloseIcon fontSize="medium" />
                </IconButton>
              )}
            </div>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText
            style={
              resizable
                ? {height: `${dialogHeight}px`, width: `${dialogWidth}px`}
                : {}
            }
          >
            <div className="p-1">{children}</div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {showCancelButton && (
            <Button onClick={onClose}>{cancelButtonText}</Button>
          )}
          {showConfirmButton && (
            <Button onClick={onConfirm} variant="contained" autoFocus>
              {confirmButtonText}
            </Button>
          )}
        </DialogActions>
      </>
    </MUIDialog>
  );
  if (resizable) {
    return (
      <ResizableDialog
        height={dialogHeight}
        width={dialogWidth}
        onResize={onResize}
      >
        {dialog}
      </ResizableDialog>
    );
  }
  return dialog;
};

export default Dialog;
