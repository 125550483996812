import {
  FiHome,
  FiMessageSquare,
  FiList,
  FiDatabase,
  FiSettings,
  FiStar,
} from 'react-icons/fi';
import {RiDashboardLine, RiBuilding4Line} from 'react-icons/ri';

const iconMapToName: {[key: string]: JSX.Element} = {
  home: <FiHome className="nav-items-icon" />,
  grid: <RiDashboardLine className="nav-items-icon" />,
  star: <FiStar className="nav-items-icon" />,
  'message-square': <FiMessageSquare className="nav-items-icon" />,
  Tasks: <FiList className="nav-items-icon" />,
  Activities: <FiList className="nav-items-icon" />,
  Accounts: <RiBuilding4Line className="nav-items-icon" />,
  Inquiries: <FiDatabase className="nav-items-icon" />,
  Admin: <FiSettings className="nav-items-icon" />,
};

export default iconMapToName;
