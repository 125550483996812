import {constants, fileNames} from '../../constants/common';
import {fetchActivitiesForHighlightedRow} from '../../services/activities';
import {
  exportCommunications,
  fetchCommunicationsVTwo,
  updateBulkCommunications,
} from '../../services/communications';
import {downloadExcel} from '../../utils/fileUtils';
import {stripHTML} from '../../utils/stringUtils';
import {actionTypes} from '../constants/actionTypes';
import {hideDialog, setFilteredData} from './generalActions';

export const getAllCommunicationsAndSettings =
  options => (dispatch, getState) => {
    const toast = getState().generalReducer?.toastRef;
    const communicationsState = getState().communicationsReducer;
    const {
      userKey,
      assignedUserKey,
      isShowingArchivedActivities,
      groupKey,
      activityType,
      ruleKey,
      taskKey,
      isHighlighted,
      SettingKey,
      isRestricted,
      sortQuery,
      pageSize,
      skip,
      filterQuery,
      sqlQuery,
    } = options;

    dispatch({type: actionTypes.FETCHING_COMMUNICATIONS});
    const filterQueryRequest =
      SettingKey === communicationsState?.viewSettings?.settingsKey
        ? filterQuery
        : null;
    fetchCommunicationsVTwo(
      userKey,
      assignedUserKey,
      isShowingArchivedActivities,
      groupKey,
      activityType,
      ruleKey,
      taskKey,
      isHighlighted,
      SettingKey,
      isRestricted,
      sortQuery,
      filterQueryRequest,
      pageSize,
      skip,
      sqlQuery,
    )
      .then(res => {
        if (res.data.value && res.data.value.length) {
          res.data.value.forEach(x => {
            x.notesText = stripHTML(x.notes);
          });
        }

        dispatch({
          type: actionTypes.FETCHED_COMMUNICATIONS,
          communications: res.data.value,
          communicationsSkip: communicationsState.communicationsSkip,
          communicationsCount: res.data.count,
          communicationsNextLink: res.data.nextLink,
          communicationsSelectedRows:
            communicationsState.communicationsSelectedRows,
          viewSettings: res.data.viewSetting,
        });
        return res.data;
      })
      .catch(() => {
        toast?.current.show({
          severity: 'warn',
          summary: constants.SOMETHING_WENT_WRONG,
          detail: '',
        });
        dispatch({type: actionTypes.FETCHING_COMMUNICATIONS_FAILED});
      });
  };

export const updateIsInquiriesActivitiesPage = value => {
  return {
    type: actionTypes.SET_INQUIRIES_ACTIVITIES_PAGE,
    isInquiriesActivitiesPage: value,
  };
};

export const setShowArchivedActivities = value => {
  return {
    type: actionTypes.SET_SHOW_ARCHIVED_ACTIVITIES,
    isShowingArchivedActivities: value,
  };
};

export const getAllActivitiesForHighlightedRow = options => dispatch => {
  const {userKey, taskKey} = options;
  dispatch({type: actionTypes.FETCHING_COMMUNICATIONS});
  return fetchActivitiesForHighlightedRow(userKey, taskKey)
    .then(res => {
      dispatch(setFilteredData(res.data.value));
      dispatch({
        type: actionTypes.FETCHED_ACTIVITIES_FOR_HIGHLIGHTED_ROW,
        communications: res.data.value,
        communicationsCount: res.data.count,
        communicationsNextLink: res.data.nextLink,
      });
      return res.data;
    })
    .catch(() => {
      dispatch({
        type: actionTypes.FETCHING_ACTIVITIES_FOR_HIGHLIGHTED_ROW_FAILED,
      });
    });
};

export const updateCommunicationsAllRowsSelected = selectedRows => dispatch => {
  dispatch({
    type: actionTypes.UPDATED_COMMUNICATIONS_SELECTED_ROWS,
    communicationsSelectedRows: selectedRows,
  });
};

export const updateCommunications = options => (dispatch, getState) => {
  const toast = getState().generalReducer?.toastRef;

  dispatch({type: actionTypes.COMMUNICATIONS_BULK_UPDATE_STARTED});
  updateBulkCommunications(options)
    .then(() => {
      dispatch({type: actionTypes.COMMUNICATIONS_BULK_UPDATE_COMPLETED});
      toast?.current?.show({
        severity: 'success',
        summary: constants.UPDATED,
        detail: constants.COMMUNICATIONS_BULK_UPDATE_COMPLETED,
      });
      dispatch(updateCommunicationsAllRowsSelected([]));
      dispatch(hideDialog());
    })
    .catch(() => {
      dispatch({type: actionTypes.COMMUNICATIONS_BULK_UPDATE_FAILED});
      dispatch(hideDialog());
    });
};

export const communicationExportToExcel =
  ({
    userKey,
    groupKey,
    isShowingArchivedActivities,
    modeType,
    activityType,
    ruleKey,
    taskKey,
    isHighlighted,
    SettingKey,
    isRestricted,
    sortQuery,
    filterQuery: globalFilterQuery,
    pageSize,
    skip,
    sqlQuery,
    assignedUserKey,
  }) =>
  (dispatch, getState) => {
    const toast = getState().generalReducer?.toastRef;

    dispatch({type: actionTypes.EXPORTING_COMMUNICATIONS_TO_EXCEL});
    exportCommunications(
      userKey,
      groupKey,
      isShowingArchivedActivities,
      activityType,
      ruleKey,
      taskKey,
      isHighlighted,
      isRestricted,
      sortQuery,
      globalFilterQuery,
      pageSize,
      skip,
      SettingKey,
      sqlQuery,
      assignedUserKey,
    )
      .then(res => {
        downloadExcel(
          res.data,
          fileNames[modeType] ?? 'ExportFile',
          toast,
          constants.MAIL_NOT_READ_EXPORTED_TO_EXCEL,
        );
        dispatch({type: actionTypes.EXPORTED_COMMUNICATIONS_TO_EXCEL});
      })
      .catch(() => {
        dispatch({type: actionTypes.EXPORTING_COMMUNICATIONS_TO_EXCEL_FAILED});
      });
  };

export const updateIsMailNotRead = isMailNotRead => {
  return {
    type: actionTypes.SET_IS_MAIL_NOT_READ,
    isMailNotRead,
  };
};
export const updateIsShowMailArchived = isShowArchived => {
  return {
    type: actionTypes.SET_IS_SHOW_MAIL_ARCHIVED,
    isShowArchived,
  };
};
export const updateIsShowHighlightedRowActivities =
  isShowActivitiesForHighlightedRow => {
    return {
      type: actionTypes.SET_IS_SHOW_HIGHLIGHTED_ACTIVITIES,
      isShowActivitiesForHighlightedRow,
    };
  };

export const updateSettingKey = settingId => {
  return {
    type: actionTypes.SET_VIEW_SETTING_KEY,
    settingId,
  };
};

export const updateViewSettingObject = ({settingId, viewSettings}) => {
  return {
    type: actionTypes.SET_VIEW_SETTING_OBJECT,
    payload: {settingId, viewSettings},
  };
};
export const cleanUpCommunication = () => {
  return {
    type: actionTypes.CLEAN_UP_COMMUNICATION,
  };
};
export const updateAlertDescription = alertDescription => {
  return {
    type: actionTypes.SET_ALERT_DESCRIPTION,
    alertDescription,
  };
};
