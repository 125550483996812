import {useDispatch, useSelector} from 'react-redux';
import {ViewSettingsDropDown} from '../../../common/view-settings/view-settings-dropdown/ViewSettingsDropDown';
import {CheckboxLabel} from '../../../../ui/inputs';
import {constants, gridModeTypes} from '../../../../constants/common';
import {showDialog} from '../../../../store/actions/generalActions';
import {updateUserInvoicesViewSettingObject} from '../../../../store/actions/invoiceActions';
import {Box} from '../../../../ui/layouts';

export const ViewSettings = ({
  isShowInvoiceLinesChecked,
  showInvoiceLines,
  isInvoicesSelected,
}: {
  isShowInvoiceLinesChecked: boolean;
  showInvoiceLines: (val: boolean) => void;
  isInvoicesSelected: boolean;
}) => {
  const dispatch = useDispatch();

  const {userInvoicesSettingId, userInvoicesViewSettings} = useSelector(
    (store: any) => store.invoiceReducer.userInvoices,
  );

  const onShowInvoiceChanged = (value: boolean) => {
    if (isInvoicesSelected || isShowInvoiceLinesChecked) {
      showInvoiceLines(value);
    } else {
      dispatch(
        // @ts-ignore
        showDialog({
          width: '50vw',
          title: constants.SELECT_INVOICE,
          headerIcon: 'pi pi-info',
          dialogContent: (
            <span>
              {constants.PLEASE_SELECT_AN_INVOICE_TO_SHOW_THE_LINES_FOR}
            </span>
          ),
        }),
      );
    }
  };
  return (
    <Box display="flex" gap={2}>
      <Box width="250px">
        <ViewSettingsDropDown
          settingId={userInvoicesSettingId}
          viewName="UserInvoices"
          isQueryParamDefault
          onViewSettingSelected={(selectedSettingId, viewSettings) =>
            dispatch(
              updateUserInvoicesViewSettingObject({
                settingId: selectedSettingId,
                viewSettings,
              }),
            )
          }
          currentViewSettings={userInvoicesViewSettings}
          activityType={gridModeTypes.UserInvoices}
        />
      </Box>
      <CheckboxLabel
        checked={isShowInvoiceLinesChecked}
        label={constants.SHOW_INVOICE_LINES}
        onChange={e => onShowInvoiceChanged(e.target.checked)}
      />
    </Box>
  );
};
