import React from 'react';
import {
  Popover as MuiPopover,
  PopoverOrigin,
  PopoverProps,
} from '@mui/material';

interface CustomPopoverProps extends PopoverProps {
  children: React.ReactNode;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  open: boolean;
  anchorOrigin?: PopoverOrigin;
}

const Popover = ({
  children,
  anchorEl,
  onClose,
  open,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
  },
  ...otherProps
}: CustomPopoverProps) => {
  return (
    <MuiPopover
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      onClose={onClose}
      open={open}
      {...otherProps}
    >
      {children}
    </MuiPopover>
  );
};

export default Popover;
