export const stripHTML = html => {
  if (!html) {
    return '';
  }
  let text = html;

  let bEnd;
  let bStart;
  bStart = html.indexOf('<body');
  bEnd = html.indexOf('</body');
  if (bStart >= 0 && bEnd >= 0) {
    const body = html.slice(bStart, bEnd);
    text = body.substring(body.indexOf('>') + 1);
  }
  text = text.replace(/<style(.*?)<\/style>/gi, '');
  text = text.replace(/<script(.*?)<\/script>/gi, '');
  text = text.replace(/<(.|\n)*?>/g, '');

  return text;
};
