import {actionTypes} from '../constants/actionTypes';

const initialState = {
  // inquiries/by-user/payments
  userPayments: {
    userPaymentsData: [],
    userPaymentsDataTotal: [],
    userPaymentsCount: 0,
    userPaymentsLoading: false,
    fetchingUserPayments: false,
    fetchedUserPayments: false,
    fetchingUserPaymentsFailed: false,
    userPaymentsPageSize: 10,
    userPaymentsSkip: 0,
    userPaymentsExportingToExcel: false,
    userPaymentsExportedToExcel: false,
    userPaymentsExportingToExcelFailed: false,
    userPaymentsSortBy: '',
    userPaymentsSortDirection: null,
    userPaymentsSettingId: null,
    userPaymentsViewSettings: null,
  },
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const userPaymentsReducer = (state = initialState, action) => {
  switch (action.type) {
    // inquiries/by-user/user-payments
    case actionTypes.FETCHING_USER_PAYMENTS:
      return {
        ...state,
        userPayments: {
          ...state.userPayments,
          userPaymentsLoading: true,
          fetchingUserPayments: true,
          fetchedUserPayments: false,
          fetchingUserPaymentsFailed: false,
        },
      };

    case actionTypes.FETCHED_USER_PAYMENTS:
      return {
        ...state,
        userPayments: {
          ...state.userPayments,
          userPaymentsLoading: false,
          fetchingUserPayments: false,
          fetchedUserPayments: true,
          fetchingUserPaymentsFailed: false,
          userPaymentsCount: action.userPaymentsCount,
          userPaymentsData: action.userPaymentsData,
          userPaymentsDataTotal: action.userPaymentsDataTotal,
        },
      };

    case actionTypes.FETCHING_USER_PAYMENTS_FAILED:
      return {
        ...state,
        userPayments: {
          ...state.userPayments,
          userPaymentsLoading: false,
          fetchingUserPayments: false,
          fetchedUserPayments: false,
          fetchingUserPaymentsFailed: true,
          userPaymentsData: [],
          userPaymentsCount: 0,
        },
      };

    case actionTypes.UPDATE_USER_PAYMENTS_SKIP:
      return {
        ...state,
        userPayments: {
          ...state.userPayments,
          userPaymentsSkip: action.userPaymentsSkip,
        },
      };

    case actionTypes.UPDATE_USER_PAYMENTS_PAGE_SIZE:
      return {
        ...state,
        userPayments: {
          ...state.userPayments,
          userPaymentsPageSize: action.userPaymentsPageSize,
          userPaymentsLoading: false,
          fetchingUserPayments: false,
        },
      };

    case actionTypes.EXPORTING_TO_EXCEL_USER_PAYMENTS:
      return {
        ...state,
        userPayments: {
          ...state.userPayments,
          userPaymentsExportingToExcel: true,
          userPaymentsExportedToExcel: false,
          userPaymentsExportingToExcelFailed: false,
        },
      };
    case actionTypes.EXPORTED_TO_EXCEL_USER_PAYMENTS:
      return {
        ...state,
        userPayments: {
          ...state.userPayments,
          userPaymentsExportingToExcel: false,
          userPaymentsExportedToExcel: true,
          userPaymentsExportingToExcelFailed: false,
        },
      };
    case actionTypes.EXPORTING_TO_EXCEL_USER_PAYMENTS_FAILED:
      return {
        ...state,
        userPayments: {
          ...state.userPayments,
          userPaymentsExportingToExcel: false,
          userPaymentsExportedToExcel: false,
          userPaymentsExportingToExcelFailed: true,
        },
      };

    case actionTypes.SET_USER_PAYMENTS_VIEW_SETTING_OBJECT:
      return {
        ...state,
        userPayments: {
          ...state.userPayments,
          userPaymentsViewSettings: {
            ...action.payload.viewSettings,
            settingsKey: action.payload.settingId,
            sortSetting: null,
          },
          userPaymentsSettingId: action.payload.settingId,
        },
      };
    case actionTypes.CLEAN_UP_USER_PAYMENTS:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
export default userPaymentsReducer;
