import {Button, Checkbox} from 'primereact';
import {constants} from '../../../../constants/common';

const overFlowEllipse = 'px-2 overflow-ellipse';
export const paymentInfoColumns = ({
  handleDeletePaymentMethod = () => {},
  onEditClick = () => {},
  deletingPaymentMethod,
  onPaymentInfoClick = () => {},
}) => {
  const editBodyTemplate = rowData => {
    return (
      <Button
        className="btn text-white bg-purple"
        onClick={() => onEditClick(rowData)}
      >
        {constants.EDIT}
      </Button>
    );
  };

  const paymentInfoBodyTemplate = rowData => {
    return (
      <Button
        className="btn text-white bg-purple"
        onClick={() => onPaymentInfoClick(rowData.pmtMethodKey)}
      >
        {constants.PAYMENT_INFO}
      </Button>
    );
  };

  const deleteBodyTemplate = rowData => {
    return (
      <Button
        disabled={deletingPaymentMethod}
        className="btn text-white bg-purple"
        onClick={() => handleDeletePaymentMethod(rowData?.pmtMethodKey)}
      >
        {constants.DELETE}
      </Button>
    );
  };

  const paymentMethodBodyTemplate = rowData => {
    return <span title={rowData.pmtMethodID}>{rowData.pmtMethodID}</span>;
  };

  const paymentMethodEmailTemplate = rowData => {
    return <span title={rowData.emailAddr}>{rowData.emailAddr}</span>;
  };

  const firstNameBodyTemplate = rowData => {
    return <span title={rowData.firstName}>{rowData.firstName}</span>;
  };

  const lastNameBodyTemplate = rowData => {
    return <span title={rowData.lastName}>{rowData.lastName}</span>;
  };
  const addrLineOneBodyTemplate = rowData => {
    return <span title={rowData.addrLine1}>{rowData.addrLine1}</span>;
  };
  const addrLineTwoBodyTemplate = rowData => {
    return <span title={rowData.addrLine2}>{rowData.addrLine2}</span>;
  };

  const defaultMethodBodyTemplate = rowData => {
    return <Checkbox checked={rowData.defaultMethod === 1} disabled />;
  };

  const cardTypeBodyTemplate = rowData => {
    const cType =
      rowData?.cardType?.toLowerCase() === constants.ACH.toLowerCase()
        ? constants.EFT
        : rowData.cardType;
    return <span title={cType}>{cType}</span>;
  };

  return [
    {
      field: 'editAction',
      align: 'center',
      header: 'edit',
      body: editBodyTemplate,
    },
    {
      field: 'paymentInfoAction',
      header: 'payment Info',
      align: 'center',
      body: paymentInfoBodyTemplate,
    },
    {
      field: 'pmtMethodID',
      header: 'Payment Method',
      body: paymentMethodBodyTemplate,
      style: {maxWidth: '200px'},
      className: overFlowEllipse,
    },
    {
      field: 'defaultMethod',
      header: 'Default Method',
      body: defaultMethodBodyTemplate,
      align: 'center',
    },
    {
      field: 'cardType',
      header: 'Card Type',
      body: cardTypeBodyTemplate,
    },
    {
      field: 'lastCCDigits',
      header: 'Last 4 Digits',
    },
    {
      field: 'expirationDate',
      header: 'Expiration Date',
    },
    {
      field: 'firstName',
      header: 'First Name',
      body: firstNameBodyTemplate,
      style: {maxWidth: '200px'},
      className: overFlowEllipse,
    },
    {
      field: 'lastName',
      header: 'Last Name',
      body: lastNameBodyTemplate,
      style: {maxWidth: '200px'},
      className: overFlowEllipse,
    },
    {
      field: 'emailAddr',
      header: 'Email Addr',
      body: paymentMethodEmailTemplate,
      style: {maxWidth: '300px'},
      className: overFlowEllipse,
    },
    {
      field: 'addrLine1',
      header: 'Addr Line 1',
      body: addrLineOneBodyTemplate,
      style: {maxWidth: '200px'},
      className: overFlowEllipse,
    },
    {
      field: 'addrLine2',
      header: 'Addr Line 2',
      body: addrLineTwoBodyTemplate,
      style: {maxWidth: '200px'},
      className: overFlowEllipse,
    },
    {
      field: 'city',
      header: 'City',
    },
    {
      field: 'state',
      header: 'State',
    },
    {
      field: 'country',
      header: 'Country',
    },
    {
      field: 'postalCode',
      header: 'Postal Code',
    },
    {
      field: 'actionDelete',
      header: 'Delete',
      align: 'center',
      body: deleteBodyTemplate,
    },
  ];
};
