import React from 'react';
import {ProgressSpinner} from 'primereact';

function Backdrop() {
  return (
    <div className="backdrop">
      <ProgressSpinner />
    </div>
  );
}
export default Backdrop;
