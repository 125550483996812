import {Button} from 'primereact';
import React from 'react';
import {constants} from '../../constants/common';

function MarkedIssueAsCompleteTemplate({onHide = () => {}}) {
  return (
    <div className="d-flex">
      <div className="d-flex align-items-center jusify-content-center me-3">
        <i className="pi pi-warning" />
      </div>
      <div className="d-flex flex-column justify-content-center">
        <span>
          {`${constants.NOTICE}: `} {`${constants.FOLLOW_UP_MARK_AS_COMPLETE}!`}
        </span>
        <div className="d-flex my-2">
          <Button className="bg-purple me-3" onClick={onHide}>
            {constants.OK}
          </Button>
        </div>
      </div>
    </div>
  );
}
export default MarkedIssueAsCompleteTemplate;
