import {actionTypes} from '../constants/actionTypes';

const initialState = {
  bestActivityData: [],
  bestActivityDataCount: 0,
  isLoading: false,
  fetchingBestActivity: false,
  fetchingBestActivityFailed: false,
  fetchedBestActivity: false,
  skip: 0,
  pageSize: 10,
  homeDashboardStats: {},
  isDashboardStatsLoading: false,
  fetchingDashboardStatFailed: false,
  fetchedDashboardStatFailed: false,
  userAlertSummary: [],
  isUserAlertSummaryLoading: false,
  lastSyncData: {},
  isLastSyncDataLoading: false,
};

const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_BEST_ACTIVITY:
      return {
        ...state,
        fetchingBestActivity: true,
        fetchingBestActivityFailed: false,
        fetchedBestActivity: false,
        bestActivityDataCount: 0,
        isLoading: true,
      };
    case actionTypes.FETCHED_BEST_ACTIVITY:
      return {
        ...state,
        fetchingBestActivity: false,
        fetchingBestActivityFailed: false,
        fetchedBestActivity: true,
        bestActivityData: action.bestActivityData,
        bestActivityDataCount: action.bestActivityDataCount,
        isLoading: false,
      };
    case actionTypes.FETCHING_BEST_ACTIVITY_FAILED:
      return {
        ...state,
        fetchingBestActivity: false,
        fetchingBestActivityFailed: true,
        fetchedBestActivity: true,
        isLoading: false,
        bestActivityData: [],
        bestActivityDataCount: 0,
      };

    case actionTypes.FETCHING_HOME_DASHBOARD_STATS:
      return {
        ...state,
        isDashboardStatsLoading: true,
        fetchingDashboardStatFailed: false,
        fetchedDashboardStatFailed: false,
        homeDashboardStats: {},
      };
    case actionTypes.FETCHED_HOME_DASHBOARD_STATS:
      return {
        ...state,
        isDashboardStatsLoading: false,
        fetchingDashboardStatFailed: false,
        fetchedDashboardStatFailed: true,
        homeDashboardStats: action.homeDashboardStats,
      };
    case actionTypes.FETCHING_HOME_DASHBOARD_STATS_FAILED:
      return {
        ...state,
        isDashboardStatsLoading: false,
        fetchingDashboardStatFailed: true,
        fetchedDashboardStatFailed: false,
        homeDashboardStats: {},
      };
    case actionTypes.FETCHING_USER_ALERT_SUMMARY:
      return {
        ...state,
        isUserAlertSummaryLoading: true,
      };
    case actionTypes.FETCHED_USER_ALERT_SUMMARY:
      return {
        ...state,
        isUserAlertSummaryLoading: false,
        userAlertSummary: action.userAlertSummary,
      };
    case actionTypes.FETCHING_USER_ALERT_SUMMARY_FAILED:
      return {
        ...state,
        isUserAlertSummaryLoading: false,
      };
    case actionTypes.FETCHING_LAST_SYNC_DATA:
      return {
        ...state,
        isLastSyncDataLoading: true,
      };
    case actionTypes.FETCHED_LAST_SYNC_DATA:
      return {
        ...state,
        isLastSyncDataLoading: false,
        lastSyncData: action.lastSyncData,
      };
    case actionTypes.FETCHING_LAST_SYNC_DATA_FAILED:
      return {
        ...state,
        isLastSyncDataLoading: false,
      };

    default:
      return state;
  }
};

export default homeReducer;
