import {GridPaginationModel, GridColDef} from '@mui/x-data-grid-pro';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {
  exportHeaderViewMessages,
  fetchHeaderViewMessages,
} from '../../../store/actions/generalActions';
import {getUserData} from '../../../store/actions/userActions';
import {DataGrid} from '../../../ui/data';
import {PAGE_SIZE, defaultViewMessageMode} from '../../../utils/constants';
import {calculateColumnWidth, onMessageReadUnread} from '../helper';
import {viewMessagesColumns} from './columns';
import ViewMessagesToolbar from './ViewMessagesToolbar';
import {useUserSettings} from '../../../hooks/useUserSettings';
import {useQueryKeys} from '../../../hooks/useQueryKeys';
import {useSessionStorage} from '../../../hooks/useSessionStorage';

const ViewMessages = () => {
  const toastRef = useSelector((store: any) => store.generalReducer?.toastRef);
  const {messages, count, fetchingMessages} = useSelector(
    (store: any) => store.generalReducer.viewMessages,
  );
  const dispatch = useDispatch<any>();
  const [showAllMessages, setShowAllMessages] = useState(false);
  const mode = defaultViewMessageMode;
  const {groupKey} = getUserData();
  const [{userId}] = useSessionStorage('profile');
  const {userKey} = useQueryKeys();
  const [skip, setSkip] = useState(0);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: PAGE_SIZE,
  });

  const {
    userSettings: {timezoneOffset},
  } = useUserSettings();

  const [bestFitColumns, setBestFitColumns] = useState<GridColDef[]>([]);

  const [bestFit, setBestFit] = useState<boolean>(false);

  const getMessages = ({
    showAllMessagesParam = false,
    pageSizeParam = PAGE_SIZE,
    skipParam = 0,
  }: {
    showAllMessagesParam: boolean;
    pageSizeParam: number;
    skipParam: number;
  }) => {
    dispatch(
      // @ts-ignore
      fetchHeaderViewMessages({
        groupKey,
        userKey,
        mode,
        userId,
        isShowAllMessages: showAllMessagesParam,
        skip: skipParam,
        pageSize: pageSizeParam,
      }),
    );
    setBestFit(false);
  };

  // reset pagination
  const resetPagination = () => {
    setPaginationModel({
      pageSize: paginationModel.pageSize,
      page: 0,
    });
  };
  // Refresh the grid
  const onRefresh = () => {
    getMessages({
      showAllMessagesParam: showAllMessages,
      pageSizeParam: paginationModel.pageSize,
      skipParam: skip,
    });
  };
  const columns: GridColDef[] = viewMessagesColumns(timezoneOffset, onRefresh);

  useEffect(() => {
    getMessages({
      showAllMessagesParam: showAllMessages,
      pageSizeParam: paginationModel.pageSize,
      skipParam: skip,
    });
  }, []);
  const exportToExcel = () => {
    // @ts-ignore
    dispatch(
      exportHeaderViewMessages({
        groupKey,
        userKey,
        mode,
        userId,
        isShowAllMessages: showAllMessages,
        skip,
        pageSize: paginationModel.pageSize,
      }),
    );
  };

  /**
   * onPageChange is prop set for view-messages grid to handle pagination
   * @param args GridPaginationModel object which has page which starts from 0 and skip properties
   */
  const onPageChange = (args: GridPaginationModel) => {
    const pageSkip =
      args.pageSize !== paginationModel.pageSize
        ? 0
        : (args.page + 1) * args.pageSize - args.pageSize;
    setPaginationModel({
      pageSize: args.pageSize,
      page: args.pageSize !== paginationModel.pageSize ? 0 : args.page,
    });
    setSkip(pageSkip);
    getMessages({
      showAllMessagesParam: showAllMessages,
      pageSizeParam: args.pageSize,
      skipParam: pageSkip,
    });
  };

  const markAllRead = () => {
    onMessageReadUnread('', true, onRefresh, toastRef);
  };

  const applyBestFit = () => {
    const bestFitColumnsTemp = columns.map(column => {
      const minWidth = calculateColumnWidth(column.field);
      return {
        ...column,
        flex: 1,
        minWidth,
      };
    });
    setBestFitColumns(bestFitColumnsTemp);
    setBestFit(!bestFit);
  };

  const handleShowAllMessages = () => {
    setShowAllMessages(prevState => !prevState);
    getMessages({
      showAllMessagesParam: !showAllMessages,
      pageSizeParam: paginationModel.pageSize,
      skipParam: 0,
    });
    resetPagination();
  };

  return (
    <div>
      <DataGrid
        height={600}
        disableVirtualization
        rows={messages}
        columns={bestFit ? bestFitColumns : columns}
        loading={fetchingMessages}
        rowCount={count}
        checkboxSelection={false}
        onPageChange={onPageChange}
        CustomToolbar={ViewMessagesToolbar}
        customToolbarMethods={{
          exportToExcel,
          onRefresh,
          markAllRead,
          handleShowAllMessages,
          showAllMessages,
          applyBestFit,
        }}
        getRowId={row => row.eventKey}
        paginationModel={paginationModel}
      />
    </div>
  );
};

export default ViewMessages;
