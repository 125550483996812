import {constants} from '../../constants/common';
import {isEmpty} from '../../lib/utils';
import {Button} from '../../ui/inputs';

function MakeAPaymentInfoDialog({
  makeAPayment = () => {},
  setShowMakePaymentInfoDialog = () => {},
  paymentAmount,
  handleUpdateErrorMessage = () => {},
  selectedCurrency,
}) {
  const handleMakePayment = () => {
    if (isEmpty(paymentAmount)) {
      setShowMakePaymentInfoDialog(false);
      handleUpdateErrorMessage(constants.PAYMENT_AMOUNT_CAN_NOT_BE_ZERO);
    } else if (isEmpty(selectedCurrency)) {
      handleUpdateErrorMessage(constants.NO_CURRENCY_HAS_BEEN_SELECTED);
      setShowMakePaymentInfoDialog(false);
    } else {
      makeAPayment();
    }
  };

  return (
    <div className="d-flex flex-column">
      <p className="py-2">{`${constants.MAKE_PAYMENT_INFO}?`}</p>
      <div className="d-flex">
        <Button
          variant="contained"
          className="my-2"
          size="medium"
          onClick={handleMakePayment}
        >
          {constants.OK}
        </Button>
        <Button
          variant="contained"
          size="medium"
          className="my-2 ms-3"
          onClick={() => setShowMakePaymentInfoDialog(false)}
        >
          {constants.CANCEL}
        </Button>
      </div>
    </div>
  );
}

export default MakeAPaymentInfoDialog;
