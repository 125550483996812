import {Checkbox} from '../../../ui/inputs';

export const VoiceTextColumns = onChkChanged => {
  return [
    {
      field: 'selectedRecipient',
      headerName: 'Selected Recipient',
      renderCell: ({row}) => (
        <Checkbox
          checked={row.selectedRecipient}
          onChange={e => onChkChanged(e, 'selectedRecipient', row.id)}
        />
      ),
      type: 'boolean',
      width: 150,
      sortable: false,
    },
    {
      field: 'contactName',
      headerName: 'Contact Name',
      sortable: false,
      width: 180,
      type: 'string',
    },
    {
      field: 'phone',
      headerName: 'Phone',
      sortable: false,
      width: 120,
      type: 'string',
    },
    {
      field: 'ext',
      headerName: 'Ext',
      sortable: false,
      width: 120,
      type: 'string',
    },
  ];
};
