import {gridModeTypes, constants} from '../constants/common';
import data from '../lib/data';
import {isEmpty} from '../lib/utils';
import {downloadBlobFilePostApi} from './download';

const createEndpointAndOptions = (
  userKey,
  assignedUserKey,
  isShowingArchivedActivities,
  groupKey,
  activityType,
  ruleKey,
  taskKey,
  isHighlighted,
  SettingKey,
  isRestricted,
  sortQuery,
  globalFilterQuery,
  pageSize,
  skip,
  url,
  sqlQuery,
) => {
  let endpoint = url;
  const sortJson =
    sortQuery?.length === 0
      ? null
      : {
          ViewSetting: {
            SortSetting: sortQuery.map((item, index) => {
              return {
                SortIndex: index + 1,
                SortCol: item.field,
                SortOrder: item.sort,
              };
            }),
          },
        };

  const options = {
    userKey,
    assignedUserKey,
    groupKey,
    countAll: true,
    sortJson: sortJson ? JSON.stringify(sortJson) : null,
    getCount: true,
    isRestricted,
    mode: activityType,
    isArchived: isShowingArchivedActivities ? '1' : '0',
    ruleKey,
    taskKey,
    isHighlighted,
    SettingKey,
    filterJson: globalFilterQuery ? JSON.stringify(globalFilterQuery) : null,
    filterSql: sqlQuery,
    source: 'react',
  };

  if (!isEmpty(skip)) {
    endpoint += `&%24skip=${skip}`;
  }

  if (!isEmpty(pageSize)) {
    endpoint = `${endpoint}&%24top=${pageSize}`;
  }

  return {endpoint, options};
};

export const fetchCommunicationsVTwo = (
  userKey,
  assignedUserKey,
  isShowingArchivedActivities,
  groupKey,
  activityType,
  ruleKey,
  taskKey,
  isHighlighted,
  SettingKey,
  isRestricted,
  sortQuery,
  globalFilterQuery,
  pageSize,
  skip,
  sqlQuery,
) => {
  const url = `v4/api/activity/activitiesbyuser/${groupKey}/${userKey}?`;

  const {endpoint, options} = createEndpointAndOptions(
    userKey,
    assignedUserKey,
    isShowingArchivedActivities,
    groupKey,
    activityType,
    ruleKey,
    taskKey,
    isHighlighted,
    SettingKey,
    isRestricted,
    sortQuery,
    globalFilterQuery,
    pageSize,
    skip,
    url,
    sqlQuery,
  );

  return data.post(endpoint, options);
};

export const updateBulkCommunications = options => {
  const endpoint = 'v4/api/activity';
  return data.put(endpoint, options);
};

export const fetchStatementsOfAccounts = ({
  custKey,
  groupKey,
  showAllAccounts,
  SettingKey,
  sortQuery,
  globalFilterQuery,
  pageSize,
  skip,
  sqlQuery,
}) => {
  const sortJson =
    sortQuery?.length === 0
      ? null
      : {
          ViewSetting: {
            SortSetting: sortQuery.map((item, index) => {
              return {
                SortIndex: index + 1,
                SortCol: item.field,
                SortOrder: item.sort,
              };
            }),
          },
        };

  let endpoint = `v4/api/accountoverview/for/${groupKey}/${custKey}/statementofaccount?`;

  const options = {
    Mode: gridModeTypes.StatementOfAccount,
    GroupKey: groupKey,
    CustKey: custKey,
    ShowAllAccounts: showAllAccounts,
    SettingKey,
    sortJson: sortJson ? JSON.stringify(sortJson) : null,
    filterJson: globalFilterQuery ? JSON.stringify(globalFilterQuery) : null,
    filterSql: sqlQuery,
    source: 'react',
  };

  if (!isEmpty(skip)) {
    endpoint = `${endpoint}&%24skip=${skip}`;
  }

  if (!isEmpty(pageSize)) {
    endpoint += `&%24top=${pageSize}`;
  }

  return data.post(endpoint, options);
};

export const fetchAccountOverviewActivities = option => {
  const {
    sortQuery,
    settingKey,
    custKey,
    userKey,
    isArchived,
    taskKey,
    groupKey,
    userId,
    globalFilterQuery,
    skip,
    pageSize,
    sqlQuery,
  } = option;

  const sortJson =
    isEmpty(sortQuery) || sortQuery?.length === 0
      ? null
      : {
          ViewSetting: {
            SortSetting: sortQuery.map((item, index) => {
              return {
                SortIndex: index + 1,
                SortCol: item.field,
                SortOrder: item.sort,
              };
            }),
          },
        };

  let endpoint = `v4/api/accountoverview/${custKey}/${userKey}/activities?`;

  const options = {
    mode: gridModeTypes.ActivitiesForCustomerAndUser,
    groupKey,
    custKey,
    settingKey,
    sortJson: sortJson ? JSON.stringify(sortJson) : null,
    userId,
    userKey,
    taskKey,
    isArchived,
    filterJson: globalFilterQuery ? JSON.stringify(globalFilterQuery) : null,
    filterSql: sqlQuery,
    source: 'react',
  };

  if (!isEmpty(skip)) {
    endpoint = `${endpoint}&%24skip=${skip}`;
  }

  if (!isEmpty(pageSize)) {
    endpoint += `&%24top=${pageSize}`;
  }

  return data.post(endpoint, options);
};

export const fetchInvoiceLines = ({
  invoiceKey,
  skip,
  pageSize,
  sortQuery,
  globalFilterQuery,
}) => {
  const {field: orderby, sort: sortorder} = sortQuery[0] || {};
  let endpoint = `v4/api/accountoverview/${invoiceKey}/invoicelines?`;

  if (!isEmpty(orderby) && !isEmpty(sortorder)) {
    endpoint = `${endpoint}&%24orderby=${orderby}&%24sortorder=${sortorder}`;
  }
  if (!isEmpty(skip)) {
    endpoint = `${endpoint}&%24skip=${skip}`;
  }

  if (!isEmpty(pageSize)) {
    endpoint = `${endpoint}&%24top=${pageSize}`;
  }
  if (!isEmpty(globalFilterQuery)) {
    endpoint += `&%24filter=${encodeURIComponent(globalFilterQuery)}`;
  }
  return data.get(endpoint);
};

export const fetchAccountNotes = ({
  custKey,
  userKey,
  skip,
  pageSize,
  sortBy,
  sortDirection,
}) => {
  const orderby = sortBy || '';
  const sortorder = sortDirection === 1 ? 'asc' : 'desc';

  let endpoint = `v4/api/accountoverview/${custKey}/${userKey}/accountnotes?`;
  if (!isEmpty(sortBy) && !isEmpty(sortDirection)) {
    endpoint = `${endpoint}&%24orderby=${orderby}&%24sortorder=${sortorder}`;
  }

  if (!isEmpty(skip)) {
    endpoint = `${endpoint}&%24skip=${skip}`;
  }

  if (!isEmpty(pageSize)) {
    endpoint += `&%24top=${pageSize}`;
  }
  return data.get(endpoint);
};

export const deleteAccountNotes = (taskKey, taskActKey) => {
  const endpoint = `v4/api/accountoverview/${taskKey}/${taskActKey}/accountNotes`;
  return data.delete(endpoint);
};

export const exportCommunications = (
  userKey,
  groupKey,
  isShowingArchivedActivities,
  activityType,
  ruleKey,
  taskKey,
  isHighlighted,
  isRestricted,
  sortQuery,
  globalFilterQuery,
  pageSize,
  skip,
  SettingKey,
  sqlQuery,
  assignedUserKey,
) => {
  const url = `v4/api/export/${
    activityType === '' ? gridModeTypes.None : activityType
  }?`;
  const {endpoint, options} = createEndpointAndOptions(
    userKey,
    assignedUserKey,
    isShowingArchivedActivities,
    groupKey,
    activityType,
    ruleKey,
    taskKey,
    isHighlighted,
    SettingKey,
    isRestricted,
    sortQuery,
    globalFilterQuery,
    pageSize,
    skip,
    url,
    sqlQuery,
  );

  return downloadBlobFilePostApi(endpoint, options);
};

export const fetchApplicationDetail = ({key, type, pageSize, skip}) => {
  let endpoint = `v4/api/accountoverview/applicationdetails/`;

  if (type === constants.INVOICE.toLocaleLowerCase()) {
    endpoint += `payment/${key}?`;
  } else {
    endpoint += `invoice/${key}?`;
  }
  if (!isEmpty(skip)) {
    endpoint = `${endpoint}&%24skip=${skip}`;
  }

  if (!isEmpty(pageSize)) {
    endpoint = `${endpoint}&%24top=${pageSize}`;
  }
  return data.get(endpoint);
};

export const fetchActivityDetail = ({docKey, userKey, pageSize, skip}) => {
  let endpoint = `v4/api/accountoverview/${docKey}/${userKey}/activitydetails?`;
  if (!isEmpty(skip)) {
    endpoint = `${endpoint}&%24skip=${skip}`;
  }

  if (!isEmpty(pageSize)) {
    endpoint = `${endpoint}&%24top=${pageSize}`;
  }
  return data.get(endpoint);
};

export const fetchInvoiceDetails = (invcKey, workSessionId = null) => {
  let endpoint = `v4/api/accountoverview/${invcKey}/invoiceinfo`;
  if (workSessionId != null) {
    endpoint += `?worksessionId=${workSessionId}`;
  }
  return data.get(endpoint).then(res => res.data);
};

export const fetchApplicationInvoiceByInvoiceKey = invoiceKey => {
  const endpoint = `v4/api/accountoverview/applicationdetails/payment/${invoiceKey}`;
  return data.get(endpoint);
};

export const fetchAccountOverviewSummary = (groupKey, custKey) => {
  const endpoint = `v4/api/accountoverview/accountSummary/for/${groupKey}/${custKey}`;
  return data.get(endpoint);
};

export const addToNote = request => {
  const endpoint = `v4/api/accountoverview/for/${request.groupKey}/${request.custKey}/${request.userKey}/accountnotes`;
  return data.post(endpoint, request.payload);
};

export const exportStatementsOverview = (
  statementOfAccountState,
  {groupKey: GroupKey, custKey: CustKey, showAllAccounts, SettingKey},
) => {
  let endpoint = `v4/api/export/StatementOfAccount?`;
  const payload = {
    GroupKey,
    CustKey,
    ShowAllAccounts: showAllAccounts ? '1' : '0',
    SettingKey,
  };

  const orderby = statementOfAccountState.sortBy;
  const sortorder =
    statementOfAccountState.sortDirection === 1 ? 'asc' : 'desc';

  if (
    !isEmpty(statementOfAccountState?.sortBy) &&
    !isEmpty(statementOfAccountState?.sortDirection)
  ) {
    endpoint = `${endpoint}&%24orderby=${orderby}&%24sortorder=${sortorder}`;
  }

  if (!isEmpty(statementOfAccountState.statementsOfAccountSkip)) {
    endpoint = `${endpoint}&%24skip=${statementOfAccountState.statementsOfAccountSkip}`;
  }

  if (!isEmpty(statementOfAccountState.statementsOfAccountPageSize)) {
    endpoint += `&%24top=${statementOfAccountState.statementsOfAccountPageSize}`;
  }

  return downloadBlobFilePostApi(endpoint, payload);
};

export const checkSiteOptionValueForShowAllAccount = ({groupKey, custKey}) => {
  const endpoint = `v4/api/accountoverview/for/${groupKey}/${custKey}/showAllAccounts`;
  return data.get(endpoint);
};

export const updateDisputeReasonInfo = options => {
  const endpoint = `v2/api/invoice/for/disputereasoninfo`;
  return data.put(endpoint, options);
};
