import data from '../lib/data';
import {isEmpty} from '../lib/utils';
import {downloadBlobFilePostApi} from './download';

export const addOrRemoveFromFavorites = ({userId, payload}) => {
  const endpoint = `v2/api/sidenav/addtofavorite/${userId}`;
  return data.post(endpoint, payload);
};

export const updateViewMessageMailStatusService = ({
  groupKey,
  userId,
  eventKey,
  isUpdateAll,
}) => {
  const endpoint = `v2/api/sidenav/for/${groupKey}/${userId}/messages?${
    !isEmpty(eventKey) ? `eventKey=${eventKey}&` : ''
  }isUpdateAll=${isUpdateAll}`;
  return data.put(endpoint);
};

export const fetchMessagesService = payload => {
  let endpoint = 'v2/api/sidenav/for/messages?';
  const {pageSize, skip, ...rest} = payload;

  if (!isEmpty(skip)) {
    endpoint += `&%24skip=${skip}`;
  }

  if (!isEmpty(pageSize)) {
    endpoint = `${endpoint}&%24top=${pageSize}`;
  }

  return data.post(endpoint, rest);
};

export const exportMessagesService = payload => {
  let endpoint = 'v4/api/export/ViewMessages?';

  if (!isEmpty(payload.skip)) {
    endpoint += `&%24skip=${payload.skip}`;
  }

  if (!isEmpty(payload.pageSize)) {
    endpoint = `${endpoint}&%24top=${payload.pageSize}`;
  }

  const columnSettings = [
    {
      DisplayText: 'Create Date',
      IsDisplay: true,
      DataField: 'CreateDate',
      ColumnDataType: 'String',
      OrderIndex: 1,
    },
    {
      DisplayText: 'Source',
      IsDisplay: true,
      DataField: 'Source',
      ColumnDataType: 'String',
      OrderIndex: 2,
    },
    {
      DisplayText: 'Subject',
      IsDisplay: false,
      DataField: 'Subject',
      ColumnDataType: 'String',
      OrderIndex: 3,
    },
    {
      DisplayText: 'Message',
      IsDisplay: false,
      DataField: 'Message',
      ColumnDataType: 'String',
      OrderIndex: 4,
    },
  ];
  const {groupKey, userKey, mode, userId, isShowAllMessages} = payload;
  return downloadBlobFilePostApi(endpoint, {
    groupKey,
    userKey,
    mode,
    userId,
    isShowAllMessages,
    columSetting: columnSettings ? JSON.stringify(columnSettings) : null,
  });
};

export const fetchJWTToken = () => {
  return data.post(`v4/api/zendesk/sso/token`);
};
