import {Dropdown} from 'primereact/dropdown';
import {InputText} from 'primereact/inputtext';
import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {constants} from '../../constants/common';
import {isEmpty} from '../../lib/utils';
import {setPageInputTooltip} from '../../store/actions/generalActions';
import {onlyNumbers} from '../../constants/regex';

export const PaginatorTemplate = (
  totalRecords,
  onLoadMore,
  skip,
  loading,
  updatePageRowCount,
  pageRowCount,
  loadedPage,
  setCurrentPage,
  currentPage,
  setLoadedPage,
) => {
  const {pageInputTooltip} = useSelector(store => store.generalReducer);
  const dispatch = useDispatch();
  const numPages = Math.ceil(totalRecords / pageRowCount);
  let pages = [];

  const handleOnKeyDown = e => {
    const page = parseInt(currentPage);
    if (isEmpty(e.target.value) && e.key === constants.ENTER) {
      setLoadedPage(loadedPage);
      setCurrentPage(loadedPage);
    } else if (page < 1 || page > numPages) {
      dispatch(setPageInputTooltip(constants.valueMustbe(1, numPages)));
    } else if (e.key === constants.ENTER) {
      setLoadedPage(page);
      setCurrentPage(page);
      onLoadMore(page);
    }
  };

  const onPageInputChange = event => {
    if (onlyNumbers.test(event.target.value)) {
      setCurrentPage(event.target.value);
      dispatch(
        setPageInputTooltip(constants.PRESS_ENTER_KEY_TO_GO_TO_THIS_PAGE),
      );
    } else {
      dispatch(setPageInputTooltip(constants.ONLY_NUMBERS_ALLOWED));
    }
  };

  const handleonPageClick = page => {
    if (!isEmpty(onLoadMore)) {
      onLoadMore(page);
    }
    setLoadedPage(page);
  };

  const handleOnFirstPageButtonClicked = () => {
    onLoadMore(1);
    setLoadedPage(1);
  };

  const handleOnPrevPageButtonClicked = () => {
    if (!isEmpty(onLoadMore)) {
      onLoadMore(loadedPage - 1);
    }
    setLoadedPage(loadedPage - 1);
  };

  const handleOnPageRowCountChange = e => {
    setLoadedPage(1);
    setCurrentPage(1);
    onLoadMore(1);
    updatePageRowCount(e.value);
  };

  const handleOnNextPageButtonClicked = () => {
    if (!isEmpty(onLoadMore)) {
      onLoadMore(loadedPage + 1);
    }
    setLoadedPage(loadedPage + 1);
  };

  const handleOnLastPageButtonClicked = () => {
    if (!isEmpty(onLoadMore)) {
      onLoadMore(numPages);
    }
    setLoadedPage(numPages);
  };

  useEffect(() => {
    setCurrentPage(loadedPage);
  }, [loadedPage]);

  for (let i = 1; i <= numPages; i += 1) {
    switch (true) {
      case i === 1:
        pages = [...pages, i];
        break;
      case i === numPages:
        pages = [...pages, i];
        break;
      case i === loadedPage:
        pages = [...pages, i];
        break;
      case loadedPage <= 6 && i < 10:
        pages = [...pages, i];
        break;
      case loadedPage <= 6 && i === 10:
        pages = [...pages, constants.ELLIPSE];
        break;
      case i >= loadedPage - 3 && i <= loadedPage + 3:
        pages = [...pages, i];
        break;
      case loadedPage >= numPages - 5 && i > numPages - 9:
        pages = [...pages, i];
        break;
      case loadedPage >= numPages - 5 && i === numPages - 9:
        pages = [...pages, constants.ELLIPSE];
        break;
      case i === loadedPage - 4 || i === loadedPage + 4:
        pages = [...pages, constants.ELLIPSE];
        break;
      default:
        break;
    }
  }

  pages = pages.map((page, i) => {
    return (
      !loading && (
        <span
          aria-hidden="true"
          className={`${
            page === loadedPage
              ? 'active'
              : page === constants.ELLIPSE
              ? 'page-ellipse'
              : ''
          } page`}
          disabled={page === constants.ELLIPSE || loadedPage === page}
          onClick={() => handleonPageClick(page)}
          key={page + i}
        >
          {page}
        </span>
      )
    );
  });

  return {
    layout: 'PageLinks RowsPerPageDropdown CurrentPageReport',
    PageLinks: () => {
      return (
        !loading && (
          <>
            <span
              className="action-button pe-2"
              disabled={loadedPage === 1}
              onClick={() => handleOnFirstPageButtonClicked()}
            >
              <i
                className={`pi pi-step-backward font-22-px text-white ${
                  loadedPage === 1 && 'disabled'
                }`}
              />
            </span>
            <span
              className="action-button pe-2"
              onClick={() => handleOnPrevPageButtonClicked()}
              disabled={loadedPage === 1}
            >
              <i
                className={`pi pi-caret-left font-22-px text-white ${
                  loadedPage === 1 && 'disabled'
                }`}
              />
            </span>
            {pages}
            <span
              className="action-button pe-2"
              onClick={() => handleOnNextPageButtonClicked()}
              disabled={loadedPage === numPages}
            >
              <i
                className={`pi pi-caret-right font-22-px text-white ${
                  loadedPage === numPages && 'disabled'
                }`}
              />
            </span>
            <span
              className="action-button pe-2"
              disabled={loadedPage === numPages}
              onClick={() => handleOnLastPageButtonClicked()}
            >
              <i
                className={`pi pi-step-forward font-22-px text-white ${
                  loadedPage === numPages && 'disabled'
                }`}
              />
            </span>
          </>
        )
      );
    },
    RowsPerPageDropdown: () => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
      ];
      return (
        <>
          <span className="mx-2 text-white">{constants.PAGE_SIZE}:</span>
          <Dropdown
            className="noBorder dropdown dropdown-paging"
            value={pageRowCount}
            options={dropdownOptions}
            onChange={handleOnPageRowCountChange}
          />
        </>
      );
    },
    CurrentPageReport: ({first, last}) => {
      return (
        <div className="mx-3">
          <span className="px-1">{constants.GO_TO}</span>
          <InputText
            size="2"
            className="ml-1 input-text-custom"
            value={currentPage}
            tooltip={pageInputTooltip}
            onKeyDown={handleOnKeyDown}
            onChange={onPageInputChange}
          />
          <span className="ms-3 text-white text-align-center">
            {constants.ITEM} {first + skip} - {last + skip} {constants.OF}{' '}
            {totalRecords}
          </span>
        </div>
      );
    },
  };
};
