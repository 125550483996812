/* eslint-disable no-nested-ternary */
import dayjs from 'dayjs';
import {GrAttachment} from 'react-icons/gr';
import {MdOutlineKeyboardVoice} from 'react-icons/md';
import {FiMessageSquare} from 'react-icons/fi';
import {
  CheckboxEditable,
  IconLink,
  OverViewActivityMenu,
  SingleLink,
} from './RowComponents';
import {constants} from '../../../constants/common';
import {isDateLessThanToday} from '../../../utils/dataTimeUtils';
import {isEmpty} from '../../../lib/utils';
import {CALL_STATUS, MAIL_STATUS} from '../../../utils/constants';
import {Checkbox} from '../../../ui/inputs';
import {filterOperators} from '../../../lib/commonTableHelpers';
import {CheckUnCheckFilter} from '../../common/filter/FilterComponents';

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

export const accountOverviewActivitiesColumns = [
  {
    field: 'dateEntered',
    headerName: 'Activity Date',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_TIME_FORMAT)
        : '',
    minWidth: 150,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'activity',
    headerName: 'Activity',
    sortable: false,
    mandatory: true,
    renderCell: cellValues => {
      return <OverViewActivityMenu values={cellValues} />;
    },
    minWidth: 50,
    maxWidth: 100,
  },
  {
    field: 'description',
    headerName: 'Issue',
    sortable: true,
    renderCell: cellValues => {
      return <SingleLink values={cellValues} />;
    },
    minWidth: 180,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'notes',
    headerName: 'Notes',
    sortable: true,
    minWidth: 120,
    renderCell: cellValues => {
      const {row} = cellValues;
      return <span title={row.notesText}>{row.notesText}</span>;
    },
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'phoneCall',
    headerName: 'Phone Call',
    sortable: false,
    disableColumnMenu: true,
    maxWidth: 15,
    renderHeader: () => <i className="pi pi-phone" title="Phone Call" />,
    renderCell: cellValues => {
      const {row} = cellValues;
      if (row.phoneCall) {
        return (
          <IconLink values={cellValues}>
            {row.successful ? (
              <i
                className="pi pi-phone-successful"
                title="Successful Phone Call"
              />
            ) : (
              <i
                className="pi pi-phone-unsuccessful"
                title="Unsuccessful Phone Call"
              />
            )}
          </IconLink>
        );
      }
      return null;
    },
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
  {
    field: 'receivedMail',
    headerName: 'Received Mail',
    sortable: false,
    disableColumnMenu: true,
    maxWidth: 15,
    renderHeader: () => (
      <i className="pi pi-received-email" title="Received Mail" />
    ),
    renderCell: cellValues => {
      const {row} = cellValues;
      if (row.receivedMail) {
        return (
          <IconLink values={cellValues}>
            {row.mailStatus === MAIL_STATUS.unread ? (
              <i className="pi pi-received-email-unread" title="Unread Email" />
            ) : row.mailStatus === MAIL_STATUS.read ? (
              <i className="pi pi-received-email-read" title="Received Email" />
            ) : (
              ''
            )}
          </IconLink>
        );
      }
      return null;
    },
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
  {
    field: 'followUp',
    headerName: 'Follow up',
    sortable: false,
    disableColumnMenu: true,
    maxWidth: 15,
    renderHeader: () => <i className="pi pi-follow-up" title="Follow up" />,
    renderCell: cellValues => {
      const {row} = cellValues;
      if (row.followUp) {
        return (
          <IconLink values={cellValues}>
            {row.activityComplete ? (
              <i
                className="pi pi-followup-complete"
                title="Completed FollowUp"
              />
            ) : (row.followupDate && isDateLessThanToday(row.followupDate)) ||
              isEmpty(isDateLessThanToday(row.followupDate)) ? (
              <i className="pi pi-followup-overdue" />
            ) : (
              <i className="pi pi-follow-up" title="Open FollowUp" />
            )}
          </IconLink>
        );
      }
      return null;
    },
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
  {
    field: 'disputed',
    headerName: 'Disputed',
    sortable: false,
    disableColumnMenu: true,
    maxWidth: 15,
    renderHeader: () => <i className="pi pi-dispute" title="Disputed" />,
    renderCell: cellValues => {
      const {row} = cellValues;
      return (
        <IconLink values={cellValues} tabName={constants.TRANSACTIONS_TAB}>
          {row.disputed && (
            <i className="pi pi-dispute" title="Disputed Invoice" />
          )}
        </IconLink>
      );
    },
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
  {
    field: 'tagged',
    headerName: 'Tagged',
    sortable: false,
    disableColumnMenu: true,
    maxWidth: 15,
    renderHeader: () => (
      <i className="pi pi-tagged-invoice" title="Tagged Invoice" />
    ),
    renderCell: cellValues => {
      const {row} = cellValues;
      return (
        <IconLink values={cellValues} tabName={constants.TRANSACTIONS_TAB}>
          {row.tagged && (
            <i className="pi pi-tagged-invoice" title="Tagged Invoice" />
          )}
        </IconLink>
      );
    },
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
  {
    field: 'expectPayment',
    headerName: 'Expect Payment',
    sortable: false,
    disableColumnMenu: true,
    maxWidth: 15,
    renderHeader: () => <i className="pi pi-dollar" title="Expect Payment" />,
    renderCell: cellValues => {
      const {row} = cellValues;
      return (
        <IconLink values={cellValues} tabName={constants.PAYMENTS_TAB}>
          {row.expectPayment && <i className="pi pi-dollar" />}
        </IconLink>
      );
    },
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
  {
    field: 'sentMail',
    headerName: 'Sent Email',
    sortable: false,
    disableColumnMenu: true,
    maxWidth: 15,
    renderHeader: () => (
      <i className="pi pi-sent-email" title={constants.SENT_MAIL} />
    ),
    renderCell: cellValues => {
      const {row} = cellValues;
      return (
        <IconLink values={cellValues} tabName={constants.EMAIL_TAB}>
          {!isEmpty(row.mailKey) && (
            <>
              {row.sentMail && (
                <i className="pi pi-email-resend" title="Sent Email" />
              )}

              {row.mailStatus === -1 && (
                <i className="pi pi-email-error" title="Error Sending" />
              )}

              {row.mailStatus === -2 && (
                <i className="pi pi-email-queued" title="Email Queued" />
              )}
            </>
          )}
        </IconLink>
      );
    },
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
  {
    field: 'attachment',
    headerName: 'Attachment',
    sortable: false,
    disableColumnMenu: true,
    maxWidth: 15,
    renderHeader: () => (
      <GrAttachment size={20} title={constants.ATTACHMENTS} />
    ),
    renderCell: cellValues => {
      const {row} = cellValues;
      return (
        <IconLink values={cellValues} tabName={constants.ATTACHMENTS_TAB}>
          {row.attachment && <i className="pi pi-attachment" />}
        </IconLink>
      );
    },
    renderHeaderFilter: params => <CheckUnCheckFilter {...params} />,
    minWidth: 100,
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
  {
    field: 'voice',
    headerName: 'VoiceMessage',
    sortable: false,
    disableColumnMenu: true,
    maxWidth: 15,
    renderHeader: () => (
      <MdOutlineKeyboardVoice size={24} title={constants.VOICE_MESSAGE} />
    ),
    renderCell: cellValues => {
      const {row} = cellValues;
      if (!isEmpty(row?.voice)) {
        let iconClass = '';
        let iconTitle = '';

        if (row?.voice < 0) {
          iconClass = 'pi pi-voice-call-unsuccessful';
          iconTitle = constants.UNSUCCESSFUL_VOICE_CALL;
        } else if (row.voice === CALL_STATUS.SUCCESSFUL) {
          iconClass = 'pi pi-voice-call-successful';
          iconTitle = constants.SUCCESSFUL_PHONE_CALL;
        } else if (row.voice === CALL_STATUS.QUEUED) {
          iconClass = 'MdOutlineKeyboardVoice';
          iconTitle = constants.VOICE_CALL_QUEUED;
        } else {
          return null;
        }

        return (
          <IconLink values={cellValues} tabName={constants.VOICE_MESSAGE}>
            {iconClass === 'MdOutlineKeyboardVoice' ? (
              <MdOutlineKeyboardVoice size={23} title={iconTitle} />
            ) : (
              <i className={iconClass} title={iconTitle} />
            )}
          </IconLink>
        );
      }

      return null;
    },
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
  {
    field: 'sms',
    headerName: 'Text',
    sortable: false,
    disableColumnMenu: true,
    maxWidth: 15,
    renderHeader: () => <FiMessageSquare size={20} title={constants.TEXT} />,
    renderCell: cellValues => {
      const {row} = cellValues;
      if (!isEmpty(row?.voice)) {
        let iconClass = '';
        let iconTitle = '';

        if (row?.voice < 0) {
          iconClass = 'pi pi-voice-call-unsuccessful';
          iconTitle = constants.UNSUCCESSFUL_VOICE_CALL;
        } else if (row.voice === CALL_STATUS.SUCCESSFUL) {
          iconClass = 'pi pi-voice-call-successful';
          iconTitle = constants.SUCCESSFUL_PHONE_CALL;
        } else if (row.voice === CALL_STATUS.QUEUED) {
          iconClass = 'MdOutlineKeyboardVoice';
          iconTitle = constants.VOICE_CALL_QUEUED;
        } else {
          return null;
        }

        return (
          <IconLink values={cellValues} tabName={constants.TEXT_TAB}>
            {iconClass === 'MdOutlineKeyboardVoice' ? (
              <MdOutlineKeyboardVoice size={23} title={iconTitle} />
            ) : (
              <i className={iconClass} title={iconTitle} />
            )}
          </IconLink>
        );
      }

      return null;
    },
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
  {
    field: 'issueComplete',
    headerName: 'Issue Complete',
    sortable: false,
    disableColumnMenu: true,
    renderCell: cellValues => {
      return <CheckboxEditable values={cellValues} field="issueComplete" />;
    },
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
  {
    field: 'activityComplete',
    headerName: 'Activity Complete',
    sortable: false,
    disableColumnMenu: true,
    renderCell: cellValues => {
      return (
        <CheckboxEditable
          values={cellValues}
          field="activityComplete"
          isActivity
        />
      );
    },
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
  {
    field: 'activityAssignTo',
    headerName: 'ActivityAssignTo',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'priority',
    headerName: 'Priority',
    sortable: true,
    minWidth: 60,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'activityId',
    headerName: 'ActivityType',
    sortable: true,
    minWidth: 60,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'alertId',
    headerName: 'ActivityID',
    sortable: true,
    minWidth: 60,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'followupDate',
    headerName: 'Followup Date',
    sortable: true,
    minWidth: 80,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_TIME_FORMAT)
        : '',
    renderCell: cellValues => {
      const {row} = cellValues;
      const followUpDate =
        row?.followupDate &&
        dayjs(row?.followupDate).utc().format(constants.DATE_TIME_FORMAT);
      if (row.followUp && !row.activityComplete) {
        const isInvalidDate =
          isEmpty(row.followupDate) ||
          (row.followupDate && isDateLessThanToday(row.followupDate));

        return (
          <div
            className={`bg-${
              isInvalidDate ? 'red' : 'white'
            } p-3 w-100 h-100 d-flex`}
          >
            <span>{followUpDate}</span>
          </div>
        );
      }

      return <span>{followUpDate}</span>;
    },
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'followupDesc',
    headerName: 'Followup Desc',
    sortable: true,
    minWidth: 100,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'issueCompleteDate',
    headerName: 'Issue Complete Date',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_TIME_FORMAT)
        : '',
    minWidth: 120,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'activityCompleteDate',
    headerName: 'Activity Complete Date',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_TIME_FORMAT)
        : '',
    minWidth: 120,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'taggedAmtHc',
    headerName: 'TaggedAmtHC',
    sortable: true,
    minWidth: 60,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'successful',
    headerName: 'Successful',
    sortable: false,
    disableColumnMenu: true,
    minWidth: 60,
    renderCell: cellValues => {
      const {row} = cellValues;
      return <Checkbox checked={row.successful} disabled />;
    },
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
  {
    field: 'unreadMail',
    headerName: 'Unread Email',
    sortable: false,
    disableColumnMenu: true,
    minWidth: 60,
    renderCell: cellValues => {
      const {row} = cellValues;
      return <Checkbox checked={row.unreadMail} disabled />;
    },
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },

  {
    field: 'taskKey',
    headerName: 'IssueID',
    minWidth: 60,
    sortable: false,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'taskActKey',
    headerName: 'TaskActKey',
    minWidth: 60,
    sortable: true,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'closeStatus',
    headerName: 'CloseStatus',
    minWidth: 100,
    sortable: false,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'isArchived',
    headerName: 'IsArchived',
    sortable: false,
    disableColumnMenu: true,
    minWidth: 60,
    renderCell: cellValues => {
      const {row} = cellValues;
      return <Checkbox checked={row.isArchived} disabled />;
    },
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
  {
    field: 'timeZoneId',
    headerName: 'Timezone ID',
    minWidth: 120,
    sortable: false,
    type: 'string',
    filterOperators: filterOperators.string,
  },
];
