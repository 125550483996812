import {gridModeTypes} from '../constants/common';
import data from '../lib/data';
import {isEmpty} from '../lib/utils';
import {downloadBlobFilePostApi} from './download';

export const fetchFollowUpData = ({
  userKey,
  AssignedUserKey,
  groupKey,
  maxDate,
  minDate,
  skipRows,
  rowCount,
  sortQuery,
  globalFilterQuery,
  settingKey,
  excludeFromRule,
  sqlQuery,
}) => {
  let endpoint = `v2/api/taskactivity/followup/for/${groupKey}?`;

  if (!isEmpty(skipRows)) {
    endpoint += `&%24skip=${skipRows}`;
  }

  if (!isEmpty(rowCount)) {
    endpoint += `&%24top=${rowCount}`;
  }

  const sortJson =
    isEmpty(sortQuery) || sortQuery?.length === 0
      ? null
      : {
          ViewSetting: {
            SortSetting: sortQuery.map((item, index) => {
              return {
                SortIndex: index + 1,
                SortCol: item.field,
                SortOrder: item.sort,
              };
            }),
          },
        };

  const options = {
    UserKey: userKey,
    AssignedUserKey,
    maxDate,
    minDate,
    SkipRows: 0,
    RowCount: 10,
    groupKey,
    sortJson: sortJson ? JSON.stringify(sortJson) : null,
    mode: gridModeTypes.followupActivities,
    SettingKey: settingKey,
    ExcludeFromRule: excludeFromRule,
    filterJson: globalFilterQuery ? JSON.stringify(globalFilterQuery) : null,
    filterSql: sqlQuery,
    source: 'react',
  };
  return data.post(endpoint, options);
};

export const exportFollowUpData = ({
  userKey,
  AssignedUserKey,
  groupKey,
  maxDate,
  minDate,
  sortQuery,
  filterQuery,
  settingKey,
  modeType,
  excludeFromRule,
  sqlQuery,
}) => {
  const sortJson =
    isEmpty(sortQuery) || sortQuery?.length === 0
      ? null
      : {
          ViewSetting: {
            SortSetting: sortQuery.map((item, index) => {
              return {
                SortIndex: index + 1,
                SortCol: item.field,
                SortOrder: item.sort,
              };
            }),
          },
        };

  const options = {
    UserKey: userKey,
    AssignedUserKey,
    maxDate,
    minDate,
    groupKey,
    sortJson: sortJson ? JSON.stringify(sortJson) : null,
    mode: gridModeTypes.followupActivities,
    SettingKey: settingKey,
    ExcludeFromRule: excludeFromRule,
    filterJson: filterQuery ? JSON.stringify(filterQuery) : null,
    filterSql: sqlQuery,
    source: 'react',
    modeType,
  };

  const endpoint = `v4/api/export/followUpActivities?`;

  return downloadBlobFilePostApi(endpoint, options);
};
