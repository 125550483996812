import {useDispatch, useSelector} from 'react-redux';
import {ViewSettingsDropDown} from '../../../common/view-settings/view-settings-dropdown/ViewSettingsDropDown';
import {Box} from '../../../../ui/layouts';
import {gridModeTypes} from '../../../../constants/common';
import {updateUserPaymentsViewSettingObject} from '../../../../store/actions/userPaymentsActions';

export const ViewSettings = () => {
  const {userPaymentsSettingId, userPaymentsViewSettings} = useSelector(
    (store: any) => store.userPaymentsReducer.userPayments,
  );
  const dispatch = useDispatch();
  return (
    <Box width="250px">
      <ViewSettingsDropDown
        settingId={userPaymentsSettingId}
        viewName="UserPayments"
        isQueryParamDefault
        onViewSettingSelected={(selectedSettingId, viewSettings) =>
          dispatch(
            updateUserPaymentsViewSettingObject({
              settingId: selectedSettingId,
              viewSettings,
            }),
          )
        }
        activityType={gridModeTypes.UserPaymentHistory}
        currentViewSettings={userPaymentsViewSettings}
      />
    </Box>
  );
};
