import {actionTypes} from '../constants/actionTypes';
import {fetchInvoiceLines} from '../../services/communications';

export const getInvoiceLines =
  ({invoiceKey, pageSize, skip, sortQuery, globalFilterQuery}) =>
  dispatch => {
    dispatch({type: actionTypes.FETCHING_INVOICE_LINES});
    fetchInvoiceLines({
      invoiceKey,
      skip,
      pageSize,
      sortQuery,
      globalFilterQuery,
    })
      .then(res => {
        dispatch({
          type: actionTypes.FETCHED_INVOICE_LINES,
          invoiceLines: res.data.value,
          invoiceLinesCount: res.data.count,
          invoiceLinesNextLink: res.data.nextLink,
        });
        return res.data;
      })
      .catch(() => {
        dispatch({type: actionTypes.FETCHING_INVOICE_LINES_FAILED});
      });
  };
