import {Button, Checkbox, Tree} from 'primereact';
import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {AiFillFolder} from 'react-icons/ai';
import {constants} from '../../constants/common';
import Backdrop from '../common/Backdrop';
import {copyAttachmentsToCustDocsTemplateService} from '../../services/activities';
import {getFileList} from '../../store/actions/fileActions';
import {StorageType} from '../../constants/typeConstants';
import CopyAttachmentMessageTemplate from './CopyAttachmentMessageTemplate';
import {downloadAttachment} from '../../services/mailbox';
import {FileItem} from '../common/file-manager/files-viewer/FileItem';

function CopyAttachmentsToCustDocsTemplate({
  attachments,
  setAttachments,
  groupKey,
  custKey,
  mailKey,
}) {
  const [selectDeselect, setSelectDeselect] = useState(false);
  const [selectedNodeKey, setSelectedNodeKey] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [attachmentSelected, setAttachmentSelected] = useState(false);
  const [responseMessage, setResponseMessage] = useState(null);
  const [fileExists, setFileExists] = useState(null);
  const [fileNotExists, setFileNotExists] = useState(null);
  const {isLoading} = useSelector(store => store.fileReducer);
  const [treeData, setTreeData] = useState(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const storageType = StorageType.public;
  const onAttachmentChkChange = (e, index) => {
    const attachmentsObj = attachments?.map((attachment, i) => {
      if (i === index) {
        return {...attachment, selected: e.checked};
      }

      return {...attachment};
    });
    const checkIsSelectedAttachment = attachmentsObj?.filter(
      att => att?.selected,
    );
    if (checkIsSelectedAttachment.length > 0) {
      setAttachmentSelected(true);
    } else {
      setAttachmentSelected(false);
    }
    setAttachments(attachmentsObj);
  };

  const loopThroughFilesAndFolder = files => {
    return files
      .filter(file => file.fileName.toLocaleLowerCase() !== 'directory.exists')
      .map(file => {
        return {...file, label: file.fileName};
      });
  };

  const toggleSelectedAttachment = val => {
    const attachmentsObg = attachments?.map(attachment => {
      return {...attachment, selected: val};
    });
    setAttachments(attachmentsObg);
  };

  const loadInitialValues = () => {
    setSelectedNodeKey(null);
    setSelectedFolder(null);
    toggleSelectedAttachment(false);
    setSelectDeselect(false);
    setLoading(false);
  };

  const loadFileList = async () => {
    setLoading(true);
    const blobPath = `uploads/${groupKey}/custdocs/${custKey}`;
    const response = await dispatch(
      getFileList({storageType, blobPath, groupKey}),
    );
    response.map(function recursion(res) {
      if (res?.files?.length > 0) {
        res.files = loopThroughFilesAndFolder(res.files);
        if (res.files.length > 0) {
          if (Array.isArray(res.children)) {
            res.children = [...res.children, ...res.files];
          } else {
            res.children = [...res.files];
          }
        }
      }
      if (res?.children?.length > 0) {
        res.children.map(res1 => recursion(res1));
      }
      return res;
    });
    setTreeData(response);
    loadInitialValues();
  };

  useEffect(() => {
    loadFileList();
  }, []);

  const onSelectDeselectChkChange = e => {
    if (e.checked) {
      setAttachmentSelected(true);
    } else {
      setAttachmentSelected(false);
    }
    setSelectDeselect(e.checked);
    toggleSelectedAttachment(e.checked);
  };

  const onSelect = e => {
    let {path} = e.node;
    const {key} = e.node;
    setFileExists(null);
    setFileNotExists(null);
    if (path) {
      path = path.substring(path.indexOf('/') + 1);
      setSelectedNodeKey(key);
      setSelectedFolder(e.node);
    }
  };

  const checkFileExistsInDestinationDir = () => {
    const files = attachments.filter(file => file.selected);

    const requestFiles = files?.filter(
      x => !selectedFolder?.files?.some(y => y.fileName === x.fileName),
    );
    const duplicateFiles = files.filter(x =>
      selectedFolder?.files?.some(y => y.fileName === x.fileName),
    );

    setFileExists(duplicateFiles);
    return {filesNotExists: requestFiles};
  };

  const onCopy = async () => {
    const {filesNotExists} = checkFileExistsInDestinationDir();
    const path = selectedFolder?.path;
    const destinationFolderPath = path.substring(path.indexOf('/') + 1);
    const selectedFiles = attachments?.filter(att => att.selected);
    const request = selectedFiles.map(file => {
      const sp = file?.fileUrl.replace('Blob://', '');
      return {
        SourceBlobPath: sp,
        DestinationBlobPath: `uploads/${groupKey}/custdocs/${custKey}/${destinationFolderPath}/${file.fileName}`,
      };
    });
    try {
      if (filesNotExists.length > 0) {
        setLoading(true);
        const response = await copyAttachmentsToCustDocsTemplateService(
          groupKey,
          request,
        );
        setFileNotExists(filesNotExists);
        loadInitialValues();
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const onRefresh = () => {
    loadFileList();
    setFileExists(null);
    setFileNotExists(null);
  };

  const treeNodeTemplate = node => {
    const label = <span>{node.label}</span>;
    return (
      <span
        title={node.label}
        className="d-flex align-items-center overflow-ellipse w-200-px"
      >
        {node.path && (
          <>
            <AiFillFolder color="sandybrown" size={15} />{' '}
            <span className="ms-2"> {label}</span>
          </>
        )}
        {!node.path && <FileItem size={15} name={node.label} />}
      </span>
    );
  };
  return (
    <>
      {loading && <Backdrop />}
      <h2 className="ps-5">{constants.COPY_ATTACHMENTS_TO_CUST_DOCS}</h2>
      <div className="ps-5 mt-3">
        <CopyAttachmentMessageTemplate
          className="text-error"
          data={fileExists}
          message={constants.ALREADY_EXISTS}
        />
        <CopyAttachmentMessageTemplate
          className="text-success"
          data={fileNotExists}
          message={constants.COPIED_SUCCESSFULLY}
        />
      </div>
      <div className="d-flex mt-2">
        <div className="ps-5 w-50">
          <div className="h-50-px my-2">
            <Checkbox
              autoFocus={false}
              checked={selectDeselect}
              onChange={onSelectDeselectChkChange}
            />{' '}
            {`${constants.SELECT}/${constants.DESELECT}${constants.ALL}`}
          </div>
          <h6 className="mt-1">{constants.CHOOSE_DOCUMENTS_TO_ATTACH}</h6>
          <div className="list-documents border h-300-px overflow-y-auto">
            {attachments?.map((attachment, index) => {
              return (
                <div
                  key={attachment?.id ? attachment.id : index}
                  className="p-2 my-1 d-flex text-align-center"
                >
                  <Checkbox
                    autoFocus={false}
                    checked={attachment.selected}
                    onChange={e => onAttachmentChkChange(e, index)}
                  />
                  <Button
                    key={attachment?.id ? attachment.id : index}
                    className="px-2 btn-over-flow btn-as-link"
                    onClick={() => downloadAttachment(mailKey, attachment)}
                    title={attachment?.fileName}
                  >
                    {attachment?.fileName};
                  </Button>
                </div>
              );
            })}
          </div>
        </div>
        <div className="w-80">
          <div className="d-flex h-50-px mb-1">
            <Button
              disabled={!attachmentSelected || !selectedNodeKey}
              className="btn btn-purple h-25-px me-5"
              onClick={onCopy}
            >
              {constants.COPY}
            </Button>
            <Button className="btn btn-purple h-25-px" onClick={onRefresh}>
              {constants.REFRESH}
            </Button>
          </div>
          <h6>{constants.CHOOSE_FOLDER_TO_COPY_TO}</h6>
          <div className="w-100 h-300-px">
            <Tree
              selectionMode="single"
              className="file-explorer-tree"
              value={treeData}
              selectionKeys={selectedNodeKey}
              onSelect={onSelect}
              loading={isLoading}
              nodeTemplate={treeNodeTemplate}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default CopyAttachmentsToCustDocsTemplate;
