import data from '../lib/data';
import {isEmpty} from '../lib/utils';

export const fetchCustomerInfoList = (customerState, filterQuery) => {
  let endpoint = `v2/api/customerInfo/?`;

  if (!isEmpty(customerState.orderBy)) {
    endpoint = `${endpoint}&%24orderby=${customerState.orderBy}`;
  }

  if (!isEmpty(customerState.skip)) {
    endpoint += `&%24skip=${customerState.skip}`;
  }

  if (!isEmpty(customerState.top)) {
    endpoint += `&%24top=${customerState.top}`;
  }

  if (filterQuery) {
    endpoint += `&%24filter=${encodeURIComponent(filterQuery)}`;
  }
  return data.get(endpoint);
};

export const fetchCustomerInfoListByUserKey = (
  userKey,
  options,
  customerState,
) => {
  let endpoint = `V2/api/search/customers/${userKey}?`;

  if (!isEmpty(customerState.orderBy)) {
    endpoint = `${endpoint}&%24orderby=${customerState.orderBy}`;
  }

  if (!isEmpty(customerState.skip)) {
    endpoint += `&%24skip=${customerState.skip}`;
  }

  if (!isEmpty(customerState.top)) {
    endpoint += `&%24top=${customerState.top}`;
  }

  return data.post(endpoint, options);
};
