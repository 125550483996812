import {Button, InputTextarea} from 'primereact';
import {useState} from 'react';
import {AiOutlineInfoCircle} from 'react-icons/ai';
import {constants} from '../../constants/common';
import {isEmpty} from '../../lib/utils';

function AddNoteTemplate({
  onHideDialog = () => {},
  onAddNote = () => {},
  setNoteTxt = () => {},
  noteTxt,
}) {
  const [errorMessage, setErrorMessage] = useState('');

  const handleAddNote = () => {
    if (isEmpty(noteTxt)) {
      setErrorMessage(constants.NOTE_FIELD_IS_REQUIRED_FIELD);
    } else {
      onAddNote();
    }
  };

  return (
    <>
      <label className="my-2" htmlFor="note">
        {constants.NOTE}
      </label>
      <InputTextarea
        id="note"
        className="my-2 w-100"
        rows={4}
        cols={30}
        value={noteTxt}
        onChange={e => {
          setNoteTxt(e.target.value);
          setErrorMessage('');
        }}
        autoResize
      />

      <div className="my-3 text-error bold">
        {errorMessage ? (
          <>
            <AiOutlineInfoCircle color="red" size={23} className="me-2" />
            {errorMessage}
          </>
        ) : (
          ''
        )}
      </div>

      <Button className="bg-purple me-2" onClick={handleAddNote}>
        {constants.OK}
      </Button>
      <Button className="bg-purple" onClick={onHideDialog}>
        {constants.CANCEL}
      </Button>
    </>
  );
}
export default AddNoteTemplate;
