import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  GridColumnVisibilityModel,
  GridSortModel,
  GridPaginationModel,
  GridColDef,
  GridFilterModel,
  GridLogicOperator,
} from '@mui/x-data-grid';
import {getInvoiceLines} from '../../../store/actions/invoiceLinesActions';
import {invoiceLinesColumns} from '../../common/table/columns/invoiceLinesColumns';
import {DataGrid} from '../../../ui/data';
import InvoiceLinesToolbar from './InvoiceLinesToolbar';
import {
  applyBestFit,
  convertFilterToQueryString,
  hasFilterValue,
} from '../../../lib/commonTableHelpers';
import {PAGE_SIZE, UDF_LABELS_REF} from '../../../utils/constants';
import {updateInvoiceLinesUDFColumns} from '../../../utils/udfList';

function InvoiceLines({
  selectedRowForInvoiceLines,
}: {
  selectedRowForInvoiceLines: any;
}) {
  const invoiceKey = selectedRowForInvoiceLines[0]?.invcKey;
  const dispatch = useDispatch<any>();
  const {invoiceLines, invoiceLinesLoading, invoiceLinesCount} = useSelector(
    (store: any) => store.invoiceLinesReducer,
  );
  const [bestFit, setBestFit] = useState(false);
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({});

  const [sortColumn, setSortColumn] = useState<GridSortModel>([
    {field: 'invcLineNo', sort: 'asc'},
  ]);

  const [skip, setSkip] = useState(0);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: PAGE_SIZE,
  });

  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    logicOperator: GridLogicOperator.And,
    items: [],
  });
  const [globalFilter, setGlobalFilter] = useState('');

  const [bestFitColumns, setBestFitColumns] = useState<GridColDef[]>([]);
  const [columns, setColumns] = useState<GridColDef[]>(invoiceLinesColumns);

  const udfDetails = useSelector((store: any) => store.usersReducer.udfDetails);

  const loadInvoiceLines = ({
    sortQuery = [],
    pageSizeParam = PAGE_SIZE,
    skipParam = 0,
    globalFilterQueryParam = '',
  }: {
    sortQuery: any[];
    pageSizeParam: number;
    skipParam: number;
    globalFilterQueryParam?: string;
  }) => {
    dispatch(
      getInvoiceLines({
        invoiceKey,
        sortQuery,
        skip: skipParam,
        pageSize: pageSizeParam,
        globalFilterQuery: globalFilterQueryParam,
      }),
    );
  };
  // Reset filter model
  const resetFilterModel = () => {
    setFilterModel({
      logicOperator: GridLogicOperator.And,
      items: [],
    });
  };

  // reset pagination
  const resetPagination = () => {
    setPaginationModel({
      pageSize: paginationModel.pageSize,
      page: 0,
    });
  };

  useEffect(() => {
    const updatedColumns = updateInvoiceLinesUDFColumns(
      invoiceLinesColumns,
      udfDetails,
      UDF_LABELS_REF.invoiceLinesUdf,
    );
    setColumns(updatedColumns);
    loadInvoiceLines({
      sortQuery: sortColumn,
      pageSizeParam: paginationModel.pageSize,
      skipParam: 0,
      globalFilterQueryParam: globalFilter,
    });
    resetFilterModel();
    resetPagination();
  }, [invoiceKey]);

  /** Invoice Lines grid props method */
  /**
   * onPageChange is prop set for mail box grid to handle pagination
   * @param args GridPaginationModel object which has page which starts from 0 and skip properties
   */
  const onPageChange = (args: GridPaginationModel) => {
    const pageSkip =
      args.pageSize !== paginationModel.pageSize
        ? 0
        : (args.page + 1) * args.pageSize - args.pageSize;
    setPaginationModel({
      pageSize: args.pageSize,
      page: args.pageSize !== paginationModel.pageSize ? 0 : args.page,
    });
    setSkip(pageSkip);
    loadInvoiceLines({
      sortQuery: sortColumn,
      pageSizeParam: args.pageSize,
      skipParam: pageSkip,
      globalFilterQueryParam: globalFilter,
    });
  };

  /**
   * onSortChange is prop set for invoice lines grid to sorting
   * @param args GridPaginationModel array of object which field and sort properties
   */
  const onSortChange = (args: GridSortModel) => {
    setSortColumn(args);
    loadInvoiceLines({
      sortQuery: args,
      pageSizeParam: paginationModel.pageSize,
      skipParam: skip,
      globalFilterQueryParam: globalFilter,
    });
  };

  /**
   * onFilterChange is prop set for mail box grid to filter
   * @param args GridFilterModel array of object which field, value and operator properties
   */
  const onFilterChange = (args: GridFilterModel) => {
    setFilterModel(args);
    if (hasFilterValue(args)) {
      let filterQuery = '';
      if (args.items.length) {
        filterQuery = convertFilterToQueryString(args, columns);
      } else {
        // Reset filter on Remove All clicked and if no filter
        filterQuery = '';
      }
      setGlobalFilter(filterQuery);

      loadInvoiceLines({
        sortQuery: sortColumn,
        pageSizeParam: paginationModel.pageSize,
        skipParam: 0,
        globalFilterQueryParam: filterQuery,
      });
      resetPagination();
    }
  };

  const onRefresh = () => {
    loadInvoiceLines({
      sortQuery: sortColumn,
      pageSizeParam: paginationModel.pageSize,
      skipParam: skip,
      globalFilterQueryParam: globalFilter,
    });
  };
  const applyGridBestFit = () => {
    applyBestFit(columns, bestFit, setBestFit, setBestFitColumns);
  };

  return (
    <div className="my-2">
      <DataGrid
        disableVirtualization
        columns={bestFit ? bestFitColumns : columns}
        rows={invoiceLines}
        loading={invoiceLinesLoading}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityChange={data => setColumnVisibilityModel(data)}
        rowCount={invoiceLinesCount}
        onPageChange={onPageChange}
        onFilterChange={onFilterChange}
        onSortChange={onSortChange}
        sortModel={sortColumn}
        CustomToolbar={InvoiceLinesToolbar}
        customToolbarMethods={{
          onRefresh,
          applyGridBestFit,
        }}
        filterModel={filterModel}
        paginationModel={paginationModel}
        getRowId={row => row?.itemID}
      />
    </div>
  );
}
export default InvoiceLines;
