import {GridFilterAltIcon} from '@mui/x-data-grid';
import {useFilterPopoverContext} from './FilterPopoverContext';
import {Button} from '../../inputs';
import {constants} from '../../../constants/common';

const CustomFilterButton = () => {
  const {setAnchorEl} = useFilterPopoverContext();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Button
      data-testid="custom-filter-button"
      startIcon={<GridFilterAltIcon />}
      onClick={handleClick}
    >
      {constants.FILTERS}
    </Button>
  );
};

export default CustomFilterButton;
