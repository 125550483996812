import {useDispatch, useSelector} from 'react-redux';
import {ViewSettingsDropDown} from '../../../common/view-settings/view-settings-dropdown/ViewSettingsDropDown';
import {Box} from '../../../../ui/layouts';
import {gridModeTypes} from '../../../../constants/common';
import {updateReportingGroupLogsDashboardViewObject} from '../../../../store/actions/reportingGroupLogsActions';

export const ViewSettings = () => {
  const {reportingGroupLogsSettingId, reportingGroupLogsViewSettings} =
    useSelector((store: any) => store.reportingGroupLogsReducer);
  const dispatch = useDispatch();
  return (
    <Box display="flex" justifyContent="flex-end" marginY="12px">
      <Box width="250px">
        <ViewSettingsDropDown
          settingId={reportingGroupLogsSettingId}
          viewName="ReportingGroupLogs"
          isQueryParamDefault
          onViewSettingSelected={(selectedSettingId, viewSettings) =>
            dispatch(
              updateReportingGroupLogsDashboardViewObject({
                settingId: selectedSettingId,
                viewSettings,
              }),
            )
          }
          currentViewSettings={reportingGroupLogsViewSettings}
          activityType={gridModeTypes.ReportingGroupLog}
        />
      </Box>
    </Box>
  );
};
