import {actionTypes} from '../constants/actionTypes';
import {constants} from '../../constants/common';
import {downloadExcel} from '../../utils/fileUtils';
import {exportUserPayments, fetchUserPayments} from '../../services/payment';

export const getUserPayments = options => (dispatch, getState) => {
  const {userPayments} = getState().userPaymentsReducer;
  const toast = getState().generalReducer?.toastRef;
  const filterQueryVal =
    options.settingKey === userPayments?.userPaymentsViewSettings?.settingsKey
      ? options.filterQuery
      : '';
  const updatedOptions = {...options, globalFilterQuery: filterQueryVal};
  dispatch({type: actionTypes.FETCHING_USER_PAYMENTS});
  return fetchUserPayments(updatedOptions)
    .then(response => {
      dispatch({
        type: actionTypes.FETCHED_USER_PAYMENTS,
        userPaymentsData: response.data.value,
        userPaymentsCount: response.data.count,
        userPaymentsDataTotal: [
          response.data.totalTranAmount,
          response.data.totalUnappliedAmount,
        ],
      });
    })
    .catch(() => {
      dispatch({type: actionTypes.FETCHING_USER_PAYMENTS_FAILED});
      toast?.current.show({
        severity: 'warn',
        summary: constants.FAILED_TO_LOAD_DATA,
        detail: constants.LOADING_PAYMENTS_FAILED,
      });
    });
};

export const updateUserPaymentsSkip = userPaymentsSkip => {
  return {
    type: actionTypes.UPDATE_USER_PAYMENTS_SKIP,
    userPaymentsSkip,
  };
};

export const updateUserPaymentsPageSize = userPaymentsPageSize => dispatch => {
  dispatch({type: actionTypes.FETCHING_USER_PAYMENTS});
  dispatch({
    type: actionTypes.UPDATE_USER_PAYMENTS_PAGE_SIZE,
    userPaymentsPageSize,
  });
};

export const updateUserPaymentsViewSettingObject = ({
  settingId,
  viewSettings,
}) => {
  return {
    type: actionTypes.SET_USER_PAYMENTS_VIEW_SETTING_OBJECT,
    payload: {settingId, viewSettings},
  };
};

export const userPaymentsExportToExcel = options => (dispatch, getState) => {
  const toast = getState().generalReducer?.toastRef;

  dispatch({type: actionTypes.EXPORTING_TO_EXCEL_USER_PAYMENTS});
  toast?.current.show({
    severity: 'info',
    summary: constants.EXPORTING,
  });
  exportUserPayments(options)
    .then(res => {
      downloadExcel(
        res.data,
        constants.PAYMENTS,
        toast,
        constants.PAYMENTS_EXPORTED_TO_EXCEL,
      );
      toast?.current.show({
        severity: 'success',
        summary: constants.EXPORTED_SUCCESSFULLY,
      });
      dispatch({type: actionTypes.EXPORTED_TO_EXCEL_USER_PAYMENTS});
    })
    .catch(() => {
      dispatch({type: actionTypes.EXPORTING_TO_EXCEL_USER_PAYMENTS_FAILED});
      toast?.current.show({
        severity: 'warn',
        summary: constants.FAILED_TO_EXPORT,
      });
    });
};

export const cleanUpUserPayments = () => {
  return {
    type: actionTypes.CLEAN_UP_USER_PAYMENTS,
  };
};
