import {Button, Checkbox, InputText, InputTextarea} from 'primereact';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {constants} from '../../constants/common';
import {addActivitiesContact} from '../../store/actions/activitiesActions';
import {getUserData} from '../../store/actions/userActions';
import Backdrop from '../common/Backdrop';

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
function AddContactTemplate({setShowOnContactDialog = val => {}, CustKey}) {
  const dispatch = useDispatch();
  const addingContact = useSelector(
    store => store.activitiesReducer.addingContact,
  );
  const activityInfo = useSelector(
    store => store.activitiesReducer.activityInfo,
  );
  const [contactInfo, setContactInfo] = useState({
    includeInEmailChk: false,
    includeInEmail2Chk: false,
    keepInSyncChk: false,
    primaryChk: false,
    inactiveChk: false,
    contactName: '',
    address1: '',
    address2: '',
    address3: '',
    address4: '',
    address5: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
    phone: '',
    phoneExt: '',
    fax: '',
    faxExt: '',
    email: '',
    email2: '',
    title: '',
    comment: '',
  });
  const UDFObj = {
    UDF1: '',
    UDF2: '',
    UDF3: '',
    UDF4: '',
    UDF5: '',
    UDF6: '',
    UDF7: '',
    UDF8: '',
    UDF9: '',
    UDF10: '',
  };
  const groupKey = getUserData()?.groupKey;

  const saveAddContact = () => {
    const options = {
      groupKey,
      contactName: contactInfo.contactName,
      comment: contactInfo.comment,
      custKey: activityInfo?.customerTask?.custKey || CustKey,
      emailAddr: contactInfo.email,
      emailAddr2: contactInfo.email2,
      fax: contactInfo.fax,
      faxExt: contactInfo.faxExt,
      includeInEmailYN: contactInfo.includeInEmailChk === true ? '1' : '0',
      includeInEmailYN2: contactInfo.includeInEmail2Chk === true ? '1' : '0',
      keepCntctInSync: contactInfo.keepInSyncChk === true ? '1' : '0',
      phone: contactInfo.phone,
      phoneExt: contactInfo.phoneExt,
      primaryContactFlag: contactInfo.primaryChk === true ? '1' : '0',
      title: contactInfo.title,
      addrLine1: contactInfo.address1,
      addrLine2: contactInfo.address2,
      addrLine3: contactInfo.address3,
      addrLine4: contactInfo.address4,
      addrLine5: contactInfo.address5,
      city: contactInfo.city,
      state: contactInfo.state,
      country: contactInfo.country,
      postalCode: contactInfo.zipCode,
      isInactive: contactInfo.inactiveChk,
      Notes: '',
      PrintAs: '',
      CheckSum: '',
      LinkedInProfile: '',
      ProfilePictureURL: '',
      ...UDFObj,
    };
    dispatch(addActivitiesContact(options));
    setShowOnContactDialog(false);
  };

  const onValueChange = (value, field) => {
    setContactInfo(prevState => {
      return {...prevState, [field]: value};
    });
  };

  return (
    <div className="card">
      {addingContact && <Backdrop />}
      <div className="d-flex p-4 flex-wrap-reverse">
        <div className="d-flex flex-column align-items-center justify-content-center p-0">
          <span>{constants.INCLUDE_IN_EMAIL}</span>
          <br />
          <Checkbox
            checked={contactInfo.includeInEmailChk}
            onChange={e => onValueChange(e.checked, 'includeInEmailChk')}
          />
        </div>
        <div className="d-flex flex-column align-items-center justify-content-center p-0  ms-3">
          <span>{`${constants.INCLUDE_IN_EMAIL}2`}</span>
          <br />
          <Checkbox
            checked={contactInfo.includeInEmail2Chk}
            onChange={e => onValueChange(e.checked, 'includeInEmail2Chk')}
          />
        </div>
        <div className="d-flex flex-column align-items-center justify-content-center p-0 ms-3">
          <span>{constants.KEEP_IN_SYNC}</span>
          <br />
          <Checkbox
            checked={contactInfo.keepInSyncChk}
            onChange={e => onValueChange(e.checked, 'keepInSyncChk')}
          />
        </div>
        <div className="d-flex flex-column align-items-center justify-content-center p-0 ms-3">
          <span>{constants.PRIMARY}</span>
          <br />
          <Checkbox
            checked={contactInfo.primaryChk}
            onChange={e => onValueChange(e.checked, 'primaryChk')}
          />
        </div>
        <div className="d-flex flex-column align-items-center justify-content-center p-0 ms-3">
          <span>{constants.INACTIVE}</span>
          <br />
          <Checkbox
            checked={contactInfo.inactiveChk}
            onChange={e => onValueChange(e.checked, 'inactiveChk')}
          />
        </div>
        <div className="d-flex align-items-center justify-content-center p-0 ms-3">
          <Button className="btn btn-purple mb-2" onClick={saveAddContact}>
            {constants.SAVE}
          </Button>
          <Button
            className="btn btn-purple mb-2"
            onClick={() => setShowOnContactDialog(false)}
          >
            {constants.CANCEL}
          </Button>
        </div>
      </div>

      <div className="mt-3 d-flex align-items-center justify-content-between">
        <table className="p-2 w-100 border-collapse-seperate">
          <tbody>
            <tr>
              <td>
                <label htmlFor="contact-name">{constants.CONTACT_NAME}</label>
              </td>
              <td colSpan={4}>
                <InputText
                  id="contact-name"
                  className="w-100 input-text-custom"
                  value={contactInfo.contactName}
                  onChange={e => onValueChange(e.target.value, 'contactName')}
                />
              </td>
            </tr>

            <tr>
              <td className="align-middle">
                <label htmlFor="addresses">{constants.ADDRESSES}</label>
              </td>
              <td colSpan={4}>
                <div className="my-4" id="addresses">
                  {[1, 2, 3, 4, 5].map(num => (
                    <InputText
                      key={num}
                      className="mt-2 w-100 input-text-custom"
                      value={contactInfo[`address${num}`]}
                      onChange={e =>
                        onValueChange(e.target.value, `address${num}`)
                      }
                    />
                  ))}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <label htmlFor="city">{constants.CITY}</label>
              </td>
              <td>
                <InputText
                  id="city"
                  className="w-100 input-text-custom"
                  value={contactInfo.city}
                  onChange={e => onValueChange(e.target.value, 'city')}
                />
              </td>
              <td className="text-center">
                <label htmlFor="state">{constants.STATE}</label>
              </td>
              <td>
                <InputText
                  id="state"
                  className="w-100 input-text-custom"
                  value={contactInfo.state}
                  onChange={e => onValueChange(e.target.value, 'state')}
                />
              </td>
            </tr>
            <br />
            <tr>
              <td>
                <label htmlFor="zip-code">{constants.ZIP_CODE}</label>
              </td>
              <td>
                <InputText
                  id="zip-code"
                  className="w-100 input-text-custom"
                  value={contactInfo.zipCode}
                  onChange={e => onValueChange(e.target.value, 'zipCode')}
                />
              </td>
              <td className="text-center">
                <label htmlFor="country">{constants.COUNTRY}</label>
              </td>
              <td>
                <InputText
                  id="country"
                  className="w-100 input-text-custom"
                  value={contactInfo.country}
                  onChange={e => onValueChange(e.target.value, 'country')}
                />
              </td>
            </tr>
            <br />
            <tr>
              <td>
                <label htmlFor="phone">{constants.PHONE}</label>
              </td>
              <td>
                <InputText
                  id="phone"
                  className="w-100 input-text-custom"
                  value={contactInfo.phone}
                  onChange={e => onValueChange(e.target.value, 'phone')}
                />
              </td>
              <td className="text-center">
                <label htmlFor="phone-ext">{constants.PHONE_EXT}</label>
              </td>
              <td>
                <InputText
                  id="phone-ext"
                  className="w-100 input-text-custom"
                  value={contactInfo.phoneExt}
                  onChange={e => onValueChange(e.target.value, 'phoneExt')}
                />
              </td>
            </tr>
            <br />
            <tr>
              <td>
                <label htmlFor="fax">{constants.FAX}</label>
              </td>
              <td>
                <InputText
                  id="fax"
                  className="w-100 input-text-custom"
                  value={contactInfo.fax}
                  onChange={e => onValueChange(e.target.value, 'fax')}
                />
              </td>
              <td className="text-center">
                <label htmlFor="fax-ext">{constants.FAX_EXT}</label>
              </td>
              <td>
                <InputText
                  id="fax-ext"
                  className="w-100 input-text-custom"
                  value={contactInfo.faxExt}
                  onChange={e => onValueChange(e.target.value, 'faxExt')}
                />
              </td>
            </tr>
            <br />
            <tr>
              <td>
                <label htmlFor="email">{constants.EMAIL}</label>
              </td>
              <td>
                <InputText
                  id="email"
                  className="w-100 input-text-custom"
                  value={contactInfo.email}
                  onChange={e => onValueChange(e.target.value, 'email')}
                />
              </td>
              <td className="text-center">
                <label htmlFor="email">{`${constants.EMAIL}2`}</label>
              </td>
              <td>
                <InputText
                  id="email"
                  className="w-100 input-text-custom"
                  value={contactInfo.email2}
                  onChange={e => onValueChange(e.target.value, 'email2')}
                />
              </td>
            </tr>
            <br />
            <tr>
              <td>
                <label htmlFor="title">{constants.TITLE}</label>
              </td>
              <td>
                <InputText
                  id="title"
                  className="w-100 input-text-custom"
                  value={contactInfo.title}
                  onChange={e => onValueChange(e.target.value, 'title')}
                />
              </td>
            </tr>
            <br />
            <tr>
              <td>
                <label htmlFor="comment">{constants.COMMENT}</label>
              </td>
              <td colSpan={4}>
                <InputTextarea
                  id="comment"
                  rows={4}
                  cols={30}
                  autoResize
                  className="w-100"
                  value={contactInfo.comment}
                  onChange={e => onValueChange(e.target.value, 'comment')}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
export default AddContactTemplate;
