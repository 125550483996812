/* eslint-disable */
import moment from 'moment';
import {
  getGridBooleanOperators,
  getGridDateOperators,
  getGridNumericOperators,
  getGridStringOperators,
  GridAlignment,
  GridColumnResizeParams,
  GridLogicOperator,
} from '@mui/x-data-grid-pro';
import dayjs from 'dayjs';
import {Dispatch, SetStateAction} from 'react';
import {isValidDate} from '../utils/dataTimeUtils';
import {ColumnNames, constants} from '../constants/common';
import {isEmpty, isNumeric} from './utils';
import {IColumns} from '../components/common/interface/viewSettingsInterfaces';
import {
  ColumnSetting,
  IViewSetting,
} from '../components/common/view-settings/interface';
import {
  mapUDFDisplayText,
  removeUDFColumnsWithNoDisplayText,
  UDFLabelRefType,
} from '../utils/udfList';

/**
 * Method to convert column data type format from api to MUI data grid format
 * If none of the type matches then it will return null and getColumns method will handle it
 * @param {string} type Column data type received from api
 * @returns returns MUI data grid column data type "date" | "string" | "boolean" | "number" | "dateTime" | null
 */

export const convertColumnType = (type: any) => {
  switch (type) {
    case 'String':
      return 'string';
    case 'Decimal':
    case 'Int32':
      return 'number';
    case 'Date':
      return 'date';
    case 'DateTime':
      return 'dateTime';
    case 'Boolean':
      return 'boolean';
    default:
      return null;
  }
};

/**
 * Method which takes both local and server columns and gives you sorted columns and list of columns to be hidden initially
 * @param {any[]} data List of columns received from get activities list api
 * @param {any[]} communicationColumns List of columns defined on client side
 * @returns a object with sortedColumns which has sorted columns and initialHiddenColumns which has list of columns to be hidden initially
 */
export const getColumns = (
  data: any[],
  communicationColumns: any[],
  viewSortSettings: any,
) => {
  const initialHiddenColumns = data?.reduce((acc, curr) => {
    if (!curr.isDisplay) {
      acc[curr.dataField] = curr.isDisplay;
    }

    return acc;
  }, {});
  let sortedData = [];
  if (data.length === 0) {
    sortedData = [...communicationColumns];
  } else {
    const orderIndexedData = communicationColumns.map(item => {
      const f = data.find(val =>
        item.mandatory
          ? val.displayText === item.headerName
          : val.dataField === item.field,
      );
      return {
        ...item,
        headerName: f?.displayText || item.headerName,
        type: convertColumnType(f?.columnDataType) || item.type,
        orderIndex: f?.orderIndex,
      };
    });
    sortedData = orderIndexedData.sort(
      (a, b) => (a.orderIndex || Infinity) - (b.orderIndex || Infinity),
    );

    communicationColumns.forEach(element => {
      if (data.findIndex(rep => rep.dataField === element.field) === -1) {
        initialHiddenColumns[element.field] = false;
      }
    });
  }

  let formattedSortData = [];
  if (
    viewSortSettings &&
    viewSortSettings?.viewSetting?.sortSetting?.length > 0
  ) {
    const settings = viewSortSettings.viewSetting.sortSetting;
    formattedSortData = settings.map((item: any) => {
      return {field: item.sortCol, sort: item.sortOrder.toLowerCase()};
    });
  }
  return {
    sortedColumns: sortedData,
    initialHiddenColumns,
    formattedSortData,
  };
};

/**
 * Convert user friendly operator to SQL operator
 * @param {string} operator Operator which needs to be converted to SQL operator
 * @returns returns SQL operator
 */

const isNotNullLabel = 'IS NOT NULL';
function convertOperatorToSQL(operator: string) {
  switch (operator) {
    case 'contains':
      return 'LIKE';
    case 'equals':
    case 'is':
      return '=';
    case 'startsWith':
      return 'LIKE';
    case 'endsWith':
      return 'LIKE';
    case 'notContains':
      return 'NOT LIKE';
    case 'notEquals':
    case 'not':
      return '<>';
    case 'notStartsWith':
      return 'NOT LIKE';
    case 'notEndsWith':
      return 'NOT LIKE';
    case 'greaterThan':
    case 'after':
      return '>';
    case 'greaterThanOrEqual':
    case 'onOrAfter':
      return '>=';
    case 'lessThan':
    case 'before':
      return '<';
    case 'lessThanOrEqual':
    case 'onOrBefore':
      return '<=';
    case 'isEmpty':
      return 'IS NULL';
    case 'isNotEmpty':
      return isNotNullLabel;
    default:
      return operator;
  }
}

// To convert SQL operators coming in api response to MUI filter operator. Example <> to !=.
export const convertOperatorToMuiFilterOperator = (
  operator: string,
  type: string,
) => {
  switch (operator?.toLowerCase()) {
    case 'LIKE'.toLowerCase():
    case 'startsWith'.toLowerCase():
    case 'endsWith'.toLowerCase():
      return 'contains';
    case '=':
      if (type === 'boolean' || type === 'date' || type === 'datetime') {
        return 'is';
      }
      if (type === 'number') {
        return operator;
      }
      return 'equals';
    case 'NOT LIKE':
      return 'notContains';
    case '<>':
      return type === 'number' ? '!=' : 'not';
    case 'IS NULL'.toLowerCase():
      return 'isEmpty';
    case isNotNullLabel.toLowerCase():
      return 'isNotEmpty';
    default:
      return operator;
  }
};

/**
 * Convert array of filters to string
 * @param {*} filter list of filtered columns along with values
 * @param {*} columns list of columns displayed. This is required to check the type of column
 * @returns return string in () format as required for api request
 */

// eslint-disable-next-line consistent-return
export const getColumnFieldNameAndType = (field: any, columns: any[]) => {
  if (columns.length) {
    const tempObj = columns?.find(
      column => column?.field.toLowerCase() === field.toLowerCase(),
    );
    return {field: tempObj?.field || field, type: tempObj?.type};
  }
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const convertFilterToQueryString = (filter: any, columns: any) => {
  if (!filter || !filter.items || filter.items.length === 0) {
    return '';
  }

  const {logicOperator} = filter;
  let {items} = filter;

  items = items.reduce((acc: any, argItem: any) => {
    if (argItem.operator === '!=') {
      // eslint-disable-next-line no-param-reassign
      argItem = {...argItem, operator: '<>'};
    }
    if (argItem.value !== '') {
      acc.push(argItem);
    }
    return acc;
  }, []);

  const isNegated = logicOperator && logicOperator === 'NOT';

  const text = items
    .map((item: any) => {
      let value = item.value || '';
      if (typeof item.value === 'string') {
        value = item.value.includes(' ') ? `"${item.value}"` : value || "''";
      }

      const column = columns.find((col: any) => col.field === item.field);
      if (column && column.type === 'date') {
        const formattedDate = moment.utc(value).format('M/D/YYYY');
        value = isValidDate(formattedDate) ? formattedDate : '';
      }

      return `${item.field} ${convertOperatorToSQL(item.operator)} ${value}`;
    })
    .join(` ${logicOperator} `);

  return isNegated ? `NOT (${text})` : `(${text})`;
};

/**
 * Check if filters has value
 * @param {*} args list of filtered columns along with values
 * @returns returns true if filters array has value else returns false
 */
export const hasFilterValue = (args: any) => {
  const valueExits = args.items.every(
    (obj: any) =>
      (Object.prototype.hasOwnProperty.call(obj, 'value') &&
        obj.value !== undefined &&
        obj.value !== null) ||
      obj.operator === 'isEmpty' ||
      obj.operator === 'isNotEmpty',
  );
  return valueExits || args.items.length === 0;
};

/**
 * Check if filters has value
 * @param {*} args list of filtered columns along with values
 * @returns returns true if filters array has value else returns false
 */
export const hasQuickFilterValue = (args: any) => {
  if (args.quickFilterValues) {
    return args.quickFilterValues.length > 0;
  }
  return false;
};

/**
 * Get selected rows Id and returns object of those ids
 * @param {*} selectedRows Rows selected by user
 * @param {*} rows Rows displayed
 * @param {*} key key on which data has to be filtered
 * @returns list of objects selected by user
 */
export const getSelectedRowsByKey = (
  selectedRows: any[],
  rows: any[],
  key: any,
) => {
  if (Array.isArray(selectedRows)) {
    return selectedRows.map(selectedId =>
      rows.find(row => row[key] === selectedId),
    );
  }
  const selected = rows.find(row => row[key] === selectedRows);
  return [selected];
};

/**
 * Get best fit width of the column
 * @param {*} field column of the respective grid
 * @returns width of the column
 */
export const calculateColumnWidth = (field: any) => {
  const query = `[data-field="${field}"].MuiDataGrid-cell`;
  const cellElements = document.querySelectorAll(query);
  let minWidth = 0;
  cellElements.forEach((cellElement: any) => {
    if (
      cellElement?.firstChild?.nodeName
        ?.toLowerCase()
        ?.includes(constants.TEXT.toLowerCase())
    ) {
      minWidth = Math.max(minWidth, cellElement.scrollWidth);
    } else {
      const cellContentWidth = cellElement.firstChild?.scrollWidth || 80;
      minWidth = Math.max(minWidth, cellContentWidth);
    }
  });
  return minWidth + 25;
};

/**
 * Get best fit width of the column
 * @param {*} columns columns of the respective grid
 * @param {*} bestFit booean value of the best fit option
 * @param {*} setBestFit state method to set best fit value
 * @param {*} setBestFitColumns state method to set best fit columns value
 * @returns updates best fit and bestfit columns values
 */
export const applyBestFit = (
  columns: any[],
  bestFit: boolean,
  setBestFit: Dispatch<SetStateAction<boolean>>,
  setBestFitColumns: any,
) => {
  const bestFitColumnsTemp = columns.map(column => {
    const minWidth = calculateColumnWidth(column.field);
    return {
      ...column,
      minWidth,
    };
  });
  setBestFit(!bestFit);
  setBestFitColumns(bestFitColumnsTemp);
};

function replaceOperatorLabel(value: string) {
  switch (value) {
    case 'is':
    case '=':
      return 'equals';
    case '!=':
      return 'notEquals';
    case 'isEmpty':
      return 'is null';
    case 'isNotEmpty':
      return 'is not null';
    case '<':
    case 'before':
      return 'lessThan';
    case '>':
    case 'after':
      return 'greaterThan';
    case '<=':
    case 'onOrBefore':
      return 'lessThanOrEqualTo';
    case '>=':
    case 'onOrAfter':
      return 'greaterThanOrEqualTo';
    case 'startsWith':
      return 'begins with';
    case 'endsWith':
      return 'ends with';
    default:
      return value;
  }
}

function replaceDateOperatorValue(value: string) {
  switch (value) {
    case '=':
      return 'is';
    case 'before':
      return '<';
    case 'after':
      return '>';
    case 'onOrBefore':
      return '<=';
    case 'onOrAfter':
      return '>=';
    default:
      return value;
  }
}

function getBooleanValue(input: any) {
  if (typeof input === 'string') {
    // If the input is a string, and having number like '0' and '1' convert it and compare it
    if (isNumeric(Number(input))) {
      return Number(input) !== 0;
    }
    // If the input is a string, compare it to "true" (case insensitive)
    if (!Number.isInteger(Number(input))) {
      return input.toLowerCase() === 'true';
    }
  }
  if (typeof input === 'number') {
    // If the input is a number, treat 0 as false and any other number as true
    return input !== 0;
  }
  if (typeof input === 'boolean') {
    // If the input is already a boolean, simply return it
    return input;
  }
  // If the input is of an unsupported type, return false
  return false;
}

// function to remove between condition from filter
const handleBetweenOperator = (filter: string) => {
  const removeSpecialCharAndLogicOperators = filter
    // eslint-disable-next-line no-useless-escape
    ?.replace(/[\(\)]/g, '')
    ?.replace(/ and /gi, ',')
    ?.replace(/ or /gi, ',')
    ?.replace(/"/g, '')
    ?.split(',');

  return removeSpecialCharAndLogicOperators.filter((single, i, arr) => {
    // Skip the element if it is a NOT contains condition
    if (
      single.trim().toLowerCase().startsWith('not') &&
      single.toLowerCase().includes('like')
    ) {
      return false;
    }

    if (single.toLowerCase().includes('between')) {
      arr.splice(i + 1, 1);
    }
    return !single.toLowerCase().includes('between');
  });
};

// method to get the cancatinated string value from the array if type is string
export const getConcatenatedString = (array: any[], type: string) => {
  if (type === 'string') {
    return array.slice(2).join(' ');
  }
  return array[2];
};

// TODO: Missing filter operators to be handled
// convert sql query to array of object
export const processFilterQuery = (
  filter = '',
  columns = [],
  sortedColumns = [],
) => {
  // TODO: handle between operator
  const removedSpecialCharAndLogicOperators = handleBetweenOperator(filter);
  let obj = {};
  const operators = ['IS NULL', isNotNullLabel, 'NOT LIKE'];
  // if sql operators include ['IS NULL', isNotNullLabel, 'NOT LIKE'] operators value property will not be present
  let filteredQuery = [];
  // eslint-disable-next-line sonarjs/cognitive-complexity
  filteredQuery = removedSpecialCharAndLogicOperators.map((single, i) => {
    const index = operators.findIndex(o => single.includes(o));
    if (index !== -1) {
      operators.forEach(operator => {
        if (single.includes(operator)) {
          const field = single.replace(operator, '').trim();
          const {type, field: renamedField} =
            getColumnFieldNameAndType(field, columns) || {};
          obj = {
            field: renamedField,
            operator: convertOperatorToMuiFilterOperator(operator, type),
            id: `${i}-${field}`, // for unique id
          };
        }
      });
    } else {
      const separateFieldOperatorValue = single
        .split(' ')
        .filter(single1 => !isEmpty(single1));
      // separateFieldOperatorValue will return array of length 3,
      // In which array[0] will be field,array[1] will be operator and array[2] will be the value
      if (!isEmpty(separateFieldOperatorValue[0])) {
        const {field} =
          getColumnFieldNameAndType(
            separateFieldOperatorValue[0],
            sortedColumns,
          ) || {};
        obj = {
          ...obj,
          field,
          id: `${i}-${field}`, // for unique id
        };
      }
      if (!isEmpty(separateFieldOperatorValue[1])) {
        const {type} =
          getColumnFieldNameAndType(
            separateFieldOperatorValue[0],
            sortedColumns,
          ) || {};
        obj = {
          ...obj,
          operator: convertOperatorToMuiFilterOperator(
            separateFieldOperatorValue[1],
            type,
          ),
        };
      }
      if (!isEmpty(separateFieldOperatorValue[2])) {
        const {type} =
          getColumnFieldNameAndType(
            separateFieldOperatorValue[0],
            sortedColumns,
          ) || {};
        let value = getConcatenatedString(separateFieldOperatorValue, type);
        if (type === 'date') {
          // Mui date filter follows YYYY-MM-DD format
          value = dayjs(value).format('YYYY-MM-DD');
        }
        if (type === 'boolean') {
          value = `${getBooleanValue(value)}`;
        }
        if (type === 'number') {
          value = `${value}`;
        }
        obj = {
          ...obj,
          value,
        };
      }
    }
    return obj;
  });
  return filteredQuery;
};

const dateOperators = getGridDateOperators()
  .filter(operator => operator.value !== 'isAnyOf')
  .map(o => {
    return {
      ...o,
      value: replaceDateOperatorValue(o.value),
      label: replaceOperatorLabel(o.value),
    };
  });

const stringOperators = getGridStringOperators()
  .filter(o => o.value !== 'isAnyOf' && o.value !== 'equals')
  .map(o => {
    return {...o, label: replaceOperatorLabel(o.value)};
  });

function uniqueBy(arr: any[], prop: any) {
  // Enabled downlevelIteration. in tsconfig.json
  return [...new Map(arr.map(m => [m[prop], m])).values()];
}

const numberOperators = getGridNumericOperators()
  .filter(o => o.value !== 'isAnyOf')
  .map(o => {
    return {...o, label: replaceOperatorLabel(o.value)};
  });

const booleanOperators = getGridBooleanOperators();

export const filterOperators = {
  date: dateOperators,
  string: uniqueBy(stringOperators, 'value'),
  number: numberOperators,
  boolean: booleanOperators,
};

export const getProcessedFilterQuery = (
  filter: string,
  columns: any[],
  sortedColumns: any[],
) => {
  const positionOfAndLogicalOperator = filter.toLowerCase().indexOf(' and ');
  const positionOfOrLogicalOperator = filter.toLowerCase().indexOf(' or ');

  let filterModelOperator = GridLogicOperator.And;
  if (positionOfAndLogicalOperator >= 0 && positionOfOrLogicalOperator === -1) {
    filterModelOperator = GridLogicOperator.And;
  }

  if (positionOfOrLogicalOperator >= 0 && positionOfAndLogicalOperator === -1) {
    filterModelOperator = GridLogicOperator.Or;
  }

  if (positionOfOrLogicalOperator >= 0 && positionOfAndLogicalOperator >= 0) {
    filterModelOperator =
      positionOfAndLogicalOperator < positionOfOrLogicalOperator
        ? GridLogicOperator.And
        : GridLogicOperator.Or;
  }

  const filteredQuery = processFilterQuery(
    filter,
    columns as never[],
    sortedColumns as never[],
  );
  return {filterModelOperator, filteredQuery};
};

export const applyDatatableSettings = (
  viewSettings: any,
  columns: any[],
  gridColumns: any[],
  setFilterModel: Dispatch<SetStateAction<any>> | undefined,
  setColumns: Dispatch<SetStateAction<any>>,
  setColumnVisibilityModel: Dispatch<SetStateAction<any>>,
  setSortColumn: Dispatch<SetStateAction<any>>,
) => {
  if (!viewSettings) {
    return;
  }
  const {sortedColumns, initialHiddenColumns, formattedSortData} = getColumns(
    viewSettings.columnSetting || [],
    gridColumns,
    viewSettings.sortSetting,
  );
  if (!isEmpty(viewSettings.displayFilter)) {
    const {filteredQuery, filterModelOperator} = getProcessedFilterQuery(
      viewSettings.displayFilter,
      columns.length > 0 ? columns : gridColumns,
      sortedColumns,
    );
    if (setFilterModel) {
      setFilterModel({
        logicOperator: filterModelOperator,
        items: filteredQuery,
      });
    }
  }
  setColumns(sortedColumns);
  setColumnVisibilityModel(initialHiddenColumns);
  setSortColumn(formattedSortData);
};

export const updateColumnSettings = (
  columnSetting: ColumnSetting[],
  gridColumns: any,
  setColumns: any,
  setColumnVisibilityModel: any,
) => {
  const {sortedColumns, initialHiddenColumns, formattedSortData} = getColumns(
    columnSetting || [],
    gridColumns,
    null,
  );
  setColumns(sortedColumns);
  setColumnVisibilityModel(initialHiddenColumns);
};

type ColumnAlignments = 'left' | 'center' | 'right';

export const columnAlignments: {[key in ColumnAlignments]: GridAlignment} = {
  left: 'left' as GridAlignment,
  center: 'center' as GridAlignment,
  right: 'right' as GridAlignment,
};

// This code handles column resizing. Previously, when an action was clicked, the column width would reset to its default. This code ensures the resized column width is preserved.
export const handleColumnResize = (
  params: GridColumnResizeParams,
  setColumnWidths: Dispatch<SetStateAction<Record<string, number>>>,
  columnWidths: Record<string, number>,
) => {
  setColumnWidths({
    ...columnWidths,
    [params.colDef.field]: params.width,
  });
};

// TODO : Once migration is completed. We need to avoid saving these columns as part of column settings
export const handleDuplicatesInColumnSettings = (
  columnSettings: ColumnSetting[],
) => {
  const recordsToRemove: string[] = ['dateEnteredForSort', 'select'];
  return columnSettings.filter(
    item => !recordsToRemove.includes(item.dataField),
  );
};

export const getGridColumnsSettings = (
  columnsSettingParam: any,
  columnVisibilityModelParam: any,
  sortColumnParam: any,
) => {
  const columnSettingJson = columnsSettingParam?.map((columnSetting: any) => {
    const matchField = Object.keys(columnVisibilityModelParam).find(
      hiddenObjKey =>
        (hiddenObjKey === '__check__' &&
          columnSetting.dataField === 'select') ||
        hiddenObjKey === columnSetting.dataField,
    );
    if (matchField) {
      return {
        ...columnSetting,
        isDisplay: false,
      };
    }
    return {
      ...columnSetting,
      isDisplay: true,
    };
  });

  const sortJson =
    sortColumnParam?.length === 0
      ? null
      : {
          ViewSetting: {
            SortSetting: sortColumnParam.map(
              (item: {field: string; sort: string}, index: number) => {
                return {
                  SortIndex: index + 1,
                  SortCol: item.field,
                  SortOrder: item.sort,
                };
              },
            ),
          },
        };
  return {columnSettingJson, sortSettingJson: sortJson};
};

export function convertAndAppendExistingFilters(
  customFilterJsonValue: any,
  queryBuilderQuery: any,
  filterModel: any,
) {
  const cleanedObject =
    filterModel.items.length > 0
      ? customFilterJsonValue.rules.slice(0, -2)
      : customFilterJsonValue.rules;
  let valueToConvert;
  if (cleanedObject) {
    let filterObject = {rules: [...cleanedObject]};
    if (
      (Array.isArray(queryBuilderQuery) && queryBuilderQuery[0] !== 'and') ||
      (!Array.isArray(queryBuilderQuery) &&
        typeof cleanedObject[cleanedObject.length - 1] === 'object')
    ) {
      filterObject.rules.push('and');
    }
    filterObject.rules.push(queryBuilderQuery);
    valueToConvert = filterObject;
  } else {
    valueToConvert = queryBuilderQuery;
  }
  return valueToConvert;
}

interface IGetUpdatedSettingsAndColumnsParams {
  viewSettings: IViewSetting;
  udfDetails: any;
  columns: any;
  udfLabel: UDFLabelRefType;
  alternateUDFKey?: string;
}

export const getUDFUpdatedSettingsAndColumns = ({
  viewSettings,
  udfDetails,
  columns,
  udfLabel,
  alternateUDFKey,
}: IGetUpdatedSettingsAndColumnsParams) => {
  let updatedViewSettings = {...viewSettings};
  let columnsToDisplay = columns;

  if (udfDetails) {
    updatedViewSettings = mapUDFDisplayText({
      viewSettingsData: viewSettings,
      mapping: udfDetails,
      udfLabel,
      alternateUDFKey,
    });
    columnsToDisplay = removeUDFColumnsWithNoDisplayText(
      udfDetails,
      columnsToDisplay,
      udfLabel,
    );
  }

  return {updatedViewSettings, columnsToDisplay};
};

export const getCheckBoxSelection = (columnSetting: ColumnSetting[]) => {
   return columnSetting?.find(
        (x: ColumnSetting) => x.dataField === ColumnNames.Selection.toLowerCase(),
      )?.isDisplay;
}