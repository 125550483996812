import dayjs from 'dayjs';
import {constants} from '../../../constants/common';
import {filterOperators} from '../../../lib/commonTableHelpers';
import {ActivityMenu, SingleLink} from '../communication/RowComponents';
import {CustomerMenu} from '../../common/CommonRowComponents';

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

export const followUpColumns = [
  {
    field: 'userId',
    headerName: 'User ID',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'communicate',
    headerName: 'Activity',
    sortable: false,
    mandatory: true,
    renderCell: cellValues => {
      return <ActivityMenu values={cellValues} />;
    },
    renderHeaderFilter: () => null,
    minWidth: 80,
  },
  {
    field: 'custId',
    headerName: 'Cust ID',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'custName',
    headerName: 'Cust Name',
    sortable: true,
    minWidth: 120,
    type: 'string',
    renderCell: cellValues => {
      return <CustomerMenu values={cellValues} />;
    },
    filterOperators: filterOperators.string,
  },
  {
    field: 'issueDesc',
    headerName: 'Issue Description',
    sortable: true,
    minWidth: 120,
    type: 'string',
    renderCell: cellValues => {
      return <SingleLink values={cellValues} />;
    },
    filterOperators: filterOperators.string,
  },
  {
    field: 'toDoDesc',
    headerName: 'FollowUpDesc',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'toDoDate',
    headerName: 'FollowUp Date',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_TIME_FORMAT)
        : '',
    minWidth: 150,
    type: 'date',
    filterOperators: filterOperators.date,
    renderCell: params => {
      const toDoDate = dayjs(params.value);
      const now = dayjs();
      const style = toDoDate.isBefore(now)
        ? {color: 'white', backgroundColor: 'red', padding: '0 4px'}
        : {};
      return (
        <span style={style}>
          {params?.value
            ? toDoDate.utc().format(constants.DATE_TIME_FORMAT)
            : ''}
        </span>
      );
    },
  },
  {
    field: 'toDoDuration',
    headerName: 'Duration',
    sortable: true,
    minWidth: 120,
    type: 'number',
    renderHeaderFilter: () => null,
    filterOperators: filterOperators.number,
  },
  {
    field: 'dateEntered',
    headerName: 'Activity Date',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_TIME_FORMAT)
        : '',
    minWidth: 150,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'activityNote',
    headerName: 'ActivityNote',
    sortable: true,
    minWidth: 120,
    renderCell: cellValues => {
      const {row} = cellValues;
      return (
        <span
          title={row.activityNote}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{__html: row?.activityNote}}
        />
      );
    },
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'companyID',
    headerName: 'Company ID',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'parentID',
    headerName: 'Parent ID',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'daysToRespond',
    headerName: 'Days To Respond',
    sortable: true,
    valueFormatter: params =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 2,
      }),
    minWidth: 120,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'followUpRespondBy',
    headerName: 'Follow Up RespondBy',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_TIME_FORMAT)
        : '',
    minWidth: 150,
    type: 'date',
    filterOperators: filterOperators.date,
  },

  {
    field: 'respondDueDays',
    headerName: 'Respond Due Days',
    sortable: true,
    valueFormatter: params =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 2,
      }),
    minWidth: 120,
    type: 'number',
    filterOperators: filterOperators.number,
  },

  {
    field: 'dateEnteredDays',
    headerName: 'Date Entered Days',
    sortable: true,
    valueFormatter: params =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 2,
      }),
    minWidth: 120,
    type: 'number',
    filterOperators: filterOperators.number,
  },

  {
    field: 'followUpDateDays',
    headerName: 'Follow Up DateDays',
    sortable: true,
    valueFormatter: params =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 2,
      }),
    minWidth: 120,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'timeZoneID',
    headerName: 'Time Zone ID',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
];
