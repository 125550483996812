import {useDispatch, useSelector} from 'react-redux';
import React, {useEffect, useState} from 'react';
import {
  DataGridProProps,
  GridColDef,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridLogicOperator,
  GridPaginationModel,
  GridSortModel,
} from '@mui/x-data-grid-pro';

import {Outlet} from 'react-router-dom';
import {getUserData} from '../../../../store/actions/userActions';
import {
  DataGrid,
  FilterPopoverProvider,
  generateJsonFromSql,
  QueryReturnValue,
} from '../../../../ui/data';
import {
  convertAndAppendExistingFilters,
  getColumns,
  getGridColumnsSettings,
  hasFilterValue,
  updateColumnSettings,
} from '../../../../lib/commonTableHelpers';
import {calculateColumnWidth} from '../../../header/helper';
import {ViewSettings} from './ViewSettings';
import {useQueryKeys} from '../../../../hooks/useQueryKeys';
import {scheduledPaymentsColumns} from './scheduledPaymentsColumns';
import ScheduledPaymentsToolbar from './ScheduledPaymentsToolbar';
import {
  cleanUpScheduledPayments,
  getSchedulePaymentInvoicesDetails,
  getScheduledPayments,
  pendingPaymentsActions,
  scheduledPaymentsExportToExcel,
  updateScheduledPaymentsViewSettingObject,
} from '../../../../store/actions/scheduledPaymentsActions';
import {constants, gridModeTypes} from '../../../../constants/common';
import {DeclinePayment} from './DeclinePayment';
import {Dialog} from '../../../../ui';
import {ScheduleTasks} from './ScheduleTasks';
import {DisplayInvoicePanel} from './DisplayInvoicePanel/DisplayInvoicePanel';
import {PAGE_SIZE} from '../../../../utils/constants';
import {
  convertMUIToQueryBuilder,
  generateJsonAndSql,
  initialQuery,
} from '../../../../ui/data/query-builder/queryHelper';
import {useComponentMountStatus} from '../../../../hooks/useComponentMountStatus';
import {
  ColumnSetting,
  IViewSettings,
} from '../../../common/view-settings/interface';
import ViewSettingsModal from '../../../common/view-settings/ViewSettingsModal';
import {shouldResetFilterModel} from '../../../../utils/filterUtils';

export const ScheduledPayments = () => {
  const dispatch = useDispatch<any>();
  const [columns, setColumns] = useState<any[]>([]);
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({});
  const [selectedPayments, setSelectedPayments] = useState<any[]>([]);
  const [sortColumn, setSortColumn] = useState<GridSortModel>([]);
  const [isIncludePastDuePayments, setIsIncludePastDuePayments] =
    useState<boolean>(false);

  const {groupKey, userId} = getUserData();
  const {userKey} = useQueryKeys();
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
    logicOperator: GridLogicOperator.And,
  });
  const [bestFit, setBestFit] = useState<boolean>(false);
  const [showDeclinePayments, setShowDeclinePayments] =
    useState<boolean>(false);
  const [showDuePaymentsNotification, setShowDuePaymentsNotification] =
    useState<boolean>(false);
  const [showAutoPaymentSchedule, setShowAutoPaymentSchedule] =
    useState<boolean>(false);
  const [bestFitColumns, setBestFitColumns] = useState<GridColDef[]>([]);
  const [skip, setSkip] = useState(0);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: PAGE_SIZE,
  });
  const [currentExpandedIds, setCurrentExpandedIds] = useState<string[]>([]);

  const [settingsKey, setSettingsKey] = useState<string>('');
  const [showViewSettingsPopup, setShowViewSettingsPopup] =
    useState<boolean>(false);

  const [customFilterSqlValue, setCustomFilterSqlValue] = useState<
    string | null
  >(null);
  const [customFilterJsonValue, setCustomFilterJsonValue] =
    useState<QueryReturnValue['json']>();

  const isComponentMounted = useComponentMountStatus(cleanUpScheduledPayments);

  const {
    scheduledPaymentsSettingId,
    scheduledPaymentsData,
    scheduledPaymentsLoading,
    scheduledPaymentsViewSettings,
    scheduledPaymentsCount,
  } = useSelector((store: any) => store.scheduledPaymentsReducer);

  const loadScheduledPayments = ({
    sortQuery = [],
    settingKey,
    filterQuery = undefined,
    sqlQuery = null,
    pageSizeParam = PAGE_SIZE,
    skipParam = 0,
  }: {
    sortQuery: any[];
    settingKey: string;
    filterQuery: QueryReturnValue['json'] | undefined;
    sqlQuery: string | null;
    pageSizeParam: number;
    skipParam: number;
  }) => {
    setSelectedPayments([]);
    const options = {
      userKey,
      groupKey,
      settingKey,
      sortQuery,
      filterQuery,
      sqlQuery,
      skip: skipParam,
      pageSize: pageSizeParam,
      includePastDue: isIncludePastDuePayments ? 1 : 0,
    };
    dispatch(getScheduledPayments(options));
  };

  // Reset filter model
  const resetFilterModel = () => {
    setFilterModel({
      logicOperator: GridLogicOperator.And,
      items: [],
    });
  };

  // reset pagination
  const resetPagination = () => {
    setPaginationModel({
      pageSize: paginationModel.pageSize,
      page: 0,
    });
  };

  useEffect(() => {
    if (settingsKey) {
      loadScheduledPayments({
        settingKey: settingsKey,
        sortQuery: sortColumn,
        filterQuery: customFilterJsonValue,
        sqlQuery: customFilterSqlValue,
        skipParam: 0,
        pageSizeParam: paginationModel.pageSize,
      });
      resetFilterModel();
      resetPagination();
      setSelectedPayments([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsKey, isIncludePastDuePayments]);

  /** Use effect to update and set columns based on view settings response */
  useEffect(() => {
    if (
      scheduledPaymentsViewSettings &&
      scheduledPaymentsViewSettings.settingsKey &&
      isComponentMounted
    ) {
      const {sortedColumns, initialHiddenColumns, formattedSortData} =
        getColumns(
          scheduledPaymentsViewSettings.columnSetting || [],
          scheduledPaymentsColumns,
          scheduledPaymentsViewSettings.sortSetting,
        );
      setColumns(sortedColumns);
      setColumnVisibilityModel(initialHiddenColumns);
      setSortColumn(formattedSortData);

      const jsonData =
        scheduledPaymentsViewSettings?.filter?.length > 0
          ? generateJsonFromSql(scheduledPaymentsViewSettings?.filter)
          : initialQuery;
      setCustomFilterSqlValue(scheduledPaymentsViewSettings?.filter || '');
      setCustomFilterJsonValue(jsonData);
      setSettingsKey(scheduledPaymentsViewSettings.settingsKey);
    }
  }, [scheduledPaymentsViewSettings?.settingsKey]);

  /**
   * onPageChange is prop set for scheduled payments grid to handle pagination
   * @param args GridPaginationModel object which has page which starts from 0 and skip properties
   */
  const onPageChange = (args: GridPaginationModel) => {
    setCurrentExpandedIds([]);
    const pageSkip =
      args.pageSize !== paginationModel.pageSize
        ? 0
        : (args.page + 1) * args.pageSize - args.pageSize;
    setPaginationModel({
      pageSize: args.pageSize,
      page: args.pageSize !== paginationModel.pageSize ? 0 : args.page,
    });
    setSkip(pageSkip);
    loadScheduledPayments({
      settingKey: scheduledPaymentsSettingId,
      sortQuery: sortColumn,
      filterQuery: customFilterJsonValue,
      sqlQuery: customFilterSqlValue,
      pageSizeParam: args.pageSize,
      skipParam: pageSkip,
    });
  };

  /**
   * onSortChange is prop set for scheduled payments grid to sorting
   * @param args GridPaginationModel array of object which field and sort properties
   */
  const onSortChange = (args: GridSortModel) => {
    setSortColumn(args);
    loadScheduledPayments({
      settingKey: scheduledPaymentsSettingId,
      sortQuery: args,
      filterQuery: customFilterJsonValue,
      sqlQuery: customFilterSqlValue,
      pageSizeParam: paginationModel.pageSize,
      skipParam: skip,
    });
  };

  const handleGetScheduledPayments = (
    filterQuery: QueryReturnValue['json'],
    sqlQuery: string | null,
  ) => {
    loadScheduledPayments({
      settingKey: scheduledPaymentsSettingId,
      sortQuery: sortColumn,
      filterQuery,
      sqlQuery,
      pageSizeParam: paginationModel.pageSize,
      skipParam: skip,
    });

    resetPagination();
  };

  /**
   * onColumnRowSelect Update state on checkbox selection
   * @param newSelectionModel is selected row object
   */
  const onColumnRowSelect = (newSelectionModel: any) => {
    setSelectedPayments(newSelectionModel);
  };

  /** All Toolbar methods */
  /**
   * onFilterChange is prop set for scheduled payments grid to filter
   * @param args GridFilterModel array of object which field, value and operator properties
   */
  const onFilterChange = (args: GridFilterModel) => {
    const queryBuilderQuery = convertMUIToQueryBuilder(args);
    const valueToConvert = convertAndAppendExistingFilters(
      {...customFilterJsonValue},
      queryBuilderQuery,
      filterModel,
    );
    const {json, sql} = generateJsonAndSql(valueToConvert);
    setFilterModel(args);
    setCustomFilterSqlValue(json.rules.length > 0 ? sql : null);
    setCustomFilterJsonValue(json);
    if (hasFilterValue(args)) {
      handleGetScheduledPayments(json, json.rules.length > 0 ? sql : null);
    }
  };

  const onQueryFilterApply = (data: QueryReturnValue) => {
    // if filter is empty then reset filter model
    if (shouldResetFilterModel(data)) {
      resetFilterModel();
    }
    setCustomFilterSqlValue(data.sql);
    setCustomFilterJsonValue(data.json);
    handleGetScheduledPayments(
      data.json,
      data.json.rules.length > 0 ? data.sql : null,
    );
  };

  /**
   * Download Excel report
   */
  const exportToExcel = () => {
    const options = {
      userKey,
      groupKey,
      settingKey: scheduledPaymentsSettingId,
      sortQuery: sortColumn,
      filterQuery: customFilterJsonValue,
      sqlQuery: customFilterSqlValue,
      skip,
      pageSize: paginationModel.pageSize,
      includePastDue: isIncludePastDuePayments ? 1 : 0,
    };
    dispatch(
      // @ts-ignore
      scheduledPaymentsExportToExcel(options),
    );
  };

  /**
   * On Refresh refetch list of scheduled payments
   */
  const onRefresh = () => {
    setCurrentExpandedIds([]);
    loadScheduledPayments({
      settingKey: scheduledPaymentsSettingId,
      sortQuery: sortColumn,
      filterQuery: customFilterJsonValue,
      sqlQuery: customFilterSqlValue,
      skipParam: skip,
      pageSizeParam: paginationModel.pageSize,
    });
  };

  // Handling best-fit (Column resizing)
  const applyBestFit = () => {
    const bestFitColumnsTemp = columns.map(column => {
      const minWidth = calculateColumnWidth(column.field);
      return {
        ...column,
        minWidth,
      };
    });
    setBestFit(!bestFit);
    setBestFitColumns(bestFitColumnsTemp);
  };

  const handleActionClick = (actionType: string, note: string = '') => {
    const paymentKeys = selectedPayments?.map((value: any) => {
      return {tblKey: value};
    });

    const options = {
      pendPmtKeys: paymentKeys,
      groupKey,
      userKey,
      userId,
      note,
      actionType,
    };

    dispatch(
      pendingPaymentsActions(
        options,
        onRefresh,
        setShowDuePaymentsNotification,
      ),
    );
  };
  const onAutoPaymentScheduleClick = () => {
    setShowAutoPaymentSchedule(true);
  };
  const onHideDialog = () => {
    setShowDeclinePayments(false);
    onRefresh();
  };

  const handleRejectPayment = (actionType: string, note: string = '') => {
    handleActionClick(actionType, note);
    onHideDialog();
  };

  const getDetailPanelContent = React.useCallback<
    NonNullable<DataGridProProps['getDetailPanelContent']>
  >(({row}) => {
    return (
      <DisplayInvoicePanel
        scheduledPaymentsInvoicesData={row.summary ? row.summary : undefined}
      />
    );
  }, []);

  const handleDetailPanelExpandedRowIdsChange = React.useCallback(
    (newIds: any[]) => {
      if (
        newIds.length !== currentExpandedIds.length &&
        newIds.length > currentExpandedIds.length
      ) {
        const current = newIds[newIds.length - 1];
        dispatch(getSchedulePaymentInvoicesDetails(groupKey, current));
      }
      setCurrentExpandedIds(newIds);
    },
    [currentExpandedIds],
  );

  const updateTableOnColumnSettingsChange = (
    updatedColumnsList: ColumnSetting[],
    settingKey: string,
  ) => {
    if (settingKey === scheduledPaymentsViewSettings?.settingsKey) {
      updateColumnSettings(
        updatedColumnsList,
        columns,
        setColumns,
        setColumnVisibilityModel,
      );
    }
  };

  const onViewSettings = () => {
    setShowViewSettingsPopup(true);
  };

  const handleCloseViewSettingsPopup = () => {
    setShowViewSettingsPopup(false);
  };

  const {columnSettingJson, sortSettingJson} = getGridColumnsSettings(
    scheduledPaymentsViewSettings?.columnSetting,
    columnVisibilityModel,
    sortColumn,
  );

  return (
    <>
      <Outlet context={{onReload: onRefresh}} />
      <ViewSettingsModal
        viewName="ScheduledPayments"
        mode={gridModeTypes.ScheduledPayments}
        updateTableOnColumnSettingsChange={updateTableOnColumnSettingsChange}
        handleCloseViewSettingsPopup={handleCloseViewSettingsPopup}
        customFilterSqlValue={customFilterSqlValue || ''}
        sortSettingJson={JSON.stringify(sortSettingJson) || ''}
        columnSettingJson={JSON.stringify(columnSettingJson) || ''}
        loadViewSetting={(row: IViewSettings) => {
          dispatch(
            updateScheduledPaymentsViewSettingObject({
              settingId: row.settingsKey,
              viewSettings: row,
            }),
          );
        }}
        showViewSettingsPopup={showViewSettingsPopup}
      />
      <ViewSettings
        isIncludePastDuePayments={isIncludePastDuePayments}
        setIsIncludePastDuePayments={setIsIncludePastDuePayments}
        setShowDeclinePayments={setShowDeclinePayments}
        handleActionClick={handleActionClick}
        selectedPayments={selectedPayments}
        onAutoPaymentScheduleClick={onAutoPaymentScheduleClick}
      />
      <FilterPopoverProvider>
        <DataGrid
          disableVirtualization
          columns={bestFit ? bestFitColumns : columns}
          rows={scheduledPaymentsData}
          loading={scheduledPaymentsLoading}
          rowCount={scheduledPaymentsCount}
          checkboxSelection
          sortModel={sortColumn}
          onSortChange={onSortChange}
          onPageChange={onPageChange}
          onFilterChange={onFilterChange}
          filterModel={filterModel}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityChange={data => setColumnVisibilityModel(data)}
          rowsSelected={selectedPayments}
          onColumnRowSelect={onColumnRowSelect}
          CustomToolbar={ScheduledPaymentsToolbar}
          disableMultipleColumnsSorting
          customToolbarMethods={{
            exportToExcel,
            onRefresh,
            applyBestFit,
            onViewSettings,
          }}
          showCustomFilters
          customFilterSqlValue={customFilterSqlValue}
          onQueryFilterApply={data => onQueryFilterApply(data)}
          getRowId={row => row.pendPmtKey}
          headerFilters
          paginationModel={paginationModel}
          getDetailPanelContent={getDetailPanelContent}
          getDetailPanelHeight={() => 'auto'}
          detailPanelExpandedRowIds={currentExpandedIds}
          onDetailPanelExpandedRowIdsChange={
            handleDetailPanelExpandedRowIdsChange
          }
        />
      </FilterPopoverProvider>

      <Dialog
        title={constants.DECLINE_PAYMENT}
        open={showDeclinePayments}
        onClose={onHideDialog}
        fullWidth
      >
        <DeclinePayment
          selectedPayments={selectedPayments}
          handleRejectPayment={handleRejectPayment}
          onHideDialog={onHideDialog}
        />
      </Dialog>

      <Dialog
        title={constants.NOTIFICATION}
        open={showDuePaymentsNotification}
        onClose={() => setShowDuePaymentsNotification(false)}
        fullWidth
      >
        <p className="d-flex text-align-center">
          <i className="pi pi-custom pi-warning mx-3" />
          {constants.DUE_PAYMENTS_NOTIFICATION}
        </p>
      </Dialog>
      <Dialog
        title={constants.SCHEDULE_TASKS}
        open={showAutoPaymentSchedule}
        onClose={() => setShowAutoPaymentSchedule(false)}
        fullWidth
      >
        <ScheduleTasks
          onCloseDialog={() => setShowAutoPaymentSchedule(false)}
        />
      </Dialog>
    </>
  );
};
