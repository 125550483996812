import {Stack as MuiStack, StackProps} from '@mui/material';

interface Props extends StackProps {}

/**
 * information available here https://mui.com/material-ui/react-stack/
 */

const Stack: React.FC<Props> = ({
  direction = 'row',
  spacing = 2,
  ...otherProps
}) => {
  return (
    <MuiStack direction={direction} spacing={spacing} {...otherProps}>
      {otherProps.children}
    </MuiStack>
  );
};

export default Stack;
