import {CreditScoreScaleList} from './CustomerInfoInterface';

export const getScoreColorAndDescription = (
  scoreScales: CreditScoreScaleList,
  score: number,
): {scaleColor: string; description: string} => {
  const sortedScales = [...scoreScales].sort(
    (a, b) => a.scaleStart - b.scaleStart,
  );
  for (let i = 0; i < sortedScales.length; i += 1) {
    if (
      score >= sortedScales[i].scaleStart &&
      score <= sortedScales[i].scaleEnd
    ) {
      return {
        scaleColor: sortedScales[i].scaleColor,
        description: sortedScales[i].scaleDescription,
      };
    }
  }

  return {
    scaleColor: '#00B050',
    description: 'Very good',
  };
};
