import {actionTypes} from '../constants/actionTypes';

const initialState = {
  projectedCashReceiptsData: [],
  projectedCashReceiptsViewSettings: null,
  fetchingProjectedCashReceipts: false,
  fetchingProjectedCashReceiptsFailed: false,
  fetchedProjectedCashReceipts: false,
  projectedCashReceiptsLoading: false,
  projectedCashReceiptsCount: 0,
  projectedCashReceiptsNextLink: '',
  projectedCashReceiptsSelectedRows: [],
  exportingToExcel: false,
  exportedToExcel: false,
  exportingToExcelFailed: false,
  projectedCashReceiptsSettingId: null,
  totalBalanceHC: null,
  totalPredicted: null,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const projectedCashReceiptsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_PROJECTED_CASH_RECEIPTS:
      return {
        ...state,
        fetchingProjectedCashReceipts: true,
        fetchingProjectedCashReceiptsFailed: false,
        projectedCashReceiptsLoading: true,
      };
    case actionTypes.FETCHED_PROJECTED_CASH_RECEIPTS:
      return {
        ...state,
        fetchingProjectedCashReceipts: false,
        fetchingProjectedCashReceiptsFailed: false,
        fetchedProjectedCashReceipts: true,
        projectedCashReceiptsData: action.projectedCashReceiptsData,
        projectedCashReceiptsLoading: false,
        projectedCashReceiptsCount: action.projectedCashReceiptsCount,
        projectedCashReceiptsNextLink: action.projectedCashReceiptsNextLink,
        totalBalanceHC: action.totalBalanceHC,
        totalPredicted: action.totalPredicted,
      };
    case actionTypes.FETCHING_PROJECTED_CASH_RECEIPTS_FAILED:
      return {
        ...state,
        fetchingProjectedCashReceipts: false,
        fetchingProjectedCashReceiptsFailed: true,
        fetchedProjectedCashReceipts: true,
        projectedCashReceiptsLoading: false,
        projectedCashReceiptsData: [],
        projectedCashReceiptsCount: 0,
        totalBalanceHC: 0,
        totalPredicted: 0,
      };
    case actionTypes.EXPORTING_PROJECTED_CASH_RECEIPTS_TO_EXCEL:
      return {
        ...state,
        projectedCashReceiptsLoading: true,
        exportingToExcel: true,
        exportedToExcel: false,
        exportingToExcelFailed: false,
      };
    case actionTypes.EXPORTED_PROJECTED_CASH_RECEIPTS_TO_EXCEL:
      return {
        ...state,
        projectedCashReceiptsLoading: false,
        exportingToExcel: false,
        exportedToExcel: true,
        exportingToExcelFailed: false,
      };
    case actionTypes.EXPORTING_PROJECTED_CASH_RECEIPTS_TO_EXCEL_FAILED:
      return {
        ...state,
        projectedCashReceiptsLoading: false,
        exportingToExcel: false,
        exportedToExcel: false,
        exportingToExcelFailed: true,
      };
    case actionTypes.SET_PROJECTED_CASH_RECEIPTS_VIEW_SETTING_OBJECT:
      return {
        ...state,
        projectedCashReceiptsViewSettings: {
          ...action.payload.viewSettings,
          settingsKey: action.payload.settingId,
          sortSetting: null,
        },
        projectedCashReceiptsSettingId: action.payload.settingId,
      };
    case actionTypes.CLEAN_UP_PROJECTED_CASH_RECEIPTS:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default projectedCashReceiptsReducer;
