import {GridActionsCellItem, GridRowModes} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import Copy from '@mui/icons-material/FileCopyOutlined';
import Load from '@mui/icons-material/Refresh';
import Columns from '@mui/icons-material/ViewColumn';
import {Checkbox} from '../../../ui/inputs';
import {SortOrderColumn} from './RowComponent';

export const ViewSettingsColumns = ({
  onCancel,
  onRowEditSave,
  handleEditClick,
  rowModesModel,
  columnAlignments,
  handleCopyClick,
  handleLoadClick,
  handleColumnsClick,
  handleCopyAndSaveViewSettings,
  handleDeleteClick,
  onGridColumnValueChanged,
}) => {
  const sortable = false;
  return [
    {
      field: 'edit',
      type: 'actions',
      headerName: 'Edit',
      width: 100,
      cellClassName: 'actions',
      getActions: ({row}) => {
        const isInEditMode =
          rowModesModel &&
          rowModesModel[row.settingsKey]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon color="primary" />}
              label="Save"
              title="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={() => onRowEditSave(row)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon color="action" />}
              label="Cancel"
              title="Cancel"
              className="textPrimary"
              onClick={() => onCancel(row.settingsKey)}
              color="inherit"
            />,
          ];
        }
        return row.isSystem || row.isReactView === 0
          ? []
          : [
              <GridActionsCellItem
                icon={<EditIcon color="action" />}
                label="Edit"
                title="Edit"
                className="textPrimary"
                onClick={() => handleEditClick(row.settingsKey)}
                color="inherit"
              />,
            ];
      },
    },
    {
      field: 'copy',
      headerName: 'Copy',
      align: columnAlignments.center,
      headerAlign: columnAlignments.center,
      width: 100,
      getActions: ({row}) => {
        return row.isSystem
          ? []
          : [
              <GridActionsCellItem
                icon={<Copy color="action" style={{fontSize: 20}} />}
                label="Copy"
                title="Copy"
                className="textPrimary"
                onClick={() => handleCopyClick(row.settingsKey)}
                color="inherit"
              />,
            ];
      },
      type: 'actions',
    },
    {
      field: 'load',
      headerName: 'Load',
      align: columnAlignments.center,
      headerAlign: columnAlignments.center,
      width: 100,
      getActions: ({row}) => {
        const isInEditMode =
          rowModesModel &&
          rowModesModel[row.settingsKey]?.mode === GridRowModes.Edit;
        return isInEditMode
          ? []
          : [
              <GridActionsCellItem
                icon={<Load color="action" style={{fontSize: 20}} />}
                label="Load"
                title="Load"
                className="textPrimary"
                onClick={() => handleLoadClick(row)}
                color="inherit"
              />,
            ];
      },
      type: 'actions',
    },
    {
      field: 'columns',
      headerName: 'Columns',
      align: columnAlignments.center,
      headerAlign: columnAlignments.center,
      width: 100,
      getActions: ({row}) => {
        const isInEditMode =
          rowModesModel &&
          rowModesModel[row.settingsKey]?.mode === GridRowModes.Edit;
        return isInEditMode || row.isSystem || row.isReactView === 0
          ? []
          : [
              <GridActionsCellItem
                icon={<Columns color="action" style={{fontSize: 20}} />}
                title="Columns"
                label="Columns"
                className="textPrimary"
                onClick={() => handleColumnsClick(row)}
                color="inherit"
                settingID
              />,
            ];
      },

      type: 'actions',
    },
    {
      field: 'save',
      headerName: 'Save',
      align: columnAlignments.center,
      headerAlign: columnAlignments.center,
      width: 100,
      getActions: ({row}) => {
        const isInEditMode =
          rowModesModel &&
          rowModesModel[row.settingsKey]?.mode === GridRowModes.Edit;
        return isInEditMode || row.isSystem || row.isReactView === 0
          ? []
          : [
              <GridActionsCellItem
                icon={<SaveIcon color="primary" style={{fontSize: 20}} />}
                title="Save"
                label="Save"
                className="textPrimary"
                onClick={() =>
                  handleCopyAndSaveViewSettings(row.settingsKey, row.settingId)
                }
                color="inherit"
              />,
            ];
      },
      type: 'actions',
    },
    {
      field: 'delete',
      headerName: 'Delete',
      align: columnAlignments.center,
      headerAlign: columnAlignments.center,
      width: 100,
      getActions: ({row}) => {
        const isInEditMode =
          rowModesModel &&
          rowModesModel[row.settingsKey]?.mode === GridRowModes.Edit;
        return isInEditMode || row.isSystem || row.isReactView === 0
          ? []
          : [
              <GridActionsCellItem
                icon={<DeleteIcon color="warning" style={{fontSize: 20}} />}
                label="Delete"
                title="Delete"
                className="textPrimary"
                onClick={() =>
                  handleDeleteClick(row.settingsKey, row.settingId)
                }
                color="inherit"
              />,
            ];
      },
      type: 'actions',
    },
    {
      field: 'settingId',
      headerName: 'Setting Name',
      align: columnAlignments.left,
      headerAlign: columnAlignments.left,
      sortable,
      width: 180,
    },
    {
      field: 'sortOrder',
      headerName: 'Sort Order',
      align: columnAlignments.center,
      headerAlign: columnAlignments.center,
      width: 100,
      type: 'number',
      renderCell: ({row}) => {
        const isInEditMode =
          rowModesModel &&
          rowModesModel[row.settingsKey]?.mode === GridRowModes.Edit;
        return isInEditMode ? (
          <SortOrderColumn
            row={row}
            onChange={value => {
              onGridColumnValueChanged('sortOrder', value, row.settingsKey);
            }}
          />
        ) : (
          row.sortOrder
        );
      },
      sortable,
    },
    {
      field: 'isDefault',
      headerName: 'Is Default',
      align: columnAlignments.center,
      headerAlign: columnAlignments.center,
      width: 80,
      renderCell: ({row}) => {
        const isInEditMode =
          rowModesModel &&
          rowModesModel[row.settingsKey]?.mode === GridRowModes.Edit;
        return (
          <Checkbox
            disabled={!isInEditMode}
            checked={row.isDefault}
            onChange={e =>
              onGridColumnValueChanged(
                'isDefault',
                e.target.checked,
                row.settingsKey,
              )
            }
          />
        );
      },
      sortable,
      type: 'boolean',
    },
    {
      field: 'show',
      headerName: 'Show',
      align: columnAlignments.center,
      headerAlign: columnAlignments.center,
      width: 100,
      renderCell: ({row}) => {
        const isInEditMode =
          rowModesModel &&
          rowModesModel[row.settingsKey]?.mode === GridRowModes.Edit;
        return (
          <Checkbox
            disabled={!isInEditMode}
            checked={row.show}
            onChange={e =>
              onGridColumnValueChanged(
                'show',
                e.target.checked,
                row.settingsKey,
              )
            }
          />
        );
      },

      sortable,
      type: 'boolean',
    },
    {
      field: 'isSystem',
      headerName: 'Is System',
      width: 80,
      hide: true,
      renderCell: ({row}) => {
        return <Checkbox disabled checked={row.isSystem} />;
      },
      sortable,
    },
  ];
};
