import {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {GridPaginationModel} from '@mui/x-data-grid';
import {constants} from '../../../constants/common';
import {getApplicationDetailForInvoice} from '../../../store/actions/applicationDetailActions';
import {applicationDetailColumns} from '../../common/table/columns/applicationDetailColumns';
// Todo: Resolve dependency cycle es-lint errors
// eslint-disable-next-line import/no-cycle
import InvoiceDetailTemplate from '../InvoiceDetailTemplate';
import {DataGrid} from '../../../ui/data';
import {applicationDetailsAggregateData} from './helper';
import {Dialog} from '../../../ui';
import {isEmpty} from '../../../lib/utils';
import {PAGE_SIZE} from '../../../utils/constants';

function ApplicationDetailTemplate({
  custPmtKey,
  tranType,
  invcKey,
  onPDFDownloadIconClick,
  handleSetShowCustomerDocs,
  checkIsTranTypePayment,
}: {
  custPmtKey: string;
  tranType: string;
  invcKey: string;
  onPDFDownloadIconClick: (columnInvoiceKey?: string) => void;
  handleSetShowCustomerDocs: (
    invcKey?: string,
    tranId?: string,
    tranType?: string,
  ) => void;
  checkIsTranTypePayment: (tranType: string) => boolean;
}) {
  const {applicationDetail, applicationDetailCount, applicationDetailLoading} =
    useSelector((store: any) => store.applicationDetailReducer);
  const [showInvoiceDetailsDialog, setShowInvoiceDetailsDialog] =
    useState(false);
  const [selectedInvoiceKey, setSelectedInvoiceKey] = useState<string>('');
  const [selectedRow, setSelectedRow] = useState<any>({});
  const dispatch = useDispatch<any>();
  const [skip, setSkip] = useState<number>(0);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: PAGE_SIZE,
  });
  const [tableData, setTableData] = useState<any>([]);

  const getDetails = ({
    pageSizeParam,
    skipParam,
  }: {
    pageSizeParam: number;
    skipParam: number;
  }) => {
    const checkPayment = checkIsTranTypePayment(tranType);
    if (checkPayment) {
      dispatch(
        getApplicationDetailForInvoice({
          key: custPmtKey,
          type: constants.PAYMENT.toLocaleLowerCase(),
          pageSize: pageSizeParam,
          skip: skipParam,
        }),
      );
    } else {
      dispatch(
        getApplicationDetailForInvoice({
          key: invcKey,
          type: constants.INVOICE.toLocaleLowerCase(),
          pageSize: pageSizeParam,
          skip: skipParam,
        }),
      );
    }
  };
  useEffect(() => {
    getDetails({pageSizeParam: paginationModel.pageSize, skipParam: skip});
  }, []);

  const onRefresh = () => {
    getDetails({pageSizeParam: paginationModel.pageSize, skipParam: skip});
  };

  const handleOnInvoiceClick = (
    selectedInvoiceKeyParam?: string,
    selectedRowParam?: any,
  ) => {
    if (selectedInvoiceKeyParam) {
      setSelectedInvoiceKey(selectedInvoiceKeyParam);
    }
    setSelectedRow(selectedRowParam);
    setShowInvoiceDetailsDialog(true);
  };

  useEffect(() => {
    const columns = applicationDetailColumns({
      handleOnInvoiceClick,
      onPDFDownloadIconClick,
      handleSetShowCustomerDocs,
    });
    const aggregatedRow = applicationDetailsAggregateData(
      applicationDetail,
      columns,
    );
    const dataWithAggregates = [...applicationDetail, aggregatedRow];
    setTableData(dataWithAggregates);
  }, [applicationDetail]);

  const onPageChange = (args: GridPaginationModel) => {
    const pageSkip =
      args.pageSize !== paginationModel.pageSize
        ? 0
        : (args.page + 1) * args.pageSize - args.pageSize;
    setPaginationModel({
      pageSize: args.pageSize,
      page: args.pageSize !== paginationModel.pageSize ? 0 : args.page,
    });
    setSkip(pageSkip);
    getDetails({
      pageSizeParam: args.pageSize,
      skipParam: pageSkip,
    });
  };

  const tranTypePayment: boolean = checkIsTranTypePayment(tranType);
  return (
    <>
      <Dialog
        title={constants.INVOICE_DETAILS}
        open={
          !!(
            !isEmpty(selectedRow) &&
            selectedInvoiceKey &&
            showInvoiceDetailsDialog
          )
        }
        onClose={() => {
          setShowInvoiceDetailsDialog(false);
          setSelectedInvoiceKey('');
          setSelectedRow({});
        }}
        maxWidth="lg"
        fullWidth
      >
        <InvoiceDetailTemplate
          invcKey={selectedInvoiceKey}
          selectedRow={selectedRow}
          onReload={onRefresh}
          setShowInvoiceDetailsDialog={setShowInvoiceDetailsDialog}
          isTaggedInvoice={false}
        />
      </Dialog>
      <div className="d-flex justify-content-between align-items-center">
        <span
          aria-hidden="true"
          className="px-1 py-2 d-flex align-items-center cursor-pointer"
          onClick={onRefresh}
        >
          <i className="pi pi-custom-refresh" />
          <span className="ps-3">{constants.REFRESH_LIST}</span>
        </span>
      </div>

      <div className="my-1">
        <DataGrid
          columns={applicationDetailColumns({
            tranTypePayment,
            handleOnInvoiceClick,
            onPDFDownloadIconClick,
            handleSetShowCustomerDocs,
          })}
          rows={tableData}
          loading={applicationDetailLoading}
          rowCount={applicationDetailCount}
          onPageChange={onPageChange}
          paginationModel={paginationModel}
          disableColumnMenu
          disableColumnFilter
          disableColumnResize
          height={550}
          getRowId={row => row.tranId}
        />
      </div>
    </>
  );
}
export default ApplicationDetailTemplate;
