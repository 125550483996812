import {constants} from '../../constants/common';
import {
  deleteAccountNotes,
  fetchAccountNotes,
} from '../../services/communications';
import {actionTypes} from '../constants/actionTypes';

export const getAllAccountNotes = options => (dispatch, getState) => {
  const toast = getState().generalReducer?.toastRef;

  const accountNotesState = getState().accountNotesReducer;
  dispatch({type: actionTypes.FETCHING_ACCOUNT_NOTES});
  fetchAccountNotes(options, accountNotesState)
    .then(res => {
      dispatch({
        type: actionTypes.FETCHED_ACCOUNT_NOTES,
        accountNotes: res.data.value,
        accountNotesCount: res.data.count,
        accountNotesNextLink: res.data.nextLink,
      });
      return res.data;
    })
    .catch(() => {
      toast?.current.show({
        severity: 'warn',
        summary: constants.SOMETHING_WENT_WRONG,
        detail: constants.FETCHING_ACCOUNT_NOTES_FAILED,
      });
      dispatch({type: actionTypes.FETCHING_ACCOUNT_NOTES_FAILED});
    });
};

export const deleteAccountNote =
  (taskKey, taskActKey) => (dispatch, getState) => {
    const toast = getState().generalReducer?.toastRef;
    dispatch({type: actionTypes.DELETING_ACCOUNT_NOTES});
    return deleteAccountNotes(taskKey, taskActKey)
      .then(() => {
        toast?.current.show({
          severity: 'success',
          summary: constants.DELETED_ACCOUNT_NOTES,
          detail: '',
        });
        dispatch({type: actionTypes.DELETED_ACCOUNT_NOTES});
      })
      .catch(() => {
        toast?.current.show({
          severity: 'warn',
          summary: constants.SOMETHING_WENT_WRONG,
          detail: '',
        });
        dispatch({type: actionTypes.DELETING_ACCOUNT_NOTES_FAILED});
      });
  };
