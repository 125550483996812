import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {Dayjs} from 'dayjs';
import {
  MultiInputTimeRangeField as MUIMultiInputTimeRangeField,
  MultiInputTimeRangeFieldProps,
  DateRange,
  TimeRangeValidationError,
} from '@mui/x-date-pickers-pro';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {FormControl} from '@mui/material';
import {FieldChangeHandlerContext} from '@mui/x-date-pickers/internals';

interface Props extends MultiInputTimeRangeFieldProps<Dayjs> {
  startLabel: string;
  endLabel: string;
  fullWidth?: boolean;
  margin: 'dense' | 'normal' | 'none';
  onChange: (
    a: DateRange<Dayjs>,
    b: FieldChangeHandlerContext<TimeRangeValidationError>,
  ) => void;
}

const MultiInputTimeRangeField: React.FC<Props> = ({
  startLabel,
  endLabel,
  fullWidth = true,
  margin = 'normal',
  onChange,
  value,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <FormControl fullWidth={fullWidth} margin={margin}>
        <MUIMultiInputTimeRangeField
          slotProps={{
            textField: ({position}) => ({
              label: position === 'start' ? startLabel : endLabel,
              size: 'small',
            }),
          }}
          value={value}
          onChange={onChange}
          //  {...otherProps}
        />
      </FormControl>
    </LocalizationProvider>
  );
};

export default MultiInputTimeRangeField;
