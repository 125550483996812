import {Dispatch} from 'redux';
import {
  ColumnSetting,
  ICopyViewSettingsPayload,
  IViewSetting,
  ViewSettingsType,
} from '../../components/common/view-settings/interface';
import {
  copySettingsService,
  deleteViewSettingsBySettingsKeyService,
  getUsersForViewSetting,
  updateViewSettings,
} from '../../services/viewSettings';
import {IViewSettingsActions} from './interface';
import {constants} from '../../constants/common';
import {ISnackbarProps} from '../../ui/feedback/snackbar/Snackbar';
import {deleteAlertsOrSchedule} from '../../services/alerts';
import {IDeleteAlertsOrSchedule} from '../../components/common/alerts/interface';

export const setViewSettingsListState = (
  viewSettings: IViewSetting[],
  viewName: string,
) => {
  return {type: 'FETCHED_VIEW_SETTINGS', payload: {viewSettings, viewName}};
};

export const addViewSettingsState = (
  viewSettingObject: IViewSetting,
  viewName: string,
) => {
  return {type: 'ADD_VIEW_SETTINGS', payload: {viewSettingObject, viewName}};
};

export const updateViewSettingsState = (
  viewSettingObject: IViewSetting,
  viewName: string,
) => {
  return {type: 'UPDATE_VIEW_SETTINGS', payload: {viewSettingObject, viewName}};
};
export const updateViewSettingsColumn = (payload: {
  settingsKey: string;
  columnSetting: ColumnSetting[];
  viewName: string;
}) => {
  return {type: 'UPDATE_VIEW_SETTINGS_COLUMN', payload};
};

export const saveViewSettings =
  ({
    settingsKey,
    payload,
  }: {
    settingsKey: string;
    payload: {isDefault: boolean; show: boolean};
  }) =>
  (dispatch: Dispatch<IViewSettingsActions>) => {
    dispatch({type: 'SAVING_VIEW_SETTINGS'});
    // save view settings
    updateViewSettings({settingsKey, payload})
      .then(() => {
        dispatch({type: 'SAVED_VIEW_SETTINGS'});
      })
      .catch(() => {
        dispatch({type: 'SAVING_VIEW_SETTINGS_FAILED'});
      });
  };

export const getAccountUsers =
  ({
    userKey,
    groupKey,
    updateSnackbarObj,
  }: {
    userKey: number;
    groupKey: string;
    updateSnackbarObj: (obj: ISnackbarProps) => void;
  }) =>
  (dispatch: Dispatch<IViewSettingsActions>) => {
    dispatch({type: 'FETCHING_ACCOUNT_USERS'});

    getUsersForViewSetting({
      groupKey,
      userKey,
    })
      .then(res => {
        const users = res.data;
        dispatch({type: 'FETCHED_ACCOUNT_USERS', accountUsers: users});
      })
      .catch(() => {
        dispatch({type: 'FETCHING_ACCOUNT_USERS_FAILED'});
        updateSnackbarObj({
          message: constants.FAILED_TO_LOAD_USERS,
          open: true,
          type: 'error',
          title: constants.ERROR,
        });
      });
  };

export const copyViewSettings =
  ({updateSnackbarObj, ...options}: ICopyViewSettingsPayload) =>
  (dispatch: Dispatch<IViewSettingsActions>) => {
    dispatch({type: 'COPYING_VIEW_SETTINGS'});
    copySettingsService({...options, updateSnackbarObj: () => {}})
      .then(() => {
        dispatch({type: 'COPIED_VIEW_SETTINGS'});
      })
      .catch(() => {
        dispatch({type: 'COPYING_VIEW_SETTINGS_FAILED'});
        updateSnackbarObj({
          message: constants.FAILED_TO_COPY_VIEW_SETTINGS,
          open: true,
          type: 'error',
          title: constants.ERROR,
        });
      });
  };

export const deleteViewSettingsBySettingsKey = (
  settingsKey: string,
  updateSnackbarObj: (obj: ISnackbarProps) => void,
  viewName: string,
) => {
  return (dispatch: Dispatch<IViewSettingsActions>, getState: any) => {
    const {[viewName]: viewSettings} = getState().viewSettingsReducer;
    const updatedViewSettings = viewSettings.filter(
      (settings: ViewSettingsType) => {
        return settings.settingsKey !== settingsKey;
      },
    );
    dispatch({type: 'DELETING_VIEW_SETTINGS'});
    deleteViewSettingsBySettingsKeyService({settingsKey})
      .then(() => {
        dispatch({
          type: 'DELETED_VIEW_SETTINGS',
          payload: {viewSettings: updatedViewSettings, viewName},
        });
        updateSnackbarObj({
          message: constants.VIEW_SETTINGS_DELETED_SUCCESSFULLY,
          open: true,
          type: 'success',
          title: constants.SUCCESS,
        });
      })
      .catch(() => {
        dispatch({type: 'DELETING_VIEW_SETTINGS_FAILED'});
        updateSnackbarObj({
          message: constants.FAILED_TO_DELETE_VIEW_SETTINGS,
          open: true,
          type: 'error',
          title: constants.ERROR,
        });
      });
  };
};

export const deleteAlertsOrScheduleSettingsKey = ({
  payload,
  updateSnackbarObj,
  viewName,
  type,
}: {
  payload: IDeleteAlertsOrSchedule;
  updateSnackbarObj: (obj: ISnackbarProps) => void;
  viewName: string;
  type: string;
}) => {
  return (dispatch: Dispatch<any>, getState: any) => {
    const {[viewName]: viewSettings} = getState().viewSettingsReducer;
    const updatedViewSettings = viewSettings.filter(
      (settings: ViewSettingsType) => {
        return settings.settingsKey !== payload.SettingsKey;
      },
    );
    if (type.toLowerCase() === constants.ALERT.toLowerCase()) {
      dispatch({type: 'DELETING_VIEW_SETTINGS'});
    } else {
      dispatch({type: 'DELETING_SCHEDULE'});
    }
    deleteAlertsOrSchedule(payload)
      .then(() => {
        if (type.toLowerCase() === constants.ALERT.toLowerCase()) {
          dispatch({
            type: 'DELETED_VIEW_SETTINGS',
            payload: {viewSettings: updatedViewSettings, viewName},
          });
        } else {
          dispatch({type: 'DELETED_SCHEDULE'});
        }
        updateSnackbarObj({
          message: constants.deleteSuccessfully(type), // Alert or Schedule
          open: true,
          type: 'success',
          title: constants.SUCCESS,
        });
      })
      .catch(() => {
        if (type.toLowerCase() === constants.ALERT.toLowerCase()) {
          dispatch({type: 'DELETING_VIEW_SETTINGS_FAILED'});
        } else {
          dispatch({type: 'DELETING_SCHEDULE_FAILED'});
        }
        updateSnackbarObj({
          message: constants.failedToDelete(type), // Alert or Schedule
          open: true,
          type: 'error',
          title: constants.ERROR,
        });
      });
  };
};
