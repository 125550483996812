import {useContext, useState, createContext, useRef} from 'react';
import PropTypes from 'prop-types';
import {ContextMenu} from 'primereact/contextmenu';
import {Button} from 'primereact';
import {AiFillCaretLeft, AiFillCaretRight} from 'react-icons/ai';
import {useSelector} from 'react-redux';
import {FileExplorer} from './FileExplorer';
import {FileManageOptions} from './file-manage-options/FileManageOptions';
import {FileViewer} from './files-viewer/FileViewer';
import {constants} from '../../../constants/common';
import {
  downloadFileFromUrl,
  validateDirForDelete,
} from '../../../utils/fileUtils';
import {isEmpty} from '../../../lib/utils';

export const FileManagerContext = createContext();

export const FileManagerProvider = ({
  children,
  onNewFolderCreate,
  onFilesUpload,
  onTreeSelected,
  selectedDirectory,
  onFileOrFolderDelete,
  onRefresh,
  fileSelected,
  isInvoice,
  selectedTree,
  updateTreeSelection,
  onOpenFolderClicked,
}) => {
  const [isListView, setIsListView] = useState(true);

  const updateView = value => setIsListView(value);

  return (
    <FileManagerContext.Provider
      value={{
        isListView,
        updateView,
        onNewFolderCreate,
        onFilesUpload,
        onTreeSelected,
        selectedDirectory,
        onFileOrFolderDelete,
        onRefresh,
        selectedTree,
        updateTreeSelection,
        fileSelected,
        isInvoice,
        onOpenFolderClicked,
      }}
    >
      {children}
    </FileManagerContext.Provider>
  );
};

function useFileManager() {
  return useContext(FileManagerContext);
}

const FileManager = ({
  onNewFolderCreate,
  onTreeSelected,
  onFilesUpload,
  selectedDirectory,
  filesTree,
  onFileOrFolderDelete,
  onRefresh,
  selectedFilesAndFolder,
  onFileSelected,
  fileSelected,
  isInvoice,
  hideAttachToCommuicate,
  selectedInvoice,
  custName,
  onAttachToCommunicate,
  custId,
  onPDFDownloadIconClick,
  updateSelectedDirectory,
}) => {
  const cm = useRef(null);
  const [showFileUpload, setShowFileUpload] = useState(false);
  const [showNewFolder, setShowNewFolder] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showAttachWarning, setShowAttachWarning] = useState(false);
  const [showFolderNotSelected, setShowFolderNotSelected] = useState(false);
  const [collapsedLeftPane, setCollapsedLeftPane] = useState(false);
  const [selectedTree, setSelectedTree] = useState(selectedDirectory);
  const toast = useSelector(store => store.generalReducer?.toastRef);

  const updateTreeSelection = value => setSelectedTree(value);
  const onAttachmentClick = () => {
    if (fileSelected && !fileSelected.isFolder) {
      onAttachToCommunicate();
    } else {
      setShowAttachWarning(true);
    }
  };

  const onOpenFolderClicked = () => {
    if (fileSelected == null) {
      setShowFolderNotSelected(true);
    } else if (fileSelected?.isFolder) {
      updateSelectedDirectory(fileSelected);
      onTreeSelected(fileSelected);
      updateTreeSelection(fileSelected.key);
    } else {
      downloadFileFromUrl(fileSelected?.fileUrl, fileSelected?.fileName);
    }
  };

  const onContextMenuItemClick = e => {
    switch (e.item.label?.toLocaleLowerCase()) {
      case constants.OPEN.toLocaleLowerCase():
        onOpenFolderClicked();
        break;
      case constants.DELETE.toLocaleLowerCase():
        setShowConfirmDelete(true);
        break;
      case constants.RENAME.toLocaleLowerCase():
        break;
      case constants.NEW_FOLDER.toLocaleLowerCase():
        setShowNewFolder(true);
        break;
      case constants.UPLOAD.toLocaleLowerCase():
        setShowFileUpload(true);
        break;
      case constants.ATTACH_TO_COMMUNICATE.toLocaleLowerCase():
        onAttachmentClick();
        break;
      default:
        break;
    }
  };
  const showDelete = validateDirForDelete(selectedDirectory, fileSelected);
  let contextMenuItems = [
    {
      label: constants.OPEN,
      disabled: isEmpty(fileSelected),
      command: e => onContextMenuItemClick(e),
    },
    {
      label: constants.DELETE,
      command: e => onContextMenuItemClick(e),
      disabled: !showDelete,
    },
    {
      label: constants.RENAME,
      disabled: true,
      command: e => onContextMenuItemClick(e),
    },
    {
      label: constants.NEW_FOLDER,
      command: e => onContextMenuItemClick(e),
    },
    {
      label: constants.UPLOAD,
      command: e => onContextMenuItemClick(e),
    },
  ];

  if (!isInvoice && !hideAttachToCommuicate) {
    contextMenuItems = [
      ...contextMenuItems,
      {
        label: constants.ATTACH_TO_COMMUNICATE,
        command: e => onContextMenuItemClick(e),
      },
    ];
  }

  const onCollapseExpandLeftPaneClick = () => {
    setCollapsedLeftPane(!collapsedLeftPane);
  };

  return (
    <FileManagerProvider
      onNewFolderCreate={onNewFolderCreate}
      onFilesUpload={onFilesUpload}
      onTreeSelected={onTreeSelected}
      onFileOrFolderDelete={onFileOrFolderDelete}
      selectedDirectory={selectedDirectory}
      onRefresh={onRefresh}
      fileSelected={fileSelected}
      isInvoice={isInvoice}
      selectedTree={selectedTree}
      updateTreeSelection={updateTreeSelection}
      onOpenFolderClicked={onOpenFolderClicked}
    >
      {isInvoice && (
        <div>
          <p className="h5">
            {constants.CUST_ID}: {custId} {constants.CUST_NAME}: {custName}
          </p>
          <div className="d-flex gap-4 align-items-center mb-2">
            <p className="h5">
              {constants.INVOICE} {selectedInvoice?.documentNo}
            </p>
            <i
              className="pi pi-pdf p-2 cursor-pointer"
              onClick={() => onPDFDownloadIconClick()}
              aria-hidden="true"
            />
          </div>
        </div>
      )}
      <div className="pb-2">
        <div className="p-2 bg-light-grey ">
          <FileManageOptions
            onAttachToCommunicate={onAttachToCommunicate}
            setShowFileUpload={setShowFileUpload}
            showFileUpload={showFileUpload}
            showAttachWarning={showAttachWarning}
            setShowAttachWarning={setShowAttachWarning}
            onAttachmentClick={onAttachmentClick}
            showNewFolder={showNewFolder}
            setShowNewFolder={setShowNewFolder}
            setShowConfirmDelete={setShowConfirmDelete}
            showConfirmDelete={showConfirmDelete}
            hideAttachToCommuicate={hideAttachToCommuicate}
            showFolderNotSelected={showFolderNotSelected}
            setShowFolderNotSelected={setShowFolderNotSelected}
          />
        </div>
        <ContextMenu
          ref={cm}
          model={contextMenuItems}
          baseZIndex={99999999999}
        />
        <div
          className="d-flex h-450-px"
          onContextMenu={e => cm.current.show(e)}
        >
          <div className={`${collapsedLeftPane ? 'w-0' : 'w-25'} files-tree`}>
            <FileExplorer filesTree={filesTree} />
          </div>
          <div className="d-flex flex-column align-items-center justify-content-center bg-light w-8-px">
            <Button
              onClick={onCollapseExpandLeftPaneClick}
              title={constants.COLLAPSE_EXPAND_THE_LEFT_PANE}
              className="expand-collapse-customer-doc-files-tree border-0 bg-light-grey p-0 h-40-px rounded-1"
            >
              {collapsedLeftPane ? (
                <AiFillCaretRight fill="grey" size={8} />
              ) : (
                <AiFillCaretLeft fill="grey" size={8} />
              )}
            </Button>
          </div>
          <div
            className={`${
              collapsedLeftPane ? 'w-100' : 'w-75'
            }  border border-1 border-black`}
          >
            <FileViewer
              selectedFilesAndFolder={selectedFilesAndFolder}
              onFileSelected={onFileSelected}
              fileSelected={fileSelected}
            />
          </div>
        </div>
      </div>
    </FileManagerProvider>
  );
};

FileManager.propTypes = {
  onNewFolderCreate: PropTypes.func.isRequired,
  onTreeSelected: PropTypes.func.isRequired,
  onFilesUpload: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onFileOrFolderDelete: PropTypes.func.isRequired,
  onFileSelected: PropTypes.func.isRequired,
  updateSelectedDirectory: PropTypes.func,
  selectedDirectory: PropTypes.string.isRequired,
  filesTree: PropTypes.array.isRequired,
  selectedFilesAndFolder: PropTypes.object.isRequired,
  fileSelected: PropTypes.object.isRequired,
  isInvoice: PropTypes.bool,
  selectedInvoice: PropTypes.object,
  onAttachToCommunicate: PropTypes.func.isRequired,
  custId: PropTypes.string,
};

export {FileManager, useFileManager};
