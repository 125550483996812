import {useEffect, useState} from 'react';
import {Button, RadioGroup, Select} from '../../../ui/inputs';
import {constants} from '../../../constants/common';
import {getUserData} from '../../../store/actions/userActions';
import {REPORT_OUTPUT_TYPE, REPORT_TYPE} from '../../../utils/constants';
import Backdrop from '../../../ui/utils/backdrop/Backdrop';
import {
  fetchReportsByGroupKeyAndType,
  sendReportForProcess,
} from '../../../services/activities';
import {IRadioGroupItems, IReports} from '../../../interfaces/common';
import {
  IProcessReportPayload,
  IRenderReportsContent,
} from '../../../interfaces/report';
import {Stack} from '../../../ui/layouts';
import {getUniqueArray, isEmpty} from '../../../lib/utils';
import {useQueryKeys} from '../../../hooks/useQueryKeys';
import {Dialog} from '../../../ui';
import {Typography} from '../../../ui/displays';

export const RenderReportsContent = ({
  reportType,
  onClose,
  selectedRows,
  updateSnackbarObj,
  keyName,
}: IRenderReportsContent) => {
  const {groupKey} = getUserData();
  const [selectReportError, setSelectReportError] = useState<boolean>(false);
  const [fetchingReports, setFetchingReports] = useState<boolean>(false);
  const [showReportConfirmationDialog, setShowReportConfirmationDialog] =
    useState<boolean>(false);
  const [reports, setReports] = useState<IReports[]>([]);
  // Todo: Need to add a new radio button for Email and Process
  const PROCESS_TYPE_RADIO_VALUES = {
    PROCESS_FOR_PREVIEW: 1,
    PROCESS_AND_EMAIL: 2,
  };
  const processTypeRadioItems: IRadioGroupItems[] = [
    {
      id: `${PROCESS_TYPE_RADIO_VALUES.PROCESS_FOR_PREVIEW}`,
      label: constants.PROCESS_FOR_PREVIEW,
      value: PROCESS_TYPE_RADIO_VALUES.PROCESS_FOR_PREVIEW,
      hidden: false,
    },
    {
      id: `${PROCESS_TYPE_RADIO_VALUES.PROCESS_AND_EMAIL}`,
      label: constants.PROCESS_AND_EMAIL,
      value: PROCESS_TYPE_RADIO_VALUES.PROCESS_AND_EMAIL,
      hidden: true,
      // hidden: reportType === REPORT_TYPE.COMMUNICATION,
    },
  ];
  const [selectedProcessType, setSelectedProcessType] =
    useState<IRadioGroupItems>(processTypeRadioItems[0]);
  const [selectedReport, setSelectedReport] = useState<IReports | null>(null);
  const [outputType, setOutputType] = useState<number>(
    REPORT_OUTPUT_TYPE.PREVIEW,
  );
  const loadReports = async () => {
    try {
      setFetchingReports(true);
      const res = await fetchReportsByGroupKeyAndType({
        groupKey,
        type: reportType,
      });
      if (res && res.data) {
        const updatedResponse = [...res.data];
        updatedResponse.forEach(response => {
          response.label = response.reportName;
          response.value = response.reportKey;
        });
        setReports(updatedResponse);
      }
      setFetchingReports(false);
    } catch (error) {
      setFetchingReports(false);
    }
  };

  useEffect(() => {
    loadReports();
    // Don't need to pass any dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onProcessTypeChange = (e: any) => {
    const findProcessTypeByValue = processTypeRadioItems.find(
      pType => pType.value === Number(e.target.value),
    );
    if (
      Number(e.target.value) === PROCESS_TYPE_RADIO_VALUES.PROCESS_FOR_PREVIEW
    ) {
      setOutputType(REPORT_OUTPUT_TYPE.PREVIEW);
    } else {
      setOutputType(REPORT_OUTPUT_TYPE.Email);
    }
    if (findProcessTypeByValue) {
      setSelectedProcessType(findProcessTypeByValue);
    }
  };

  const onReportValueChange = (e: any) => {
    const findReportByReportKey = reports.find(
      rep => rep.reportKey === e.target.value,
    );
    setSelectedReport(findReportByReportKey || null);
    setSelectReportError(false);
  };
  const {userId} = getUserData();
  const {userKey} = useQueryKeys();

  const onProcessBtnClicked = async () => {
    if (isEmpty(selectedReport)) {
      setSelectReportError(true);
    } else if (selectedReport) {
      const taskActivityKeys = selectedRows.map((row: any) => row[keyName]);
      // Get unique Keys for Payload
      const uniqueTaskActKeys = getUniqueArray(taskActivityKeys);

      const formatTaskActKeys = uniqueTaskActKeys.map(taskActKey => {
        return {
          tblKey: taskActKey,
        };
      });

      const payload: IProcessReportPayload = {
        accountKey: groupKey,
        userID: userId,
        reportKey: selectedReport.reportKey,
        outputType,
        taskActKeys: formatTaskActKeys,
        reportType: selectedReport.reportType,
        userKey,
      };
      try {
        setFetchingReports(true);
        await sendReportForProcess(payload);
        setFetchingReports(false);
        onClose();
        updateSnackbarObj({
          type: 'info',
          title: '',
          open: true,
          message: constants.YOU_WILL_BE_ALERTED_WHEN_PROCESS_IS_COMPLETE,
        });
      } catch (error) {
        setFetchingReports(false);
        updateSnackbarObj({
          type: 'error',
          title: '',
          open: true,
          message: constants.FAILED_TO_PROCESS_REPORT,
        });
      }
    }

    if (showReportConfirmationDialog) {
      setShowReportConfirmationDialog(false);
    }
  };

  const handleOnProcessBtnClicked = () => {
    if (
      reportType === REPORT_TYPE.ACCOUNT &&
      selectedProcessType?.value ===
        PROCESS_TYPE_RADIO_VALUES.PROCESS_AND_EMAIL &&
      !isEmpty(selectedReport)
    ) {
      // Todo: Add Dialog in All Accounts Page for Reports having Ok and cancel button if ok then proceed with ProcessBtnClicked ow cancel
      setShowReportConfirmationDialog(true);
    } else {
      // Executes when invoice or communication page
      onProcessBtnClicked();
    }
  };

  return (
    <Stack position="relative" direction="column" spacing={2} padding={2}>
      <Dialog
        open={showReportConfirmationDialog}
        title={constants.WARNING}
        onClose={() => setShowReportConfirmationDialog(false)}
        maxWidth="xs"
        fullWidth
      >
        <Stack position="relative" direction="column" spacing={2} padding={2}>
          <Typography variant="body2">
            {constants.processEmailForReportTypeAccount(selectedRows.length)}
          </Typography>
          <Stack direction="row" spacing={2}>
            <Button variant="contained" onClick={onProcessBtnClicked}>
              {constants.OK}
            </Button>
            <Button
              variant="contained"
              onClick={() => setShowReportConfirmationDialog(false)}
            >
              {constants.CANCEL}
            </Button>
          </Stack>
        </Stack>
      </Dialog>
      <Backdrop isLoading={fetchingReports} open={fetchingReports} />
      <Select
        items={reports}
        label={constants.SELECT_REPORT}
        onChange={onReportValueChange}
        value={selectedReport?.value}
        error={selectReportError}
        helperText={
          selectReportError ? constants.YOU_MUST_SELECT_A_REPORT_TYPE : ''
        }
      />
      <RadioGroup
        name="processType"
        items={processTypeRadioItems}
        onChange={onProcessTypeChange}
        value={selectedProcessType?.value}
        row
      />
      <Stack direction="row" spacing={2}>
        <Button variant="contained" onClick={handleOnProcessBtnClicked}>
          {constants.PROCESS}
        </Button>
        <Button variant="contained" onClick={onClose}>
          {constants.CANCEL}
        </Button>
      </Stack>
    </Stack>
  );
};
