import {FallbackProps} from 'react-error-boundary';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import {Button} from '../../ui/inputs';
import {getConfig} from '../../lib/utils';
import {getUserData} from '../../store/actions/userActions';
import {constants} from '../../constants/common';
import Typography from '../../ui/displays/typography/Typography';

export type ErrorFallbackProps = {
  customMessage?: string;
  showResetButton?: boolean;
  showLogoutButton?: boolean;
  showFeedbackButton?: boolean;
  resetButtonText?: string;
  logoutButtonText?: string;
  feedbackButtonText?: string;
};

const ErrorFallback: React.FC<ErrorFallbackProps & FallbackProps> = ({
  resetErrorBoundary,
  customMessage = constants.ERROR_PAGE_MESSAGE,
  showResetButton = true,
  showLogoutButton = false,
  showFeedbackButton = false,
  resetButtonText = constants.TRY_AGAIN_TEXT,
  logoutButtonText = constants.LOGOUT,
  feedbackButtonText = constants.SHARE_INFO,
}) => {
  const onHandleLogout = () => {
    window.localStorage.clear();
    window.sessionStorage.clear();
    const baseRedirectUrl = getConfig().webClientRedirectUrl;
    const {sessionId} = getUserData() || {sessionId: ''};
    const logoutUrl = `${baseRedirectUrl}/(S(${sessionId}))/login/login.aspx?IsReactLogout=true`;
    window.location.href = logoutUrl;
  };

  const onHandleFeedback = () => {};

  return (
    <div className="d-flex flex-column justify-content-center align-items-center ">
      <Typography variant="h6">{constants.SOMETHING_WENT_WRONG}!</Typography>
      <span className="d-flex align-items-center gap-2 mb-4">
        <ErrorOutlineIcon color="error" fontSize="large" />{' '}
        <Typography variant="body1">{customMessage}</Typography>
      </span>
      <div className="d-flex gap-4">
        {showResetButton && (
          <Button variant="contained" onClick={resetErrorBoundary}>
            {resetButtonText}
          </Button>
        )}
        {showLogoutButton && (
          <Button variant="contained" color="error" onClick={onHandleLogout}>
            {logoutButtonText}
          </Button>
        )}
        {showFeedbackButton && (
          <Button variant="contained" color="info" onClick={onHandleFeedback}>
            {feedbackButtonText}
          </Button>
        )}
      </div>
    </div>
  );
};

export default ErrorFallback;
