import {useEffect, useRef, useState} from 'react';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Dialog} from 'primereact';
import {Header} from './components/header/Header';
import {NavBarProvider} from './components/navbar/NavbarContext';
import {getSideBarMenu, updateSessionTime} from './services/application';
import {getUserData} from './store/actions/userActions';
import {
  updateSelectedSideNavElement,
  updateSideBarMenuItems,
  updateSideNavRefresh,
} from './store/actions/generalActions';
import {actionTypes} from './store/constants/actionTypes';
import {useSessionStorage} from './hooks/useSessionStorage';
import {useIdleTimer} from './hooks/useIdleTimer';
import {getConfig} from './lib/utils';
import {Button} from './ui/inputs';
import {constants} from './constants/common';
import ErrorBoundaryWrapper from './components/error-boundary/ErrorBoundaryWrapper';
import {useQueryKeys} from './hooks/useQueryKeys';
import {Navbar} from './components/navbar/Navbar';

export default function Layout() {
  const {
    groupKey,
    sessionOemName,
    sessionMenuVersion,
    sessionAppVersion,
    sessionSupportUserGuidID,
    userType,
    sessionId,
    windowName,
    isReactLogin,
  } = getUserData() || {};
  const {userKey} = useQueryKeys();
  const navigate = useNavigate();

  const baseRedirectUrl = getConfig().webClientRedirectUrl;
  const logoutUrl = `${baseRedirectUrl}/(S(${sessionId}))/login/login.aspx?IsReactLogout=true`;
  const logout = () => {
    window.localStorage.clear();
    window.sessionStorage.clear();
    if (isReactLogin) {
      navigate('/login');
    } else {
      window.location.href = logoutUrl;
    }
  };
  const dispatch = useDispatch();
  const {sideNavRefresh, sidebarMenu} = useSelector(
    store => store.generalReducer,
  );
  const toast = useSelector(store => store.generalReducer?.toastRef);
  const [hideNavBar, setHideNavBar] = useState(false);
  const intervalReference = useRef(null);
  const timeInterval = 5 * 60 * 1000;
  const {pathname} = useLocation();
  const prevPath = useRef(pathname);
  const [{userId}] = useSessionStorage('profile');
  const latestUserIdRef = useRef(userId);
  const {showIdleDialog, onStillActive, remainingTime} = useIdleTimer(logout);
  const fetchSidebarMenu = async () => {
    try {
      const request = {
        GroupKey: groupKey,
        UserId: latestUserIdRef.current,
        MenuVersion: sessionMenuVersion,
        OEMList: sessionOemName,
        AppVersion: sessionAppVersion,
        SupportUserGuid: sessionSupportUserGuidID,
        UserType: userType,
      };
      dispatch({type: actionTypes.FETCHING_SIDEBAR_MENU});
      const {data} = await getSideBarMenu(request);
      const addRecentAccount =
        data.length &&
        data.map(item => {
          if (item.sideNavItemText === 'Accounts') {
            const recentAccounts =
              JSON.parse(localStorage.getItem('recentAccounts')) || [];
            const addNavItems = recentAccounts.map((account, i) => {
              return {
                navItemURL: `account-overview?CustKey=${account.custKey}&AssignedUserKey=${userKey}&SettingID=~All&OEM=LS`,
                parentSideNavKey: item.sideNavKey,
                sideNavItemCount: 0,
                sideNavItemIconName: null,
                sideNavItemText: account?.custName,
                sideNavItemURL: `AccountOverview.aspx?CustKey=${account.custKey}`,
                sideNavItems: [],
                sideNavKey: `_${item.sideNavKey}-${+i}`,
              };
            });
            // eslint-disable-next-line no-param-reassign
            item.sideNavItems = [...item.sideNavItems, ...addNavItems];
          }
          return item;
        });
      dispatch(updateSideBarMenuItems(addRecentAccount));
    } catch (error) {
      dispatch({type: actionTypes.FETCHING_SIDEBAR_MENU_FAILED});
      toast?.current.show({
        severity: 'error',
        summary: 'Cannot fetch navigation details',
        detail: '',
      });
    }
  };

  const updateSession = () => {
    if (!isReactLogin) {
      updateSessionTime({
        sessionId,
        windowName,
        userId: latestUserIdRef.current,
      });
    }
  };

  useEffect(() => {
    if (userId) {
      latestUserIdRef.current = userId;
      fetchSidebarMenu();
      clearInterval(intervalReference.current);
      intervalReference.current = setInterval(() => {
        updateSession();
        fetchSidebarMenu();
      }, timeInterval);
    }
    return () => {
      clearInterval(intervalReference.current);
    };
  }, [userId]);

  const findSelectedSideNavItemUsingNavItemURL = sidebarMenuObj => {
    let result = null;
    sidebarMenuObj.forEach(obj => {
      if (`/${obj.navItemURL}`.includes(pathname?.replace('/activity', ''))) {
        result = obj;
      } else if (obj.sideNavItems.length > 0) {
        const childResult = findSelectedSideNavItemUsingNavItemURL(
          obj.sideNavItems,
        );
        if (childResult) {
          result = childResult;
        }
      }
    });
    return result;
  };

  useEffect(() => {
    if (
      !pathname.includes('/activity') &&
      pathname !== prevPath &&
      sidebarMenu.length
    ) {
      const result = findSelectedSideNavItemUsingNavItemURL(sidebarMenu);
      dispatch(updateSelectedSideNavElement(result));
    }
  }, [prevPath, sidebarMenu]);

  useEffect(() => {
    if (sideNavRefresh) {
      fetchSidebarMenu();
      dispatch(updateSideNavRefresh(false));
    }
  }, [sideNavRefresh]);

  const toggleNav = () => {
    setHideNavBar(!hideNavBar);
  };

  const value = {hideNavBar, toggleNav};

  const onStayLogin = () => {
    updateSession();
    onStillActive();
  };

  return (
    <NavBarProvider value={value}>
      <div className="navbar-and-content-wrapper">
        <div>
          <Navbar navItems={sidebarMenu} />
        </div>
        <div
          className={`layout-container transition-medium ${
            hideNavBar ? 'w-95' : ''
          } p-2`}
        >
          <Header />
          <main className="main-container">
            <ErrorBoundaryWrapper>
              <Outlet />
            </ErrorBoundaryWrapper>
          </main>
        </div>
      </div>
      {showIdleDialog && (
        <Dialog
          closeOnEscape={false}
          visible={showIdleDialog}
          className="w-40-vw"
          position="center"
          closable={false}
          showHeader={false}
        >
          <div className="d-flex flex-column align-items-center justify-content-center">
            <p>{constants.logoutWarningMessageOne(remainingTime)}</p>
            <p>{constants.LOGOUT_WARNING_MESSAGE_TWO}</p>
            <Button variant="contained" onClick={onStayLogin}>
              {constants.LOGOUT_WARNING_BUTTON}
            </Button>
          </div>
        </Dialog>
      )}
    </NavBarProvider>
  );
}
