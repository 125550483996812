import {useState} from 'react';
import {Column, DataTable} from 'primereact';
import {PaginatorTemplate} from '../PaginatorTemplate';
import {constants} from '../../../constants/common';

function CustomDataTable({
  columns,
  totalRecords,
  onLoadMore = () => {},
  skip,
  loading,
  tableData,
  selectionMode = true,
  selection = true,
  selectedRows,
  updateSelectedRows = () => {},
  pageRowCount,
  updatePageRowCount = () => {},
  tableClassName,
  loadedPage,
  setLoadedPage = () => {},
  currentPage,
  setCurrentPage = () => {},
  paginator = true,
  selectionType,
  resizableColumns = true,
  scrollable = false,
  scrollHeight = '',
  tableFilterDisplayType = 'menu',
  sortBy,
  sortDirection,
  onSortChange = () => {},
  className = '',
  fixedHeader = true,
  selectionModeType = 'checkbox',
}) {
  const [filters] = useState([]);
  const [first, setFirst] = useState(0);
  const defaultPageRows = 10;
  const onPage = event => {
    setFirst(event.first);
    setCurrentPage(event.page + 1);
  };

  const columnComponents = columns?.map(col => (
    <Column
      filterPlaceholder={col.filterPlaceholder}
      footer={col.footer}
      filterMatchMode={col.filterMatchMode}
      filterField={col.filterField}
      filterFunction={col.filterFunction}
      editor={col.editor}
      dataType={col.dataType}
      filterElement={col.filterElement}
      filterMenuStyle={col.filterMenuStyle}
      frozen={col.frozen}
      showFilterMenu={!!col.showFilterMenu}
      align={col.align}
      alignHeader={col.alignHeader}
      className={col.className}
      reorderable={col.reorderable}
      resizeable={col.resizeable}
      filter={col.filter}
      showClearButton={false}
      key={col.field}
      field={col.field}
      style={col.style}
      headerStyle={col.headerStyle}
      header={col.header}
      body={col.body}
      sortable={col.sortable}
      exportable={col.exportable}
    />
  ));
  const onRowSelectionChange = e => {
    if (selectionMode || selectionType === 'multiple') {
      updateSelectedRows(e.value);
    } else {
      return null;
    }
  };

  return (
    <DataTable
      className={`${className || ''} ${fixedHeader ? 'fixed-header' : ''}`}
      size="small"
      filters={filters}
      filterDisplay={tableFilterDisplayType}
      loading={loading}
      value={tableData}
      paginator={!!paginator}
      paginatorTemplate={PaginatorTemplate(
        totalRecords,
        onLoadMore,
        skip,
        loading,
        updatePageRowCount,
        pageRowCount,
        loadedPage,
        setCurrentPage,
        currentPage,
        setLoadedPage,
      )}
      first={first}
      rows={loading ? defaultPageRows : pageRowCount}
      onPage={onPage}
      responsiveLayout="scroll"
      resizableColumns={resizableColumns}
      showGridlines
      stripedRows
      columnResizeMode="expand"
      selection={selection && selectedRows}
      onSelectionChange={onRowSelectionChange}
      emptyMessage={constants.NO_RECORDS_TO_DISPLAY}
      selectionMode={
        (selectionMode && 'checkbox') ||
        (selectionModeType === 'single' && 'single')
      }
      scrollHeight={scrollHeight || '550px'}
      tableClassName={tableClassName}
      selectionAutoFocus={false}
      scrollable={scrollable}
      onSort={e => onSortChange({field: e.sortField, direction: e.sortOrder})}
      sortMode="single"
      sortField={sortBy}
      sortOrder={sortDirection}
    >
      {selectionMode && (
        <Column
          selectionMode="multiple"
          headerStyle={{width: '3rem', paddingLeft: '17px', padding: '0 .5rem'}}
          exportable={false}
        />
      )}
      {columnComponents}
    </DataTable>
  );
}
export default CustomDataTable;
