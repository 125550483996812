import dayjs from 'dayjs';
import {constants} from '../../../../constants/common';
import {TRAN_TYPE} from '../../../../utils/constants';
import {Checkbox, Select} from '../../../../ui/inputs';
import {filterOperators} from '../../../../lib/commonTableHelpers';
import {isEmpty} from '../../../../lib/utils';

export const activityTransactionsColumns = (
  onTransactionObjChange = (key, val, index) => {},
  onPDFDownloadIconClick = row => {},
  onFolderIconClicked = row => {},
  disputeCodes = {},
  handleOnInvoiceClick = (tranType, invoiceKey) => {},
) => {
  const dateFormat = 'M/DD/YYYY h:mm A';

  return [
    {
      field: 'tagged',
      headerName: 'Tag',
      renderCell: ({row}) => (
        <Checkbox
          checked={row.tagged}
          onChange={e => {
            onTransactionObjChange('tagged', e.target.checked, row.rowIndex);
          }}
        />
      ),
      renderHeaderFilter: () => null,
      sortable: false,
      disableColumnMenu: true,
      type: 'boolean',
      filterOperators: filterOperators.boolean,
    },
    {
      field: 'taggedToTask',
      headerName: 'issue',
      renderCell: ({row}) => (
        <Checkbox
          disabled
          checked={row.tagged}
          onChange={e => {
            onTransactionObjChange(
              'taggedToTask',
              e.target.checked,
              row.rowIndex,
            );
          }}
        />
      ),

      renderHeaderFilter: () => null,
      sortable: false,
      disableColumnMenu: true,
      type: 'boolean',
      filterOperators: filterOperators.boolean,
    },
    {
      field: 'dispute',
      headerName: 'Dispute',
      renderCell: ({row}) => (
        <Checkbox
          disabled={!row.tagged || row.tranType === TRAN_TYPE.CR}
          checked={row.dispute}
          onChange={e => {
            onTransactionObjChange('dispute', e.target.checked, row.rowIndex);
          }}
        />
      ),

      renderHeaderFilter: () => null,
      sortable: false,
      disableColumnMenu: true,
      type: 'boolean',
      filterOperators: filterOperators.boolean,
    },
    {
      field: 'excludeFromAging',
      headerName: 'Exclude Aging YN',
      renderCell: ({row}) => (
        <Checkbox
          disabled={!row.tagged || row.tranType === TRAN_TYPE.CR}
          checked={row.excludeFromAging}
          onChange={e => {
            onTransactionObjChange(
              'excludeFromAging',
              e.target.checked,
              row.rowIndex,
            );
          }}
        />
      ),

      renderHeaderFilter: () => null,
      sortable: false,
      disableColumnMenu: true,
      type: 'boolean',
      filterOperators: filterOperators.boolean,
    },
    {
      field: 'resolved',
      headerName: 'Resolved',
      renderCell: ({row}) => (
        <Checkbox
          disabled={!row.tagged || row.tranType === TRAN_TYPE.CR}
          checked={row.resolved}
          onChange={e => {
            onTransactionObjChange('resolved', e.target.checked, row.rowIndex);
          }}
        />
      ),

      renderHeaderFilter: () => null,
      sortable: false,
      disableColumnMenu: true,
      type: 'boolean',
      filterOperators: filterOperators.boolean,
    },
    {
      field: 'reasonCode',
      headerName: 'Reason code',
      minWidth: 220,
      renderCell: ({row}) => {
        return (
          <Select
            label={constants.SELECT_REASON_CODE}
            size="small"
            margin="dense"
            disabled={!row.tagged}
            value={!isEmpty(row.reasonCode) ? parseInt(row.reasonCode, 10) : ''}
            items={disputeCodes}
            onChange={e =>
              onTransactionObjChange('reasonCode', e.target.value, row.rowIndex)
            }
            placeholder={constants.SELECT_USER}
          />
        );
      },

      renderHeaderFilter: () => null,
      sortable: false,
      disableColumnMenu: true,
      type: 'number',
      filterOperators: filterOperators.number,
    },
    {
      field: 'copyPDFToBlob',
      headerName: 'Display',
      renderCell: ({row}) => (
        <span className="mx-1">
          {' '}
          {(row.tranType === TRAN_TYPE.IN || row.tranType === TRAN_TYPE.CM) && (
            <>
              <i
                className="pi pi-pdf p-2 cursor-pointer"
                onClick={() => onPDFDownloadIconClick(row)}
                aria-hidden="true"
              />
              <i
                className="pi pi-folder p-2 ms-1 cursor-pointer"
                onClick={() => onFolderIconClicked(row)}
                aria-hidden="true"
              />
            </>
          )}
        </span>
      ),

      renderHeaderFilter: () => null,
      sortable: false,
      disableColumnMenu: true,
      type: 'actions',
    },
    {
      field: 'documentNo',
      headerName: 'Invoice',
      renderCell: ({row}) => (
        <span
          aria-hidden="true"
          className="blueLink cursor-pointer"
          onClick={() => handleOnInvoiceClick(row?.tranType, row?.invcKey)}
        >
          {row?.documentNo}
        </span>
      ),

      renderHeaderFilter: () => null,
      sortable: false,
      disableColumnMenu: true,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'documentDate',
      headerName: 'Invoice date',
      valueFormatter: params =>
        params?.value ? dayjs(params?.value).utc().format(dateFormat) : '',
      minWidth: 150,

      renderHeaderFilter: () => null,
      sortable: false,
      disableColumnMenu: true,
      type: 'date',
      filterOperators: filterOperators.date,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      valueFormatter: params => params?.value?.toFixed(2),
      minWidth: 120,

      renderHeaderFilter: () => null,
      sortable: false,
      disableColumnMenu: true,
      type: 'number',
      filterOperators: filterOperators.number,
    },
    {
      field: 'tranType',
      headerName: 'Tran type',
      sortable: false,

      renderHeaderFilter: () => null,
      disableColumnMenu: true,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'homeCurrID',
      headerName: 'Curr ID',
      sortable: false,
      disableColumnMenu: true,

      renderHeaderFilter: () => null,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'balanceDue',
      headerName: 'Balance Due',
      valueFormatter: params => params?.value?.toFixed(2),
      renderHeaderFilter: () => null,

      minWidth: 180,
      sortable: false,
      disableColumnMenu: true,
      type: 'number',
      filterOperators: filterOperators.number,
    },
    {
      field: 'custPONo',
      headerName: 'Customer PO',
      sortable: false,
      disableColumnMenu: true,

      renderHeaderFilter: () => null,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'dueDate',
      headerName: 'Due Date',
      valueFormatter: params =>
        params?.value ? dayjs(params?.value).utc().format(dateFormat) : '',
      minWidth: 150,

      renderHeaderFilter: () => null,
      sortable: false,
      disableColumnMenu: true,
      type: 'date',
      filterOperators: filterOperators.date,
    },
    {
      field: 'paySched',
      headerName: 'Pay Sched',

      renderHeaderFilter: () => null,
      renderCell: ({row}) => (
        <Checkbox
          disabled
          checked={row.paySched}
          onClick={e => e.preventDefault()}
        />
      ),
      sortable: false,
      disableColumnMenu: true,
      type: 'boolean',
      filterOperators: filterOperators.boolean,
    },
    {
      field: 'postDate',
      headerName: 'Post Date',
      valueFormatter: params =>
        params?.value ? dayjs(params?.value).utc().format(dateFormat) : '',
      minWidth: 150,

      renderHeaderFilter: () => null,
      sortable: false,
      disableColumnMenu: true,
      type: 'date',
      filterOperators: filterOperators.date,
    },
    {
      field: 'discDate',
      headerName: 'Disc Date',
      valueFormatter: params =>
        params?.value ? dayjs(params?.value).utc().format(dateFormat) : '',
      minWidth: 150,

      renderHeaderFilter: () => null,
      sortable: false,
      disableColumnMenu: true,
      type: 'date',
      filterOperators: filterOperators.date,
    },
    {
      field: 'pmtTermsID',
      headerName: 'Payment Terms',
      sortable: false,
      disableColumnMenu: true,

      renderHeaderFilter: () => null,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'pmtTermsDesc',
      headerName: 'PmtTermsDesc',
      sortable: false,
      disableColumnMenu: true,

      renderHeaderFilter: () => null,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'closingTranDate',
      headerName: 'Closing Tran Date',
      valueFormatter: params =>
        params?.value ? dayjs(params?.value).utc().format(dateFormat) : '',
      minWidth: 150,
      sortable: false,

      renderHeaderFilter: () => null,
      disableColumnMenu: true,
      type: 'date',
      filterOperators: filterOperators.date,
    },
    {
      field: 'companyID',
      headerName: 'Company ID',
      sortable: false,

      renderHeaderFilter: () => null,
      disableColumnMenu: true,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      renderHeaderFilter: () => null,

      disableColumnMenu: true,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'consolidatedInvcNo',
      headerName: 'Consolidated Invc No',
      sortable: false,
      disableColumnMenu: true,

      renderHeaderFilter: () => null,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'amount01',
      headerName: 'Amount 1',
      valueFormatter: params => params?.value?.toFixed(2),
      minWidth: 120,
      sortable: false,
      renderHeaderFilter: () => null,

      disableColumnMenu: true,
      type: 'number',
      filterOperators: filterOperators.number,
    },
    {
      field: 'amount02',
      headerName: 'Amount 2',
      valueFormatter: params => params?.value?.toFixed(2),
      minWidth: 120,
      renderHeaderFilter: () => null,

      sortable: false,
      disableColumnMenu: true,
      type: 'number',
      filterOperators: filterOperators.number,
    },
    {
      field: 'amount03',
      headerName: 'Amount 3',
      valueFormatter: params => params?.value?.toFixed(2),
      minWidth: 120,
      sortable: false,
      renderHeaderFilter: () => null,

      disableColumnMenu: true,
      type: 'number',
      filterOperators: filterOperators.number,
    },
    {
      field: 'amount04',
      headerName: 'Amount 4',
      valueFormatter: params => params?.value?.toFixed(2),
      minWidth: 120,
      renderHeaderFilter: () => null,

      sortable: false,
      disableColumnMenu: true,
      type: 'number',
      filterOperators: filterOperators.number,
    },
    {
      field: 'amount05',
      headerName: 'Amount 5',
      valueFormatter: params => params?.value?.toFixed(2),
      minWidth: 120,
      renderHeaderFilter: () => null,

      sortable: false,
      disableColumnMenu: true,
      type: 'number',
      filterOperators: filterOperators.number,
    },
    {
      field: 'amount06',
      headerName: 'Amount 6',
      valueFormatter: params => params?.value?.toFixed(2),
      minWidth: 120,
      renderHeaderFilter: () => null,

      sortable: false,
      disableColumnMenu: true,
      type: 'number',
      filterOperators: filterOperators.number,
    },
    {
      field: 'amount07',
      headerName: 'Amount 7',
      valueFormatter: params => params?.value?.toFixed(2),
      minWidth: 120,
      renderHeaderFilter: () => null,

      sortable: false,
      disableColumnMenu: true,
      type: 'number',
      filterOperators: filterOperators.number,
    },
    {
      field: 'amount08',
      headerName: 'Amount 8',
      valueFormatter: params => params?.value?.toFixed(2),
      minWidth: 120,
      sortable: false,
      renderHeaderFilter: () => null,

      disableColumnMenu: true,
      type: 'number',
      filterOperators: filterOperators.number,
    },
    {
      field: 'amount09',
      headerName: 'Amount 9',
      valueFormatter: params => params?.value?.toFixed(2),
      minWidth: 120,
      renderHeaderFilter: () => null,

      sortable: false,
      disableColumnMenu: true,
      type: 'number',
      filterOperators: filterOperators.number,
    },
    {
      field: 'amount10',
      headerName: 'Amount 10',
      renderCell: ({row}) => <span>{row.amount10?.toFixed(2)}</span>,
      sortable: false,
      renderHeaderFilter: () => null,

      disableColumnMenu: true,
      type: 'number',
      filterOperators: filterOperators.number,
    },
    {
      field: 'date01',
      headerName: 'Date 1',
      valueFormatter: params =>
        params?.value ? dayjs(params?.value).utc().format(dateFormat) : '',
      minWidth: 150,

      sortable: false,
      disableColumnMenu: true,
      renderHeaderFilter: () => null,

      type: 'date',
      filterOperators: filterOperators.date,
    },
    {
      field: 'date02',
      headerName: 'Date 2',
      valueFormatter: params =>
        params?.value ? dayjs(params?.value).utc().format(dateFormat) : '',
      minWidth: 150,

      renderHeaderFilter: () => null,
      sortable: false,
      disableColumnMenu: true,
      type: 'date',
      filterOperators: filterOperators.date,
    },
    {
      field: 'date03',
      headerName: 'Date 3',
      valueFormatter: params =>
        params?.value ? dayjs(params?.value).utc().format(dateFormat) : '',
      minWidth: 150,
      renderHeaderFilter: () => null,

      sortable: false,
      disableColumnMenu: true,
      type: 'date',
      filterOperators: filterOperators.date,
    },
    {
      field: 'date04',
      headerName: 'Date 4',
      valueFormatter: params =>
        params?.value ? dayjs(params?.value).utc().format(dateFormat) : '',
      minWidth: 150,
      renderHeaderFilter: () => null,

      sortable: false,
      disableColumnMenu: true,
      type: 'date',
      filterOperators: filterOperators.date,
    },
    {
      field: 'date05',
      headerName: 'Date 5',
      valueFormatter: params =>
        params?.value ? dayjs(params?.value).utc().format(dateFormat) : '',
      minWidth: 150,
      sortable: false,
      renderHeaderFilter: () => null,

      disableColumnMenu: true,
      type: 'date',
      filterOperators: filterOperators.date,
    },
    {
      field: 'date06',
      headerName: 'Date 6',
      valueFormatter: params =>
        params?.value ? dayjs(params?.value).utc().format(dateFormat) : '',
      minWidth: 150,
      sortable: false,
      renderHeaderFilter: () => null,

      disableColumnMenu: true,
      type: 'date',
      filterOperators: filterOperators.date,
    },
    {
      field: 'date07',
      headerName: 'Date 7',
      valueFormatter: params =>
        params?.value ? dayjs(params?.value).utc().format(dateFormat) : '',
      minWidth: 150,
      sortable: false,
      renderHeaderFilter: () => null,

      disableColumnMenu: true,
      type: 'date',
      filterOperators: filterOperators.date,
    },
    {
      field: 'date08',
      headerName: 'Date 8',
      valueFormatter: params =>
        params?.value ? dayjs(params?.value).utc().format(dateFormat) : '',
      minWidth: 150,
      sortable: false,
      renderHeaderFilter: () => null,

      disableColumnMenu: true,
      type: 'date',
      filterOperators: filterOperators.date,
    },
    {
      field: 'date09',
      headerName: 'Date 9',
      valueFormatter: params =>
        params?.value ? dayjs(params?.value).utc().format(dateFormat) : '',
      minWidth: 150,
      sortable: false,
      renderHeaderFilter: () => null,

      disableColumnMenu: true,
      type: 'date',
      filterOperators: filterOperators.date,
    },
    {
      field: 'date10',
      headerName: 'Date 10',
      valueFormatter: params =>
        params?.value ? dayjs(params?.value).utc().format(dateFormat) : '',
      minWidth: 150,
      sortable: false,
      renderHeaderFilter: () => null,

      disableColumnMenu: true,
      type: 'date',
      filterOperators: filterOperators.date,
    },
  ];
};
