import dayjs from 'dayjs';
import {constants} from '../../../../constants/common';
import {filterOperators} from '../../../../lib/commonTableHelpers';
import {CustomerMenu} from '../../../common/CommonRowComponents';

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

export const scheduledPaymentsColumns = [
  {
    field: 'pendPmtId',
    headerName: 'OnlinePmtID',
    sortable: true,
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'statusDesc',
    headerName: 'Status',
    sortable: true,
    minWidth: 200,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'companyId',
    headerName: 'Company ID',
    sortable: true,
    minWidth: 200,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'custId',
    headerName: 'Cust ID',
    sortable: true,
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'custName',
    headerName: 'Cust Name',
    sortable: true,
    renderCell: cellValues => {
      return <CustomerMenu values={cellValues} />;
    },
    minWidth: 180,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'paymentAmt',
    headerName: 'Payment Amount',
    sortable: true,
    minWidth: 120,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'currId',
    headerName: 'Curr ID',
    sortable: true,
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'processByDate',
    headerName: 'Due Date',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 150,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'note',
    headerName: 'Notes',
    sortable: true,
    minWidth: 170,
    type: 'string',
    filterOperators: filterOperators.string,
  },
];
