import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  GridColDef,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridLogicOperator,
  GridPaginationModel,
  GridSortModel,
} from '@mui/x-data-grid-pro';
import {Outlet} from 'react-router-dom';
import {
  DataGrid,
  FilterPopoverProvider,
  QueryReturnValue,
  convertMUIToQueryBuilder,
  generateJsonAndSql,
  generateJsonFromSql,
} from '../../../ui/data';
import {getUserData} from '../../../store/actions/userActions';
import {
  cleanMailboxData,
  getAllMailbox,
  mailboxExportToExcel,
  updateMailboxAllRowsSelected,
  updateMailBoxViewSettingObject,
} from '../../../store/actions/mailboxActions';
import {
  applyDatatableSettings,
  convertAndAppendExistingFilters,
  getGridColumnsSettings,
  getSelectedRowsByKey,
  hasFilterValue,
  updateColumnSettings,
} from '../../../lib/commonTableHelpers';
import {updateAssignEmailStatus} from '../../../store/actions/emailActions';
import MailBoxToolbar from './MailBoxToolbar';
import {mailboxColumns} from './columns';
import {ViewSettings} from './ViewSettings';
import {setCurrentRoute} from '../../../store/actions/generalActions';
import {constants, gridModeTypes} from '../../../constants/common';
import {MAX_EXCEL_DOWNLOAD, PAGE_SIZE} from '../../../utils/constants';
import {calculateColumnWidth} from '../../header/helper';
import {initialQuery} from '../../../ui/data/query-builder/queryHelper';
import ViewSettingsModal from '../../common/view-settings/ViewSettingsModal';
import {
  ColumnSetting,
  IViewSettings,
} from '../../common/view-settings/interface';
import {useComponentMountStatus} from '../../../hooks/useComponentMountStatus';
import {shouldResetFilterModel} from '../../../utils/filterUtils';

export default function MailBox({isMailNotFiled}: {isMailNotFiled: boolean}) {
  const dispatch = useDispatch<any>();
  const [groupKey] = useState(getUserData()?.groupKey);
  const [refresh, setRefresh] = useState(false);
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({});
  const {toastRef: toast} = useSelector((store: any) => store.generalReducer);
  const assignedEmail = useSelector(
    (store: any) => store.emailReducer?.assignedEmail,
  );
  const [bestFit, setBestFit] = useState<boolean>(false);
  const [bestFitColumns, setBestFitColumns] = useState<GridColDef[]>([]);
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    logicOperator: GridLogicOperator.And,
    items: [],
  });
  const [customFilterSqlValue, setCustomFilterSqlValue] = useState<
    string | null
  >(null);
  const [customFilterJsonValue, setCustomFilterJsonValue] =
    useState<QueryReturnValue['json']>();
  const [showViewSettingsPopup, setShowViewSettingsPopup] =
    useState<boolean>(false);

  const [skip, setSkip] = useState(0);
  const [sortColumn, setSortColumn] = useState<GridSortModel>([]);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: PAGE_SIZE,
  });
  const [settingsKey, setSettingsKey] = useState<string | null>(null);

  const isComponentMounted = useComponentMountStatus(cleanMailboxData);

  const {
    mailboxData,
    mailboxCount,
    mailboxLoading,
    mailboxSelectedRows,
    deletedMails,
    viewSettings,
    userKey,
  } = useSelector((store: any) => store.mailboxReducer);

  const getAllMails = ({
    sortQuery = [],
    pageSizeParam = PAGE_SIZE,
    skipParam = 0,
    filterQuery = undefined,
    sqlQuery = null,
  }: {
    sortQuery: any[];
    pageSizeParam: number;
    skipParam: number;
    filterQuery: QueryReturnValue['json'] | undefined;
    sqlQuery: string | null;
  }) => {
    const options = {
      groupKey,
      sortQuery,
      skip: skipParam,
      pageSize: pageSizeParam,
      filterQuery,
      sqlQuery,
      settingKey: viewSettings?.settingsKey,
    };
    if (userKey && viewSettings?.settingsKey) {
      // @ts-ignore
      dispatch(updateMailboxAllRowsSelected([]));
      // @ts-ignore
      dispatch(getAllMailbox(options));
      if (refresh) {
        setRefresh(false);
        dispatch(updateAssignEmailStatus(false));
      }
    }
    setBestFit(false);
  };

  // Reset filter model
  const resetFilterModel = () => {
    setFilterModel({
      logicOperator: GridLogicOperator.And,
      items: [],
    });
  };

  // reset pagination
  const resetPagination = () => {
    setPaginationModel({
      pageSize: paginationModel.pageSize,
      page: 0,
    });
  };
  const handleGetMailbox = (
    filterQuery: QueryReturnValue['json'],
    sqlQuery: string | null,
  ) => {
    getAllMails({
      sortQuery: sortColumn,
      pageSizeParam: paginationModel.pageSize,
      skipParam: 0,
      filterQuery,
      sqlQuery,
    });
    resetPagination();
  };

  /** Use Effect to fetch list of mails when filter,
   * sort and other properties are selected */
  useEffect(() => {
    if (settingsKey && typeof settingsKey === 'string' && userKey) {
      getAllMails({
        sortQuery: sortColumn,
        pageSizeParam: paginationModel.pageSize,
        skipParam: 0,
        filterQuery: customFilterJsonValue,
        sqlQuery: customFilterSqlValue,
      });
      resetFilterModel();
      resetPagination();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userKey, deletedMails, settingsKey]);

  useEffect(() => {
    dispatch(setCurrentRoute(constants.MAIL_NOT_FILED_ROUTE));
    if (assignedEmail) {
      setRefresh(true);
      // @ts-ignore
      dispatch(updateMailboxAllRowsSelected([]));
      getAllMails({
        sortQuery: sortColumn,
        pageSizeParam: paginationModel.pageSize,
        skipParam: 0,
        filterQuery: customFilterJsonValue,
        sqlQuery: customFilterSqlValue,
      });
      resetPagination();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignedEmail]);

  // TODO: To be removed once API is updated
  const updateBodyTextColumn = () => {
    const updatedViewSettings = {...viewSettings};
    updatedViewSettings.columnSetting = updatedViewSettings.columnSetting.map(
      (item: {dataField: string; columnDataType: string}) => {
        if (item.dataField === 'bodyText') {
          return {...item, columnDataType: 'String'};
        }
        return item;
      },
    );
    return updatedViewSettings;
  };

  const updateTableOnColumnSettingsChange = (
    updatedColumns: ColumnSetting[],
    settingKey: string,
  ) => {
    if (settingKey === viewSettings?.settingsKey) {
      updateColumnSettings(
        updatedColumns,
        columns,
        setColumns,
        setColumnVisibilityModel,
      );
    }
  };

  /** Use effect to update and set columns based on view settings response */
  useEffect(() => {
    if (viewSettings && viewSettings.settingsKey && isComponentMounted) {
      const updatedViewSettingsWithBodyText = updateBodyTextColumn();
      applyDatatableSettings(
        updatedViewSettingsWithBodyText,
        columns,
        mailboxColumns,
        undefined,
        setColumns,
        setColumnVisibilityModel,
        setSortColumn,
      );
      const jsonData =
        viewSettings?.filter?.length > 0
          ? generateJsonFromSql(viewSettings?.filter)
          : initialQuery;

      //  handleGetMailbox(jsonData, viewSettings?.filter);
      setSettingsKey(viewSettings.settingsKey);
      setCustomFilterSqlValue(viewSettings?.filter);
      setCustomFilterJsonValue(jsonData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewSettings?.settingsKey]);

  useEffect(() => {
    if (viewSettings?.settingsKey && viewSettings?.viewName) {
      const updatedViewSettingsWithBodyText = updateBodyTextColumn();
      applyDatatableSettings(
        updatedViewSettingsWithBodyText,
        columns,
        mailboxColumns,
        undefined,
        setColumns,
        setColumnVisibilityModel,
        setSortColumn,
      );
    }
  }, [viewSettings?.viewName]);

  /** Mail box grid props method */
  /**
   * onPageChange is prop set for mail box grid to handle pagination
   * @param args GridPaginationModel object which has page which starts from 0 and skip properties
   */
  const onPageChange = (args: GridPaginationModel) => {
    const pageSkip =
      args.pageSize !== paginationModel.pageSize
        ? 0
        : (args.page + 1) * args.pageSize - args.pageSize;
    setPaginationModel({
      pageSize: args.pageSize,
      page: args.pageSize !== paginationModel.pageSize ? 0 : args.page,
    });
    setSkip(pageSkip);
    getAllMails({
      sortQuery: sortColumn,
      pageSizeParam: args.pageSize,
      skipParam: pageSkip,
      filterQuery: customFilterJsonValue,
      sqlQuery: customFilterSqlValue,
    });
  };

  /**
   * onSortChange is prop set for mail box grid to sorting
   * @param args GridPaginationModel array of object which field and sort properties
   */
  const onSortChange = (args: GridSortModel) => {
    setSortColumn(args);
    getAllMails({
      sortQuery: args,
      pageSizeParam: paginationModel.pageSize,
      skipParam: skip,
      filterQuery: customFilterJsonValue,
      sqlQuery: customFilterSqlValue,
    });
  };

  /**
   * onFilterChange is prop set for mail box grid to filter
   * @param args GridFilterModel array of object which field, value and operator properties
   */
  const onFilterChange = (args: GridFilterModel) => {
    const queryBuilderQuery = convertMUIToQueryBuilder(args);
    const valueToConvert = convertAndAppendExistingFilters(
      {...customFilterJsonValue},
      queryBuilderQuery,
      filterModel,
    );
    const {json, sql} = generateJsonAndSql(valueToConvert);
    setCustomFilterSqlValue(json.rules.length > 0 ? sql : null);
    setCustomFilterJsonValue(json);
    setFilterModel(args);
    if (hasFilterValue(args)) {
      handleGetMailbox(json, json.rules.length > 0 ? sql : null);
    }
  };
  const onQueryFilterApply = (data: QueryReturnValue) => {
    // if filter is empty or nothing then reset filter model
    if (shouldResetFilterModel(data)) {
      resetFilterModel();
    }
    const {json, sql} = data;
    setCustomFilterSqlValue(sql);
    setCustomFilterJsonValue(json);
    handleGetMailbox(json, json.rules.length > 0 ? sql : null);
  };

  /**
   * onColumnRowSelect Update state on checkbox selection
   * @param newSelectionModel is selected row object
   */
  const onColumnRowSelect = (newSelectionModel: any) => {
    const selectedRowsObject = getSelectedRowsByKey(
      newSelectionModel,
      mailboxData,
      'uniqueKey',
    );
    // @ts-ignore
    dispatch(updateMailboxAllRowsSelected(selectedRowsObject));
  };

  /** All Toolbar methods */

  /**
   * On Refresh refetch list of mails
   */
  const onRefresh = () => {
    getAllMails({
      sortQuery: sortColumn,
      pageSizeParam: paginationModel.pageSize,
      skipParam: skip,
      filterQuery: customFilterJsonValue,
      sqlQuery: customFilterSqlValue,
    });
  };

  /**
   * Download excel report
   */
  const exportToExcel = () => {
    if (Number(mailboxCount) > MAX_EXCEL_DOWNLOAD) {
      toast?.current.show({
        severity: 'warn',
        summary: '',
        detail: `Maximum of ${MAX_EXCEL_DOWNLOAD} records will be downloaded`,
      });
    }
    dispatch(
      // @ts-ignore
      mailboxExportToExcel({
        groupKey,
        pageSize: paginationModel.pageSize,
        skip,
        filterQuery: customFilterJsonValue,
        sqlQuery: customFilterSqlValue,
      }),
    );
  };

  const handleOnDelete = (value: boolean) => {
    setRefresh(value);
  };

  const onViewSettings = () => {
    setShowViewSettingsPopup(true);
  };

  const handleCloseViewSettingsPopup = () => {
    setShowViewSettingsPopup(false);
  };

  const applyBestFit = () => {
    const bestFitColumnsTemp = columns.map(column => {
      const minWidth = calculateColumnWidth(column.field);
      return {
        ...column,
        minWidth,
      };
    });
    setBestFit(!bestFit);
    setBestFitColumns(bestFitColumnsTemp);
  };

  const {columnSettingJson, sortSettingJson} = getGridColumnsSettings(
    viewSettings?.columnSetting,
    columnVisibilityModel,
    sortColumn,
  );

  return (
    <div className="border-bottom-0">
      <Outlet context={{onReload: onRefresh}} />
      <ViewSettingsModal
        viewName="Mailbox_User"
        mode={gridModeTypes.MailNotFiledActivities}
        updateTableOnColumnSettingsChange={updateTableOnColumnSettingsChange}
        handleCloseViewSettingsPopup={handleCloseViewSettingsPopup}
        customFilterSqlValue={customFilterSqlValue || ''}
        sortSettingJson={JSON.stringify(sortSettingJson) || ''}
        columnSettingJson={JSON.stringify(columnSettingJson) || ''}
        loadViewSetting={(row: IViewSettings) => {
          dispatch(
            updateMailBoxViewSettingObject({
              settingId: row.settingsKey,
              viewSettings: row,
            }),
          );
        }}
        showViewSettingsPopup={showViewSettingsPopup}
      />
      <ViewSettings
        handleOnDelete={handleOnDelete}
        isMailNotFiled={isMailNotFiled}
      />

      <FilterPopoverProvider>
        <DataGrid
          height={650}
          disableVirtualization
          columns={bestFit ? bestFitColumns : columns}
          rows={mailboxData}
          loading={mailboxLoading}
          checkboxSelection
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityChange={data => setColumnVisibilityModel(data)}
          rowCount={mailboxCount}
          onPageChange={onPageChange}
          onSortChange={onSortChange}
          onFilterChange={onFilterChange}
          sortModel={sortColumn}
          rowsSelected={mailboxSelectedRows.map((item: any) => item.uniqueKey)}
          onColumnRowSelect={onColumnRowSelect}
          CustomToolbar={MailBoxToolbar}
          getRowId={row => row.uniqueKey}
          customToolbarMethods={{
            onRefresh,
            exportToExcel,
            applyBestFit,
            onViewSettings,
          }}
          filterModel={filterModel}
          paginationModel={paginationModel}
          showCustomFilters
          customFilterSqlValue={customFilterSqlValue}
          onQueryFilterApply={data => onQueryFilterApply(data)}
        />
      </FilterPopoverProvider>
    </div>
  );
}
