import dayjs from 'dayjs';
import {constants} from '../../../../constants/common';
import {Checkbox} from '../../../../ui/inputs';
import {CheckUnCheckFilter} from '../../../common/filter/FilterComponents';
import {filterOperators} from '../../../../lib/commonTableHelpers';
import {CustomerMenu} from '../../../common/CommonRowComponents';
import {
  ActivityMenu,
  SingleLink,
} from '../../../messages/communication/RowComponents';
import {ViewTaggedInvoices} from './RowComponents';

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

export const expectedPaymentColumns = [
  {
    field: 'activity',
    headerName: 'Activity',
    sortable: false,
    mandatory: true,
    renderCell: cellValues => {
      return <ActivityMenu values={cellValues} />;
    },
    renderHeaderFilter: () => null,
    minWidth: 80,
  },
  {
    field: 'custId',
    headerName: 'Cust ID',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'custName',
    headerName: 'Cust Name',
    sortable: true,
    renderCell: cellValues => {
      return <CustomerMenu values={cellValues} />;
    },
    minWidth: 180,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'description',
    headerName: 'Issue',
    sortable: true,
    renderCell: cellValues => {
      return <SingleLink values={cellValues} />;
    },
    minWidth: 180,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'expectPayDate',
    headerName: 'ExpectDate',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 120,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'expectPayAmount',
    headerName: 'ExpectAmt',
    sortable: true,
    valueFormatter: params =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 2,
      }),
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'promise',
    headerName: 'Promise',
    sortable: true,
    renderCell: cellValues => {
      return <Checkbox disabled checked={cellValues.row.promise} />;
    },
    minWidth: 50,
    renderHeaderFilter: params => <CheckUnCheckFilter {...params} />,
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
  {
    field: 'promiseBroken',
    headerName: 'Broken',
    sortable: true,
    renderCell: cellValues => {
      return <Checkbox disabled checked={cellValues.row.promiseBroken} />;
    },
    minWidth: 50,
    renderHeaderFilter: params => <CheckUnCheckFilter {...params} />,
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
  {
    field: 'actualPayDate',
    headerName: 'ActualDate',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 120,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'actualPayAmount',
    headerName: 'ActualAmt',
    sortable: true,
    valueFormatter: params =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 2,
      }),
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'userId',
    headerName: 'UserID',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'notes',
    headerName: 'Notes',
    sortable: true,
    minWidth: 180,
    renderCell: cellValues => {
      const {row} = cellValues;
      return (
        <span
          title={row.notes}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{__html: row?.notes}}
        />
      );
    },
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'tagged',
    headerName: 'Tagged invoices',
    sortable: false,
    renderCell: cellValues => {
      return <ViewTaggedInvoices values={cellValues} />;
    },
    renderHeaderFilter: () => null,
    minWidth: 80,
  },
  {
    field: 'issueComplete',
    headerName: 'IssueComplete',
    sortable: true,
    renderCell: cellValues => {
      return <Checkbox disabled checked={cellValues.row.issueComplete} />;
    },
    minWidth: 50,
    renderHeaderFilter: params => <CheckUnCheckFilter {...params} />,
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
  {
    field: 'activityComplete',
    headerName: 'ActivityComplete',
    sortable: true,
    renderCell: cellValues => {
      return <Checkbox disabled checked={cellValues.row.activityComplete} />;
    },
    minWidth: 50,
    renderHeaderFilter: params => <CheckUnCheckFilter {...params} />,
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
  {
    field: 'dateEntered',
    headerName: 'Activity Date',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_TIME_FORMAT)
        : '',
    minWidth: 120,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'creditClassId',
    headerName: 'CreditClassID',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'companyId',
    headerName: 'CompanyID',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'expectPayDateDays',
    headerName: 'ExpectPayDays',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'actualPayDateDays',
    headerName: 'ActualPayDays',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'parentId',
    headerName: 'ParentID',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
];
