/* eslint-disable no-nested-ternary */
import dayjs from 'dayjs';
import {constants} from '../../../constants/common';
import {ReAssignMail, ReadUnreadMessage} from './RowComponents';
import {filterOperators} from '../../../lib/commonTableHelpers';
import {ViewEmail} from '../../common/RowComponents';

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

export const mailboxColumns = [
  {
    field: 'read/Unread',
    headerName: 'Read/Unread',
    sortable: false,
    renderCell: cellValues => {
      return <ReadUnreadMessage values={cellValues} />;
    },
    minWidth: 80,
    type: 'actions',
  },
  {
    field: 'view',
    headerName: 'View',
    sortable: false,
    renderCell: cellValues => {
      return <ViewEmail values={cellValues} />;
    },
    minWidth: 60,
    type: 'actions',
  },
  {
    field: 'reassign',
    headerName: 'Reassign',
    sortable: false,
    renderCell: cellValues => {
      return <ReAssignMail values={cellValues} />;
    },
    minWidth: 60,
    type: 'actions',
  },
  {
    field: 'fromAddress',
    headerName: 'FromAddress',
    sortable: true,
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'sentDate',
    headerName: 'SentDate',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).format(constants.DATE_TIME_FORMAT)
        : '',
    minWidth: 150,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'toAddress',
    headerName: 'ToAddress',
    sortable: true,
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'ccAddress',
    headerName: 'CcAddress',
    sortable: true,
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'bccAddress',
    headerName: 'BccAddress',
    sortable: true,
    minWidth: 100,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'subject',
    headerName: 'Subject',
    sortable: true,
    minWidth: 100,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'attachment',
    headerName: 'Attachment',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'userId',
    headerName: 'UserID',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'custId',
    headerName: 'CustID',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'bodyText',
    headerName: 'BodyText',
    sortable: true,
    minWidth: 180,
    type: 'string', // API response type is datetime
    filterOperators: filterOperators.string,
  },
  {
    field: 'custName',
    headerName: 'CustName',
    sortable: true,
    minWidth: 100,
    type: 'string',
    filterOperators: filterOperators.string,
  },
];
