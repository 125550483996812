import {useEffect, useState} from 'react';
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {useDispatch} from 'react-redux';
import {Button, Checkbox, TextField} from '../../../ui/inputs';
import {constants} from '../../../constants/common';
import {updateColumnSettingsBySettingsKey} from '../../../services/viewSettings';
import {Snackbar} from '../../../ui/feedback';
import {ISnackbarProps} from '../../../ui/feedback/snackbar/Snackbar';
import {Typography} from '../../../ui/displays';
import {Box} from '../../../ui/layouts';
import {theme} from '../../../ui/theme/theme';
import Backdrop from '../../../ui/utils/backdrop/Backdrop';
import {updateViewSettingsColumn} from '../../../store/actions/viewSettingsActions';
import {ColumnSetting} from './interface';

const RenderViewSettingsColumns = ({
  settingsKey,
  columnSettings,
  onClose,
  updateParentSnackbarObj,
  updateTableOnColumnSettingsChange,
  viewName,
}: {
  settingsKey: string;
  columnSettings: any;
  onClose: () => void;
  updateParentSnackbarObj: (snackbarObj: ISnackbarProps) => void;
  updateTableOnColumnSettingsChange: (
    updatedColumns: ColumnSetting[],
    settingKey: string,
  ) => void;
  viewName: string;
}) => {
  const [tableData, setTableData] = useState<ColumnSetting[]>(columnSettings);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [snackbarObj, setSnackbarObj] = useState<ISnackbarProps>({
    message: '',
    onClose: () => {},
    open: false,
    type: 'info',
    title: '',
  });

  useEffect(() => {
    setTableData(columnSettings);
  }, []);

  const updateSnackbarObj = ({type, title, open, message}: ISnackbarProps) => {
    setSnackbarObj({type, title, open, message, onClose: () => {}});
  };
  const handleCloseSnackbar = () => {
    setSnackbarObj(prev => ({...prev, open: false}));
  };

  const handleCheckChange =
    (index: number) => (event: {target: {checked: boolean}}) => {
      const newItems = [...tableData];
      newItems[index].isDisplay = event.target.checked;
      setTableData(newItems);
    };

  const handleTextChange =
    (index: number) => (event: {target: {value: string}}) => {
      const newItems = [...tableData];
      newItems[index].displayText = event.target.value;
      setTableData(newItems);
    };

  const saveColumnSettings = () => {
    const payload = {
      settingsKey,
      columnSettingsJson: JSON.stringify(tableData),
    };
    setLoading(true);
    updateColumnSettingsBySettingsKey(payload)
      .then(() => {
        onClose();
        dispatch(
          updateViewSettingsColumn({
            settingsKey,
            columnSetting: tableData,
            viewName,
          }),
        );
        updateTableOnColumnSettingsChange(tableData, settingsKey);
        setLoading(false);
        updateParentSnackbarObj({
          message: constants.COLUMN_SETTINGS_UPDATED_SUCCESSFULLY,
          open: true,
          type: 'success',
          title: constants.SUCCESS,
        });
      })
      .catch(() => {
        setLoading(false);
        updateSnackbarObj({
          message: constants.UNABLE_TO_UPDATE_COLUMN_SETTINGS,
          open: true,
          type: 'error',
          title: constants.ERROR,
        });
      });
  };

  return (
    <>
      <Snackbar
        open={snackbarObj.open}
        onClose={handleCloseSnackbar}
        message={snackbarObj.message}
        title={snackbarObj.title}
        type={snackbarObj.type}
      />
      <Box marginBottom="8px">
        <Button variant="contained" onClick={saveColumnSettings}>
          {constants.SAVE}
        </Button>
      </Box>
      <TableContainer component={Paper} sx={{maxHeight: '550px'}}>
        <Table size="small">
          <TableHead sx={{backgroundColor: theme.palette.grey[200]}}>
            <TableRow>
              <TableCell>
                <Typography variant="body1">Column Name</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1">Display</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1">Header Text</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((item, index) => (
              <TableRow key={item.dataField}>
                <TableCell padding="normal">
                  <Typography variant="body1">{item.dataField}</Typography>
                </TableCell>
                <TableCell padding="normal">
                  <Checkbox
                    checked={item.isDisplay}
                    onChange={handleCheckChange(index)}
                  />
                </TableCell>
                <TableCell padding="normal">
                  <TextField
                    size="small"
                    margin="none"
                    value={item.displayText}
                    onChange={handleTextChange(index)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Backdrop
        sx={{zIndex: _theme => _theme.zIndex.drawer + 1}}
        style={{backgroundColor: 'transparent'}}
        isLoading={loading}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default RenderViewSettingsColumns;
