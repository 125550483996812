import DOMPurify from 'dompurify';
import {constants} from '../../constants/common';
import TextField from '../../ui/inputs/textfield/TextField';
import {Button} from '../../ui/inputs';
import MainLoader from '../common/MainLoader';
import {downloadAttachment} from '../../services/mailbox';

interface IEmailTemplateForm {
  attachments: any;
  message: string;
  mailKey: any;
  loading: boolean;
  from: string;
  to: string;
  cc: string;
  subject: string;
}

function RenderEmailTemplateForm({
  attachments,
  message,
  mailKey,
  loading,
  from,
  to,
  cc,
  subject,
}: IEmailTemplateForm) {
  const onDownloadAttachment = (att: any) => {
    downloadAttachment(mailKey, att);
  };

  return (
    <form className="mt-1">
      <TextField
        key={constants.FROM.toLocaleLowerCase()}
        label={constants.FROM}
        disabled
        id={constants.FROM.toLocaleLowerCase()}
        value={from}
      />
      <TextField
        key={constants.TO.toLocaleLowerCase()}
        label={constants.TO}
        disabled
        id={constants.TO.toLocaleLowerCase()}
        value={to}
      />
      <TextField
        key={constants.CC.toLocaleLowerCase()}
        label={constants.CC}
        disabled
        id={constants.CC.toLocaleLowerCase()}
        value={cc}
      />
      <TextField
        key={constants.SUBJECT.toLocaleLowerCase()}
        label={constants.SUBJECT}
        disabled
        id={constants.SUBJECT.toLocaleLowerCase()}
        value={subject}
      />
      <div className="mt-3 d-flex align-items-center justify-content-start">
        <label htmlFor="attachments">{constants.ATTACHMENTS}</label>
        <div className="ps-2 py-2 d-flex flex-wrap">
          {attachments?.length ? (
            attachments.map((attachment: any) => {
              const fileName = attachment?.fileName;
              return (
                <Button
                  sx={{textTransform: 'none'}}
                  key={fileName}
                  variant="text"
                  className="m-2"
                  onClick={() => onDownloadAttachment(attachment)}
                >
                  {fileName};
                </Button>
              );
            })
          ) : (
            <p className="text-muted my-2 text-center">
              {constants.NO_ATTACHMENTS_FOUND}
            </p>
          )}
        </div>
      </div>
      <div className="mt-3 border-1">
        <div
          className={`mt-2 p-3 text-editor-email-body ${
            loading
              ? 'd-flex justify-content-center align-items-center h-300-px'
              : 'h-480-px'
          } `}
        >
          {loading ? (
            <MainLoader fullScreen className="justify-content-center" />
          ) : (
            <div
              id="mailContent"
              // No fix required as we are using DOMPurify lib
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  message?.replace(/href/g, "target='_blank' href"),
                ),
              }}
            />
          )}
        </div>
      </div>
    </form>
  );
}

export default RenderEmailTemplateForm;
