import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {HiInformationCircle} from 'react-icons/hi';
import {GridPaginationModel} from '@mui/x-data-grid';
import {isEmpty} from '../../../lib/utils';
import {constants} from '../../../constants/common';
import {updateTableFilterDisplayType} from '../../../store/actions/generalActions';
import {activityViewContactsColumns} from './activityViewContactsColumns';
import {
  viewAllContacts,
  addContactForNote,
} from '../../../store/actions/activitiesActions';
import {getUserData} from '../../../store/actions/userActions';

import {makeAPhoneCall} from '../../../services/activities';
import {NotificationDialog} from '../NotificationDialog';
import ErrorMessageTemplate from '../../common/ErrorMessageTemplate';
import {useQueryKeys} from '../../../hooks/useQueryKeys';
import TextField from '../../../ui/inputs/textfield/TextField';
import {Button} from '../../../ui/inputs';
import {Dialog} from '../../../ui';
import {DataGrid} from '../../../ui/data';
import {IContact} from '../../../interfaces/view-contacts/contacts';
import {getSelectedRowsByKey} from '../../../lib/commonTableHelpers';
import {PAGE_SIZE} from '../../../utils/constants';

function ActivityViewContactsTemplate({
  handleShowActivityViewContactsModal = () => {},
  activityRef,
  updateActivityInfoState = () => {},
}: {
  handleShowActivityViewContactsModal: (val: boolean) => void;
  activityRef: any;
  updateActivityInfoState: (val: any) => void;
}) {
  const dispatch = useDispatch<any>();

  const {
    contactsCount,
    fetchingContacts: contactsLoading,
    contacts,
  } = useSelector((store: any) => store.activitiesReducer);
  const {customerTask, customerTaskActivity} = useSelector(
    (store: any) => store.activitiesReducer.activityInfo,
  );
  const {custKey, workSessionID} = customerTask;
  const {taskKey} = customerTaskActivity;
  const {groupKey} = getUserData();
  const {userKey} = useQueryKeys();
  const [searchQuery, setSearchQuery] = useState('');
  const [showNotification, setShowNotification] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessageToDisplay, setErrorMessageToDisplay] = useState('');
  const [skip, setSkip] = useState(0);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: PAGE_SIZE,
  });
  const [selectedContacts, setSelectedContacts] = useState<any[]>([]);
  const [filterQuery, setFilterQuery] = useState('');

  const getContacts = ({
    pageSizeParam = PAGE_SIZE,
    skipParam = 0,
    filterQueryParam = '',
  }: {
    pageSizeParam: number;
    skipParam: number;
    filterQueryParam: string;
  }) => {
    dispatch(
      viewAllContacts({
        groupKey,
        custKey,
        pageSize: pageSizeParam,
        skip: skipParam,
        filterQuery: filterQueryParam,
      }),
    );
  };

  const resetPaginationAndRowSelection = () => {
    setPaginationModel({
      pageSize: paginationModel.pageSize,
      page: 0,
    });
    setSkip(0);
    dispatch(addContactForNote([]));
  };

  useEffect(() => {
    dispatch(updateTableFilterDisplayType('row'));
    getContacts({
      pageSizeParam: paginationModel.pageSize,
      skipParam: skip,
      filterQueryParam: filterQuery,
    });
    return () => {
      resetPaginationAndRowSelection();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearFilter = () => {
    if (!isEmpty(searchQuery)) {
      setFilterQuery('');
      setSearchQuery('');
      setPaginationModel({page: 0, pageSize: 10});
      getContacts({
        pageSizeParam: 10,
        skipParam: 0,
        filterQueryParam: '',
      });
    }
  };

  const applySearchQuery = () => {
    if (!isEmpty(searchQuery)) {
      const filterQueryTemp = `emailAddr like "${searchQuery}"`;
      getContacts({
        pageSizeParam: paginationModel.pageSize,
        skipParam: skip,
        filterQueryParam: filterQueryTemp,
      });
      setFilterQuery(filterQueryTemp);
    }
  };

  /**
   * onColumnRowSelect Update state on checkbox selection
   * @param newSelectionModel is selected row object
   */
  const onColumnRowSelect = (newSelectionModel: any) => {
    setErrorMessageToDisplay('');

    const selectedRowsObject = getSelectedRowsByKey(
      newSelectionModel,
      contacts,
      'id',
    );
    setSelectedContacts(selectedRowsObject);
  };

  const addContactToNote = () => {
    if (selectedContacts.length === 0) {
      setErrorMessageToDisplay(
        constants.PLEASE_SELECT_RECORDS_TO_ADD_CONTACT_TO_NOTES,
      );
    } else {
      if (selectedContacts.length) {
        const contactDetail = selectedContacts.map(contact => {
          return `${contact.contactName ? contact.contactName : ''} ${
            contact.phone ? `: ${contact.phone}` : ''
          }`;
        });
        let tempActivityObj = {...activityRef.current};
        tempActivityObj = {
          ...tempActivityObj,
          customerTaskActivity: {
            ...tempActivityObj.customerTaskActivity,
            notes: `${
              !isEmpty(tempActivityObj?.customerTaskActivity?.notes)
                ? tempActivityObj?.customerTaskActivity?.notes
                : ''
            }${
              !tempActivityObj?.customerTaskActivity?.notes?.includes(
                'Phone call to:',
              )
                ? '\nPhone call to:\n'
                : ''
            }${contactDetail.join('\n')}`,
          },
        };
        updateActivityInfoState(tempActivityObj);
      }
      dispatch(addContactForNote(selectedContacts));
      handleShowActivityViewContactsModal(false);
    }
  };

  const makeACall = ({contactKey}: IContact) => {
    setShowNotification(true);
    const options = {
      userKey,
      groupKey,
      customerKey: custKey,
      taskKey: taskKey ?? -1,
      contactKey,
      callType: constants.CONNECT_TO_CUSTOMER,
      workSessionId: workSessionID,
    };

    makeAPhoneCall(options)
      .then(() => {
        setShowErrorMessage(false);
      })
      .catch(res => {
        setErrorMessage(res.response.data);
        setShowErrorMessage(true);
      });
  };

  const onPageChange = (args: GridPaginationModel) => {
    const pageSkip =
      args.pageSize !== paginationModel.pageSize
        ? 0
        : (args.page + 1) * args.pageSize - args.pageSize;
    setPaginationModel({
      pageSize: args.pageSize,
      page: args.pageSize !== paginationModel.pageSize ? 0 : args.page,
    });
    setSkip(pageSkip);
    getContacts({
      pageSizeParam: args.pageSize,
      skipParam: pageSkip,
      filterQueryParam: filterQuery,
    });
  };

  return (
    <>
      <div className="d-flex align-items-center my-2 justify-content-between flex-wrap">
        <div className="d-flex align-items-center">
          <TextField
            fullWidth={false}
            margin="dense"
            value={searchQuery}
            onChange={e => {
              if (!isEmpty(e.target.value)) {
                setSearchQuery(e.target.value);
              } else if (!isEmpty(filterQuery)) {
                clearFilter();
              }
            }}
            sx={{minWidth: '300px'}}
            label={constants.SEARCH_CONTACT_BY_EMAIL}
          />
          <Button
            variant="contained"
            size="medium"
            className="ms-2"
            onClick={applySearchQuery}
          >
            {constants.SEARCH}
          </Button>
          <Button
            className="ms-2"
            variant="contained"
            size="medium"
            onClick={clearFilter}
          >
            {constants.CLEAR}
          </Button>
        </div>
        <Button variant="contained" size="medium" onClick={addContactToNote}>
          {constants.ADD_CONTACTS_TO_NOTE}
        </Button>
      </div>
      {errorMessageToDisplay && (
        <ErrorMessageTemplate message={errorMessageToDisplay} />
      )}
      <DataGrid
        columns={activityViewContactsColumns(makeACall)}
        rows={contacts}
        loading={contactsLoading}
        rowCount={contactsCount}
        rowsSelected={selectedContacts?.map(item => item.id)}
        onColumnRowSelect={onColumnRowSelect}
        onPageChange={onPageChange}
        paginationModel={paginationModel}
        checkboxSelection
        density="compact"
        height={400}
      />
      {!showErrorMessage && showNotification && (
        <NotificationDialog
          errorMessage={constants.PLEASE_WAIT_WHILE_WE_CONNECT_YOU_TO_AN_AGENT}
          onClose={() => setShowNotification(false)}
          headerMessage={<i className="pi pi-lockstep me-2" />}
          iconType={constants.WARNING}
          showOkButton
        />
      )}
      <Dialog
        open={showErrorMessage}
        onClose={() => setShowErrorMessage(false)}
        title={
          <>
            <HiInformationCircle size={24} color="#014e87" className="me-2" />{' '}
            {constants.ERROR}
          </>
        }
        showConfirmButton
        onConfirm={() => {
          setShowErrorMessage(false);
          setShowNotification(false);
        }}
      >
        {errorMessage || constants.SOMETHING_WENT_WRONG}
      </Dialog>
    </>
  );
}
export default ActivityViewContactsTemplate;
