import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {MODE_TYPES, TRAN_TYPE} from '../../../utils/constants';
import {downloadInvoice} from '../../../utils/fileUtils';
import {getUserData} from '../../../store/actions/userActions';
import {constants} from '../../../constants/common';
import {showDialog} from '../../../store/actions/generalActions';
import {OpenDocs} from '../../communicate/open-docs/OpenDocs';
import ActivityDetailTemplate from '../../messages/activities-details/ActivityDetailTemplate';
import ApplicationDetailTemplate from '../../messages/application-details/ApplicationDetailTemplate';
import {checkIsTranTypePayment} from './helper';
import {useShowCustomerDocs} from '../hooks/useShowCustomerDocs';
import {useCustKey} from '../../../hooks/useCustKey';
import TextField from '../../../ui/inputs/textfield/TextField';
import {isEmpty, isNumeric} from '../../../lib/utils';
import {useQueryKeys} from '../../../hooks/useQueryKeys';
import {regExNumeric} from '../../../lib/regex';
import {Dialog} from '../../../ui';
import {IStatementsOfAccount} from './interface';
import {Checkbox} from '../../../ui/inputs';
import InvoiceDetailTemplate from '../../messages/InvoiceDetailTemplate';
import PaymentDetailTemplate from '../../messages/payment-details/PaymentDetailTemplate';
import { createHandleSetShowCustomerDocs } from '../../common/helper';

const ShowCustomerDocs = ({
  invoiceType,
  selectedInvoice,
  showCustomerDocs,
  setShowCustomerDocs,
}: {
  invoiceType: boolean;
  selectedInvoice: any;
  showCustomerDocs: boolean;
  setShowCustomerDocs: (val: boolean) => void;
}) => {
  const groupKey = getUserData()?.groupKey;
  const location = useLocation();
  const {state} = location;
  const {custKey} = useCustKey();
  const {custName, custId} = state || {};
  if (!showCustomerDocs) {
    return null;
  }
  return (
    <OpenDocs
      showCustomerDocs={showCustomerDocs}
      setShowCustomerDocs={setShowCustomerDocs}
      groupKey={groupKey}
      custName={custName}
      custKey={custKey}
      isInvoice={invoiceType}
      selectedInvoice={selectedInvoice}
      custId={custId}
    />
  );
};

export const InvoiceLink = ({values}: {values: any}) => {
  const {row} = values;
  const [showInvoiceDetailsDialog, setShowInvoiceDetailsDialog] =
    useState<boolean>(false);
  const [showPaymentDetailsDialog, setShowPaymentDetailsDialog] =
    useState<boolean>(false);
  const [selectedCustomerPmtKey, setSelectedCustomerPmtKey] =
    useState<string>('');
  const [selectedTranType, setSelectedTranType] = useState<string>('');
  const [selectedInvoiceKey, setSelectedInvoiceKey] = useState<string>('');
  const [selectedRow, setSelectedRow] = useState<IStatementsOfAccount | null>(
    null,
  );
  const handleOnInvoiceClick = (
    tranType: string,
    invcKey: string,
    custPmtKey: string,
  ) => {
    const checkPayment = checkIsTranTypePayment(tranType);
    if (checkPayment) {
      setShowPaymentDetailsDialog(true);
      setSelectedTranType(tranType);
      setSelectedCustomerPmtKey(custPmtKey);
      setSelectedRow(row);
    } else {
      setSelectedInvoiceKey(invcKey);
      setSelectedRow(row);
      setShowInvoiceDetailsDialog(true);
    }
  };

  return (
    <>
      <Dialog
        title={constants.PAYMENT_DETAILS}
        open={
          !!(
            !isEmpty(selectedRow) &&
            selectedCustomerPmtKey &&
            showPaymentDetailsDialog
          )
        }
        onClose={() => {
          setShowPaymentDetailsDialog(false);
          setSelectedCustomerPmtKey('');
          setSelectedTranType('');
          setSelectedRow(null);
        }}
        draggable
        maxWidth="md"
        fullWidth
      >
        <PaymentDetailTemplate
          custPmtKey={selectedCustomerPmtKey}
          tranType={selectedTranType}
        />
      </Dialog>
      <Dialog
        title={constants.INVOICE_DETAILS}
        open={
          !!(
            !isEmpty(selectedRow) &&
            selectedInvoiceKey &&
            showInvoiceDetailsDialog
          )
        }
        onClose={() => {
          setShowInvoiceDetailsDialog(false);
          setSelectedInvoiceKey('');
          setSelectedRow(null);
        }}
        draggable
        maxWidth="lg"
        fullWidth
      >
        <InvoiceDetailTemplate
          invcKey={selectedInvoiceKey}
          selectedRow={selectedRow}
          onReload={() => {}}
          isTaggedInvoice={false}
          setShowInvoiceDetailsDialog={setShowInvoiceDetailsDialog}
        />
      </Dialog>
      <span
        className={
          row.tranType.toLocaleLowerCase() === 'pp'.toLocaleLowerCase()
            ? ``
            : `blueLink cursor-pointer`
        }
        onClick={() => {
          handleOnInvoiceClick(row?.tranType, row?.invcKey, row?.custPmtKey);
        }}
        aria-hidden="true"
      >
        {row?.tranId}
      </span>
    </>
  );
};

export const DisplayColumn = ({values}: {values: any}) => {
  const {row} = values;
  const toast = useSelector((store: any) => store.generalReducer?.toastRef);
  const groupKey = getUserData()?.groupKey;
  const {
    showCustomerDocs,
    setShowCustomerDocs,
    openDocsProp,
    handleSetShowCustomerDocs,
  } = useShowCustomerDocs();
  const onPDFDownloadIconClick = (
    columnInvoiceKey: string,
    message: string = '',
  ) => {
    downloadInvoice(groupKey, columnInvoiceKey, toast, undefined, message);
  };

  return (
    <>
      <span className="mx-1">
        {(row.tranType.toLowerCase() === TRAN_TYPE.IN.toLowerCase() ||
          row.tranType.toLowerCase() === TRAN_TYPE.CM.toLowerCase()) && (
          <>
            {row?.copyPdftoBlob === 99 ? (
              <i
                className="pi pi-pdf p-2 cursor-pointer"
                aria-hidden="true"
                onClick={() =>
                  onPDFDownloadIconClick(
                    row.invcKey,
                    constants.PDF_DOES_NOT_EXISTS_FOR_THIS_INVOICE,
                  )
                }
              />
            ) : (
              <i
                className="pi pi-pdf-warning p-2 cursor-pointer"
                aria-hidden="true"
                onClick={() => {
                  toast?.current.show({
                    severity: 'error',
                    summary: constants.FILE_NOT_FOUND,
                    detail: constants.PDF_DOES_NOT_EXISTS_FOR_THIS_INVOICE,
                  });
                }}
              />
            )}
            <i
              className="pi pi-folder p-2 ms-1 cursor-pointer"
              aria-hidden="true"
              onClick={() => handleSetShowCustomerDocs(row)}
            />
          </>
        )}
      </span>
      <ShowCustomerDocs
        invoiceType={openDocsProp?.invoiceType}
        selectedInvoice={openDocsProp?.selectedInvoice}
        showCustomerDocs={showCustomerDocs}
        setShowCustomerDocs={setShowCustomerDocs}
      />
    </>
  );
};

export const TagCount = ({values}: {values: any}) => {
  const {row} = values;
  const dispatch = useDispatch();
  const {userKey} = useQueryKeys();
  const navigate = useNavigate();
  const location = useLocation();
  const {state, search} = location;

  const {custId, companyId, selectCompanyId} = state || {};

  const showExistingActivity = (rowData: any) => {
    const communicateProps = {
      ...rowData,
      ModeType: MODE_TYPES.enquiry,
      custId,
      companyId,
      selectCompanyId,
      id: rowData?.taskActKey,
    };
    const params = new URLSearchParams(search);
    params.set('activityId', communicateProps.id);

    navigate(`activity?${params.toString()}`, {
      state: {...communicateProps, from: location},
    });
  };

  const showActivitiesDialog = (InvcNumber: string, docKey: string) => {
    dispatch(
      // @ts-ignore
      showDialog({
        width: '70vw',
        title: constants.activityDetailsForInvoice(InvcNumber),
        dialogContent: (
          <ActivityDetailTemplate
            docKey={docKey}
            userKey={userKey}
            // @ts-ignore as ActivityDetailTemplate is jsx file
            showExistingActivity={showExistingActivity}
          />
        ),
      }),
    );
  };
  const onActivitiesClick = (rowData: any) => {
    if (rowData.tagCount > 0) {
      showActivitiesDialog(rowData.tranId, rowData.docKey);
    }
  };
  return (
    <span
      aria-hidden="true"
      className={`${row.tagCount > 0 && 'blueLink cursor-pointer'}`}
      onClick={() => onActivitiesClick(row)}
    >
      {`${row.tagCount} ${constants.ACTIVITIES}`}{' '}
    </span>
  );
};

export const PaymentAmount = ({
  values,
  onPaymentAmountChange,
  setTotalPayment,
}: {
  values: any;
  onPaymentAmountChange: (id: string, val: string) => void;
  setTotalPayment: () => void;
}) => {
  const rowsSelected = values.api.state.rowSelection;
  const {row, id} = values;
  const [value, setValue] = useState(values.row.balance);
  const isSelected = rowsSelected.includes(values.id);
  useEffect(() => {
    if (!isSelected) {
      setValue(Number(values.row.balance).toFixed(2));
    }
  }, [isSelected]);

  const validateAndSetAmount = (val: string) => {
    if (!regExNumeric.test(val)) {
      return;
    }

    if (Number(values.row.balance) < 0) {
      let updatedValue = '0';
      updatedValue =
        val === '-' ||
        Number.isNaN(val) ||
        (Number(val) <= 0 &&
          Math.abs(Number(val)) <= Math.abs(Number(values.row.balance)))
          ? val
          : Number(values.row.balance).toFixed(2);

      setValue(updatedValue);
      onPaymentAmountChange(id, updatedValue);
    } else if (Number(val) <= Number(values.row.balance)) {
      const updatedValue = Number(val) < 0 ? '0' : val;
      setValue(updatedValue);
      onPaymentAmountChange(id, val);
    } else {
      setValue(Number(values.row.balance).toFixed(2));
      onPaymentAmountChange(id, values.row.balance);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // Prevent arrow keys from triggering default behavior (focus change)

    if (event.key === 'a' && event.ctrlKey) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (
      ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(event.key)
    ) {
      event.stopPropagation();
    }
  };
  const isCMOrInvoice =
    isSelected &&
    (row.tranType === TRAN_TYPE.CM ||
      (row.tranType === TRAN_TYPE.IN && row.balance > 0));

  return (
    <TextField
      disabled={
        !isSelected ||
        row.tranType === TRAN_TYPE.CR ||
        row.tranType === TRAN_TYPE.RV
      }
      value={isSelected && isCMOrInvoice ? value : ''}
      onChange={e => validateAndSetAmount(e.target.value)}
      onBlur={() => {
        const updateValue = Number.isNaN(Number(value))
          ? Number(0).toFixed(2)
          : Number(value || 0).toFixed(2);
        setValue(updateValue);
        setTotalPayment();
      }}
      onKeyDown={handleKeyDown}
    />
  );
};

export const ViewApplication = ({values}: {values: any}) => {
  const {row} = values;
  const [showApplicationDialog, setShowApplicationDialog] =
    useState<boolean>(false);
  const [docKey, setDocKey] = useState<string>('');
  const [tranType, setTranType] = useState<string>('');
  const [tranId, setTranId] = useState<string>('');

  const toast = useSelector((store: any) => store.generalReducer?.toastRef);
  const groupKey = getUserData()?.groupKey;

  const {
    showCustomerDocs,
    setShowCustomerDocs,
    openDocsProp,
    handleSetShowCustomerDocs,
  } = useShowCustomerDocs();

  const onPDFDownloadIconClick = (columnInvoiceKey?: string) => {
    downloadInvoice(groupKey, columnInvoiceKey, toast, undefined);
  };
  const showApplicationsDialog = (
    tranTypeParam: string,
    docKeyParam: string,
    tranIdParam: string,
  ) => {
    setShowApplicationDialog(true);
    setTranId(tranIdParam);
    setDocKey(docKeyParam);
    setTranType(tranTypeParam);
  };
  const onApplicationsClick = (rowData: any) => {
    if (rowData.pmtCount > 0) {
      showApplicationsDialog(rowData.tranType, rowData.docKey, rowData.tranId);
    }
  };
  const dialogTitle = checkIsTranTypePayment(tranType)
    ? constants.invoiceApplicationForPayment(tranId)
    : constants.applicationDetailsForInvoice(tranId);
  return (
    <>
      <Dialog
        open={!!tranId && !!docKey && !!tranType && showApplicationDialog}
        title={dialogTitle}
        onClose={() => {
          setTranId('');
          setDocKey('');
          setTranType('');
          setShowApplicationDialog(false);
        }}
        draggable
        maxWidth="md"
        fullWidth
      >
        <ApplicationDetailTemplate
          tranType={tranType}
          custPmtKey={docKey}
          invcKey={docKey}
          onPDFDownloadIconClick={onPDFDownloadIconClick}
          handleSetShowCustomerDocs={() =>
            createHandleSetShowCustomerDocs(handleSetShowCustomerDocs)
          }
          checkIsTranTypePayment={checkIsTranTypePayment}
        />
      </Dialog>
      <span
        aria-hidden="true"
        className={`${row.pmtCount > 0 && 'blueLink cursor-pointer'}`}
        onClick={() => onApplicationsClick(row)}
      >
        {`${row.pmtCount} ${constants.APPLICATIONS}`}{' '}
      </span>
      <ShowCustomerDocs
        invoiceType={openDocsProp?.invoiceType}
        selectedInvoice={openDocsProp?.selectedInvoice}
        showCustomerDocs={showCustomerDocs}
        setShowCustomerDocs={setShowCustomerDocs}
      />
    </>
  );
};

export const OverrideDiscountAmountCheckbox = ({
  values,
  discDate,
  onOverrideDiscountChange,
}: {
  values: any;
  discDate: string;
  onOverrideDiscountChange: (id: string, val: string) => void;
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const currentDate = new Date();
  const parsedDiscDate = new Date(discDate);
  const {id} = values;
  const rowsSelected = values.api.state.rowSelection;
  const isSelected = rowsSelected.includes(values.id);
  const handleCheckboxChange = (e: any) => {
    onOverrideDiscountChange(id, e.target.checked);
    setIsChecked(e.target.checked);
  };
  return (
    <div>
      <Checkbox
        disabled={!isSelected || parsedDiscDate >= currentDate}
        onChange={handleCheckboxChange}
        checked={isChecked}
      />
    </div>
  );
};

export const DiscountAmount = ({
  values,
  onDiscountAmountChange,
}: {
  values: any;
  onDiscountAmountChange: (id: string, val: number) => void;
}) => {
  const {id} = values;
  const [discountAmount, setDiscountAmount] = useState(values.row.discAmt);
  const validateDiscountAmount = (val: any) => {
    if (!isNumeric(val)) {
      return;
    }
    setDiscountAmount(val);
    onDiscountAmountChange(id, val);
  };
  const discountAmountValue = discountAmount > 0 ? discountAmount : '';

  return (
    <TextField
      disabled
      type="number"
      value={discountAmountValue}
      onChange={e => validateDiscountAmount(e.target.value)}
    />
  );
};
