import {actionTypes} from '../constants/actionTypes';

const initialState = {
  reportingGroupLogsData: [],
  reportingGroupLogsViewSettings: null,
  fetchingReportingGroupLogs: false,
  fetchingReportingGroupLogsFailed: false,
  fetchedReportingGroupLogs: false,
  reportingGroupLogsLoading: false,
  reportingGroupLogsCount: 0,
  reportingGroupLogsNextLink: '',
  reportingGroupLogsSelectedRows: [],
  exportingToExcel: false,
  exportedToExcel: false,
  exportingToExcelFailed: false,
  reportingGroupLogsSettingId: null,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const reportingGroupLogsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_REPORTING_GROUP_LOGS:
      return {
        ...state,
        fetchingReportingGroupLogs: true,
        fetchingReportingGroupLogsFailed: false,
        reportingGroupLogsLoading: true,
      };
    case actionTypes.FETCHED_REPORTING_GROUP_LOGS:
      return {
        ...state,
        fetchingReportingGroupLogs: false,
        fetchingReportingGroupLogsFailed: false,
        reportingGroupLogsData: action.reportingGroupLogsData,
        reportingGroupLogsLoading: false,
        reportingGroupLogsCount: action.reportingGroupLogsCount,
        reportingGroupLogsNextLink: action.reportingGroupLogsNextLink,
      };
    case actionTypes.FETCHING_REPORTING_GROUP_LOGS_FAILED:
      return {
        ...state,
        fetchingReportingGroupLogs: false,
        fetchingReportingGroupLogsFailed: true,
        fetchedReportingGroupLogs: true,
        reportingGroupLogsLoading: false,
        reportingGroupLogsData: [],
        reportingGroupLogsCount: 0,
      };
    case actionTypes.EXPORTING_REPORTING_GROUP_LOGS_TO_EXCEL:
      return {
        ...state,
        reportingGroupLogsLoading: true,
        exportingToExcel: true,
        exportedToExcel: false,
        exportingToExcelFailed: false,
      };
    case actionTypes.EXPORTED_REPORTING_GROUP_LOGS_TO_EXCEL:
      return {
        ...state,
        reportingGroupLogsLoading: false,
        exportingToExcel: false,
        exportedToExcel: true,
        exportingToExcelFailed: false,
      };
    case actionTypes.EXPORTING_REPORTING_GROUP_LOGS_TO_EXCEL_FAILED:
      return {
        ...state,
        reportingGroupLogsLoading: false,
        exportingToExcel: false,
        exportedToExcel: false,
        exportingToExcelFailed: true,
      };
    case actionTypes.SET_REPORTING_GROUP_LOGS_VIEW_SETTING_KEY:
      return {
        ...state,
        reportingGroupLogsSettingId: action.reportingGroupLogsSettingId,
      };

    case actionTypes.SET_REPORTING_GROUP_LOGS_DASHBOARD_VIEW_SETTING_OBJECT:
      return {
        ...state,
        reportingGroupLogsViewSettings: {
          ...action.payload.viewSettings,
          sortSetting: null,
        },
        reportingGroupLogsSettingId: action.payload.settingId,
      };

    case actionTypes.CLEAN_UP_REPORTING_GROUP_LOGS:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default reportingGroupLogsReducer;
