import React, {useState} from 'react';
import {TextField} from '../../../ui/inputs';
import {IViewSettings} from './interface';
import {regExNumeric} from '../../../lib/regex';

export const SortOrderColumn = ({
  row,
  onChange,
}: {
  row: IViewSettings;
  onChange: (value: string) => void;
}) => {
  const [value, setValue] = useState<string>(row?.sortOrder?.toString());
  return (
    <TextField
      type="number"
      value={`${value}`}
      InputProps={{
        inputProps: {
          min: 0,
        },
      }}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        const {value: inputValue} = e.target;
        if (inputValue.includes('-') || !regExNumeric.test(inputValue)) {
          return;
        }
        setValue(inputValue);
        onChange(inputValue);
      }}
    />
  );
};
