import {constants} from '../../constants/common';
import {refreshSideNav} from '../../lib/utils';
import {
  deleteMail,
  exportMailbox,
  fetchMailboxDataVTwo,
  fetchTreeData,
  updateMailStatus,
} from '../../services/mailbox';
import {downloadExcel} from '../../utils/fileUtils';
import {actionTypes} from '../constants/actionTypes';
import {updateSideNavRefresh} from './generalActions';
import {getUserData} from './userActions';

export const getAllMailbox = options => (dispatch, getState) => {
  const mailboxState = getState().mailboxReducer;
  const {userKey} = getUserData();
  const {
    groupKey,
    sortQuery,
    skip,
    pageSize,
    filterQuery,
    settingKey,
    sqlQuery,
  } = options;
  dispatch({type: actionTypes.FETCHING_MAILBOX_DATA});
  const filterQueryVal =
    settingKey === mailboxState?.viewSettings?.settingsKey ? filterQuery : '';
  fetchMailboxDataVTwo({
    groupKey,
    userKey,
    sortQuery,
    skip,
    pageSize,
    globalFilterQuery: filterQueryVal,
    mailboxState,
    sqlQuery,
  })
    .then(res => {
      dispatch({
        type: actionTypes.FETCHED_MAILBOX_DATA,
        mailboxData: res.data.value,
        mailboxSkip: mailboxState.mailboxSkip,
        mailboxCount: res.data.count,
        mailboxNextLink: res.data.nextLink,
        mailboxSelectedRows: mailboxState.mailboxSelectedRows,
        viewSettings: res.data.viewSetting,
      });
      return res.data;
    })
    .catch(() => dispatch({type: actionTypes.FETCHING_MAILBOX_DATA_FAILED}));
};

export const changeMailStatus =
  (selectedRows, groupKey, sortQuery) => dispatch => {
    const mailKeys = Array.isArray(selectedRows)
      ? selectedRows.map(d => d.mailKey)
      : [selectedRows.mailKey];
    dispatch({type: actionTypes.UPDATING_MAIL_STATUS});
    updateMailStatus(mailKeys)
      .then(() => {
        dispatch({type: actionTypes.UPDATED_MAIL_STATUS});
        dispatch(
          getAllMailbox({
            groupKey,
            sortQuery,
          }),
        );
      })
      .catch(() => {
        dispatch({type: actionTypes.UPDATING_MAIL_STATUS_FAILED});
      });
  };

export const onLoadMailboxData = options => dispatch => {
  dispatch(getAllMailbox(options));
};

export const updateUserOrMail = userOrMail => {
  return {
    type: actionTypes.SET_USER_OR_MAIL,
    userOrMail,
  };
};

export const updateViewName = viewName => {
  return {
    type: actionTypes.SET_VIEW_NAME,
    viewName,
  };
};

export const setAllMail = allMail => {
  return {
    type: actionTypes.SET_ALL_MAIL,
    allMail,
  };
};

export const updateMailboxSkip = skip => {
  return {
    type: actionTypes.SET_MAILBOX_SKIP,
    mailboxSkip: skip,
  };
};

export const handleDeleteAllSelected =
  ({mailKeys}) =>
  dispatch => {
    dispatch({type: actionTypes.DELETING_MAILS});
    deleteMail(mailKeys)
      .then(() => {
        dispatch({type: actionTypes.MAILS_DELETED});
        refreshSideNav();
        dispatch(updateSideNavRefresh());
      })
      .catch(() => dispatch({type: actionTypes.DELETING_MAILS_FAILED}));
  };

export const updateMailboxAllRowsSelected = selectedRows => dispatch => {
  dispatch({
    type: actionTypes.UPDATED_MAILBOX_SELECTED_ROWS,
    mailboxSelectedRows: selectedRows,
  });
};

export const mailboxExportToExcel =
  ({groupKey, pageSize, skip, filterQuery, sqlQuery}) =>
  (dispatch, getState) => {
    const mailboxState = getState().mailboxReducer;
    const toast = getState().generalReducer?.toastRef;
    const {userKey} = getUserData();

    dispatch({type: actionTypes.EXPORTING_MAILBOX_TO_EXCEL});
    exportMailbox({
      mailboxState,
      groupKey,
      userKey,
      pageSize,
      skip,
      globalFilterQuery: filterQuery,
      sqlQuery,
    })
      .then(res => {
        downloadExcel(
          res.data,
          'mailnotfiled',
          toast,
          constants.MAIL_NOT_FILED_EXPORTED_TO_EXCEL,
        );
        dispatch({type: actionTypes.EXPORTED_MAILBOX_TO_EXCEL});
      })
      .catch(() => {
        dispatch({type: actionTypes.EXPORTING_MAILBOX_TO_EXCEL_FAILED});
      });
  };

export const updateMailBoxSettingKey = settingId => {
  return {
    type: actionTypes.UPDATE_MAIL_SETTING_KEY,
    settingId,
  };
};

export const updateMailBoxViewSettingObject = ({settingId, viewSettings}) => {
  return {
    type: actionTypes.UPDATE_MAIL_SETTING_OBJECT,
    payload: {settingId, viewSettings},
  };
};

export const updateMailBoxUserKey = userKey => {
  return {
    type: actionTypes.UPDATE_MAIL_USER_KEY,
    userKey,
  };
};

export const updateMailBoxSortQuery = sortQuery => {
  return {
    type: actionTypes.UPDATE_MAIL_SORT_QUERY,
    sortQuery,
  };
};

export const loadMailBoxDropdownItems =
  ({userKey, groupKey}) =>
  dispatch => {
    dispatch({type: actionTypes.FETCHING_MAILBOX_DROPDOWN_ITEMS});
    fetchTreeData({userKey, groupKey})
      .then(res => {
        const mappedResponse = res.map(item => {
          return {
            name: item.label,
            value: item.key,
            label: item.label,
            ...item,
          };
        });
        dispatch({
          type: actionTypes.FETCHED_MAILBOX_DROPDOWN_ITEMS,
          mailBoxDropdownItems: mappedResponse,
        });
      })
      .catch(() =>
        dispatch({type: actionTypes.FETCHING_MAILBOX_DROPDOWN_ITEMS_FAILED}),
      );
  };

export const clearMailboxData = () => dispatch => {
  dispatch({type: actionTypes.CLEAR_MAILBOX_DATA});
};

export const cleanMailboxData = () => {
  return {type: actionTypes.CLEAN_MAILBOX_DATA};
};
