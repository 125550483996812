import data from '../lib/data';
import {isEmpty} from '../lib/utils';
import {downloadBlobFilePostApi} from './download';

export const fetchPendingPaymentSummary = ({
  userKey,
  groupKey,
  settingKey,
  sortQuery,
  globalFilterQuery,
  pageSize,
  skip,
  isRestricted,
  mode,
  sqlQuery,
}) => {
  let endpoint = `v4/api/payments/for/${groupKey}/${userKey}/pendingpaymentsummary?`;
  const sortJson =
    sortQuery?.length === 0
      ? null
      : {
          ViewSetting: {
            SortSetting: sortQuery.map((item, index) => {
              return {
                SortIndex: index + 1,
                SortCol: item.field,
                SortOrder: item.sort,
              };
            }),
          },
        };

  const payload = {
    countAll: true,
    sortJson: sortJson ? JSON.stringify(sortJson) : null,
    settingKey,
    userKey,
    getCount: true,
    fetchDays: 1500,
    isReturnCount: true,
    mode,
    isRestricted,
    filterJson: globalFilterQuery ? JSON.stringify(globalFilterQuery) : null,
    filterSql: sqlQuery,
    source: 'react',
  };
  if (!isEmpty(skip)) {
    endpoint += `&%24skip=${skip}`;
  }

  if (!isEmpty(pageSize)) {
    endpoint += `&%24top=${pageSize}`;
  }

  return data.post(endpoint, payload);
};

export const exportPendingPaymentSummary = ({
  userKey,
  groupKey,
  settingKey,
  sortQuery,
  globalFilterQuery,
  isRestricted,
  mode,
  sqlQuery,
  skipRows,
  rowCount,
}) => {
  const sortJson =
    isEmpty(sortQuery) || sortQuery?.length === 0
      ? null
      : {
          ViewSetting: {
            SortSetting: sortQuery.map((item, index) => {
              return {
                SortIndex: index + 1,
                SortCol: item.field,
                SortOrder: item.sort,
              };
            }),
          },
        };

  const options = {
    countAll: true,
    sortJson: sortJson ? JSON.stringify(sortJson) : null,
    isRestricted,
    mode,
    settingKey,
    userKey,
    groupKey,
    filterJson: globalFilterQuery ? JSON.stringify(globalFilterQuery) : null,
    filterSql: sqlQuery,
    source: 'react',
    RowCount: 10,
  };

  let endpoint = `v4/api/export/${mode}?`;

  if (!isEmpty(skipRows)) {
    endpoint += `&skipRows=${skipRows}`;
  }

  if (!isEmpty(rowCount)) {
    endpoint += `&rowCount=${rowCount}`;
  }

  return downloadBlobFilePostApi(endpoint, options);
};

// get data Pending-payments/Details
export const fetchPendingPaymentDetails = ({
  userKey,
  groupKey,
  settingKey,
  sortQuery,
  globalFilterQuery,
  pageSize,
  skip,
  isRestricted,
  mode,
  sqlQuery,
}) => {
  let endpoint = `v4/api/payments/for/${groupKey}/${userKey}/pendingpaymentdetails?`;
  const sortJson =
    sortQuery?.length === 0
      ? null
      : {
          ViewSetting: {
            SortSetting: sortQuery.map((item, index) => {
              return {
                SortIndex: index + 1,
                SortCol: item.field,
                SortOrder: item.sort,
              };
            }),
          },
        };

  const payload = {
    countAll: true,
    sortJson: sortJson ? JSON.stringify(sortJson) : null,
    getCount: true,
    fetchDays: 1500,
    isReturnCount: true,
    isRestricted,
    mode,
    settingKey,
    userKey,
    groupKey,
    filterSql: sqlQuery,
    source: 'react',
    filterJson: globalFilterQuery ? JSON.stringify(globalFilterQuery) : null,
  };
  if (!isEmpty(skip)) {
    endpoint += `&%24skip=${skip}`;
  }

  if (!isEmpty(pageSize)) {
    endpoint += `&%24top=${pageSize}`;
  }

  return data.post(endpoint, payload);
};

// export data pending-payment / details

export const exportPendingPaymentDetails = ({
  userKey,
  groupKey,
  settingKey,
  sortQuery,
  globalFilterQuery,
  isRestricted,
  mode,
  sqlQuery,
  skipRows,
  rowCount,
}) => {
  const sortJson =
    sortQuery?.length === 0
      ? null
      : {
          ViewSetting: {
            SortSetting: sortQuery.map((item, index) => {
              return {
                SortIndex: index + 1,
                SortCol: item.field,
                SortOrder: item.sort,
              };
            }),
          },
        };

  const options = {
    countAll: true,
    sortJson: sortJson ? JSON.stringify(sortJson) : null,
    isRestricted,
    mode,
    settingKey,
    userKey,
    groupKey,
    filterJson: globalFilterQuery ? JSON.stringify(globalFilterQuery) : null,
    filterSql: sqlQuery,
    source: 'react',
  };

  let endpoint = `v4/api/export/${mode}?`;

  if (!isEmpty(skipRows)) {
    endpoint += `&skipRows=${skipRows}`;
  }

  if (!isEmpty(rowCount)) {
    endpoint += `&rowCount=${rowCount}`;
  }

  return downloadBlobFilePostApi(endpoint, options);
};

// Fetch Invoices data in pending payments
export const fetchPendingPaymentsInvoicesDetails = (
  groupKey,
  pendingPaymentKey,
) => {
  const endpoint = `v4/api/payments/for/${groupKey}/${pendingPaymentKey}/invoices`;
  return data.get(endpoint);
};

// Trigger resend pending payments api
export const resendPendingPayments = ({groupKey, userId}) => {
  const endpoint = `v2/api/payments/for/${groupKey}/${userId}/resendpaymenttoerp`;
  return data.post(endpoint);
};

// Fetch Webhook Setting
export const fetchWebhookSetting = groupKey => {
  const endpoint = `v2/api/payments/displaypendingpaymentbutton/for/${groupKey}`;
  return data.get(endpoint);
};

// Update Webhook Status
export const updateWebhookStatus = groupKey => {
  const endpoint = `v2/api/payments/resetwebhookstatus/for/${groupKey}`;
  return data.put(endpoint);
};

// Update Pending Payment Reconcile Status
export const updateReconcileStatus = request => {
  const endpoint = `v2/api/payments/updatereconcilestatus`;
  return data.post(endpoint, request);
};
