import {actionTypes} from '../constants/actionTypes';

const initialState = {
  filesTree: [],
  isLoading: false,
};

const fileReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCHED_FILE_LIST:
      return {
        ...state,
        filesTree: action.filesTree,
        isLoading: false,
      };
    case actionTypes.FETCHING_FILE_LIST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.FETCHING_FILE_LIST_FAILED:
      return {
        ...state,
        filesTree: [],
        isLoading: false,
      };
    case actionTypes.UPLOADING_FILES:
    case actionTypes.UPLOADING_FILES_ATTACHMENTS:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.UPLOADED_FILES:
    case actionTypes.UPLOADING_FILES_FAILED:
    case actionTypes.UPLOADED_FILES_ATTACHMENTS:
    case actionTypes.UPLOADING_FILES_ATTACHMENTS_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default fileReducer;
