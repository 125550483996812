import {Button} from '@mui/material';
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid-pro';
import CachedIcon from '@mui/icons-material/Cached';
import SettingsIcon from '@mui/icons-material/Settings';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import DescriptionIcon from '@mui/icons-material/Description';
import PaymentIcon from '@mui/icons-material/Payment';
import {FaArrowsAltH} from 'react-icons/fa';
import {CustomFilterButton} from '../../../ui/data';

export default function StatementOfAccountToolbar({
  onRefresh,
  exportToExcel,
  onNewActivity,
  onPayment,
  applyBestFit,
  onViewSettings,
}: {
  onRefresh: () => void;
  exportToExcel: () => void;
  onNewActivity: () => void;
  onPayment: () => void;
  applyBestFit: () => void;
  onViewSettings: () => void;
}) {
  return (
    <GridToolbarContainer>
      <Button startIcon={<ChatBubbleIcon />} onClick={onNewActivity}>
        New Activity
      </Button>
      <Button startIcon={<PaymentIcon />} onClick={onPayment}>
        Payment
      </Button>
      <CustomFilterButton />
      <GridToolbarColumnsButton />
      <Button startIcon={<DescriptionIcon />} onClick={exportToExcel}>
        Export To Excel
      </Button>
      <Button startIcon={<CachedIcon />} onClick={onRefresh}>
        Refresh
      </Button>
      <Button startIcon={<FaArrowsAltH />} onClick={applyBestFit}>
        BestFit
      </Button>
      <GridToolbarDensitySelector />
      <Button startIcon={<SettingsIcon />} onClick={onViewSettings}>
        View Settings
      </Button>
    </GridToolbarContainer>
  );
}
