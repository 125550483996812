import {fetchActivityDetail} from '../../services/communications';
import {actionTypes} from '../constants/actionTypes';

export const getActivityDetailForInvoice =
  ({docKey, userKey, pageSize, skip}) =>
  dispatch => {
    dispatch({type: actionTypes.FETCHING_ACTIVITY_DETAIL});
    fetchActivityDetail({docKey, userKey, pageSize, skip})
      .then(res => {
        dispatch({
          type: actionTypes.FETCHED_ACTIVITY_DETAIL,
          activityDetail: res.data.value,
          activityDetailCount: res.data.count,
          activityDetailNextLink: res.data.nextLink,
        });
        return res.data;
      })
      .catch(() => {
        dispatch({type: actionTypes.FETCHING_ACTIVITY_DETAIL_FAILED});
      });
  };

export const updateActivityDetailSkip = activityDetailSkip => {
  return {
    type: actionTypes.UPDATE_ACTIVITY_DETAIL_SKIP,
    activityDetailSkip,
  };
};

export const updateActivityDetailPageSize =
  activityDetailPageSize => dispatch => {
    dispatch({type: actionTypes.UPDATING_ACTIVITY_DETAIL_PAGE_SIZE});
    dispatch({
      type: actionTypes.UPDATE_ACTIVITY_DETAIL_PAGE_SIZE,
      activityDetailPageSize,
    });
  };
