import dayjs from 'dayjs';
import {ViewAttachment, ReadUnreadMessage} from './RowComponent';
import {constants} from '../../../constants/common';
import {filterOperators} from '../../../lib/commonTableHelpers';

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

export const viewMessagesColumns = (timezoneOffset, onRefresh) => [
  {
    field: 'createDate',
    headerName: 'Create Date',
    sortable: false,
    disableColumnMenu: true,
    valueFormatter: params => {
      const offsetValue = timezoneOffset || 0;
      return params?.value
        ? dayjs(params?.value)
            .add(offsetValue, 'minute')
            .utc()
            .format(constants.DATE_TIME_FORMAT)
        : '';
    },
    minWidth: 50,
    flex: 1,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'source',
    headerName: 'Source',
    sortable: false,
    minWidth: 180,
    disableColumnMenu: true,
    flex: 1,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'subject',
    headerName: 'Subject',
    sortable: false,
    minWidth: 180,
    disableColumnMenu: true,
    flex: 1,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'message',
    headerName: 'Message',
    sortable: false,
    minWidth: 180,
    disableColumnMenu: true,
    flex: 1,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'readUnread',
    headerName: 'Read/Unread',
    sortable: false,
    disableColumnMenu: true,
    renderCell: cellValues => {
      const props = {...cellValues, onRefresh};
      return <ReadUnreadMessage values={props} />;
    },
    minWidth: 70,
    flex: 1,
  },
  {
    field: 'view',
    headerName: 'View',
    sortable: false,
    disableColumnMenu: true,
    renderCell: cellValues => {
      const props = {...cellValues};
      return <ViewAttachment values={props} />;
    },
    minWidth: 70,
    flex: 1,
  },
];
