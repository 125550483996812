import {useEffect, useRef, useState} from 'react';
import DOMPurify from 'dompurify';
import {constants} from '../../../constants/common';
import {useGetTemplateList} from '../../../hooks/useGetTemplateList';
import {CommunicationDropDownType} from '../../../constants/typeConstants';
import {SelectWithHeader} from '../../common/select/select-with-header/SelectWithHeader';
import {TemplateHeader} from './template-dropdown/TemplateHeader';
import {TemplateOptions} from './template-dropdown/TemplateOptions';
import {fetchTemplateDetailsByKey} from '../../../services/activities';
import AddressBook from '../address-book/AddressBook';
import {isEmpty} from '../../../lib/utils';
import {MODE_TYPES} from '../../../utils/constants';
import Popover from '../../../ui/utils/popover/Popover';
import {Button, TextEditor} from '../../../ui/inputs';
import TextField from '../../../ui/inputs/textfield/TextField';

interface IEmailTemplate {
  emailTemplateKey: string;
  emailTemplateID: string;
  emailTemplateDesc: string;
}

interface ICommunicateTextAndVoice {
  activityInfo: any;
  newActivity: boolean;
  groupKey: string;
  custKey: number;
  userKey: string;
  modeType: number;
  updateActivityInfoState: (value: any) => void;
}
interface ITextVoiceContact {
  phone: string | number;
}

function CommunicateText({
  activityInfo,
  newActivity,
  groupKey,
  custKey,
  userKey,
  modeType,
  updateActivityInfoState,
}: ICommunicateTextAndVoice) {
  const [selectedTemplate, setSelectedTemplate] = useState<IEmailTemplate>();
  const [smsText, setSmsText] = useState(
    activityInfo?.mail?.smsBody?.replaceAll('\n', '<br>'),
  );
  const {templateDropdown} = useGetTemplateList({
    groupKey,
    type: CommunicationDropDownType.text,
  });
  const activityRef = useRef(activityInfo);
  const [toAnchorEl, setToAnchorEl] = useState(null);

  useEffect(() => {
    activityRef.current = activityInfo;
  }, [activityInfo]);

  const onEmailTemplateSelect = async (e: IEmailTemplate) => {
    setSelectedTemplate(e);
    const {data} = await fetchTemplateDetailsByKey({
      EmailTemplateKey: e.emailTemplateKey,
      CommunicationType: CommunicationDropDownType.text,
      GroupKey: groupKey,
      CustomerKey: custKey,
      UserKey: userKey,
      ModeType: 0,
    });
    const {TemplateInfo} = data;
    if (TemplateInfo) {
      let activityInfoTemp = {...activityInfo};
      activityInfoTemp = {
        ...activityInfoTemp,
        mail: {
          ...activityInfoTemp.mail,
          smsTo: TemplateInfo.smsTo,
          smsBody: TemplateInfo.smsBody,
        },
      };
      updateActivityInfoState(activityInfoTemp);
    }
  };

  const onTextChange = (value: any, field: string) => {
    let activityInfoTemp = {...activityRef.current};
    activityInfoTemp = {
      ...activityInfoTemp,
      mail: {
        ...activityInfoTemp.mail,
        [field]: value,
      },
    };
    updateActivityInfoState(activityInfoTemp);
  };

  const updateToRecipientValues = (selectedRecipient: ITextVoiceContact[]) => {
    let activityInfoTemp = {...activityRef.current};
    activityInfoTemp = {
      ...activityInfoTemp,
      mail: {
        ...activityInfoTemp.mail,
        smsTo: selectedRecipient.map(res => ` ${res?.phone}`),
      },
    };
    updateActivityInfoState(activityInfoTemp);
  };

  const handleClickToAnchorEl = (event: any) => {
    setToAnchorEl(event.currentTarget);
  };
  const handleCloseToAnchorEl = () => {
    setToAnchorEl(null);
  };

  const openToAddressBook = Boolean(toAnchorEl);

  return !newActivity && isEmpty(activityInfo?.mail?.smsBody) ? (
    <p>{constants.THERE_ARE_NO_TEXTS}</p>
  ) : (
    <>
      <div className="d-flex justify-content-between align-items-center my-3">
        {newActivity && (
          <Button
            className="me-2"
            size="small"
            variant="contained"
            onClick={handleClickToAnchorEl}
            style={{alignSelf: 'flex-center'}}
          >
            {constants.TO.toLocaleLowerCase()}
          </Button>
        )}
        <TextField
          id="sms-to"
          label={newActivity ? '' : constants.TO}
          value={activityInfo?.mail?.smsTo}
          onChange={e => onTextChange(e.target.value, 'smsTo')}
          size="small"
          margin="dense"
          fullWidth
        />
      </div>
      {newActivity && (
        <div className="d-flex justify-content-between my-3">
          <Button className="me-2" disabled style={{color: 'black'}}>
            {constants.TEMPLATE}
          </Button>
          <div className=" d-flex w-100">
            <div className="w-50">
              <SelectWithHeader
                placeholder={constants.SELECT_EMAIL_TEMPLATE_TO_INSERT}
                options={templateDropdown}
                Header={<TemplateHeader />}
                components={{Option: TemplateOptions}}
                onChange={(e: IEmailTemplate) => onEmailTemplateSelect(e)}
                value={selectedTemplate}
                getOptionLabel={(option: IEmailTemplate) =>
                  option.emailTemplateID
                }
              />
            </div>
          </div>
        </div>
      )}
      {modeType === MODE_TYPES.enquiry && !newActivity ? (
        <div className="mt-3 border-1">
          <div className="mt-2 p-3 text-editor-email-body h-480-px">
            <div
              id="mailContent"
              // No fix required as we are using DOMPurify lib
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  activityInfo?.mail?.smsBody?.replace(
                    /href/g,
                    "target='_blank' href",
                  ),
                ),
              }}
            />
          </div>
        </div>
      ) : (
        <TextEditor
          className="h-450-px"
          textEditorValue={smsText}
          onTextEditorValueChange={e => {
            setSmsText(e.htmlValue);
            onTextChange(e.textValue, 'smsBody');
          }}
        />
      )}
      <Popover
        open={openToAddressBook}
        anchorEl={toAnchorEl}
        onClose={handleCloseToAnchorEl}
      >
        <AddressBook
          isTextTab
          isVoiceTab={false}
          setShowAddContactDialog={() => {}}
          setShowAddPersonalContactsDialog={() => {}}
          updateRecipientValues={updateToRecipientValues}
          textFieldValueArray={activityInfo?.mail?.smsTo}
          fieldKey="phone"
        />
      </Popover>
    </>
  );
}
export default CommunicateText;
