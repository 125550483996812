import {useLocation, useNavigate} from 'react-router-dom';
import {useState} from 'react';
import {MODE_TYPES} from '../../../utils/constants';
import {Checkbox} from '../../../ui/inputs';
import {issueCompleteAndActivityCompleteService} from '../../../services/activities';
import {isEmpty} from '../../../lib/utils';
import {constants} from '../../../constants/common';

export const OverViewActivityMenu = ({values}: {values: any}) => {
  const {row} = values;
  const navigate = useNavigate();
  const location = useLocation();
  const {state, search} = location;

  const {custId, companyId, selectCompanyId} = state || {};

  const onHandleActivityClick = () => {
    const communicateProps = {
      ...row,
      newActivity: true,
      ModeType: MODE_TYPES.activityOnEnquiry,
      tabName: 'email',
      custId,
      companyId,
      selectCompanyId,
      hideSaveAndNext: true,
    };
    const params = new URLSearchParams(search);
    params.set('activityId', communicateProps.id);

    navigate(`activity?${params.toString()}`, {
      state: {...communicateProps, from: location},
    });
  };
  return (
    <span>
      <i
        role="presentation"
        className="pi pi-activity cursor-pointer"
        onClick={onHandleActivityClick}
      />
    </span>
  );
};

export const SingleLink = ({values}: {values: any}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {row} = values;
  const {state, search} = location;

  const {custId, companyId, selectCompanyId} = state || {};
  return (
    <span
      role="presentation"
      className="blueLink cursor-pointer"
      title={values.row[values.field]}
      onClick={() => {
        const communicateProps = {
          ...row,
          ModeType: MODE_TYPES.enquiry,
          custId,
          companyId,
          selectCompanyId,
        };
        const params = new URLSearchParams(search);
        params.set('activityId', communicateProps.id);

        navigate(`activity?${params.toString()}`, {
          state: {...communicateProps, from: location},
        });
      }}
    >
      {isEmpty(values.row[values.field])
        ? constants.BLANK_ISSUE
        : values.row[values.field]}
    </span>
  );
};

export const IconLink = ({
  values,
  children,
  tabName,
}: {
  values: any;
  children: React.ReactNode;
  tabName: string;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {row} = values;
  const {state, search} = location;
  const {custId, companyId, selectCompanyId} = state || {};
  return (
    <span
      role="presentation"
      className="blueLink cursor-pointer"
      onClick={() => {
        const communicateProps = {
          ...row,
          ModeType: MODE_TYPES.enquiry,
          tabName,
          custId,
          companyId,
          selectCompanyId,
        };
        const params = new URLSearchParams(search);
        params.set('activityId', communicateProps.id);

        navigate(`activity?${params.toString()}`, {
          state: {...communicateProps, from: location},
        });
      }}
    >
      {children}
    </span>
  );
};

export const CheckboxEditable = ({
  values,
  field,
  isActivity = false,
}: {
  values: any;
  field: string;
  isActivity?: boolean;
}) => {
  const {row} = values;
  const [value, setValue] = useState(row[field]);
  const onIssueCompleteAndActivityCompleteChkChanged = async (
    isChecked: boolean,
  ) => {
    const request = {
      taskKey: row?.taskKey,
      isActivity,
      taskActKey: row?.taskActKey,
      isChecked,
    };

    try {
      await issueCompleteAndActivityCompleteService(request);
    } catch {
      setValue(!isChecked);
    }
  };
  const onHandleCheckboxChange = (val: boolean) => {
    setValue(val);
    onIssueCompleteAndActivityCompleteChkChanged(val);
  };
  return (
    <Checkbox
      checked={value}
      onChange={e => onHandleCheckboxChange(e.target.checked)}
    />
  );
};
