import {actionTypes} from '../constants/actionTypes';

const initialState = {
  mailboxSkip: 0,
  mailboxData: [],
  viewSettings: null,
  fetchingMailboxData: false,
  fetchingMailboxDataFailed: false,
  fetchedMailboxData: false,
  mailboxLoading: false,
  mailboxCount: 0,
  mailboxNextLink: '',
  userOrMail: '1',
  allMail: '01',
  viewName: 'Mailbox_User',
  deletingMails: false,
  deletingMailsFailed: false,
  deletedMails: false,
  mailboxSelectedRows: [],
  mailboxUnselectedRows: [],
  exportingToExcel: false,
  exportedToExcel: false,
  exportingToExcelFailed: false,
  settingId: null,
  userKey: null,
  sortQuery: [],

  fetchingMailboxDropdown: false,
  fetchingMailboxDropdownFailed: false,
  fetchedMailboxDropdown: false,
  mailBoxDropdownItems: [],
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const mailboxReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_MAILBOX_DATA:
      return {
        ...state,
        fetchingMailboxData: true,
        fetchingMailboxDataFailed: false,
        mailboxLoading: true,
        deletedMails: false,
      };
    case actionTypes.FETCHED_MAILBOX_DATA:
      return {
        ...state,
        fetchingMailboxData: false,
        fetchingMailboxDataFailed: false,
        fetchedMailboxData: true,
        mailboxData: action.mailboxData,
        mailboxLoading: false,
        mailboxCount: action.mailboxCount,
        mailboxNextLink: action.mailboxNextLink,
        mailboxSkip: action.mailboxSkip,
        mailboxSelectedRows: action.mailboxSelectedRows,
        deletedMails: false,
        viewSettings: {...action.viewSettings, viewName: state.viewName},
      };
    case actionTypes.FETCHING_MAILBOX_DATA_FAILED:
      return {
        ...state,
        fetchingMailboxData: false,
        fetchingMailboxDataFailed: true,
        fetchedMailboxData: true,
        mailboxLoading: false,
        deletedMails: false,
      };
    case actionTypes.SET_USER_OR_MAIL:
      return {
        ...state,
        userOrMail: action.userOrMail,
      };
    case actionTypes.SET_VIEW_NAME:
      return {
        ...state,
        viewName: action.viewName,
      };
    case actionTypes.SET_ALL_MAIL:
      return {
        ...state,
        allMail: action.allMail,
      };
    case actionTypes.SET_MAILBOX_SKIP:
      return {
        ...state,
        mailboxSkip: action.mailboxSkip,
      };
    case actionTypes.UPDATED_MAIL_STATUS:
      return {
        ...state,
        mailboxLoading: false,
        mailboxSelectedRows: [],
      };
    case actionTypes.UPDATING_MAIL_STATUS_FAILED:
      return {
        ...state,
        mailboxLoading: false,
      };
    case actionTypes.UPDATING_MAIL_STATUS:
      return {
        ...state,
        mailboxLoading: true,
      };
    case actionTypes.DELETING_MAILS:
      return {
        ...state,
        mailboxLoading: true,
        deletingMails: true,
        deletingMailsFailed: false,
        deletedMails: false,
      };
    case actionTypes.DELETING_MAILS_FAILED:
      return {
        ...state,
        mailboxLoading: false,
        deletingMails: false,
        deletingMailsFailed: true,
        deletedMails: false,
      };
    case actionTypes.MAILS_DELETED:
      return {
        ...state,
        mailboxLoading: false,
        deletingMails: false,
        deletingMailsFailed: false,
        deletedMails: true,
        mailboxSelectedRows: [],
      };
    case actionTypes.UPDATED_MAILBOX_SELECTED_ROWS:
      return {...state, mailboxSelectedRows: action.mailboxSelectedRows};

    case actionTypes.EXPORTING_MAILBOX_TO_EXCEL:
      return {
        ...state,
        mailboxLoading: true,
        exportingToExcel: true,
        exportedToExcel: false,
        exportingToExcelFailed: false,
      };
    case actionTypes.EXPORTED_MAILBOX_TO_EXCEL:
      return {
        ...state,
        mailboxLoading: false,
        exportingToExcel: false,
        exportedToExcel: true,
        exportingToExcelFailed: false,
      };
    case actionTypes.EXPORTING_MAILBOX_TO_EXCEL_FAILED:
      return {
        ...state,
        mailboxLoading: false,
        exportingToExcel: false,
        exportedToExcel: false,
        exportingToExcelFailed: true,
      };
    case actionTypes.UPDATE_MAIL_SETTING_KEY:
      return {
        ...state,
        settingId: action.settingId,
      };
    case actionTypes.UPDATE_MAIL_SETTING_OBJECT: {
      const views = [
        'mailbox_allinbox',
        'mailbox_allunread',
        'mailbox_inbox',
        'mailbox_unread',
      ];
      return {
        ...state,
        viewSettings: {
          ...action.payload.viewSettings,
          columnSetting: action.payload.viewSettings.columnSetting.map(
            column => {
              if (
                column.dataField === 'sentDate' &&
                column.displayText === 'BodyText'
              ) {
                return {...column, dataField: 'bodyText'};
              }
              return column;
            },
          ),
          sortSetting: null,
        },
        settingId: action.payload.settingId,
      };
    }
    case actionTypes.UPDATE_MAIL_USER_KEY:
      return {
        ...state,
        userKey: action.userKey,
      };
    case actionTypes.UPDATE_MAIL_SORT_QUERY:
      return {
        ...state,
        sortQuery: action.sortQuery,
      };
    case actionTypes.CLEAR_MAILBOX_DATA:
      return {
        ...state,
        mailboxData: [],
        fetchingMailboxData: false,
        fetchingMailboxDataFailed: false,
        mailboxLoading: false,
        deletedMails: false,
        fetchedMailboxData: true,
        userKey: null,
        userOrMail: '',
        allMail: '',
        mailboxCount: 0,
        mailboxNextLink: '',
        mailboxSelectedRows: [],
        mailboxUnselectedRows: [],
      };
    case actionTypes.FETCHING_MAILBOX_DROPDOWN_ITEMS:
      return {
        ...state,
        fetchingMailboxDropdown: true,
        fetchingMailboxDropdownFailed: false,
        fetchedMailboxDropdown: false,
      };
    case actionTypes.FETCHED_MAILBOX_DROPDOWN_ITEMS:
      return {
        ...state,
        fetchingMailboxDropdown: false,
        fetchingMailboxDropdownFailed: false,
        fetchedMailboxDropdown: true,
        mailBoxDropdownItems: action.mailBoxDropdownItems,
      };
    case actionTypes.FETCHING_MAILBOX_DROPDOWN_ITEMS_FAILED:
      return {
        ...state,
        fetchingMailboxDropdown: false,
        fetchingMailboxDropdownFailed: true,
        fetchedMailboxDropdown: false,
      };
    case actionTypes.CLEAN_MAILBOX_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
export default mailboxReducer;
