// es lint disable added as react quill control doest support label
/* eslint-disable jsx-a11y/control-has-associated-label */
import ReactQuill, {Quill} from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import moment from 'moment';
import './text.editor.css';
import {useCallback} from 'react';
import {constants, textEditorToolbarTitle} from '../../../constants/common';

interface Props {
  customDropdownOption?: {label: string; value: string}[];
}

const CustomDropdown: React.FC<Props> = ({customDropdownOption}) => (
  <select className="ql-insertCustomTags" title="Insert code snippet">
    {customDropdownOption?.map(val => (
      <option key={val.value} title={val.label} value={val.value}>
        {val.label}
      </option>
    ))}
  </select>
);

const CustomToolbar: React.FC<Props & {toolbarId: string}> = ({
  customDropdownOption,
  toolbarId,
}) => (
  <div id={toolbarId}>
    <select title={textEditorToolbarTitle.FONT_FAMILY} className="ql-font">
      <option value="arial" selected>
        Arial
      </option>
      <option value="comic-sans">Comic Sans</option>
      <option value="courier-new">Courier New</option>
      <option value="georgia">Georgia</option>
      <option value="helvetica">Helvetica</option>
      <option value="lucida">Lucida</option>
    </select>
    <select title={textEditorToolbarTitle.Header} className="ql-header" />
    <select title={textEditorToolbarTitle.FONT_SIZE} className="ql-size">
      <option value="extra-small">Size 1</option>
      <option value="small">Size 2</option>
      <option value="medium" selected>
        Size 3
      </option>
      <option value="large">Size 4</option>
    </select>
    <button
      title={textEditorToolbarTitle.BOLD}
      className="ql-bold"
      type="button"
    />
    <button
      title={textEditorToolbarTitle.ITALIC}
      className="ql-italic"
      type="button"
    />
    <button
      title={textEditorToolbarTitle.UNDERLINE}
      className="ql-underline"
      type="button"
    />
    <button
      title={textEditorToolbarTitle.STRIKE_THROUGH}
      className="ql-strike"
      type="button"
    />
    <select
      title={textEditorToolbarTitle.TEXT_ALIGNMENT}
      className="ql-align"
    />
    <button
      title={textEditorToolbarTitle.TEXT_DIRECTION}
      className="ql-direction"
      value="rtl"
      type="button"
    />
    <select title={textEditorToolbarTitle.TEXT_COLOR} className="ql-color" />
    <select
      title={textEditorToolbarTitle.BACKGROUND_COLOR}
      className="ql-background"
    />
    <button
      title={textEditorToolbarTitle.SUBSCRIPT}
      className="ql-script"
      value="sub"
      type="button"
    />
    <button
      title={textEditorToolbarTitle.SUPERSCRIPT}
      className="ql-script"
      value="super"
      type="button"
    />
    <button
      title={textEditorToolbarTitle.HEADING_1}
      className="ql-header"
      value="1"
      type="button"
    />
    <button
      title={textEditorToolbarTitle.HEADING_2}
      className="ql-header"
      value="2"
      type="button"
    />
    <button
      title={textEditorToolbarTitle.BLOCKQUOTE}
      className="ql-blockquote"
      type="button"
    />
    <button
      title={textEditorToolbarTitle.INSERT_LINK}
      className="ql-link"
      type="button"
    />
    <button
      title={textEditorToolbarTitle.INSERT_IMAGE}
      className="ql-image"
      type="button"
    />
    <button
      title={textEditorToolbarTitle.ORDERED_LIST}
      className="ql-list"
      value="ordered"
      type="button"
    />
    <button
      title={textEditorToolbarTitle.BULLET_LIST}
      className="ql-list"
      value="bullet"
      type="button"
    />
    <button
      title={textEditorToolbarTitle.DECREASE_INDENTATION}
      className="ql-indent"
      value="-1"
      type="button"
    />
    <button
      title={textEditorToolbarTitle.INCREASE_INDENTATION}
      className="ql-indent"
      value="+1"
      type="button"
    />
    <button
      title={textEditorToolbarTitle.CLEAN_FORMATTING}
      className="ql-clean"
      type="button"
    />
    <button
      title={textEditorToolbarTitle.INSERT_CODE}
      className="ql-code-block"
      type="button"
    />
    <button
      title={textEditorToolbarTitle.INSERT_DATE_AND_TIME}
      className="ql-insertDateTimeHandler"
      type="button"
    >
      <i className="pi pi-calendar" aria-hidden="true" />
    </button>
    {Boolean(customDropdownOption?.length) && (
      <CustomDropdown customDropdownOption={customDropdownOption} />
    )}
  </div>
);

// Add sizes to whitelist and register them
const Size = Quill.import('formats/size');
Size.whitelist = ['extra-small', 'small', 'medium', 'large'];
Quill.register(Size, true);

// Add fonts to whitelist and register them
const Font = Quill.import('formats/font');
Font.whitelist = [
  'arial',
  'comic-sans',
  'courier-new',
  'georgia',
  'helvetica',
  'lucida',
];
Quill.register(Font, true);

function insertCustomTags(
  this: {
    quill: any;
    insertCustomDropdownValue: (args: any[]) => void;
  },
  args: string,
) {
  if (!args || args.length === 0) return;
  const cursorPosition = this.quill.getSelection().index;
  this.quill.insertText(cursorPosition, args);
  this.quill.setSelection(cursorPosition + args.length);
}

// custom handler to insert date time
function insertDateTimeHandler(this: {quill: any}) {
  const currentDate = moment().format(constants.DATE_TIME_FORMAT2);
  const cursorPosition = this.quill.getSelection().index;
  this.quill.insertText(cursorPosition, `${currentDate} :`);
  this.quill.setSelection(cursorPosition + currentDate.length + 2);
}

interface TextEditorProps extends Props {
  textEditorValue?: string;
  defaultValue?: string;
  className?: string;
  onTextEditorValueChange: (value: {
    textValue: string;
    htmlValue: string;
  }) => void;
  toolbarId?: string;
}
const TextEditor: React.FC<TextEditorProps> = ({
  onTextEditorValueChange,
  defaultValue,
  textEditorValue,
  customDropdownOption = [],
  className = '',
  toolbarId = 'toolbar',
}) => {
  const modules = {
    toolbar: {
      container: `#${toolbarId}`,
      handlers: {
        insertCustomTags,
        insertDateTimeHandler,
      },
    },
  };

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'color',
  ];

  const generateCustomDropdownStyles = (selectOptions: any[]) => {
    let styles = '';

    selectOptions.forEach(({label, value}) => {
      styles += `.ql-picker.ql-insertCustomTags [data-value="${value}"]::before {content: "${label}"}\n`;
    });

    return styles;
  };

  const onChange = useCallback(
    (
      content: string,
      _delta: any,
      _source: any,
      editor: ReactQuill.UnprivilegedEditor,
    ) => {
      if (content !== textEditorValue) {
        const textValue = editor.getText();
        onTextEditorValueChange({textValue, htmlValue: content});
      }
    },
    [onTextEditorValueChange, textEditorValue],
  );

  return (
    <>
      <style
        //
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: generateCustomDropdownStyles(customDropdownOption),
        }}
      />
      <CustomToolbar
        toolbarId={toolbarId}
        customDropdownOption={customDropdownOption}
      />
      <ReactQuill
        className={className}
        theme="snow"
        defaultValue={defaultValue}
        value={textEditorValue}
        onChange={onChange}
        modules={modules}
        formats={formats}
      />
    </>
  );
};

export default TextEditor;
