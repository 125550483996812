import {constants} from '../../../constants/common';

export const SelectFooter = ({page, totalRecord}) => {
  return (
    <div className="header-footer ">
      <span>
        {constants.ITEM} 1 - {page} {constants.OF} {totalRecord}
      </span>
    </div>
  );
};
