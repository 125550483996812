import {gridModeTypes} from '../../constants/common';
import {getQueryParameters} from '../../lib/utils';

export const getPageQueryParams = location => {
  const result = {
    mode: null,
    ruleKey: null,
    alertDesc: null,
    activityType: '',
  };
  if (location.search && location.search !== '') {
    const searchParams = new URLSearchParams(location.search);
    const uniqueParams = new URLSearchParams();

    searchParams.forEach((value, key) => {
      if (!uniqueParams.has(key)) {
        uniqueParams.append(key, value);
      }
    });

    const queryString = uniqueParams.toString();

    const res = getQueryParameters(queryString.replace(/^#\/?/, ''));
    if (res.Mode) {
      result.mode = res.Mode;
      result.activityType = gridModeTypes[res.Mode] ?? gridModeTypes.None;
      result.ruleKey = res.RuleKey ?? null;
      result.alertDesc = res.AlertDesc ?? null;

      return result;
    }
    return result;
  }
  return result;
};
