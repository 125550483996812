import React, {useEffect, useState} from 'react';
import {Outlet} from 'react-router-dom';
import {
  GridColDef,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridLogicOperator,
  GridPaginationModel,
  GridSortModel,
} from '@mui/x-data-grid-pro';
import {useDispatch, useSelector} from 'react-redux';
import {
  applyDatatableSettings,
  convertAndAppendExistingFilters,
  getGridColumnsSettings,
  getSelectedRowsByKey,
  hasFilterValue,
  updateColumnSettings,
} from '../../../lib/commonTableHelpers';
import {calculateColumnWidth} from '../../header/helper';
import {
  convertMUIToQueryBuilder,
  DataGrid,
  FilterPopoverProvider,
  generateJsonAndSql,
  generateJsonFromSql,
  QueryReturnValue,
} from '../../../ui/data';
import {useQueryKeys} from '../../../hooks/useQueryKeys';
import {
  cleanUpDraftDashboard,
  deleteDraftsActivity,
  draftsDataExportToExcel,
  getDraftDashboard,
  updateDraftDashboardViewSettingObject,
} from '../../../store/actions/draftActions';
import {draftDashboardColumns} from './columns';
import {ViewSettings} from './ViewSettings';
import DraftDashboardToolbar from './DraftDashboardToolbar';
import {PAGE_SIZE} from '../../../utils/constants';
import {initialQuery} from '../../../ui/data/query-builder/queryHelper';
import ViewSettingsModal from '../../common/view-settings/ViewSettingsModal';
import {gridModeTypes} from '../../../constants/common';
import {
  ColumnSetting,
  IViewSettings,
} from '../../common/view-settings/interface';
import {useComponentMountStatus} from '../../../hooks/useComponentMountStatus';
import {shouldResetFilterModel} from '../../../utils/filterUtils';

export const DraftDashboard = () => {
  const [bestFit, setBestFit] = useState<boolean>(false);
  const [bestFitColumns, setBestFitColumns] = useState<GridColDef[]>([]);
  const [sortColumn, setSortColumn] = useState<GridSortModel>([]);
  const dispatch = useDispatch<any>();
  const {userKey} = useQueryKeys();
  const [skip, setSkip] = useState(0);
  const [columns, setColumns] = useState<any[]>([]);
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({});
  const [selectedAccounts, setSelectedAccounts] = useState<any[]>([]);

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: PAGE_SIZE,
  });

  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    logicOperator: GridLogicOperator.And,
    items: [],
  });

  const [showViewSettingsPopup, setShowViewSettingsPopup] =
    useState<boolean>(false);

  const [customFilterSqlValue, setCustomFilterSqlValue] = useState<
    string | null
  >(null);
  const [customFilterJsonValue, setCustomFilterJsonValue] =
    useState<QueryReturnValue['json']>();

  const [settingsKey, setSettingsKey] = useState<string | null>(null);

  const isComponentMounted = useComponentMountStatus(cleanUpDraftDashboard);

  const {
    draftSettingId,
    draftDashboardData,
    draftDataLoading,
    draftDataViewSettings,
    draftDataCount,
  } = useSelector((store: any) => store.draftReducer);

  const loadDraftActivityData = ({
    sortQuery = [],
    settingKey,
    pageSizeParam = PAGE_SIZE,
    skipParam = 0,
    filterQuery = undefined,
    sqlQuery = null,
  }: {
    sortQuery: any[];
    settingKey: string;
    pageSizeParam: number;
    skipParam: number;
    filterQuery: QueryReturnValue['json'] | undefined;
    sqlQuery: string | null;
  }) => {
    const options = {
      userKey,
      skipRows: skipParam,
      rowCount: pageSizeParam,
      sortQuery,
      settingKey,
      filterQuery,
      sqlQuery,
    };
    dispatch(getDraftDashboard(options));
  };
  // Reset filter model
  const resetFilterModel = () => {
    setFilterModel({
      logicOperator: GridLogicOperator.And,
      items: [],
    });
  };

  // reset pagination
  const resetPagination = () => {
    setPaginationModel({
      pageSize: paginationModel.pageSize,
      page: 0,
    });
  };

  const onColumnRowSelect = (newSelectionModel: any) => {
    const selectedRowsObject = getSelectedRowsByKey(
      newSelectionModel,
      draftDashboardData,
      'uniqueKey',
    ).filter((obj: any) => obj !== undefined);
    setSelectedAccounts(selectedRowsObject);
  };

  useEffect(() => {
    if (settingsKey) {
      loadDraftActivityData({
        settingKey: draftSettingId,
        sortQuery: sortColumn,
        filterQuery: customFilterJsonValue,
        sqlQuery: customFilterSqlValue,
        skipParam: 0,
        pageSizeParam: paginationModel.pageSize,
      });
      resetFilterModel();
      resetPagination();
    }
  }, [settingsKey]);

  const handleGetDraftActivityData = (
    filterQuery: QueryReturnValue['json'],
    sqlQuery: string | null,
  ) => {
    loadDraftActivityData({
      settingKey: draftSettingId,
      sortQuery: sortColumn,
      filterQuery,
      sqlQuery,
      pageSizeParam: paginationModel.pageSize,
      skipParam: skip,
    });
    resetPagination();
  };

  useEffect(() => {
    if (
      draftDataViewSettings &&
      draftDataViewSettings.settingsKey &&
      isComponentMounted
    ) {
      applyDatatableSettings(
        draftDataViewSettings,
        columns,
        draftDashboardColumns,
        undefined,
        setColumns,
        setColumnVisibilityModel,
        setSortColumn,
      );
      const jsonData =
        draftDataViewSettings?.filter?.length > 0
          ? generateJsonFromSql(draftDataViewSettings?.filter)
          : initialQuery;
      setSettingsKey(draftDataViewSettings.settingsKey);
      setCustomFilterSqlValue(draftDataViewSettings?.filter);
      setCustomFilterJsonValue(jsonData);
    }
  }, [draftDataViewSettings?.settingsKey]);

  const onPageChange = (args: GridPaginationModel) => {
    const pageSkip =
      args.pageSize !== paginationModel.pageSize
        ? 0
        : (args.page + 1) * args.pageSize - args.pageSize;
    setPaginationModel({
      pageSize: args.pageSize,
      page: args.pageSize !== paginationModel.pageSize ? 0 : args.page,
    });
    setSkip(pageSkip);
    loadDraftActivityData({
      settingKey: draftSettingId,
      sortQuery: sortColumn,
      filterQuery: customFilterJsonValue,
      sqlQuery: customFilterSqlValue,
      pageSizeParam: args.pageSize,
      skipParam: pageSkip,
    });
  };

  const onSortChange = (args: GridSortModel) => {
    setSortColumn(args);
    loadDraftActivityData({
      settingKey: draftSettingId,
      sortQuery: args,
      filterQuery: customFilterJsonValue,
      sqlQuery: customFilterSqlValue,
      pageSizeParam: paginationModel.pageSize,
      skipParam: skip,
    });
  };

  const onFilterChange = (args: GridFilterModel) => {
    const queryBuilderQuery = convertMUIToQueryBuilder(args);
    const valueToConvert = convertAndAppendExistingFilters(
      {...customFilterJsonValue},
      queryBuilderQuery,
      filterModel,
    );
    const {json, sql} = generateJsonAndSql(valueToConvert);
    setFilterModel(args);

    setCustomFilterSqlValue(json.rules.length > 0 ? sql : null);
    setCustomFilterJsonValue(json);
    if (hasFilterValue(args)) {
      handleGetDraftActivityData(json, json.rules.length > 0 ? sql : null);
    }
  };

  const onQueryFilterApply = (data: QueryReturnValue) => {
    // if filter is empty then reset filter model
    if (shouldResetFilterModel(data)) {
      resetFilterModel();
    }
    const {json, sql} = data;
    setCustomFilterSqlValue(sql);
    setCustomFilterJsonValue(json);
    handleGetDraftActivityData(json, json.rules.length > 0 ? sql : null);
  };

  const onRefresh = () => {
    loadDraftActivityData({
      settingKey: draftSettingId,
      sortQuery: sortColumn,
      filterQuery: customFilterJsonValue,
      sqlQuery: customFilterSqlValue,
      skipParam: skip,
      pageSizeParam: paginationModel.pageSize,
    });
  };
  const deleteDrafts = async () => {
    const selectedAccountsData = selectedAccounts.map(account => ({
      taskKey: account.taskKey,
      taskActKey: account.taskActKey,
      mailKey: account.mailKey,
    }));

    await dispatch(deleteDraftsActivity(selectedAccountsData));
    onRefresh();
  };

  const exportToExcel = () => {
    const options = {
      userKey,
      sortQuery: sortColumn,
      filterQuery: customFilterJsonValue,
      sqlQuery: customFilterSqlValue,
      settingKey: draftSettingId,
      skipRows: skip,
      rowCount: paginationModel.pageSize,
    };
    dispatch(draftsDataExportToExcel(options));
  };

  const updateTableOnColumnSettingsChange = (
    updatedColumns: ColumnSetting[],
    settingKey: string,
  ) => {
    if (settingKey === draftDataViewSettings?.settingsKey) {
      updateColumnSettings(
        updatedColumns,
        columns,
        setColumns,
        setColumnVisibilityModel,
      );
    }
  };

  const onViewSettings = () => {
    setShowViewSettingsPopup(true);
  };

  const handleCloseViewSettingsPopup = () => {
    setShowViewSettingsPopup(false);
  };

  //   Handling best-fit (Column resizing)
  const applyBestFit = () => {
    const bestFitColumnsTemp = columns.map(column => {
      const minWidth = calculateColumnWidth(column.field);
      return {
        ...column,
        minWidth,
      };
    });
    setBestFit(!bestFit);
    setBestFitColumns(bestFitColumnsTemp);
  };

  const {columnSettingJson, sortSettingJson} = getGridColumnsSettings(
    draftDataViewSettings?.columnSetting,
    columnVisibilityModel,
    sortColumn,
  );

  return (
    <>
      <Outlet context={{onReload: onRefresh}} />
      <ViewSettingsModal
        viewName="DraftActivity"
        mode={gridModeTypes.DraftActivity}
        updateTableOnColumnSettingsChange={updateTableOnColumnSettingsChange}
        handleCloseViewSettingsPopup={handleCloseViewSettingsPopup}
        customFilterSqlValue={customFilterSqlValue || ''}
        sortSettingJson={JSON.stringify(sortSettingJson) || ''}
        columnSettingJson={JSON.stringify(columnSettingJson) || ''}
        loadViewSetting={(row: IViewSettings) => {
          dispatch(
            updateDraftDashboardViewSettingObject({
              settingId: row.settingsKey,
              viewSettings: row,
            }),
          );
        }}
        showViewSettingsPopup={showViewSettingsPopup}
      />
      <div className="d-flex align-items-center justify-content-between">
        <ViewSettings />
      </div>
      <FilterPopoverProvider>
        <DataGrid
          height={800}
          disableVirtualization
          checkboxSelection
          columns={bestFit ? bestFitColumns : columns}
          rows={draftDashboardData}
          loading={draftDataLoading}
          rowCount={draftDataCount}
          sortModel={sortColumn}
          onSortChange={onSortChange}
          onPageChange={onPageChange}
          rowsSelected={selectedAccounts.map(item => item.uniqueKey)}
          onColumnRowSelect={onColumnRowSelect}
          onFilterChange={onFilterChange}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityChange={data => setColumnVisibilityModel(data)}
          CustomToolbar={DraftDashboardToolbar}
          disableMultipleColumnsSorting
          customToolbarMethods={{
            exportToExcel,
            onRefresh,
            applyBestFit,
            deleteDrafts,
            onViewSettings,
          }}
          showCustomFilters
          customFilterSqlValue={customFilterSqlValue}
          onQueryFilterApply={data => onQueryFilterApply(data)}
          getRowId={row => row.uniqueKey}
          headerFilters
          filterModel={filterModel}
          paginationModel={paginationModel}
        />
      </FilterPopoverProvider>
    </>
  );
};
