import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {constants} from '../../../constants/common';
import {
  editEmailSignature,
  deleteEmailSignature,
} from '../../../services/activities';
import {getEmailSignature} from '../../../store/actions/activitiesActions';
import Backdrop from '../../common/Backdrop';
import {Button, TextEditor} from '../../../ui/inputs';
import {Dialog} from '../../../ui';

export const EditEmailSignature = ({
  showEmailSignature,
  setShowEmailSignature,
  groupKey,
  userKey,
  onSaveOrDeleteSignature,
  custKey,
}) => {
  const [newSignature, setNewSignature] = useState();
  const toast = useSelector(store => store.generalReducer?.toastRef);
  const {signature, loading} = useSelector(store => store.activitiesReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    setNewSignature(signature);
  }, [signature]);

  useEffect(() => {
    dispatch(getEmailSignature({userKey, groupKey, custKey}));
  }, [dispatch, userKey, groupKey, custKey]);

  const onSave = async () => {
    const request = {
      GroupKey: groupKey,
      UserKey: userKey,
      MailSettingsKey: 0,
      EditorContent: newSignature,
    };
    try {
      await editEmailSignature(request);
      onSaveOrDeleteSignature();
    } catch (error) {
      toast?.current.show({
        severity: 'warn',
        summary: constants.SOMETHING_WENT_WRONG,
        detail: '',
      });
    }
  };

  const onDelete = async () => {
    try {
      await deleteEmailSignature({userKey, groupKey});
      onSaveOrDeleteSignature();
    } catch (error) {
      toast?.current.show({
        severity: 'warn',
        summary: constants.SOMETHING_WENT_WRONG,
        detail: '',
      });
    }
  };

  return (
    <Dialog
      open={showEmailSignature}
      onClose={() => setShowEmailSignature(false)}
      title="Maintain Email Signature"
      maxWidth="md"
      fullWidth
    >
      <>
        {loading && <Backdrop />}
        <div>
          <Button
            variant="contained"
            size="small"
            className="m-2"
            onClick={onSave}
          >
            {constants.SAVE}
          </Button>
          <Button
            variant="contained"
            size="small"
            className="m-2"
            onClick={onDelete}
          >
            {constants.DELETE}
          </Button>
        </div>
        <div className="my-4  pb-4">
          <TextEditor
            toolbarId="modal-toolbar"
            className="h-300-px"
            textEditorValue={newSignature}
            onTextEditorValueChange={e => {
              setNewSignature(e.htmlValue);
            }}
          />
        </div>
      </>
    </Dialog>
  );
};
