import data from '../lib/data';
import {isEmpty, saveBlobAsDownload} from '../lib/utils';
import {constants, gridModeTypes} from '../constants/common';
import {downloadBlobFilePostApi} from './download';

export const fetchMailboxDataVTwo = ({
  groupKey,
  userKey,
  sortQuery,
  mailboxState,
  globalFilterQuery,
  pageSize,
  skip,
  sqlQuery,
}) => {
  let endpoint = `v4/api/mailnotfiled/for/${groupKey}/${userKey}/mails?`;

  if (!isEmpty(skip)) {
    endpoint += `&%24skip=${skip}`;
  }

  if (!isEmpty(pageSize)) {
    endpoint += `&%24top=${pageSize}`;
  }

  const sortJson =
    isEmpty(sortQuery) || sortQuery?.length === 0
      ? null
      : {
          ViewSetting: {
            SortSetting: sortQuery.map((item, index) => {
              return {
                SortIndex: index + 1,
                SortCol: item.field,
                SortOrder: item.sort,
              };
            }),
          },
        };

  const options = {
    groupKey,
    mgdUserKey: mailboxState.userKey,
    showAllAccounts: 0,
    viewName: mailboxState.viewName.toLocaleLowerCase(),
    userOrMail: mailboxState.userOrMail,
    allMail: mailboxState.allMail,
    mode: gridModeTypes.MailNotFiledActivities,
    SettingKey: mailboxState.settingId,
    countAll: true,
    sortJson: sortJson ? JSON.stringify(sortJson) : null,
    getCount: true,
    filterJson: globalFilterQuery ? JSON.stringify(globalFilterQuery) : null,
    filterSql: sqlQuery,
    source: 'react',
  };
  return data.post(endpoint, options);
};

export const updateMailStatus = mailKeys => {
  const endpoint = `v4/api/mailnotfiled`;
  return data.post(endpoint, mailKeys);
};

export const deleteMail = mailKeys => {
  const endpoint = `v4/api/mailnotfiled`;
  return data.delete(endpoint, {data: mailKeys});
};

export const exportMailbox = ({
  mailboxState,
  groupKey,
  userKey,
  globalFilterQuery,
  pageSize,
  skip,
  sqlQuery,
}) => {
  let endpoint = `v4/api/export/MailNotFiledActivities?`;
  if (!isEmpty(skip)) {
    endpoint += `&%24skip=${skip}`;
  }

  if (!isEmpty(pageSize)) {
    endpoint += `&%24top=${pageSize}`;
  }

  const sortJson =
    mailboxState.sortQuery?.length === 0
      ? null
      : {
          ViewSetting: {
            SortSetting: mailboxState.sortQuery.map((item, index) => {
              return {
                SortIndex: index + 1,
                SortCol: item.field,
                SortOrder: item.sort,
              };
            }),
          },
        };

  const payload = {
    groupKey,
    userKey,
    mgdUserKey: mailboxState.userKey,
    showAllAccounts: 0,
    viewName: mailboxState.viewName.toLocaleLowerCase(),
    userOrMail: mailboxState.userOrMail,
    allMail: mailboxState.allMail,
    mode: 'MailNotFiledActivities',
    SettingKey: mailboxState.settingId,
    countAll: true,
    sortJson: sortJson ? JSON.stringify(sortJson) : null,
    getCount: true,
    filterJson: globalFilterQuery ? JSON.stringify(globalFilterQuery) : null,
    filterSql: sqlQuery,
    source: 'react',
  };

  return downloadBlobFilePostApi(endpoint, payload);
};

export const downloadAttachment = (mailKey, attachment) => {
  const endpoint = `v4/api/emailviewer/downloademailattachment/${mailKey}`;
  return downloadBlobFilePostApi(endpoint, attachment).then(res => {
    // let data = new Blob([res.data]);
    // downloadFile(res.data, attachment.fileName, null);
    saveBlobAsDownload(res.data, attachment.fileName);
  });
};

export const mailboxCreateSingle = (
  groupKey,
  customerKey,
  mailKeys,
  followupChk,
  issueDescription,
) => {
  const endpoint = `v4/api/activity/for/${groupKey}/${customerKey}/-1?followUp=${followupChk}`;
  return data.post(endpoint, {
    mailKeys,
    issueDescription,
  });
};

export const mailboxReassignActivity = (
  groupKey,
  customerKey,
  mailKeys,
  taskActKey,
  followupChk,
  existingTaskKey,
  issueDescription,
) => {
  const endpoint = `v4/api/activity/for/${groupKey}/${customerKey}/-1/${taskActKey}/${existingTaskKey}?followUp=${followupChk}`;
  return data.put(endpoint, {
    mailKeys,
    issueDescription,
  });
};

export const mailboxCreateMultiple = (
  groupKey,
  customerKey,
  mailKeys,
  followupChk,
  issueDescription,
) => {
  const endpoint = `v4/api/activity/for/${groupKey}/${customerKey}/multiple/-1?followUp=${followupChk}`;
  return data.post(endpoint, {mailKeys, issueDescription});
};

export const reassignAll = (
  groupKey,
  customerKey,
  taskKey,
  mailKeys,
  followupChk,
  issueDescription,
) => {
  let endpoint = `v4/api/activity/for/${groupKey}/${customerKey}/`;
  if (mailKeys.length === 1) {
    endpoint += `${taskKey}?followUp=${followupChk}`;
  } else {
    endpoint = `${endpoint}multiple/${taskKey}?followUp=${followupChk}`;
  }
  return data.post(endpoint, {mailKeys, issueDescription});
};

export const loadMailByMailkey = (groupKey, mailkey, toast) => {
  return data
    .get(`v4/api/emailviewer/${groupKey}/${mailkey}`)
    .then(res => {
      return res.data;
    })
    .catch(() => {
      toast?.current.show({
        severity: 'warn',
        summary: constants.SOMETHING_WENT_WRONG,
        detail: '',
      });
    });
};

export const fetchTreeData = ({userKey, groupKey}) => {
  const endpoint = `v4/api/manageduser/${groupKey}/${userKey}/all`;
  return data.get(endpoint).then(res => {
    const treeData = res.data;
    if (treeData && treeData.length) {
      treeData.forEach((x, index) => {
        x.key = `${x.key}:index-${index}`;
        if (x.children.length) {
          x.children.forEach(x1 => {
            x1.parent = x.label;
            if (x1?.children?.length) {
              x1.children.forEach(secondChild => {
                secondChild.parent = x1.label;
              });
              return x1;
            }
          });
          return x;
        }
      });
      return treeData;
    }
    return res.data;
  });
};
