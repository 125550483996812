import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid-pro';
import {FaArrowsAltH} from 'react-icons/fa';
import {Button} from '../../../ui/inputs';

export default function AllAccountsToolbar({
  applyGridBestFit,
}: {
  applyGridBestFit: any;
}) {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />

      <GridToolbarDensitySelector />
      <Button startIcon={<FaArrowsAltH />} onClick={applyGridBestFit}>
        BestFit
      </Button>
    </GridToolbarContainer>
  );
}
