import {AsyncPaginate, wrapMenuList} from 'react-select-async-paginate';
import {components} from 'react-select';
import {useCallback} from 'react';
import {constants} from '../../../constants/common';

export const AsyncSelect = ({
  options,
  onChange,
  Header,
  Footer,
  customComponents,
  displayOnModal,
  getOptionLabel,
  cacheUniqs = [false],
  value,
  onMenuClose = () => {},
  onMenuOpen = () => {},
}) => {
  const CustomMenulist = useCallback(
    wrapMenuList(props => {
      const {Header = null, Footer = null} = props.selectProps.components;
      return (
        <>
          {props.children.length && Header}
          <components.MenuList {...props}>{props.children}</components.MenuList>
          {props.children.length && Footer}
        </>
      );
    }),
    [],
  );

  let styles = {
    menu: ({width, ...css}) => ({
      ...css,
      width: 'max-content',
      minWidth: '100%',
    }),
    singleValue: provided => ({
      ...provided,
      fontSize: '13px',
    }),
  };
  if (displayOnModal) {
    styles = {
      ...styles,
      menuPortal: base => ({...base, zIndex: '9999999999 !important'}),
    };
  }
  return (
    <AsyncPaginate
      className="header-com-search-bar"
      menuPortalTarget={displayOnModal ? document.body : null}
      styles={styles}
      debounceTimeout={300}
      loadOptions={options}
      getOptionValue={option => option}
      getOptionLabel={getOptionLabel}
      onChange={onChange}
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
      components={{
        ...customComponents,
        MenuList: CustomMenulist,
        Header,
        Footer,
      }}
      isSearchable
      placeholder={constants.BEGIN_TYPING_CUSTOMER_NAME_OR_ID}
      additional={{
        page: 0,
      }}
      value={value}
      cacheUniqs={cacheUniqs}
    />
  );
};
