import {actionTypes} from '../constants/actionTypes';

const initialState = {
  installments: [],
  isLoading: false,

  // inquiries/by-user/invoices
  userInvoices: {
    userInvoicesData: [],
    userInvoicesCount: 0,
    userInvoicesLoading: false,
    fetchingUserInvoices: false,
    fetchedUserInvoices: false,
    fetchingUserInvoicesFailed: false,
    userInvoicesPageSize: 10,
    userInvoicesSkip: 0,
    userInvoicesExportingToExcel: false,
    userInvoicesExportedToExcel: false,
    userInvoicesExportingToExcelFailed: false,
    userInvoicesSortBy: '',
    userInvoicesSortDirection: null,
    userInvoicesSettingId: null,
    userInvoicesViewSettings: null,
    userInvoicesTotalAmountHC: null,
    userInvoicesTotalBalanceHC: null,
  },
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const invoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCHED_INVOICE_INSTALLMENTS:
      return {
        ...state,
        installments: action.data,
        isLoading: false,
      };
    case actionTypes.FETCHING_INVOICE_INSTALLMENTS:
    case actionTypes.ADDING_INVOICE_INSTALLMENTS:
    case actionTypes.DELETING_ALL_INVOICE_INSTALLMENTS:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.DELETED_ALL_INVOICE_INSTALLMENTS:
    case actionTypes.FETCHING_INVOICE_INSTALLMENTS_FAILED:
      return {
        ...state,
        installments: [],
        isLoading: false,
      };
    case actionTypes.ADDED_INVOICE_INSTALLMENTS:
    case actionTypes.ADDING_INVOICE_INSTALLMENTS_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    // inquiries/by-user/user-invoices
    case actionTypes.FETCHING_USER_INVOICES:
      return {
        ...state,
        userInvoices: {
          ...state.userInvoices,
          userInvoicesLoading: true,
          fetchingUserInvoices: true,
          fetchedUserInvoices: false,
          fetchingUserInvoicesFailed: false,
        },
      };
    case actionTypes.FETCHED_USER_INVOICES:
      return {
        ...state,
        userInvoices: {
          ...state.userInvoices,
          userInvoicesLoading: false,
          fetchingUserInvoices: false,
          fetchedUserInvoices: true,
          fetchingUserInvoicesFailed: false,
          userInvoicesCount: action.userInvoicesCount,
          userInvoicesData: action.userInvoicesData,
          userInvoicesTotalAmountHC: action.userInvoicesTotalAmountHC,
          userInvoicesTotalBalanceHC: action.userInvoicesTotalBalanceHC,
        },
      };
    case actionTypes.FETCHING_USER_INVOICES_FAILED:
      return {
        ...state,
        userInvoices: {
          ...state.userInvoices,
          userInvoicesLoading: false,
          fetchingUserInvoices: false,
          fetchedUserInvoices: false,
          fetchingUserInvoicesFailed: true,
          userInvoicesData: [],
          userInvoicesCount: 0,
          userInvoicesTotalAmountHC: 0,
          userInvoicesTotalBalanceHC: 0,
        },
      };
    case actionTypes.UPDATE_USER_INVOICES_SKIP:
      return {
        ...state,
        userInvoices: {
          ...state.userInvoices,
          userInvoicesSkip: action.userInvoicesSkip,
        },
      };
    case actionTypes.UPDATE_USER_INVOICES_PAGE_SIZE:
      return {
        ...state,
        userInvoices: {
          ...state.userInvoices,
          userInvoicesPageSize: action.userInvoicesPageSize,
          userInvoicesLoading: false,
          fetchingUserInvoices: false,
        },
      };
    case actionTypes.EXPORTING_TO_EXCEL_USER_INVOICES:
      return {
        ...state,
        userInvoices: {
          ...state.userInvoices,
          userInvoicesExportingToExcel: true,
          userInvoicesExportedToExcel: false,
          userInvoicesExportingToExcelFailed: false,
        },
      };
    case actionTypes.EXPORTED_TO_EXCEL_USER_INVOICES:
      return {
        ...state,
        userInvoices: {
          ...state.userInvoices,
          userInvoicesExportingToExcel: false,
          userInvoicesExportedToExcel: true,
          userInvoicesExportingToExcelFailed: false,
        },
      };
    case actionTypes.EXPORTING_TO_EXCEL_USER_INVOICES_FAILED:
      return {
        ...state,
        userInvoices: {
          ...state.userInvoices,
          userInvoicesExportingToExcel: false,
          userInvoicesExportedToExcel: false,
          userInvoicesExportingToExcelFailed: true,
        },
      };
    case actionTypes.UPDATE_USER_INVOICES_SETTING_KEY:
      return {
        ...state,
        userInvoices: {
          ...state.userInvoices,
          userInvoicesSettingId: action.userInvoicesSettingId,
        },
      };
    case actionTypes.SET_USER_INVOICES_VIEW_SETTING_OBJECT:
      return {
        ...state,
        userInvoices: {
          ...state.userInvoices,
          userInvoicesViewSettings: {
            ...action.payload.viewSettings,
            settingsKey: action.payload.settingId,
            sortSetting: null,
          },
          userInvoicesSettingId: action.payload.settingId,
        },
      };
    case actionTypes.CLEANUP_USER_INVOICE_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default invoiceReducer;
