export const allAccountsTotalsColumns = [
  {
    field: 'companyID',
    headerName: 'Company ID',
    sortable: false,
    minWidth: 150,
  },
  {
    field: 'homeCurrID',
    headerName: 'Home Curr ID',
    sortable: false,
    minWidth: 120,
  },
  {
    field: 'accountCurrID',
    headerName: 'Site Currency ID',
    sortable: false,
    minWidth: 120,
  },
  {
    field: 'totalAge',
    headerName: 'Total Age',
    type: 'number',
    valueFormatter: params => (params?.value ? Math.round(params?.value) : 0),
    sortable: false,
    minWidth: 120,
  },
  {
    field: 'ageCurrent',
    headerName: 'Age Current',
    type: 'number',
    valueFormatter: params => (params?.value ? Math.round(params?.value) : 0),
    sortable: false,
    minWidth: 180,
  },

  {
    field: 'age1',
    headerName: 'Aging 31-60 Days',
    sortable: false,
    type: 'number',
    valueFormatter: params => (params?.value ? Math.round(params?.value) : 0),
    minWidth: 80,
  },
  {
    field: 'age2',
    headerName: 'Aging 61-90 Days',
    sortable: false,
    type: 'number',
    valueFormatter: params => (params?.value ? Math.round(params?.value) : 0),
    minWidth: 80,
  },
  {
    field: 'age3',
    headerName: 'Aging 91-120 Days',
    sortable: false,
    type: 'number',
    valueFormatter: params => (params?.value ? Math.round(params?.value) : 0),
    minWidth: 80,
  },
  {
    field: 'age4',
    headerName: 'Aging 121+ Days',
    sortable: false,
    type: 'number',
    valueFormatter: params => (params?.value ? Math.round(params?.value) : 0),
    minWidth: 80,
  },
  {
    field: 'ageFuture',
    headerName: 'Age Future',
    sortable: false,
    type: 'number',
    valueFormatter: params => (params?.value ? Math.round(params?.value) : 0),
    minWidth: 180,
  },
  {
    field: 'lateAgeFuture',
    headerName: 'Due Future',
    sortable: false,
    type: 'number',
    valueFormatter: params => (params?.value ? Math.round(params?.value) : 0),
    minWidth: 180,
  },
  {
    field: 'lateAgeCurrent',
    headerName: 'Late 1-30 Days',
    sortable: false,
    type: 'number',
    valueFormatter: params => (params?.value ? Math.round(params?.value) : 0),
    minWidth: 180,
  },
  {
    field: 'lateAge1',
    headerName: 'Late 31-60 Days',
    sortable: false,
    type: 'number',
    valueFormatter: params => (params?.value ? Math.round(params?.value) : 0),
    minWidth: 180,
  },
  {
    field: 'lateAge2',
    headerName: 'Late 61-90 Days',
    sortable: false,
    type: 'number',
    valueFormatter: params => (params?.value ? Math.round(params?.value) : 0),
    minWidth: 120,
  },
  {
    field: 'lateAge3',
    headerName: 'Late 91-120 Days',
    sortable: false,
    type: 'number',
    valueFormatter: params => (params?.value ? Math.round(params?.value) : 0),
    minWidth: 120,
  },
  {
    field: 'lateAge4',
    headerName: 'Late 121+ Days',
    sortable: false,
    type: 'number',
    valueFormatter: params => (params?.value ? Math.round(params?.value) : 0),
    minWidth: 120,
  },
  {
    field: 'projPmts1',
    headerName: 'Proj Pmts 7 Days',
    sortable: false,
    type: 'number',
    valueFormatter: params => (params?.value ? Math.round(params?.value) : 0),
    minWidth: 120,
  },
  {
    field: 'projPmts2',
    headerName: 'Proj Pmts 14 Days',
    sortable: false,
    type: 'number',
    valueFormatter: params => (params?.value ? Math.round(params?.value) : 0),
    minWidth: 80,
  },
  {
    field: 'projPmts3',
    headerName: 'Proj Pmts 21 Days',
    sortable: false,
    type: 'number',
    valueFormatter: params => (params?.value ? Math.round(params?.value) : 0),
    minWidth: 120,
  },
  {
    field: 'projPmts4',
    headerName: 'Proj Pmts 28 Days',
    sortable: false,
    type: 'number',
    valueFormatter: params => (params?.value ? Math.round(params?.value) : 0),
    minWidth: 120,
  },
  {
    field: 'projPmts5',
    headerName: 'Proj Pmts 35 Days',
    sortable: false,
    type: 'number',
    valueFormatter: params => (params?.value ? Math.round(params?.value) : 0),
    minWidth: 80,
  },
  {
    field: 'totalAgeSC',
    headerName: 'Total Age SC',
    sortable: false,
    type: 'number',
    valueFormatter: params => (params?.value ? Math.round(params?.value) : 0),
    minWidth: 120,
  },
  {
    field: 'ageCurrentSC',
    headerName: 'Age Current SC',
    sortable: false,
    type: 'number',
    valueFormatter: params => (params?.value ? Math.round(params?.value) : 0),
    minWidth: 80,
  },
  {
    field: 'age1SC',
    headerName: 'Aging 31-60 Days-SC',
    sortable: false,
    type: 'number',
    valueFormatter: params => (params?.value ? Math.round(params?.value) : 0),
    minWidth: 120,
  },
  {
    field: 'age2SC',
    headerName: 'Aging 61-90 Days-SC',
    sortable: false,
    type: 'number',
    valueFormatter: params => (params?.value ? Math.round(params?.value) : 0),
    minWidth: 80,
  },
  {
    field: 'age3SC',
    headerName: 'Aging 91-120 Days-SC',
    sortable: false,
    type: 'number',
    valueFormatter: params => (params?.value ? Math.round(params?.value) : 0),
    minWidth: 120,
  },
  {
    field: 'age4SC',
    headerName: 'Aging 121+ Days-SC',
    sortable: false,
    type: 'number',
    valueFormatter: params => (params?.value ? Math.round(params?.value) : 0),
    minWidth: 120,
  },
  {
    field: 'ageFutureSC',
    headerName: 'Age Future SC',
    sortable: false,
    type: 'number',
    valueFormatter: params => (params?.value ? Math.round(params?.value) : 0),
    minWidth: 120,
  },
  {
    field: 'lateAgeFutureSC',
    headerName: 'Due Future-SC',
    sortable: false,
    type: 'number',
    valueFormatter: params => (params?.value ? Math.round(params?.value) : 0),
    minWidth: 120,
  },
  {
    field: 'lateAgeCurrentSC',
    headerName: 'Late 1-30 Days-SC',
    sortable: false,
    type: 'number',
    valueFormatter: params => (params?.value ? Math.round(params?.value) : 0),
    minWidth: 120,
  },
  {
    field: 'lateAge1SC',
    headerName: 'Late 31-60 Days-SC',
    sortable: false,
    type: 'number',
    valueFormatter: params => (params?.value ? Math.round(params?.value) : 0),
    minWidth: 120,
  },
  {
    field: 'lateAge2SC',
    headerName: 'Late 61-90 Days-SC',
    sortable: false,
    type: 'number',
    valueFormatter: params => (params?.value ? Math.round(params?.value) : 0),
    minWidth: 120,
  },
  {
    field: 'lateAge3SC',
    headerName: 'Late 91-120 Days-SC',
    sortable: false,
    type: 'number',
    valueFormatter: params => (params?.value ? Math.round(params?.value) : 0),
    minWidth: 120,
  },
  {
    field: 'lateAge4SC',
    headerName: 'Late 121+ Days-SC',
    sortable: false,
    type: 'number',
    valueFormatter: params => (params?.value ? Math.round(params?.value) : 0),
    minWidth: 120,
  },
  {
    field: 'projPmts1Sc',
    headerName: 'Proj Pmts 7 Days-SC',
    sortable: false,
    type: 'number',
    valueFormatter: params => (params?.value ? Math.round(params?.value) : 0),
    minWidth: 120,
  },
  {
    field: 'projPmts2Sc',
    headerName: 'Proj Pmts 14 Days-SC',
    sortable: false,
    type: 'number',
    valueFormatter: params => (params?.value ? Math.round(params?.value) : 0),
    minWidth: 120,
  },
  {
    field: 'projPmts3Sc',
    headerName: 'Proj Pmts 21 Days-SC',
    sortable: false,
    type: 'number',
    valueFormatter: params => (params?.value ? Math.round(params?.value) : 0),
    minWidth: 120,
  },
  {
    field: 'projPmts4Sc',
    headerName: 'Proj Pmts 28 Days-SC',
    sortable: false,
    type: 'number',
    valueFormatter: params => (params?.value ? Math.round(params?.value) : 0),
    minWidth: 120,
  },
  {
    field: 'projPmts5Sc',
    headerName: 'Proj Pmts 35 Days-SC',
    sortable: false,
    type: 'number',
    valueFormatter: params => (params?.value ? Math.round(params?.value) : 0),
    minWidth: 120,
  },
];
