const MainLoader = ({className, fullScreen}) => {
  const spinner = <div className="full-screen-spinner" />;
  if (fullScreen === true) {
    return (
      <div
        className={`d-flex flex-center flex-align-center h-100 ${
          className || ''
        }`}
      >
        {spinner}
      </div>
    );
  }
  return spinner;
};

export default MainLoader;
