import {TRAN_TYPE} from '../../../utils/constants';
import {isDateLessThanToday} from '../../../utils/dataTimeUtils';

export const checkIsTranTypePayment = tranType => {
  return (
    tranType?.toLocaleLowerCase() === TRAN_TYPE.CR.toLocaleLowerCase() ||
    tranType?.toLocaleLowerCase() === TRAN_TYPE.RV.toLocaleLowerCase()
  );
};

const findCurrentStatementAmount = (list, combinationKey) => {
  const statement = list.find(
    item => item.tranId + item.invcSchedKey === combinationKey,
  );
  return statement.paymentAmount;
};

export const mappedFilteredRows = (filteredRows, accountList) => {
  return filteredRows.map(item => {
    return item.paymentAmountDisabled
      ? {
          ...item,
          paymentAmountDisabled: false,
          paymentAmount: item.balance,
        }
      : {
          ...item,
          paymentAmount: findCurrentStatementAmount(
            accountList,
            item.tranId + item.invcSchedKey,
          ),
        };
  });
};

export const calculateSum = list => {
  return list.reduce((acc, o) => {
    return o.tranType === TRAN_TYPE.CM ||
      (o.tranType === TRAN_TYPE.IN && o.balance > 0)
      ? Number(acc) + Number(o.paymentAmount)
      : Number(acc);
  }, 0);
};

export const calculateDiscountSum = list => {
  return list.reduce((acc, o) => {
    let discAmount = 0.0;
    if (
      (!isDateLessThanToday(o.discDate) && o.tranAmt === o.paymentAmount) ||
      o.overrideDiscount
    ) {
      discAmount = Number(o.discAmt) > 0 ? Number(o.discAmt) : 0;
    } else {
      discAmount = 0.0;
    }
    return Number(acc) + discAmount;
  }, 0);
};
