import {useDispatch} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {updateSelectedCustomer} from '../../store/actions/generalActions';
import {constants} from '../../constants/common';
import {showCommunicate} from '../messages/communication/helper';
import {isEmpty} from '../../lib/utils';
import {MODE_TYPES} from '../../utils/constants';

// Component to show on Issue column which displays link which navigates to activity on enquiry mode
export const SingleLink = ({values}: {values: any}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<any>();
  return (
    <span
      role="presentation"
      className="blueLink cursor-pointer"
      title={values.row[values.field]}
      onClick={() => {
        dispatch(updateSelectedCustomer(values.row));
        showCommunicate(
          {
            ...values.row,
            tabName: constants.EMAIL_TAB,
            newActivity: true,
            ModeType: MODE_TYPES.activityOnEnquiry,
            source: constants.HOMEPAGE,
          },
          navigate,
          location,
        );
      }}
    >
      {isEmpty(values.row[values.field])
        ? constants.BLANK_ISSUE
        : values.row[values.field]}
    </span>
  );
};
