import {useEffect, useState} from 'react';
import CachedIcon from '@mui/icons-material/Cached';
import {DataGrid} from '../../../../ui/data';
import {fetchTaggedInvoices} from '../../../../services/payment';
import {taggedInvoiceColumns} from './taggedInvoicesColumns';
import {getUserData} from '../../../../store/actions/userActions';
import {Button} from '../../../../ui/inputs';
import {constants} from '../../../../constants/common';

const TaggedInvoices = ({taskActKey}: {taskActKey: any}) => {
  const {userKey} = getUserData();

  const [taggedInvoices, setTaggedInvoices] = useState<any[]>([]);
  const [taggedInvoicesLoading, setTaggedInvoicesLoading] =
    useState<boolean>(false);

  const getTaggedInvoices = () => {
    setTaggedInvoicesLoading(true);
    const options = {
      taskActKey,
      mgdUserKey: userKey,
    };
    fetchTaggedInvoices(options).then(response => {
      setTaggedInvoices(response.data);
      setTaggedInvoicesLoading(false);
    });
  };

  const onRefresh = () => {
    getTaggedInvoices();
  };

  useEffect(() => {
    getTaggedInvoices();
  }, []);

  return (
    <div>
      <div>
        <Button startIcon={<CachedIcon />} onClick={onRefresh}>
          {constants.REFRESH_LIST_DETAILS}
        </Button>
      </div>
      <DataGrid
        disableVirtualization
        columns={taggedInvoiceColumns}
        rows={taggedInvoices}
        loading={taggedInvoicesLoading}
        checkboxSelection={false}
        height={400}
        getRowId={row => row.id}
      />
    </div>
  );
};

export default TaggedInvoices;
