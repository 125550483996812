import dayjs from 'dayjs';
import {constants} from '../../constants/common';
import {SingleLink} from './RowComponents';

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

export const homeDashboardColumns = [
  {
    field: 'custName',
    headerName: 'Customer Name',
    minWidth: 250,
    type: 'string',
    sortable: false,
    renderCell: params => <strong>{params.value}</strong>,
  },
  {
    field: 'description',
    headerName: 'Issue',
    minWidth: 280,
    type: 'string',
    sortable: false,
    renderCell: cellValues => {
      return <SingleLink values={cellValues} />;
    },
  },
  {
    field: 'activityId',
    headerName: 'Activity Type',
    minWidth: 150,
    type: 'string',
    sortable: false,
  },
  {
    field: 'followupDate',
    headerName: 'FollowUp Date',
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_TIME_FORMAT)
        : '',
    minWidth: 200,
    type: 'date',
    sortable: false,
  },
  {
    field: 'dateEntered',
    headerName: 'Activity Date',
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_TIME_FORMAT)
        : '',
    minWidth: 200,
    type: 'date',
    sortable: false,
  },
];
