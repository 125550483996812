function CopyAttachmentMessageTemplate({data, className, message}) {
  return (
    <div className="p-2 d-flex align-items-center text-bold">
      {data?.length > 0 &&
        data.map((file, index) => (
          <span
            className={`${
              className || ''
            } me-2 overflow-ellipse w-auto max-width-60-per`}
            key={file.fileName || index}
          >
            {file?.fileName}
            {data?.length > 1 && data?.length !== data[data.length - 1] && ', '}
          </span>
        ))}
      {data?.length > 0 && message}
    </div>
  );
}

export default CopyAttachmentMessageTemplate;
