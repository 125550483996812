import moment from 'moment';

const dateTimeFormat = 'M/DD/YYYY h:mm:s A';

export const portalAccessColumns = () => {
  return [
    {
      sortable: true,
      field: 'AccessDate',
      headerStyle: {width: '200px'},
      header: 'Access Date',
      body: rowData =>
        rowData?.AccessDate
          ? moment.utc(rowData?.AccessDate).format(dateTimeFormat)
          : null,
    },
    {
      sortable: true,
      field: 'ExpireDate',
      headerStyle: {width: '200px'},
      header: 'Expire Date',
      body: rowData =>
        rowData?.ExpireDate
          ? moment.utc(rowData?.ExpireDate).format(dateTimeFormat)
          : null,
    },
    {
      sortable: true,
      field: 'AccessCountLeft',
      header: 'Access Count Left',
      headerStyle: {minWidth: ''},
    },
    {
      sortable: true,
      field: 'LastAcessDate',
      headerStyle: {width: '200px'},
      header: 'Last Access Date',
      body: rowData =>
        rowData?.LastAcessDate
          ? moment.utc(rowData?.LastAcessDate).format(dateTimeFormat)
          : null,
    },
    {
      sortable: true,
      field: 'AccessCount',
      header: 'Access Count',
    },
    {
      sortable: true,
      field: 'ContactName',
      header: 'Contact Name',
    },
  ];
};
