import React, {useRef, useEffect} from 'react';

function useOutsideAlerter(ref, setShowTooltip) {
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowTooltip(false);
      } else {
        setShowTooltip(false);
      }
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

export default function OutsideAlerter(props) {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, props.setShowTooltip);
  return <span ref={wrapperRef}>{props.children}</span>;
}
