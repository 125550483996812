import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getUserData, getUserSettings} from '../store/actions/userActions';
import {useQueryKeys} from './useQueryKeys';

export const useUserSettings = () => {
  const {
    user: userSettings,
    options: userOptions,
    features: userFeatures,
  } = useSelector(store => store.usersReducer.userSettings);
  const {groupKey} = getUserData() || {};
  const {userKey} = useQueryKeys();
  const dispatch = useDispatch();
  const [userId, setUserId] = useState();

  // TODO: To be removed when settings is moved to react
  useEffect(() => {
    if (userKey && groupKey) {
      dispatch(getUserSettings({userKey, groupKey}));
    }
  }, [dispatch, groupKey, userKey]);

  useEffect(() => {
    if (userSettings.userAccountKey) {
      setUserId(userSettings.userAccountKey);
    }
  }, [userSettings]);

  return {
    userSettings,
    userOptions,
    userFeatures,
    userId,
  };
};
