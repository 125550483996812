import dayjs from 'dayjs';
import {DateRange} from '@mui/x-date-pickers-pro';
import {constants} from '../../../../constants/common';

export const aggregateData = (data: any[], columns: any[]) => {
  const aggregatedData: Record<string, any> = {
    statusDesc: 'Total',
    id: '000001',
  };

  // Calculate column sums
  columns.forEach(column => {
    if (column.type === 'number') {
      const columnName = column.field;
      aggregatedData[columnName] = data.reduce(
        (sum, row) => sum + (row[columnName] || 0),
        0,
      );
    }
  });

  return aggregatedData;
};

export const runDropDownValues = {
  HOURLY: 0,
  DAILY: 1,
  MONTHLY: 2,
} as const;

export const runDropDown = [
  {label: constants.HOURLY, value: runDropDownValues.HOURLY},
  {label: constants.DAILY, value: runDropDownValues.DAILY},
  {label: constants.MONTHLY, value: runDropDownValues.MONTHLY},
] as const;

export const hourlyDropDown = [
  {label: constants.Every_1_Hour, value: 1},
  {label: constants.Every_2_Hour, value: 2},
  {label: constants.Every_3_Hour, value: 3},
  {label: constants.Every_4_Hour, value: 4},
  {label: constants.Every_6_Hour, value: 6},
  {label: constants.Every_8_Hour, value: 8},
  {label: constants.Every_12_Hour, value: 12},
  {label: constants.Every_24_Hour, value: 24},
];

export const getMonthlyDropDown = () => {
  const monthlyDropDown: {
    label: string;
    value: string;
  }[] = [];
  for (let i = 1; i <= 28; i += 1) {
    monthlyDropDown.push({label: String(i), value: String(i)});
  }
  return monthlyDropDown;
};

export const convertStringToTime = (value: string) => {
  return dayjs(`${dayjs().format('YYYY-MM-DD')} ${value}`);
};

export const convertDateTimeToTimeString = (value: DateRange<dayjs.Dayjs>) => {
  const time: {startTime: string; endTime: string} = {
    startTime: '',
    endTime: '',
  };
  if (value[0]) {
    time.startTime = value[0].format('HH:mm:ss');
  }
  if (value[1]) {
    time.endTime = value[1].format('HH:mm:ss');
  }

  return time;
};

export const convertDateTimeToString = (
  value: dayjs.Dayjs,
  format: string = 'YYYY-MM-DDTHH:mm:ssZ',
) => {
  const reversedDate = dayjs.utc(value).local();
  return reversedDate.format(format);
};
