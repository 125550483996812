import {constants} from '../../../constants/common';

export const SelectHeader = () => {
  return (
    <div className="d-flex justify-content-center px-2 py-1">
      <div className="w-300-px">{constants.CUSTOMER_ID_AND_NAME}</div>
      <div className="width-100-px">{constants.COMPANY_ID}</div>
      <div className="width-100-px">{constants.ASSIGNED_USER}</div>
    </div>
  );
};
