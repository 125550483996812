import {constants} from '../constants/common';
import {getConfig} from '../lib/utils';

export const MODE_TYPES = {
  new: 0,
  enquiry: 1,
  activityOnEnquiry: 2,
};

export const REPLY_TYPE = {
  reply: 1,
  replyAll: 2,
  forward: 3,
  other: 0,
};

export const MAIL_STATUS = {
  unread: 2,
  read: 3,
  queued: -2,
  error: -1,
};

export const ISSUE_STATUS = {
  complete: 1,
  inComplete: 0,
};

export const TRAN_TYPE = {
  CR: 'CR',
  IN: 'IN',
  CM: 'CM',
  RV: 'RV',
  PP: 'PP',
};

export const MAX_EXCEL_DOWNLOAD = 5000;

export const SURCHARGE_STATUS_CODE = {
  SUCCESS: 0,
  NO_INVOICE_TO_GET_SURCHARGE_FEE: 1,
  FAILED: 2,
  NOT_SUPPORTED_FOR_ACTIVE_MERCHANT: 3,
  NO_PAYMENT_METHOD_AVAILABLE: 4,
  ONE_OR_MORE_NEGATIVE_TRANSACTION: 5,
  Warning: 6,
};

export const DEFAULT_PAYMENT_METHOD = 1;

export const PAYMENT_ERROR_CODES = {
  CARD_DECLINED: '5',
  OTHER: '-1',
};

export const CALL_STATUS = {
  QUEUED: 0,
  SUCCESSFUL: 99,
};

export const filterBuilderOperators = [
  {name: 'EqualTo', label: 'EqualTo'},
  {name: 'NotEqualTo', label: 'NotEqualTo'},
  {name: 'LessThan', label: 'LessThan'},
  {name: 'GreaterThan', label: 'GreaterThan'},
  {name: 'LessThanOrEqualTo', label: 'LessThanOrEqualTo'},
  {name: 'GreaterThanOrEqualTo', label: 'GreaterThanOrEqualTo'},
  {name: 'contains', label: 'contains'},
  {name: 'beginsWith', label: 'begins with'},
  {name: 'endsWith', label: 'ends with'},
  {name: 'doesNotContain', label: 'does not contain'},
  {name: 'doesNotBeginWith', label: 'does not begin with'},
  {name: 'doesNotEndWith', label: 'does not end with'},
  {name: 'null', label: 'is null'},
  {name: 'notNull', label: 'is not null'},
  {name: 'in', label: 'in'},
  {name: 'notIn', label: 'not in'},
  {name: 'between', label: 'between'},
  {name: 'notBetween', label: 'not between'},
];

export const creditScoreBgColor = {
  VERY_POOR: '#FF0000',
  POOR_OR_FAIRISH: '#FFC000',
  GOOD: '#92D050',
  VERY_GOOD: '#00B050',
};
// TODO: Need to refactor this function
// eslint-disable-next-line consistent-return
export const lockstepCreditScoreBgColorWithLabel = (creditScore: any) => {
  if (creditScore > 0 && creditScore < 20) {
    return {color: creditScoreBgColor.VERY_POOR, label: constants.VERY_POOR};
  }

  if (creditScore > 20 && creditScore < 40) {
    return {
      color: creditScoreBgColor.POOR_OR_FAIRISH,
      label: constants.POOR,
    };
  }

  if (creditScore > 40 && creditScore < 50) {
    return {
      color: creditScoreBgColor.POOR_OR_FAIRISH,
      label: constants.FAIRISH,
    };
  }

  if (creditScore > 60 && creditScore < 90) {
    return {
      color: creditScoreBgColor.GOOD,
      label: constants.GOOD,
    };
  }

  if (creditScore > 89 && creditScore <= 100) {
    return {
      color: creditScoreBgColor.VERY_GOOD,
      label: constants.VERY_GOOD,
    };
  }
};

export const SESSION_NACM_SUPPORT = {
  NACM: '1',
  CREDIT_SAFE: '2',
};

export const defaultViewMessageMode = 'viewMessages';

export const ONLINE_CM_APPLICATION_TYPE = {
  ALL: 2,
  CREDIT_USER: 1,
  NONE: 0,
};

export const REPORT_TYPE = {
  COMMUNICATION: 'Communication',
  INVOICE: 'Invoice',
  ACCOUNT: 'Account',
};

export const REPORT_OUTPUT_TYPE = {
  PREVIEW: 0,
  Print: 1,
  Email: 2,
  AutoEmail: 3,
};

export const PAGE_SIZE = 20;

export const UDF_LABELS_REF = {
  invoiceUdf: 'udfLabelInvc',
  userPaymentsUdf: 'udfLabelCPmt',
  userContactsUdf: 'udfLabelCntct',
  invoiceLinesUdf: 'udfLabelILine',
  customerUdf: 'udfLabelCust',
  disputeInvoiceUdf: 'udfLabelDispInvc',
} as const;

export const loginReturnUrl = `/connect/authorize?client_id=appid&redirect_uri=${
  getConfig().apiEndpoint
}/swagger/&response_type=code+id_token&nonce=8d2d9e66-2344-47b0-8784-409e34533596&scope=openid+profile+api+offline_access`;
