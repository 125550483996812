import {
  IAlertSchedule,
  ICreateOrUpdateAlerts,
  IDeleteAlertsOrSchedule,
  IDeleteSchedule,
  IGetAlertSchedule,
  IProcessAlertsPayload,
} from '../components/common/alerts/interface';
import data from '../lib/data';

export const getAlertsList = (options: {
  GroupKey: string;
  ViewName: string;
}) => {
  const endpoint = `v4/api/alert/for/${options.GroupKey}/Communication`;
  return data.get(endpoint, options);
};

export const createOrUpdateAlerts = (payload: ICreateOrUpdateAlerts) => {
  const endpoint = `v4/api/alert/createorupdate`;
  return data.post(endpoint, payload);
};

export const fetchAlertsUsersDetails = (payload: {
  UserKey: string;
  GroupKey: string;
  SettingsKey: string;
}) => {
  const endpoint = `v4/api/alert/getassigneduser`;
  return data.post(endpoint, payload);
};

export const updateAssignedUsers = (payload: {
  UserKey: string;
  Settingskey: string;
  GroupKey: string;
  IsAssignedUserChecked: boolean;
}) => {
  const endpoint = `v4/api/alert/manageassignuser`;
  return data.post(endpoint, payload);
};

export const createOrUpdateAlertsSchedule = (payload: IAlertSchedule) => {
  const endpoint = `v4/api/alert/schedule/createorupdate`;
  return data.post(endpoint, payload);
};

export const getAlertsSchedule = (payload: IGetAlertSchedule) => {
  const endpoint = `v4/api/alert/schedule/fetch`;
  return data.post(endpoint, payload);
};

export const deleteAlertsOrSchedule = (payload: IDeleteAlertsOrSchedule) => {
  const endpoint = `v4/api/alert/delete`;
  return data.delete(endpoint, {data: payload});
};

export const processAlerts = (payload: IProcessAlertsPayload) => {
  const endpoint = `v4/api/alert/process`;
  return data.post(endpoint, payload);
};

export const deleteAlertsSchedule = (payload: IDeleteSchedule) => {
  const endpoint = `v4/api/alert/schedule/delete`;
  return data.delete(endpoint, {data: payload});
};
