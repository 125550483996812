import {styled} from '@mui/material';

function RenderRedirectUrlTemplate({redirectUrl}) {
  const StyledIframe = styled('iframe')(() => ({
    width: '100%',
    minHeight: '500px',
  }));

  return (
    <StyledIframe
      id="newPaymentIframe"
      title="New Payment method"
      src={redirectUrl}
    />
  );
}

export default RenderRedirectUrlTemplate;
