import {Outlet} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {
  GridColDef,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridLogicOperator,
  GridPaginationModel,
  GridSortModel,
} from '@mui/x-data-grid';
import {useDispatch, useSelector} from 'react-redux';
import {
  DataGrid,
  FilterPopoverProvider,
  generateJsonFromSql,
  QueryReturnValue,
} from '../../../../ui/data';
import {ViewSettings} from './ViewSettings';
import {calculateColumnWidth} from '../../../header/helper';
import {
  cleanUpUserPayments,
  getUserPayments,
  updateUserPaymentsViewSettingObject,
  userPaymentsExportToExcel,
} from '../../../../store/actions/userPaymentsActions';
import {getUserData} from '../../../../store/actions/userActions';
import {useQueryKeys} from '../../../../hooks/useQueryKeys';
import {UserPaymentsColumns} from './UserPaymentsColumn';
import {
  applyDatatableSettings,
  convertAndAppendExistingFilters,
  getGridColumnsSettings,
  getUDFUpdatedSettingsAndColumns,
  hasFilterValue,
  updateColumnSettings,
} from '../../../../lib/commonTableHelpers';
import {useCustKey} from '../../../../hooks/useCustKey';
import UserPaymentsToolbar from './UserPaymentsToolbar';
import {PaymentSummary} from './PaymentSummary';
import {PAGE_SIZE, UDF_LABELS_REF} from '../../../../utils/constants';
import {Box} from '../../../../ui/layouts';
import {
  convertMUIToQueryBuilder,
  generateJsonAndSql,
  initialQuery,
} from '../../../../ui/data/query-builder/queryHelper';
import {gridModeTypes} from '../../../../constants/common';
import ViewSettingsModal from '../../../common/view-settings/ViewSettingsModal';
import {
  ColumnSetting,
  IViewSettings,
} from '../../../common/view-settings/interface';
import {useComponentMountStatus} from '../../../../hooks/useComponentMountStatus';
import {shouldResetFilterModel} from '../../../../utils/filterUtils';

export const UserPayments = () => {
  const dispatch = useDispatch<any>();
  const [columns, setColumns] = useState<any[]>([]);
  const [bestFit, setBestFit] = useState<boolean>(false);
  const [bestFitColumns, setBestFitColumns] = useState<GridColDef[]>([]);
  const [sortColumn, setSortColumn] = useState<GridSortModel>([]);
  const {groupKey} = getUserData();
  const {userKey} = useQueryKeys();
  const {custKey} = useCustKey();
  const [selectedAccounts, setSelectedAccounts] = useState<any[]>([]);
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({});
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
    logicOperator: GridLogicOperator.And,
  });
  const [skip, setSkip] = useState(0);

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: PAGE_SIZE,
  });
  const [settingsKey, setSettingsKey] = useState<string>('');

  const [customFilterSqlValue, setCustomFilterSqlValue] = useState<
    string | null
  >(null);
  const [customFilterJsonValue, setCustomFilterJsonValue] =
    useState<QueryReturnValue['json']>();
  const [showViewSettingsPopup, setShowViewSettingsPopup] =
    useState<boolean>(false);
  const udfDetails = useSelector((store: any) => store.usersReducer.udfDetails);
  const isComponentMounted = useComponentMountStatus(cleanUpUserPayments);

  const {
    userPaymentsSettingId,
    userPaymentsData,
    userPaymentsDataTotal,
    fetchingUserPayments,
    userPaymentsViewSettings,
    userPaymentsCount,
  } = useSelector((store: any) => store.userPaymentsReducer?.userPayments);

  const loadUserPayments = ({
    sortQuery = [],
    settingKey,
    filterQuery = undefined,
    sqlQuery = null,
    pageSizeParam = PAGE_SIZE,
    skipParam = 0,
  }: {
    sortQuery: any[];
    settingKey: string;
    filterQuery: QueryReturnValue['json'] | undefined;
    sqlQuery: string | null;
    pageSizeParam: number;
    skipParam: number;
  }) => {
    const options = {
      userKey,
      groupKey,
      settingKey,
      sortQuery,
      filterQuery,
      sqlQuery,
      skip: skipParam,
      pageSize: pageSizeParam,
    };
    dispatch(getUserPayments(options));
  };

  // Reset filter model
  const resetFilterModel = () => {
    setFilterModel({
      logicOperator: GridLogicOperator.And,
      items: [],
    });
  };

  // reset pagination
  const resetPagination = () => {
    setPaginationModel({
      pageSize: paginationModel.pageSize,
      page: 0,
    });
  };

  useEffect(() => {
    if (settingsKey) {
      loadUserPayments({
        settingKey: settingsKey,
        sortQuery: sortColumn,
        filterQuery: customFilterJsonValue,
        sqlQuery: customFilterSqlValue,
        skipParam: 0,
        pageSizeParam: paginationModel.pageSize,
      });
      resetFilterModel();
      resetPagination();
      setSelectedAccounts([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsKey, userKey]);

  /** On user selection reset selected accounts */
  useEffect(() => {
    if (userKey) {
      setSelectedAccounts([]);
    }
  }, [userKey]);

  useEffect(() => {
    // Here we call update all udf fields method before setting value in data grid
    const updateColumns = async () => {
      if (
        userPaymentsViewSettings &&
        userPaymentsViewSettings.settingsKey &&
        isComponentMounted
      ) {
        const {updatedViewSettings, columnsToDisplay} =
          getUDFUpdatedSettingsAndColumns({
            viewSettings: userPaymentsViewSettings,
            udfDetails,
            columns: UserPaymentsColumns,
            udfLabel: UDF_LABELS_REF.userPaymentsUdf,
          });
        applyDatatableSettings(
          updatedViewSettings,
          columns,
          columnsToDisplay,
          undefined,
          setColumns,
          setColumnVisibilityModel,
          setSortColumn,
        );

        const jsonData =
          userPaymentsViewSettings?.filter?.length > 0
            ? generateJsonFromSql(userPaymentsViewSettings?.filter)
            : initialQuery;
        setCustomFilterSqlValue(userPaymentsViewSettings?.filter || '');
        setCustomFilterJsonValue(jsonData);
        setSettingsKey(userPaymentsViewSettings.settingsKey);
      }
    };
    updateColumns();
  }, [userPaymentsViewSettings?.settingsKey, udfDetails]);

  const onPageChange = (args: GridPaginationModel) => {
    const pageSkip =
      args.pageSize !== paginationModel.pageSize
        ? 0
        : (args.page + 1) * args.pageSize - args.pageSize;
    setPaginationModel({
      pageSize: args.pageSize,
      page: args.pageSize !== paginationModel.pageSize ? 0 : args.page,
    });
    setSkip(pageSkip);
    loadUserPayments({
      settingKey: userPaymentsSettingId,
      sortQuery: sortColumn,
      filterQuery: customFilterJsonValue,
      sqlQuery: customFilterSqlValue,
      pageSizeParam: args.pageSize,
      skipParam: pageSkip,
    });
  };

  const onSortChange = (args: GridSortModel) => {
    setSortColumn(args);
    loadUserPayments({
      settingKey: userPaymentsSettingId,
      sortQuery: args,
      filterQuery: customFilterJsonValue,
      sqlQuery: customFilterSqlValue,
      skipParam: skip,
      pageSizeParam: paginationModel.pageSize,
    });
  };

  const handleGetUserPayments = (
    filterQuery: QueryReturnValue['json'],
    sqlQuery: string | null,
  ) => {
    loadUserPayments({
      settingKey: userPaymentsSettingId,
      sortQuery: sortColumn,
      filterQuery,
      sqlQuery,
      skipParam: skip,
      pageSizeParam: paginationModel.pageSize,
    });
    resetPagination();
  };

  const onColumnRowSelect = (newSelectionModel: any) => {
    setSelectedAccounts(newSelectionModel);
  };

  const onFilterChange = (args: GridFilterModel) => {
    const queryBuilderQuery = convertMUIToQueryBuilder(args);
    const valueToConvert = convertAndAppendExistingFilters(
      {...customFilterJsonValue},
      queryBuilderQuery,
      filterModel,
    );
    const {json, sql} = generateJsonAndSql(valueToConvert);

    setFilterModel(args);
    setCustomFilterSqlValue(json.rules.length > 0 ? sql : null);
    setCustomFilterJsonValue(json);
    if (hasFilterValue(args)) {
      handleGetUserPayments(json, json.rules.length > 0 ? sql : null);
    }
  };

  const onQueryFilterApply = (data: QueryReturnValue) => {
    // if filter is empty then reset filter model
    if (shouldResetFilterModel(data)) {
      resetFilterModel();
    }
    setCustomFilterSqlValue(data.sql);
    setCustomFilterJsonValue(data.json);
    handleGetUserPayments(
      data.json,
      data.json.rules.length > 0 ? data.sql : null,
    );
  };

  const exportToExcel = () => {
    dispatch(
      userPaymentsExportToExcel({
        custKey,
        userKey,
        groupKey,
        settingKey: userPaymentsSettingId,
        filterQuery: customFilterJsonValue,
        sqlQuery: customFilterSqlValue,
        skip,
        pageSize: paginationModel.pageSize,
      }),
    );
  };

  const onRefresh = () => {
    loadUserPayments({
      settingKey: userPaymentsSettingId,
      sortQuery: sortColumn,
      filterQuery: customFilterJsonValue,
      sqlQuery: customFilterSqlValue,
      skipParam: skip,
      pageSizeParam: paginationModel.pageSize,
    });
  };

  // Handling best-fit (Column resizing)
  const applyBestFit = () => {
    const bestFitColumnsTemp = columns.map(column => {
      const minWidth = calculateColumnWidth(column.field);
      return {
        ...column,
        minWidth,
      };
    });
    setBestFit(!bestFit);
    setBestFitColumns(bestFitColumnsTemp);
  };

  const updateTableOnColumnSettingsChange = (
    updatedColumnsList: ColumnSetting[],
    settingKey: string,
  ) => {
    if (settingKey === userPaymentsViewSettings?.settingsKey) {
      updateColumnSettings(
        updatedColumnsList,
        columns,
        setColumns,
        setColumnVisibilityModel,
      );
    }
  };

  const onViewSettings = () => {
    setShowViewSettingsPopup(true);
  };

  const handleCloseViewSettingsPopup = () => {
    setShowViewSettingsPopup(false);
  };

  const {columnSettingJson, sortSettingJson} = getGridColumnsSettings(
    userPaymentsViewSettings?.columnSetting,
    columnVisibilityModel,
    sortColumn,
  );

  return (
    <>
      <Outlet context={{onReload: onRefresh}} />
      <ViewSettingsModal
        viewName="UserPayments"
        mode={gridModeTypes.UserPaymentHistory}
        updateTableOnColumnSettingsChange={updateTableOnColumnSettingsChange}
        handleCloseViewSettingsPopup={handleCloseViewSettingsPopup}
        customFilterSqlValue={customFilterSqlValue || ''}
        sortSettingJson={JSON.stringify(sortSettingJson) || ''}
        columnSettingJson={JSON.stringify(columnSettingJson) || ''}
        loadViewSetting={(row: IViewSettings) => {
          dispatch(
            updateUserPaymentsViewSettingObject({
              settingId: row.settingsKey,
              viewSettings: row,
            }),
          );
        }}
        showViewSettingsPopup={showViewSettingsPopup}
      />
      <Box
        display="flex"
        marginY="12px"
        alignItems="center"
        justifyContent="space-between"
      >
        <ViewSettings />
        <PaymentSummary paymentSummary={userPaymentsDataTotal} />
      </Box>
      <FilterPopoverProvider>
        <DataGrid
          disableVirtualization
          columns={bestFit ? bestFitColumns : columns}
          rows={userPaymentsData}
          loading={fetchingUserPayments}
          rowCount={userPaymentsCount}
          checkboxSelection
          sortModel={sortColumn}
          onSortChange={onSortChange}
          onFilterChange={onFilterChange}
          filterModel={filterModel}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityChange={data => setColumnVisibilityModel(data)}
          rowsSelected={selectedAccounts}
          onColumnRowSelect={onColumnRowSelect}
          CustomToolbar={UserPaymentsToolbar}
          disableMultipleColumnsSorting
          customToolbarMethods={{
            exportToExcel,
            onRefresh,
            applyBestFit,
            onViewSettings,
          }}
          onPageChange={onPageChange}
          showCustomFilters
          customFilterSqlValue={customFilterSqlValue}
          onQueryFilterApply={data => onQueryFilterApply(data)}
          getRowId={row => row.uniqueKey}
          headerFilters
          paginationModel={paginationModel}
        />
      </FilterPopoverProvider>
    </>
  );
};
