import {useDispatch, useSelector} from 'react-redux';

import {ViewSettingsDropDown} from '../../common/view-settings/view-settings-dropdown/ViewSettingsDropDown';
import {updateAllAccountsViewSettingObject} from '../../../store/actions/allAccountsActions';
import {Box} from '../../../ui/layouts';
import {gridModeTypes} from '../../../constants/common';

export const ViewSettings = () => {
  const {settingId, viewSettings} = useSelector(
    (store: any) => store.allAccountsReducer,
  );

  const dispatch = useDispatch();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      marginY="12px"
      width="100%"
    >
      <Box width="250px" marginLeft="auto">
        <ViewSettingsDropDown
          settingId={settingId}
          viewName="Accounts"
          onViewSettingSelected={(selectedSettingId, selectedViewSettings) =>
            dispatch(
              updateAllAccountsViewSettingObject({
                settingId: selectedSettingId,
                viewSettings: selectedViewSettings,
              }),
            )
          }
          currentViewSettings={viewSettings}
          activityType={gridModeTypes.AllAccounts}
          isQueryParamDefault
        />
      </Box>
    </Box>
  );
};
