import data from '../lib/data';
import {isEmpty} from '../lib/utils';
import {downloadBlobFileGetApi, downloadBlobFilePostApi} from './download';

export const fetchActivities = endpoint =>
  data
    .get(endpoint)
    .then(res => res.data)
    .catch(e => e);

export const fetchActivitiesForHighlightedRow = (userKey, taskKey) => {
  const endpoint = `v4/api/activity/for/${userKey}/${taskKey}`;
  return data.get(endpoint);
};

export const fetActivityInfo = (requestPayload, copyNotesEmailBtnClicked) => {
  let endpoint = 'v4/api/activity';
  if (copyNotesEmailBtnClicked) {
    endpoint = `${endpoint}/for/prioractivity`;
  } else {
    endpoint += '/load/info';
  }
  return data.post(endpoint, requestPayload);
};

export const fetchActivityReportInfo = groupKey => {
  const endpoint = `v4/api/activity/reportinfo/for/${groupKey}`;
  return data.get(endpoint);
};
export const fetchActivityAttachments = workSessionId => {
  const endpoint = `v4/api/activity/attachments/for/${workSessionId}`;
  return data.get(endpoint);
};
export const fetchActivityTypeList = groupKey => {
  const endpoint = `v4/api/activity/dropdown/for/${groupKey}/7`;
  return data.get(endpoint);
};

export const downloadActivityAttachment = (groupKey, attachment) => {
  const endpoint = `v4/api/emailviewer/for/${groupKey}/downloademailattachment/`;
  return downloadBlobFilePostApi(endpoint, attachment);
};

export const fetchTaggedInvoices = (request, activitiesState) => {
  const {skip, pageSize, ...restRequest} = request;
  let endpoint = `v4/api/activity/taggedinvoices?`;

  if (!isEmpty(skip)) {
    endpoint = `${endpoint}&%24skip=${skip}`;
  }

  if (!isEmpty(pageSize)) {
    endpoint += `&%24top=${pageSize}`;
  }

  if (!isEmpty(request.filterQuery)) {
    endpoint = `${endpoint}&%24filter=${encodeURIComponent(
      request.filterQuery,
    )}`;
  }
  if (!isEmpty(activitiesState.totalsOnly)) {
    endpoint = `${endpoint}&%24totalsonly=${activitiesState.totalsOnly}`;
  }

  return data.post(endpoint, restRequest);
};

export const fetchActivitiesContacts = options => {
  let endpoint;
  if (options.bulkActivity) {
    endpoint = `v2/api/activity/bulk/email/contacts/for/${options.userKey}?$totalsonly=${options.totalsOnly}`;
  } else if (options.isVoiceTab) {
    endpoint = `v2/api/activity/for/${options.groupKey}/${options.custKey}/voice/contacts?$totalsonly=${options.totalsOnly} `;
  } else if (options.isTextTab) {
    endpoint = `v2/api/activity/for/${options.groupKey}/${options.custKey}/text/contacts?$totalsonly=${options.totalsOnly}`;
  } else {
    endpoint = `v2/api/activity/for/${options.groupKey}/${options.custKey}/${options.userId}/email/contacts?$totalsonly=${options.totalsOnly}`;
  }

  if (options.filterQuery) {
    endpoint = `${endpoint}&%24filter=${encodeURIComponent(
      options.filterQuery,
    )}`;
  }

  return data.get(endpoint);
};

export const saveActivitiesContact = options => {
  const endpoint = `v2/api/activity/email/contact`;
  return data.post(endpoint, options);
};

export const saveActivitiesPersonalContact = options => {
  const endpoint = `v2/api/activity/email/personal/contact`;
  return data.post(endpoint, options);
};

export const updatePersonalContact = options => {
  const endpoint = `v2/api/activity/email/contact`;
  return data.put(endpoint, options);
};

export const deleteContact = options => {
  const endpoint = `v2/api/activity/email/contact`;
  return data.delete(endpoint, {
    data: {
      EmailAddress: options.emailAddr,
      UserKey: options.userKey,
      GroupKey: options.groupKey,
    },
  });
};

export const fetchAllContacts = options => {
  let endpoint = `v2/api/activity/for/${options.groupKey}/${options.custKey}/email/contacts?`;

  if (!isEmpty(options.skip)) {
    endpoint += `&%24skip=${options.skip}`;
  }

  if (!isEmpty(options.pageSize)) {
    endpoint += `&%24top=${options.pageSize}`;
  }

  if (!isEmpty(options.filterQuery)) {
    endpoint = `${endpoint}&%24filter=${encodeURIComponent(
      options.filterQuery,
    )}`;
  }
  return data.get(endpoint);
};

export const fetchDropDownTemplate = ({groupKey, type}) => {
  const endpoint = `v4/api/emailviewer/dropdown/for/${groupKey}/${type}`;
  return data.get(endpoint);
};

export const fetchTemplateDetailsByKey = request => {
  const endpoint = `v4/api/emailviewer/for/emailtemplate`;
  return data.post(endpoint, request);
};

export const editEmailSignature = request => {
  const endpoint = `v4/api/emailviewer/for/emailsignature`;
  return data.post(endpoint, request);
};

export const deleteEmailSignature = ({userKey, groupKey}) => {
  const endpoint = `v4/api/emailviewer/signature/${userKey}/${groupKey}`;
  return data.delete(endpoint);
};

export const fetchEmailSignature = ({userKey, groupKey, custKey}) => {
  const endpoint = `v4/api/emailviewer/signature/for/${groupKey}/${custKey}/${userKey}`;
  return data.get(endpoint);
};

export const saveActivity = options => {
  const endpoint = `v4/api/activity/save`;
  return data.post(endpoint, options);
};
export const uploadTaggedInvoice = ({workSessionID, groupKey}) => {
  const endpoint = `v4/api/activity/for/attachTaggedInvoices/${groupKey}/${workSessionID}`;
  return data.get(endpoint);
};

export const uploadAttachments = ({
  StorageType,
  BlobPath,
  GroupKey,
  Files,
  workSessionId,
}) => {
  const formData = new FormData();
  formData.append('StorageType', StorageType);
  formData.append('BlobPath', BlobPath);
  formData.append('GroupKey', GroupKey);
  formData.append('workSessionId', workSessionId);
  Files.forEach(file => {
    formData.append('Files', file);
  });

  const endpoint = `v4/api/activity/for/attachments/fileupload`;
  const headers = {'Content-Type': 'multipart/form-data'};
  return data.post(endpoint, formData, headers);
};

export const fetchDisputeCode = groupKey => {
  const endpoint = `v2/api/disputecode/dropdown/for/${groupKey}`;
  return data.get(endpoint);
};

export const updateAllTaggedInvoice = options => {
  const endpoint = `transactions?api-version=2.0`;
  return data.put(endpoint, options);
};

export const deleteFile = ({fileName, workSessionId}) => {
  const formData = new FormData();
  formData.append('FileName', fileName);
  formData.append('WorkSessionId', workSessionId);

  const endpoint = `/v4/api/activity/for/deleteAttachment`;
  const headers = {'Content-Type': 'multipart/form-data'};
  return data.post(endpoint, formData, headers);
};
export const checkUnCheckFile = ({
  fileName,
  workSessionId,
  excludeFromEmail,
}) => {
  const formData = new FormData();
  formData.append('FileName', fileName);
  formData.append('WorkSessionId', workSessionId);
  formData.append('ExcludeFromEmail', excludeFromEmail);

  const endpoint = `/v4/api/activity/for/attachmentExcludeFromEmail`;
  const headers = {'Content-Type': 'multipart/form-data'};
  return data.post(endpoint, formData, headers);
};

export const markMailAsUnread = options => {
  const endpoint = `v4/api/activity/for/mailStatus/${options.worksessionId}/${options.mailKey}/${options.issueStatus}/${options.emailStatus}`;
  return data.put(endpoint);
};

export const resendMail = options => {
  const endpoint = 'v4/api/emailviewer/for/resend';
  return data.post(endpoint, options);
};

export const copyAttachmentsToCustDocsTemplateService = (groupKey, options) => {
  const endpoint = `v4/api/activity/for/${groupKey}/attachments/Copyfile`;
  return data.post(endpoint, options);
};

export const downloadInvoiceFile = (groupKey, invoiceKey) => {
  const endpoint = `v2/api/invoice/for/${groupKey}/downloadInvoice/${invoiceKey}`;
  return downloadBlobFileGetApi(endpoint);
};

export const exportActivitiesOverview = (
  activitiesOverviewState,
  {
    custKey: CustKey,
    taskKey: TaskKey,
    userKey: UserKey,
    groupKey: GroupKey,
    isArchived: IsArchived,
    settingKey: SettingKey,
  },
) => {
  let endpoint = `v4/api/export/ActivitiesForCustomerAndUser?`;
  const payload = {
    GroupKey,
    CustKey,
    UserKey,
    IsArchived,
    TaskKey,
    SettingKey,
  };

  const orderby = activitiesOverviewState?.sortBy;
  const sortorder =
    activitiesOverviewState?.sortDirection === 1 ? 'asc' : 'desc';

  if (
    !isEmpty(activitiesOverviewState?.sortBy) &&
    !isEmpty(activitiesOverviewState?.sortDirection)
  ) {
    endpoint = `${endpoint}&%24orderby=${orderby}&%24sortorder=${sortorder}`;
  }
  if (!isEmpty(activitiesOverviewState.activitiesOverviewSkip)) {
    endpoint = `${endpoint}&%24skip=${activitiesOverviewState.activitiesOverviewSkip}`;
  }

  if (!isEmpty(activitiesOverviewState.activitiesOverviewPageSize)) {
    endpoint += `&%24top=${activitiesOverviewState.activitiesOverviewPageSize}`;
  }
  if (!isEmpty(activitiesOverviewState.activitiesOverviewGlobalFilter)) {
    endpoint += `&%24filter=${encodeURIComponent(
      activitiesOverviewState.activitiesOverviewGlobalFilter,
    )}`;
  }

  return downloadBlobFilePostApi(endpoint, payload);
};

export const issueCompleteAndActivityCompleteService = ({
  taskKey,
  isActivity,
  taskActKey,
  isChecked,
}) => {
  const endpoint = `v4/api/accountoverview/for/${taskKey}/${isActivity}/${taskActKey}/${isChecked}`;
  return data.post(endpoint);
};

export const fetchCustomerPaymentDetails = customerPaymentKey => {
  const endpoint = `v4/api/accountoverview/for/${customerPaymentKey}/paymentdetails`;
  return data.get(endpoint);
};

export const updatePaymentDetails = (custPmtKey, options) => {
  const endpoint = `v4/api/accountoverview/for/${custPmtKey}/updatepaymentdetails`;
  return data.put(endpoint, options);
};

export const updateInvoiceDetails = options => {
  const endpoint = `v2/api/invoice/for/updateinvoiceinfo`;
  return data.put(endpoint, options);
};

export const fetchAllActiveContacts = ({groupKey, custKey}) => {
  const endpoint = `v4/api/emailviewer/for/${groupKey}/${custKey}/contacts`;
  return data.get(endpoint);
};

export const updateKeepInSync = (custPmtKey, keepInSync) => {
  const endpoint = `v4/api/accountoverview/for/${custPmtKey}/updatepayment?doNotSync=${keepInSync}`;
  return data.put(endpoint);
};

export const cleanUpWorkTables = workSessionId => {
  const endpoint = `v4/api/activity/${workSessionId}/worktablescleanup`;
  return data.delete(endpoint);
};

export const makeAPhoneCall = options => {
  const endpoint = `v4/api/phone/for/${options.groupKey}/makecall`;
  return data.post(endpoint, options);
};

export const getprocedureNote = activityKey => {
  const endpoint = `v4/api/activity/for/${activityKey}/procedureNote`;
  return data.get(endpoint);
};

export const attachReportToCommunicateService = ({
  groupKey,
  custKey,
  workSessionId,
  reportKey,
  reportOutputType,
}) => {
  const payload = {workSessionId, reportOutputType};
  const endpoint = `v4/api/collectreports/for/${groupKey}/${custKey}/${reportKey}`;
  return data.post(endpoint, payload);
};

export const fetchReportsByGroupKeyAndType = ({groupKey, type}) => {
  const endpoint = `v4/api/collectreports/reportInfo/for/${groupKey}/${type}`;
  return data.get(endpoint);
};

export const sendReportForProcess = payload => {
  const endpoint = `v4/api/collectreports/processReport`;
  return data.post(endpoint, payload);
};
