import dayjs from 'dayjs';
import React from 'react';
import {filterOperators} from '../../../../lib/commonTableHelpers';
import {constants} from '../../../../constants/common';
import {Checkbox} from '../../../../ui/inputs';
import {CheckUnCheckFilter} from '../../../common/filter/FilterComponents';
import {ViewApplication} from './RowComponent';
import {CustomerMenu} from '../../../common/CommonRowComponents';
import {InvoiceLink} from '../../../common/RowComponents';

export const UserPaymentsColumns = [
  {
    field: 'custId',
    headerName: 'CustID',
    sortable: true,
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'custName',
    headerName: 'CustName',
    sortable: true,
    minWidth: 180,
    type: 'string',
    filterOperators: filterOperators.string,
    renderCell: cellValues => {
      return <CustomerMenu values={cellValues} />;
    },
  },
  {
    field: 'tranId',
    headerName: 'TranID',
    sortable: true,
    type: 'string',
    filterOperators: filterOperators.string,
    renderCell: cellValues => {
      if (
        cellValues?.row?.tranType?.toLocaleLowerCase() ===
          'CR'.toLocaleLowerCase() ||
        cellValues?.row?.tranType?.toLocaleLowerCase() ===
          'RV'.toLocaleLowerCase()
      ) {
        return <InvoiceLink values={cellValues} />;
      }
      return cellValues?.row?.tranID;
    },
    minWidth: 120,
  },
  {
    field: 'tranDate',
    headerName: 'Pmt Date',
    sortable: true,
    minWidth: 150,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_TIME_FORMAT)
        : '',
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'postDate',
    headerName: 'Post Date',
    sortable: true,
    minWidth: 150,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_TIME_FORMAT)
        : '',
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'tranAmt',
    headerName: 'Payment Amount',
    sortable: true,
    minWidth: 150,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'unappliedAmt',
    headerName: 'Unapplied Amt',
    sortable: true,
    minWidth: 150,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'tranAmtHC',
    headerName: 'Payment Amount HC',
    sortable: true,
    minWidth: 150,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'unappliedAmtHC',
    headerName: 'Unapplied Amt HC',
    sortable: true,
    minWidth: 150,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'pmtRef',
    headerName: 'Check #/Reference',
    sortable: true,
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'tenderTypeId',
    headerName: 'Tender Type',
    sortable: true,
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'tranType',
    headerName: 'Tran Type',
    sortable: true,
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'currId',
    headerName: 'Currency',
    sortable: true,
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'tranCmnt',
    headerName: 'Comment',
    sortable: true,
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: '',
    headerName: 'View Applications',
    sortable: true,
    renderCell: cellValues => {
      return <ViewApplication values={cellValues} />;
    },
    minWidth: 80,
    filterable: false,
  },
  {
    field: 'isPendPmt',
    headerName: 'IsPendPmt',
    sortable: true,
    minWidth: 150,
    type: 'boolean',
    filterOperators: filterOperators.boolean,
    renderCell: cellValues => {
      return <Checkbox disabled checked={cellValues.row.isPendPmt} />;
    },
    renderHeaderFilter: params => <CheckUnCheckFilter {...params} />,
  },
  {
    field: 'userId',
    headerName: 'UserID',
    sortable: true,
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'assignedUserId',
    headerName: 'AssignedUserID',
    sortable: true,
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'companyId',
    headerName: 'CompanyID',
    sortable: true,
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'parentId',
    headerName: 'ParentID',
    sortable: false,
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'pmtDateDays',
    headerName: 'PmtDateDays',
    sortable: true,
    minWidth: 150,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'udf1',
    headerName: 'UDF1',
    sortable: true,
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'udf2',
    headerName: 'UDF2',
    sortable: true,
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'udf3',
    headerName: 'UDF3',
    sortable: true,
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'udf4',
    headerName: 'UDF4',
    sortable: true,
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'udf5',
    headerName: 'UDF5',
    sortable: true,
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'udf6',
    headerName: 'UDF6',
    sortable: true,
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'udf7',
    headerName: 'UDF7',
    sortable: true,
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'udf8',
    headerName: 'UDF8',
    sortable: true,
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'udf9',
    headerName: 'UDF9',
    sortable: true,
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'udf10',
    headerName: 'UDF10',
    sortable: true,
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'tranAmtSC',
    headerName: 'Payment Amount SC',
    sortable: true,
    minWidth: 150,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'unappliedAmtSC',
    headerName: 'Unapplied Amt SC',
    sortable: false,
    minWidth: 150,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'insertDate',
    headerName: 'InsertDate',
    sortable: true,
    minWidth: 150,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_TIME_FORMAT)
        : '',
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'updateDate',
    headerName: 'UpdateDate',
    sortable: true,
    minWidth: 150,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    type: 'date',
    filterOperators: filterOperators.date,
  },
];
