import {useEffect, useState, useRef} from 'react';
import {Toast} from 'primereact';
import {useDispatch, useSelector} from 'react-redux';
import {constants} from '../../constants/common';
import PaymentInformation from './PaymentInformation';
import Backdrop from '../common/Backdrop';

import {
  addOrUpdatePaymentMethod,
  deletePaymentMethod,
  resetNoPaymentMethodFoundError,
  updatePaymentErrorObject,
  updatePaymentInfoCurrentPage,
  updatePaymentInfoLoadedPage,
  updateRedirectURL,
  updateSelectedCurrency,
  viewPaymentMethod,
} from '../../store/actions/paymentsCurrenciesActions';
import RenderPaymentPanel from './RenderPaymentPanel';
import {ONLINE_CM_APPLICATION_TYPE, TRAN_TYPE} from '../../utils/constants';
import {buildPage} from '../../utils/pagingUtils';
import {
  getInterPaymentDetails,
  loadPaymentMethods,
} from '../../utils/paymentUtils';
import {isEmpty} from '../../lib/utils';
import RenderRedirectUrlTemplate from './RenderRedirectUrlTemplate';
import MainLoader from '../common/MainLoader';
import {getMakePaymentButtonStatus} from '../../services/payment';
import {getUserData} from '../../store/actions/userActions';
import {useUserSettings} from '../../hooks/useUserSettings';
import {Dialog} from '../../ui';

function PaymentPanel({
  showPaymentPanel,
  setShowPaymentPanel,
  custKey,
  groupKey,
  showPaymentInfo,
  setShowPaymentInfo,
  selectedCurrency,
  statementOfAccountsSelectedRows,
  paymentAmount,
  discountAmount,
  showAllAccounts,
  setFilterFieldsValues = () => {},
  setFilterFieldsBlur = () => {},
  statementOfAccountsObj,
  isSelectedInvoicesBlur,
  setIsSelectedInvoicesBlur,
  setExternalFilterWithDataGrid,
  allowDiscount,
  filterModel,
  statementCountAndTotals,
  allowOnlineCMApplication,
  closePaymentPanelOnPaymentMade = () => {},
}) {
  const {
    currencies,
    paymentMethods,
    fetchingPaymentMethods,
    fetchingCurrencies,
    paymentInfoPageSize,
    deletedPaymentMethods,
    addedPaymentMethod,
    redirectUrl,
    updatedPaymentMethod,
    deletingPaymentMethod,
    showLoader,
    paymentErrorObj,
    errorMessage,
    madePayment,
    paymentMethodNotFoundError,
    paymentMethodNotFoundErrorMessage,
    paymentInformationLoadedPage,
    paymentInformationCurrentPage,
  } = useSelector(store => store.paymentsCurrenciesReducer);
  const [showAddEditPaymentMethodForm, setShowAddEditPaymentMethodForm] =
    useState(false);
  const dispatch = useDispatch();
  const loading = fetchingPaymentMethods || fetchingCurrencies;
  const toastRef = useRef(null);
  const [showDeletedPaymentMethodsDialog, setShowDeletedPaymentMethodsDialog] =
    useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
    constants.NONE,
  );
  const [interPaymentObj, setInterPaymentObj] = useState({});
  const [paymentNote, setPaymentNote] = useState('');

  const [selectedInvoices, setSelectedInvoices] = useState([]);

  const [paymentIncludesCR, setPaymentIncludesCR] = useState(false);
  const [paymentIncludesCM, setPaymentIncludesCM] = useState(false);

  const [isRequireFieldErrorMessage, setIsRequireFieldErrorMessage] =
    useState(false);
  const [paymentWithOtherTranTypeMessage, setPaymentWithOtherTranTypeMessage] =
    useState('');
  const [currencyIdOnRowSelect, setCurrencyIdOnRowSelect] = useState('');

  const [paymentMethodsObj, setPaymentMethodObj] = useState(paymentMethods);
  const [makePaymentBtnText, setMakePaymentBtnText] = useState('');
  const [makePaymentBtnDisplay, setMakePaymentBtnDisplay] = useState(false);
  const userType = getUserData()?.userType;
  const {userFeatures} = useUserSettings();
  const solupayJWTFeature = userFeatures.find(
    obj => obj.featureId === constants.SOLU_PAY_JWT,
  );

  const interPaymentOptions = {
    selectedPaymentMethod,
    custKey,
    groupKey,
    selectedInvoices:
      allowOnlineCMApplication === ONLINE_CM_APPLICATION_TYPE.ALL
        ? selectedInvoices
        : selectedInvoices.filter(
            statement => statement.tranType !== TRAN_TYPE.CM,
          ),
  };
  const paramObj = {dispatch, custKey};
  const loadPaymentMethodsOptions = {...paramObj, groupKey};
  const onReload = () => loadPaymentMethods(loadPaymentMethodsOptions);
  const [isEditPaymentMethod, setIsEditPaymentMethod] = useState(true);

  const onHideIframe = () => {
    dispatch(updateRedirectURL(''));
    if (madePayment) {
      setShowPaymentPanel(false);
      closePaymentPanelOnPaymentMade();
    }
    onReload();
  };

  useEffect(() => {
    loadPaymentMethods(loadPaymentMethodsOptions);
    dispatch(updatePaymentErrorObject({}));
  }, [custKey]);

  const updateInterPaymentObj = async () => {
    try {
      const res = await getInterPaymentDetails(interPaymentOptions);
      setInterPaymentObj(res);
    } catch (error) {
      // Todo: Handle Error
      console.log(error);
    }
  };

  const selectedRowsIncludesCR = selectedInvoicesTemp =>
    selectedInvoicesTemp.find(
      row =>
        row.tranType.toLocaleLowerCase() === TRAN_TYPE.CR.toLocaleLowerCase(),
    );

  const selectedRowsIncludesCM = selectedInvoicesTemp =>
    selectedInvoicesTemp.find(
      row =>
        row.tranType.toLocaleLowerCase() === TRAN_TYPE.CM.toLocaleLowerCase(),
    );

  const updateIsCROrCMType = selectedRowsTemp => {
    let obj = {};
    if (!isEmpty(selectedRowsIncludesCR(selectedRowsTemp))) {
      obj = {
        ...obj,
        includesCR: true,
      };
    } else {
      obj = {
        ...obj,
        includesCR: false,
      };
    }

    if (!isEmpty(selectedRowsIncludesCM(selectedRowsTemp))) {
      obj = {
        ...obj,
        includesCM: true,
      };
    } else {
      obj = {
        ...obj,
        includesCM: false,
      };
    }
    return obj;
  };

  useEffect(() => {
    const result = updateIsCROrCMType(statementOfAccountsSelectedRows);
    if (result.includesCR) {
      setPaymentIncludesCR(result.includesCR);
      setPaymentWithOtherTranTypeMessage(
        constants.YOU_CAN_NOT_MAKE_A_PAYMENT_AGAINST_A_CR,
      );
    } else {
      setPaymentIncludesCR(result.includesCR);
    }

    if (result.includesCM) {
      setPaymentIncludesCM(result.includesCM);
      setPaymentWithOtherTranTypeMessage(constants.CREDIT_MEMO_MESSAGE);
    } else {
      setPaymentIncludesCM(result.includesCM);
    }

    const selectedInvoicesTemp = statementOfAccountsSelectedRows?.filter(
      row =>
        row.tranType.toLocaleLowerCase() === TRAN_TYPE.IN.toLocaleLowerCase() ||
        row.tranType.toLocaleLowerCase() === TRAN_TYPE.CM.toLocaleLowerCase(),
    );
    setSelectedInvoices(selectedInvoicesTemp);
  }, [statementOfAccountsSelectedRows, statementOfAccountsObj]);

  const handleCurrencyIdOnRowSelect = currId => {
    if (!currencyIdOnRowSelect && isEmpty(selectedCurrency)) {
      setCurrencyIdOnRowSelect(currId);
      dispatch(updateSelectedCurrency(currId));
      setFilterFieldsValues(prev => {
        return {...prev, currId: {value: currId, type: 'string'}};
      });
      setFilterFieldsBlur(true);
    }
  };
  const getButtonStatus = async () => {
    const {
      data: {isButtonDisplay, buttonText},
    } = await getMakePaymentButtonStatus({
      groupKey,
      custKey,
      userType,
      selectedCurrency,
    });
    setMakePaymentBtnText(buttonText);
    setMakePaymentBtnDisplay(isButtonDisplay);
  };

  useEffect(() => {
    if (showPaymentPanel) {
      setIsSelectedInvoicesBlur(true);
      if (statementOfAccountsSelectedRows.length) {
        handleCurrencyIdOnRowSelect(statementOfAccountsSelectedRows[0].currId);
      }
      getButtonStatus();
    }

    if (!showPaymentPanel && !isEmpty(currencyIdOnRowSelect)) {
      setCurrencyIdOnRowSelect('');
    }
  }, [showPaymentPanel, statementOfAccountsSelectedRows]);

  useEffect(() => {
    if (deletedPaymentMethods) {
      setShowDeletedPaymentMethodsDialog(true);
      loadPaymentMethods(loadPaymentMethodsOptions);
    } else if (addedPaymentMethod || updatedPaymentMethod) {
      loadPaymentMethods(loadPaymentMethodsOptions);
      setShowDeletedPaymentMethodsDialog(false);
    }

    if (!madePayment) {
      setIsSelectedInvoicesBlur(true);
      const resultArray = buildPage(1, 10, paymentMethods);
      setPaymentMethodObj(resultArray);
    }

    updateIsCROrCMType([]);
  }, [
    deletedPaymentMethods,
    addedPaymentMethod,
    updatedPaymentMethod,
    madePayment,
  ]);

  const handleAddEdit = ({
    isDefaultMethod,
    isEdit,
    paymentMethod,
    paymentMethodForEdit,
  }) => {
    let options = {
      custKey,
      groupKey,
      IsDefault: isDefaultMethod,
      PaymentMethodId: paymentMethod,
    };
    if (isEdit) {
      options = {
        ...options,
        selectedPaymentMethodKey: paymentMethodForEdit.pmtMethodKey,
      };
    } else {
      setIsEditPaymentMethod(false);
    }

    if (isEmpty(paymentMethod)) {
      setIsRequireFieldErrorMessage(true);
    } else {
      setShowAddEditPaymentMethodForm(false);
      dispatch(addOrUpdatePaymentMethod(isEdit, options, toastRef));
    }
  };

  const onPaymentInfoClick = pmtMethodKey => {
    dispatch(
      viewPaymentMethod({
        groupKey,
        custKey,
        selectedPaymentMethodKey: pmtMethodKey,
      }),
    );
    setIsEditPaymentMethod(false);
  };

  const handleDeletePaymentMethod = pmtMethodKey =>
    dispatch(deletePaymentMethod({groupKey, custKey, pmtMethodKey}));

  const onResetNoPaymentMethodFoundError = () => {
    dispatch(resetNoPaymentMethodFoundError(false));
  };

  useEffect(() => {
    if (isSelectedInvoicesBlur) {
      updateInterPaymentObj();
      setIsSelectedInvoicesBlur(false);
    }
  }, [isSelectedInvoicesBlur]);

  const handleUpdateCurrentPage = page => {
    dispatch(updatePaymentInfoCurrentPage(page));
  };

  const handleUpdateLoadedPage = page => {
    dispatch(updatePaymentInfoLoadedPage(page));
  };

  return (
    showPaymentPanel && (
      <div className="bg-light-grey py-2 px-1 position-relative">
        <Dialog
          open={showLoader || (!showLoader && !isEmpty(redirectUrl))}
          title={constants.PAYMENT_INFO}
          onClose={() => {
            onHideIframe();
            if (!isEditPaymentMethod) {
              setShowDeletedPaymentMethodsDialog(false);
            }
          }}
          maxWidth="md"
          fullWidth
        >
          {showLoader ? (
            <div className="h-100 position-relative d-flex align-items-center justify-content-center">
              <MainLoader />
            </div>
          ) : (
            <RenderRedirectUrlTemplate redirectUrl={redirectUrl} />
          )}
        </Dialog>
        <Toast ref={toastRef} />
        {(fetchingPaymentMethods || fetchingCurrencies) && !showPaymentInfo ? (
          <Backdrop />
        ) : null}

        <Dialog
          open={showPaymentInfo}
          title={constants.PAYMENT_INFO}
          onClose={() => setShowPaymentInfo(false)}
          maxWidth="md"
          fullWidth
        >
          <PaymentInformation
            paymentMethods={paymentMethods}
            onReload={onReload}
            loading={loading}
            handleAddEdit={handleAddEdit}
            custKey={custKey}
            paymentInfoPageSize={paymentInfoPageSize}
            deletedPaymentMethods={deletedPaymentMethods}
            handleDeletePaymentMethod={handleDeletePaymentMethod}
            redirectUrl={redirectUrl}
            onHideIframe={onHideIframe}
            showAddEditPaymentMethodForm={showAddEditPaymentMethodForm}
            setShowAddEditPaymentMethodForm={setShowAddEditPaymentMethodForm}
            deletingPaymentMethod={deletingPaymentMethod}
            showDeletedPaymentMethodsDialog={showDeletedPaymentMethodsDialog}
            setShowDeletedPaymentMethodsDialog={
              setShowDeletedPaymentMethodsDialog
            }
            onPaymentInfoClick={onPaymentInfoClick}
            isRequireFieldErrorMessage={isRequireFieldErrorMessage}
            setIsRequireFieldErrorMessage={setIsRequireFieldErrorMessage}
            paymentMethodNotFoundError={paymentMethodNotFoundError}
            paymentMethodNotFoundErrorMessage={
              paymentMethodNotFoundErrorMessage
            }
            onResetNoPaymentMethodFoundError={onResetNoPaymentMethodFoundError}
            paymentInformationLoadedPage={paymentInformationLoadedPage}
            paymentInformationCurrentPage={paymentInformationCurrentPage}
            handleUpdateCurrentPage={handleUpdateCurrentPage}
            handleUpdateLoadedPage={handleUpdateLoadedPage}
            paymentMethodsObj={paymentMethodsObj}
            setPaymentMethodObj={setPaymentMethodObj}
          />
        </Dialog>
        <RenderPaymentPanel
          currencies={currencies}
          selectedCurrency={selectedCurrency}
          paymentMethods={paymentMethods}
          setShowPaymentInfo={setShowPaymentInfo}
          setShowPaymentPanel={setShowPaymentPanel}
          selectedPaymentMethod={selectedPaymentMethod}
          setSelectedPaymentMethod={setSelectedPaymentMethod}
          interPaymentObj={interPaymentObj}
          paymentNote={paymentNote}
          setPaymentNote={setPaymentNote}
          paymentAmount={paymentAmount}
          discountAmount={discountAmount}
          showAllAccounts={showAllAccounts}
          setFilterFieldsValues={setFilterFieldsValues}
          setFilterFieldsBlur={setFilterFieldsBlur}
          custKey={custKey}
          groupKey={groupKey}
          paymentErrorObj={paymentErrorObj}
          selectedInvoices={selectedInvoices}
          errorMessage={errorMessage}
          paymentIncludesCR={paymentIncludesCR}
          paymentIncludesCM={paymentIncludesCM}
          paymentWithOtherTranTypeMessage={paymentWithOtherTranTypeMessage}
          setIsEditPaymentMethod={setIsEditPaymentMethod}
          setIsSelectedInvoicesBlur={setIsSelectedInvoicesBlur}
          makePaymentBtnText={makePaymentBtnText}
          makePaymentBtnDisplay={makePaymentBtnDisplay}
          setExternalFilterWithDataGrid={setExternalFilterWithDataGrid}
          allowDiscount={allowDiscount}
          solupayJWTFeature={solupayJWTFeature}
          filterModel={filterModel}
          statementCountAndTotals={statementCountAndTotals}
          allowOnlineCMApplication={allowOnlineCMApplication}
        />
      </div>
    )
  );
}

export default PaymentPanel;
