import {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Select} from '../../../../ui/inputs';
import {getViewSettingList} from '../../../../services/viewSettings';
import {getUserData} from '../../../../store/actions/userActions';
import {getQueryParameters} from '../../../../lib/utils';
import {useSessionStorage} from '../../../../hooks/useSessionStorage';
import {ColumnNames, constants, ViewNames} from '../../../../constants/common';
import {getOnlyShowInDropdownSettings} from './viewSettingsHelper';
import {setViewSettingsListState} from '../../../../store/actions/viewSettingsActions';
import {ColumnSetting, IViewSetting} from '../interface';
import {getAlertsList} from '../../../../services/alerts';

type ViewSettingsType = {
  settingId: string;
  settingsKey: string;
  isDefault: boolean;
  show: boolean;
};

const pendingPaymentColumns: ColumnSetting[] = [
  {
    displayText: 'WebhookStatus',
    isDisplay: false,
    dataField: 'webhookStatusDesc',
    columnDataType: 'String',
    orderIndex: 30,
  },
  {
    displayText: 'WebhookMessage',
    isDisplay: false,
    dataField: 'webhookMessage',
    columnDataType: 'String',
    orderIndex: 31,
  },
  {
    displayText: 'WebhookSentDate',
    isDisplay: false,
    dataField: 'webhookSentDate',
    columnDataType: 'DateTime',
    orderIndex: 32,
  },
  {
    displayText: 'Total Surcharge Amount',
    isDisplay: false,
    dataField: 'totalSurchargeAmount',
    columnDataType: 'Decimal',
    orderIndex: 33,
  },
  {
    displayText: 'Is Schedule Payment',
    isDisplay: false,
    dataField: 'isSchpmt',
    columnDataType: 'Boolean',
    orderIndex: 34,
  },
];

const paymentMissingColumns: ColumnSetting[] = [
  {
    displayText: 'TranAmtSC',
    isDisplay: false,
    dataField: 'tranAmtSC',
    columnDataType: 'Decimal',
    orderIndex: 50,
  },
  {
    displayText: 'UnappliedAmtSC',
    isDisplay: false,
    dataField: 'unappliedAmtSC',
    columnDataType: 'Decimal',
    orderIndex: 41,
  },
  {
    displayText: 'InsertDate',
    isDisplay: false,
    dataField: 'insertDate',
    columnDataType: 'DateTime',
    orderIndex: 42,
  },
  {
    displayText: 'UpdateDate',
    isDisplay: false,
    dataField: 'updateDate',
    columnDataType: 'DateTime',
    orderIndex: 43,
  },
];

export const ViewSettingsDropDown = ({
  settingId,
  onViewSettingSelected,
  viewName,
  isQueryParamDefault,
  activityType,
  currentViewSettings,
}: {
  settingId: string;
  onViewSettingSelected: (value: string, viewSettingObj?: any) => void;
  viewName: string;
  isQueryParamDefault: boolean;
  activityType: string;
  currentViewSettings: IViewSetting;
}) => {
  const location = useLocation();
  const [viewSettingsList, setViewSettingsList] = useState<
    {value: string; label: string}[]
  >([]);
  const [viewSettingsResponse, setViewSettingsResponse] = useState<any[]>([]);
  const {groupKey} = getUserData();
  const [{userId}] = useSessionStorage('profile');
  const dispatch = useDispatch();
  const {[viewName]: viewSettings} = useSelector(
    (state: any) => state.viewSettingsReducer,
  );

  /**
   * Gets setting object based on the settings key and
   * invokes callback function received as prop which will update the view settings of parent component
   * @param settingsKey string
   * @param settingsList IViewSetting[]
   */
  const updateViewSetting = (
    settingsKey: string,
    settingsList: IViewSetting[],
  ) => {
    const viewSettingObj = settingsList.find(
      item => item.settingsKey === settingsKey,
    );
    onViewSettingSelected(settingsKey, viewSettingObj);
  };

  /**
   * Fetches view settings from the API
   * @returns Promise<IViewSetting[]>
   */
  const fetchSettings = async () => {
    const res = await getViewSettingList({
      GroupKey: groupKey,
      UserKey: userId,
      SettingId: 'LOAD_DEFAULT',
      ViewName: viewName,
      ViewGroup: '00,',
      Culture: 'en-US',
      Mode: activityType,
    });

    return res.data as Promise<IViewSetting[]>;
  };

  const fetchAlerts = async () => {
    const res = await getAlertsList({
      GroupKey: groupKey,
      ViewName: viewName,
    });

    return res.data as Promise<IViewSetting[]>;
  };

  /**
   * Returns the list of settings that are to be shown in the dropdown
   * @param filteredSettings ViewSettingsType[]
   */
  const getDropDownList = (filteredSettings: ViewSettingsType[]) => {
    return filteredSettings.map((item: ViewSettingsType) => {
      return {label: item.settingId, value: item.settingsKey};
    });
  };

  /**
   *
   * @param defaultSetting {{settingsKey: string}}
   * @param settings IViewSetting[]
   */
  const updateViewSettingBasedOnDefault = (
    defaultSetting: {settingsKey: string} | undefined,
    settings: IViewSetting[],
  ) => {
    if (defaultSetting) {
      updateViewSetting(defaultSetting.settingsKey, settings);
    } else {
      updateViewSetting(constants.EMPTY_GUID_VALUE, settings);
    }
  };

  const getUniqueParams = (searchParams: any[] | URLSearchParams) => {
    const uniqueParams = new URLSearchParams();

    searchParams.forEach((value, key) => {
      if (!uniqueParams.has(key.toString())) {
        uniqueParams.append(key.toString(), value);
      }
    });

    return uniqueParams;
  };

  /**
   * Check for url and get the default setting if setting id is present in the query
   * @param filteredSettings IViewSetting[]
   * @returns IViewSetting | null | undefined
   */
  const getDefaultSettingFromQuery = (filteredSettings: IViewSetting[]) => {
    const searchParams = new URLSearchParams(location.search);
    const uniqueParams = getUniqueParams(searchParams);
    const queryString = uniqueParams.toString();
    const result = getQueryParameters(queryString.replace(/^#\/?/, ''));

    if (result.SettingID) {
      return filteredSettings.find(
        (item: ViewSettingsType) => item.settingId === result.SettingID,
      );
    }

    return null;
  };

  /**
   * Get the default setting from the query params or from the list of settings
   * @param filteredSettings IViewSetting[]
   * @returns IViewSetting | undefined
   */
  const getDefaultSetting = (filteredSettings: IViewSetting[]) => {
    let defaultSetting = null;

    if (isQueryParamDefault && location.search && location.search !== '') {
      defaultSetting = getDefaultSettingFromQuery(filteredSettings);
    }

    // Sort the settings based on isReact and isSystem
    filteredSettings.sort((a, b) => {
      // First, sort by isReact (true first)
      if (a.isReactView !== b.isReactView) {
        return a.isReactView ? -1 : 1;
      }
      // Then, sort by isSystem (false first)
      if (a.isSystem !== b.isSystem) {
        return a.isSystem ? 1 : -1;
      }
      return 0; // If both fields are the same, keep original order
    });

    if (!defaultSetting) {
      defaultSetting = filteredSettings.find(
        (item: ViewSettingsType) =>
          item.isDefault === true &&
          (item.show === true ||
            !filteredSettings.some(
              i => i.isDefault === true && i.show === true,
            )),
      );
    }

    return defaultSetting;
  };

  /**
   * Process the view settings and return the filtered settings and the dropdown list
   * @param settings IViewSetting[]
   */
  const processViewSettings = (settings: IViewSetting[]) => {
    const filteredSettings = getOnlyShowInDropdownSettings(settings);
    setViewSettingsResponse(filteredSettings);
    const defaultSetting = getDefaultSetting(settings);
    const dropDownList = getDropDownList(filteredSettings);

    setViewSettingsList(dropDownList);
    return {dropDownList, defaultSetting, settings};
  };

  /*
   Update columnSettings list with the missing columns
  */
  const addMissingColumnSettings = (
    columnsList: ColumnSetting[],
    missingColumnsList: ColumnSetting[],
  ) => {
    missingColumnsList.forEach(item => {
      const existsInList1 = columnsList.some(
        listItem => listItem.dataField === item.dataField,
      );

      if (!existsInList1) {
        columnsList.push(item);
      }
    });
    return columnsList;
  };

  /*
   Update columnSettings object under viewSettings for the select field
  */
  const updateColumnSettings = (settingsList: IViewSetting[]) => {
    let settings: IViewSetting[] | null = null;
    if (viewName === ViewNames.UserPayments) {
      settings = settingsList.map(setting => {
        const updatedColumnSetting = addMissingColumnSettings(
          setting.columnSetting,
          paymentMissingColumns,
        ).filter(column => column.dataField !== '');
        return {...setting, columnSetting: updatedColumnSetting};
      });
    } else {
      settings = settingsList.map(setting => {
        const updatedColumnSetting = setting.columnSetting.map(column => {
          if (column.dataField === '') {
            return {
              ...column,
              dataField: ColumnNames.Selection.toLowerCase(),
              displayText: ColumnNames.Selection,
            };
          }
          return column;
        });
        return {...setting, columnSetting: updatedColumnSetting};
      });
    }
    if (viewName === ViewNames.PendingPayments) {
      settings = settings.map(setting => {
        const updatedColumnSetting = addMissingColumnSettings(
          setting.columnSetting,
          pendingPaymentColumns,
        );
        return {...setting, columnSetting: updatedColumnSetting};
      });
    }

    return settings;
  };

  /**
   * Fetch view settings from the API and update the view settings based on the default setting
   */
  const fetchViewSettings = async () => {
    try {
      const [settings, alerts] = await Promise.all([
        fetchSettings(),
        fetchAlerts(),
      ]);

      const updatedSettings = updateColumnSettings([...settings, ...alerts]);
      dispatch(setViewSettingsListState(updatedSettings, viewName));
      const {defaultSetting} = processViewSettings(updatedSettings);
      updateViewSettingBasedOnDefault(defaultSetting, updatedSettings);
    } catch (error) {
      // handle error
    }
  };
  // Fetch view settings from api when the user id is available
  useEffect(() => {
    if (userId) {
      fetchViewSettings();
    }
  }, [viewName, userId, location.search]);

  // Update the view settings dropdown when the view settings are updated through view setting modal
  useEffect(() => {
    if (viewSettings && viewSettings.length > 0) {
      processViewSettings(viewSettings);
    }
  }, [viewSettings]);

  return (
    <Select
      label={constants.VIEW_SETTINGS}
      value={settingId || ''}
      items={viewSettingsList}
      formMargin="none"
      fullWidth
      onChange={e => {
        updateViewSetting(e.target.value as string, viewSettingsResponse);
      }}
      renderValue={value => {
        // Find the corresponding item in the list
        const item = viewSettingsList.find(i => i.value === value);
        // If the item exists, return its label. Otherwise, return a view setting selected
        return item ? item.label : currentViewSettings?.settingId || '';
      }}
    />
  );
};
