import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {constants} from '../../constants/common';

function PageNotFound() {
  const {toastRef: toast} = useSelector(store => store.generalReducer);
  useEffect(() => {
    toast?.current.show({
      severity: constants.INFO.toLocaleLowerCase(),
      summary: constants.INFO,
      detail: constants.PLEASE_GO_TO_MAIN_PAGE,
    });
  }, []);
  return <h2>{constants.PAGE_NOT_FOUND}</h2>;
}
export default PageNotFound;
