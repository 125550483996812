import {useDispatch, useSelector} from 'react-redux';
import {useState} from 'react';
import {changeMailStatus} from '../../../store/actions/mailboxActions';
import {getUserData} from '../../../store/actions/userActions';
import EmailTemplate from '../EmailTemplate';
import {Button} from '../../../ui/inputs';
import {Dialog} from '../../../ui';
import {constants} from '../../../constants/common';

// Component to change read and unread message status
export const ReadUnreadMessage = ({values}: {values: any}) => {
  const {groupKey} = getUserData();
  const {sortQuery} = useSelector((store: any) => store.mailboxReducer);
  const {status} = values.row;
  const dispatch = useDispatch();

  return (
    <Button
      className="btn btn-purple py-1 d-flex align-items-center justify-content-center h-30-px m-auto"
      onClick={() =>
        // @ts-ignore

        dispatch(changeMailStatus(values.row, groupKey, sortQuery))
      }
    >
      <span className={`pi pi-${status === 3 ? 'read' : 'unread'}`} />
    </Button>
  );
};

// Component to re-assign email
export const ReAssignMail = ({values}: {values: any}) => {
  const isAdmin = getUserData()?.isAdmin;
  const {enableEnhancedAdmin} = useSelector(
    (store: any) => store.generalReducer,
  );
  const setRefresh = () => {};
  const [reAssignClicked, setReAssignClicked] = useState<boolean>(false);

  return (
    <>
      <Button
        disabled={enableEnhancedAdmin === 1 && !isAdmin}
        aria-hidden="true"
        className="py-1 d-flex align-items-center justify-content-center"
        onClick={() => {
          setReAssignClicked(true);
        }}
        variant="contained"
        size="medium"
        startIcon={<span className="pi pi-assign-mail" />}
      />
      <Dialog
        open={reAssignClicked}
        width={800}
        height={600}
        draggable
        resizable
        title={constants.ASSIGN_MESSAGES}
        onClose={() => {
          setReAssignClicked(false);
        }}
      >
        <EmailTemplate
          isAssignMessagesTemplate
          {...values.row}
          isAssignAllSelected={false}
          onReload={setRefresh}
          navigateBack={() => {
            setReAssignClicked(false);
          }}
          setShowReassignModal={setReAssignClicked}
        />
      </Dialog>
    </>
  );
};
