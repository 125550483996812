import React, {useEffect, useState} from 'react';
import {Button, InputText} from 'primereact';
import {useDispatch, useSelector} from 'react-redux';
import {constants} from '../../constants/common';
import {isEmpty} from '../../lib/utils';
import CustomDataTable from '../common/table/CustomDataTable';
import {personalContactsColumns} from '../common/table/columns/personalContactsColumns';
import {updateTableFilterDisplayType} from '../../store/actions/generalActions';
import {
  addActivitiesPersonalContact,
  deleteActivitiesContact,
  updateActivitiesPersonalContact,
} from '../../store/actions/activitiesActions';
import {getUserData} from '../../store/actions/userActions';
import {useQueryKeys} from '../../hooks/useQueryKeys';

function AddPersonalContactTemplate({loadContactsList = options => {}}) {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    errorMessage: '',
    searchQuery: '',
    addEditContactForm: {
      contactName: '',
      emailAddr: '',
      emailAddressToBeUpdated: '',
    },
  });
  const [showAddOrEditContactForm, setShowAddOrEditContactForm] =
    useState(false);
  const {userKey} = useQueryKeys();
  const [{sessionUserId}] = useSessionStorage('profile');
  const groupKey = getUserData()?.groupKey;
  const deletedContact = useSelector(
    store => store.activitiesReducer.deletedContact,
  );
  const updatedContact = useSelector(
    store => store.activitiesReducer.updatedContact,
  );
  const addedPersonalContact = useSelector(
    store => store.activitiesReducer.addedPersonalContact,
  );
  const fetchingActivitiesContactsList = useSelector(
    store => store.activitiesReducer.fetchingActivitiesContactsList,
  );
  const custKey = useSelector(
    store => store.activitiesReducer.activityInfo?.customerTask?.custKey,
  );
  const personalContactList = useSelector(
    store => store.activitiesReducer.personalContactList,
  );

  const [tableData, setTableData] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const onInputChange = (value, field) => {
    setState(prevState => {
      return {...prevState, [field]: value};
    });
  };

  const onCancelAddEditForm = () => {
    setShowAddOrEditContactForm(false);
    setState(prevState => {
      return {...prevState, addEditContactForm: {}};
    });
  };

  const handleLoadPersonalContact = rest => {
    loadContactsList({
      groupKey,
      custKey,
      userKey,
      userId: sessionUserId,
      bulkActivity: false,
      totalsOnly: true,
      personal: rest.personal,
      filterQuery: rest.filterQuery,
      isVoiceTab: false,
      isTextTab: false,
    });
  };

  useEffect(() => {
    dispatch(updateTableFilterDisplayType('menu'));
    handleLoadPersonalContact({
      filterQuery: 'PersonalContact = 1',
      personal: true,
    });
  }, [deletedContact, updatedContact, addedPersonalContact]);

  useEffect(() => {
    setTableData(personalContactList);
  }, [personalContactList]);

  const onDeletePersonalContact = emailAddr => {
    const options = {
      groupKey,
      userKey,
      emailAddr,
    };
    dispatch(deleteActivitiesContact(options));
  };

  const onEditPersonalContact = rowData => {
    setShowAddOrEditContactForm(true);
    setState(prevState => {
      return {
        ...prevState,
        addEditContactForm: {
          ...prevState.addEditContactForm,
          emailAddr: rowData.emailAddr,
          contactName: rowData.contactName,
          emailAddressToBeUpdated: rowData.emailAddr,
        },
      };
    });
  };

  const onSearchClear = () => {
    onInputChange('', 'searchQuery');
    handleLoadPersonalContact({
      filterQuery: 'PersonalContact = 1',
      personal: true,
    });
  };

  const updatePersonalContact = () => {
    if (isEmpty(state.addEditContactForm.emailAddr)) {
      setErrorMessage(constants.EMAIL_IS_REQUIRED);
    } else {
      const UDFObj = {
        UDF1: '',
        UDF2: '',
        UDF3: '',
        UDF4: '',
        UDF5: '',
        UDF6: '',
        UDF7: '',
        UDF8: '',
        UDF9: '',
        UDF10: '',
      };
      const AddrLines = {
        AddrLine1: '',
        AddrLine2: '',
        AddrLine3: '',
        AddrLine4: '',
        AddrLine5: '',
      };
      setErrorMessage('');
      const options = {
        ContactName: state.addEditContactForm.contactName,
        GroupKey: groupKey,
        UserKey: userKey,
        Fax: '',
        City: '',
        Phone: '',
        State: '',
        Title: '',
        FaxExt: '',
        Comment: '',
        Country: '',
        Notes: '',
        PrintAs: '',
        CheckSum: '',
        PhoneExt: '',
        PostalCode: '',
        LinkedInProfile: '',
        ProfilePictureURL: '',
        ...AddrLines,
        ...UDFObj,
      };

      if (isEditMode) {
        dispatch(
          updateActivitiesPersonalContact({
            ...options,
            EmailAddress: state.addEditContactForm.emailAddr,
            EmailAddressToBeUpdated:
              state.addEditContactForm.emailAddressToBeUpdated,
            contactType: 'personal',
          }),
        );
      } else {
        dispatch(
          addActivitiesPersonalContact({
            ...options,
            EmailAddress: state.addEditContactForm.emailAddr,
            EmailAddressToBeUpdated: '',
            contactType: 'personal',
          }),
        );
      }
      setShowAddOrEditContactForm(false);
    }
  };

  const applySearchFilter = () => {
    if (isEmpty(state.searchQuery)) {
      handleLoadPersonalContact({
        filterQuery: `PersonalContact = 1`,
        personal: true,
      });
    } else {
      handleLoadPersonalContact({
        filterQuery: `PersonalContact = 1 and EmailAddr like ${state.searchQuery}`,
        personal: true,
      });
    }
  };

  return (
    <>
      <div className="personal-contacts-header d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center mb-3 flex-wrap-reverse">
          <InputText
            className="m-1 me-3  h-40-px"
            value={state?.searchQuery}
            onChange={e => onInputChange(e.target.value, 'searchQuery')}
            placeholder={constants.SEARCH_CONTACT_BY_EMAIL}
          />
          <div className="m-1">
            <Button className="bg-purple me-3" onClick={applySearchFilter}>
              {constants.SEARCH}
            </Button>
            <Button className="bg-purple" onClick={onSearchClear}>
              {constants.CLEAR}
            </Button>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center my-2">
        <Button
          className="bg-transparent ps-2 border-0 text-black"
          onClick={() => {
            setState(prevState => {
              return {
                addEditContactForm: {
                  ...prevState.addEditContactForm,
                  contactName: '',
                  emailAddr: '',
                  emailAddressToBeUpdated: '',
                },
              };
            });
            setShowAddOrEditContactForm(!showAddOrEditContactForm);
            setIsEditMode(false);
          }}
        >
          <i
            className={`pi pi-${
              showAddOrEditContactForm ? 'minus' : 'plus'
            } pe-2 text-bold`}
          />{' '}
          {constants.ADD_A_NEW_CONTACT}
        </Button>
      </div>
      <div className="my-2">
        {showAddOrEditContactForm && (
          <>
            <div className="d-flex flex-wrap align-items-center w-300-px justify-content-between">
              <label className="m-1 me-3" htmlFor="contact-name">
                {constants.CONTACT_NAME}
              </label>
              <InputText
                id="contact-name"
                className="m-1 w-60 input-text-custom"
                value={state.addEditContactForm.contactName}
                onChange={e =>
                  setState(prevState => {
                    return {
                      addEditContactForm: {
                        ...prevState.addEditContactForm,
                        contactName: e.target.value,
                      },
                    };
                  })
                }
              />
            </div>
            <div className="d-flex flex-wrap align-items-center w-300-px justify-content-between">
              <label className="m-1 me-3" htmlFor="email-address">
                {constants.EMAIL_ADDR}
              </label>
              <InputText
                id="email-address"
                className="m-1 w-60 input-text-custom"
                value={state.addEditContactForm.emailAddr}
                onChange={e => {
                  setErrorMessage('');
                  setState(prevState => {
                    return {
                      addEditContactForm: {
                        ...prevState.addEditContactForm,
                        emailAddr: e.target.value,
                      },
                    };
                  });
                }}
              />
              {!isEmpty(errorMessage) && (
                <div className="p-2 text-danger">{errorMessage}</div>
              )}
            </div>

            <div className="d-flex flex-wrap align-items-center ">
              <Button
                className="m-1 me-3 bg-purple"
                onClick={updatePersonalContact}
              >
                {constants.SAVE}
              </Button>
              <Button className="m-1 bg-purple" onClick={onCancelAddEditForm}>
                {constants.CANCEL}
              </Button>
            </div>
          </>
        )}
      </div>
      <CustomDataTable
        tableData={tableData}
        paginator={false}
        selectionMode={false}
        columns={personalContactsColumns(
          onEditPersonalContact,
          onDeletePersonalContact,
          setIsEditMode,
        )}
        scrollable={false}
        resizableColumns={false}
        scrollHeight="250px"
        loading={fetchingActivitiesContactsList}
      />
    </>
  );
}
export default AddPersonalContactTemplate;
