import {AiFillWarning} from 'react-icons/ai';
import {HiInformationCircle} from 'react-icons/hi';
import {constants} from '../../constants/common';
import {Dialog} from '../../ui';
import {Button} from '../../ui/inputs';

export const NotificationDialog = ({
  errorMessage,
  onClose,
  headerMessage,
  iconType,
  showOkButton,
}) => {
  return (
    <Dialog open title={headerMessage} onClose={onClose}>
      <div className="my-2 ps-4 d-flex align-items-center">
        {iconType === constants.WARNING && (
          <AiFillWarning size={24} color="gold" className="me-2" />
        )}
        {iconType === constants.INFORMATION && (
          <HiInformationCircle size={24} color="#014e87" className="me-2" />
        )}

        <p className="my-2 ">{errorMessage}</p>
      </div>
      {showOkButton && (
        <Button variant="contained" size="medium" onClick={onClose}>
          {constants.OK}
        </Button>
      )}
    </Dialog>
  );
};
