/* eslint-disable no-nested-ternary */
import dayjs from 'dayjs';
import DOMPurify from 'dompurify';
import {constants} from '../../../constants/common';
import {decodeHtmlEntities, isEmpty} from '../../../lib/utils';
import {Checkbox} from '../../../ui/inputs';
import {isDateLessThanToday} from '../../../utils/dataTimeUtils';
import {ActivityMenu, IconLink, SingleLink} from './RowComponents';
import {CheckUnCheckFilter} from '../../common/filter/FilterComponents';
import {filterOperators} from '../../../lib/commonTableHelpers';
import {CustomerMenu} from '../../common/CommonRowComponents';
import {MAIL_STATUS} from '../../../utils/constants';
import {Typography} from '../../../ui/displays';

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

export const communicationColumns = [
  {
    field: 'dateEntered',
    headerName: 'Activity Date',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_TIME_FORMAT)
        : '',
    minWidth: 150,
    type: 'dateTime',
    filterOperators: filterOperators.date,
  },
  {
    field: 'activity',
    headerName: 'Activity',
    sortable: false,
    renderCell: cellValues => {
      return <ActivityMenu values={cellValues} />;
    },
    renderHeaderFilter: () => null,
    minWidth: 80,
  },
  {
    field: 'companyId',
    headerName: 'Company ID',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'custId',
    headerName: 'Cust ID',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'custName',
    headerName: 'Cust Name',
    sortable: true,
    renderCell: cellValues => {
      return <CustomerMenu values={cellValues} />;
    },
    minWidth: 180,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'description',
    headerName: 'Issue',
    sortable: true,
    renderCell: cellValues => {
      return <SingleLink values={cellValues} />;
    },
    minWidth: 180,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'activityId',
    headerName: 'Activity ID',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'notes',
    headerName: 'Notes',
    sortable: true,
    minWidth: 180,
    renderCell: cellValues => {
      const {row} = cellValues;

      const sanitizedHtml = DOMPurify.sanitize(row.notesText);
      const textForTooltip = decodeHtmlEntities(sanitizedHtml)
        .replace(/<\/?[^>]+(>|$)/g, '') // Remove HTML tags
        .replace(/&nbsp;/g, ' ') // Replace &nbsp; with space
        .trim();
      return (
        <Typography
          variant="body2"
          title={textForTooltip}
          dangerouslySetInnerHTML={{
            __html: sanitizedHtml,
          }}
        />
      );
    },
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'phoneCall',
    headerName: 'Phone Call',
    sortable: true,
    renderHeader: () => <i className="pi pi-phone" title="Phone Call" />,
    renderCell: cellValues => {
      const {row} = cellValues;
      if (row.phoneCall) {
        return (
          <IconLink values={cellValues}>
            {row.successful ? (
              <i
                className="pi pi-phone-successful"
                title="Successful Phone Call"
              />
            ) : (
              <i
                className="pi pi-phone-unsuccessful"
                title="Unsuccessful Phone Call"
              />
            )}
          </IconLink>
        );
      }
      return null;
    },
    renderHeaderFilter: params => <CheckUnCheckFilter {...params} />,
    minWidth: 120,
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
  {
    field: 'receivedMail',
    headerName: 'Received Mail',
    sortable: true,
    renderHeader: () => (
      <i className="pi pi-received-email" title="Received Mail" />
    ),
    renderCell: cellValues => {
      const {row} = cellValues;
      if (row.receivedMail) {
        return (
          <IconLink values={cellValues}>
            {row.mailStatus === MAIL_STATUS.unread ? (
              <i className="pi pi-received-email-unread" title="Unread Email" />
            ) : row.mailStatus === MAIL_STATUS.read ? (
              <i className="pi pi-received-email-read" title="Received Email" />
            ) : (
              ''
            )}
          </IconLink>
        );
      }
      return null;
    },
    renderHeaderFilter: params => <CheckUnCheckFilter {...params} />,
    minWidth: 120,
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
  {
    field: 'followUp',
    headerName: 'Follow up',
    sortable: true,
    renderHeader: () => <i className="pi pi-follow-up" title="Follow up" />,
    renderCell: cellValues => {
      const {row} = cellValues;
      if (row.followUp) {
        return (
          <IconLink values={cellValues}>
            {row.activityComplete ? (
              <i
                className="pi pi-followup-complete"
                title="Completed FollowUp"
              />
            ) : (row.followupDate && isDateLessThanToday(row.followupDate)) ||
              isEmpty(isDateLessThanToday(row.followupDate)) ? (
              <i className="pi pi-followup-overdue" />
            ) : (
              <i className="pi pi-follow-up" title="Open FollowUp" />
            )}
          </IconLink>
        );
      }
      return null;
    },
    renderHeaderFilter: params => <CheckUnCheckFilter {...params} />,
    minWidth: 120,
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
  {
    field: 'disputed',
    headerName: 'Disputed',
    sortable: true,
    renderHeader: () => <i className="pi pi-dispute" title="Disputed" />,
    renderCell: cellValues => {
      const {row} = cellValues;
      return (
        <IconLink values={cellValues} tabName={constants.TRANSACTIONS_TAB}>
          {row.disputed && (
            <i className="pi pi-dispute" title="Disputed Invoice" />
          )}
        </IconLink>
      );
    },
    renderHeaderFilter: params => <CheckUnCheckFilter {...params} />,
    minWidth: 120,
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
  {
    field: 'tagged',
    headerName: 'Tagged',
    sortable: true,
    renderHeader: () => (
      <i className="pi pi-tagged-invoice" title="Tagged Invoice" />
    ),
    renderCell: cellValues => {
      const {row} = cellValues;
      return (
        <IconLink values={cellValues} tabName={constants.TRANSACTIONS_TAB}>
          {row.tagged && (
            <i className="pi pi-tagged-invoice" title="Tagged Invoice" />
          )}
        </IconLink>
      );
    },
    renderHeaderFilter: params => <CheckUnCheckFilter {...params} />,
    minWidth: 120,
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
  {
    field: 'expectPayment',
    headerName: 'Expect Payment',
    sortable: true,
    renderHeader: () => <i className="pi pi-dollar" title="Expect Payment" />,
    renderCell: cellValues => {
      const {row} = cellValues;
      return (
        <IconLink values={cellValues} tabName={constants.PAYMENTS_TAB}>
          {row.expectPayment && <i className="pi pi-dollar" />}
        </IconLink>
      );
    },
    renderHeaderFilter: params => <CheckUnCheckFilter {...params} />,
    minWidth: 120,
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
  {
    field: 'sentMail',
    headerName: 'Sent Email',
    sortable: true,
    renderHeader: () => <i className="pi pi-sent-email" title="Sent Email" />,
    renderCell: cellValues => {
      const {row} = cellValues;
      return (
        <IconLink values={cellValues} tabName={constants.EMAIL_TAB}>
          {!isEmpty(row.mailKey) && (
            <>
              {row.sentMail && (
                <i className="pi pi-email-resend" title="Sent Email" />
              )}

              {row.mailStatus === -1 && (
                <i className="pi pi-email-error" title="Error Sending" />
              )}

              {row.mailStatus === -2 && (
                <i className="pi pi-email-queued" title="Email Queued" />
              )}
            </>
          )}
        </IconLink>
      );
    },
    renderHeaderFilter: params => <CheckUnCheckFilter {...params} />,
    minWidth: 120,
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
  {
    field: 'activityComplete',
    headerName: 'Activity Complete',
    sortable: true,
    renderCell: cellValues => {
      return <Checkbox disabled checked={cellValues.row.activityComplete} />;
    },
    renderHeaderFilter: () => null,
    minWidth: 80,
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
  {
    field: 'issueComplete',
    headerName: 'Issue Complete',
    sortable: true,
    renderCell: cellValues => {
      return <Checkbox disabled checked={cellValues.row.issueComplete} />;
    },
    renderHeaderFilter: () => null,
    minWidth: 80,
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
  {
    field: 'userId',
    headerName: 'User ID',
    sortable: true,
    minWidth: 180,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'alertId',
    headerName: 'Activity Template',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'creditClassId',
    headerName: 'Credit Class ID',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'followupDate',
    headerName: 'Followup Date',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_TIME_FORMAT)
        : '',
    minWidth: 120,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'followupDesc',
    headerName: 'Followup Desc',
    sortable: true,
    renderHeaderFilter: () => null,
    minWidth: 180,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'issueCompleteDate',
    headerName: 'Issue Complete Date',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_TIME_FORMAT)
        : '',
    renderHeaderFilter: () => null,
    minWidth: 120,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'activityCompleteDate',
    headerName: 'Activity Complete Date',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_TIME_FORMAT)
        : '',
    minWidth: 120,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'unreadMail',
    headerName: 'Unread Email',
    sortable: true,
    renderCell: cellValues => {
      return <Checkbox disabled checked={cellValues.row.unreadMail} />;
    },
    renderHeaderFilter: () => null,
    minWidth: 80,
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
  {
    field: 'totalAge',
    headerName: 'Total Age',
    sortable: true,
    renderHeaderFilter: () => null,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'dateEnteredDays',
    headerName: 'Date Entered Days',
    sortable: true,
    renderHeaderFilter: () => null,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'followUpDateDays',
    headerName: 'Followup Date Days',
    sortable: true,
    renderHeaderFilter: () => null,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'totalPastDue',
    headerName: 'Total Past Due',
    sortable: true,
    renderHeaderFilter: () => null,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'fromAddress',
    headerName: 'From Address',
    sortable: true,
    minWidth: 180,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'toAddress',
    headerName: 'To Address',
    sortable: true,
    minWidth: 180,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'ccAddress',
    headerName: 'Cc Address',
    sortable: true,
    minWidth: 180,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'mailSubject',
    headerName: 'Mail Subject',
    sortable: true,
    minWidth: 180,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'attachment',
    headerName: 'Attachment',
    sortable: true,
    renderHeader: () => <i className="pi pi-attachment" title="Attachment" />,
    renderCell: cellValues => {
      const {row} = cellValues;
      return (
        <IconLink values={cellValues} tabName={constants.ATTACHMENTS_TAB}>
          {row.attachment && (
            <i className="pi pi-attachment" title="Attachment" />
          )}
        </IconLink>
      );
    },
    renderHeaderFilter: params => <CheckUnCheckFilter {...params} />,
    minWidth: 120,
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
  {
    field: 'parentId',
    headerName: 'ParentID',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'voice',
    headerName: 'VoiceMessage',
    sortable: true,
    renderHeader: () => (
      <i className="pi pi-voice-call" title="Voice Message" />
    ),
    renderCell: cellValues => {
      const {row} = cellValues;
      return (
        <IconLink values={cellValues} tabName={constants.VOICE_MESSAGE_TAB}>
          {row.voice && (
            <i className="pi pi-voice-call" title="Voice Message" />
          )}
        </IconLink>
      );
    },
    minWidth: 80,
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
  {
    field: 'sms',
    headerName: 'Text',
    sortable: true,
    renderHeader: () => <i className="pi pi-text" title="Text Message" />,
    renderCell: cellValues => {
      const {row} = cellValues;
      return (
        <IconLink values={cellValues} tabName={constants.TEXT_TAB}>
          {row.sms && <i className="pi pi-text" title="Text Message" />}
        </IconLink>
      );
    },
    minWidth: 120,
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },

  {
    field: 'priority',
    headerName: 'Priority',
    sortable: true,
    minWidth: 120,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'taggedAmtHc',
    headerName: 'TaggedAmtHC',
    sortable: true,
    valueFormatter: params =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 2,
      }),
    minWidth: 120,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'openActivityCount',
    headerName: 'OpenActivityCount',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'successful',
    headerName: 'Successful',
    sortable: true,
    renderCell: cellValues => {
      return <Checkbox disabled checked={cellValues.row.successful} />;
    },
    minWidth: 80,
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
  {
    field: 'taskKey',
    headerName: 'IssueID',
    sortable: true,
    minWidth: 120,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'taskActKey',
    headerName: 'TaskActKey',
    sortable: true,
    minWidth: 120,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'daysToRespond',
    headerName: 'DaysToRespond',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'followUpRespondBy',
    headerName: 'FollowUpRespondByDate',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_TIME_FORMAT)
        : '',
    minWidth: 120,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'respondDueDays',
    headerName: 'RespondDueDays',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'closeStatus',
    headerName: 'CloseStatus',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'isArchived',
    headerName: 'IsArchived',
    sortable: true,
    renderCell: cellValues => {
      return <Checkbox disabled checked={cellValues.row.isArchived} />;
    },
    minWidth: 80,
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
  {
    field: 'customerClass',
    headerName: 'CustomerClass',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'timeZoneId',
    headerName: 'TimeZoneID',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
];
