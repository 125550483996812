import {actionTypes} from '../constants/actionTypes';

const initialState = {
  contactsData: [],
  contactsViewSettings: null,
  fetchingContacts: false,
  fetchingContactsFailed: false,
  fetchedContacts: false,
  contactsCount: 0,
  exportingToExcel: false,
  exportedToExcel: false,
  exportingToExcelFailed: false,
  contactsSettingId: null,
  contactsGlobalFilterQuery: '',
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const contactsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_USER_CONTACTS:
      return {
        ...state,
        fetchingContacts: true,
        fetchingContactsFailed: false,
        fetchedContacts: false,
      };
    case actionTypes.FETCHED_USER_CONTACTS:
      return {
        ...state,
        fetchingContacts: false,
        fetchingContactsFailed: false,
        fetchedContacts: true,
        contactsData: action.contactsData,
        contactsCount: action.contactsCount,
      };
    case actionTypes.FETCHING_USER_CONTACTS_FAILED:
      return {
        ...state,
        fetchingContacts: false,
        fetchingContactsFailed: true,
        fetchedContacts: false,
      };
    case actionTypes.EXPORTING_USER_CONTACTS_TO_EXCEL:
      return {
        ...state,
        exportingToExcel: true,
        exportedToExcel: false,
        exportingToExcelFailed: false,
      };
    case actionTypes.EXPORTED_USER_CONTACTS_TO_EXCEL:
      return {
        ...state,
        exportingToExcel: false,
        exportedToExcel: true,
        exportingToExcelFailed: false,
      };
    case actionTypes.EXPORTING_USER_CONTACTS_TO_EXCEL_FAILED:
      return {
        ...state,
        exportingToExcel: false,
        exportedToExcel: false,
        exportingToExcelFailed: true,
      };
    case actionTypes.UPDATE_USER_CONTACTS_SETTING_KEY:
      return {
        ...state,
        contactsSettingId: action.settingId,
      };
    case actionTypes.SET_CONTACTS_VIEW_SETTING_OBJECT:
      return {
        ...state,
        contactsViewSettings: {
          ...action.payload.viewSettings,
          sortSetting: null,
        },
        contactsSettingId: action.payload.settingId,
      };

    case actionTypes.CLEAN_UP_USER_CONTACTS_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default contactsReducer;
