import {
  fetchApplicationDetail,
  fetchApplicationInvoiceByInvoiceKey,
} from '../../services/communications';
import {actionTypes} from '../constants/actionTypes';

export const getApplicationDetailForInvoice =
  ({key, type, pageSize, skip}) =>
  dispatch => {
    dispatch({type: actionTypes.FETCHING_APPLICATION_DETAIL});
    fetchApplicationDetail({key, type, pageSize, skip})
      .then(res => {
        dispatch({
          type: actionTypes.FETCHED_APPLICATION_DETAIL,
          applicationDetail: res.data.value,
          applicationDetailCount: res.data.count,
          applicationDetailNextLink: res.data.nextLink,
        });
        return res.data;
      })
      .catch(() => {
        dispatch({type: actionTypes.FETCHING_APPLICATION_DETAIL_FAILED});
      });
  };

export const updateApplicationDetailSkip = applicationDetailSkip => {
  return {
    type: actionTypes.UPDATE_APPLICATION_DETAIL_SKIP,
    applicationDetailSkip,
  };
};

export const updateApplicationDetailPageSize =
  applicationDetailPageSize => dispatch => {
    dispatch({type: actionTypes.UPDATING_APPLICATION_DETAIL_PAGE_SIZE});
    dispatch({
      type: actionTypes.UPDATE_APPLICATION_DETAIL_PAGE_SIZE,
      applicationDetailPageSize,
    });
  };

export const getApplicationPaymentByInvoiceKey =
  ({invoiceKey}) =>
  dispatch => {
    dispatch({type: actionTypes.FETCHING_APPLICATION_DETAIL_PAYMENT});
    fetchApplicationInvoiceByInvoiceKey(invoiceKey)
      .then(res => {
        dispatch({
          type: actionTypes.FETCHED_APPLICATION_DETAIL_PAYMENT,
          data: res.data,
        });
      })
      .catch(e => {
        dispatch({type: actionTypes.FETCHING_APPLICATION_DETAIL_FAILED});
      });
  };
