import {constants, fileNames} from '../../constants/common';
import {exportFollowUpData, fetchFollowUpData} from '../../services/followup';
import {downloadExcel} from '../../utils/fileUtils';
import {actionTypes} from '../constants/actionTypes';

export const getFollowUpData = options => (dispatch, getState) => {
  const toast = getState().generalReducer?.toastRef;
  const followUpState = getState().followUpReducer;
  const {
    userKey,
    AssignedUserKey,
    groupKey,
    maxDate,
    minDate,
    skipRows,
    sortQuery,
    filterQuery,
    rowCount,
    settingKey,
    excludeFromRule,
    sqlQuery,
  } = options;
  const filterQueryVal =
    settingKey === followUpState?.followUpDataViewSettings?.settingsKey
      ? filterQuery
      : '';

  dispatch({type: actionTypes.FETCHING_FOLLOW_UP});
  fetchFollowUpData({
    followUpState,
    userKey,
    AssignedUserKey,
    groupKey,
    maxDate,
    minDate,
    skipRows,
    rowCount,
    sortQuery,
    globalFilterQuery: filterQueryVal,
    settingKey,
    excludeFromRule,
    sqlQuery,
  })
    .then(res => {
      dispatch({
        type: actionTypes.FETCHED_FOLLOW_UP,
        followUpData: res.data.value,
        followUpDataCount: res.data.count,
      });
    })
    .catch(() => {
      toast?.current.show({
        severity: 'warn',
        summary: constants.SOMETHING_WENT_WRONG,
        detail: '',
      });
      dispatch({type: actionTypes.FETCHING_FOLLOW_UP_FAILED});
    });
};

export const updateFollowUpDashboardViewSettingObject = ({
  settingId,
  viewSettings,
}) => {
  return {
    type: actionTypes.SET_FOLLOWUP_DASHBOARD_VIEW_SETTING_OBJECT,
    payload: {settingId, viewSettings},
  };
};
export const followUpExportToExcel =
  ({
    userKey,
    AssignedUserKey,
    groupKey,
    maxDate,
    minDate,
    sortQuery,
    filterQuery,
    modeType,
    settingKey,
    excludeFromRule,
    sqlQuery,
  }) =>
  (dispatch, getState) => {
    const toast = getState().generalReducer?.toastRef;

    dispatch({type: actionTypes.EXPORTING_TO_EXCEL_FOLLOW_UP});
    exportFollowUpData({
      userKey,
      AssignedUserKey,
      groupKey,
      maxDate,
      minDate,
      sortQuery,
      filterQuery,
      modeType,
      settingKey,
      excludeFromRule,
      sqlQuery,
    })
      .then(res => {
        downloadExcel(
          res.data,
          fileNames[modeType] ?? 'ExportFile',
          toast,
          constants.EXPORT_TO_EXCEL_SUCCESSFUL,
        );
        dispatch({type: actionTypes.EXPORTED_TO_EXCEL_FOLLOW_UP});
      })
      .catch(() => {
        dispatch({
          type: actionTypes.EXPORTING_TO_EXCEL_FOLLOW_UP_FAILED,
        });
      });
  };

export const cleanUpFollowUpData = () => {
  return {
    type: actionTypes.CLEAN_UP_FOLLOW_UP,
  };
};
