import {
  updateActivitiesOverviewCurrentPage,
  updateActivitiesOverviewLoadedPage,
  updateActivitiesOverviewPageSize,
  updateActivitiesOverviewSkip,
  updateActivitiesOverviewSort,
  updateIsCustomerDropdownValueChanged,
} from '../store/actions/activitiesOverviewActions';

export const resetActivitiesOverviewPaginationAndSort = ({
  dispatch = () => {},
}) => {
  const defaultSkip = 0;
  const defaultPageSize = 10;
  dispatch(updateActivitiesOverviewCurrentPage(1));
  dispatch(updateActivitiesOverviewLoadedPage(1));
  dispatch(updateActivitiesOverviewSort({field: '', direction: null}));
  dispatch(updateActivitiesOverviewSkip(defaultSkip));
  dispatch(updateActivitiesOverviewPageSize(defaultPageSize));
  dispatch(updateIsCustomerDropdownValueChanged(false));
};
