import React from 'react';
import {constants} from '../../constants/common';

function Unauthorized() {
  return (
    <div className="d-flex align-items-center justify-content-center flex-column m-auto p-5">
      <i className="pi pi-warning mx-3" />
      <h2>{constants.UNAUTHORIZED_USER}</h2>
    </div>
  );
}
export default Unauthorized;
