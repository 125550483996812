import {Button} from '@mui/material';
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid-pro';
import CachedIcon from '@mui/icons-material/Cached';
import SettingsIcon from '@mui/icons-material/Settings';
import DescriptionIcon from '@mui/icons-material/Description';
import ErrorIcon from '@mui/icons-material/Error';
import {FaArrowsAltH} from 'react-icons/fa';
import {CustomFilterButton} from '../../../ui/data';
import {constants} from '../../../constants/common';

export default function FollowUpToolbar({
  onRefresh,
  exportToExcel,
  applyBestFit,
  onViewSettings,
}: {
  onRefresh: () => void;
  exportToExcel: () => void;
  applyBestFit: () => void;
  onViewSettings: () => void;
}) {
  return (
    <GridToolbarContainer>
      <CustomFilterButton />
      <GridToolbarColumnsButton />
      <Button startIcon={<DescriptionIcon />} onClick={exportToExcel}>
        {constants.EXPORT_TO_EXCEL}
      </Button>
      <Button startIcon={<CachedIcon />} onClick={onRefresh}>
        {constants.REFRESH}
      </Button>
      <Button startIcon={<FaArrowsAltH />} onClick={applyBestFit}>
        {constants.BEST_FIT}
      </Button>
      <GridToolbarDensitySelector />
      <Button startIcon={<ErrorIcon />} disabled>
        {constants.ALERT}
      </Button>
      <Button startIcon={<SettingsIcon />} onClick={onViewSettings}>
        {constants.VIEW_SETTINGS}
      </Button>
    </GridToolbarContainer>
  );
}
