import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {constants} from '../../../constants/common';
import {isEmpty} from '../../../lib/utils';
import {
  addContact,
  deleteCustomerContact,
  updateContact,
} from '../../../services/customerInfo';
import {
  fetchContacts,
  updateContactsDialog,
  updateContactsEditMode,
} from '../../../store/actions/customerInfoActions';
import {getUserData} from '../../../store/actions/userActions';
import {DataGrid} from '../../../ui/data';
import {Dialog} from '../../../ui/feedback';
import {Button} from '../../../ui/inputs';
import CheckboxLabel from '../../../ui/inputs/checkbox/CheckboxLabel';
import Backdrop from '../../../ui/utils/backdrop/Backdrop';
import {getUDFContactList} from '../../../utils/udfList';
import {contactsColumns} from '../../common/table/columns/customer-info-tab-columns/contactColumns';
import {
  contactsTabCheckboxesObject,
  findByKeyAndUpdateObj,
  StyledFormDiv,
  StyledTableDiv,
} from '../helper';
import {editableFieldClassName} from './classNameConstants';
import ContactsFormTemplate from './ContactsFormTemplate';
import EditSaveAndCancelTemplate from './EditSaveAndCancelTemplate';
import {RenderUDFListTemplate} from './RenderUDFListTemplate';
import IconAlertCircle from '../../images/custom-icons/IconAlertCircle';

function Contacts() {
  const {fetchingContacts, contacts, isEditMode} = useSelector(
    store => store.customerInfoReducer.contacts,
  );
  const {open, showConfirmButton, onConfirm, content, title} = useSelector(
    store => store.customerInfoReducer.contacts.dialog,
  );
  const groupKey = getUserData()?.groupKey;

  const dispatch = useDispatch();
  const selectedCustomer = useSelector(
    store => store.generalReducer?.selectedCustomer,
  );
  const udfDetails = useSelector(store => store.usersReducer.udfDetails);
  const [selectedContact, setSelectedContact] = useState();
  const [contactsObj, setContactsObj] = useState(null);
  const [editObj, setEditObj] = useState(null);
  const [showUDF, setShowUDF] = useState(false);
  const [UDFFields, setUDFFields] = useState(null);
  const [unchangedUDFFields, setUnchangedUDFFields] = useState(null);
  const [addBtnClicked, setAddBtnClicked] = useState(false);
  const [contactNameError, setContactNameError] = useState(false);
  const [emailAddrError, setEmailAddrError] = useState(false);
  const [showIncludeEmailErrorPopup, setShowIncludeEmailErrorPopup] =
    useState(false);

  const fetchData = () => {
    dispatch(fetchContacts(groupKey, selectedCustomer?.custKey));
  };

  const onSelectedContactChanged = (value, field, contactKey) => {
    const contactsTempObj = contactsObj?.map(data => {
      if (data?.contactKey === contactKey) {
        return {...data, [field]: value};
      }
      if (data?.contactKey !== contactKey) {
        return {...data, [field]: false};
      }
    });
    setContactsObj(contactsTempObj);
  };

  const onEditClick = (
    val = true,
    type = constants.EDIT.toLocaleLowerCase(),
  ) => {
    if (val && type === constants.EDIT.toLocaleLowerCase()) {
      setEditObj(selectedContact);
    } else {
      setAddBtnClicked(true);
      const udfFields = {
        udF1: UDFFields.udF1?.value || '',
        udF2: UDFFields.udF2?.value || '',
        udF3: UDFFields.udF3?.value || '',
        udF4: UDFFields.udF4?.value || '',
        udF5: UDFFields.udF5?.value || '',
        udF6: UDFFields.udF6?.value || '',
        udF7: UDFFields.udF7?.value || '',
        udF8: UDFFields.udF8?.value || '',
        udF9: UDFFields.udF9?.value || '',
        udF10: UDFFields.udF10?.value || '',
      };
      const otherProps = {
        CheckSum: '',
        AddrLine4: '',
        AddrLine5: '',
        LinkedInProfile: '',
        ProfilePictureURL: '',
        notes: '',
        custKey: selectedCustomer?.custKey,
        groupKey,
      };
      const tempObj = {
        contactName: '',
        printAs: '',
        title: '',
        addrLine1: '',
        addrLine2: '',
        addrLine3: '',
        isInactive: false,
        emailAddr: '',
        emailAddr2: '',
        phone: '',
        fax: '',
        city: '',
        postalCode: '',
        insertDate: '',
        phoneExt: '',
        faxExt: '',
        state: '',
        country: '',
        updateDate: '',
        comment: '',
        includeInEmailYN: 0,
        includeInEmailYN2: 0,
        includeInTextYN: false,
        includeInVoiceYN: false,
        primaryContactFlag: 0,
        keepCntctInSync: 0,
        statement: false,
        invoice: false,
        ...udfFields,
        ...otherProps,
      };
      setEditObj(tempObj);
    }

    if (!val) {
      setAddBtnClicked(false);
    }
    setEmailAddrError(false);
    setContactNameError(false);
    dispatch(updateContactsEditMode(val));
  };

  const handleOnCheckBoxValueChange = ({
    emailAddr,
    emailAddr2,
    value,
    type,
    key,
  }) => {
    // Determine if the popup should be shown
    const shouldShowPopup =
      (key === 'includeInEmailYN' && isEmpty(emailAddr) && value) ||
      (key === 'includeInEmailYN2' && isEmpty(emailAddr2) && value);

    const formattedValue = value ? 1 : 0;
    const newValue = type === 'number' ? formattedValue : value;

    // Show the popup if needed
    if (shouldShowPopup) {
      setShowIncludeEmailErrorPopup(true);
    }

    const tempEditObj = {
      ...editObj,
      emailAddr,
      emailAddr2,
      [key]: shouldShowPopup ? 0 : newValue,
    };
    const updatedValue = findByKeyAndUpdateObj(
      tempEditObj,
      shouldShowPopup ? 0 : newValue,
      key,
    );
    setEditObj(updatedValue);
  };

  const onValueChange = (value, key) => {
    const updatedValue = findByKeyAndUpdateObj(editObj, key, value);

    if (key === 'emailAddr' && isEmpty(updatedValue.emailAddr)) {
      handleOnCheckBoxValueChange({
        emailAddr: updatedValue.emailAddr || '',
        emailAddr2: updatedValue.emailAddr2 || '',
        value: updatedValue.includeInEmailYN === 1,
        type: 'number',
        key: 'includeInEmailYN',
      });
    } else if (key === 'emailAddr2' && isEmpty(updatedValue.emailAddr2)) {
      handleOnCheckBoxValueChange({
        emailAddr: updatedValue.emailAddr || '',
        emailAddr2: updatedValue.emailAddr2 || '',
        value: updatedValue.includeInEmailYN2 === 1,
        type: 'number',
        key: 'includeInEmailYN2',
      });
    } else {
      setEditObj(updatedValue);
    }
  };

  const handleSaveContact = async () => {
    if (!showUDF && isEmpty(editObj?.contactName)) {
      setContactNameError(true);
    } else if (!showUDF && isEmpty(editObj?.emailAddr)) {
      setEmailAddrError(true);
    } else {
      setAddBtnClicked(false);
      dispatch(updateContactsEditMode(false));
      try {
        let payload = {
          udF1: UDFFields.udF1?.value || '',
          udF2: UDFFields.udF2?.value || '',
          udF3: UDFFields.udF3?.value || '',
          udF4: UDFFields.udF4?.value || '',
          udF5: UDFFields.udF5?.value || '',
          udF6: UDFFields.udF6?.value || '',
          udF7: UDFFields.udF7?.value || '',
          udF8: UDFFields.udF8?.value || '',
          udF9: UDFFields.udF9?.value || '',
          udF10: UDFFields.udF10?.value || '',
        };

        const {insertDate, updateDate, ...rest} = editObj;

        payload = {...rest, ...payload};

        if (addBtnClicked) {
          await addContact(payload);
        } else {
          await updateContact(selectedContact?.contactKey, payload);
        }
        fetchData();
      } catch (error) {
        if (
          error &&
          error?.response?.data?.includes(
            constants.VIOLATION_OF_UNIQUE_KEY_CONSTRAINT,
          )
        ) {
          dispatch(
            updateContactsDialog({
              open: true,
              title: `${constants.DUPLICATE_VALUE_FOUND}!`,
              content: (
                <div>{constants.duplicateContact(editObj?.contactName)}</div>
              ),
            }),
          );
        }
      }
    }
  };

  const handleOnUdfClick = () => {
    setShowUDF(true);
  };
  const onUDFTextFieldsChange = (key, value) => {
    const tempObject = {...UDFFields};
    tempObject[key].value = value;
    setUDFFields(tempObject);
  };

  const onConfirmDelete = async rowData => {
    try {
      await deleteCustomerContact(rowData.contactKey);
      fetchData();
      dispatch(updateContactsDialog({open: false}));
    } catch (error) {
      dispatch(updateContactsDialog({open: false}));
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedCustomer]);

  useEffect(() => {
    if (contacts?.length) {
      const contactsTemp = [
        {...contacts[0], selectedContact: true},
        ...contacts.slice(1),
      ];
      setContactsObj(contactsTemp);
    }
  }, [contacts]);

  useEffect(() => {
    const selectedContactTemp = contactsObj?.filter(
      contact => contact?.selectedContact,
    );
    if (selectedContactTemp?.length) {
      setSelectedContact(selectedContactTemp[0]);
    }
  }, [contactsObj]);

  useEffect(() => {
    const resultData = getUDFContactList(udfDetails, selectedContact);
    setUnchangedUDFFields(resultData);
    setUDFFields(resultData);
  }, [selectedContact]);

  const handleDeleteContact = rowData => {
    const {isInactive} = rowData;
    if (isInactive) {
      dispatch(
        updateContactsDialog({
          title: `${constants.ARE_YOU_SURE}?`,
          open: true,
          content: `${constants.DELETE_THIS_CONTACT}?`,
          showConfirmButton: true,
          showCancelButton: true,
          onConfirm: () => onConfirmDelete(rowData),
        }),
      );
    } else {
      dispatch(
        updateContactsDialog({
          title: constants.DELETING_CONTACT_FAILED,
          open: true,
          content: `${constants.CANNOT_DELETE_ACTIVE_CONTACT}!`,
        }),
      );
    }
  };

  return (
    <div className="p-3 customer-info-item-tab-content-wrapper">
      <Dialog
        open={open}
        title={title}
        showConfirmButton={showConfirmButton}
        onConfirm={onConfirm}
        onClose={() => dispatch(updateContactsDialog(false))}
      >
        <div className="p-2">{content}</div>
      </Dialog>
      <Dialog
        open={showIncludeEmailErrorPopup}
        title={constants.INFO}
        onConfirm={onConfirm}
        onClose={() => setShowIncludeEmailErrorPopup(false)}
      >
        <div className="p-2">
          {' '}
          <IconAlertCircle height={18} className="me-2" />{' '}
          {constants.INCLUDE_IN_EMAIL_MESSAGE}
        </div>
      </Dialog>
      <Backdrop open={fetchingContacts} />
      <div className="d-flex justify-content-between">
        <StyledTableDiv>
          <DataGrid
            columns={contactsColumns({
              onSelectedContactChanged,
              handleDeleteContact,
            })}
            rows={(contactsObj?.length && contactsObj) || []}
            disableColumnResize
            disableColumnMenu
            sx={{
              height: 'auto',
              maxHeight: '600px',
              minHeight: '500px',
              overflowY: 'scroll',
            }}
            hideFooter
          />
        </StyledTableDiv>
        <StyledFormDiv>
          <div className="mb-2 d-flex">
            <div className="d-flex">
              {showUDF ? null : (
                <div className="d-flex flex-wrap">
                  {contactsTabCheckboxesObject(
                    isEditMode ? editObj : selectedContact,
                  )?.map(checkBoxItem => {
                    return (
                      <div
                        className={`${isEditMode && editableFieldClassName}`}
                      >
                        <CheckboxLabel
                          className="m-1"
                          key={checkBoxItem.key}
                          label={checkBoxItem.label}
                          disabled={!isEditMode}
                          onChange={e =>
                            handleOnCheckBoxValueChange({
                              emailAddr: editObj.emailAddr,
                              emailAddr2: editObj.emailAddr2,
                              value: e.target.checked,
                              type: checkBoxItem.type,
                              key: checkBoxItem.key,
                            })
                          }
                          checked={checkBoxItem.checked}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
              <div className="d-flex">
                <EditSaveAndCancelTemplate
                  size="small"
                  isEditMode={isEditMode}
                  onEdit={() =>
                    onEditClick(true, constants.EDIT.toLocaleLowerCase())
                  }
                  onCancel={() => onEditClick(false)}
                  onSave={handleSaveContact}
                />
                {!isEditMode && !showUDF && (
                  <>
                    <div>
                      <Button
                        size="small"
                        variant="contained"
                        onClick={() =>
                          onEditClick(true, constants.ADD.toLocaleLowerCase())
                        }
                        className="m-1"
                      >
                        {constants.ADD}
                      </Button>
                    </div>

                    <div>
                      <Button
                        size="small"
                        className="m-1"
                        variant="contained"
                        onClick={handleOnUdfClick}
                        disabled={isEmpty(UDFFields)}
                      >
                        {constants.UDF}
                      </Button>
                    </div>
                  </>
                )}
                {!isEditMode && showUDF && (
                  <div>
                    <Button
                      size="small"
                      className="m-1"
                      variant="contained"
                      onClick={() => setShowUDF(false)}
                    >
                      {constants.CONTACTS}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
          {showUDF ? (
            <RenderUDFListTemplate
              UDFFields={isEditMode ? UDFFields : unchangedUDFFields}
              isEditMode={isEditMode}
              obj={selectedContact}
              unchangedUDFFields={unchangedUDFFields}
              onUDFTextFieldsChange={isEditMode && onUDFTextFieldsChange}
            />
          ) : (
            <ContactsFormTemplate
              isEditMode={isEditMode}
              selectedContact={selectedContact}
              editObj={editObj}
              onValueChange={onValueChange}
              contactNameError={contactNameError}
              emailAddrError={emailAddrError}
              setContactNameError={setContactNameError}
              setEmailAddrError={setEmailAddrError}
              addBtnClicked={addBtnClicked}
            />
          )}
        </StyledFormDiv>
      </div>
    </div>
  );
}

export default Contacts;
