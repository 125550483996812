import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  useProSidebar,
} from 'react-pro-sidebar';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import {Link, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import Logo from '../images/icons/logo.png';
import Icon from '../images/icons/icon.png';
import {useNavBar} from '../../hooks/useNavBar';
import Badge from '../../ui/utils/badge/Badge';
import {INavItems} from './INavbar';
import {getNavItemTextFromResourceFile, splitMenus} from './helper';
import iconMapToName from './IconMapToName';
import {getUserData} from '../../store/actions/userActions';
import {getConfig} from '../../lib/utils';
import {updateSelectedSideNavElement} from '../../store/actions/generalActions';
import Typography from '../../ui/displays/typography/Typography';

// Component to display badge with content
const MenuBadge = ({content}: {content: number}) => {
  return <Badge color="error" badgeContent={content} max={99999} />;
};

// Function to render a single menu item
const renderMenuItem = (
  item: INavItems,
  webRedirectUrl: string,
  onNavigationChange: (item: INavItems) => void,
  isShowCount?: boolean,
  selectedSideNavKey?: number,
) => {
  const redirect = !item.navItemURL || item.navItemURL.length === 0;
  const {sessionId, isReactLogin} = getUserData();
  const isRedirectToWebClientRoot = isReactLogin && !sessionId;
  const webClientLink = isRedirectToWebClientRoot
    ? getConfig().webClientRedirectUrl
    : `${webRedirectUrl}${item.sideNavItemURL}`;

  return (
    <MenuItem
      key={item.sideNavKey}
      title={item.sideNavItemText}
      icon={iconMapToName[item.sideNavItemIconName]}
      suffix={
        isShowCount ? <MenuBadge content={item.sideNavItemCount} /> : null
      }
      component={
        redirect ? (
          // eslint-disable-next-line jsx-a11y/anchor-has-content
          <a href={webClientLink} aria-label={item.sideNavItemText} />
        ) : (
          <Link
            to={`/${item.navItemURL}`}
            className={
              item.sideNavKey === selectedSideNavKey ? 'nav-active-link' : ''
            }
            onClick={() => onNavigationChange(item)}
          />
        )
      }
    >
      {getNavItemTextFromResourceFile(
        item.sideNavItemText,
        item.parentSideNavKey,
      )}
    </MenuItem>
  );
};

// Function to render a submenu
const renderSubMenu = (
  item: INavItems,
  webRedirectUrl: string,
  onNavigationChange: (item: INavItems) => void,
  activeSubmenuKey: number | undefined,
  onSubmenuChange: (key: number) => void,
  selectedSideNavKey?: number,
) => {
  const isSubMenuOpen = item.sideNavKey === activeSubmenuKey;

  return (
    <SubMenu
      data-testid="submenu-list"
      open={isSubMenuOpen}
      onOpenChange={() => onSubmenuChange(item.sideNavKey)}
      key={item.sideNavKey}
      label={getNavItemTextFromResourceFile(
        item.sideNavItemText,
        item.parentSideNavKey,
      )}
      title={getNavItemTextFromResourceFile(
        item.sideNavItemText,
        item.parentSideNavKey,
      )}
      icon={iconMapToName[item.sideNavItemIconName]}
    >
      {item.sideNavItems.map(subItem => {
        if (subItem.sideNavItems.length === 0) {
          return renderMenuItem(
            subItem,
            webRedirectUrl,
            onNavigationChange,
            true,
            selectedSideNavKey,
          );
        }
        return (
          <SubMenu
            data-testid="child-submenu-list"
            defaultOpen={isSubMenuOpen}
            key={subItem.sideNavKey}
            label={getNavItemTextFromResourceFile(
              subItem.sideNavItemText,
              subItem.parentSideNavKey,
            )}
            title={getNavItemTextFromResourceFile(
              subItem.sideNavItemText,
              subItem.parentSideNavKey,
            )}
            icon={iconMapToName[subItem.sideNavItemIconName]}
          >
            {subItem.sideNavItems.map(childItems => {
              return renderMenuItem(
                childItems,
                webRedirectUrl,
                onNavigationChange,
                false,
                selectedSideNavKey,
              );
            })}
          </SubMenu>
        );
      })}
    </SubMenu>
  );
};

// Main Navbar component
export const Navbar = ({navItems}: {navItems: INavItems[]}) => {
  const {hideNavBar, toggleNav} = useNavBar();
  const udfDetails = useSelector((store: any) => store.usersReducer.udfDetails);
  const {sessionId} = getUserData();
  const location = useLocation();
  const {singleMenus, accordionMenus, landingMenuItem} = splitMenus(
    navItems,
    location.pathname,
  );
  const dispatch = useDispatch<any>();
  const {collapseSidebar} = useProSidebar();
  const [activeSubmenuKey, setActiveSubmenuKey] = useState<number>();

  const selectedSideNavKey = useSelector(
    (store: any) => store.generalReducer.selectedSideNavElement?.sideNavKey,
  );

  // Effects to set active submenu key
  useEffect(() => {
    if (landingMenuItem) {
      setActiveSubmenuKey(landingMenuItem.sideNavKey);
    }
  }, [landingMenuItem]);

  // Function to handle navigation change
  const onNavigationChange = (item: any) => {
    dispatch(updateSelectedSideNavElement(item));
  };

  // Function to handle submenu change
  const onSubmenuChange = (subMenuKey: number) => {
    setActiveSubmenuKey(prev => (prev === subMenuKey ? undefined : subMenuKey));
  };
  // URL for web client redirection
  const webRedirectUrl = `${
    getConfig().webClientRedirectUrl
  }/(S(${sessionId}))/Pages/`;

  return (
    <Sidebar className="bg-navbar" backgroundColor="#0f3044" width="285px">
      <Menu
        transitionDuration={1000}
        menuItemStyles={{
          root: {
            color: 'white',
          },
          button: {
            height: '40px',
            '&:hover': {
              backgroundColor: '#1b4c65',
              color: '#cccccc',
            },
            '&.active': {
              backgroundColor: '#0a212f',
              color: 'white',
            },
          },
          subMenuContent: {
            backgroundColor: '#0f3044',
          },
        }}
      >
        <MenuItem
          className="logo-menu"
          title="Collapse/Expand menu"
          icon={
            <MenuRoundedIcon
              onClick={() => {
                toggleNav();
                collapseSidebar();
              }}
            />
          }
        >
          <img className="nav-logo" src={Logo} alt="logo" />
        </MenuItem>
        <div className="icon-container">
          {hideNavBar && <img width={35} src={Icon} alt="icon" />}
        </div>
        <div className="icon-container login-name-container">
          <Typography
            variant="h5"
            color="white"
            style={{opacity: hideNavBar ? 0 : 1, letterSpacing: '0.5px'}}
          >
            {udfDetails?.groupDesc}
          </Typography>
        </div>
        {singleMenus.map(item =>
          renderMenuItem(item, webRedirectUrl, onNavigationChange),
        )}
        {accordionMenus.map(item =>
          renderSubMenu(
            item,
            webRedirectUrl,
            onNavigationChange,
            activeSubmenuKey,
            onSubmenuChange,
            selectedSideNavKey,
          ),
        )}
      </Menu>
    </Sidebar>
  );
};
