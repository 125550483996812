import {Typography as MuiTypography, TypographyProps} from '@mui/material';

interface Props extends TypographyProps {}

/**
 * information available here https://mui.com/material-ui/react-typography/
 */

const Typography: React.FC<Props> = ({...otherProps}) => {
  return <MuiTypography {...otherProps}>{otherProps.children}</MuiTypography>;
};

export default Typography;
