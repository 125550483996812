import React from 'react';
import {Box as MUIBox, BoxProps} from '@mui/material';

// Define TypeScript interface for Box component props
interface Props extends BoxProps {}

const Box: React.FC<Props> = ({children, ...restProps}) => {
  return <MUIBox {...restProps}>{children}</MUIBox>;
};

export default Box;
