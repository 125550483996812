import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {CheckboxLabel} from '../../../ui/inputs';
import {
  updateIsMailNotRead,
  updateIsShowHighlightedRowActivities,
  updateIsShowMailArchived,
  updateViewSettingObject,
} from '../../../store/actions/communicationActions';
import {Dialog} from '../../../ui/feedback';
import {ViewSettingsDropDown} from '../../common/view-settings/view-settings-dropdown/ViewSettingsDropDown';
import Typography from '../../../ui/displays/typography/Typography';
import {Box} from '../../../ui/layouts';

export const ViewSettings = ({
  isRowSelected,
  isSingleRowSelected,
  activityType,
}: {
  activityType: string;
  isRowSelected?: boolean;
  isSingleRowSelected?: boolean;
}) => {
  const [showSelectCommunicationError, setShowSelectCommunicationError] =
    useState(false);

  const {
    isMailNotRead,
    isShowArchived,
    isShowActivitiesForHighlightedRow,
    settingId,
    alertDescription,
    viewSettings,
  } = useSelector((store: any) => store.communicationsReducer);

  const dispatch = useDispatch();

  const dialogTitle = !isRowSelected
    ? 'Select a Communication'
    : 'Please select only one record';

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginY="12px"
      >
        <div>
          <CheckboxLabel
            label="Show all activities for highlighted row"
            checked={isShowActivitiesForHighlightedRow}
            onChange={e => {
              if (isRowSelected && isSingleRowSelected) {
                dispatch(
                  updateIsShowHighlightedRowActivities(e.target.checked),
                );
              } else {
                setShowSelectCommunicationError(true);
              }
            }}
          />
          {alertDescription != null && (
            <CheckboxLabel
              label={`Restricted to Alert ${alertDescription}`}
              checked={isMailNotRead}
              onChange={e => {
                dispatch(updateIsMailNotRead(e.target.checked));
              }}
            />
          )}
          <CheckboxLabel
            label="Show archived activities"
            checked={isShowArchived}
            onChange={e => {
              dispatch(updateIsShowMailArchived(e.target.checked));
            }}
          />
        </div>
        <Box width="250px">
          <ViewSettingsDropDown
            settingId={settingId}
            viewName="Communication"
            activityType={activityType}
            onViewSettingSelected={(selectedSettingId, selectedViewSettings) =>
              dispatch(
                updateViewSettingObject({
                  settingId: selectedSettingId,
                  viewSettings: selectedViewSettings,
                }),
              )
            }
            currentViewSettings={viewSettings}
            isQueryParamDefault
          />
        </Box>
      </Box>
      <Dialog
        title={dialogTitle}
        open={showSelectCommunicationError}
        onClose={() => setShowSelectCommunicationError(false)}
      >
        <Typography variant="body2">{dialogTitle}</Typography>
      </Dialog>
    </>
  );
};
