import dayjs from 'dayjs';
import {constants} from '../../../constants/common';
import {ActivityMenu, CustomerMenu} from './RowComponents';
import {Checkbox} from '../../../ui/inputs';
import {filterOperators} from '../../../lib/commonTableHelpers';

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

export const allAccountsColumns = [
  {
    field: 'companyId',
    headerName: 'Company ID',
    sortable: true,
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'custId',
    headerName: 'CustID',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'custName',
    headerName: 'CustName',
    sortable: true,
    renderCell: cellValues => {
      return <CustomerMenu values={cellValues} />;
    },
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'creditUserName',
    headerName: 'Credit User Name',
    sortable: true,
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'noOverDueInvc',
    headerName: 'NoOverDueInvc',
    sortable: true,
    minWidth: 120,
    type: 'number',
    filterOperators: filterOperators.number,
  },

  {
    field: 'activity',
    headerName: 'Activity',
    sortable: false,
    renderCell: cellValues => {
      return <ActivityMenu values={cellValues} />;
    },
    renderHeaderFilter: () => null,
    minWidth: 50,
    maxWidth: 100,
  },
  {
    field: 'totalAge',
    headerName: 'TotalAge',
    sortable: true,
    valueFormatter: params =>
      params?.value ? Math.round(params?.value).toLocaleString() : 0,
    minWidth: 100,
    cellClassName: 'bg-light-blue text-white',
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'lateAgeCurrent',
    headerName: 'Late 1-30 Days',
    sortable: true,
    valueFormatter: params =>
      params?.value ? Math.round(params?.value).toLocaleString() : 0,
    minWidth: 120,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'lateAge1',
    headerName: 'Late 31-60 Days',
    sortable: true,
    valueFormatter: params =>
      params?.value ? Math.round(params?.value).toLocaleString() : 0,
    minWidth: 120,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'lateAge2',
    headerName: 'Late 61-90 Days',
    sortable: true,
    valueFormatter: params =>
      params?.value ? Math.round(params?.value).toLocaleString() : 0,
    minWidth: 120,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'lateAge3',
    headerName: 'Late 91-120 Days',
    sortable: true,
    valueFormatter: params =>
      params?.value ? Math.round(params?.value).toLocaleString() : 0,
    minWidth: 120,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'lateAge4',
    headerName: 'Late 121+ Days',
    sortable: true,
    valueFormatter: params =>
      params?.value ? Math.round(params?.value).toLocaleString() : 0,
    minWidth: 120,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'lateAgeFuture',
    headerName: 'Due Future',
    sortable: true,
    valueFormatter: params =>
      params?.value ? Math.round(params?.value).toLocaleString() : 0,
    minWidth: 120,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'lateOver1',
    headerName: 'Late Over 30 Days',
    valueFormatter: params =>
      params?.value ? Math.round(params?.value).toLocaleString() : 0,
    sortable: true,
    minWidth: 100,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'lateOver2',
    headerName: 'Late Over 60 Days',
    valueFormatter: params =>
      params?.value ? Math.round(params?.value).toLocaleString() : 0,
    sortable: true,
    minWidth: 100,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'lateOver3',
    headerName: 'Late Over 90 Days',
    valueFormatter: params =>
      params?.value ? Math.round(params?.value).toLocaleString() : 0,
    sortable: true,
    minWidth: 100,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'creditLimit',
    headerName: 'CreditLimit',
    sortable: true,
    valueFormatter: params =>
      params?.value ? Math.round(params?.value).toLocaleString() : 0,
    minWidth: 100,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'availableCredit',
    headerName: 'AvailableCredit',
    sortable: true,
    valueFormatter: params =>
      params?.value ? Math.round(params?.value).toLocaleString() : 0,
    minWidth: 100,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'percentofCreditUsed',
    headerName: 'PercentCreditUsed',
    valueFormatter: params =>
      params?.value
        ? params?.value.toLocaleString('en-US', {
            maximumFractionDigits: 2,
          })
        : '',
    sortable: true,
    minWidth: 120,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'creditHold',
    headerName: 'CreditHold',
    sortable: true,
    renderCell: cellValues => {
      return <Checkbox disabled checked={cellValues.row.creditHold} />;
    },
    minWidth: 80,
    type: 'boolean',
    filterOperators: filterOperators.boolean,
  },
  {
    field: 'lastPmtDate',
    headerName: 'LastPmtDate',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 150,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'pmtTermsDesc',
    headerName: 'PmtTermsDesc',
    sortable: true,
    minWidth: 170,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'lastPmtAmt',
    headerName: 'Last Pmt Amt',
    sortable: true,
    minWidth: 120,
    valueFormatter: params =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 2,
      }),
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'contactName',
    headerName: 'ContactName',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'openTaskCnt',
    headerName: 'OpenIssueCnt',
    sortable: true,
    minWidth: 120,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'cntctPrimaryAddrPhone',
    headerName: 'CntctPrimaryAddrPhone',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'lastActivity',
    headerName: 'LastActivity',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'lastActivityUserId',
    headerName: 'LastActivityUserID',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'cntctEmailAddr',
    headerName: 'CntctEmailAddr',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'assignedUserId',
    headerName: 'AssignedUserID',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'oldestTaskDate',
    headerName: 'OldestIssueDate',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 120,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'oldestTaskDesc',
    headerName: 'OldestIssueDesc',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'addrLine1',
    headerName: 'AddrLine1',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'addrLine2',
    headerName: 'AddrLine2',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'addrLine3',
    headerName: 'AddrLine3',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'addrLine4',
    headerName: 'AddrLine4',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'addrLine5',
    headerName: 'AddrLine5',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'city',
    headerName: 'City',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'homeCurrId',
    headerName: 'HomeCurrID',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'postalCode',
    headerName: 'PostalCode',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'currId',
    headerName: 'CurrID',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'dateEstab',
    headerName: 'DateEstab',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 120,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'custStatus',
    headerName: 'CustStatus',
    sortable: true,
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'pmtTermsId',
    headerName: 'PmtTermsID',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'creditClassId',
    headerName: 'CreditClassID',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'cntctAddrLine1',
    headerName: 'CntctAddrLine1',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'cntctAddrLine2',
    headerName: 'CntctAddrLine2',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'cntctCity',
    headerName: 'CntctCity',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'cntctState',
    headerName: 'CntctState',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'cntctPostalCode',
    headerName: 'CntctPostalCode',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'cntctCountry',
    headerName: 'CntctCountry',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'violationCode',
    headerName: 'ActionCode',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'cntctPrimaryAddrFax',
    headerName: 'CntctPrimaryAddrFax',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'lastActivityDate',
    headerName: 'LastActivityDate',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 150,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'cntctComment',
    headerName: 'CntctComment',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'custClassId',
    headerName: 'CustClassID',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'reference',
    headerName: 'Reference',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'overCurrent',
    headerName: 'OverCurrent',
    sortable: true,
    valueFormatter: params =>
      params?.value ? Math.round(params?.value).toLocaleString() : 0,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'over1',
    headerName: 'Over 30 Days',
    sortable: true,
    valueFormatter: params =>
      params?.value ? Math.round(params?.value).toLocaleString() : 0,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'over2',
    headerName: 'Over 60 Days',
    sortable: true,
    valueFormatter: params =>
      params?.value ? Math.round(params?.value).toLocaleString() : 0,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'over3',
    headerName: 'Over 90 Days',
    sortable: true,
    valueFormatter: params =>
      params?.value ? Math.round(params?.value).toLocaleString() : 0,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'ageCurrent',
    headerName: 'AgeCurrent',
    sortable: true,
    minWidth: 80,
    valueFormatter: params =>
      params?.value ? Math.round(params?.value).toLocaleString() : 0,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'age1',
    headerName: 'Aging 31-60 Days',
    sortable: true,
    valueFormatter: params =>
      params?.value ? Math.round(params?.value).toLocaleString() : 0,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'age2',
    headerName: 'Aging 61-90 Days',
    sortable: true,
    valueFormatter: params =>
      params?.value ? Math.round(params?.value).toLocaleString() : 0,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'age3',
    headerName: 'Aging 91-120 Days',
    sortable: true,
    valueFormatter: params =>
      params?.value ? Math.round(params?.value).toLocaleString() : 0,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'age4',
    headerName: 'Aging 121+ Days',
    sortable: true,
    valueFormatter: params =>
      params?.value ? Math.round(params?.value).toLocaleString() : 0,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'ageFuture',
    headerName: 'AgeFuture',
    sortable: true,
    valueFormatter: params =>
      params?.value ? Math.round(params?.value).toLocaleString() : 0,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'lateTotalAge',
    headerName: 'Due Total',
    sortable: true,
    valueFormatter: params =>
      params?.value ? Math.round(params?.value).toLocaleString() : 0,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'lateOverCurrent',
    headerName: 'Total Past Due',
    sortable: true,
    valueFormatter: params =>
      params?.value ? Math.round(params?.value).toLocaleString() : 0,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'lastActivityNote',
    headerName: 'LastActivityNote',
    sortable: true,
    minWidth: 300,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'state',
    headerName: 'State',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'country',
    headerName: 'Country',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'avgDaysToPay1',
    headerName: 'ADP 35000.000',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'avgDaysToPay2',
    headerName: 'ADP 73000.000',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'avgDaysToPay3',
    headerName: 'ADP 111000.000',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'avgDaysToPay4',
    headerName: 'ADP 149000.000',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'avgDaysToPay5',
    headerName: 'ADP 187000.000',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'avgDaysToPay6',
    headerName: 'ADP 225000.000',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'avgDaysToPay7',
    headerName: 'ADP Over 225000.000',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'salesTerritory',
    headerName: 'SalesTerritory',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'brokenPromise1',
    headerName: 'Broken Promise 90 Days',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'brokenPromise2',
    headerName: 'Broken Promise 6 Mths',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'brokenPromise3',
    headerName: 'Broken Promise 1 Yr',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'brokenPromise4',
    headerName: 'Broken Promise 2 Yrs',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'cocBalance',
    headerName: 'COCBalance',
    sortable: true,
    valueFormatter: params =>
      params?.value ? Math.round(params?.value).toLocaleString() : 0,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'custUDF1',
    headerName: 'CustUDF1',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'cocBalanceLate',
    headerName: 'COCBalanceLate',
    sortable: true,
    valueFormatter: params =>
      params?.value ? Math.round(params?.value).toLocaleString() : 0,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'custUDF2',
    headerName: 'CustUDF2',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'custUDF3',
    headerName: 'CustUDF3',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'custUDF4',
    headerName: 'CustUDF4',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'custUDF5',
    headerName: 'CustUDF5',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'custUDF6',
    headerName: 'CustUDF6',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'custUDF7',
    headerName: 'CustUDF7',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'custUDF8',
    headerName: 'CustUDF8',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'custUDF9',
    headerName: 'CustUDF9',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'custUDF10',
    headerName: 'CustUDF10',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'sperId',
    headerName: 'SperID',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'sperName',
    headerName: 'SperName',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'avgDaysToPayTotal',
    headerName: 'AvgDaysToPayTotal',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'sortOrder',
    headerName: 'SortOrder',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'projPmts1',
    headerName: 'Proj Pmts 7 Days',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'projPmts2',
    headerName: 'Proj Pmts 14 Days',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'projPmts3',
    headerName: 'Proj Pmts 21 Days',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'projPmts4',
    headerName: 'Proj Pmts 28 Days',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'projPmts5',
    headerName: 'Proj Pmts 35 Days',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'oldestTranDateDays',
    headerName: 'OldestTranDateDays',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'lastActivityDays',
    headerName: 'LastActivityDays',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'avgDaysLate',
    headerName: 'AvgDaysLate',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'parentId',
    headerName: 'ParentID',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'daysSinceOpenActivity',
    headerName: 'DaysSinceOpenActivity',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'avgDaysLateDiff',
    headerName: 'AvgDaysLateDiff',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'avgDaysAmtTotal',
    headerName: 'AvgDaysAmtTotal',
    sortable: true,
    valueFormatter: params =>
      params?.value ? Math.round(params?.value).toLocaleString() : 0,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'avgDaysCountTotal',
    headerName: 'AvgDaysCountTotal',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'creditReviewDays',
    headerName: 'Credit Review Days',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'creditReviewDate',
    headerName: 'Credit Review Date',
    sortable: true,
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 80,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'daysSinceClosedActivity',
    headerName: 'DaysSinceClosedActivity',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'oldestDueDateDays',
    headerName: 'OldestDueDateDays',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'nacmScoreDate',
    headerName: 'NACM Score Date',
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    sortable: true,
    minWidth: 80,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'nacmScore',
    headerName: 'NACM Score',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'nacmRiskClass',
    headerName: 'NACM Risk Class',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'nacmRiskDesc',
    headerName: 'NACM Risk Desc',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'nacmScoreDateDays',
    headerName: 'NACM Score Days',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'nacmScoreFactors',
    headerName: 'NACM Score Factors',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'creditScore',
    headerName: 'Credit Score',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'avgDaysLateWeighted',
    headerName: 'AvgDaysLateWeighted',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'creditUserId',
    headerName: 'Credit User ID',
    sortable: true,
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'daysSinceLastPmt',
    headerName: 'Days Since Last Pmt',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'accountCurrId',
    headerName: 'Site Currency ID',
    sortable: true,
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'totalAgeSC',
    headerName: 'TotalAgeSC',
    sortable: true,
    valueFormatter: params =>
      params?.value ? Math.round(params?.value).toLocaleString() : 0,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'ageCurrentSC',
    headerName: 'AgeCurrentSC',
    sortable: true,
    valueFormatter: params =>
      params?.value ? Math.round(params?.value).toLocaleString() : 0,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'age1SC',
    headerName: 'Aging 31-60 Days-SC',
    sortable: true,
    valueFormatter: params =>
      params?.value ? Math.round(params?.value).toLocaleString() : 0,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'age2SC',
    headerName: 'Aging 61-90 Days-SC',
    sortable: true,
    valueFormatter: params =>
      params?.value ? Math.round(params?.value).toLocaleString() : 0,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'age3SC',
    headerName: 'Aging 91-120 Days-SC',
    sortable: true,
    valueFormatter: params =>
      params?.value ? Math.round(params?.value).toLocaleString() : 0,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'age4SC',
    headerName: 'Aging 121+ Days-SC',
    sortable: true,
    valueFormatter: params =>
      params?.value ? Math.round(params?.value).toLocaleString() : 0,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'ageFutureSC',
    headerName: 'AgeFutureSC',
    sortable: true,
    valueFormatter: params =>
      params?.value ? Math.round(params?.value).toLocaleString() : 0,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'lateTotalAgeSC',
    headerName: 'Due Total-SC',
    sortable: true,
    valueFormatter: params =>
      params?.value ? Math.round(params?.value).toLocaleString() : 0,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'lateAgeFutureSC',
    headerName: 'Due Future-SC',
    sortable: true,
    valueFormatter: params =>
      params?.value ? Math.round(params?.value).toLocaleString() : 0,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'lateAgeCurrentSC',
    headerName: 'Late 1-30 Days-SC',
    sortable: true,
    valueFormatter: params =>
      params?.value ? Math.round(params?.value).toLocaleString() : 0,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'lateAge1SC',
    headerName: 'Late 31-60 Days-SC',
    sortable: true,
    valueFormatter: params =>
      params?.value ? Math.round(params?.value).toLocaleString() : 0,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'lateAge2SC',
    headerName: 'Late 61-90 Days-SC',
    sortable: true,
    valueFormatter: params =>
      params?.value ? Math.round(params?.value).toLocaleString() : 0,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'lateAge3SC',
    headerName: 'Late 91-120 Days-SC',
    sortable: true,
    valueFormatter: params =>
      params?.value ? Math.round(params?.value).toLocaleString() : 0,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'lateAge4SC',
    headerName: 'Late 121+ Days-SC',
    sortable: true,
    valueFormatter: params =>
      params?.value ? Math.round(params?.value).toLocaleString() : 0,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'projPmts1SC',
    headerName: 'Proj Pmts 7 Days-SC',
    sortable: true,
    valueFormatter: params =>
      params?.value ? Math.round(params?.value).toLocaleString() : 0,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'projPmts2SC',
    headerName: 'Proj Pmts 14 Days-SC',
    sortable: true,
    valueFormatter: params =>
      params?.value ? Math.round(params?.value).toLocaleString() : 0,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'projPmts3SC',
    headerName: 'Proj Pmts 21 Days-SC',
    sortable: true,
    valueFormatter: params =>
      params?.value ? Math.round(params?.value).toLocaleString() : 0,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'projPmts4SC',
    headerName: 'Proj Pmts 28 Days-SC',
    sortable: true,
    valueFormatter: params =>
      params?.value ? Math.round(params?.value).toLocaleString() : 0,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'projPmts5SC',
    headerName: 'Proj Pmts 35 Days-SC',
    sortable: true,
    valueFormatter: params =>
      params?.value ? Math.round(params?.value).toLocaleString() : 0,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'naAgeCurrent',
    headerName: 'NAAgeCurrent',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'naAgeFuture',
    headerName: 'NAAgeFuture',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'naAge1',
    headerName: 'NAAge1',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'naAge2',
    headerName: 'NAAge2',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'naAge3',
    headerName: 'NAAge3',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'naAge4',
    headerName: 'NAAge4',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'naLateAgeCurrent',
    headerName: 'NALateAgeCurrent',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'naLateAgeFuture',
    headerName: 'NALateAgeFuture',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'naLateAge1',
    headerName: 'NALateAge1',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'naLateAge2',
    headerName: 'NALateAge2',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'naLateAge3',
    headerName: 'NALateAge3',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'naLateAge4',
    headerName: 'NALateAge4',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'naAvgDaysToPay1',
    headerName: 'NAAvgDaysToPay1',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'naAvgDaysToPay2',
    headerName: 'NAAvgDaysToPay2',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'naAvgDaysToPay3',
    headerName: 'NAAvgDaysToPay3',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'naAvgDaysToPay4',
    headerName: 'NAAvgDaysToPay4',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'naAvgDaysToPay5',
    headerName: 'NAAvgDaysToPay5',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'naAvgDaysToPay6',
    headerName: 'NAAvgDaysToPay6',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'naAvgDaysToPay7',
    headerName: 'NAAvgDaysToPay7',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'naAvgDaysToPayTotal',
    headerName: 'NAAvgDaysToPayTotal',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'naAvgDaysLate1',
    headerName: 'NAAvgDaysLate1',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'naAvgDaysLate2',
    headerName: 'NAAvgDaysLate2',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'naAvgDaysLate3',
    headerName: 'NAAvgDaysLate3',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'naAvgDaysLate4',
    headerName: 'NAAvgDaysLate4',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'naAvgDaysLate5',
    headerName: 'NAAvgDaysLate5',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'naAvgDaysLate6',
    headerName: 'NAAvgDaysLate6',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'naAvgDaysLate7',
    headerName: 'NAAvgDaysLate7',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'naAvgDaysLateTotal',
    headerName: 'NAAvgDaysLateTotal',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'naBrokenPromise1',
    headerName: 'NABrokenPromise1',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'naBrokenPromise2',
    headerName: 'NABrokenPromise2',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'naBrokenPromise3',
    headerName: 'NABrokenPromise3',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'naBrokenPromise4',
    headerName: 'NABrokenPromise4',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'naAvgDaysLateWeighted',
    headerName: 'NAAvgDaysLateWeighted',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'naAvgDaysAmtTotal',
    headerName: 'NAAvgDaysAmtTotal',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'naAvgDaysCountTotal',
    headerName: 'NAAvgDaysCountTotal',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'naTotalAge',
    headerName: 'NATotalAge',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'naCreditLimit',
    headerName: 'NACreditLimit',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'nA3MthsDispute',
    headerName: 'NA3MthsDispute',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'nA6MthsDispute',
    headerName: 'NA6MthsDispute',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'nA12MthsDispute',
    headerName: 'NA12MthsDispute',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'nA24MthsDispute',
    headerName: 'NA24MthsDispute',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'naAvailableCredit',
    headerName: 'NAAvailableCredit',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'atcCreditScore',
    headerName: 'LSC Credit Score',
    sortable: true,
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
];
