import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from '@mui/material';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Dialog} from '../../../ui';
import {Typography} from '../../../ui/displays';
import {Box} from '../../../ui/layouts';
import {theme} from '../../../ui/theme/theme';
import {getUserData} from '../../../store/actions/userActions';
import {getCreditScore} from '../../../services/customerInfo';
import {constants} from '../../../constants/common';
import {ColoredSpan} from './ColoredSpan';
import {CreditScoreList, CreditScoreScaleList} from './CustomerInfoInterface';
import {getConfig} from '../../../lib/utils';

const ScoreTableCell = styled(TableCell)({
  whiteSpace: 'nowrap',
  padding: '8px 0',
});
const NoWrapTableCell = styled(TableCell)({
  whiteSpace: 'nowrap',
  paddingTop: '8px',
  paddingBottom: '8px',
});

const StyledLink = styled('a')({
  color: '#178087',
  fontSize: '13px',
  fontWeight: 500,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
    color: '#178087',
  },
});

const CreditInfoDialog = ({
  infoDialogOpen,
  closeInfoDialog,
  score,
  creditScoreScaleList,
  scoreColorAndDescription,
}: {
  infoDialogOpen: boolean;
  closeInfoDialog: () => void;
  creditScoreScaleList: CreditScoreScaleList;
  score: string;
  scoreColorAndDescription: {scaleColor: string; description: string};
}) => {
  const {groupKey, sessionId} = getUserData();
  const selectedCustomer = useSelector(
    (store: any) => store.generalReducer?.selectedCustomer,
  );
  const [creditScoreList, setCreditScoreList] = useState<CreditScoreList>([]);
  const baseRedirectUrl = getConfig().webClientRedirectUrl;
  const redirectUrl = `${baseRedirectUrl}/(S(${sessionId}))/Pages/SiteOptions.aspx?CreditScore`;

  const fetchData = async () => {
    try {
      if (selectedCustomer && selectedCustomer.custKey) {
        const response = await getCreditScore(
          groupKey,
          selectedCustomer.custKey,
        );
        setCreditScoreList(response.data);
      }
    } catch (error) {
      // Do nothing
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Dialog
      title=""
      open={infoDialogOpen}
      onClose={closeInfoDialog}
      resizable
      width={900}
      height={500}
    >
      <div>
        <Box display="flex" alignItems="center">
          <Typography
            variant="h5"
            sx={{color: '#0F3044', fontSize: '22px', fontWeight: 500}}
          >
            {constants.LOCKSTEP_CREDIT_SCORE}:{score}
            <ColoredSpan
              color={scoreColorAndDescription.scaleColor}
              sx={{marginLeft: '8px', verticalAlign: 'middle'}}
            >
              {scoreColorAndDescription.description}
            </ColoredSpan>
          </Typography>
        </Box>
        <Typography variant="body1" sx={{color: '#3E5969'}}>
          {constants.CALCULATED_LOCKSTEP_CREDIT_SCORE_TEXT}
        </Typography>
        <StyledLink href={redirectUrl}>
          {constants.DETAIL_CREDIT_SCORE_LINK}
        </StyledLink>
        <Box display="flex" gap="8px" marginTop="8px">
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead sx={{backgroundColor: theme.palette.grey[200]}}>
                <TableRow>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight={600}
                      textTransform="uppercase"
                    >
                      {constants.VARIABLE}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight={600}
                      textTransform="uppercase"
                    >
                      {constants.VALUE}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight={600}
                      textTransform="uppercase"
                    >
                      {constants.SCORE}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight={600}
                      textTransform="uppercase"
                    >
                      {constants.ASSIGNED_WEIGHT}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      variant="body1"
                      fontWeight={600}
                      textTransform="uppercase"
                    >
                      {constants.WEIGHTED_WEIGHT}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {creditScoreList.map(item => (
                  <TableRow key={item.uniqueKey}>
                    <NoWrapTableCell padding="normal">
                      <Typography>{item.displayText}</Typography>
                    </NoWrapTableCell>
                    <NoWrapTableCell>
                      <Typography>{item.resultsValue}</Typography>
                    </NoWrapTableCell>
                    <NoWrapTableCell>
                      <Typography>{item.assignedPoints}</Typography>
                    </NoWrapTableCell>
                    <NoWrapTableCell>
                      <Typography>{item.assignedWeight}</Typography>
                    </NoWrapTableCell>
                    <NoWrapTableCell>
                      <Typography>{item.weightedValue}</Typography>
                    </NoWrapTableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={2}>
                    <Typography fontWeight={600}>Total</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontWeight={600}>
                      {creditScoreList.reduce(
                        (total, item) => total + item.assignedPoints,
                        0,
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontWeight={600}>
                      {creditScoreList.reduce(
                        (total, item) => total + item.assignedWeight,
                        0,
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography fontWeight={600}>
                      {creditScoreList.reduce(
                        (total, item) => total + item.weightedValue,
                        0,
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Box border={`1px solid ${theme.palette.grey[200]}`} padding="8px">
            <Typography variant="body1" sx={{fontWeight: 'bold'}}>
              {constants.LOCKSTEP_CREDIT_SCORE_SCALE}
            </Typography>
            <Table>
              <TableBody>
                {creditScoreScaleList.map(item => (
                  <TableRow key={item.id}>
                    <ScoreTableCell padding="none">
                      <Typography>
                        {item.scaleStart} - {item.scaleEnd}
                      </Typography>
                    </ScoreTableCell>
                    <ScoreTableCell padding="none">
                      <ColoredSpan color={item.scaleColor}>
                        {item.scaleDescription}
                      </ColoredSpan>
                    </ScoreTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Box>
      </div>
    </Dialog>
  );
};

export default CreditInfoDialog;
