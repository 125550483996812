import {constants, sideNavItemTextSource} from '../../constants/common';
import {isEmpty, removeWhiteSpacesFromString} from '../../lib/utils';
import {INavItems} from './INavbar';

export const splitMenus = (menuList: INavItems[], pathname: string) => {
  const singleMenus: INavItems[] = [];
  const accordionMenus: INavItems[] = [];
  menuList.forEach(item => {
    if (item.sideNavItems.length > 0) {
      accordionMenus.push(item);
    } else {
      singleMenus.push(item);
    }
  });
  const landingMenuItem = accordionMenus.find(item => {
    return item.sideNavItems.find(val => `/${val.navItemURL}` === pathname);
  });
  return {singleMenus, accordionMenus, landingMenuItem};
};

export const getNavItemTextFromResourceFile = (
  navItemToCompare: string,
  parentSideNavKey: number,
) => {
  let str = removeWhiteSpacesFromString(navItemToCompare);
  if (
    navItemToCompare.toLocaleLowerCase() ===
      sideNavItemTextSource.Sidenav_inquiries_Text.toLocaleLowerCase() &&
    parentSideNavKey
  ) {
    str = constants.INQUIRY_ACCOUNT;
  }

  const temp: any = Object.entries(sideNavItemTextSource).find(entry => {
    if (
      entry[0].toLocaleLowerCase() === `Sidenav_${str}_Text`.toLocaleLowerCase()
    ) {
      return entry[1];
    }
    return '';
  });

  if (!isEmpty(temp)) {
    return temp[1];
  }

  if (isEmpty(temp)) {
    return navItemToCompare;
  }
  return '';
};
