import {constants} from '../../../../constants/common';
import {TotalAmountTypography} from '../../../common/css/Styles';

export function PaymentSummary({paymentSummary}: {paymentSummary: any}) {
  return (
    <div className="position-relative" style={{textAlign: 'right'}}>
      <TotalAmountTypography>
        <b>{constants.TOTAL_AMOUNT}</b>{' '}
        {paymentSummary[0]?.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </TotalAmountTypography>
      <TotalAmountTypography>
        <b>{constants.TOTAL_UNAPPLIED}</b>{' '}
        {paymentSummary[1]?.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </TotalAmountTypography>
    </div>
  );
}
