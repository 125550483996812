import {components} from 'react-select';

export const ReasonCodeOptions = props => {
  const {disputeCode, disputeDescription} = props.data;
  return (
    <components.Option {...props}>
      <div className="d-flex">
        <div className="px-1 w-50">{disputeCode}</div>
        <div className="px-2 w-50">{disputeDescription}</div>
      </div>
    </components.Option>
  );
};
