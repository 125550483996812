import {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {useSessionStorage} from './useSessionStorage';

export const useQueryKeys = () => {
  const [searchParams] = useSearchParams();
  const [custKey, setCustKey] = useState(Number(searchParams.get('CustKey')));
  const [sessionData] = useSessionStorage('profile');
  const {userKey: sessionUserKey} = sessionData || {userKey: null};
  const userKey = searchParams.get('AssignedUserKey')
    ? searchParams.get('AssignedUserKey')
    : sessionUserKey;
  const taskKey = searchParams.get('taskKey');
  const assignedUserKey = searchParams.get('SummaryUserKey') ?? userKey;

  useEffect(() => {
    if (searchParams.get('CustKey')) {
      setCustKey(Number(searchParams.get('CustKey')));
    }
  }, [searchParams]);

  return {custKey, userKey, taskKey, assignedUserKey};
};
