import {AiOutlineInfoCircle} from 'react-icons/ai';
import PropTypes from 'prop-types';
import {isEmpty} from '../../lib/utils';

function ErrorMessageTemplate({message, iconSize, iconColor, className}) {
  return (
    <div className="my-3 d-flex align-items-center text-error bold">
      {!isEmpty(message) ? (
        <>
          <AiOutlineInfoCircle
            color={iconColor}
            size={iconSize}
            className={`me-2 ${className || ''}`}
          />
          {message}
        </>
      ) : (
        <div className="my-3" />
      )}
    </div>
  );
}

ErrorMessageTemplate.defaultProps = {
  iconSize: 23,
  message: '',
  className: '',
  iconColor: 'red',
};

ErrorMessageTemplate.propTypes = {
  iconSize: PropTypes.number,
  message: PropTypes.string,
  iconColor: PropTypes.string,
  className: PropTypes.string,
};

export default ErrorMessageTemplate;
