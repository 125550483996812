import React from 'react';
import {constants} from '../../constants/common';

function FeatureNotAvailable() {
  return (
    <p className="d-flex text-align-center">
      <i className="pi pi-custom pi-warning mx-3" />{' '}
      {constants.FEATURE_NOT_AVAILABLE} <br />
      {constants.PLEASE_TRY_AGAIN_LATER}{' '}
    </p>
  );
}
export default FeatureNotAvailable;
