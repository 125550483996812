import data from '../lib/data';

export const postLogin = payload => {
  const endpoint = 'v1/user/login/authorize';
  return data.postAuth(endpoint, payload);
};

export const getProfileByToken = () => {
  const endpoint = 'v1/api/user/profile';
  return data.get(endpoint);
};
