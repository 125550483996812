import {Calendar, globalizeLocalizer} from 'react-big-calendar';
import globalize from 'globalize';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import {useState} from 'react';
import {constants} from '../../../constants/common';
import events from '../../../services/events';

const localizer = globalizeLocalizer(globalize);

const CustomCalendar = () => {
  const [isShowingWorkingHrs, setIsShowingWorkingHrs] = useState(true);
  const toggleHourFormat = () => setIsShowingWorkingHrs(prev => !prev);
  const handleTimeFormat = d => moment.utc(d).format('h a');

  return (
    <>
      <div>
        <Calendar
          hideOnDateTimeSelect
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          className="h-500-px"
          formats={{timeGutterFormat: handleTimeFormat}}
          defaultView="day"
          views={['day', 'week', 'month', 'agenda']}
        />
      </div>
      <button
        className="my-3 blueLink py-3 border-2"
        onClick={toggleHourFormat}
      >
        {isShowingWorkingHrs
          ? constants.SHOW_N_HOURS('24')
          : constants.SHOW_N_HOURS('Business')}
      </button>
    </>
  );
};
export default CustomCalendar;
