import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchRolledUpHistory} from '../../../store/actions/customerInfoActions';
import {labelValueForHistoryAndRolledUpHistory} from '../helper';
import HistoryAndRolledUpHistoryTemplate from './HistoryAndRolledUpHistoryTemplate';

function RolledUpHistory() {
  const [firstColumnFieldsAndValues, setFirstColumnFieldsAndValues] = useState(
    [],
  );
  const {rolledUpHistory, fetchingRolledUpHistory} = useSelector(
    store => store.customerInfoReducer.rolledUpHistory,
  );

  const dispatch = useDispatch();
  const selectedCustomer = useSelector(
    store => store.generalReducer?.selectedCustomer,
  );

  useEffect(() => {
    const result = labelValueForHistoryAndRolledUpHistory(rolledUpHistory);
    setFirstColumnFieldsAndValues(result);
  }, [rolledUpHistory]);

  useEffect(() => {
    dispatch(fetchRolledUpHistory(selectedCustomer?.custKey));
  }, [selectedCustomer]);

  return (
    <HistoryAndRolledUpHistoryTemplate
      data={rolledUpHistory}
      firstColumnFieldsAndValues={firstColumnFieldsAndValues}
      isRolledUpHistory
      loading={fetchingRolledUpHistory}
    />
  );
}

export default RolledUpHistory;
