import {Tree} from 'primereact';
import {useState} from 'react';
import {useFileManager} from './FileManager';

const data = [
  {
    key: 'External',
    label: 'External',
    path: '/External',
    children: [
      {
        key: 'Upload',
        label: 'Upload',
        path: '/External/Upload',
      },
    ],
  },
  {
    key: 'Internal',
    label: 'Internal',
    path: '/Internal',
  },
];

export const FileExplorer = ({filesTree}) => {
  const {onTreeSelected, selectedTree, updateTreeSelection} = useFileManager();

  const onSelect = event => {
    const {key} = event.node;
    updateTreeSelection(key);
    onTreeSelected(event.node);
  };
  return (
    <div>
      <Tree
        className="file-explorer-tree"
        selectionMode="single"
        onSelect={onSelect}
        value={filesTree}
        selectionKeys={selectedTree}
      />
    </div>
  );
};
