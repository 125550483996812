import data from '../lib/data';

const headers = {'Content-Type': 'blob', responseType: 'arraybuffer'};
export const downloadBlobFilePostApi = (url, payload) => {
  return data.post(url, payload, headers);
};

export const downloadBlobFileGetApi = url => {
  return data.get(url, headers);
};
