import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {constants} from '../../../constants/common';
import {fetchNacm} from '../../../store/actions/customerInfoActions';
import {getUserData} from '../../../store/actions/userActions';
import TextField from '../../../ui/inputs/textfield/TextField';
import Backdrop from '../../../ui/utils/backdrop/Backdrop';
import {SESSION_NACM_SUPPORT} from '../../../utils/constants';

function Nacm() {
  const {nacm, fetchingNacm} = useSelector(
    store => store.customerInfoReducer.nacm,
  );
  const sessionNACMSupport = getUserData()?.sessionNACMSupport;
  const dispatch = useDispatch();
  const selectedCustomer = useSelector(
    store => store.generalReducer?.selectedCustomer,
  );

  useEffect(() => {
    dispatch(fetchNacm(selectedCustomer?.custKey));
  }, [selectedCustomer]);

  return (
    <div className="customer-info-item-tab-content-wrapper p-3">
      <Backdrop open={fetchingNacm} />
      <div className="tab-content-column ">
        <div>
          <TextField
            value={nacm?.NACMScoreDate}
            disabled
            label={
              sessionNACMSupport === SESSION_NACM_SUPPORT.NACM
                ? constants.NACM_SCORE_DATE
                : constants.SCORE_DATE
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div>
          <TextField
            value={nacm?.NACMScore}
            disabled
            label={
              sessionNACMSupport === SESSION_NACM_SUPPORT.NACM
                ? constants.NACM_SCORE
                : constants.LOCAL_SCORE
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div>
          <TextField
            value={nacm?.NACMRiskClass}
            disabled
            label={
              sessionNACMSupport === SESSION_NACM_SUPPORT.NACM
                ? constants.NACM_RISK_CLASS
                : constants.INTERNATIONAL_SCORE
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div>
          <TextField
            value={nacm?.NACMRiskDesc}
            disabled
            label={
              sessionNACMSupport === SESSION_NACM_SUPPORT.NACM
                ? constants.NACM_RISK_DESC
                : constants.INTERNATIONAL_SCORE_DESC
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      </div>
      <div className="tab-content-column ms-4">
        <div>
          <TextField
            label={
              sessionNACMSupport === SESSION_NACM_SUPPORT.NACM
                ? constants.NACM_SCORE_FACTORS
                : constants.LOCAL_SCORE_DESC
            }
            value={nacm?.NACMScoreFactors}
            InputProps={{
              multiline: true,
              rows: 5,
              style: {minWidth: '400px'},
            }}
            disabled
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Nacm;
