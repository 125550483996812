import {useEffect, useState} from 'react';

import {Dialog} from '../../../ui';
import {Stack} from '../../../ui/layouts';
import {constants} from '../../../constants/common';
import {Button, Checkbox, CheckboxLabel, TextField} from '../../../ui/inputs';
import {DataGrid} from '../../../ui/data';
import {
  fetchAlertsUsersDetails,
  updateAssignedUsers,
} from '../../../services/alerts';
import {useQueryKeys} from '../../../hooks/useQueryKeys';
import {getUserData} from '../../../store/actions/userActions';
import Backdrop from '../../../ui/utils/backdrop/Backdrop';

interface EditAlertsModalProps {
  showEditAlertModal: boolean;
  handleRowForEditChange: (field: string, value: any) => void;
  rowForEdit: any;
  onEditAlertModalClose: () => void;
  saveOrUpdateAlert: () => void;
  loading: boolean;
}
function EditAlertsModal({
  showEditAlertModal,
  handleRowForEditChange,
  rowForEdit,
  onEditAlertModalClose,
  saveOrUpdateAlert,
  loading,
}: EditAlertsModalProps) {
  const [fetchingUsers, setFetchingUsers] = useState<boolean>(false);
  const [accountUsers, setAccountUsers] = useState<any[]>([]);
  const [accountUsersUpdated, setAccountUsersUpdated] = useState<any[]>([]);
  const {userKey: UserKey} = useQueryKeys();
  const GroupKey = getUserData()?.groupKey;

  const loadAccountUsers = async () => {
    setFetchingUsers(true);

    try {
      const payload = {
        UserKey,
        GroupKey,
        SettingsKey: rowForEdit.settingsKey,
      };
      const res = await fetchAlertsUsersDetails(payload);
      setAccountUsers(res.data);
      setFetchingUsers(false);
    } catch {
      setFetchingUsers(false);
    }
  };

  useEffect(() => {
    loadAccountUsers();
    return () => {
      setAccountUsers([]);
    };
  }, []);

  useEffect(() => {
    setAccountUsersUpdated(accountUsers);
  }, [accountUsers]);

  const onColumnRowSelect = async (
    userKeyParam: string,
    checkedParam: boolean,
  ) => {
    setFetchingUsers(true);
    const updatedData = accountUsersUpdated.map(item => {
      if (item.userKey === userKeyParam) {
        return {
          ...item,
          selectedUser: checkedParam,
        };
      }
      return item;
    });
    setAccountUsersUpdated(updatedData);

    const payload = {
      UserKey: userKeyParam,
      Settingskey: rowForEdit.settingsKey,
      GroupKey,
      IsAssignedUserChecked: checkedParam,
    };
    try {
      await updateAssignedUsers(payload);
      setFetchingUsers(false);
    } catch {
      setFetchingUsers(false);
    }
  };

  const handleSaveClick = () => {
    saveOrUpdateAlert();
  };

  return (
    <Dialog
      title={constants.EDIT_ALERT}
      open={showEditAlertModal}
      onClose={onEditAlertModalClose}
      draggable
      maxWidth="sm"
      fullWidth
    >
      <Stack
        direction="column"
        spacing={2}
        marginY={2}
        sx={{position: 'relative'}}
      >
        <Backdrop isLoading={loading} open={loading} />
        <Stack
          direction="column"
          spacing={2}
          padding={2}
          border={1}
          borderColor="#ccc"
        >
          <TextField
            value={rowForEdit.alertDesc}
            margin="normal"
            label={constants.DESCRIPTION}
            onChange={e => {
              handleRowForEditChange('alertDesc', e.target.value);
            }}
          />
          <TextField
            type="number"
            value={`${rowForEdit.alertPriority}`}
            margin="normal"
            label={constants.PRIORITY}
            onChange={e => {
              const value = parseInt(e.target.value, 10);
              if (!Number.isNaN(value) && value >= 0) {
                handleRowForEditChange('alertPriority', e.target.value);
              }
            }}
          />
          <TextField
            type="number"
            value={`${rowForEdit.sortOrder}`}
            margin="normal"
            label={constants.SORT_ORDER}
            onChange={e => {
              const value = parseInt(e.target.value, 10);
              if (!Number.isNaN(value) && value >= 0) {
                handleRowForEditChange('sortOrder', value);
              }
            }}
          />
          <CheckboxLabel
            onChange={e => {
              handleRowForEditChange('show', e.target.checked);
            }}
            checked={rowForEdit.show}
            label={constants.SHOW_IN_TAB}
          />

          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              className="me-3"
              onClick={handleSaveClick}
            >
              {constants.SAVE}
            </Button>
            <Button variant="contained" onClick={onEditAlertModalClose}>
              {constants.CANCEL}
            </Button>
          </Stack>
        </Stack>
        <DataGrid
          disableColumnMenu
          loading={fetchingUsers}
          hidePagination
          disableColumnResize
          rows={accountUsersUpdated}
          columns={[
            {
              field: 'selectedUser',
              headerName: 'Select',
              width: 200,
              flex: 1,
              sortable: false,
              renderCell: ({row}) => {
                return (
                  <Checkbox
                    checked={row.selectedUser}
                    onChange={e => {
                      onColumnRowSelect(row.userKey, e.target.checked);
                    }}
                  />
                );
              },
            },

            {
              field: 'userID',
              headerName: 'User ID',
              width: 200,
              flex: 1,
              sortable: false,
            },
          ]}
          disableColumnReorder
          hideFooterPagination
          height={550}
          getRowId={row => row.userKey}
        />
      </Stack>
    </Dialog>
  );
}

export default EditAlertsModal;
