import React, {useEffect, useState} from 'react';
import {GridPaginationModel} from '@mui/x-data-grid';
import {useDispatch, useSelector} from 'react-redux';
import {
  Card,
  CircularProgress,
  Grid,
  IconButton,
  LinearProgress,
  SelectChangeEvent,
  Tooltip,
  Typography,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import {Outlet} from 'react-router-dom';
import {DataGrid} from '../../ui/data';
import {getUserData} from '../../store/actions/userActions';
import {useQueryKeys} from '../../hooks/useQueryKeys';
import {
  getBestActivities,
  getHomeDashboardStats,
  getLastSyncData,
  getUserAlertSummary,
} from '../../store/actions/homeActions';
import {homeDashboardColumns} from './columns';
import {StatCard} from './StatCard';
import {constants, monthNames} from '../../constants/common';
import {Select} from '../../ui/inputs';
import {getCurrencySymbol} from '../../lib/utils';
import {Box} from '../../ui/layouts';
import {PAGE_SIZE} from '../../utils/constants';

const Home = () => {
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: PAGE_SIZE,
  });
  const dispatch = useDispatch<any>();
  const {groupKey} = getUserData();
  const {userKey} = useQueryKeys();
  const [skip, setSkip] = useState(0);
  const [selectedLabel, setSelectedLabel] = useState(0);
  const [open, setOpen] = useState(false);

  const {
    bestActivityData,
    bestActivityDataCount,
    isLoading,
    homeDashboardStats,
    userAlertSummary,
    lastSyncData,
    isDashboardStatsLoading,
    isLastSyncDataLoading,
  } = useSelector((store: any) => store.homeReducer);

  const {udfDetails} = useSelector((store: any) => store.usersReducer);
  const hasDashboardStats = Object.keys(homeDashboardStats || {}).length > 0;

  const summary = [{label: 'All', value: 0}, ...userAlertSummary].map(
    (item: any) => {
      return {
        label: item?.issue || item.label,
        value: item?.ruleKey || item.value,
      };
    },
  );

  const loadBestActivity = ({
    ruleKey = 0,
    pageSizeParam = PAGE_SIZE,
    skipParam = 0,
  }: {
    ruleKey: number;
    pageSizeParam: number;
    skipParam: number;
  }) => {
    const options = {
      userKey,
      groupKey,
      ruleKey,
      skipRows: skipParam,
      rowCount: pageSizeParam,
    };
    dispatch(getBestActivities(options));
    dispatch(getHomeDashboardStats({userKey, groupKey}));
    dispatch(getUserAlertSummary({userKey, groupKey}));
    dispatch(getLastSyncData(groupKey));
  };

  // reset pagination
  const resetPagination = () => {
    setPaginationModel({
      pageSize: paginationModel.pageSize,
      page: 0,
    });
  };

  useEffect(() => {
    if (groupKey) {
      loadBestActivity({
        ruleKey: 0,
        skipParam: skip,
        pageSizeParam: paginationModel.pageSize,
      });
      resetPagination();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupKey, userKey]);

  const getSyncStatusMessage = (syncStatus: string) => {
    switch (syncStatus) {
      case '1':
        return constants.SYNC_WAS_SUCCESSFULLY;
      case '2':
        return constants.SYNC_HAD_ERRORS;
      default:
        return constants.SYNC_STARTED;
    }
  };

  const getLastSyncInfo = () => {
    if (!lastSyncData) {
      return '';
    }

    const syncStatus = getSyncStatusMessage(lastSyncData?.syncStatus);
    const lastSyncOccurred = constants.LAST_SYNC_OCCURRED;

    if (lastSyncData.endDateTime) {
      return `${lastSyncOccurred} ${new Date(
        lastSyncData.endDateTime,
      ).toLocaleString()} - ${syncStatus}`;
    }
    return `${lastSyncOccurred} ${syncStatus}`;
  };

  const onPageChange = (args: GridPaginationModel) => {
    const pageSkip =
      args.pageSize !== paginationModel.pageSize
        ? 0
        : (args.page + 1) * args.pageSize - args.pageSize;
    setPaginationModel({
      pageSize: args.pageSize,
      page: args.pageSize !== paginationModel.pageSize ? 0 : args.page,
    });
    setSkip(pageSkip);
    loadBestActivity({
      ruleKey: 0,
      pageSizeParam: args.pageSize,
      skipParam: pageSkip,
    });
  };
  const onChangeOfUserAlertSummary = (event: SelectChangeEvent<string>) => {
    const ruleKey = Number(event.target.value);
    setSelectedLabel(ruleKey);
    loadBestActivity({
      ruleKey,
      skipParam: skip,
      pageSizeParam: paginationModel.pageSize,
    });
  };

  const onRefresh = () => {
    loadBestActivity({
      ruleKey: selectedLabel,
      skipParam: skip,
      pageSizeParam: paginationModel.pageSize,
    });
  };

  return (
    <>
      <Outlet context={{onReload: onRefresh}} />
      <Box className="d-flex justify-content-between" marginTop="35px">
        <Typography
          fontSize="1.5em"
          fontWeight="bold"
          display="flex"
          gap="10px"
          className="text-bold"
        >
          {hasDashboardStats && (
            <Typography
              display="flex"
              gap="10px"
              fontSize="1.5em"
              fontWeight="bold"
            >
              <span>{constants.COLLECTIONS_SUCCESS_FOR}</span>
              <span>{monthNames[new Date().getMonth()]}</span>
              <span>{new Date().getFullYear()}</span>
            </Typography>
          )}
        </Typography>
        <span>
          {isLastSyncDataLoading ? <CircularProgress /> : getLastSyncInfo()}
        </span>
      </Box>
      {hasDashboardStats &&
        !(
          homeDashboardStats?.userCollectionsGoal === 0 &&
          homeDashboardStats?.userTaskCompGoal === 0
        ) && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap"
            margin="20px ,0 "
          >
            <div>
              <Typography
                color="textSecondary"
                gutterBottom
                fontSize="1.15rem"
                fontWeight="bold"
              >
                {constants.CASH_COLLECTED}
              </Typography>
              <LinearProgress
                variant="determinate"
                value={
                  ((homeDashboardStats?.userCollectionsGoal ?? 0) /
                    (homeDashboardStats?.thisCashRecd ?? 1)) *
                  100
                }
                style={{width: '600px', height: '40px'}}
              />
              <Box display="flex" justifyContent="flex-end" fontWeight="bold">
                <Typography
                  variant="body1"
                  fontSize="1.15rem"
                  fontWeight="bold"
                >
                  {homeDashboardStats?.userCollectionsGoal}/
                  {getCurrencySymbol(udfDetails?.accountCurrID)}
                  {homeDashboardStats?.thisCashRecd}
                </Typography>
              </Box>
            </div>
            <div>
              <Typography
                color="textSecondary"
                gutterBottom
                fontSize="1.15rem"
                fontWeight="bold"
              >
                {constants.ACTIVITY_COMPLETE}
              </Typography>
              <LinearProgress
                variant="determinate"
                value={
                  ((homeDashboardStats?.taskCompletes ?? 0) /
                    (homeDashboardStats?.userTaskCompGoal ?? 1)) *
                  100
                }
                style={{width: '600px', height: '40px'}}
              />
              <Box display="flex" justifyContent="flex-end" fontWeight="bold">
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  fontSize="1.15rem"
                >
                  {homeDashboardStats?.taskCompletes}/
                  {homeDashboardStats?.userTaskCompGoal}
                </Typography>
              </Box>
            </div>
          </Box>
        )}
      {isDashboardStatsLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      ) : (
        hasDashboardStats && (
          <Card
            className="d-flex justify-content-center align-items-center"
            sx={{marginBottom: '20px', marginTop: '20px', minHeight: '100px'}}
            variant="outlined"
          >
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <StatCard
                  label={constants.ACTIVITIES_COMPLETED_THIS_MONTH}
                  value={homeDashboardStats?.taskCompletes}
                />
              </Grid>
              <Grid item xs={3} className="d-flex align-items-center">
                <StatCard
                  label={constants.COLLECTED_THIS_MONTH}
                  value={homeDashboardStats?.thisCashRecd}
                  currency={udfDetails?.accountCurrID}
                />
              </Grid>
              <Grid item xs={3}>
                <StatCard
                  label={constants.OPEN_INVOICES}
                  value={homeDashboardStats?.openInvoices}
                />
              </Grid>
              <Grid item xs={3}>
                <StatCard
                  label={constants.PAST_DUE_INVOICES}
                  value={homeDashboardStats?.pastDueinvoices}
                />
              </Grid>
            </Grid>
          </Card>
        )
      )}
      <Box className="text-bold" display="flex" gap="10px" fontSize="1.5em">
        {constants.NEXT_BEST_ACTIVITY}
        <Tooltip
          title={constants.NEXT_BEST_ACTIVITY_TOOLTIP}
          open={open}
          placement="bottom"
        >
          <IconButton
            size="small"
            color="inherit"
            onClick={() => setOpen(!open)}
          >
            <InfoIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <div className="width-250-px">
        <Select
          label=""
          value={selectedLabel || 0}
          items={summary}
          fullWidth
          onChange={(e: SelectChangeEvent<unknown>) =>
            onChangeOfUserAlertSummary(e as SelectChangeEvent<string>)
          }
          displayEmpty
        />
      </div>
      <div>
        <DataGrid
          disableVirtualization
          columns={homeDashboardColumns}
          disableColumnFilter
          disableColumnMenu
          rows={bestActivityData}
          loading={isLoading}
          rowCount={bestActivityDataCount}
          onPageChange={onPageChange}
          disableMultipleColumnsSorting
          getRowId={row => row.id}
          paginationModel={paginationModel}
        />
      </div>
    </>
  );
};

export default Home;
