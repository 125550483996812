import dayjs from 'dayjs';
import {constants} from '../../../../constants/common';
import {Checkbox} from '../../../../ui/inputs';

export const applicationDetailColumns = ({
  tranTypePayment = false,
  handleOnInvoiceClick = () => {},
  onPDFDownloadIconClick = () => {},
  handleSetShowCustomerDocs = () => {},
}) => {
  if (tranTypePayment) {
    return [
      {
        field: 'tranId',
        headerName: 'Invoice',
        renderCell: ({row}) => (
          <span
            className="blueLink cursor-pointer"
            aria-hidden="true"
            onClick={() => {
              handleOnInvoiceClick(row?.invcKey, row);
            }}
          >
            {row?.tranId}
          </span>
        ),
        minWidth: 150,
      },
      {
        field: 'CopyPDFToBlob',
        headerName: 'Display',
        renderCell: ({row}) => {
          if (row?.copyPdftoBlob) {
            return (
              <span className="mx-1">
                <i
                  aria-hidden="true"
                  className="pi pi-pdf p-2 cursor-pointer"
                  onClick={() => onPDFDownloadIconClick(row)}
                />
                <i
                  className="pi pi-folder p-2 ms-1 cursor-pointer"
                  onClick={() => handleSetShowCustomerDocs(row)}
                  aria-hidden="true"
                />
              </span>
            );
          }
          return null; // Return null if the field is not present
        },
        minWidth: 150,
      },
      {
        field: 'tranDate',
        headerName: 'Invoice date',
        sortable: false,
        valueFormatter: params =>
          params?.value
            ? dayjs(params?.value).utc().format(constants.DATE_TIME_FORMAT)
            : '',
        minWidth: 150,
        type: 'date',
      },
      {
        field: 'tranAmt',
        headerName: 'Amount',
        sortable: false,
        valueFormatter: params =>
          params?.value?.toLocaleString('en-US', {
            maximumFractionDigits: 2,
          }),
        minWidth: 120,
        type: 'number',
      },
      {
        field: 'balance',
        headerName: 'Balance',
        sortable: false,
        valueFormatter: params =>
          params?.value?.toLocaleString('en-US', {
            maximumFractionDigits: 2,
          }),
        minWidth: 120,
        type: 'number',
      },
      {
        field: 'pmtAmt',
        headerName: 'Payment Amount',
        sortable: false,
        valueFormatter: params =>
          params?.value?.toLocaleString('en-US', {
            maximumFractionDigits: 2,
          }),
        minWidth: 120,
        type: 'number',
      },

      {
        field: 'dueDate',
        headerName: 'Due Date',
        sortable: false,
        valueFormatter: params =>
          params?.value
            ? dayjs(params?.value).utc().format(constants.DATE_TIME_FORMAT)
            : '',
        minWidth: 150,
        type: 'date',
      },

      {
        field: 'paySched',
        style: {minWidth: '50px'},
        headerName: 'Pay Sched',
        renderCell: ({row}) => (
          <Checkbox
            disabled
            checked={row.paySched}
            onChange={e => e.preventDefault()}
          />
        ),
        minWidth: 120,
      },
      {
        field: 'discDate',
        headerName: 'Disc Date',
        sortable: false,
        valueFormatter: params =>
          params?.value
            ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
            : '',
        minWidth: 150,
        type: 'date',
      },
      {
        field: 'discAmt',
        headerName: 'Disc Amount',
        sortable: false,
        valueFormatter: params =>
          params?.value?.toLocaleString('en-US', {
            maximumFractionDigits: 2,
          }),
        minWidth: 120,
        type: 'number',
      },
      {field: 'currId', headerName: 'CurrID', minWidth: 120},
      {
        field: 'custPono',
        headerName: 'Customer PO',
        minWidth: 150,
      },
    ];
  }
  return [
    {
      field: 'tranId',
      headerName: 'PaymentMemo',
      minWidth: 150,
      sortable: false,
    },
    {field: 'pmtRef', headerName: 'Ref#', minWidth: 120, sortable: false},
    {
      field: 'tranDate',
      headerName: 'Payment Date',
      sortable: false,
      valueFormatter: params =>
        params?.value
          ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
          : '',
      minWidth: 150,
      type: 'date',
    },
    {
      field: 'pmtAmt',
      headerName: 'Amount',
      sortable: false,
      valueFormatter: params =>
        params?.value?.toLocaleString('en-US', {
          maximumFractionDigits: 2,
        }),
      minWidth: 120,
      type: 'number',
    },
    {
      field: 'tenderTypeId',
      headerName: 'Tender Type',
      minWidth: 120,
      sortable: false,
    },
    {field: 'currId', headerName: 'Currency', minWidth: 120, sortable: false},
  ];
};
