import {components} from 'react-select';

export const TemplateOptions = props => {
  const {emailTemplateID, emailTemplateDesc} = props.data;
  return (
    <components.Option {...props}>
      <div className="d-flex px-2 w-100">
        <div className="px-1 w-50">{emailTemplateID}</div>
        <div className="px-2 w-50">{emailTemplateDesc}</div>
      </div>
    </components.Option>
  );
};
