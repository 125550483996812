import {actionTypes} from '../constants/actionTypes';

const initialState = {
  paymentSummaryData: [],
  paymentSummaryViewSettings: null,
  fetchingPaymentSummary: false,
  fetchingPaymentSummaryFailed: false,
  fetchedPaymentSummary: false,
  paymentSummaryLoading: false,
  paymentSummaryCount: 0,
  paymentSummaryNextLink: '',
  paymentSummarySelectedRows: [],
  paymentSummaryInvoicesData: [],
  exportingToExcel: false,
  exportedToExcel: false,
  exportingToExcelFailed: false,
  paymentSummarySettingId: null,
  webhookSetting: true,
  resendPaymentsStatus: null,
  updatingReconcileStatus: false,
  updatedReconcileStatus: false,
  updatingReconcileStatusFailed: false,
  reconcileStatus: null,
  isRestricted: true,
  alertDescription: '',
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const pendingPaymentSummaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_PENDING_PAYMENTS_SUMMARY:
      return {
        ...state,
        fetchingPaymentSummary: true,
        fetchingPaymentSummaryFailed: false,
        paymentSummaryLoading: true,
      };
    case actionTypes.FETCHED_PENDING_PAYMENTS_SUMMARY:
      return {
        ...state,
        fetchingPaymentSummary: false,
        fetchingPaymentSummaryFailed: false,
        scheduledPaymentsAccounts: true,
        paymentSummaryData: action.paymentSummaryData,
        selectedSchedulePaymentsPageSize:
          action.selectedSchedulePaymentsPageSize || 10,
        paymentSummaryLoading: false,
        paymentSummaryCount: action.paymentSummaryCount,
        paymentSummaryNextLink: action.paymentSummaryNextLink,
      };
    case actionTypes.FETCHING_PENDING_PAYMENTS_SUMMARY_FAILED:
      return {
        ...state,
        fetchingPaymentSummary: false,
        fetchingPaymentSummaryFailed: true,
        fetchedPaymentSummary: true,
        paymentSummaryLoading: false,
        paymentSummaryData: [],
        paymentSummaryCount: 0,
      };
    case actionTypes.EXPORTING_PENDING_PAYMENTS_SUMMARY_TO_EXCEL:
      return {
        ...state,
        paymentSummaryLoading: true,
        exportingToExcel: true,
        exportedToExcel: false,
        exportingToExcelFailed: false,
      };
    case actionTypes.EXPORTED_PENDING_PAYMENTS_SUMMARY_TO_EXCEL:
      return {
        ...state,
        paymentSummaryLoading: false,
        exportingToExcel: false,
        exportedToExcel: true,
        exportingToExcelFailed: false,
      };
    case actionTypes.EXPORTING_PENDING_PAYMENTS_SUMMARY_TO_EXCEL_FAILED:
      return {
        ...state,
        paymentSummaryLoading: false,
        exportingToExcel: false,
        exportedToExcel: false,
        exportingToExcelFailed: true,
      };
    case actionTypes.SET_PENDING_PAYMENTS_SUMMARY_VIEW_SETTING_KEY:
      return {
        ...state,
        paymentSummarySettingId: action.paymentSummarySettingId,
      };
    case actionTypes.FETCHED_PENDING_PAYMENTS_INVOICES_DETAILS: {
      const updatedSummary = state.paymentSummaryData.map(item =>
        item.pendPmtKey === action.pendPmtKey
          ? {...item, summary: action.paymentSummaryInvoicesData}
          : item,
      );
      return {
        ...state,
        paymentSummaryInvoicesData: action.paymentSummaryInvoicesData,
        paymentSummaryData: updatedSummary,
      };
    }
    case actionTypes.RESEND_PENDING_PAYMENTS:
      return {
        ...state,
        resendPaymentsStatus: action.resendPaymentsStatus,
      };
    case actionTypes.FETCH_WEBHOOK_SETTING:
      return {
        ...state,
        webhookSetting: action.webhookSetting,
      };
    case actionTypes.UPDATING_PENDING_PAYMENT_RECONCILE_STATUS:
      return {
        ...state,
        updatingReconcileStatus: true,
        updatedReconcileStatus: false,
        updatingReconcileStatusFailed: false,
      };
    case actionTypes.UPDATED_PENDING_PAYMENT_RECONCILE_STATUS:
      return {
        ...state,
        reconcileStatus: action.reconcileStatus,
        updatingReconcileStatus: false,
        updatedReconcileStatus: true,
        updatingReconcileStatusFailed: false,
      };
    case actionTypes.UPDATE_PENDING_PAYMENT_RECONCILE_STATUS_FAILED:
      return {
        ...state,
        updatingReconcileStatus: false,
        updatedReconcileStatus: false,
        updatingReconcileStatusFailed: true,
      };
    case actionTypes.SET_PENDING_PAYMENT_IS_RESTRICTED:
      return {
        ...state,
        isRestricted: action.isRestricted,
      };
    case actionTypes.SET_PENDING_PAYMENTS_ALERT_DESCRIPTION:
      return {...state, alertDescription: action.alertDescription};
    case actionTypes.SET_PAYMENT_SUMMARY_DASHBOARD_VIEW_SETTING_OBJECT:
      return {
        ...state,
        paymentSummaryViewSettings: {
          ...action.payload.viewSettings,
          settingsKey: action.payload.settingId,
          sortSetting: null,
        },
        paymentSummarySettingId: action.payload.settingId,
      };

    case actionTypes.CLEAN_UP_PENDING_PAYMENTS_SUMMARY:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default pendingPaymentSummaryReducer;
