import {constants, fileNames} from '../../constants/common';
import {
  exportPendingPaymentSummary,
  fetchPendingPaymentSummary,
  fetchPendingPaymentsInvoicesDetails,
  fetchWebhookSetting,
  resendPendingPayments,
  updateReconcileStatus,
  updateWebhookStatus,
} from '../../services/pendingPayment';
import {downloadExcel} from '../../utils/fileUtils';
import {actionTypes} from '../constants/actionTypes';

export const getPendingPaymentSummary = options => (dispatch, getState) => {
  const toast = getState().generalReducer?.toastRef;
  const pendingPaymentSummaryState = getState().pendingPaymentSummaryReducer;
  const {
    userKey,
    groupKey,
    settingKey,
    sortQuery,
    filterQuery,
    pageSize,
    skip,
    isRestricted,
    mode,
    sqlQuery,
  } = options;
  const filterQueryVal =
    settingKey ===
    pendingPaymentSummaryState?.paymentSummaryViewSettings?.settingsKey
      ? filterQuery
      : '';

  dispatch({type: actionTypes.FETCHING_PENDING_PAYMENTS_SUMMARY});
  fetchPendingPaymentSummary({
    pendingPaymentSummaryState,
    userKey,
    groupKey,
    settingKey,
    sortQuery,
    globalFilterQuery: filterQueryVal,
    pageSize,
    skip,
    isRestricted,
    mode,
    sqlQuery,
  })
    .then(res => {
      dispatch({
        type: actionTypes.FETCHED_PENDING_PAYMENTS_SUMMARY,
        paymentSummaryData: res.data.value,
        paymentSummaryCount: res.data.count,
        paymentSummaryNextLink: res.data.nextLink,
      });
    })
    .catch(() => {
      toast?.current.show({
        severity: 'warn',
        summary: constants.SOMETHING_WENT_WRONG,
        detail: '',
      });
      dispatch({type: actionTypes.FETCHING_PENDING_PAYMENTS_SUMMARY_FAILED});
    });
};

export const updatePaymentSummaryDashboardViewObject = ({
  settingId,
  viewSettings,
}) => {
  return {
    type: actionTypes.SET_PAYMENT_SUMMARY_DASHBOARD_VIEW_SETTING_OBJECT,
    payload: {settingId, viewSettings},
  };
};

export const paymentsSummaryExportToExcel =
  ({
    userKey,
    groupKey,
    modeType,
    settingKey,
    sortQuery,
    filterQuery,
    isRestricted,
    mode,
    sqlQuery,
  }) =>
  (dispatch, getState) => {
    const toast = getState().generalReducer?.toastRef;
    dispatch({type: actionTypes.EXPORTING_PENDING_PAYMENTS_SUMMARY_TO_EXCEL});
    exportPendingPaymentSummary({
      userKey,
      groupKey,
      settingKey,
      sortQuery,
      filterQuery,
      isRestricted,
      mode,
      sqlQuery,
    })
      .then(res => {
        downloadExcel(
          res.data,
          fileNames[modeType] ?? 'ExportFile',
          toast,
          constants.SCHEDULED_PAYMENTS_EXPORTED_TO_EXCEL,
        );
        dispatch({
          type: actionTypes.EXPORTED_PENDING_PAYMENTS_SUMMARY_TO_EXCEL,
        });
      })
      .catch(() => {
        dispatch({
          type: actionTypes.EXPORTING_PENDING_PAYMENTS_SUMMARY_TO_EXCEL_FAILED,
        });
      });
  };

export const getPendingPaymentsInvoicesDetails =
  (groupKey, pendPmtKey) => (dispatch, getState) => {
    const toast = getState().generalReducer?.toastRef;

    fetchPendingPaymentsInvoicesDetails(groupKey, pendPmtKey)
      .then(res => {
        dispatch({
          type: actionTypes.FETCHED_PENDING_PAYMENTS_INVOICES_DETAILS,
          paymentSummaryInvoicesData: res.data,
          pendPmtKey,
        });
      })
      .catch(() => {
        toast?.current.show({
          severity: 'warn',
          summary: constants.SOMETHING_WENT_WRONG,
          detail: '',
        });
        dispatch({
          type: actionTypes.FETCHING_PENDING_PAYMENTS_INVOICES_DETAILS_FAILED,
        });
      });
  };

export const retryPendingPayments =
  ({groupKey, userId}) =>
  (dispatch, getState) => {
    const toast = getState().gerneralReducer?.toastRef;

    resendPendingPayments({groupKey, userId})
      .then(res => {
        dispatch({
          type: actionTypes.RESEND_PENDING_PAYMENTS,
          resendPaymentsStatus: res.status,
        });
      })
      .catch(() => {
        toast?.current.show({
          severity: 'warn',
          summary: constants.SOMETHING_WENT_WRONG,
          detail: '',
        });
        dispatch({
          type: actionTypes.RESEND_PENDING_PAYMENTS_FAILED,
        });
      });
  };
export const resetWebhookStatus = groupKey => (dispatch, getState) => {
  const toast = getState().generalReducer?.toastRef;
  updateWebhookStatus(groupKey)
    .then(res => {
      dispatch({
        type: actionTypes.CLEAR_WEBHOOK_STATUS,
        clearWebhookStatus: res.status,
      });
    })
    .catch(() => {
      toast?.current.show({
        severity: 'warn',
        summary: constants.SOMETHING_WENT_WRONG,
        detail: '',
      });
      dispatch({
        type: actionTypes.RESEND_PENDING_PAYMENTS_FAILED,
      });
    });
};

export const getWebhookSetting = groupKey => (dispatch, getState) => {
  const toast = getState().gerneralReducer?.toastRef;
  fetchWebhookSetting(groupKey)
    .then(res => {
      dispatch({
        type: actionTypes.FETCH_WEBHOOK_SETTING,
        webhookSetting: res.data,
      });
    })
    .catch(() => {
      toast?.current.show({
        severity: 'warn',
        summary: constants.SOMETHING_WENT_WRONG,
        detail: '',
      });
      dispatch({
        type: actionTypes.FETCH_WEBHOOK_SETTING_FAILED,
      });
    });
};

export const updatePendingPaymentReconcileStatus =
  request => (dispatch, getState) => {
    const toast = getState().generalReducer?.toastRef;
    dispatch({type: actionTypes.UPDATING_PENDING_PAYMENT_RECONCILE_STATUS});
    updateReconcileStatus(request)
      .then(res => {
        dispatch({
          type: actionTypes.UPDATED_PENDING_PAYMENT_RECONCILE_STATUS,
          reconcileStatus: res,
        });
      })
      .catch(() => {
        toast?.current.show({
          severity: 'warn',
          summary: constants.SOMETHING_WENT_WRONG,
          detail: '',
        });
        dispatch({
          type: actionTypes.UPDATE_PENDING_PAYMENT_RECONCILE_STATUS_FAILED,
        });
      });
  };

export const updatePendingPaymentIsRestricted = isRestricted => {
  return {
    type: actionTypes.SET_PENDING_PAYMENT_IS_RESTRICTED,
    isRestricted,
  };
};

export const updatePaymentsSummaryAlertDescription = alertDescription => {
  return {
    type: actionTypes.SET_PENDING_PAYMENTS_ALERT_DESCRIPTION,
    alertDescription,
  };
};

export const cleanUpPendingPaymentsSummary = () => {
  return {
    type: actionTypes.CLEAN_UP_PENDING_PAYMENTS_SUMMARY,
  };
};
