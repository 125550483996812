import {useDispatch, useSelector} from 'react-redux';
import {ViewSettingsDropDown} from '../../common/view-settings/view-settings-dropdown/ViewSettingsDropDown';
import {Box} from '../../../ui/layouts';
import {updateDraftDashboardViewSettingObject} from '../../../store/actions/draftActions';
import {gridModeTypes} from '../../../constants/common';

export const ViewSettings = () => {
  const {draftSettingId, draftDataViewSettings} = useSelector(
    (store: any) => store.draftReducer,
  );

  const dispatch = useDispatch();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      marginY="12px"
      width="100%"
    >
      <Box width="250px" marginLeft="auto">
        <ViewSettingsDropDown
          settingId={draftSettingId}
          viewName="DraftActivity"
          onViewSettingSelected={(selectedSettingId, viewSettings) =>
            dispatch(
              updateDraftDashboardViewSettingObject({
                settingId: selectedSettingId,
                viewSettings,
              }),
            )
          }
          isQueryParamDefault
          activityType={gridModeTypes.DraftActivity}
          currentViewSettings={draftDataViewSettings}
        />
      </Box>
    </Box>
  );
};
