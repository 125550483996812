import {IViewSetting} from '../interface';

export const getOnlyShowInDropdownSettings = (
  viewSettings: IViewSetting[],
): IViewSetting[] => {
  return viewSettings.filter(viewSetting => viewSetting.show);
};

export const filterSystemViewSettings = (
  viewSettings: IViewSetting[],
): IViewSetting[] => {
  return viewSettings.filter(viewSetting => !viewSetting.isSystem);
};

export const sortViewSettingsByReactView = (
  viewSettings: IViewSetting[],
): IViewSetting[] => {
  return viewSettings.sort((a, b) => (b.isReactView === 1 ? 1 : -1));
};
