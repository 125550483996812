import {useSelector} from 'react-redux';
import {useEffect, useState} from 'react';

import {DataGrid} from '../../../../ui/data';
import {getSelectedRowsByKey} from '../../../../lib/commonTableHelpers';
import {Button} from '../../../../ui/inputs';
import {constants} from '../../../../constants/common';
import TextField from '../../../../ui/inputs/textfield/TextField';
import {aggregateData} from './helper';

const declinePaymentColumns = [
  {
    field: 'tranId',
    headerName: 'Invoice',
    sortable: false,
    minWidth: 120,
    type: 'string',
  },
  {
    field: 'statusDesc',
    headerName: 'Status',
    sortable: false,
    minWidth: 150,
    type: 'string',
  },
  {
    field: 'paymentAmt',
    headerName: 'Payment Amount',
    sortable: false,
    minWidth: 120,
    type: 'number',
  },
];

export const DeclinePayment = ({
  selectedPayments,
  handleRejectPayment,
  onHideDialog,
}: {
  selectedPayments: any;
  handleRejectPayment: (actionType: string, reasonNote: string) => void;
  onHideDialog: () => void;
}) => {
  const [declinePaymentsLoading, setDeclinePaymentsLoading] =
    useState<boolean>(false);
  const [paymentsData, setPaymentsData] = useState<any[]>([]);
  const [reasonNote, setReasonNote] = useState('');

  const {scheduledPaymentsData, scheduledPaymentsCount} = useSelector(
    (store: any) => store.scheduledPaymentsReducer,
  );

  const createDeclinePaymentData = () => {
    const declinePaymentsData = getSelectedRowsByKey(
      selectedPayments,
      scheduledPaymentsData,
      'pendPmtKey',
    );

    const aggregatedRow = aggregateData(
      declinePaymentsData,
      declinePaymentColumns,
    );
    const dataWithAggregates = [...declinePaymentsData, aggregatedRow];
    setPaymentsData(dataWithAggregates);
  };

  useEffect(() => {
    setDeclinePaymentsLoading(true);
    createDeclinePaymentData();
    setDeclinePaymentsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DataGrid
        disableVirtualization
        columns={declinePaymentColumns}
        rows={paymentsData}
        loading={declinePaymentsLoading}
        rowCount={scheduledPaymentsCount}
        disableMultipleColumnsSorting
        hidePagination
        height={250}
      />
      <TextField
        variant="standard"
        value={reasonNote}
        onChange={e => setReasonNote(e.target.value)}
        rows={4}
        minRows={4}
        maxRows={5}
        label="Reason"
        multiline
      />

      <div className="d-flex align-items-center gap-2 m-2">
        <Button
          variant="contained"
          onClick={() =>
            handleRejectPayment(
              constants.REJECT.toLocaleLowerCase(),
              reasonNote,
            )
          }
        >
          {constants.REJECT}
        </Button>
        <Button variant="contained" onClick={onHideDialog}>
          {constants.CANCEL}
        </Button>
      </div>
    </>
  );
};
