import {actionTypes} from '../constants/actionTypes';

const initialState = {
  statementOfAccountsCount: 0,
  statementOfAccountsNextLink: '',
  statementOfAccounts: [],
  statementOfAccountsLoading: false,
  fetchingStatementsOfAccount: false,
  fetchedStatementsOfAccount: false,
  fetchingStatementsOfAccountFailed: false,
  statementsOfAccountBestFit: false,
  statementsOfAccountExportingToExcel: false,
  statementsOfAccountExportedToExcel: false,
  statementsOfAccountExportingToExcelFailed: false,
  statementOfAccountsFilter: '',
  statementOfAccountsSelectedRows: [],
  statementOfAccountsRowsUpdated: false,
  statementOfAccountsAllRowsSelected: false,
  statementOfAccountsPaymentsPanel: false,
  showAllNaAcc: 0,
  exportingToExcel: false,
  exportedToExcel: false,
  exportingToExcelFailed: false,
  sortBy: '',
  sortDirection: null,
  settingId: null,
  viewSettings: null,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const statementOfAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_STATEMENT_OF_ACCOUNTS:
      return {
        ...state,
        statementOfAccountsLoading: true,
        fetchingStatementsOfAccount: true,
        fetchedStatementsOfAccount: false,
        fetchingStatementsOfAccountFailed: false,
        statementOfAccountsRowsUpdated: true,
      };
    case actionTypes.FETCHED_STATEMENT_OF_ACCOUNTS:
      return {
        ...state,
        statementOfAccountsLoading: false,
        fetchingStatementsOfAccount: false,
        fetchedStatementsOfAccount: true,
        fetchingStatementsOfAccountFailed: false,
        statementOfAccounts: action.statementOfAccounts,
        statementOfAccountsCount: action.statementOfAccountsCount,
        statementOfAccountsNextLink: action.statementOfAccountsNextLink,
        statementOfAccountsSelectedRows: action.statementOfAccountsSelectedRows,
        statementOfAccountsRowsUpdated: true,
      };
    case actionTypes.FETCHING_STATEMENT_OF_ACCOUNTS_FAILED:
      return {
        ...state,
        statementOfAccountsLoading: false,
        fetchingStatementsOfAccount: false,
        fetchedStatementsOfAccount: false,
        fetchingStatementsOfAccountFailed: true,
        statementOfAccounts: initialState.statementOfAccounts,
        statementOfAccountsCount: initialState.statementOfAccountsCount,
        statementOfAccountsNextLink: initialState.statementOfAccountsNextLink,
        statementOfAccountsSelectedRows:
          initialState.statementOfAccountsSelectedRows,
      };
    case actionTypes.UPDATING_STATEMENTS_OF_ACCOUNT_SELECTED_ROWS:
      return {...state, statementOfAccountsRowsUpdated: false};
    case actionTypes.UPDATED_STATEMENT_OF_ACCOUNT_SELECTED_ROWS: {
      const tempObj = [...state.statementOfAccounts];
      const updatedList = tempObj.map(obj => {
        const index = action.statementOfAccountsSelectedRows.findIndex(
          item =>
            item.tranId + item.invcSchedKey === obj.tranId + obj.invcSchedKey,
        );
        return {
          ...obj,
          paymentAmountDisabled: index === -1,
          paymentAmount:
            index !== -1
              ? action.statementOfAccountsSelectedRows[index].paymentAmount
              : '',
        };
      });
      return {
        ...state,
        statementOfAccountsSelectedRows: action.statementOfAccountsSelectedRows,
        statementOfAccountsRowsUpdated: true,
        statementOfAccounts: updatedList,
      };
    }
    case actionTypes.UPDATED_STATEMENT_OF_ACCOUNT_SELECTED_ROWS_RESET:
      return {
        ...state,
        statementOfAccountsSelectedRows: [],
      };
    case actionTypes.EXPORTING_STATEMENTS_TO_EXCEL:
      return {
        ...state,
        activitiesOverviewLoading: true,
        exportingToExcel: true,
        exportedToExcel: false,
        exportingToExcelFailed: false,
      };
    case actionTypes.EXPORTED_STATEMENTS_TO_EXCEL:
      return {
        ...state,
        activitiesOverviewLoading: false,
        exportingToExcel: false,
        exportedToExcel: true,
        exportingToExcelFailed: false,
      };
    case actionTypes.EXPORTING_STATEMENTS_TO_EXCEL_FAILED:
      return {
        ...state,
        activitiesOverviewLoading: false,
        exportingToExcel: false,
        exportedToExcel: false,
        exportingToExcelFailed: true,
      };
    case actionTypes.UPDATE_STATEMENT_OF_ACCOUNT_SORT:
      return {
        ...state,
        sortBy: action.sortBy,
        sortDirection: action.sortDirection,
      };
    case actionTypes.UPDATE_ACCOUNT_SETTING_KEY:
      return {
        ...state,
        settingId: action.settingId,
      };
    case actionTypes.UPDATE_ACCOUNT_SETTING_OBJECT:
      return {
        ...state,
        viewSettings: {...action.payload.viewSettings, sortSetting: null},
        settingId: action.payload.settingId,
      };

    case actionTypes.CLEAN_UP_STATEMENTS_OF_ACCOUNT_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
export default statementOfAccountReducer;
