import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {GridPaginationModel, GridSortModel} from '@mui/x-data-grid';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {getUserData} from '../../store/actions/userActions';
import AddContactTemplate from './AddContactTemplate';
import data from '../../lib/data';
import {constants} from '../../constants/common';
import {SelectHeader} from '../common/select/SelectHeader';
import {SelectFooter} from '../common/select/SelectFooter';
import {SelectOptions} from '../common/select/SelectOptions';
import {AsyncSelect} from '../common/select/AsyncSelect';
import {fetchCustomerInfoList} from '../../services/customers';
import {
  getAllActivities,
  updateIsEmailTemplateActivities,
  updateIsCustomerDropdownValueChanged,
} from '../../store/actions/activitiesOverviewActions';
import {messageActivitiesOverviewColumns} from '../common/table/columns/messageActivitiesOverviewColumns';
import {
  mailboxCreateMultiple,
  mailboxCreateSingle,
  mailboxReassignActivity,
  reassignAll,
  updateMailStatus,
} from '../../services/mailbox';
import {
  hideDialog,
  updateSideNavRefresh,
} from '../../store/actions/generalActions';
import FeatureNotAvailable from '../common/FeatureNotAvailable';
import {updateAssignEmailStatus} from '../../store/actions/emailActions';
import {MAIL_STATUS, MODE_TYPES, PAGE_SIZE} from '../../utils/constants';
import {resetActivitiesOverviewPaginationAndSort} from '../../utils/mailUtils';
import {isEmpty} from '../../lib/utils';
import {useQueryKeys} from '../../hooks/useQueryKeys';
import {DataGrid} from '../../ui/data';
import {Button, Checkbox} from '../../ui/inputs';
import Menu from '../../ui/navigation/menu/Menu';
import Tabs from '../../ui/utils/tabs/Tabs';
import {Dialog} from '../../ui';
import Typography from '../../ui/displays/typography/Typography';
import RenderEmailTemplateForm from './RenderEmailTemplateForm';
import TextField from '../../ui/inputs/textfield/TextField';

// For communicate or activity page we need most of all props. For Mailbox.tsx/Mail-not-filed page we need only required props
interface IEmailTemplate {
  isAssignAllSelected: boolean;
  custKey?: any;
  CommunicateReassignClicked?: boolean;
  taskActKey?: number;
  setShowReassignModal?: (val: boolean) => void;
  existingTaskKey?: number;
  navigateBack: () => void;
  onReload: () => void;
  fromAddress?: string;
  toAddress?: string;
  ccAddress?: string;
  subject?: string;
  selectedRows: any[];
  mailKey?: any;
  status?: number;
  isAssignMessagesTemplate: boolean;
}

interface IActivitiesPayload {
  customerKeyParam: any;
  sortQuery: GridSortModel;
  pageSizeParam: number;
  skipParam: number;
}

function EmailTemplate({
  isAssignAllSelected,
  custKey,
  CommunicateReassignClicked = false,
  taskActKey,
  setShowReassignModal = () => {},
  existingTaskKey,
  navigateBack = () => {},
  onReload = () => {},
  status,
  selectedRows,
  mailKey,
  fromAddress,
  toAddress,
  ccAddress,
  isAssignMessagesTemplate,
  subject,
}: IEmailTemplate) {
  const from: string = fromAddress || '';
  const to: string = toAddress || '';
  const cc: string = ccAddress || '';
  const [message, setMessage] = useState<string>('');
  const [mailSubject, setMailSubject] = useState<string>(subject || '');
  const [checkedSearchCustomerByEmail, setCheckedSearchCustomerByEmail] =
    useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<any>(null);
  const [issueAnchorEl, setIssueAnchorEl] = useState<null | HTMLElement>(null);
  const [mailKeysForUpdateStatus, setMailKeysForUpdateStatus] = useState<
    number[]
  >([]);

  const [tabActiveIndex, setTabActiveIndex] = useState(
    selectedRows?.length ? 0 : 1,
  );
  const [attachments, setAttachments] = useState([]);
  const [showOnContactDialog, setShowOnContactDialog] =
    useState<boolean>(false);

  const [followupChk, setFollowupChk] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const toast = useSelector((store: any) => store.generalReducer?.toastRef);
  const [totalRecordCount, setTotalRecordCount] = useState();
  const {userKey} = useQueryKeys();
  const groupKey = getUserData()?.groupKey;
  const options = {userKey, groupKey, isArchived: 0, taskKey: -1};
  const {
    emailTemplateActivities,
    emailTemplateActivitiesCount,
    emailTemplateActivitiesLoading,
    isCustomerDropdownValueChanged,
  } = useSelector((store: any) => store.activitiesOverviewReducer);
  const [customerKey, setCustomerKey] = useState(null);
  const dispatch = useDispatch<any>();
  const [mailKeys, setMailKeys] = useState<any>([]);
  const [showEmailSuccessfullyAssigned, setShowEmailSuccessfullyAssigned] =
    useState(false);
  const [showFeatureNotAvailableDialog, setShowFeatureNotAvailableDialog] =
    useState(false);
  const [emailViewerDataFetched, setEmailViewerDataFetched] = useState(false);
  const location = useLocation();
  const {search, pathname} = location;
  const [skip, setSkip] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: PAGE_SIZE,
  });
  const [sortColumn, setSortColumn] = useState<GridSortModel>([]);
  const [issueDescription, setIssueDescription] = useState<string>('');
  const [markAsRead, setMarkAsRead] = useState<boolean>(false);
  const [cacheUniqs, setCacheUniqs] = useState<boolean[]>([true]);
  const navigate = useNavigate();

  const getActivities = ({
    customerKeyParam,
    sortQuery,
    pageSizeParam,
    skipParam,
  }: IActivitiesPayload) => {
    dispatch(
      getAllActivities({
        ...options,
        custKey: customerKeyParam,
        skip: skipParam,
        pageSize: pageSizeParam,
        sortQuery,
        isEmailTemplate: true,
      }),
    );
  };

  const onMarkAsReadCheck = (e: any) => {
    let updatedMailKeys = [];
    const readUnreadValue = e.target.checked
      ? MAIL_STATUS.read
      : MAIL_STATUS.unread;

    if (selectedRows) {
      const filteredMailKeys = selectedRows
        ?.filter(row => row.status !== readUnreadValue)
        .map(row => row.mailKey);
      updatedMailKeys = filteredMailKeys;
    } else if (
      (status !== undefined || status !== null) &&
      readUnreadValue !== status
    ) {
      updatedMailKeys = [mailKey];
    }
    setMarkAsRead(e.target.checked);
    setMailKeysForUpdateStatus(updatedMailKeys);
  };

  const updateStatusOfMailKeys = async () => {
    try {
      await updateMailStatus(mailKeysForUpdateStatus);
    } catch (error) {
      toast?.current.show({
        severity: constants.ERROR.toLowerCase(),
        summary: constants.ERROR,
        detail: constants.FAILED_TO_CHANGE_THE_MAIL_STATUS,
      });
    }
  };

  const showExistingActivity = (params: any) => {
    const communicateProps = {
      ...params,
      toAddress,
      fromAddress,
      mailKey,
      ccAddress,
      groupKey,
      custKey,
      newActivity: false,
      ModeType: MODE_TYPES.enquiry,
      tabName: constants.NOTES_TAB,
      custName: selectedCustomer?.custName,
    };

    const paramsObj = new URLSearchParams(search);

    setShowReassignModal(false);
    dispatch(hideDialog());
    if (pathname.includes('mail-not-filed')) {
      paramsObj.set('activityId', communicateProps.taskKey);
      const activityPath = pathname.includes('mail-not-filed/activity')
        ? ''
        : 'activity';
      const path = `${activityPath}?activityId=${communicateProps.taskKey}`;
      navigate(path, {
        state: {...communicateProps, from, id: communicateProps.taskKey},
      });
    } else {
      paramsObj.set('activityId', communicateProps.id);
      const path = `?${paramsObj.toString()}`;
      navigate(path, {
        state: {
          ...communicateProps,
          from: location,
          id: communicateProps.taskKey,
        },
      });
    }
  };

  const assignSingle = async () => {
    const res = CommunicateReassignClicked
      ? await mailboxReassignActivity(
          groupKey,
          customerKey,
          mailKeys,
          taskActKey,
          followupChk,
          existingTaskKey,
          issueDescription,
        )
      : await mailboxCreateSingle(
          groupKey,
          customerKey,
          mailKeys,
          followupChk,
          issueDescription,
        );
    if (res) {
      updateStatusOfMailKeys();
      setShowEmailSuccessfullyAssigned(true);
    }
  };

  const assignMultiple = async () => {
    const res = await mailboxCreateMultiple(
      groupKey,
      customerKey,
      mailKeys,
      followupChk,
      issueDescription,
    );
    if (res) {
      updateStatusOfMailKeys();
      setShowEmailSuccessfullyAssigned(true);
    }
  };

  const assignAndOpenActivity = async () => {
    const res = CommunicateReassignClicked
      ? await mailboxReassignActivity(
          groupKey,
          customerKey,
          mailKeys,
          taskActKey,
          followupChk,
          existingTaskKey,
          issueDescription,
        )
      : await mailboxCreateSingle(
          groupKey,
          customerKey,
          mailKeys,
          followupChk,
          issueDescription,
        );

    if (res && res.data) {
      const obj = {
        taskActKey: res.data.taskActKey ? res.data.taskActKey : taskActKey,
        taskKey: res.data.taskKey ? res.data.taskKey : existingTaskKey,
        id: res?.data.activityKey,
      };
      updateStatusOfMailKeys();
      showExistingActivity({...obj});
    }
  };

  const onNewIssueValueChange = async (e: any) => {
    try {
      if (e.value === constants.CREATE) {
        assignSingle();
      } else if (isAssignAllSelected) {
        assignMultiple();
      } else {
        assignAndOpenActivity();
      }
      dispatch(updateSideNavRefresh());
    } catch (error) {
      toast?.current.show({
        severity: 'warn',
        summary: constants.SOMETHING_WENT_WRONG,
      });
      if (e.value === constants.CREATE || isAssignAllSelected) {
        dispatch(hideDialog());
      }
    }
  };

  const newIssueOptions = [
    {
      label: isAssignAllSelected ? constants.CREATE_SINGLE : constants.CREATE,
      onClick: () => {
        onNewIssueValueChange({value: constants.CREATE});
      },
    },
    {
      label: isAssignAllSelected
        ? constants.CREATE_MULTIPLE
        : constants.CREATE_EDIT,
      onClick: () => {
        onNewIssueValueChange({value: constants.CREATE_EDIT});
      },
    },
  ];

  useEffect(() => {
    resetActivitiesOverviewPaginationAndSort({
      dispatch,
    });
    dispatch(updateIsEmailTemplateActivities(true));
    const e = {target: {checked: status === MAIL_STATUS.read}};
    onMarkAsReadCheck(e);
    // we can ignore this warning as we don't need any deps in this dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // reset activities data-grid pagination
  const resetPagination = () => {
    setPaginationModel({
      pageSize: paginationModel.pageSize,
      page: 0,
    });
  };

  useEffect(() => {
    if (isCustomerDropdownValueChanged && !isEmpty(selectedCustomer)) {
      getActivities({
        customerKeyParam: selectedCustomer?.custKey,
        sortQuery: sortColumn,
        pageSizeParam: paginationModel.pageSize,
        skipParam: skip,
      });
    }
    resetPagination();
    // we can ignore this warning as we need only 2 deps in this dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCustomerDropdownValueChanged, selectedCustomer]);

  useEffect(() => {
    if (!selectedRows) {
      setMailKeys([mailKey || 0]);
    } else {
      const mailKeysTemp = selectedRows?.map(
        ({mailKey: renamedMailKey}) => renamedMailKey,
      );
      setMailKeys(mailKeysTemp);
    }
    // we can ignore this warning as we need only "selectedRows" in this dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows]);

  useEffect(() => {
    if (!emailViewerDataFetched && mailKeys.length === 1) {
      setLoading(true);
      data
        .get(`v4/api/emailviewer/${groupKey}/${mailKeys[0]}`)
        .then(res => {
          setEmailViewerDataFetched(true);
          setMessage(res.data.bodyHtml);
          setAttachments(res.data.emailAttachments);
          setMailSubject(subject ?? res.data.subject);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setMessage('');
          setEmailViewerDataFetched(true);
          toast?.current.show({
            severity: 'warn',
            summary: constants.SOMETHING_WENT_WRONG,
            detail: '',
          });
        });
    }
    // we can ignore this warning as we need only 2 deps in this dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mailKeys, emailViewerDataFetched]);

  const loadOptions = async (
    searchQuery: string,
    loadedOptions: any,
    {page}: {page: number},
  ) => {
    const pageSize = 20;
    const request = {top: pageSize, skip: page};
    let filterQuery = null;
    if (searchQuery.trim().length > 0) {
      if (checkedSearchCustomerByEmail) {
        filterQuery = `EmailAddr like ${searchQuery.trim()}`;
      }
      if (searchQuery.includes(':')) {
        const colonIndex = searchQuery?.indexOf(':');
        const custID = searchQuery.substring(0, colonIndex).trim();
        const custName = searchQuery.substring(colonIndex + 1).trim();
        const customerIDQuery = custID ? `custid like '${custID}'` : '';
        const customerNameQuery = custName ? `custName like '${custName}'` : '';
        let operator = '';
        if (customerIDQuery && customerNameQuery) {
          operator = 'AND';
        } else if (customerNameQuery) {
          operator = 'OR';
        }
        filterQuery = `${customerIDQuery} ${operator} ${customerNameQuery}`;
      }
      if (!checkedSearchCustomerByEmail && !searchQuery.includes(':')) {
        filterQuery = `custid like '${searchQuery.trim()}' OR custName like '${searchQuery.trim()}'`;
      }
    }

    const res = await fetchCustomerInfoList(request, filterQuery?.trim());
    const totalRecordsLoaded =
      Number(loadedOptions.length) + res.data.value.length;

    setTotalRecordCount(res.data.count);
    setCurrentPage(() => totalRecordsLoaded);
    return {
      options: [...res.data.value],
      hasMore: totalRecordsLoaded < res.data.count,
      additional: {
        page: Number(page) + pageSize,
      },
    };
  };

  const onPageChange = (args: GridPaginationModel) => {
    const pageSkip =
      args.pageSize !== paginationModel.pageSize
        ? 0
        : (args.page + 1) * args.pageSize - args.pageSize;
    setPaginationModel({
      pageSize: args.pageSize,
      page: args.pageSize !== paginationModel.pageSize ? 0 : args.page,
    });
    setSkip(pageSkip);
    getActivities({
      customerKeyParam: selectedCustomer?.custKey,
      sortQuery: sortColumn,
      skipParam: pageSkip,
      pageSizeParam: args.pageSize,
    });
  };

  const onSortChange = (args: GridSortModel) => {
    setSortColumn(args);
    getActivities({
      customerKeyParam: selectedCustomer?.custKey,
      sortQuery: args,
      skipParam: skip,
      pageSizeParam: paginationModel.pageSize,
    });
  };

  const onAssignAll = (rowData: any) => {
    reassignAll(
      groupKey,
      customerKey,
      rowData.taskKey,
      mailKeys,
      followupChk,
      issueDescription,
    )
      .then(() => {
        setShowEmailSuccessfullyAssigned(true);
        updateStatusOfMailKeys();
        if (onReload) {
          onReload();
        }
      })
      .catch(() => {
        toast?.current.show({
          severity: 'warn',
          summary: constants.SOMETHING_WENT_WRONG,
          detail: constants.ASSIGNING_EMAIL_FAILED,
        });
        dispatch(hideDialog());
      });
  };

  const onAssignedSuccessful = () => {
    toast?.current.show({
      severity: constants.SUCCESS.toLocaleLowerCase(),
      summary: constants.SUCCESS,
      detail: constants.EMAIL_SUCCESSFULLY_ASSIGNED,
    });
    dispatch(updateAssignEmailStatus(true));
    setShowEmailSuccessfullyAssigned(false);
    navigateBack();
    if (onReload) {
      onReload();
    }
    dispatch(hideDialog());
    dispatch(updateIsEmailTemplateActivities(false));
  };

  const onAddContactBtnClicked = () => {
    setShowOnContactDialog(true);
  };

  const onSearchCustomerByEmailChange = (e: any) => {
    setCheckedSearchCustomerByEmail(e.target.checked);
  };

  const handleMenuOpen = () => {
    setCacheUniqs([true]);
  };

  const handleMenuClose = () => {
    setCacheUniqs([false]);
  };

  const columns: any[] = messageActivitiesOverviewColumns(onAssignAll);

  const onIssueDescriptionChange = ({
    target: {value},
  }: {
    target: {value: string};
  }) => {
    setIssueDescription(value);
  };

  return (
    <>
      <Dialog
        title={constants.ADD_CONTACT}
        open={showOnContactDialog}
        onClose={() => setShowOnContactDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <AddContactTemplate
          setShowOnContactDialog={setShowOnContactDialog}
          CustKey={selectedCustomer?.custKey}
        />
      </Dialog>
      <Dialog
        title={constants.FEATURE_NOT_AVAILABLE_TITLE}
        open={showFeatureNotAvailableDialog}
        onClose={() => setShowFeatureNotAvailableDialog(false)}
      >
        <FeatureNotAvailable />
      </Dialog>
      <Dialog
        title={constants.SUCCESS}
        open={showEmailSuccessfullyAssigned}
        onClose={onAssignedSuccessful}
      >
        <div className="d-flex ">
          <i className="pi pi-warning me-3 h-35-px" />
          <div className="d-flex flex-column h-100-px justify-content-around">
            <span> {constants.EMAIL_SUCCESSFULLY_ASSIGNED}</span>
            <Button
              variant="contained"
              size="medium"
              sx={{width: 'max-content', marginTop: '5px'}}
              onClick={onAssignedSuccessful}
            >
              {constants.OK}
            </Button>
          </div>
        </div>
      </Dialog>
      {isAssignMessagesTemplate && (
        <>
          <div className="mt-3">
            <span>
              {constants.EMAIL_FROM}: {from || constants.NONE}
              <Checkbox
                checked={checkedSearchCustomerByEmail}
                onChange={onSearchCustomerByEmailChange}
              />
              {constants.SEARCH_CUSTOMER_BY_EMAIL}
            </span>
          </div>
          <div className="mt-3 d-flex align-items-center flex-wrap">
            <AsyncSelect
              options={loadOptions}
              displayOnModal
              onChange={(customer: any) => {
                if (!CommunicateReassignClicked) {
                  getActivities({
                    customerKeyParam: customer.custKey,
                    sortQuery: sortColumn,
                    pageSizeParam: paginationModel.pageSize,
                    skipParam: skip,
                  });
                }
                setSelectedCustomer(customer);
                setCustomerKey(customer.custKey);
                setTabActiveIndex(0);
                dispatch(updateIsCustomerDropdownValueChanged(true));
              }}
              getOptionLabel={(option: any) =>
                `${option.custID}, ${option.custName}`
              }
              Header={<SelectHeader />}
              Footer={
                <SelectFooter
                  page={currentPage}
                  totalRecord={totalRecordCount}
                />
              }
              onMenuOpen={handleMenuOpen}
              onMenuClose={handleMenuClose}
              cacheUniqs={cacheUniqs}
              customComponents={{
                Option: SelectOptions,
              }}
              value={selectedCustomer}
            />

            {isCustomerDropdownValueChanged && (
              <>
                <Button
                  variant="contained"
                  className="ms-2"
                  onClick={onAddContactBtnClicked}
                  size="medium"
                >
                  {constants.ADD_CONTACT}
                </Button>

                <div className="ms-2">
                  <Menu
                    id="dropdown-menu"
                    anchorEl={issueAnchorEl}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    open={Boolean(issueAnchorEl)}
                    onClose={() => setIssueAnchorEl(null)}
                    items={newIssueOptions}
                  />
                </div>
                <Button
                  variant="contained"
                  onClick={event => setIssueAnchorEl(event.currentTarget)}
                  endIcon={<ArrowDropDownIcon />}
                  size="medium"
                >
                  <span className="d-flex align-items-center justify-content-between">
                    <i className="pi pi-communicate pe-2"> </i>
                    {constants.NEW_ISSUE}
                  </span>
                </Button>
                <div className="d-flex align-items-center ms-2">
                  <Checkbox
                    checked={followupChk}
                    onChange={e => setFollowupChk(e.target.checked)}
                  />
                  <span className="ps-1"> {constants.FOLLOWUP}</span>
                </div>
                <div className="d-flex align-items-center ms-2">
                  <Checkbox
                    name={constants.MARK_AS_READ_OR_UNREAD}
                    checked={markAsRead}
                    onChange={onMarkAsReadCheck}
                  />
                  <Typography className="ps-1">
                    {constants.MARK_AS_READ}
                  </Typography>
                </div>
                <TextField
                  label={`${constants.ISSUE_DESCRIPTION} (${constants.OPTIONAL})`}
                  multiline
                  InputProps={{
                    rows: 3,
                    inputProps: {maxLength: 100},
                  }}
                  value={issueDescription}
                  onChange={onIssueDescriptionChange}
                />
              </>
            )}
          </div>
          <div className="mt-3">
            <Tabs
              initialTab={tabActiveIndex}
              tabs={[
                {
                  label: constants.ACTIVITIES,
                  content: (
                    <div className="tabs-content-wrapper">
                      {' '}
                      {customerKey && (
                        <DataGrid
                          height={600}
                          columns={columns}
                          rowSelection={false}
                          rows={emailTemplateActivities}
                          rowCount={emailTemplateActivitiesCount}
                          onPageChange={onPageChange}
                          loading={emailTemplateActivitiesLoading}
                          sortModel={sortColumn}
                          onSortChange={onSortChange}
                          paginationModel={paginationModel}
                          getRowId={row => row.taskActKey}
                        />
                      )}
                    </div>
                  ),
                },
                {
                  label: constants.VIEW_EMAIL,
                  content: (
                    <div className="tabs-content-wrapper">
                      {' '}
                      {isAssignMessagesTemplate && (
                        <RenderEmailTemplateForm
                          message={message}
                          mailKey={mailKey}
                          attachments={attachments}
                          loading={loading}
                          from={from}
                          to={to}
                          cc={cc}
                          subject={mailSubject || ''}
                        />
                      )}{' '}
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </>
      )}
      {!isAssignMessagesTemplate && (
        <RenderEmailTemplateForm
          message={message}
          mailKey={mailKey}
          attachments={attachments}
          loading={loading}
          from={from}
          to={to}
          cc={cc}
          subject={mailSubject || ''}
        />
      )}
    </>
  );
}
export default EmailTemplate;
