import {actionTypes} from '../constants/actionTypes';

const initialState = {
  activityDetailCount: 0,
  activityDetailNextLink: '',
  activityDetail: [],
  activityDetailLoading: false,
  fetchingActivityDetail: false,
  fetchedActivityDetail: false,
  fetchingActivityDetailFailed: false,
  activityDetailPageSize: 10,
  activityDetailSkip: 0,
};

const activityDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_ACTIVITY_DETAIL:
      return {
        ...state,
        activityDetailLoading: true,
        fetchingActivityDetail: true,
        fetchedActivityDetail: false,
        fetchingActivityDetailFailed: false,
      };
    case actionTypes.FETCHED_ACTIVITY_DETAIL:
      return {
        ...state,
        activityDetailLoading: false,
        fetchingActivityDetail: false,
        fetchedActivityDetail: true,
        fetchingActivityDetailFailed: false,
        activityDetail: action.activityDetail,
        activityDetailCount: action.activityDetailCount,
        activityDetailNextLink: action.activityDetailNextLink,
        activityDetailSelectedRows: action.activityDetailSelectedRows,
      };
    case actionTypes.FETCHING_ACTIVITY_DETAIL_FAILED:
      return {
        ...state,
        activityDetailLoading: false,
        fetchingActivityDetail: false,
        fetchedActivityDetail: false,
        fetchingActivityDetailFailed: true,
      };
    case actionTypes.UPDATE_ACTIVITY_DETAIL_SKIP:
      return {
        ...state,
        activityDetailSkip: action.activityDetailSkip,
      };

    case actionTypes.UPDATING_ACTIVITY_DETAIL_PAGE_SIZE:
      return {
        ...state,
        activityDetailLoading: true,
      };
    case actionTypes.UPDATE_ACTIVITY_DETAIL_PAGE_SIZE:
      return {
        ...state,
        activityDetailPageSize: action.activityDetailPageSize,
        activityDetailLoading: false,
      };
    default:
      return state;
  }
};
export default activityDetailReducer;
