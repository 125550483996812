import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  fetchPortalAccess,
  updatePortalAccessPageSize,
  updatePortalAccessSkip,
  updatePortalAccessSort,
} from '../../../store/actions/customerInfoActions';
import {portalAccessColumns} from '../../common/table/columns/customer-info-tab-columns/portalAccessColumns';
import CustomDataTable from '../../common/table/CustomDataTable';

function PortalAccess() {
  const {
    fetchingPortalAccess: loading,
    skip: portalAccessSkip,
    pageSize: portalAccessPageSize,
    sortBy,
    sortDirection,
    portalAccessData,
    count,
  } = useSelector(store => store.customerInfoReducer.portalAccess);
  const selectedCustomer = useSelector(
    store => store.generalReducer?.selectedCustomer,
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [loadedPage, setLoadedPage] = useState(1);
  const dispatch = useDispatch();

  const updatePageRowCount = pageSize => {
    dispatch(updatePortalAccessPageSize(pageSize));
  };
  const onSortChange = ({field, direction}) => {
    dispatch(updatePortalAccessSort({field, direction}));
  };

  const loadPortalAccessData = () => {
    const {custKey} = selectedCustomer;
    dispatch(fetchPortalAccess({custKey}));
  };

  useEffect(() => {
    loadPortalAccessData();
  }, [
    portalAccessSkip,
    portalAccessPageSize,
    sortBy,
    sortDirection,
    selectedCustomer,
  ]);

  const onLoadMore = pageToLoad => {
    const skip = pageToLoad * portalAccessPageSize - portalAccessPageSize;
    dispatch(updatePortalAccessSkip(skip));
  };

  return (
    <div className="customer-info-item-tab-wrapper p-1">
      <CustomDataTable
        selectionMode={false}
        columns={portalAccessColumns()}
        tableData={portalAccessData}
        totalRecords={count}
        onLoadMore={onLoadMore}
        skip={portalAccessSkip}
        loading={loading}
        paginator
        pageRowCount={portalAccessPageSize}
        loadedPage={loadedPage}
        setLoadedPage={setLoadedPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        updatePageRowCount={updatePageRowCount}
        sortBy={sortBy}
        sortDirection={sortDirection}
        onSortChange={onSortChange}
      />
    </div>
  );
}

export default PortalAccess;
