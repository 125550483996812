import {Dropdown, InputNumber, InputTextarea} from 'primereact';
import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {AiOutlineInfoCircle} from 'react-icons/ai';
import {GridLogicOperator} from '@mui/x-data-grid';
import dayjs from 'dayjs';
import {constants} from '../../constants/common';
import {isEmpty} from '../../lib/utils';
import {
  makePayment,
  updateErrorMessage,
  updatePaymentErrorObject,
  updateSelectedCurrency,
} from '../../store/actions/paymentsCurrenciesActions';
import {
  ONLINE_CM_APPLICATION_TYPE,
  SURCHARGE_STATUS_CODE,
} from '../../utils/constants';
import {getCMApplicationsForInvoices} from '../../utils/paymentUtils';
import IconAlertCircle from '../images/custom-icons/IconAlertCircle';
import MakeAPaymentInfoDialog from './MakeAPaymentInfoDialog';
import {updateStatementOfAccountReset} from '../../store/actions/statementOfAccountActions';
import {Dialog} from '../../ui';
import {Button} from '../../ui/inputs';
import Typography from '../../ui/displays/typography/Typography';

const emptyPaymentMethod = {
  pmtMethodID: constants.NONE,
};

function RenderPaymentPanel({
  currencies,
  selectedCurrency,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  paymentMethods,
  setShowPaymentPanel,
  setShowPaymentInfo,
  interPaymentObj,
  paymentNote,
  setPaymentNote = () => {},
  paymentAmount,
  discountAmount,
  showAllAccounts,
  setFilterFieldsValues = () => {},
  setFilterFieldsBlur = () => {},
  paymentErrorObj,
  custKey,
  groupKey,
  selectedInvoices,
  errorMessage,
  paymentIncludesCR,
  paymentIncludesCM,
  paymentWithOtherTranTypeMessage,
  setIsEditPaymentMethod = () => {},
  setIsSelectedInvoicesBlur = booleanVal => {},
  makePaymentBtnText,
  makePaymentBtnDisplay,
  setExternalFilterWithDataGrid,
  allowDiscount,
  solupayJWTFeature,
  filterModel,
  statementCountAndTotals,
  allowOnlineCMApplication,
}) {
  const dispatch = useDispatch();
  const [additionalPayment, setAdditionalPayment] = useState('');
  const [totalPaymentAmount, setTotalPaymentAmount] = useState(0);
  const [showMakePaymentInfoDialog, setShowMakePaymentInfoDialog] =
    useState(false);
  const [showPaymentIncludesCRorCMDialog, setShowPaymentIncludesCRorCMDialog] =
    useState(false);
  const renderDropdown = props => <Dropdown {...props} />;

  const filterCurrency = {
    items: [
      {
        field: 'currId',
        operator: 'contains',
        value: '',
      },
    ],
    logicOperator: GridLogicOperator.And,
  };

  const resetErrorMessages = () => {
    dispatch(updatePaymentErrorObject({}));
    dispatch(updateErrorMessage(''));
  };

  const onCurrencyChange = e => {
    resetErrorMessages();
    dispatch(updateSelectedCurrency(e.value));
    dispatch(updateStatementOfAccountReset());
    // Push latest value of currency
    filterCurrency.items[0].value = e.value;
    const filterUpdate = {
      logicOperator: filterModel.logicOperator,
      items: [],
    };
    const removePrevCurrIdObjFromFilter = filterModel?.items?.filter(
      fModel => fModel.field !== 'currId',
    );
    const updatedCurrencyFilter = filterCurrency.items.filter(x => {
      return (
        (x.field === 'currId' && x.value === e.value && !x?.id) ||
        x.field !== 'currId'
      );
    });
    filterUpdate.items = [
      ...updatedCurrencyFilter,
      ...removePrevCurrIdObjFromFilter,
    ];

    filterCurrency.items = updatedCurrencyFilter;
    setExternalFilterWithDataGrid(filterUpdate);
    setFilterFieldsValues(prev => {
      return {...prev, currId: {value: e.value, type: 'string'}};
    });
    setFilterFieldsBlur(true);
  };

  const onPaymentMethodChange = e => {
    setSelectedPaymentMethod(e.value);
    setIsSelectedInvoicesBlur(true);
    resetErrorMessages();
  };
  const handleShowPaymentInfo = () => {
    setShowPaymentInfo(true);
    setIsEditPaymentMethod(true);
  };

  const handleSelectedPaymentMethod = () => {
    const defaultPaymentMethod = paymentMethods?.find(
      pMethod => pMethod.defaultMethod === 1,
    );
    if (paymentMethods.length && !isEmpty(defaultPaymentMethod)) {
      setSelectedPaymentMethod(defaultPaymentMethod);
      setIsSelectedInvoicesBlur(true);
    } else {
      setSelectedPaymentMethod(emptyPaymentMethod);
    }
  };

  const calculateDiscount = totalDisplayAmount => {
    if (totalDisplayAmount >= discountAmount && allowDiscount?.featureValue)
      setTotalPaymentAmount(totalDisplayAmount - discountAmount);
    else setTotalPaymentAmount(totalDisplayAmount);
  };

  const calculateAmount = (
    type,
    totalSumAmount,
    additionalAmount,
    totalPayment,
  ) => {
    let displayAmount = 0.0;

    if (type === 'additionalPayment') {
      setAdditionalPayment(additionalAmount);
      displayAmount = Number(totalSumAmount) + Number(additionalAmount);
      setTotalPaymentAmount(Number(totalSumAmount) + Number(additionalAmount));
    }
    if (type === 'totalPaymentAmount') {
      if (Number(totalPayment) > Number(totalSumAmount)) {
        setTotalPaymentAmount(totalPayment);
        displayAmount = totalPayment;
        setAdditionalPayment(Number(totalPayment) - Number(totalSumAmount));
      } else {
        setTotalPaymentAmount(totalSumAmount);
        setAdditionalPayment(0);
        displayAmount = totalSumAmount;
      }
    }
    if (type === 'totalAmount') {
      const amount = Number(totalSumAmount) + Number(additionalAmount || 0);
      setTotalPaymentAmount(amount);
      displayAmount = amount;
    }
    calculateDiscount(displayAmount);
    resetErrorMessages();
  };

  useEffect(() => {
    calculateAmount(
      'totalAmount',
      paymentAmount,
      additionalPayment,
      totalPaymentAmount,
    );
  }, [paymentAmount]);

  useEffect(() => {
    handleSelectedPaymentMethod();
  }, [paymentMethods]);

  useEffect(() => {
    calculateAmount(
      'totalAmount',
      paymentAmount,
      additionalPayment,
      totalPaymentAmount,
    );
  }, [discountAmount]);

  const onCancel = () => {
    setShowPaymentPanel(false);
    setAdditionalPayment('');
    setTotalPaymentAmount('');
    setFilterFieldsValues(prev => {
      return prev.currId ? {...prev, currId: {value: ''}} : prev;
    });
    setFilterFieldsBlur(true);
    dispatch(updateSelectedCurrency(''));
  };

  const makeAPayment = () => {
    const {invoices, CMApplications} = getCMApplicationsForInvoices({
      selectedStatement: selectedInvoices,
      groupKey,
    });
    if (!allowDiscount?.featureValue) {
      invoices.forEach((invoice, index) => {
        const updatedInvoice = {...invoice}; // Create a copy of the invoice object
        updatedInvoice.discountAmount = 0; // Modify the copied object's property
        // replacing the original invoice in the invoices array with the updated one:
        invoices[index] = updatedInvoice; // Replace the original invoice with the updated one
      });
    }

    let payload = {};
    if (interPaymentObj?.isSurchargeApplicable) {
      const invoiceArray = [];

      if (
        interPaymentObj?.surchargeStatusCode === 5 &&
        interPaymentObj?.transactionFeeResponses
      )
        payload = {
          paymentMethodId: selectedPaymentMethod.pmtMethodKey,
          invoices,
          CMApplications,
          paymentDate: dayjs.utc(),
        };
      else {
        interPaymentObj?.transactionFeeResponses.forEach(
          transactionFeeResponse => {
            invoices.find(invoice => {
              const ledgerHash = invoice.ledgerHash.split('-');
              const invoiceKey = ledgerHash.pop() || null;
              if (transactionFeeResponse.invoiceKey === invoiceKey) {
                invoiceArray.push({
                  invoiceId: invoice.invoiceId,
                  amount: invoice.amount,
                  InterPaymentTransactionId: transactionFeeResponse.sTxId,
                  InterPaymentTransactionFee:
                    transactionFeeResponse.transactionFee,
                  ledgerHash: invoice?.ledgerHash,
                  InterPaymentTransactionFeePercent:
                    transactionFeeResponse.transactionFeePercent,
                  InvoiceCreationDate: invoice.invoiceCreationDate,
                  CMApplications: transactionFeeResponse?.cmApplications,
                  discountAmount: invoice?.discountAmount,
                  discountDate: invoice?.discountDate,
                  invoiceTranAmount: invoice?.invoiceTranAmount,
                });
              }
            });
          },
        );
        const updatedInvoices =
          invoiceArray.length === 0 ? invoices : invoiceArray;

        payload = {
          paymentMethodId: selectedPaymentMethod.pmtMethodKey,
          invoices: updatedInvoices,
          paymentDate: dayjs.utc(),
        };
      }
    } else {
      payload = {
        paymentMethodId: selectedPaymentMethod.pmtMethodKey,
        invoices,
        CMApplications:
          allowOnlineCMApplication === ONLINE_CM_APPLICATION_TYPE.ALL
            ? CMApplications
            : [],
        paymentDate: dayjs.utc(),
      };
    }

    payload.note = paymentNote;
    payload.custKey = custKey;

    if (additionalPayment) {
      payload.invoices = [...payload.invoices, {amount: additionalPayment}];
    }

    const requestObj = {
      groupKey,
      payload,
    };
    setShowMakePaymentInfoDialog(false);
    dispatch(makePayment(requestObj));
    setIsEditPaymentMethod(false);
  };

  const handleUpdateErrorMessage = msg => {
    dispatch(updateErrorMessage(msg));
  };

  const filteredPaymentMethods = () => {
    const filteredPaymentMethodsTemp = paymentMethods.filter(
      methods => methods.isCreditCardTokenPresent,
    );
    return [emptyPaymentMethod, ...filteredPaymentMethodsTemp];
  };

  const {creditMemoCount} = statementCountAndTotals;
  return (
    <>
      <div className="d-flex justify-content-between my-2 align-items-center">
        <Dialog
          open={showPaymentIncludesCRorCMDialog}
          title={constants.MAKE_A_PAYMENT}
          onClose={() => setShowPaymentIncludesCRorCMDialog(false)}
          maxWidth="xs"
          fullWidth
        >
          <p className="py-2">{paymentWithOtherTranTypeMessage}</p>
          <div className="d-flex">
            <Button
              variant="contained"
              size="medium"
              onClick={() => {
                setShowPaymentIncludesCRorCMDialog(false);
                setShowMakePaymentInfoDialog(true);
              }}
            >
              {constants.OK}
            </Button>
            <Button
              className="ms-3"
              variant="contained"
              size="medium"
              onClick={() => setShowPaymentIncludesCRorCMDialog(false)}
            >
              {constants.CANCEL}
            </Button>
          </div>
        </Dialog>
        <div>
          <label htmlFor="selected-transaction-total" className="mx-2">
            {constants.TOTAL_OF_SELECTED_TRANSACTIONS}
          </label>
          <InputNumber
            id="selected-transaction-total"
            className="mx-2 input-text-custom"
            value={paymentAmount}
            useGrouping={false}
            mode="decimal"
            minFractionDigits={2}
            disabled
          />
        </div>
        <div>
          <label className="mx-2" htmlFor="additional-payment">
            {constants.ADDITIONAL_PAYMENT}
          </label>
          <InputNumber
            id="additional-payment"
            className="mx-2 input-text-custom"
            value={additionalPayment}
            onChange={e =>
              calculateAmount(
                'additionalPayment',
                paymentAmount,
                e.value,
                totalPaymentAmount,
              )
            }
            useGrouping={false}
            mode="decimal"
            minFractionDigits={2}
          />
        </div>
        <div>
          {/* check feature value of discount is 1 */}
          {allowDiscount?.featureValue ? (
            <>
              <label htmlFor="discount-amount" className="mx-2">
                {constants.DISCOUNT_AMOUNT}
              </label>
              <InputNumber
                id="discount-amount"
                className="mx-2 input-text-custom"
                mode="decimal"
                value={discountAmount}
                minFractionDigits={2}
                disabled
              />
            </>
          ) : (
            ''
          )}
        </div>
        <div>
          <label className="mx-2" htmlFor="payment-amount">
            {constants.PAYMENT_AMOUNT}
          </label>
          <InputNumber
            id="payment-amount"
            className="mx-2 input-text-custom"
            value={totalPaymentAmount}
            onChange={e => setTotalPaymentAmount(e.value)}
            useGrouping={false}
            mode="decimal"
            minFractionDigits={2}
            onBlur={() =>
              calculateAmount(
                'totalPaymentAmount',
                paymentAmount,
                additionalPayment,
                totalPaymentAmount,
              )
            }
          />
        </div>

        {interPaymentObj?.surchargeStatusCode ===
          SURCHARGE_STATUS_CODE.FAILED ||
        (interPaymentObj?.isSurchargeApplicable &&
          (interPaymentObj?.surchargeStatusCode ===
            SURCHARGE_STATUS_CODE.SUCCESS ||
            interPaymentObj?.surchargeStatusCode ===
              SURCHARGE_STATUS_CODE.Warning)) ? (
          <div>
            <label className="mx-2" htmlFor="transaction-fee">
              {constants.TRANSACTION_FEE}
            </label>
            <InputNumber
              id="transaction-fee"
              disabled
              className="mx-2 input-text-custom"
              value={interPaymentObj?.totalFee?.toFixed(2)}
              useGrouping={false}
              mode="decimal"
              minFractionDigits={2}
            />
          </div>
        ) : null}
        <div>
          {renderDropdown({
            placeholder: constants.SELECT_CURRENCY,
            options: currencies,
            value: selectedCurrency?.toUpperCase(),
            onChange: onCurrencyChange,
            className: 'bg-purple  mx-2 text-white w-150-px',
          })}
        </div>
      </div>
      <div className="d-flex justify-content-between my-3">
        {solupayJWTFeature?.featureValue !== 0 && makePaymentBtnDisplay && (
          <div>
            <label className="mx-2" htmlFor="payment-method">
              {constants.PAYMENT_METHOD}
            </label>
            {renderDropdown({
              id: 'payment-method',
              title: !selectedPaymentMethod
                ? constants.SELECT_PAYMENT_METHOD
                : selectedPaymentMethod.pmtMethodID,
              placeholder: isEmpty(selectedPaymentMethod)
                ? constants.SELECT_PAYMENT_METHOD
                : '',
              options: filteredPaymentMethods(),
              optionLabel: 'pmtMethodID',
              value: selectedPaymentMethod,
              onChange: onPaymentMethodChange,
              className: 'bg-purple mx-2 text-white w-150-px',
            })}
            <br />

            {makePaymentBtnDisplay && (
              <Button
                disabled={showAllAccounts}
                className="bg-light-blue my-2 border border-3 border-white"
                style={{color: 'white'}}
                onClick={handleShowPaymentInfo}
              >
                {constants.EDIT_PAYMENT_METHODS}
              </Button>
            )}
          </div>
        )}
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-column mx-5">
            {solupayJWTFeature?.featureValue !== 0 && makePaymentBtnDisplay && (
              <Button
                disabled={
                  (interPaymentObj?.isSurchargeApplicable &&
                    selectedInvoices.length === 0 &&
                    paymentMethods.length === 0) ||
                  showAllAccounts ||
                  (interPaymentObj?.isSurchargeApplicable &&
                    selectedPaymentMethod?.pmtMethodID === constants.NONE) ||
                  (interPaymentObj?.isSurchargeApplicable &&
                    interPaymentObj?.surchargeErrorMessage &&
                    interPaymentObj?.surchargeStatusCode !==
                      SURCHARGE_STATUS_CODE.ONE_OR_MORE_NEGATIVE_TRANSACTION) ||
                  interPaymentObj?.surchargeStatusCode ===
                    SURCHARGE_STATUS_CODE.FAILED
                }
                className="bg-light-blue  my-1 px-20 border border-3 border-white"
                onClick={() => {
                  if (paymentIncludesCR || paymentIncludesCM) {
                    setShowPaymentIncludesCRorCMDialog(true);
                  } else {
                    setShowMakePaymentInfoDialog(true);
                  }
                }}
                style={{color: 'white'}}
              >
                {makePaymentBtnText}
              </Button>
            )}
            <Button
              className="bg-light-blue  my-1 px-20 border border-3 border-white justify-content-center"
              onClick={() => onCancel()}
              style={{color: 'white'}}
            >
              {constants.CANCEL}
            </Button>
          </div>
          <div className="d-flex">
            <label htmlFor="pmt-note">{constants.PMT_NOTE}</label>
            <InputTextarea
              id="pmt-note"
              className="m-2 w-100"
              value={paymentNote}
              onChange={e => {
                setPaymentNote(e.target.value);
                resetErrorMessages();
              }}
              maxLength={100}
              rows={3}
              cols={100}
              autoResize
            />
          </div>
        </div>
      </div>
      {!isEmpty(errorMessage) && (
        <div className="my-2 ps-4 d-flex align-items-center">
          <AiOutlineInfoCircle color="red" size={24} className="me-2" />
          <p className="my-2 text-error bold">{errorMessage}</p>
        </div>
      )}
      {!isEmpty(paymentErrorObj) && (
        <div className="my-2 ps-4 d-flex align-items-center">
          <AiOutlineInfoCircle color="red" size={24} className="me-2" />
          <p className="my-2 text-error bold">
            {paymentErrorObj.paymentErrorMessage}
          </p>
        </div>
      )}
      {allowOnlineCMApplication !== ONLINE_CM_APPLICATION_TYPE.ALL &&
      creditMemoCount > 0 ? (
        <div className="my-3 px-2 d-flex align-items-center">
          <IconAlertCircle height={18} className="me-2" />
          <Typography fontWeight="bold">
            {constants.CM_NOT_ALLOWED_FROM_COMPANY_SETTING_MESSAGE}
          </Typography>
        </div>
      ) : (
        ''
      )}
      <div className="my-2 ps-4">
        {interPaymentObj?.surchargeStatusCode ===
          SURCHARGE_STATUS_CODE.FAILED ||
          interPaymentObj?.surchargeStatusCode ===
            SURCHARGE_STATUS_CODE.ONE_OR_MORE_NEGATIVE_TRANSACTION ||
          (interPaymentObj?.surchargeStatusCode ===
            SURCHARGE_STATUS_CODE.SUCCESS &&
            !isEmpty(interPaymentObj?.surchargeErrorMessage) && (
              <div className="my-3">
                <IconAlertCircle height={18} className="text-error me-2 " />
                <span className="text-error bold">
                  {interPaymentObj?.surchargeErrorMessage}
                </span>
              </div>
            ))}

        {interPaymentObj?.surchargeStatusCode ===
          SURCHARGE_STATUS_CODE.FAILED ||
        (interPaymentObj?.isSurchargeApplicable &&
          interPaymentObj?.surchargeStatusCode ===
            SURCHARGE_STATUS_CODE.SUCCESS) ? (
          <p className="text-error my-2 bold">
            <b>{`${constants.NOTICE}:`} </b>
            {constants.REMAINDER_FOR_TRANSACTION_FEE}
          </p>
        ) : null}
        {interPaymentObj?.isSurchargeApplicable &&
          interPaymentObj?.surchargeStatusCode ===
            SURCHARGE_STATUS_CODE.ONE_OR_MORE_NEGATIVE_TRANSACTION &&
          !isEmpty(selectedPaymentMethod) && (
            <p className="my-2 text-error bold">
              {`${constants.REMINDER.toLocaleUpperCase()}:`}{' '}
              {!isEmpty(interPaymentObj?.surchargeErrorMessage)
                ? interPaymentObj?.surchargeErrorMessage?.toUpperCase()
                : ''}
            </p>
          )}
        {interPaymentObj?.isSurchargeApplicable &&
          interPaymentObj?.surchargeStatusCode ===
            SURCHARGE_STATUS_CODE.Warning &&
          !isEmpty(selectedPaymentMethod) && (
            <p className="my-2 text-error bold">
              <b>{`${constants.WARNING}: `}</b>{' '}
              {interPaymentObj?.surchargeWarning?.toUpperCase()}
            </p>
          )}
      </div>
      <Dialog
        open={showMakePaymentInfoDialog}
        title={constants.MAKE_A_PAYMENT}
        onClose={() => setShowMakePaymentInfoDialog(false)}
        maxWidth="xs"
        fullWidth
      >
        <MakeAPaymentInfoDialog
          makeAPayment={makeAPayment}
          setShowMakePaymentInfoDialog={setShowMakePaymentInfoDialog}
          paymentAmount={totalPaymentAmount}
          handleUpdateErrorMessage={handleUpdateErrorMessage}
          selectedCurrency={selectedCurrency}
        />
      </Dialog>
    </>
  );
}
export default RenderPaymentPanel;
