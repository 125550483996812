import {IViewSetting} from '../components/common/view-settings/interface';
import {UDF_LABELS_REF} from './constants';

export const getUDFInvoiceList = (options: any, invoiceDetails: any) => {
  const udfInvoiceList: any = {};
  if (options.udfLabelInvc1) {
    udfInvoiceList.udf1 = {
      name: 'udf1',
      label: options.udfLabelInvc1,
      value: invoiceDetails?.udf1,
      editable: options.udfAllowInvc1,
    };
  }
  if (options.udfLabelInvc2) {
    udfInvoiceList.udf2 = {
      name: 'udf2',
      label: options.udfLabelInvc2,
      value: invoiceDetails?.udf2,
      editable: options.udfAllowInvc2,
    };
  }
  if (options.udfLabelInvc3) {
    udfInvoiceList.udf3 = {
      name: 'udf3',
      label: options.udfLabelInvc3,
      value: invoiceDetails?.udf3,
      editable: options.udfAllowInvc3,
    };
  }
  if (options.udfLabelInvc4) {
    udfInvoiceList.udf4 = {
      name: 'udf4',
      label: options.udfLabelInvc4,
      value: invoiceDetails?.udf4,
      editable: options.udfAllowInvc4,
    };
  }
  if (options.udfLabelInvc5) {
    udfInvoiceList.udf5 = {
      name: 'udf5',
      label: options.udfLabelInvc5,
      value: invoiceDetails?.udf5,
      editable: options.udfAllowInvc5,
    };
  }
  if (options.udfLabelInvc6) {
    udfInvoiceList.udf6 = {
      name: 'udf5',
      label: options.udfLabelInvc6,
      value: invoiceDetails?.udf6,
      editable: options.udfAllowInvc6,
    };
  }
  if (options.udfLabelInvc7) {
    udfInvoiceList.udf7 = {
      name: 'udf7',
      label: options.udfLabelInvc7,
      value: invoiceDetails?.udf7,
      editable: options.udfAllowInvc7,
    };
  }
  if (options.udfLabelInvc8) {
    udfInvoiceList.udf8 = {
      name: 'udf8',
      label: options.udfLabelInvc8,
      value: invoiceDetails?.udf8,
      editable: options.udfAllowInvc8,
    };
  }
  if (options.udfLabelInvc9) {
    udfInvoiceList.udf9 = {
      name: 'udf9',
      label: options.udfLabelInvc9,
      value: invoiceDetails?.udf9,
      editable: options.udfAllowInvc9,
    };
  }
  if (options.udfLabelInvc10) {
    udfInvoiceList.udf10 = {
      name: 'udf10',
      label: options.udfLabelInvc10,
      value: invoiceDetails?.udf10,
      editable: options.udfAllowInvc10,
    };
  }
  return udfInvoiceList;
};

export const getDisputeUDFInvoiceList = (options: any, invoiceDetails: any) => {
  const disputeUDFList: any = {};
  if (options.udfLabelDispInvc1) {
    disputeUDFList.disputeUdf1 = {
      name: 'disputeUdf1',
      label: options.udfLabelDispInvc1,
      value: invoiceDetails?.disputeUdf1,
      editable: options.udfAllowDispInvc1,
    };
  }
  if (options.udfLabelDispInvc2) {
    disputeUDFList.disputeUdf2 = {
      name: 'disputeUdf2',
      label: options.udfLabelDispInvc2,
      value: invoiceDetails?.disputeUdf2,
      editable: options.udfAllowDispInvc2,
    };
  }
  if (options.udfLabelDispInvc3) {
    disputeUDFList.disputeUdf3 = {
      name: 'disputeUdf3',
      label: options.udfLabelDispInvc3,
      value: invoiceDetails?.disputeUdf3,
      editable: options.udfAllowDispInvc3,
    };
  }
  if (options.udfLabelDispInvc4) {
    disputeUDFList.disputeUdf4 = {
      name: 'disputeUdf4',
      label: options.udfLabelDispInvc4,
      value: invoiceDetails?.disputeUdf4,
      editable: options.udfAllowDispInvc4,
    };
  }
  if (options.udfLabelDispInvc5) {
    disputeUDFList.disputeUdf5 = {
      name: 'disputeUdf5',
      label: options.udfLabelDispInvc5,
      value: invoiceDetails?.disputeUdf5,
      editable: options.udfAllowDispInvc5,
    };
  }

  return disputeUDFList;
};

export const getPaymentUDFList = (options: any, paymentDetails: any) => {
  const paymentUDFList: any = {};
  if (options.udfLabelCPmt1) {
    paymentUDFList.udf1 = {
      name: 'udf1',
      label: options.udfLabelCPmt1,
      value: paymentDetails.udF1,
      editable: options.udfAllowCPmt1,
    };
  }
  if (options.udfLabelCPmt2) {
    paymentUDFList.udf2 = {
      name: 'udf2',
      label: options.udfLabelCPmt2,
      value: paymentDetails.udF2,
      editable: options.udfAllowCPmt2,
    };
  }
  if (options.udfLabelCPmt3) {
    paymentUDFList.udf3 = {
      name: 'udf3',
      label: options.udfLabelCPmt3,
      value: paymentDetails.udF3,
      editable: options.udfAllowCPmt3,
    };
  }
  if (options.udfLabelCPmt4) {
    paymentUDFList.udf4 = {
      name: 'udf4',
      label: options.udfLabelCPmt4,
      value: paymentDetails.udF4,
      editable: options.udfAllowCPmt4,
    };
  }
  if (options.udfLabelCPmt5) {
    paymentUDFList.udf5 = {
      name: 'udf5',
      label: options.udfLabelCPmt5,
      value: paymentDetails.udF5,
      editable: options.udfAllowCPmt5,
    };
  }
  if (options.udfLabelCPmt6) {
    paymentUDFList.udf6 = {
      name: 'udf6',
      label: options.udfLabelCPmt6,
      value: paymentDetails.udF6,
      editable: options.udfAllowCPmt6,
    };
  }
  if (options.udfLabelCPmt7) {
    paymentUDFList.udf7 = {
      name: 'udf7',
      label: options.udfLabelCPmt7,
      value: paymentDetails.udF7,
      editable: options.udfAllowCPmt7,
    };
  }
  if (options.udfLabelCPmt8) {
    paymentUDFList.udf8 = {
      name: 'udf8',
      label: options.udfLabelCPmt8,
      value: paymentDetails.udF8,
      editable: options.udfAllowCPmt8,
    };
  }
  if (options.udfLabelCPmt9) {
    paymentUDFList.udf9 = {
      name: 'udf9',
      label: options.udfLabelCPmt9,
      value: paymentDetails.udF9,
      editable: options.udfAllowCPmt9,
    };
  }
  if (options.udfLabelCPmt10) {
    paymentUDFList.udf10 = {
      name: 'udf10',
      label: options.udfLabelCPmt10,
      value: paymentDetails.udF10,
      editable: options.udfAllowCPmt10,
    };
  }
  return paymentUDFList;
};

export const getUDFInfoList = (options: any, obj: any) => {
  const infoUDFList: any = {};
  if (options?.udfLabelCust1) {
    infoUDFList.udf1 = {
      name: 'UDF1',
      label: options?.udfLabelCust1,
      value: obj?.UDF1,
      editable: options?.udfAllowCust1,
    };
  }
  if (options?.udfLabelCust2) {
    infoUDFList.udf2 = {
      name: 'UDF2',
      label: options?.udfLabelCust2,
      value: obj?.UDF2,
      editable: options?.udfAllowCust2,
    };
  }
  if (options?.udfLabelCust3) {
    infoUDFList.udf3 = {
      name: 'UDF3',
      label: options?.udfLabelCust3,
      value: obj?.UDF3,
      editable: options?.udfAllowCust3,
    };
  }
  if (options?.udfLabelCust4) {
    infoUDFList.udf4 = {
      name: 'UDF4',
      label: options?.udfLabelCust4,
      value: obj?.UDF4,
      editable: options?.udfAllowCust4,
    };
  }
  if (options?.udfLabelCust5) {
    infoUDFList.udf5 = {
      name: 'UDF5',
      label: options?.udfLabelCust5,
      value: obj?.UDF5,
      editable: options?.udfAllowCust5,
    };
  }
  if (options?.udfLabelCust6) {
    infoUDFList.udf6 = {
      name: 'UDF6',
      label: options?.udfLabelCust6,
      value: obj?.UDF6,
      editable: options?.udfAllowCust6,
    };
  }
  if (options?.udfLabelCust7) {
    infoUDFList.udf7 = {
      name: 'UDF7',
      label: options?.udfLabelCust7,
      value: obj?.UDF7,
      editable: options?.udfAllowCust7,
    };
  }
  if (options?.udfLabelCust8) {
    infoUDFList.udf8 = {
      name: 'UDF8',
      label: options?.udfLabelCust8,
      value: obj?.UDF8,
      editable: options?.udfAllowCust8,
    };
  }
  if (options?.udfLabelCust9) {
    infoUDFList.udf9 = {
      name: 'UDF9',
      label: options?.udfLabelCust9,
      value: obj?.UDF9,
      editable: options?.udfAllowCust9,
    };
  }
  if (options?.udfLabelCust10) {
    infoUDFList.udf10 = {
      name: 'UDF10',
      label: options?.udfLabelCust10,
      value: obj?.UDF10,
      editable: options?.udfAllowCust10,
    };
  }
  return infoUDFList;
};

export const getUDFContactList = (options: any, obj: any) => {
  const contactUDFList: any = {};
  if (options?.udfLabelCntct1) {
    contactUDFList.udF1 = {
      name: 'UDF1',
      label: options?.udfLabelCntct1,
      value: obj?.udF1,
      editable: options?.udfAllowCntct1,
    };
  }
  if (options?.udfLabelCntct2) {
    contactUDFList.udF2 = {
      name: 'UDF2',
      label: options?.udfLabelCntct2,
      value: obj?.udF2,
      editable: options?.udfAllowCntct2,
    };
  }
  if (options?.udfLabelCntct3) {
    contactUDFList.udF3 = {
      name: 'UDF3',
      label: options?.udfLabelCntct3,
      value: obj?.udF3,
      editable: options?.udfAllowCntct3,
    };
  }
  if (options?.udfLabelCntct4) {
    contactUDFList.udF4 = {
      name: 'UDF4',
      label: options?.udfLabelCntct4,
      value: obj?.udF4,
      editable: options?.udfAllowCntct4,
    };
  }
  if (options?.udfLabelCntct5) {
    contactUDFList.udF5 = {
      name: 'UDF5',
      label: options?.udfLabelCntct5,
      value: obj?.udF5,
      editable: options?.udfAllowCntct5,
    };
  }
  if (options?.udfLabelCntct6) {
    contactUDFList.udF6 = {
      name: 'UDF6',
      label: options?.udfLabelCntct6,
      value: obj?.udF6,
      editable: options?.udfAllowCntct6,
    };
  }
  if (options?.udfLabelCntct7) {
    contactUDFList.udF7 = {
      name: 'UDF7',
      label: options?.udfLabelCntct7,
      value: obj?.udF7,
      editable: options?.udfAllowCntct7,
    };
  }
  if (options?.udfLabelCntct8) {
    contactUDFList.udF8 = {
      name: 'UDF8',
      label: options?.udfLabelCntct8,
      value: obj?.udF8,
      editable: options?.udfAllowCntct8,
    };
  }
  if (options?.udfLabelCntct9) {
    contactUDFList.udF9 = {
      name: 'UDF9',
      label: options?.udfLabelCntct9,
      value: obj?.udF9,
      editable: options?.udfAllowCntct9,
    };
  }
  if (options?.udfLabelCntct10) {
    contactUDFList.udF10 = {
      name: 'UDF10',
      label: options?.udfLabelCntct10,
      value: obj?.udF10,
      editable: options?.udfAllowCntct10,
    };
  }
  return contactUDFList;
};

export type UDFLabelRefType =
  (typeof UDF_LABELS_REF)[keyof typeof UDF_LABELS_REF];

interface IMapUDFDisplayTextParams {
  viewSettingsData: IViewSetting;
  mapping: any;
  udfLabel: UDFLabelRefType;
  alternateUDFKey?: string;
}

export const mapUDFDisplayText = ({
  viewSettingsData,
  mapping,
  udfLabel,
  alternateUDFKey,
}: IMapUDFDisplayTextParams): IViewSetting => {
  // Clone the viewSettingsData to avoid mutating the original data
  const newData = {...viewSettingsData};
  // Map over the columnSetting array
  newData.columnSetting = newData.columnSetting.map(item => {
    // Convert the dataField to lower case once and reuse it
    const dataFieldLower = item.dataField.toLowerCase();
    const udfKey = 'udf';
    // Check if the dataField is a UDF key
    if (
      dataFieldLower.startsWith(udfKey) ||
      (alternateUDFKey && dataFieldLower.startsWith(alternateUDFKey))
    ) {
      // Get the UDF number
      const sliceNumber = dataFieldLower.startsWith(udfKey)
        ? udfKey.length
        : alternateUDFKey?.length || 0;
      const udfNumber = dataFieldLower.slice(sliceNumber);
      // Construct the mapping key
      const mappingKey = `${udfLabel}${udfNumber}`;

      // If the mapping key exists in the mapping object, update the displayText
      if (mapping[mappingKey]) {
        return {
          ...item,
          // dataField should always return udf not invudf
          dataField: `${udfKey}${udfNumber}`,
          displayText: mapping[mappingKey],
        };
      }
    }
    // If the dataField is not a UDF key or the mapping key does not exist, return the item as is
    return item;
  });
  return newData;
};
export const removeUDFColumnsWithNoDisplayText = (
  mapping: any,
  columns: any,
  mappingKeyPrefix: UDFLabelRefType,
) => {
  // loop through the columns and remove the udf columns whose displayText is not present in the mapping
  return columns.filter((column: any) => {
    if (column.field.startsWith('udf')) {
      const udfNumber = column.field.slice(3);
      const mappingKey = `${mappingKeyPrefix}${udfNumber}`;
      return mapping[mappingKey];
    }
    return true;
  });
};

export const updateInvoiceLinesUDFColumns = (
  columns: any[],
  mapping: {[x: string]: any},
  udfLabel: UDFLabelRefType,
) => {
  const updatedColumns = columns.map(column => {
    const dataField = column.field;
    if (dataField.startsWith('udf')) {
      const udfNumber = dataField.slice(3);
      const mappingKey = `${udfLabel}${udfNumber}`;
      if (mapping[mappingKey]) {
        return {
          ...column,
          headerName: mapping[mappingKey],
        };
      }
    }
    return column;
  });
  return removeUDFColumnsWithNoDisplayText(mapping, updatedColumns, udfLabel);
};

interface Mapping {
  mapping: UDFLabelRefType;
}

export interface UDFColumnsToReplace {
  customerUDF: Mapping;
  disputeUDF: Mapping;
  invUDF: Mapping;
}

/**
 * Filters out UDF columns with no display text from the columns array.
 *
 * @param {any} mapping - The mapping object used to check for display text.
 * @param {any[]} columns - The array of columns to be filtered.
 * @param {UDFColumnsToReplace} prefixes - The object containing UDF columns to be replaced.
 * @returns {any[]} - The filtered array of columns.
 */
const removeUDFMultipleColumnsWithNoDisplayText = (
  mapping: any,
  columns: any[],
  prefixes: UDFColumnsToReplace,
): any[] => {
  try {
    return columns.filter((column: any) => {
      return !Object.entries(prefixes).some(([prefix, value]) => {
        if (column.field.startsWith(prefix)) {
          const udfNumber = column.field.slice(prefix.length);
          const mappingKey = `${value.mapping}${udfNumber}`;
          return !mapping[mappingKey] || mapping[mappingKey] === '';
        }
        return false;
      });
    });
  } catch (error) {
    console.error('Error filtering UDF columns:', error);
    return columns; // Return the original columns array in case of an error
  }
};

/**
 * Updates multiple UDF columns in the view settings data.
 *
 * @param {Object} params - The parameters for the function.
 * @param {IViewSetting} params.viewSettingsData - The view settings data to be updated.
 * @param {any} params.mapping - The mapping object used for removing columns with no display text.
 * @param {any[]} params.columns - The array of columns to be displayed.
 * @param {UDFColumnsToReplace} params.columnsToReplace - The object containing UDF columns to be replaced.
 * @returns {Object} - An object containing the updated view settings and columns to display.
 */
export const updateMultipleUDFColumns = ({
  viewSettingsData,
  mapping,
  columns,
  columnsToReplace,
}: {
  viewSettingsData: IViewSetting;
  mapping: any;
  columns: any[];
  columnsToReplace: UDFColumnsToReplace;
}) => {
  try {
    // Create a copy of the view settings data to avoid mutating the original object
    const newData = {...viewSettingsData};

    // Update the column settings with the new display text
    const updatedColumnSetting = newData.columnSetting.map(column => {
      const {dataField} = column;
      const updatedColumn = {...column};

      // Check if dataField matches any of the patterns in columnsToReplace
      Object.entries(columnsToReplace).forEach(([key, value]) => {
        const regex = new RegExp(`^${key}(\\d+)$`);
        const match = dataField.match(regex);
        if (match) {
          const udfNumber = match[1];
          const mappingKey = `${value.mapping}${udfNumber}`;
          updatedColumn.displayText = mapping[mappingKey];
        }
      });

      return updatedColumn;
    });

    // Assign the updated columns back to newData.columnSetting
    newData.columnSetting = updatedColumnSetting;

    // Determine the columns to display based on the mapping
    const columnsToDisplay = mapping
      ? removeUDFMultipleColumnsWithNoDisplayText(
          mapping,
          columns,
          columnsToReplace,
        )
      : columns;

    return {
      updatedViewSettings: newData,
      columnsToDisplay,
    };
  } catch (error) {
    console.error('Error updating multiple UDF columns:', error);
    return {
      updatedViewSettings: viewSettingsData,
      columnsToDisplay: columns,
    };
  }
};
