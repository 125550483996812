import moment from 'moment';
import dayjs from 'dayjs';
import {isEmpty} from '../lib/utils';

export const isDateLessThanToday = date => {
  if (isEmpty(date)) {
  } else {
    const _date = moment(date);
    const totalDays = moment
      .utc(_date)
      .startOf('day')
      .diff(moment.utc().startOf('day'), 'days');

    if (totalDays < 0) {
      return true;
    }
    return false;
  }
};

export const isValidDate = date => {
  return moment.utc(date, 'M/D/YYYY', true).isValid();
};

export const addDaysToDate = (days = '0', date) => {
  const date1 = dayjs(date)
  return dayjs(date1).add(parseInt(days),'day').format('M/DD/YYYY h:mm A');
};
