import {
  createContext,
  memo,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {constants} from '../../../../constants/common';
import Tabs from '../../../../ui/utils/tabs/Tabs';
import {PendingPaymentsSummary} from './pending-payments-summary/PendingPaymentsSummary';
import {PendingPaymentsDetails} from './pending-payments-details/PendingPaymentsDetails';
import {PendingPaymentsPurge} from './pending-payments-purge/PendingPaymentsPurge';
import {getQueryParameters} from '../../../../lib/utils';

const pendingPaymentContainerContext = createContext({});
const usePendingPaymentsContext = () => {
  return useContext(pendingPaymentContainerContext);
};

export const PendingPayments = memo(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const pendingPaymentTabs = [
    {
      label: constants.SUMMARY,
      content: <PendingPaymentsSummary />,
      disabled: false,
    },
    {
      label: constants.DETAILS,
      content: <PendingPaymentsDetails />,
    },
    {
      label: constants.PURGE,
      content: <PendingPaymentsPurge />,
    },
  ];

  const handleChange = (newValue: number) => {
    const searchParams = new URLSearchParams(location.search);

    const uniqueParams = new URLSearchParams();

    searchParams.forEach((value, key) => {
      if (!uniqueParams.has(key)) {
        uniqueParams.append(key, value);
      }
    });

    if (newValue === constants.PENDING_PAYMENTS_DETAILS_TAB) {
      uniqueParams.set('PmtType', constants.FAV_DETAILS);
    } else if (newValue === constants.PENDING_PAYMENTS_PURGE_TAB) {
      uniqueParams.set('PmtType', constants.FAV_PURGE);
    } else {
      uniqueParams.set('PmtType', constants.FAV_SUMMARY);
    }
    const queryString = `${location.pathname}?${uniqueParams.toString()}`;
    navigate(queryString);
  };

  useEffect(() => {
    if (location.search && location.search !== '') {
      const searchParams = new URLSearchParams(location.search);
      const uniqueParams = new URLSearchParams();

      searchParams.forEach((value, key) => {
        if (!uniqueParams.has(key)) {
          uniqueParams.append(key, value);
        }
      });
      const queryString = uniqueParams.toString();
      const {PmtType} = getQueryParameters(queryString.replace(/^#\/?/, ''));

      if (PmtType && PmtType === constants.FAV_DETAILS) {
        setSelectedTab(constants.PENDING_PAYMENTS_DETAILS_TAB);
      } else if (PmtType && PmtType === constants.FAV_PURGE) {
        setSelectedTab(constants.PENDING_PAYMENTS_PURGE_TAB);
      } else {
        setSelectedTab(constants.PENDING_PAYMENTS_SUMMARY_TAB);
      }
    }
  }, [location.search]);

  const providerValues = useMemo(() => ({}), []);

  return (
    <div>
      <pendingPaymentContainerContext.Provider value={providerValues}>
        <Tabs
          variant="scrollable"
          tabs={pendingPaymentTabs}
          value={selectedTab}
          initialTab={selectedTab}
          onTabChange={handleChange}
        />
      </pendingPaymentContainerContext.Provider>
    </div>
  );
});

export {usePendingPaymentsContext};
