import {actionTypes} from '../constants/actionTypes';

const initialState = {
  portalAccess: {
    portalAccessData: [],
    count: 0,
    pageSize: 10,
    skip: 0,
    sortBy: null,
    sortDirection: null,
    fetchingPortalAccess: false,
    fetchingPortalAccessFailed: false,
    fetchedPortalAccess: false,
  },
  creditDetails: {
    fetchingCreditDetails: false,
    fetchedCreditDetails: false,
    fetchingCreditDetailsFailed: false,
    creditDetails: null,
    creditClass: null,
    fetchingCreditClass: false,
    fetchedCreditClass: false,
    fetchingCreditClassFailed: false,
    savingCreditDetails: false,
    savedCreditDetails: false,
    savingCreditDetailsFailed: false,
    isEditMode: false,
  },
  info: {
    fetchingInfo: false,
    fetchedInfo: false,
    fetchingInfoFailed: false,
    info: null,
    invoiceForm: null,
    fetchingInvoiceForm: false,
    fetchedInvoiceForm: false,
    fetchingInvoiceFormFailed: false,
    savingInfo: false,
    savedInfo: false,
    savingInfoFailed: false,
    isEditMode: false,
  },
  history: {
    fetchingHistory: false,
    fetchedHistory: false,
    fetchingHistoryFailed: false,
    history: null,
  },
  rolledUpHistory: {
    fetchingRolledUpHistory: false,
    fetchedRolledUpHistory: false,
    fetchingRolledUpHistoryFailed: false,
    rolledUpHistory: null,
  },
  nacm: {
    fetchingNacm: false,
    fetchedNacm: false,
    fetchingNacmFailed: false,
    nacm: null,
  },
  transactions: {
    fetchingTransactions: false,
    fetchedTransactions: false,
    fetchingTransactionsFailed: false,
    transactions: null,
  },
  recentPayments: {
    fetchingRecentPayments: false,
    fetchedRecentPayments: false,
    fetchingRecentPaymentsFailed: false,
    recentPayments: null,
  },
  contacts: {
    fetchingContacts: false,
    fetchedContacts: false,
    fetchingContactsFailed: false,
    contacts: null,
    isEditMode: false,
    contactsPageSize: 2,
    contactsSkip: 0,
    dialog: {
      open: false,
      title: '',
      content: null,
      showCancelButton: false,
      showConfirmButton: false,
      onConfirm: () => {},
    },
  },
  accountNotes: {
    fetchingAccountNotes: false,
    fetchedAccountNotes: false,
    fetchingAccountNotesFailed: false,
    accountNotes: null,
  },
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const customerInfoReducer = (state = initialState, action) => {
  // eslint-disable-next-line sonarjs/max-switch-cases
  switch (action.type) {
    case actionTypes.FETCHING_PORTAL_ACCESS:
      return {
        ...state,
        fetchingPortalAccess: true,
        fetchingPortalAccessFailed: false,
        fetchedPortalAccess: false,
      };
    case actionTypes.FETCHED_PORTAL_ACCESS:
      return {
        ...state,
        fetchingPortalAccess: false,
        fetchingPortalAccessFailed: false,
        fetchedPortalAccess: true,
        portalAccess: {
          ...state.portalAccess,
          portalAccessData: action.portalAccessData,
          count: action.count,
        },
      };
    case actionTypes.FETCHING_PORTAL_ACCESS_FAILED:
      return {
        ...state,
        fetchingPortalAccess: false,
        fetchingPortalAccessFailed: true,
        fetchedPortalAccess: false,
      };
    case actionTypes.UPDATE_PORTAL_ACCESS_SKIP: {
      return {
        ...state,
        portalAccess: {
          ...state.portalAccess,
          skip: action.skip,
        },
      };
    }
    case actionTypes.UPDATED_PORTAL_ACCESS_PAGE_SIZE: {
      return {
        ...state,
        portalAccess: {
          ...state.portalAccess,
          pageSize: action.pageSize,
        },
      };
    }
    case actionTypes.UPDATE_PORTAL_ACCESS_SORT: {
      return {
        ...state,
        sortBy: action.sortBy,
        sortDirection: action.sortDirection,
      };
    }

    case actionTypes.FETCHING_CREDIT_DETAILS: {
      return {
        ...state,
        creditDetails: {
          ...state.creditDetails.creditDetails,
          fetchingCreditDetails: true,
          fetchedCreditDetails: false,
          fetchingCreditDetailsFailed: false,
          savingCreditDetails: false,
          savedCreditDetails: false,
          savingCreditDetailsFailed: false,
        },
      };
    }
    case actionTypes.FETCHED_CREDIT_DETAILS: {
      return {
        ...state,
        creditDetails: {
          ...state.creditDetails,
          creditDetails: action.creditDetails,
          fetchingCreditDetails: false,
          fetchedCreditDetails: true,
          fetchingCreditDetailsFailed: false,
        },
      };
    }
    case actionTypes.FETCHING_CREDIT_DETAILS_FAILED: {
      return {
        ...state,
        creditDetails: {
          ...state.creditDetails.creditDetails,
          fetchingCreditDetails: false,
          fetchedCreditDetails: false,
          fetchingCreditDetailsFailed: true,
        },
      };
    }

    case actionTypes.FETCHING_CREDIT_CLASS: {
      return {
        ...state,
        creditDetails: {
          ...state.creditDetails,
          fetchingCreditClass: true,
          fetchedCreditClass: false,
          fetchingCreditClassFailed: false,
        },
      };
    }
    case actionTypes.FETCHED_CREDIT_CLASS: {
      return {
        ...state,
        creditDetails: {
          ...state.creditDetails,
          creditClass: action.creditClass,
          fetchingCreditClass: false,
          fetchedCreditClass: true,
          fetchingCreditClassFailed: false,
        },
      };
    }
    case actionTypes.FETCHING_CREDIT_CLASS_FAILED: {
      return {
        ...state,
        creditDetails: {
          ...state.creditDetails,
          fetchingCreditClass: false,
          fetchedCreditClass: false,
          fetchingCreditClassFailed: true,
        },
      };
    }

    case actionTypes.FETCHING_INVOICE_FORM: {
      return {
        ...state,
        info: {
          ...state.info,
          fetchingInvoiceForm: true,
          fetchedInvoiceForm: false,
          fetchingInvoiceFormFailed: false,
        },
      };
    }
    case actionTypes.FETCHED_INVOICE_FORM: {
      return {
        ...state,
        info: {
          ...state.info,
          invoiceForm: action.invoiceForm,
          fetchingInvoiceForm: false,
          fetchedInvoiceForm: true,
          fetchingInvoiceFormFailed: false,
        },
      };
    }
    case actionTypes.FETCHING_INVOICE_FORM_FAILED: {
      return {
        ...state,
        info: {
          ...state.info,
          fetchingInvoiceForm: false,
          fetchedInvoiceForm: false,
          fetchingInvoiceFormFailed: true,
        },
      };
    }

    case actionTypes.SAVING_CREDIT_DETAILS: {
      return {
        ...state,
        creditDetails: {
          ...state.creditDetails.creditDetails,
          fetchingCreditDetails: false,
          fetchedCreditDetails: false,
          fetchingCreditDetailsFailed: false,
          savingCreditDetails: true,
          savedCreditDetails: false,
          savingCreditDetailsFailed: false,
        },
      };
    }
    case actionTypes.SAVED_CREDIT_DETAILS: {
      return {
        ...state,
        creditDetails: {
          ...state.creditDetails.creditDetails,
          fetchingCreditDetails: false,
          fetchedCreditDetails: false,
          fetchingCreditDetailsFailed: false,
          savingCreditDetails: false,
          savedCreditDetails: true,
          savingCreditDetailsFailed: false,
        },
      };
    }
    case actionTypes.SAVING_CREDIT_DETAILS_FAILED: {
      return {
        ...state,
        creditDetails: {
          ...state.creditDetails.creditDetails,
          fetchingCreditDetails: false,
          fetchedCreditDetails: false,
          fetchingCreditDetailsFailed: false,
          savingCreditDetails: false,
          savedCreditDetails: false,
          savingCreditDetailsFailed: true,
        },
      };
    }

    case actionTypes.FETCHING_INFO: {
      return {
        ...state,
        info: {
          ...state.info,
          fetchingInfo: true,
          fetchedInfo: false,
          fetchingInfoFailed: false,
          savingInfo: false,
          savedInfo: false,
          savingInfoFailed: false,
        },
      };
    }
    case actionTypes.FETCHED_INFO: {
      return {
        ...state,
        info: {
          ...state.info,
          fetchingInfo: false,
          fetchedInfo: true,
          fetchingInfoFailed: false,
          info: action.info,
        },
      };
    }
    case actionTypes.FETCHING_INFO_FAILED: {
      return {
        ...state,
        info: {
          ...state.info,
          fetchingInfo: false,
          fetchedInfo: false,
          fetchingInfoFailed: true,
        },
      };
    }
    case actionTypes.SAVING_INFO: {
      return {
        ...state,
        info: {
          ...state.info,
          fetchingInfo: false,
          fetchedInfo: false,
          fetchingInfoFailed: false,
          savingInfo: true,
          savedInfo: false,
          savingInfoFailed: false,
        },
      };
    }
    case actionTypes.SAVED_INFO: {
      return {
        ...state,
        info: {
          ...state.info,
          savingInfo: false,
          savedInfo: true,
          savingInfoFailed: false,
        },
      };
    }
    case actionTypes.SAVING_INFO_FAILED: {
      return {
        ...state,
        info: {
          ...state.info,
          savingInfo: false,
          savedInfo: false,
          savingInfoFailed: true,
        },
      };
    }
    case actionTypes.FETCHING_HISTORY: {
      return {
        ...state,
        history: {
          ...state.history,
          fetchingHistory: true,
          fetchedHistory: false,
          fetchingHistoryFailed: false,
        },
      };
    }
    case actionTypes.FETCHED_HISTORY: {
      return {
        ...state,
        history: {
          ...state.history,
          fetchingHistory: false,
          fetchedHistory: true,
          fetchingHistoryFailed: false,
          history: action.history,
        },
      };
    }
    case actionTypes.FETCHING_HISTORY_FAILED: {
      return {
        ...state,
        history: {
          ...state.history,
          fetchingHistory: false,
          fetchedHistory: false,
          fetchingHistoryFailed: true,
        },
      };
    }

    case actionTypes.FETCHING_ROLLED_UP_HISTORY: {
      return {
        ...state,
        rolledUpHistory: {
          ...state.rolledUpHistory,
          fetchingRolledUpHistory: true,
          fetchedRolledUpHistory: false,
          fetchingRolledUpHistoryFailed: false,
        },
      };
    }
    case actionTypes.FETCHED_ROLLED_UP_HISTORY: {
      return {
        ...state,
        rolledUpHistory: {
          ...state.rolledUpHistory,
          fetchingRolledUpHistory: false,
          fetchedRolledUpHistory: true,
          fetchingRolledUpHistoryFailed: false,
          rolledUpHistory: action.rolledUpHistory,
        },
      };
    }
    case actionTypes.FETCHING_ROLLED_UP_HISTORY_FAILED: {
      return {
        ...state,
        rolledUpHistory: {
          ...state.rolledUpHistory,
          fetchingRolledUpHistory: false,
          fetchedRolledUpHistory: false,
          fetchingRolledUpHistoryFailed: true,
        },
      };
    }
    case actionTypes.FETCHING_TRANSACTIONS: {
      return {
        ...state,
        transactions: {
          ...state.transactions,
          fetchingTransactions: true,
          fetchedTransactions: false,
          fetchingTransactionsFailed: false,
        },
      };
    }
    case actionTypes.FETCHED_TRANSACTIONS: {
      return {
        ...state,
        transactions: {
          ...state.transactions,
          fetchingTransactions: false,
          fetchedTransactions: true,
          fetchingTransactionsFailed: false,
          transactions: action.transactions,
        },
      };
    }
    case actionTypes.FETCHING_TRANSACTIONS_FAILED: {
      return {
        ...state,
        transactions: {
          ...state.transactions,
          fetchingTransactions: false,
          fetchedTransactions: false,
          fetchingTransactionsFailed: true,
        },
      };
    }

    case actionTypes.FETCHING_NACM: {
      return {
        ...state,
        nacm: {
          ...state.nacm,
          fetchingNacm: true,
          fetchedNacm: false,
          fetchingNacmFailed: false,
        },
      };
    }
    case actionTypes.FETCHED_NACM: {
      return {
        ...state,
        nacm: {
          ...state.nacm,
          fetchingNacm: false,
          fetchedNacm: true,
          fetchingNacmFailed: false,
          nacm: action.nacm,
        },
      };
    }
    case actionTypes.FETCHING_NACM_FAILED: {
      return {
        ...state,
        nacm: {
          ...state.nacm,
          fetchingNacm: false,
          fetchedNacm: false,
          fetchingNacmFailed: true,
        },
      };
    }

    case actionTypes.FETCHING_RECENT_PAYMENTS: {
      return {
        ...state,
        recentPayments: {
          ...state.recentPayments,
          fetchingRecentPayments: true,
          fetchedRecentPayments: false,
          fetchingRecentPaymentsFailed: false,
        },
      };
    }
    case actionTypes.FETCHED_RECENT_PAYMENTS: {
      return {
        ...state,
        recentPayments: {
          ...state.recentPayments,
          fetchingRecentPayments: false,
          fetchedRecentPayments: true,
          fetchingRecentPaymentsFailed: false,
          recentPayments: action.recentPayments,
        },
      };
    }
    case actionTypes.FETCHING_RECENT_PAYMENTS_FAILED: {
      return {
        ...state,
        recentPayments: {
          ...state.recentPayments,
          fetchingRecentPayments: false,
          fetchedRecentPayments: false,
          fetchingRecentPaymentsFailed: true,
        },
      };
    }

    case actionTypes.UPDATE_CREDIT_DETAILS_EDIT_MODE: {
      return {
        ...state,
        creditDetails: {
          ...state.creditDetails,
          isEditMode: action.isEditMode,
        },
      };
    }
    case actionTypes.UPDATE_INFO_EDIT_MODE: {
      return {
        ...state,
        info: {
          ...state.info,
          isEditMode: action.isEditMode,
        },
      };
    }
    case actionTypes.UPDATE_CONTACTS_EDIT_MODE: {
      return {
        ...state,
        contacts: {
          ...state.contacts,
          isEditMode: action.isEditMode,
        },
      };
    }
    case actionTypes.FETCHING_CONTACTS: {
      return {
        ...state,
        contacts: {
          ...state.contacts,
          fetchingContacts: true,
          fetchedContacts: false,
          fetchingContactsFailed: false,
        },
      };
    }
    case actionTypes.FETCHED_CONTACTS: {
      return {
        ...state,
        contacts: {
          ...state.contacts,
          fetchingContacts: false,
          fetchedContacts: true,
          fetchingContactsFailed: false,
          contacts: action.contacts,
        },
      };
    }
    case actionTypes.FETCHING_CONTACTS_FAILED: {
      return {
        ...state,
        contacts: {
          ...state.contacts,
          fetchingContacts: false,
          fetchedContacts: false,
          fetchingContactsFailed: true,
        },
      };
    }
    case actionTypes.UPDATE_CUSTOMER_INFO_CONTACTS_SKIP: {
      return {
        ...state,
        contacts: {
          ...state.contacts,
          contactsSkip: action.contactsSkip,
        },
      };
    }

    case actionTypes.UPDATE_CUSTOMER_INFO_CONTACTS_PAGE_SIZE: {
      return {
        ...state,
        contacts: {
          ...state.contacts,
          contactsPageSize: action.contactsPageSize,
        },
      };
    }

    case actionTypes.UPDATE_CONTACTS_DIALOG: {
      return {
        ...state,
        contacts: {
          ...state.contacts,
          dialog: {
            ...state.contacts.dialog,
            open: action.open,
            title: action.title || initialState.contacts.dialog.title,
            content: action.content || initialState.contacts.dialog.content,
            showCancelButton:
              action.showCancelButton ||
              initialState.contacts.dialog.showCancelButton,
            showConfirmButton:
              action.showConfirmButton ||
              initialState.contacts.dialog.showConfirmButton,
            onConfirm:
              action.onConfirm || initialState.contacts.dialog.onConfirm,
          },
        },
      };
    }
    default:
      return state;
  }
};
export default customerInfoReducer;
