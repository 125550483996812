import {actionTypes} from '../constants/actionTypes';

const initialState = {
  activitiesOverviewCount: 0,
  activitiesOverviewNextLink: '',
  activitiesOverview: [],
  activitiesOverviewLoading: false,
  fetchingActivitiesOverview: false,
  fetchedActivitiesOverview: false,
  fetchingActivitiesOverviewFailed: false,
  activitiesOverviewBestFit: false,
  activitiesOverviewPageSize: 10,
  activitiesOverviewSkip: 0,
  activitiesOverviewSelectedRows: [],
  exportingToExcel: false,
  exportedToExcel: false,
  exportingToExcelFailed: false,
  activitiesOverviewGlobalFilter: '',
  sortBy: '',
  sortDirection: null,
  isCustomerDropdownValueChanged: false,
  activitiesOverviewLoadedPage: 1,
  activitiesOverviewCurrentPage: 1,
  isEmailTemplateActivities: false,
  settingId: null,
  viewSettings: null,

  // EmailTemplate Activities
  emailTemplateActivitiesLoading: false,
  fetchingEmailTemplateActivities: false,
  fetchedEmailTemplateActivities: false,
  fetchingEmailTemplateActivitiesFailed: false,
  emailTemplateActivities: [],
  emailTemplateActivitiesCount: 0,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const activitiesOverviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_ACTIVITIES_OVERVIEW:
      return {
        ...state,
        activitiesOverviewLoading: true,
        fetchingActivitiesOverview: true,
        fetchedActivitiesOverview: false,
        fetchingActivitiesOverviewFailed: false,
      };
    case actionTypes.FETCHING_EMAIL_TEMPLATE_ACTIVITIES:
      return {
        ...state,
        emailTemplateActivitiesLoading: true,
        fetchingEmailTemplateActivities: true,
        fetchedEmailTemplateActivities: false,
        fetchingEmailTemplateActivitiesFailed: false,
      };
    case actionTypes.FETCHED_ACTIVITIES_OVERVIEW:
      return {
        ...state,
        activitiesOverviewLoading: false,
        fetchingActivitiesOverview: false,
        fetchedActivitiesOverview: true,
        fetchingActivitiesOverviewFailed: false,
        activitiesOverview: action.activitiesOverview,
        activitiesOverviewCount: action.activitiesOverviewCount,
        activitiesOverviewNextLink: action.activitiesOverviewNextLink,
      };
    case actionTypes.FETCHED_EMAIL_TEMPLATE_ACTIVITIES:
      return {
        ...state,
        emailTemplateActivitiesLoading: false,
        fetchingEmailTemplateActivities: false,
        fetchedEmailTemplateActivities: true,
        fetchingEmailTemplateActivitiesFailed: false,
        emailTemplateActivities: action.emailTemplateActivities,
        emailTemplateActivitiesCount: action.emailTemplateActivitiesCount,
      };
    case actionTypes.FETCHING_ACTIVITIES_OVERVIEW_FAILED:
      return {
        ...state,
        activitiesOverviewLoading: false,
        fetchingActivitiesOverview: false,
        fetchedActivitiesOverview: false,
        fetchingActivitiesOverviewFailed: true,
        activitiesOverview: [],
      };
    case actionTypes.FETCHING_EMAIL_TEMPLATE_ACTIVITIES_FAILED:
      return {
        ...state,
        emailTemplateActivitiesLoading: false,
        fetchingEmailTemplateActivities: false,
        fetchedEmailTemplateActivities: false,
        fetchingEmailTemplateActivitiesFailed: true,
        emailTemplateActivities: [],
      };
    case actionTypes.UPDATE_ACTIVITIES_OVERVIEW_SKIP:
      return {
        ...state,
        activitiesOverviewSkip: action.activitiesOverviewSkip,
      };
    case actionTypes.UPDATED_ACTIVITIES_OVERVIEW_SELECTED_ROWS:
      return {
        ...state,
        activitiesOverviewSelectedRows: action.activitiesOverviewSelectedRows,
      };
    case actionTypes.UPDATING_ACTIVITIES_OVERVIEW_PAGE_SIZE:
      return {
        ...state,
        activitiesOverviewLoading: true,
      };
    case actionTypes.UPDATE_ACTIVITIES_OVERVIEW_PAGE_SIZE:
      return {
        ...state,
        activitiesOverviewPageSize: action.activitiesOverviewPageSize,
        activitiesOverviewLoading: false,
      };
    case actionTypes.EXPORTING_ACTIVITIES_TO_EXCEL:
      return {
        ...state,
        activitiesOverviewLoading: true,
        exportingToExcel: true,
        exportedToExcel: false,
        exportingToExcelFailed: false,
      };
    case actionTypes.EXPORTED_ACTIVITIES_TO_EXCEL:
      return {
        ...state,
        activitiesOverviewLoading: false,
        exportingToExcel: false,
        exportedToExcel: true,
        exportingToExcelFailed: false,
      };
    case actionTypes.EXPORTING_ACTIVITIES_TO_EXCEL_FAILED:
      return {
        ...state,
        activitiesOverviewLoading: false,
        exportingToExcel: false,
        exportedToExcel: false,
        exportingToExcelFailed: true,
      };
    case actionTypes.SET_ACTIVITIES_OVERVIEW_GLOBAL_FILTER:
      return {
        ...state,
        activitiesOverviewGlobalFilter: action.activitiesOverviewGlobalFilter,
      };
    case actionTypes.UPDATE_ACTIVITIES_OVERVIEW_SORT:
      return {
        ...state,
        sortBy: action.sortBy,
        sortDirection: action.sortDirection,
      };
    case actionTypes.UPDATE_CUSTOMER_DROPDOWN_VALUE_CHANGED: {
      return {
        ...state,
        isCustomerDropdownValueChanged: action.isCustomerDropdownValueChanged,
      };
    }
    case actionTypes.UPDATE_ACTIVITIES_OVERVIEW_LOADED_PAGE:
      return {
        ...state,
        activitiesOverviewLoadedPage: action.activitiesOverviewLoadedPage,
      };
    case actionTypes.UPDATE_ACTIVITIES_OVERVIEW_CURRENT_PAGE:
      return {
        ...state,
        activitiesOverviewCurrentPage: action.activitiesOverviewCurrentPage,
      };
    case actionTypes.IS_EMAIL_TEMPLATE_ACTIVITIES:
      return {
        ...state,
        isEmailTemplateActivities: action.isEmailTemplateActivities,
      };
    case actionTypes.UPDATE_ACCOUNT_ACTIVITIES_SETTING_KEY:
      return {
        ...state,
        settingId: action.settingId,
      };
    case actionTypes.UPDATE_ACCOUNT_ACTIVITIES_SETTING_OBJECT:
      return {
        ...state,
        viewSettings: {...action.payload.viewSettings, sortSetting: null},
        settingId: action.payload.settingId,
      };
    case actionTypes.CLEAN_UP_ACTIVITIES_OVERVIEW_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
export default activitiesOverviewReducer;
