import {gridModeTypes} from '../constants/common';
import data from '../lib/data';
import {isEmpty} from '../lib/utils';
import {downloadBlobFilePostApi} from './download';

export const fetchInvoiceInstallments = options => {
  const endpoint = `v2/api/invoice/for/${options.groupKey}/${options.invcKey}/installments`;
  return data.get(endpoint);
};

export const saveInstallments = (request, {groupKey, invcKey}) => {
  const endpoint = `v2/api/invoice/for/${groupKey}/${invcKey}/installments`;
  return data.post(endpoint, request);
};

export const deleteAllInstallments = ({groupKey, invcKey, custKey}) => {
  const endpoint = `v2/api/invoice/for/${groupKey}/${custKey}/deleteinstallments/${invcKey}`;
  return data.delete(endpoint);
};

export const fetchUserInvoices = ({
  userKey,
  groupKey,
  settingKey,
  sortQuery,
  globalFilterQuery,
  sqlQuery,
  skip,
  pageSize,
}) => {
  let endpoint = `v2/api/invoice/${groupKey}/${userKey}/invoicesinfo?`;
  const sortJson =
    sortQuery?.length === 0
      ? null
      : {
          ViewSetting: {
            SortSetting: sortQuery.map((item, index) => {
              return {
                SortIndex: index + 1,
                SortCol: item.field,
                SortOrder: item.sort,
              };
            }),
          },
        };

  const payload = {
    countAll: true,
    mode: gridModeTypes.UserInvoices,
    sortJson: sortJson ? JSON.stringify(sortJson) : null,
    settingKey,
    columSetting: '',
    filterJson: globalFilterQuery ? JSON.stringify(globalFilterQuery) : null,
    filterSql: sqlQuery,
    source: 'react',
  };
  if (!isEmpty(skip)) {
    endpoint += `&%24skip=${skip}`;
  }

  if (!isEmpty(pageSize)) {
    endpoint += `&%24top=${pageSize}`;
  }

  return data.post(endpoint, payload);
};

export const exportUserInvoices = ({
  userKey,
  groupKey,
  settingKey,
  sortQuery,
  globalFilterQuery,
  sqlQuery,
  skip,
  pageSize,
}) => {
  let endpoint = `v4/api/export/UserInvoices?`;
  const sortJson =
    sortQuery?.length === 0
      ? null
      : {
          ViewSetting: {
            SortSetting: sortQuery.map((item, index) => {
              return {
                SortIndex: index + 1,
                SortCol: item.field,
                SortOrder: item.sort,
              };
            }),
          },
        };

  const payload = {
    countAll: true,
    mode: gridModeTypes.UserInvoices,
    sortJson: sortJson ? JSON.stringify(sortJson) : null,
    settingKey,
    columSetting: '',
    userKey,
    groupKey,
    filterJson: globalFilterQuery ? JSON.stringify(globalFilterQuery) : null,
    filterSql: sqlQuery,
    source: 'react',
  };
  if (!isEmpty(skip)) {
    endpoint += `&%24skip=${skip}`;
  }

  if (!isEmpty(pageSize)) {
    endpoint += `&%24top=${pageSize}`;
  }

  return downloadBlobFilePostApi(endpoint, payload);
};

export const fetchReportsButtonVisibility = groupKey => {
  const endpoint = `v4/api/collectreports/isPrintFromATCEnabled/${groupKey}?`;
  return data.get(endpoint);
};
