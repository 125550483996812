import {Dropdown} from 'primereact/dropdown';
import moment from 'moment';
import {constants} from '../../../../constants/common';

const filter = true;
const sortable = true;
const newActivityDdOptions = [
  {label: constants.NEW_ACTIVITY, value: 'newActivity'},
  {
    label: constants.ACCOUNT_DETAILS_OR_NEW_ACTIVITY,
    value: 'accountDetailOrNewActivity',
  },
];

export const activitiesColumns = bestFit => {
  return [
    {field: 'userId', header: 'User ID'},
    {
      rowEditor: false,
      style: {maxWidth: '15rem'},
      field: 'activity',
      header: 'Activity',
      onClick: () => alert('Clicked'),
      showFilterMenu: false,
      body: () => (
        <div>
          <Dropdown
            dropdownIcon=""
            optionLabel="label"
            options={newActivityDdOptions}
            className="bg-transparent border-0"
            placeholder={<i className="pi pi-activity" />}
            onChange={e => {
              e.value?.toLowerCase() ===
              'accountDetailOrNewActivity'.toLowerCase()
                ? (window.location = '#accountDetailOrNewActivity')
                : (window.location = '#newActivity');
            }}
          />
        </div>
      ),
    },
    {
      field: 'custId',
      header: 'Cust ID',
      filter,
      sortable,
      headerStyle: {minWidth: '10rem'},
      style: {maxWidth: bestFit ? '' : '10rem'},
      field: 'custId',
      header: 'Cust ID',
    },
    {
      filter,
      sortable,
      headerStyle: {minWidth: '10rem'},
      header: 'Cust Name',
      field: 'custName',
      body: (rowData, rowIndex) => (
        <a href="#openissue" className="blueLink">
          {rowData.description}
        </a>
      ),
    },
    {field: 'description', header: 'Issue Description'},
    {
      filter,
      sortable,
      headerStyle: {minWidth: '10rem'},
      header: 'Followup Desc',
      field: 'followupDesc',
      body: (rowData, rowIndex) => <span>{rowData.followupDesc}</span>,
    },
    {
      field: 'followupDate',
      header: 'Followup Date',
      filter,
      sortable,
      body: rowData => (
        <span>
          {rowData?.followupDate
            ? moment.utc(rowData?.followupDate).format('M/DD/YYYY h:mm A')
            : ''}
        </span>
      ),
    },
    {field: 'duration', header: 'Duration'},
    {
      filter,
      sortable,
      headerStyle: {minWidth: '10rem'},
      style: {maxWidth: bestFit ? '30rem' : '10rem'},
      field: 'notes',
      header: 'Activity Notes',
      body: rowData => (
        <span
          title={rowData.notes}
          dangerouslySetInnerHTML={{__html: rowData.notes}}
        />
      ),
    },
    {
      sortable,
      field: 'followUpDateDays',
      headerStyle: {minWidth: '5rem'},
      style: {maxWidth: bestFit ? '' : '5rem'},
      header: 'Days To Respond',
    },
    {field: 'parentId', header: 'Parent ID'},
    {field: 'followupRespondBy', header: 'Followup Respond By'},
    {
      sortable,
      field: 'dateEnteredDays',
      headerStyle: {minWidth: '5rem'},
      style: {maxWidth: bestFit ? '' : '5rem'},
      header: 'Date Entered Days',
    },
    {
      sortable,
      field: 'respondDueDays',
      headerStyle: {minWidth: '5rem'},
      style: {maxWidth: bestFit ? '' : '5rem'},
      header: 'Respond Due Days',
    },
    {field: 'timeZoneId', header: 'Timezone ID'},
    {
      filter,
      sortable,
      headerStyle: {minWidth: '10rem'},
      style: {maxWidth: bestFit ? '' : '5rem'},
      field: 'companyId',
      header: 'Company ID',
      body: rowData => (
        <span title={rowData.companyId}>{rowData.companyId}</span>
      ),
    },
  ];
};
