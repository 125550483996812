import dayjs from 'dayjs';
import {constants} from '../../../constants/common';
import {DisplayColumn, TagCount, ViewApplication} from './RowComponents';
import {Checkbox} from '../../../ui/inputs';
import {CheckUnCheckFilter} from '../../common/filter/FilterComponents';
import {filterOperators} from '../../../lib/commonTableHelpers';
import {InvoiceLink} from '../../common/RowComponents';

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);
export const statementOfAccountColumns = (
  isDiscountAmountColumnDisplay = false,
) => {
  let returnArray = [
    {
      field: 'tranId',
      headerName: 'Invoice',
      sortable: true,
      renderCell: cellValues => {
        return <InvoiceLink values={cellValues} />;
      },
      minWidth: 120,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'tranType',
      headerName: 'TranType',
      sortable: true,
      minWidth: 50,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'copyPDFToBlob',
      headerName: 'Display',
      sortable: false,
      renderCell: cellValues => {
        return <DisplayColumn values={cellValues} />;
      },
      minWidth: 80,
      renderHeaderFilter: () => null,
      type: 'boolean',
      filterOperators: filterOperators.boolean,
    },
    {
      field: 'tranDate',
      headerName: 'Invoice Date',
      sortable: true,
      valueFormatter: params =>
        params?.value
          ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
          : '',
      minWidth: 120,
      type: 'date',
      filterOperators: filterOperators.date,
    },
    {
      field: 'paySched',
      headerName: 'PaySched',
      sortable: true,
      renderCell: cellValues => {
        return <Checkbox disabled checked={cellValues.row.paySched} />;
      },
      minWidth: 50,
      renderHeaderFilter: params => <CheckUnCheckFilter {...params} />,
      type: 'boolean',
      filterOperators: filterOperators.boolean,
    },
    {
      field: 'dueDate',
      headerName: 'Due Date',
      sortable: true,
      valueFormatter: params =>
        params?.value
          ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
          : '',
      minWidth: 120,
      type: 'date',
      filterOperators: filterOperators.date,
    },
    {
      field: 'paymentAmount',
      headerName: 'Payment Amount',
      sortable: false,
      minWidth: 120,
      renderHeaderFilter: () => null,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'tranAmt',
      headerName: 'Amount',
      sortable: true,
      valueFormatter: params =>
        params?.value?.toLocaleString('en-US', {
          maximumFractionDigits: 2,
        }),
      minWidth: 80,
      type: 'number',
      filterOperators: filterOperators.number,
    },
    {
      field: 'balance',
      headerName: 'Balance',
      sortable: true,
      valueFormatter: params =>
        params?.value?.toLocaleString('en-US', {
          maximumFractionDigits: 2,
        }),
      minWidth: 80,
      type: 'number',
      filterOperators: filterOperators.number,
    },
    {
      field: 'currId',
      headerName: 'CurrID',
      sortable: true,
      minWidth: 80,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'dueDateDays',
      headerName: 'DaysPastDue',
      sortable: true,
      minWidth: 80,
      type: 'number',
      filterOperators: filterOperators.number,
    },
    {
      field: 'tagCount',
      headerName: 'View Activities',
      sortable: true,
      renderCell: cellValues => {
        return <TagCount values={cellValues} />;
      },
      minWidth: 80,
      renderHeaderFilter: () => null,
      type: 'number',
      filterOperators: filterOperators.number,
    },
    {
      field: 'pmtCount',
      headerName: 'View Applications',
      sortable: true,
      renderCell: cellValues => {
        return <ViewApplication values={cellValues} />;
      },
      minWidth: 80,
      renderHeaderFilter: () => null,
      type: 'number',
      filterOperators: filterOperators.number,
    },
    {
      field: 'reasonCode',
      headerName: 'Reason Code',
      sortable: false,
      minWidth: 120,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'inDisputeYn',
      headerName: 'InDispute',
      sortable: true,
      renderCell: cellValues => {
        return <Checkbox disabled checked={cellValues.row.inDispute} />;
      },
      minWidth: 80,
      renderHeaderFilter: params => <CheckUnCheckFilter {...params} />,
      type: 'boolean',
      filterOperators: filterOperators.boolean,
    },
    {
      field: 'totalPendPmt',
      headerName: 'TotalPendPmt',
      sortable: true,
      valueFormatter: params =>
        params?.value?.toLocaleString('en-US', {
          maximumFractionDigits: 2,
        }),
      minWidth: 80,
      type: 'number',
      filterOperators: filterOperators.number,
    },
    {
      field: 'consolidatedInvcNo',
      headerName: 'Consolidated Invc No',
      sortable: true,
      minWidth: 80,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'pmtRef',
      headerName: 'Check #/Reference',
      sortable: true,
      minWidth: 80,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: true,
      minWidth: 80,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'ageCurrent',
      headerName: 'Due Now',
      sortable: true,
      valueFormatter: params =>
        params?.value?.toLocaleString('en-US', {
          maximumFractionDigits: 2,
        }),
      minWidth: 80,
      type: 'number',
      filterOperators: filterOperators.number,
    },
    {
      field: 'ageFuture',
      headerName: 'Due Future',
      sortable: true,
      valueFormatter: params =>
        params?.value?.toLocaleString('en-US', {
          maximumFractionDigits: 2,
        }),
      minWidth: 80,
      type: 'number',
      filterOperators: filterOperators.number,
    },
    {
      field: 'age1',
      headerName: 'Late 31-60 Days',
      sortable: true,
      valueFormatter: params =>
        params?.value?.toLocaleString('en-US', {
          maximumFractionDigits: 2,
        }),
      minWidth: 80,
      type: 'number',
      filterOperators: filterOperators.number,
    },
    {
      field: 'age2',
      headerName: 'Late 61-90 Days',
      sortable: true,
      valueFormatter: params =>
        params?.value?.toLocaleString('en-US', {
          maximumFractionDigits: 2,
        }),
      minWidth: 80,
      type: 'number',
      filterOperators: filterOperators.number,
    },
    {
      field: 'age3',
      headerName: 'Late 91-120 Days',
      sortable: true,
      valueFormatter: params =>
        params?.value?.toLocaleString('en-US', {
          maximumFractionDigits: 2,
        }),
      minWidth: 80,
      type: 'number',
      filterOperators: filterOperators.number,
    },
    {
      field: 'age4',
      headerName: 'Late 121+ Days',
      sortable: true,
      valueFormatter: params =>
        params?.value?.toLocaleString('en-US', {
          maximumFractionDigits: 2,
        }),
      minWidth: 80,
      type: 'number',
      filterOperators: filterOperators.number,
    },
    {
      field: 'tranAmtHc',
      headerName: 'AmountHC',
      valueFormatter: params =>
        params?.value?.toLocaleString('en-US', {
          maximumFractionDigits: 2,
        }),
      sortable: true,
      minWidth: 80,
      type: 'number',
      filterOperators: filterOperators.number,
    },
    {
      field: 'balanceHc',
      headerName: 'BalanceHC',
      valueFormatter: params =>
        params?.value?.toLocaleString('en-US', {
          maximumFractionDigits: 2,
        }),
      sortable: true,
      minWidth: 80,
      type: 'number',
      filterOperators: filterOperators.number,
    },
    {
      field: 'tranDateDays',
      headerName: 'TranDateDays',
      sortable: true,
      minWidth: 80,
      type: 'number',
      filterOperators: filterOperators.number,
    },
    {
      field: 'custPono',
      headerName: 'CustPONo',
      sortable: true,
      minWidth: 80,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'lastActivityDate',
      headerName: 'Last Activity Date',
      sortable: true,
      valueFormatter: params =>
        params?.value
          ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
          : '',
      minWidth: 80,
      type: 'date',
      filterOperators: filterOperators.date,
    },
    {
      field: 'lastActivityId',
      headerName: 'Last Activity',
      sortable: true,
      minWidth: 80,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'lastActivityUserId',
      headerName: 'Last Activity UserID',
      sortable: true,
      minWidth: 80,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'lastActivityNote',
      headerName: 'Last Activity Note',
      sortable: true,
      minWidth: 120,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'udf1',
      headerName: 'InvUDF1',
      sortable: true,
      minWidth: 80,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'udf2',
      headerName: 'InvUDF2',
      sortable: true,
      minWidth: 80,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'udf3',
      headerName: 'InvUDF3',
      sortable: true,
      minWidth: 80,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'udf4',
      headerName: 'InvUDF4',
      sortable: true,
      minWidth: 80,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'udf5',
      headerName: 'InvUDF5',
      sortable: true,
      minWidth: 80,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'udf6',
      headerName: 'InvUDF6',
      sortable: true,
      minWidth: 80,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'udf7',
      headerName: 'InvUDF7',
      sortable: true,
      minWidth: 80,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'udf8',
      headerName: 'InvUDF8',
      sortable: true,
      minWidth: 80,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'udf9',
      headerName: 'InvUDF9',
      sortable: true,
      minWidth: 80,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'udf10',
      headerName: 'InvUDF10',
      sortable: true,
      minWidth: 80,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'disputeNote',
      headerName: 'Dispute Note',
      sortable: true,
      minWidth: 120,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'percentPaid',
      headerName: 'PercentPaid',
      sortable: true,
      type: 'number',
      valueFormatter: params =>
        params?.value?.toLocaleString('en-US', {
          maximumFractionDigits: 2,
        }),
      minWidth: 80,
    },
    {
      field: 'daysPaidLate',
      headerName: 'DaysPaidLate',
      sortable: true,
      minWidth: 80,
      type: 'number',
      filterOperators: filterOperators.number,
    },
    {
      field: 'lastPmtDate',
      headerName: 'LastPmtDate',
      sortable: true,
      valueFormatter: params =>
        params?.value
          ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
          : '',
      minWidth: 80,
      type: 'date',
      filterOperators: filterOperators.date,
    },
    {
      field: 'soldToId',
      headerName: 'SoldToID',
      sortable: true,
      minWidth: 120,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'invoiceCmnt',
      headerName: 'Comment',
      sortable: true,
      minWidth: 120,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'amount01',
      headerName: 'Amount01',
      sortable: true,
      valueFormatter: params =>
        params?.value?.toLocaleString('en-US', {
          maximumFractionDigits: 3,
        }),
      minWidth: 80,
      type: 'number',
      filterOperators: filterOperators.number,
    },
    {
      field: 'amount02',
      headerName: 'Amount02',
      sortable: true,
      valueFormatter: params =>
        params?.value?.toLocaleString('en-US', {
          maximumFractionDigits: 3,
        }),
      minWidth: 80,
      type: 'number',
      filterOperators: filterOperators.number,
    },
    {
      field: 'amount03',
      headerName: 'Amount03',
      sortable: true,
      valueFormatter: params =>
        params?.value?.toLocaleString('en-US', {
          maximumFractionDigits: 3,
        }),
      minWidth: 80,
      type: 'number',
      filterOperators: filterOperators.number,
    },
    {
      field: 'amount04',
      headerName: 'Amount04',
      sortable: true,
      valueFormatter: params =>
        params?.value?.toLocaleString('en-US', {
          maximumFractionDigits: 3,
        }),
      minWidth: 80,
      type: 'number',
      filterOperators: filterOperators.number,
    },
    {
      field: 'amount05',
      headerName: 'Amount05',
      sortable: true,
      valueFormatter: params =>
        params?.value?.toLocaleString('en-US', {
          maximumFractionDigits: 3,
        }),
      minWidth: 80,
      type: 'number',
      filterOperators: filterOperators.number,
    },
    {
      field: 'amount06',
      headerName: 'Amount06',
      sortable: true,
      valueFormatter: params =>
        params?.value?.toLocaleString('en-US', {
          maximumFractionDigits: 3,
        }),
      minWidth: 80,
      type: 'number',
      filterOperators: filterOperators.number,
    },
    {
      field: 'amount07',
      headerName: 'Amount07',
      sortable: true,
      valueFormatter: params =>
        params?.value?.toLocaleString('en-US', {
          maximumFractionDigits: 3,
        }),
      minWidth: 80,
      type: 'number',
      filterOperators: filterOperators.number,
    },
    {
      field: 'amount08',
      headerName: 'Amount08',
      sortable: true,
      valueFormatter: params =>
        params?.value?.toLocaleString('en-US', {
          maximumFractionDigits: 3,
        }),
      minWidth: 80,
      type: 'number',
      filterOperators: filterOperators.number,
    },
    {
      field: 'amount09',
      headerName: 'Amount09',
      sortable: true,
      valueFormatter: params =>
        params?.value?.toLocaleString('en-US', {
          maximumFractionDigits: 3,
        }),
      minWidth: 80,
      type: 'number',
      filterOperators: filterOperators.number,
    },
    {
      field: 'amount10',
      headerName: 'Amount10',
      sortable: true,
      valueFormatter: params =>
        params?.value?.toLocaleString('en-US', {
          maximumFractionDigits: 3,
        }),
      minWidth: 80,
      type: 'number',
      filterOperators: filterOperators.number,
    },
    {
      field: 'date01',
      headerName: 'Date01',
      sortable: true,
      valueFormatter: params =>
        params?.value
          ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
          : '',
      minWidth: 120,
      type: 'date',
      filterOperators: filterOperators.date,
    },
    {
      field: 'date02',
      headerName: 'Date02',
      sortable: true,
      valueFormatter: params =>
        params?.value
          ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
          : '',
      minWidth: 120,
      type: 'date',
      filterOperators: filterOperators.date,
    },
    {
      field: 'date03',
      headerName: 'Date03',
      sortable: true,
      valueFormatter: params =>
        params?.value
          ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
          : '',
      minWidth: 120,
      type: 'date',
      filterOperators: filterOperators.date,
    },
    {
      field: 'date04',
      headerName: 'Date04',
      sortable: true,
      valueFormatter: params =>
        params?.value
          ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
          : '',
      minWidth: 120,
      type: 'date',
      filterOperators: filterOperators.date,
    },
    {
      field: 'date05',
      headerName: 'Date05',
      sortable: true,
      valueFormatter: params =>
        params?.value
          ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
          : '',
      minWidth: 120,
      type: 'date',
      filterOperators: filterOperators.date,
    },
    {
      field: 'date06',
      headerName: 'Date06',
      sortable: true,
      valueFormatter: params =>
        params?.value
          ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
          : '',
      minWidth: 120,
      type: 'date',
      filterOperators: filterOperators.date,
    },
    {
      field: 'date07',
      headerName: 'Date07',
      sortable: true,
      valueFormatter: params =>
        params?.value
          ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
          : '',
      minWidth: 120,
      type: 'date',
      filterOperators: filterOperators.date,
    },
    {
      field: 'date08',
      headerName: 'Date08',
      sortable: true,
      valueFormatter: params =>
        params?.value
          ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
          : '',
      minWidth: 120,
      type: 'date',
      filterOperators: filterOperators.date,
    },
    {
      field: 'date09',
      headerName: 'Date09',
      sortable: true,
      valueFormatter: params =>
        params?.value
          ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
          : '',
      minWidth: 120,
      type: 'date',
      filterOperators: filterOperators.date,
    },
    {
      field: 'date10',
      headerName: 'Date10',
      sortable: true,
      valueFormatter: params =>
        params?.value
          ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
          : '',
      minWidth: 120,
      type: 'date',
      filterOperators: filterOperators.date,
    },
    {
      field: 'lastAutoActionId',
      headerName: 'Last Auto Action ID',
      sortable: true,
      minWidth: 80,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'lastAutoActionDate',
      headerName: 'Last Auto Action Date',
      sortable: true,
      valueFormatter: params =>
        params?.value
          ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
          : '',
      minWidth: 120,
      type: 'date',
      filterOperators: filterOperators.date,
    },
    {
      field: 'lastAutoActionDays',
      headerName: 'Last Auto Action Days',
      sortable: true,
      minWidth: 80,
      type: 'number',
      filterOperators: filterOperators.number,
    },
    {
      field: 'lastActivityDays',
      headerName: 'LastActivityDays',
      sortable: true,
      minWidth: 80,
      type: 'number',
      filterOperators: filterOperators.number,
    },
    {
      field: 'daysSinceOpenActivity',
      headerName: 'DaysSinceOpenActivity',
      sortable: true,
      minWidth: 80,
      type: 'number',
      filterOperators: filterOperators.number,
    },
    {
      field: 'daysSinceClosedActivity',
      headerName: 'DaysSinceClosedActivity',
      sortable: true,
      minWidth: 80,
      type: 'number',
      filterOperators: filterOperators.number,
    },
    {
      field: 'lastPmtAmt',
      headerName: 'LastPmtAmt',
      sortable: true,
      valueFormatter: params =>
        params?.value?.toLocaleString('en-US', {
          maximumFractionDigits: 2,
        }),
      minWidth: 80,
      type: 'number',
      filterOperators: filterOperators.number,
    },
    {
      field: 'lastPmtAmtHc',
      headerName: 'LastPmtAmtHC',
      sortable: true,
      valueFormatter: params =>
        params?.value?.toLocaleString('en-US', {
          maximumFractionDigits: 2,
        }),
      minWidth: 80,
      type: 'number',
      filterOperators: filterOperators.number,
    },
    {
      field: 'invcContactName',
      headerName: 'InvcContactName',
      sortable: true,
      minWidth: 120,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'invcContactName2',
      headerName: 'InvcContactName',
      sortable: true,
      minWidth: 120,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'insertDate',
      headerName: 'Insert Date',
      sortable: true,
      valueFormatter: params =>
        params?.value
          ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
          : '',
      minWidth: 120,
      type: 'date',
      filterOperators: filterOperators.date,
    },
    {
      field: 'updateDate',
      headerName: 'Update Date',
      sortable: true,
      valueFormatter: params =>
        params?.value
          ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
          : '',
      minWidth: 120,
      type: 'date',
      filterOperators: filterOperators.date,
    },
    {
      field: 'staxAmt',
      headerName: 'StaxAmt',
      sortable: true,
      valueFormatter: params =>
        params?.value?.toLocaleString('en-US', {
          maximumFractionDigits: 2,
        }),
      minWidth: 80,
      type: 'number',
      filterOperators: filterOperators.number,
    },
    {
      field: 'staxAmtHc',
      headerName: 'StaxAmtHC',
      sortable: true,
      valueFormatter: params =>
        params?.value?.toLocaleString('en-US', {
          maximumFractionDigits: 2,
        }),
      minWidth: 80,
      type: 'number',
      filterOperators: filterOperators.number,
    },
    {
      field: 'pmtTermsId',
      headerName: 'PmtTermsID',
      sortable: true,
      minWidth: 120,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'shipToAddrName',
      headerName: 'ShipToAddrName',
      sortable: true,
      minWidth: 120,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'shipToAddrLine1',
      headerName: 'ShipToAddrLine1',
      sortable: true,
      minWidth: 80,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'shipToAddrLine2',
      headerName: 'ShipToAddrLine2',
      sortable: true,
      minWidth: 80,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'shipToAddrLine3',
      headerName: 'ShipToAddrLine3',
      sortable: true,
      minWidth: 80,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'shipToAddrLine4',
      headerName: 'ShipToAddrLine4',
      sortable: true,
      minWidth: 80,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'shipToAddrLine5',
      headerName: 'ShipToAddrLine5',
      sortable: true,
      minWidth: 80,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'shipToCity',
      headerName: 'ShipToCity',
      sortable: true,
      minWidth: 80,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'shipToState',
      headerName: 'ShipToState',
      sortable: true,
      minWidth: 80,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'shipToPostalCode',
      headerName: 'ShipToPostalCode',
      sortable: true,
      minWidth: 80,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'shipToCountry',
      headerName: 'ShipToCountry',
      sortable: true,
      minWidth: 80,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'billToAddrName',
      headerName: 'BillToAddrName',
      sortable: true,
      minWidth: 80,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'billToAddrLine1',
      headerName: 'BillToAddrLine1',
      sortable: true,
      minWidth: 80,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'billToAddrLine2',
      headerName: 'BillToAddrLine2',
      sortable: true,
      minWidth: 80,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'billToAddrLine3',
      headerName: 'BillToAddrLine3',
      sortable: true,
      minWidth: 80,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'billToAddrLine4',
      headerName: 'BillToAddrLine4',
      sortable: true,
      minWidth: 80,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'billToAddrLine5',
      headerName: 'BillToAddrLine5',
      sortable: true,
      minWidth: 80,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'billToCity',
      headerName: 'BillToCity',
      sortable: true,
      minWidth: 80,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'billToState',
      headerName: 'BillToState',
      sortable: true,
      minWidth: 80,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'billToPostalCode',
      headerName: 'BillToPostalCode',
      sortable: true,
      minWidth: 80,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'billToCountry',
      headerName: 'BillToCountry',
      sortable: true,
      minWidth: 80,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'tenderTypeId',
      headerName: 'Tender Type',
      sortable: true,
      minWidth: 80,
      type: 'string',
      filterOperators: filterOperators.string,
    },
    {
      field: 'isPendPmt',
      headerName: 'IsPendPmt',
      sortable: true,
      renderCell: cellValues => {
        return <Checkbox disabled checked={cellValues.row.isPendPmt} />;
      },
      minWidth: 80,
      type: 'boolean',
      filterOperators: filterOperators.boolean,
    },
    {
      field: 'pmtDateDays',
      headerName: 'PmtDateDays',
      sortable: true,
      minWidth: 80,
      type: 'number',
      filterOperators: filterOperators.number,
    },
    {
      field: 'predictedPayDate',
      headerName: 'Predicted Payment Date',
      sortable: true,
      valueFormatter: params =>
        params?.value
          ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
          : '',
      minWidth: 120,
      type: 'date',
      filterOperators: filterOperators.date,
    },
    {
      field: 'predictedPayAmt',
      headerName: 'Predicted Payment Amount',
      sortable: true,
      valueFormatter: params =>
        params?.value?.toLocaleString('en-US', {
          maximumFractionDigits: 2,
        }),
      minWidth: 80,
      type: 'number',
      filterOperators: filterOperators.number,
    },
    {
      field: 'predictedPayReason',
      headerName: 'Predicted Payment Reason',
      sortable: true,
      minWidth: 80,
      type: 'string',
      filterOperators: filterOperators.string,
    },
  ];
  returnArray = isDiscountAmountColumnDisplay
    ? [
        ...returnArray,
        {
          field: 'discDate',
          headerName: 'Discount Date',
          valueFormatter: params =>
            params?.value
              ? dayjs(params?.value).utc().format(constants.DATE_TIME_FORMAT)
              : '',
          sortable: true,
          minWidth: 130,
          type: 'date',
          filterOperators: filterOperators.date,
        },
        {
          field: 'discAmt',
          headerName: 'DiscAmt',
          sortable: true,
          minWidth: 80,
          valueFormatter: params =>
            params?.value?.toLocaleString('en-US', {
              maximumFractionDigits: 3,
            }),
          type: 'number',
          filterOperators: filterOperators.number,
        },
        // {
        //   field: 'overrideDiscount',
        //   headerName: 'Override Discount',
        //   sortable: false,
        //   minWidth: 50,
        //   renderHeaderFilter: params => <CheckUnCheckFilter {...params} />,
        //   type: 'boolean',
        //   filterOperators: filterOperators.boolean,
        // },
      ]
    : returnArray;
  return returnArray;
};
