import {components} from 'react-select';

export const ActivityTypeOptions = props => {
  const {activityID, description} = props.data;
  return (
    <components.Option {...props}>
      <div className="d-flex">
        <div className="px-1 w-50">{activityID}</div>
        <div className="px-2 w-50">{description}</div>
      </div>
    </components.Option>
  );
};
