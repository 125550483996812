type HandleSetShowCustomerDocsType = ({
  invcKey,
  tranId,
  tranType,
}: {
  invcKey?: string;
  tranId?: string;
  tranType?: string;
}) => void;
export const createHandleSetShowCustomerDocs =
  (handleSetShowCustomerDocs: HandleSetShowCustomerDocsType) =>
  (invcKey?: string, tranIdParam?: string, tranTypeParam?: string) =>
    handleSetShowCustomerDocs({
      invcKey,
      tranId: tranIdParam,
      tranType: tranTypeParam,
    });

export function getFileNameFromUrl(url: string): string | null {
  try {
    // Split the URL by '/'
    const parts = url.split('/');

    // Use pop() to get the last part (which is the file name)
    const fileName = parts.pop();

    if (!fileName) {
      throw new Error('No file name found in the URL');
    }

    // Decode URI components to handle special characters properly
    return decodeURIComponent(fileName);
  } catch (error) {
    return null;
  }
}
