import data from '../lib/data';

export const createFileDirectory = requestPayload => {
  const endpoint = `v2/api/upload/directory`;
  return data.post(endpoint, requestPayload);
};

export const fetchFileList = ({storageType, blobPath, groupKey}) => {
  const endpoint = `v2/api/files?storageType=${storageType}&blobPath=${blobPath}&groupKey=${groupKey}`;
  return data.get(endpoint);
};

export const uploadFilesToDirectory = ({
  StorageType,
  BlobPath,
  GroupKey,
  Files,
}) => {
  const formData = new FormData();
  formData.append('StorageType', StorageType);
  formData.append('BlobPath', BlobPath);
  formData.append('GroupKey', GroupKey);
  Files.forEach(file => {
    formData.append('Files', file);
  });

  const endpoint = `v2/api/upload/file`;
  const headers = {'Content-Type': 'multipart/form-data'};
  return data.post(endpoint, formData, headers);
};

export const deleteFileDirectory = requestPayload => {
  const endpoint = `v2/api/file`;
  return data.delete(endpoint, {data: requestPayload});
};

export const uploadToAttachments = requestPayload => {
  const endpoint = `v4/api/activity/for/attachToCommunicate`;
  return data.post(endpoint, requestPayload);
};

export const uploadFilesToBlobStorage = requestPayload => {
  const formData = new FormData();
  formData.append('StorageType', requestPayload.StorageType);
  formData.append('BlobPath', requestPayload.BlobPath);
  formData.append('GroupKey', requestPayload.GroupKey);
  formData.append('AddToQueue', requestPayload.AddToQueue);
  formData.append('UserId', requestPayload.UserId);
  requestPayload.Files.forEach(file => {
    formData.append('Files', file);
  });
  const endpoint = `v2/api/upload/file`;
  const headers = {'Content-Type': 'multipart/form-data'};
  return data.post(endpoint, formData, headers);
};
