import moment from 'moment';
import PropTypes from 'prop-types';
import {constants} from '../../../constants/common';
import {isEmpty} from '../../../lib/utils';
import CheckboxLabel from '../../../ui/inputs/checkbox/CheckboxLabel';
import TextField from '../../../ui/inputs/textfield/TextField';
import {StyledFormDivColumns} from '../helper';
import {editableFieldClassName} from './classNameConstants';

function ContactsFormTemplate({
  isEditMode,
  selectedContact,
  editObj,
  onValueChange,
  contactNameError,
  emailAddrError,
  setContactNameError,
  setEmailAddrError,
  addBtnClicked,
}) {
  return (
    <>
      <div className="d-flex">
        <StyledFormDivColumns>
          <div className={isEditMode && editableFieldClassName}>
            <TextField
              value={
                isEditMode ? editObj?.contactName : selectedContact?.contactName
              }
              error={contactNameError}
              helperText={
                contactNameError && constants.CONTACT_NAME_IS_REQUIRED
              }
              required={isEditMode}
              label={constants.NAME}
              disabled={!isEditMode}
              InputLabelProps={
                (!isEditMode || !isEmpty(selectedContact?.contactName)) && {
                  shrink: true,
                }
              }
              onChange={e => {
                setContactNameError(false);
                onValueChange(e.target.value, 'contactName');
              }}
            />
          </div>
          <div className={isEditMode && editableFieldClassName}>
            <TextField
              value={isEditMode ? editObj?.printAs : selectedContact?.printAs}
              label={constants.PRINT_AS}
              disabled={!isEditMode}
              InputLabelProps={
                (!isEditMode || !isEmpty(selectedContact?.printAs)) && {
                  shrink: true,
                }
              }
              onChange={e => onValueChange(e.target.value, 'printAs')}
            />
          </div>
          <div className={isEditMode && editableFieldClassName}>
            <TextField
              value={isEditMode ? editObj?.title : selectedContact?.title}
              label={constants.TITLE}
              disabled={!isEditMode}
              InputLabelProps={
                (!isEditMode || !isEmpty(selectedContact?.title)) && {
                  shrink: true,
                }
              }
              onChange={e => onValueChange(e.target.value, 'title')}
            />
          </div>
          <div className={isEditMode && editableFieldClassName}>
            <TextField
              value={
                isEditMode ? editObj?.addrLine1 : selectedContact?.addrLine1
              }
              label={constants.addressLine(1)}
              disabled={!isEditMode}
              InputLabelProps={
                (!isEditMode || !isEmpty(selectedContact?.addrLine1)) && {
                  shrink: true,
                }
              }
              onChange={e => onValueChange(e.target.value, 'addrLine1')}
            />
          </div>
          <div className={isEditMode && editableFieldClassName}>
            <TextField
              value={
                isEditMode ? editObj?.addrLine2 : selectedContact?.addrLine2
              }
              label={constants.addressLine(2)}
              disabled={!isEditMode}
              InputLabelProps={
                (!isEditMode || !isEmpty(selectedContact?.addrLine2)) && {
                  shrink: true,
                }
              }
              onChange={e => onValueChange(e.target.value, 'addrLine2')}
            />
          </div>
          <div className={isEditMode && editableFieldClassName}>
            <TextField
              value={
                isEditMode ? editObj?.addrLine3 : selectedContact?.addrLine3
              }
              label={constants.addressLine(3)}
              disabled={!isEditMode}
              InputLabelProps={
                (!isEditMode || !isEmpty(selectedContact?.addrLine3)) && {
                  shrink: true,
                }
              }
              onChange={e => onValueChange(e.target.value, 'addrLine3')}
            />
          </div>
          <div
            className={`d-flex align-items-center ${
              isEditMode && editableFieldClassName
            }`}
          >
            <CheckboxLabel
              checked={
                isEditMode ? editObj?.isInactive : selectedContact?.isInactive
              }
              label={constants.IS_INACTIVE}
              disabled={!isEditMode}
              InputLabelProps={
                (!isEditMode || !isEmpty(selectedContact?.isInactive)) && {
                  shrink: true,
                }
              }
              onChange={e => onValueChange(e.target.checked, 'isInactive')}
            />
          </div>
        </StyledFormDivColumns>
        <StyledFormDivColumns>
          <div className={isEditMode && editableFieldClassName}>
            <TextField
              value={
                isEditMode ? editObj?.emailAddr : selectedContact?.emailAddr
              }
              error={emailAddrError}
              helperText={emailAddrError && constants.EMAIL_ADDR_IS_REQUIRED}
              required={isEditMode}
              label={constants.EMAIL}
              disabled={!isEditMode}
              InputLabelProps={
                (!isEditMode || !isEmpty(selectedContact?.emailAddr)) && {
                  shrink: true,
                }
              }
              onChange={e => {
                setEmailAddrError(false);
                onValueChange(e.target.value, 'emailAddr');
              }}
            />
          </div>
          <div className={isEditMode && editableFieldClassName}>
            <TextField
              value={isEditMode ? editObj?.phone : selectedContact?.phone}
              label={constants.PHONE}
              disabled={!isEditMode}
              InputLabelProps={
                (!isEditMode || !isEmpty(selectedContact?.phone)) && {
                  shrink: true,
                }
              }
              onChange={e => onValueChange(e.target.value, 'phone')}
            />
          </div>
          <div className={isEditMode && editableFieldClassName}>
            <TextField
              value={isEditMode ? editObj?.fax : selectedContact?.fax}
              label={constants.FAX}
              disabled={!isEditMode}
              InputLabelProps={
                (!isEditMode || !isEmpty(selectedContact?.fax)) && {
                  shrink: true,
                }
              }
              onChange={e => onValueChange(e.target.value, 'fax')}
            />
          </div>
          <div className={isEditMode && editableFieldClassName}>
            <TextField
              value={isEditMode ? editObj?.city : selectedContact?.city}
              label={constants.CITY}
              disabled={!isEditMode}
              InputLabelProps={
                (!isEditMode || !isEmpty(selectedContact?.city)) && {
                  shrink: true,
                }
              }
              onChange={e => onValueChange(e.target.value, 'city')}
            />
          </div>
          <div className={isEditMode && editableFieldClassName}>
            <TextField
              value={
                isEditMode ? editObj?.postalCode : selectedContact?.postalCode
              }
              label={constants.ZIP}
              disabled={!isEditMode}
              InputLabelProps={
                (!isEditMode || !isEmpty(selectedContact?.postalCode)) && {
                  shrink: true,
                }
              }
              onChange={e => onValueChange(e.target.value, 'postalCode')}
            />
          </div>
          <div className={isEditMode && editableFieldClassName}>
            <TextField
              value={
                isEditMode && addBtnClicked
                  ? ''
                  : isEmpty(selectedContact?.insertDate)
                  ? ''
                  : moment.utc(selectedContact?.insertDate).format('M/D/YYYY')
              }
              label={constants.INSERT_DATE}
              disabled
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        </StyledFormDivColumns>
        <StyledFormDivColumns>
          <div className={isEditMode && editableFieldClassName}>
            <TextField
              value={
                isEditMode ? editObj?.emailAddr2 : selectedContact?.emailAddr2
              }
              label={`${constants.EMAIL}2`}
              disabled={!isEditMode}
              InputLabelProps={
                (!isEditMode || !isEmpty(selectedContact?.emailAddr2)) && {
                  shrink: true,
                }
              }
              onChange={e => onValueChange(e.target.value, 'emailAddr2')}
            />
          </div>
          <div className={isEditMode && editableFieldClassName}>
            <TextField
              value={isEditMode ? editObj?.phoneExt : selectedContact?.phoneExt}
              label={constants.PHONE_EXT}
              disabled={!isEditMode}
              InputLabelProps={
                (!isEditMode || !isEmpty(selectedContact?.phoneExt)) && {
                  shrink: true,
                }
              }
              onChange={e => onValueChange(e.target.value, 'phoneExt')}
            />
          </div>
          <div className={isEditMode && editableFieldClassName}>
            <TextField
              value={isEditMode ? editObj?.faxExt : selectedContact?.faxExt}
              label={constants.FAX_EXT}
              disabled={!isEditMode}
              InputLabelProps={
                (!isEditMode || !isEmpty(selectedContact?.faxExt)) && {
                  shrink: true,
                }
              }
              onChange={e => onValueChange(e.target.value, 'faxExt')}
            />
          </div>
          <div className={isEditMode && editableFieldClassName}>
            <TextField
              value={isEditMode ? editObj?.state : selectedContact?.state}
              label={constants.STATE}
              disabled={!isEditMode}
              InputLabelProps={
                (!isEditMode || !isEmpty(selectedContact?.state)) && {
                  shrink: true,
                }
              }
              onChange={e => onValueChange(e.target.value, 'state')}
            />
          </div>
          <div className={isEditMode && editableFieldClassName}>
            <TextField
              value={isEditMode ? editObj?.country : selectedContact?.country}
              label={constants.COUNTRY}
              disabled={!isEditMode}
              InputLabelProps={
                (!isEditMode || !isEmpty(selectedContact?.country)) && {
                  shrink: true,
                }
              }
              onChange={e => onValueChange(e.target.value, 'country')}
            />
          </div>
          <div className={isEditMode && editableFieldClassName}>
            <TextField
              value={
                isEditMode && addBtnClicked
                  ? ''
                  : isEmpty(selectedContact?.updateDate)
                  ? ''
                  : moment.utc(selectedContact?.updateDate).format('M/D/YYYY')
              }
              label={constants.UPDATE_DATE}
              disabled
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        </StyledFormDivColumns>
      </div>
      <div className={isEditMode && editableFieldClassName}>
        <TextField
          disabled={!isEditMode}
          label={constants.COMMENT}
          value={isEditMode ? editObj?.comment : selectedContact?.comment}
          InputProps={{
            multiline: true,
            rows: 3,
          }}
          InputLabelProps={
            (!isEditMode || !isEmpty(selectedContact?.comment)) && {
              shrink: true,
            }
          }
          onChange={e => onValueChange(e.target.value, 'comment')}
          fullWidth
        />
      </div>
    </>
  );
}

ContactsFormTemplate.defaultProps = {
  isEditMode: false,
  selectedContact: null,
  editObj: null,
  onValueChange: () => {},
  contactNameError: false,
  emailAddrError: false,
  setContactNameError: () => {},
  setEmailAddrError: () => {},
  addBtnClicked: false,
};

ContactsFormTemplate.propTypes = {
  isEditMode: PropTypes.bool,
  selectedContact: PropTypes.shape({}),
  editObj: PropTypes.shape({}),
  onValueChange: PropTypes.func,
  contactNameError: PropTypes.bool,
  emailAddrError: PropTypes.bool,
  setContactNameError: PropTypes.func,
  setEmailAddrError: PropTypes.func,
  addBtnClicked: PropTypes.bool,
};

export default ContactsFormTemplate;
