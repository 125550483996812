import {constants} from '../../constants/common';
import {
  deleteAllInstallments,
  exportUserInvoices,
  fetchInvoiceInstallments,
  fetchUserInvoices,
  saveInstallments,
} from '../../services/invoice';
import {downloadExcel} from '../../utils/fileUtils';
import {actionTypes} from '../constants/actionTypes';

export const getInvoiceInstallmentList = options => dispatch => {
  dispatch({type: actionTypes.FETCHING_INVOICE_INSTALLMENTS});
  return fetchInvoiceInstallments(options)
    .then(res => {
      const {data} = res;
      dispatch({type: actionTypes.FETCHED_INVOICE_INSTALLMENTS, data});
    })
    .catch(() => {
      dispatch({type: actionTypes.FETCHING_INVOICE_INSTALLMENTS_FAILED});
    });
};

export const updateInvoiceInstallmentList = (options, params) => dispatch => {
  dispatch({type: actionTypes.ADDING_INVOICE_INSTALLMENTS});
  return saveInstallments(options, params)
    .then(res => {
      const {data} = res;
      dispatch({type: actionTypes.ADDED_INVOICE_INSTALLMENTS, data});
      dispatch(
        getInvoiceInstallmentList({
          groupKey: params.groupKey,
          invcKey: params.invcKey,
        }),
      );
    })
    .catch(() => {
      dispatch({type: actionTypes.ADDING_INVOICE_INSTALLMENTS_FAILED});
    });
};

export const deleteAllInvoiceInstallmentList = options => dispatch => {
  dispatch({type: actionTypes.DELETING_ALL_INVOICE_INSTALLMENTS});
  return deleteAllInstallments(options)
    .then(res => {
      const {data} = res;
      dispatch({type: actionTypes.DELETED_ALL_INVOICE_INSTALLMENTS, data});
    })
    .catch(() => {
      dispatch({type: actionTypes.DELETE_ALL_INVOICE_INSTALLMENTS_FAILED});
    });
};

export const getUserInvoices = options => (dispatch, getState) => {
  const userInvoicesViewSettings =
    getState().invoiceReducer?.userInvoices?.userInvoicesViewSettings;
  const toast = getState().generalReducer?.toastRef;

  const filterQueryVal =
    options.settingKey === userInvoicesViewSettings?.settingsKey
      ? options.filterQuery
      : '';
  const updatedOptions = {...options, globalFilterQuery: filterQueryVal};
  dispatch({type: actionTypes.FETCHING_USER_INVOICES});
  return fetchUserInvoices(updatedOptions)
    .then(response => {
      dispatch({
        type: actionTypes.FETCHED_USER_INVOICES,
        userInvoicesData: response.data.value,
        userInvoicesCount: response.data.count,
        userInvoicesTotalAmountHC: response.data.totalAmountHC,
        userInvoicesTotalBalanceHC: response.data.totalBalanceHC,
      });
    })
    .catch(() => {
      dispatch({type: actionTypes.FETCHING_USER_INVOICES_FAILED});
      toast?.current.show({
        severity: 'warn',
        summary: constants.FAILED_TO_LOAD_DATA,
        detail: constants.LOADING_INVOICES_FAILED,
      });
    });
};

export const updateUserInvoicesSkip = userInvoicesSkip => {
  return {
    type: actionTypes.UPDATE_USER_INVOICES_SKIP,
    userInvoicesSkip,
  };
};

export const updateUserInvoicesPageSize = userInvoicesPageSize => dispatch => {
  dispatch({type: actionTypes.FETCHING_USER_INVOICES});
  dispatch({
    type: actionTypes.UPDATE_USER_INVOICES_PAGE_SIZE,
    userInvoicesPageSize,
  });
};

export const updateUserInvoicesViewSettingObject = ({
  settingId,
  viewSettings,
}) => {
  return {
    type: actionTypes.SET_USER_INVOICES_VIEW_SETTING_OBJECT,
    payload: {settingId, viewSettings},
  };
};

export const userInvoicesExportToExcel = options => (dispatch, getState) => {
  const userInvoicesViewSettings =
    getState().invoiceReducer.userInvoices?.userInvoicesViewSettings;
  const toast = getState().generalReducer?.toastRef;
  const filterQueryVal =
    options.settingKey === userInvoicesViewSettings?.settingsKey
      ? options.filterQuery
      : '';
  const updatedOptions = {...options, globalFilterQuery: filterQueryVal};
  dispatch({type: actionTypes.EXPORTING_TO_EXCEL_USER_INVOICES});
  toast?.current.show({
    severity: 'info',
    summary: constants.EXPORTING,
  });
  exportUserInvoices(updatedOptions)
    .then(res => {
      downloadExcel(
        res.data,
        constants.INVOICES,
        toast,
        constants.INVOICES_EXPORTED_TO_EXCEL,
      );
      toast?.current.show({
        severity: 'success',
        summary: constants.EXPORTED_SUCCESSFULLY,
      });
      dispatch({type: actionTypes.EXPORTED_TO_EXCEL_USER_INVOICES});
    })
    .catch(() => {
      dispatch({type: actionTypes.EXPORTING_TO_EXCEL_USER_INVOICES_FAILED});
      toast?.current.show({
        severity: 'warn',
        summary: constants.FAILED_TO_EXPORT,
      });
    });
};

export const cleanupInvoiceData = () => {
  return {
    type: actionTypes.CLEANUP_USER_INVOICE_DATA,
  };
};
