import {ActivityByUserList, ActivityDetailList} from './summaryTypes';

/**
 * This function generates a list of activities by user with added links.
 * The link is determined based on the alertCode of each activity.
 *
 * @param {ActivityByUserList} activityByUser - The list of activities by user.
 * @returns {ActivityByUserList} - The list of activities by user with added links.
 */
export const generateActivityByUserLinks = (
  activityByUser: ActivityByUserList,
  assignedUserKey: Number,
) => {
  // Iterate over each activity in the list
  return activityByUser.map((activity, index: number) => {
    const {alertCode, userKey, alertDesc} = activity;
    let link = '';
    // Determine the link based on the alertCode
    switch (alertCode) {
      case 'PastDueFollowupAlert':
        link = `/inquiries/by-user/activity-summary?AlertCode=${alertCode}&AssignedUserKey=${assignedUserKey}&SummaryUserKey=${userKey}&MaxToDoDate=${
          new Date().toISOString().split('T')[0]
        }&SettingID=~All`;
        break;
      case 'FollowupDueAlert': {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        link = `/inquiries/by-user/activity-summary?AlertCode=${alertCode}&AssignedUserKey=${assignedUserKey}&SummaryUserKey=${userKey}&MaxToDoDate=${
          tomorrow.toISOString().split('T')[0]
        }&MinToDoDate=${new Date().toISOString().split('T')[0]}&SettingID=~All`;
        break;
      }
      case 'UnfiledMailAlert':
        link = `/inquiries/by-user/activity-summary?AlertCode=${alertCode}&UserKey=0&Status=3`;
        break;
      case 'BrokenPromise':
      case 'PendPmtNotReconciled':
      case 'PendPmtDeclined':
      case 'PendPmtNoResponse':
        link = `/inquiries/by-user/activity-summary?AlertCode=${alertCode}&Mode=${alertCode}&AssignedUserKey=${assignedUserKey}&SummaryUserKey=${userKey}&AlertDesc=${alertDesc}`;
        break;
      default:
        link = `/inquiries/by-user/activity-summary?AlertCode=${alertCode}&Mode=${alertCode}&AssignedUserKey=${assignedUserKey}&SummaryUserKey=${userKey}&AlertDesc=${alertDesc}&SettingID=~All`;
        break;
    }
    // Return the activity with the added link and a unique key
    return {
      ...activity,
      link,
      uniqueKey: index,
    };
  });
};

/**
 * This function generates a list of activity details with added links.
 * The link is determined based on the ruleKey, userKey, and issue of each activity.
 *
 * @param {ActivityDetailList} activityDetailList - The list of activity details.
 * @returns {ActivityDetailList} - The list of activity details with added links.
 */
export const generateActivityDetailLinks = (
  activityDetailList: ActivityDetailList,
) => {
  // Iterate over each activity in the list
  return activityDetailList.map((activity, index: number) => {
    const {userKey, issue, ruleKey} = activity;
    // Construct the link using the destructured properties
    const link = `?AlertCode=Communication&Mode=RuleAlert&RuleKey=${ruleKey}&AssignedUserKey=${userKey}&AlertDesc=${issue}`;

    // Return the activity with the added link and a unique key
    return {
      ...activity,
      link,
      uniqueKey: index,
    };
  });
};
