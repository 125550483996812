import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {useEffect} from 'react';
import {getUserData} from '../../store/actions/userActions';

const FavoriteAccountsHandler = () => {
  const navigate = useNavigate();
  const selectedCustomer = useSelector(
    (store: any) => store.generalReducer.selectedCustomer,
  );
  const {userKey} = getUserData();

  const handleAccountOverviewNavigation = (customer: any) => {
    const queryString = `/account-overview?CustKey=${customer.custKey}&AssignedUserKey=${userKey}&SettingID=~All&OEM=LS`;
    navigate(queryString);
  };

  const handleAllAccountsNavigation = () => {
    const queryString = `/accounts/all-accounts`;
    navigate(queryString);
  };

  useEffect(() => {
    if (selectedCustomer === null) {
      handleAllAccountsNavigation();
    } else {
      handleAccountOverviewNavigation(selectedCustomer);
    }
  }, []);
};
export default FavoriteAccountsHandler;
