import {useEffect, useState} from 'react';

const createStorageChangeEvent = () => new Event('storageChange');

export const useLocalStorage = key => {
  const [storedValue, setStoredValue] = useState(() => {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  });

  useEffect(() => {
    const handleStorageChange = () => {
      setStoredValue(() => {
        const item = localStorage.getItem(key);
        return item ? JSON.parse(item) : null;
      });
    };

    window.addEventListener('storageChange', handleStorageChange);

    return () => {
      window.removeEventListener('storageChange', handleStorageChange);
    };
  }, [key]);

  const setLocalStorage = value => {
    localStorage.setItem(key, JSON.stringify(value));
    window.dispatchEvent(createStorageChangeEvent());
  };

  return [storedValue, setLocalStorage];
};
