import {combineReducers} from 'redux';
import accountNotesReducer from './accountNotesReducer';
import activitiesOverviewReducer from './activitiesOverviewReducer';
import activitiesReducer from './activitiesReducer';
import activityDetailReducer from './activityDetailReducer';
import applicationDetailReducer from './applicationDetailReducer';
import communicationsReducer from './communicationsReducer';
import emailReducer from './emailReducer';
import generalReducer from './generalReducer';
import invoiceLinesReducer from './invoiceLinesReducer';
import mailboxReducer from './mailboxReducer';
import statementOfAccountReducer from './statementOfAccountReducer';
import treeReducer from './treeReducer';
import usersReducer from './usersReducer';
import fileReducer from './fileReducer';
import invoiceReducer from './invoiceReducer';
import paymentsCurrenciesReducer from './paymentsCurrenciesReducer';
import customerInfoReducer from './customerInfoReducer';
import allAccountsReducer from './allAccountsReducer';
import userPaymentsReducer from './userPaymentsReducers';
import scheduledPaymentsReducer from './scheduledPaymentsReducer';
import pendingPaymentSummaryReducer from './pendingPaymentSummaryReducer';
import pendingPaymentDetailsReducer from './pendingPaymentDetailsReducer';
import expectedPaymentsReducer from './expectedPaymentsReducer';
import followUpReducer from './followUpReducer';
import homeReducer from './homeReducers';
import reportingGroupLogsReducer from './reportingGroupLogsReducer';
import projectedCashReceiptsReducer from './projectedCashReceiptsReducer';
import draftReducer from './draftReducer';
import contactsReducer from './contactsReducer';
import viewSettingsReducer from './viewSettingsReducer';

const rootReducer = combineReducers({
  generalReducer,
  mailboxReducer,
  communicationsReducer,
  activitiesReducer,
  usersReducer,
  treeReducer,
  statementOfAccountReducer,
  activitiesOverviewReducer,
  accountNotesReducer,
  invoiceLinesReducer,
  applicationDetailReducer,
  activityDetailReducer,
  emailReducer,
  fileReducer,
  invoiceReducer,
  paymentsCurrenciesReducer,
  customerInfoReducer,
  allAccountsReducer,
  userPaymentsReducer,
  scheduledPaymentsReducer,
  pendingPaymentSummaryReducer,
  pendingPaymentDetailsReducer,
  expectedPaymentsReducer,
  followUpReducer,
  homeReducer,
  reportingGroupLogsReducer,
  projectedCashReceiptsReducer,
  draftReducer,
  contactsReducer,
  viewSettingsReducer,
});

export default rootReducer;
