import {styled} from '@mui/material';
import {theme} from '../../../ui/theme/theme';

export const ColoredSpan = styled('span')<{color: string}>`
  background: ${props => props.color};
  padding: 4px 8px;
  color: ${theme.palette.common.white};
  font-size: 11px;
  font-weight: 600;
`;
