import {constants} from '../../../constants/common';
import {Dialog} from '../../../ui';
import RenderViewSettings from './RenderViewSettings';
import {ColumnSetting, IViewSettings} from './interface';

function ViewSettingsModal({
  showViewSettingsPopup,
  handleCloseViewSettingsPopup,
  viewName,
  mode,
  customFilterSqlValue,
  sortSettingJson,
  columnSettingJson,
  updateTableOnColumnSettingsChange,
  loadViewSetting,
}: {
  viewName: string;
  mode: string;
  updateTableOnColumnSettingsChange: (
    updatedColumns: ColumnSetting[],
    settingKey: string,
  ) => void;
  showViewSettingsPopup: boolean;
  handleCloseViewSettingsPopup: () => void;
  customFilterSqlValue: string;
  sortSettingJson: string;
  columnSettingJson: string;
  loadViewSetting: (row: IViewSettings) => void;
}) {
  return (
    <Dialog
      open={showViewSettingsPopup}
      onClose={handleCloseViewSettingsPopup}
      title={constants.VIEW_SETTINGS}
      maxWidth="lg"
      fullWidth
      draggable
    >
      <RenderViewSettings
        viewName={viewName}
        loadViewSetting={loadViewSetting}
        handleCloseViewSettingsPopup={handleCloseViewSettingsPopup}
        filterSqlText={customFilterSqlValue}
        sortSettingJson={sortSettingJson}
        columnSettingJson={columnSettingJson}
        mode={mode}
        updateTableOnColumnSettingsChange={updateTableOnColumnSettingsChange}
      />
    </Dialog>
  );
}

export default ViewSettingsModal;
