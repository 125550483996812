import {useDispatch, useSelector} from 'react-redux';
import {ViewSettingsDropDown} from '../../../../common/view-settings/view-settings-dropdown/ViewSettingsDropDown';
import {
  updatePaymentDetailsDashboardViewSettingObject,
  updatePendingPaymentDetailsIsRestricted,
} from '../../../../../store/actions/pendingPaymentDetailsActions';
import {CheckboxLabel} from '../../../../../ui/inputs';
import {Box} from '../../../../../ui/layouts';

export const ViewSettings = ({mode}: {mode: string}) => {
  const dispatch = useDispatch();

  const {
    pendingPaymentDetailsSettingId,
    alertDescription,
    isRestricted,
    pendingPaymentDetailsViewSettings,
  } = useSelector((store: any) => store.pendingPaymentDetailsReducer);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      marginY="12px"
    >
      {alertDescription != null && (
        <CheckboxLabel
          label={`Restricted to Alert ${alertDescription}`}
          checked={isRestricted}
          onChange={e => {
            dispatch(updatePendingPaymentDetailsIsRestricted(e.target.checked));
          }}
        />
      )}
      <Box width="250px">
        <ViewSettingsDropDown
          settingId={pendingPaymentDetailsSettingId}
          viewName="PendingPaymentsDetails"
          isQueryParamDefault
          onViewSettingSelected={(selectedSettingId, viewSettings) =>
            dispatch(
              updatePaymentDetailsDashboardViewSettingObject({
                settingId: selectedSettingId,
                viewSettings,
              }),
            )
          }
          activityType={mode}
          currentViewSettings={pendingPaymentDetailsViewSettings}
        />
      </Box>
    </Box>
  );
};
