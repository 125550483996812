import {useEffect, useState} from 'react';
import {constants, gridModeTypes} from '../../../../constants/common';
import {
  userAlertSummary,
  userStandardAlert,
} from '../../../../services/activitySummary';
import {DataGrid} from '../../../../ui/data';
import {Typography} from '../../../../ui/displays';
import {Box, Stack} from '../../../../ui/layouts';
import {getUserData} from '../../../../store/actions/userActions';
import {useQueryKeys} from '../../../../hooks/useQueryKeys';
import {activityDetailColumns, byUsersColumns} from './summaryColumns';
import {SummaryToolbar} from './SummaryToolbar';
import {getViewSettingList} from '../../../../services/viewSettings';
import {useSessionStorage} from '../../../../hooks/useSessionStorage';
import {ActivityByUserList, ActivityDetailList} from './summaryTypes';
import {
  generateActivityByUserLinks,
  generateActivityDetailLinks,
} from './activitySummaryHelper';

export const ActivitySummary = () => {
  const {groupKey} = getUserData();
  const {userKey} = useQueryKeys();
  const [activityByUser, setActivityByUser] = useState<ActivityByUserList>([]);
  const [activityDetails, setActivityDetails] = useState<ActivityDetailList>(
    [],
  );
  const [isLoading, setIsLoading] = useState(false);
  const [{userId}] = useSessionStorage('profile');

  const getDefaultViewSettings = async (
    viewName:
      | 'AlertSummary/RuleSummary'
      | 'AlertSummary/RuleSummary/UserActions',
    mode: string,
  ) => {
    try {
      const res = await getViewSettingList({
        GroupKey: groupKey,
        UserKey: userId,
        SettingId: '~Standard',
        ViewName: viewName,
        ViewGroup: '00,',
        Culture: 'en-US',
        Mode: mode,
      });
      const settings = res.data;
      let defaultSetting = null;
      if (settings && settings.length > 0) {
        defaultSetting = settings.find(
          (setting: {isDefault: boolean}) => setting.isDefault,
        );
      }
      return defaultSetting;
    } catch (error) {
      return null;
    }
  };

  const getActivitySummaryData = async (
    _userKey: number,
    _groupKey: string,
  ) => {
    setIsLoading(true);
    try {
      const alertSummaryViewSetting = await getDefaultViewSettings(
        'AlertSummary/RuleSummary',
        gridModeTypes.ActivityUserAlertSummary,
      );
      const userActionsViewSetting = await getDefaultViewSettings(
        'AlertSummary/RuleSummary/UserActions',
        gridModeTypes.ActivityUserStandardAlert,
      );

      if (userActionsViewSetting?.settingsKey) {
        const standardAlertDetails = await userStandardAlert(
          {
            userKey: _userKey,
            settingKey: userActionsViewSetting.settingsKey,
            mode: gridModeTypes.ActivityUserStandardAlert,
          },
          _groupKey,
        );
        const activityByUsersWithLinks = generateActivityByUserLinks(
          standardAlertDetails.data?.value,
          userKey,
        );
        setActivityByUser(activityByUsersWithLinks);
      }

      if (alertSummaryViewSetting?.settingsKey) {
        const alertSummaryDetails = await userAlertSummary(
          {
            userKey: _userKey,
            settingKey: alertSummaryViewSetting.settingsKey,
            mode: gridModeTypes.ActivityUserAlertSummary,
          },
          _groupKey,
        );
        const activityDetailsWithLinks = generateActivityDetailLinks(
          alertSummaryDetails.data?.value,
        );
        setActivityDetails(activityDetailsWithLinks);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getActivitySummaryData(userKey, groupKey);
  }, []);

  useEffect(() => {
    getActivitySummaryData(userKey, groupKey);
  }, [userKey]);

  return (
    <Stack direction="column">
      <Box marginTop="12px">
        <Typography variant="h5" color="gray">
          {constants.ACTIVITIES_BY_USERS.toLocaleUpperCase()}
        </Typography>
        <DataGrid
          columns={byUsersColumns}
          rows={activityByUser}
          paginationMode="client"
          sortingMode="client"
          filterMode="client"
          pageSize={10}
          loading={isLoading}
          getRowId={row => row.uniqueKey}
          CustomToolbar={SummaryToolbar}
        />
      </Box>
      <Box marginTop={3}>
        <Typography variant="h5" color="gray">
          {constants.ACTIVITY_DETAIL.toLocaleUpperCase()}
        </Typography>
        <DataGrid
          columns={activityDetailColumns}
          rows={activityDetails}
          paginationMode="client"
          sortingMode="client"
          filterMode="client"
          pageSize={10}
          loading={isLoading}
          getRowId={row => row.uniqueKey}
          CustomToolbar={SummaryToolbar}
        />
      </Box>
    </Stack>
  );
};
