import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {constants} from '../../../constants/common';
import {isEmpty} from '../../../lib/utils';
import {
  fetchInfo,
  fetchInvoiceForm,
  saveInfo,
  updateInfoEditMode,
} from '../../../store/actions/customerInfoActions';
import {getUserData} from '../../../store/actions/userActions';
import Select from '../../../ui/inputs/select/Select';
import TextField from '../../../ui/inputs/textfield/TextField';
import Backdrop from '../../../ui/utils/backdrop/Backdrop';
import {getUDFInfoList} from '../../../utils/udfList';
import {StyledFormDivColumns} from '../helper';
import {editableFieldClassName} from './classNameConstants';
import EditSaveAndCancelTemplate from './EditSaveAndCancelTemplate';
import {RenderUDFListTemplate} from './RenderUDFListTemplate';

function Info() {
  const {isEditMode, info, invoiceForm, fetchingInfo} = useSelector(
    store => store.customerInfoReducer.info,
  );

  const groupKey = getUserData()?.groupKey;
  const dispatch = useDispatch();
  const selectedCustomer = useSelector(
    store => store.generalReducer?.selectedCustomer,
  );

  const udfDetails = useSelector(store => store.usersReducer.udfDetails);

  const [selectedInvoiceForm, setSelectedInvoiceForm] = useState(null);
  const [UDFFields, setUDFFields] = useState(null);
  const [unchangedUDFFields, setUnchangedUDFFields] = useState(null);
  const [daysToRespond, setDaysToRespond] = useState(null);

  const addressLine2 = isEmpty(info?.AddrLine2)
    ? `${info?.City ? info?.City : ''} ${info?.State ? info?.State : ''} ${
        info?.PostalCode ? info?.PostalCode : ''
      }`
    : info?.AddrLine2;
  const addressLine1 = isEmpty(info?.AddrLine1) ? '' : info?.AddrLine1;

  const onEditClick = (val = true) => {
    dispatch(updateInfoEditMode(val));
  };

  const handleSaveInfo = () => {
    const payload = {
      daysToRespond,
      invcReportKey:
        selectedInvoiceForm === constants.NONE ? null : selectedInvoiceForm,
      UDF1: UDFFields.udf1 ? UDFFields.udf1.value : '',
      UDF2: UDFFields.udf2 ? UDFFields.udf2.value : '',
      UDF3: UDFFields.udf3 ? UDFFields.udf3.value : '',
      UDF4: UDFFields.udf4 ? UDFFields.udf4.value : '',
      UDF5: UDFFields.udf5 ? UDFFields.udf5.value : '',
      UDF6: UDFFields.udf6 ? UDFFields.udf6.value : '',
      UDF7: UDFFields.udf7 ? UDFFields.udf7.value : '',
      UDF8: UDFFields.udf8 ? UDFFields.udf8.value : '',
      UDF9: UDFFields.udf9 ? UDFFields.udf9.value : '',
      UDF10: UDFFields.udf10 ? UDFFields.udf10.value : '',
    };
    dispatch(updateInfoEditMode(false));
    dispatch(saveInfo(selectedCustomer?.custKey, payload));
  };

  useEffect(() => {
    dispatch(fetchInvoiceForm(groupKey));
  }, []);

  useEffect(() => {
    dispatch(fetchInfo(selectedCustomer?.custKey));
  }, [selectedCustomer]);

  useEffect(() => {
    const resultData = getUDFInfoList(udfDetails, info);
    setUnchangedUDFFields(resultData);
    setUDFFields(resultData);
    setDaysToRespond(info?.DaysToRespond);
  }, [info]);

  const onUDFTextFieldsChange = (key, value) => {
    const tempObject = {...UDFFields};
    tempObject[key].value = value;
    setUDFFields(tempObject);
  };

  return (
    <div className="customer-info-item-tab-content-wrapper position-relative p-3">
      <Backdrop open={fetchingInfo} />
      <StyledFormDivColumns>
        <div>
          <TextField
            value={addressLine1}
            label={constants.addressLine(1)}
            disabled
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div>
          <TextField
            value={isEmpty(addressLine2) ? '' : addressLine2}
            label={constants.addressLine(2)}
            disabled
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div>
          <TextField
            value={info?.SperName}
            label={constants.SALES_PERSON}
            disabled
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div>
          <TextField
            value={info?.CompanyID}
            label={constants.COMPANY_ID}
            disabled
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div>
          <TextField
            value={info?.SalesTerritory}
            label={constants.SALES_TERRITORY}
            disabled
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div>
          <TextField
            value={info?.AssignedUserID}
            label={constants.CREDIT_REP}
            disabled
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      </StyledFormDivColumns>
      <StyledFormDivColumns>
        <div className={isEditMode ? `p-1 ${editableFieldClassName}` : ''}>
          <Select
            label={constants.SELECT_AN_INVOICE_FORM}
            value={
              isEditMode
                ? selectedInvoiceForm
                : info?.InvcReportKey || constants.NONE
            }
            shrinkLabel={isEditMode ? undefined : true}
            notched={isEditMode ? undefined : true}
            disabled={!isEditMode}
            items={invoiceForm}
            onChange={e => setSelectedInvoiceForm(e.target.value)}
          />
        </div>

        <div className={isEditMode ? `p-1 ${editableFieldClassName}` : ''}>
          <TextField
            type="number"
            value={isEditMode ? daysToRespond : info?.DaysToRespond}
            disabled={!isEditMode}
            label={constants.DAYS_TO_RESPOND}
            onChange={e => {
              const inputValue = e.target.value;
              setDaysToRespond(inputValue);
            }}
            InputLabelProps={{
              shrink: !isEditMode || (isEditMode && !isEmpty(daysToRespond)),
            }}
          />
        </div>
        <div>
          <TextField
            value={info?.ParentID}
            label={constants.PARENT_ID}
            disabled
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>

        <div>
          <TextField
            value={info?.CustStatus}
            label={constants.STATUS}
            disabled
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div>
          <TextField
            value={info?.CustClassID}
            label={constants.CUSTOMER_CLASS}
            disabled
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      </StyledFormDivColumns>
      <div>
        <div className="d-flex align-items-center">
          <h3 className="my-4 ms-3 bold">{constants.USER_DEFINED_FIELDS}</h3>
        </div>
        <RenderUDFListTemplate
          UDFFields={isEditMode ? UDFFields : unchangedUDFFields}
          isEditMode={isEditMode}
          obj={info}
          unchangedUDFFields={unchangedUDFFields}
          onUDFTextFieldsChange={isEditMode && onUDFTextFieldsChange}
        />
      </div>

      <div>
        <EditSaveAndCancelTemplate
          isEditMode={isEditMode}
          onEdit={onEditClick}
          onSave={handleSaveInfo}
          onCancel={() => {
            onEditClick(false);
          }}
        />
      </div>
    </div>
  );
}

export default Info;
