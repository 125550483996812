import data from '../lib/data';

export const userStandardAlert = (payload, groupKey) => {
  const endpoint = `v4/api/activity/userstandardalert/for/${groupKey}`;
  return data.post(endpoint, payload);
};

export const userAlertSummary = (payload, groupKey) => {
  const endpoint = `v4/api/activity/useralertsummary/for/${groupKey}`;
  return data.post(endpoint, payload);
};
