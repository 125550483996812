import PropTypes from 'prop-types';
import {constants} from '../../../constants/common';
import {Button} from '../../../ui/inputs';

function EditSaveAndCancelTemplate({
  isEditMode,
  onEdit,
  onSave,
  onCancel,
  size,
}) {
  return (
    <div className="tab-content-fields-group">
      {isEditMode ? (
        <div className="d-flex">
          <Button
            size={size}
            className="m-1"
            variant="contained"
            onClick={onSave}
          >
            {constants.SAVE}
          </Button>
          <Button
            className="m-1"
            size={size}
            variant="contained"
            onClick={onCancel}
          >
            {constants.CANCEL}
          </Button>
        </div>
      ) : (
        <div className="d-flex">
          <Button
            className="m-1"
            size={size}
            variant="contained"
            onClick={() => {
              onEdit(true);
            }}
          >
            {constants.EDIT}
          </Button>
        </div>
      )}
    </div>
  );
}

EditSaveAndCancelTemplate.defaultProps = {
  isEditMode: false,
  onEdit: () => {},
  onSave: () => {},
  onCancel: () => {},
  size: 'medium',
};

EditSaveAndCancelTemplate.propTypes = {
  isEditMode: PropTypes.bool,
  onEdit: PropTypes.func,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  size: PropTypes.string,
};
export default EditSaveAndCancelTemplate;
