import {Badge as MuiBadge, BadgeProps} from '@mui/material';

interface Props extends BadgeProps {
  /**
   * Bade count
   */
  badgeContent: number | string;
  /**
   * Max allowed
   */
  max?: number;
}

/**
 * Badge component to show badges.
 * More information available here https://mui.com/material-ui/react-badge
 */

const Badge: React.FC<Props> = ({badgeContent, max, ...otherProps}) => {
  return <MuiBadge badgeContent={badgeContent} max={max} {...otherProps} />;
};

export default Badge;
