import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import dayjs from 'dayjs';
import {constants} from '../../../constants/common';
import {
  fetchCustomerPaymentDetails,
  updateKeepInSync,
  updatePaymentDetails,
} from '../../../services/activities';
import {getPaymentUDFList} from '../../../utils/udfList';
import {TRAN_TYPE} from '../../../utils/constants';
import {isEmpty} from '../../../lib/utils';
import Backdrop from '../../common/Backdrop';
import {Button, Checkbox, TextField} from '../../../ui/inputs';
import {Typography} from '../../../ui/displays';
import {Box} from '../../../ui/layouts';
import {IUDFs} from '../../account-overview/interface';
import {IPaymentDetailTemplateProps, IPaymentDetails} from './interface';
import {getUdfValue} from '../helper';

function PaymentDetailTemplate({
  custPmtKey,
  tranType,
}: IPaymentDetailTemplateProps) {
  const [isEditingPaymentUdf, setIsEditingPaymentUdf] = useState(false);
  const [paymentUDFs, setPaymentUDFs] = useState<IUDFs | null>(null);
  const [paymentDetails, setPaymentDetails] = useState<IPaymentDetails | null>(
    null,
  );
  const toast = useSelector((store: any) => store.generalReducer?.toastRef);
  const {options} = useSelector(
    (store: any) => store.usersReducer.userSettings,
  );
  const [loadingCommunicate, setLoadingCommunicate] = useState(false);

  const savePaymentDetail = () => {
    const request = {
      UnappliedAmt: paymentDetails?.unappliedAmt,
      UnappliedAmtHC: paymentDetails?.unappliedAmtHC,
      KeepInSync: paymentDetails?.keepInSync,
      UDF1: getUdfValue(paymentUDFs, 'udf1'),
      UDF2: getUdfValue(paymentUDFs, 'udf2'),
      UDF3: getUdfValue(paymentUDFs, 'udf3'),
      UDF4: getUdfValue(paymentUDFs, 'udf4'),
      UDF5: getUdfValue(paymentUDFs, 'udf5'),
      UDF6: getUdfValue(paymentUDFs, 'udf6'),
      UDF7: getUdfValue(paymentUDFs, 'udf7'),
      UDF8: getUdfValue(paymentUDFs, 'udf8'),
      UDF9: getUdfValue(paymentUDFs, 'udf9'),
      UDF10: getUdfValue(paymentUDFs, 'udf10'),
    };
    setIsEditingPaymentUdf(false);
    setLoadingCommunicate(true);
    updatePaymentDetails(custPmtKey, request)
      .then(() => {
        setLoadingCommunicate(false);
      })
      .catch(() => {
        setLoadingCommunicate(false);
        toast?.current.show({
          severity: 'warn',
          summary: constants.SOMETHING_WENT_WRONG,
          detail: '',
        });
      });
  };

  useEffect(() => {
    setLoadingCommunicate(true);
    fetchCustomerPaymentDetails(custPmtKey)
      .then(response => {
        const resultObj = response.data;
        if (resultObj) {
          resultObj.unappliedAmt = resultObj.unappliedAmt
            ? resultObj.unappliedAmt?.toFixed(2)
            : resultObj.unappliedAmt;
          resultObj.unappliedAmtHC = resultObj.unappliedAmtHC
            ? resultObj.unappliedAmtHC?.toFixed(2)
            : resultObj.unappliedAmtHC;
          setLoadingCommunicate(false);
        }
        setPaymentDetails(resultObj);
        const udfObject = getPaymentUDFList(options, resultObj);
        setPaymentUDFs(udfObject);
      })
      .catch(() => {
        setLoadingCommunicate(true);
        toast?.current.show({
          severity: 'warn',
          summary: constants.SOMETHING_WENT_WRONG,
          detail: '',
        });
      });
  }, []);

  const onHandleChange = (value: any, id: string) => {
    setPaymentDetails({...paymentDetails, [id]: value});
  };

  const onKeepInSyncChange = (value: any, id: string) => {
    setPaymentDetails({...paymentDetails, [id]: value});
    setLoadingCommunicate(true);
    updateKeepInSync(custPmtKey, value)
      .then(() => {
        setLoadingCommunicate(false);
      })
      .catch(() => {
        setLoadingCommunicate(false);
        toast?.current.show({
          severity: 'warn',
          summary: constants.SOMETHING_WENT_WRONG,
          detail: '',
        });
      });
  };

  const onUDfChange = (key: string, value: any) => {
    const tempObject = {...paymentUDFs};
    tempObject[key].value = value;
    setPaymentUDFs(tempObject);
  };

  const onCancelUDF = () => {
    setIsEditingPaymentUdf(false);
    setPaymentUDFs(getPaymentUDFList(options, paymentDetails));
  };

  return (
    <div className="my-3 p-2">
      {loadingCommunicate && <Backdrop />}
      <Box padding={2} sx={{background: '#fafbfc', border: '1px solid gray'}}>
        <Box
          margin={2}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="subtitle1" fontWeight={800} marginBottom={2}>
            {constants.PAYMENT_INFORMATION.toLocaleUpperCase()}
          </Typography>
          <Typography variant="body1">
            <Checkbox
              checked={paymentDetails?.keepInSync}
              className="me-3"
              onChange={e => onKeepInSyncChange(e.target.checked, 'keepInSync')}
            />
            {constants.DO_NOT_SYNC}
          </Typography>
          <Button
            variant="contained"
            disabled={!paymentDetails?.keepInSync}
            onClick={savePaymentDetail}
          >
            {constants.SAVE}
          </Button>
        </Box>
        <div className="d-flex justify-content-between flex-wrap">
          <Box
            sx={{
              width: '300px',
            }}
            margin={2}
          >
            <TextField
              id="tranID"
              disabled
              type="number"
              label={constants.PAYMENT}
              value={paymentDetails?.tranID}
              InputLabelProps={{shrink: true}}
              margin="normal"
            />
            <Typography
              marginTop={2}
              marginBottom={1}
              fontWeight={700}
              variant="subtitle1"
            >
              {constants.CHECK_REFERENCE}
            </Typography>
            <TextField
              disabled
              id="tranAmt"
              label={constants.AMOUNT}
              value={paymentDetails?.tranAmt?.toFixed(2)}
              InputLabelProps={{shrink: true}}
              margin="normal"
            />
            <TextField
              disabled
              id="tranAmtHC"
              label={constants.AMOUNT_HC}
              value={paymentDetails?.tranAmtHC?.toFixed(2)}
              InputLabelProps={{shrink: true}}
              margin="normal"
            />
          </Box>
          <Box
            sx={{
              width: '300px',
            }}
            margin={2}
          >
            <TextField
              disabled
              id="tranDate"
              label={constants.PAYMENT_DATE}
              value={
                paymentDetails?.tranDate
                  ? dayjs(paymentDetails?.tranDate)
                      .utc()
                      .format(constants.DATE_FORMAT_1)
                  : ''
              }
              InputLabelProps={{shrink: true}}
              margin="normal"
            />
            <TextField
              disabled
              id="tenderTypeID"
              label={constants.TENDER_TYPE}
              value={paymentDetails?.tenderTypeID}
              InputLabelProps={{shrink: true}}
              margin="normal"
            />

            <TextField
              disabled={!paymentDetails?.keepInSync}
              id="unappliedAmt"
              label={constants.UNAPPLIED_AMOUNT}
              value={
                paymentDetails?.unappliedAmt
                  ? `${paymentDetails?.unappliedAmt}`
                  : ''
              }
              onChange={e =>
                onHandleChange(Number(e.target.value), 'unappliedAmt')
              }
              InputLabelProps={{
                shrink: paymentDetails?.keepInSync ? undefined : true,
              }}
              margin="normal"
            />

            <TextField
              disabled={!paymentDetails?.keepInSync}
              id="unappliedAmtHC"
              label={constants.UNAPPLIED_AMOUNT_HC}
              value={
                paymentDetails?.unappliedAmtHC
                  ? `${paymentDetails?.unappliedAmtHC}`
                  : ''
              }
              onChange={e =>
                onHandleChange(Number(e.target.value), 'unappliedAmtHC')
              }
              InputLabelProps={{
                shrink: paymentDetails?.keepInSync ? undefined : true,
              }}
              margin="normal"
            />
          </Box>
          <Box sx={{width: '300px'}} margin={2}>
            <TextField
              disabled
              label={constants.STATUS}
              id="status"
              value={
                paymentDetails?.unappliedAmt === 0
                  ? constants.CLOSED
                  : constants.OPEN
              }
              InputLabelProps={{shrink: true}}
              margin="normal"
            />
          </Box>
        </div>
        {!isEmpty(paymentUDFs) && (
          <Box sx={{margin: 2, border: '1px solid', width: '70%', padding: 2}}>
            <Typography marginBottom={2} variant="subtitle1" fontWeight={800}>
              {constants.PAYMENT_UDF}
            </Typography>
            {paymentUDFs &&
              Object.keys(paymentUDFs).map(key => {
                return (
                  <div
                    key={paymentUDFs[key].label}
                    className="py-2 d-flex align-items-center justify-content-between"
                  >
                    <Typography variant="body1">
                      {paymentUDFs[key].label}:
                    </Typography>
                    {isEditingPaymentUdf && paymentUDFs[key].editable ? (
                      <div className="w-50">
                        <TextField
                          variant="outlined"
                          value={paymentUDFs[key].value}
                          onChange={e => onUDfChange(key, e.target.value)}
                          margin="normal"
                        />
                      </div>
                    ) : (
                      <Typography
                        title={paymentUDFs[key].value}
                        variant="body1"
                        padding={1}
                        width="50%"
                      >
                        {paymentUDFs[key].value}
                      </Typography>
                    )}
                  </div>
                );
              })}
            {tranType?.toLocaleLowerCase() !==
              TRAN_TYPE.RV.toLocaleLowerCase() &&
              paymentDetails?.unappliedAmt !== 0 && (
                <Box sx={{marginY: 2}}>
                  {!isEditingPaymentUdf ? (
                    <Button
                      variant="contained"
                      className="mt-2"
                      onClick={() => setIsEditingPaymentUdf(true)}
                    >
                      {constants.EDIT}
                    </Button>
                  ) : (
                    <div>
                      <Button
                        variant="contained"
                        className="me-2"
                        onClick={savePaymentDetail}
                      >
                        {constants.SAVE}
                      </Button>
                      <Button variant="contained" onClick={() => onCancelUDF()}>
                        {constants.CANCEL}
                      </Button>
                    </div>
                  )}
                </Box>
              )}
          </Box>
        )}
      </Box>
    </div>
  );
}
export default PaymentDetailTemplate;
