import dayjs from 'dayjs';
import {filterOperators} from '../../../../lib/commonTableHelpers';
import {constants} from '../../../../constants/common';

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);
export const invoiceLinesColumns = [
  {
    field: 'invcLineNo',
    sortable: true,
    headerName: 'InvcLineNo',
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'itemID',
    sortable: true,
    headerName: 'ItemID',
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'itemShortDesc',
    sortable: true,
    headerName: 'ItemShortDesc',
    minWidth: 150,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'qtyShipped',
    sortable: true,
    headerName: 'QtyShipped',
    minWidth: 80,
    type: 'number',
    filterOperators: filterOperators.number,
  },
  {
    field: 'unitMeasID',
    sortable: true,
    headerName: 'UnitMeasID',
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'unitPrice',
    headerName: 'UnitPrice',

    sortable: true,
    valueFormatter: params =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
    minWidth: 80,
    type: 'number',
  },
  {
    field: 'extAmt',
    headerName: 'ExtAmt',
    sortable: true,
    valueFormatter: params =>
      params?.value?.toLocaleString('en-US', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
    minWidth: 80,
    type: 'number',
  },
  {
    field: 'udf1',
    sortable: true,
    headerName: 'UdF1',
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'udf2',
    sortable: true,
    headerName: 'UdF2',
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'udf3',
    sortable: true,
    headerName: 'UdF3',
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'udf4',
    sortable: true,
    headerName: 'UdF4',
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'udf5',
    sortable: true,
    headerName: 'UdF5',
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'udf6',
    sortable: true,
    headerName: 'UdF6',
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'udf7',
    sortable: true,
    headerName: 'UdF7',
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'udf8',
    sortable: true,
    headerName: 'UdF8',
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'udf9',
    sortable: true,
    headerName: 'UdF9',
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'udf10',
    sortable: true,
    headerName: 'UdF1',
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'shipToAddrName',
    sortable: true,
    headerName: 'ShipToAddrName',
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'shipToAddrLine1',
    sortable: true,
    headerName: 'ShipToAddrLine1',
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'shipToAddrLine2',
    sortable: true,
    headerName: 'ShipToAddrLine2',
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'shipToAddrLine3',
    sortable: true,
    headerName: 'ShipToAddrLine3',
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'shipToAddrLine4',
    sortable: true,
    headerName: 'ShipToAddrLine4',
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'shipToAddrLine5',
    sortable: true,
    headerName: 'ShipToAddrLine5',
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'shipToCity',
    sortable: true,
    headerName: 'ShipToCity',
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'shipToState',
    sortable: true,
    headerName: 'ShipToState',
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'shipToPostalCode',
    sortable: true,
    headerName: 'ShipToPostalCode',
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'shipToCountry',
    sortable: true,
    headerName: 'ShipToCountry',
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'shipFromAddrName',
    sortable: true,
    headerName: 'ShipFromAddrName',
    minWidth: 120,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'shipFromAddrLine1',
    sortable: true,
    headerName: 'ShipFromAddrLine1',
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'shipFromAddrLine2',
    sortable: true,
    headerName: 'ShipFromAddrLine2',
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'shipFromAddrLine3',
    sortable: true,
    headerName: 'ShipFromAddrLine3',
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'shipFromAddrLine4',
    sortable: true,
    headerName: 'ShipFromAddrLine4',
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'shipFromAddrLine5',
    sortable: true,
    headerName: 'ShipFromAddrLine5',
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'shipFromCity',
    sortable: true,
    headerName: 'shipFromCity',
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'shipFromState',
    sortable: true,
    headerName: 'ShipFromState',
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'shipFromPostalCode',
    sortable: true,
    headerName: 'ShipFromPostalCode',
    minWidth: 80,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'shipFromCountry',
    sortable: true,
    headerName: 'ShipFromCountry',
    minWidth: 100,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'amount01',
    sortable: true,
    headerName: 'Amount01',
    minWidth: 120,
    renderHeaderFilter: () => null,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'amount02',
    sortable: true,
    headerName: 'Amount02',
    minWidth: 120,
    renderHeaderFilter: () => null,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'amount03',
    sortable: true,
    headerName: 'Amount03',
    renderHeaderFilter: () => null,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'amount04',
    sortable: true,
    headerName: 'Amount04',
    renderHeaderFilter: () => null,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'amount05',
    sortable: true,
    headerName: 'Amount05',
    renderHeaderFilter: () => null,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'amount06',
    sortable: true,
    headerName: 'Amount06',
    renderHeaderFilter: () => null,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'amount07',
    sortable: true,
    headerName: 'Amount07',
    renderHeaderFilter: () => null,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'amount08',
    sortable: true,
    headerName: 'Amount08',
    renderHeaderFilter: () => null,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'amount09',
    sortable: true,
    headerName: 'Amount09',
    renderHeaderFilter: () => null,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'amount10',
    sortable: true,
    headerName: 'Amount10',
    renderHeaderFilter: () => null,
    type: 'string',
    filterOperators: filterOperators.string,
  },
  {
    field: 'date01',
    sortable: true,
    headerName: 'Date01',
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 120,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'date02',
    sortable: true,
    headerName: 'Date02',
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 120,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'date03',
    sortable: true,
    headerName: 'Date03',
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 120,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'date04',
    sortable: true,
    headerName: 'Date04',
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 120,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'date05',
    sortable: true,
    headerName: 'Date05',
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 120,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'date06',
    sortable: true,
    headerName: 'Date06',
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 120,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'date07',
    sortable: true,
    headerName: 'Date07',
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 120,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'date08',
    sortable: true,
    headerName: 'Date08',
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 120,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'date09',
    sortable: true,
    headerName: 'Date09',
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 120,
    type: 'date',
    filterOperators: filterOperators.date,
  },
  {
    field: 'date010',
    sortable: true,
    headerName: 'Date10',
    valueFormatter: params =>
      params?.value
        ? dayjs(params?.value).utc().format(constants.DATE_FORMAT)
        : '',
    minWidth: 120,
    type: 'date',
    filterOperators: filterOperators.date,
  },
];
