import PropTypes from 'prop-types';
import {isEmpty} from '../../../lib/utils';
import TextField from '../../../ui/inputs/textfield/TextField';
import {editableFieldClassName} from './classNameConstants';

export const RenderUDFListTemplate = ({
  UDFFields,
  isEditMode,
  onUDFTextFieldsChange,
}) => {
  return (
    <div className="udf-wrapper">
      {!isEmpty(UDFFields) &&
        Object.keys(UDFFields).map(key => {
          return (
            <div
              className={`my-1 ${isEditMode && editableFieldClassName}`}
              key={UDFFields[key]?.label}
            >
              <TextField
                className={`${isEditMode ? 'ms-3' : 'ms-0'} m-3`}
                value={
                  isEmpty(UDFFields[key]?.value) ? '' : UDFFields[key]?.value
                }
                label={UDFFields[key]?.label}
                InputLabelProps={{
                  shrink:
                    !isEditMode ||
                    (isEditMode && !isEmpty(UDFFields[key]?.value)),
                }}
                InputProps={
                  isEditMode && {
                    style: {
                      minWidth: '200px',
                      maxWidth: '200px',
                    },
                  }
                }
                onChange={e => onUDFTextFieldsChange(key, e.target.value)}
                disabled={
                  !isEditMode || (isEditMode && !UDFFields[key]?.editable)
                }
              />
            </div>
          );
        })}
    </div>
  );
};

RenderUDFListTemplate.defaultProps = {
  isEditMode: false,
  UDFFields: null,
  obj: null,
  onUDFTextFieldsChange: () => {},
};

RenderUDFListTemplate.propTypes = {
  isEditMode: PropTypes.bool,
  UDFFields: PropTypes.shape({}),
  obj: PropTypes.shape({}),
  onUDFTextFieldsChange: PropTypes.func,
};
