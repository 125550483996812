import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import dayjs from 'dayjs';
import {constants} from '../../constants/common';
import {updateInvoiceDetails} from '../../services/activities';
import {fetchInvoiceDetails} from '../../services/communications';
import {
  getAllActiveContacts,
  getDisputeCodeList,
} from '../../store/actions/activitiesActions';
import {getUserData} from '../../store/actions/userActions';
import {getDisputeUDFInvoiceList, getUDFInvoiceList} from '../../utils/udfList';
import Backdrop from '../common/Backdrop';
import {OpenDocs} from '../communicate/open-docs/OpenDocs';
// Todo: Resolve dependency cycle es-lint errors
// eslint-disable-next-line import/no-cycle
import InvoiceDetailsTemplateTabs from './InvoiceDetailsTemplateTabs';
import {downloadInvoice} from '../../utils/fileUtils';
import {
  Button,
  Checkbox,
  DateTimePicker,
  Select,
  TextField,
} from '../../ui/inputs';
import {isEmpty} from '../../lib/utils';
import {IContact} from '../../interfaces/view-contacts/contacts';
import {
  IDisputeUDFs,
  IInvoiceDetail,
  IUDFs,
} from '../account-overview/interface';
import {IStatementsOfAccount} from '../account-overview/statement-of-account/interface';
import {ISnackbarProps} from '../../ui/feedback/snackbar/Snackbar';
import {Snackbar} from '../../ui/feedback';
import {getUdfValue} from './helper';

interface IInvoiceDetailTemplate {
  invcKey: string;
  onReload: () => void;
  isTaggedInvoice: boolean;
  selectedRow: IStatementsOfAccount | null;
  setShowInvoiceDetailsDialog: (value: boolean) => void;
}

function InvoiceDetailTemplate({
  invcKey,
  onReload,
  isTaggedInvoice = false,
  selectedRow,
  setShowInvoiceDetailsDialog,
}: IInvoiceDetailTemplate) {
  const [invoiceDetails, setInvoiceDetails] = useState<IInvoiceDetail>();
  const {groupKey} = getUserData();
  const [showCustomerDocs, setShowCustomerDocs] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState<IInvoiceDetail>();
  const {options} = useSelector(
    (store: any) => store.usersReducer.userSettings,
  );
  const [loadingCommunicate, setLoadingCommunicate] = useState(false);
  const [udfInvoiceList, setUdfInvoiceList] = useState<IUDFs | null>(null);
  const [disputeUDFList, setDisputeUDFList] = useState<IDisputeUDFs | null>(
    null,
  );
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [closingTranDate, setClosingTranDate] = useState<
    string | Date | undefined
  >(undefined);

  const [updatedClosingTranDate, setUpdatedClosingTranDate] =
    useState<boolean>(false);

  const [snackbarObj, setSnackbarObj] = useState<ISnackbarProps>({
    message: '',
    onClose: () => {},
    open: false,
    title: '',
    type: 'success',
  });

  const dispatch = useDispatch<any>();
  const disputeCodes = useSelector((store: any) => {
    const list = store.activitiesReducer.disputeCodes;
    const formatted = list.map(
      (item: {disputeCode: number; disputeCodeKey: number}) => {
        return {label: item.disputeCode, value: item.disputeCodeKey};
      },
    );
    return [{label: 'None', value: 'None'}, ...formatted];
  });
  const contactsDropDown = useSelector((store: any) => {
    const {contactList} = store.activitiesReducer;
    return contactList.map((info: IContact) => {
      return {label: info.contactName, value: info.contactKey};
    });
  });

  const request = {
    invcKey: invoiceDetails?.invcKey,
    balance: invoiceDetails?.balance,
    balanceHc: invoiceDetails?.balanceHc,
    closingTranDate: invoiceDetails?.closingTranDate,
    postTranDate: invoiceDetails?.postDate,
    invcContactKey: invoiceDetails?.invcContactKey,
    invcContactKey2: invoiceDetails?.invcContactKey2,
    ovrdInvcContact1: invoiceDetails?.ovrdInvcContact1,
    ovrdInvcContact2: invoiceDetails?.ovrdInvcContact2,
    keepInSync: invoiceDetails?.keepInSync,
    disputeNote: invoiceDetails?.disputeNote,
    note: invoiceDetails?.disputeNote,
    UDF1: getUdfValue(udfInvoiceList, 'udf1'),
    UDF2: getUdfValue(udfInvoiceList, 'udf2'),
    UDF3: getUdfValue(udfInvoiceList, 'udf3'),
    UDF4: getUdfValue(udfInvoiceList, 'udf4'),
    UDF5: getUdfValue(udfInvoiceList, 'udf5'),
    UDF6: getUdfValue(udfInvoiceList, 'udf6'),
    UDF7: getUdfValue(udfInvoiceList, 'udf7'),
    UDF8: getUdfValue(udfInvoiceList, 'udf8'),
    UDF9: getUdfValue(udfInvoiceList, 'udf9'),
    UDF10: getUdfValue(udfInvoiceList, 'udf10'),
    DisputeUDF1: getUdfValue(disputeUDFList, 'disputeUdf1'),
    DisputeUDF2: getUdfValue(disputeUDFList, 'disputeUdf2'),
    DisputeUDF3: getUdfValue(disputeUDFList, 'disputeUdf3'),
    DisputeUDF4: getUdfValue(disputeUDFList, 'disputeUdf4'),
    DisputeUDF5: getUdfValue(disputeUDFList, 'disputeUdf5'),
  };

  const updateSnackbarObj = ({type, title, open, message}: ISnackbarProps) => {
    setSnackbarObj({type, title, open, message, onClose: () => {}});
  };

  const saveInvoiceDetail = (updateUDF = false) => {
    if (
      !updateUDF &&
      invoiceDetails?.keepInSync &&
      invoiceDetails?.closingTranDate != null &&
      invoiceDetails?.postDate != null &&
      dayjs(invoiceDetails?.closingTranDate).isBefore(
        dayjs(invoiceDetails?.postDate),
      )
    ) {
      setShowErrorMessage(true);
    } else {
      setShowErrorMessage(false);

      setLoadingCommunicate(true);
      updateInvoiceDetails({...request, UpdateUDF: updateUDF})
        .then(() => {
          onReload();
          setLoadingCommunicate(false);
        })
        .catch(() => {
          setLoadingCommunicate(false);
          updateSnackbarObj({
            type: 'warning',
            message: '',
            onClose: () => {},
            open: true,
            title: constants.SOMETHING_WENT_WRONG,
          });
        });
    }
  };

  const onPDFDownloadIconClick = async () => {
    downloadInvoice(groupKey, invoiceDetails?.invcKey, null, updateSnackbarObj);
  };

  const onFolderIconClicked = () => {
    const documentNo = invoiceDetails?.tranId;
    setSelectedInvoice({invcKey, documentNo});
    setShowCustomerDocs(true);
  };

  const handleOnInvoiceDetailsChanged = (key: string, value: any) => {
    setInvoiceDetails({...invoiceDetails, [key]: value});
  };

  useEffect(() => {
    setLoadingCommunicate(true);
    fetchInvoiceDetails(invcKey)
      .then(response => {
        const resultObj = response;
        setInvoiceDetails(resultObj);
        setUdfInvoiceList(getUDFInvoiceList(options, resultObj));
        setDisputeUDFList(getDisputeUDFInvoiceList(options, resultObj));
        const {custKey} = resultObj;
        dispatch(getAllActiveContacts({groupKey, custKey}));
        setLoadingCommunicate(false);
      })
      .catch(() => {
        setLoadingCommunicate(false);
        updateSnackbarObj({
          type: 'warning',
          message: '',
          onClose: () => {},
          open: true,
          title: constants.SOMETHING_WENT_WRONG,
        });
      });
  }, []);

  useEffect(() => {
    dispatch(getDisputeCodeList(groupKey));
  }, []);

  const onClosingTranDateChange = (value: any) => {
    const date = new Date(value);
    setClosingTranDate(date);
    handleOnInvoiceDetailsChanged('closingTranDate', date);
    setUpdatedClosingTranDate(true);
  };
  useEffect(() => {
    if (
      invoiceDetails &&
      invoiceDetails.closingTranDate &&
      isEmpty(closingTranDate) &&
      !updatedClosingTranDate
    ) {
      onClosingTranDateChange(invoiceDetails.closingTranDate);
    }
  }, [invoiceDetails]);

  const handleOnSnackbarClose = () => {
    setSnackbarObj(prev => ({
      ...prev,
      open: false,
    }));
  };

  return (
    <div className="border px-2">
      <Snackbar
        open={snackbarObj.open}
        onClose={handleOnSnackbarClose}
        message={snackbarObj.message}
        title={snackbarObj.title}
        type={snackbarObj.type}
      />
      {loadingCommunicate && <Backdrop />}
      <div className="mb-3 mt-1">
        <div>
          <span>
            {constants.companyWithATransactionCurrencyOf(
              invoiceDetails?.currId,
            )}
          </span>
        </div>
        <div>
          <span className="me-2">
            {constants.CUST_ID}: {`${invoiceDetails?.custId},`}
          </span>
          <span className="me-2">
            {constants.CUST_NAME}: {`${invoiceDetails?.custName},`}
          </span>
          <span>
            {constants.PO_NUMBER}: {invoiceDetails?.custPono}
          </span>
        </div>
      </div>

      <div className="my-2 border px-1 py-2  light-grey ">
        <div className="my-3 d-flex align-items-center justify-content-between">
          <div className="w-100">
            <span className="mb-2 text-bold">
              {constants.INVOICE_INFORMATION.toLocaleUpperCase()}
            </span>
          </div>
          <div className="w-100">
            <span>
              <Checkbox
                checked={invoiceDetails?.keepInSync}
                className="me-3"
                onChange={e =>
                  handleOnInvoiceDetailsChanged('keepInSync', e.target.checked)
                }
              />
              {constants.DO_NOT_SYNC}
            </span>
          </div>
          {!isTaggedInvoice && (
            <div className="w-100">
              <Button
                variant="contained"
                onClick={() => saveInvoiceDetail(false)}
              >
                {constants.SAVE}
              </Button>
            </div>
          )}
        </div>

        <div className="d-flex justify-content-between flex-wrap">
          <div className=" d-flex justify-content-between align-items-center flex-column m-2 w-300-px">
            <div className="w-100 p-2 d-flex justify-content-between mt-4">
              <span className="me-2">{constants.INVOICE_NUMBER}</span>
              <span className="me-2">{invoiceDetails?.tranId}</span>
              <span className="px-2">
                <i
                  className="pi pi-pdf cursor-pointer"
                  onClick={onPDFDownloadIconClick}
                  aria-hidden="true"
                />
                <i
                  className="pi pi-folder ps-1 cursor-pointer"
                  onClick={onFolderIconClicked}
                  aria-hidden="true"
                />
              </span>
            </div>
            <TextField
              id="tranAmt"
              disabled
              type="number"
              label={constants.AMOUNT}
              value={`${invoiceDetails?.tranAmt}`}
              InputLabelProps={{shrink: true}}
              margin="normal"
            />
            <TextField
              label={constants.AMOUNT_HC}
              disabled
              type="number"
              id="tranAmtHc"
              value={`${invoiceDetails?.tranAmtHc}`}
              InputLabelProps={{shrink: true}}
              margin="normal"
            />
            <TextField
              label={constants.DUE_DATE}
              disabled
              id="dueDate"
              value={
                invoiceDetails?.dueDate
                  ? dayjs(invoiceDetails?.dueDate)
                      .utc()
                      .format(constants.DATE_FORMAT_1)
                  : ''
              }
              InputLabelProps={{shrink: true}}
              margin="normal"
            />
            <TextField
              label={constants.PMT_DATE}
              disabled
              id="lastPmtDate"
              value={
                invoiceDetails?.lastPmtDate
                  ? dayjs(invoiceDetails?.lastPmtDate)
                      .utc()
                      .format(constants.DATE_FORMAT_1)
                  : ''
              }
              InputLabelProps={{shrink: true}}
              margin="normal"
            />
          </div>

          <div className="d-flex justify-content-between flex-column m-2  w-300-px">
            <TextField
              label={constants.INVOICE_DATE}
              disabled
              id="tranDate"
              value={
                invoiceDetails?.tranDate
                  ? dayjs(invoiceDetails?.tranDate)
                      .utc()
                      .format(constants.DATE_FORMAT_1)
                  : ''
              }
              InputLabelProps={{shrink: true}}
              margin="normal"
            />
            <TextField
              label={constants.BALANCE_DUE}
              disabled={!invoiceDetails?.keepInSync}
              type="number"
              id="balance"
              value={`${invoiceDetails?.balance}`}
              onChange={e =>
                handleOnInvoiceDetailsChanged('balance', e.target.value)
              }
              onBlur={() => {
                const value = Number(invoiceDetails?.balance) ?? 0;
                const updateValue = Number.isNaN(value)
                  ? Number(0).toFixed(2)
                  : Number(value || 0).toFixed(2);
                handleOnInvoiceDetailsChanged('balance', updateValue);
              }}
              InputLabelProps={{
                shrink: !invoiceDetails?.keepInSync ? true : undefined,
              }}
              margin="normal"
            />
            <TextField
              type="number"
              label={constants.BALANCE_DUE_HC}
              disabled={!invoiceDetails?.keepInSync}
              id="balanceHc"
              value={`${invoiceDetails?.balanceHc}`}
              onChange={e =>
                handleOnInvoiceDetailsChanged('balanceHc', e.target.value)
              }
              onBlur={() => {
                const value = Number(invoiceDetails?.balanceHc) ?? 0;
                const updateValue = Number.isNaN(value)
                  ? Number(0).toFixed(2)
                  : Number(value || 0).toFixed(2);
                handleOnInvoiceDetailsChanged('balanceHc', updateValue);
              }}
              InputLabelProps={{
                shrink: !invoiceDetails?.keepInSync ? true : undefined,
              }}
              margin="normal"
            />
            <TextField
              label={constants.DISC_DATE}
              disabled
              id="discDate"
              value={
                invoiceDetails?.discDate
                  ? dayjs(invoiceDetails?.discDate)
                      .utc()
                      .format(constants.DATE_FORMAT_1)
                  : ''
              }
              InputLabelProps={{shrink: true}}
              margin="normal"
            />
            <TextField
              label={constants.DAYS_PAID_LATE}
              disabled
              id="daysPaidLate"
              value={
                invoiceDetails?.daysPaidLate
                  ? `${invoiceDetails?.daysPaidLate}`
                  : ''
              }
              InputLabelProps={{shrink: true}}
              margin="normal"
            />
          </div>
          <div className="d-flex flex-column m-2 w-300-px">
            <TextField
              label={constants.STATUS}
              disabled
              id="status"
              value={invoiceDetails?.status}
              InputLabelProps={{shrink: true}}
              margin="normal"
            />
            <DateTimePicker
              format="MM/DD/YYYY"
              label={constants.CLOSING_DATE}
              orientation="landscape"
              yearsPerRow={3}
              slotProps={{
                textField: {
                  size: 'small',
                },
              }}
              disabled={!invoiceDetails?.keepInSync}
              value={
                closingTranDate
                  ? dayjs.utc(new Date(closingTranDate))
                  : undefined
              }
              onChange={onClosingTranDateChange}
              fullWidth
              margin="normal"
            />

            {showErrorMessage && (
              <p
                className="text-error m-0 p-0 position-absolute"
                style={{bottom: '-20px'}}
              >
                {constants.CLOSING_DATE_CANNOT_BE_LESS_THAN_POST_DATE}
              </p>
            )}
            <TextField
              label={constants.POST_DATE}
              disabled
              id="postDate"
              value={
                invoiceDetails?.postDate
                  ? dayjs(invoiceDetails?.postDate)
                      .utc()
                      .format(constants.DATE_FORMAT_1)
                  : ''
              }
              InputLabelProps={{shrink: true}}
              margin="normal"
            />
            <TextField
              disabled
              label={constants.TAGGED_COMMUNICATIONS}
              id="tagCount"
              value={`${invoiceDetails?.tagCount}` || ''}
              onChange={e =>
                handleOnInvoiceDetailsChanged('tagCount', e.target.value)
              }
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
            />
          </div>
        </div>

        <div className="d-flex align-items-end justify-content-between m-2">
          <div className="d-flex flex-column pb-0 me-1">
            <span className="my-1">
              {constants.overrideInvoiceContact(1)}
              <Checkbox
                checked={invoiceDetails?.ovrdInvcContact1}
                onChange={e =>
                  handleOnInvoiceDetailsChanged(
                    'ovrdInvcContact1',
                    e.target.checked,
                  )
                }
              />
            </span>
            <Select
              label={constants.SELECT}
              value={`${invoiceDetails?.invcContactKey}`}
              shrinkLabel={!invoiceDetails?.ovrdInvcContact1 ? undefined : true}
              notched={!invoiceDetails?.ovrdInvcContact1 ? undefined : true}
              disabled={!invoiceDetails?.ovrdInvcContact1}
              items={contactsDropDown}
              onChange={e =>
                handleOnInvoiceDetailsChanged('invcContactKey', e.target.value)
              }
              placeholder={constants.SELECT}
              fullWidth={false}
              sx={{minWidth: '250px'}}
            />
          </div>

          <div className="d-flex flex-column pb-0 me-1">
            <span className="my-1">
              {constants.overrideInvoiceContact(2)}
              <Checkbox
                checked={invoiceDetails?.ovrdInvcContact2}
                onChange={e =>
                  handleOnInvoiceDetailsChanged(
                    'ovrdInvcContact2',
                    e.target.checked,
                  )
                }
              />
            </span>
            <Select
              label={constants.SELECT}
              value={`${invoiceDetails?.invcContactKey2}`}
              shrinkLabel={!invoiceDetails?.ovrdInvcContact2 ? undefined : true}
              notched={!invoiceDetails?.ovrdInvcContact2 ? undefined : true}
              disabled={!invoiceDetails?.ovrdInvcContact2}
              items={contactsDropDown}
              onChange={e =>
                handleOnInvoiceDetailsChanged('invcContactKey2', e.target.value)
              }
              placeholder={constants.SELECT}
              fullWidth={false}
              sx={{minWidth: '250px'}}
            />
          </div>
          <TextField
            label={constants.CONSOLIDATED_INVC_NO}
            id="consolidatedInvcNo"
            value={invoiceDetails?.consolidatedInvcNo}
            onChange={e =>
              handleOnInvoiceDetailsChanged(
                'consolidatedInvcNo',
                e.target.value,
              )
            }
            margin="normal"
            fullWidth={false}
          />
        </div>
        <div className="d-flex align-items-center justify-content-between m-2">
          <TextField
            label={constants.PREDICTED_PAY_DATE}
            id="predictedPayDate"
            className="me-1"
            value={
              invoiceDetails?.predictedPayDate
                ? dayjs(invoiceDetails?.predictedPayDate)
                    .utc()
                    .format(constants.DATE_FORMAT_1)
                : ''
            }
            onChange={e =>
              handleOnInvoiceDetailsChanged('predictedPayDate', e.target.value)
            }
            disabled
            InputLabelProps={{shrink: true}}
            margin="normal"
            fullWidth={false}
          />
          <TextField
            label={constants.PREDICTED_PAY_AMT}
            id="predictedPayAmt"
            className="me-1"
            value={`${Number(invoiceDetails?.predictedPayAmt).toFixed(3)}`}
            onChange={e =>
              handleOnInvoiceDetailsChanged('predictedPayAmt', e.target.value)
            }
            margin="normal"
            fullWidth={false}
          />
          <TextField
            label={constants.PREDICTED_PAY_REASON}
            id="predictedPayReason"
            value={invoiceDetails?.predictedPayReason}
            onChange={e =>
              handleOnInvoiceDetailsChanged(
                'predictedPayReason',
                e.target.value,
              )
            }
            margin="normal"
            fullWidth={false}
          />
        </div>
      </div>
      <InvoiceDetailsTemplateTabs
        invoiceKey={`${invcKey}`}
        invoiceDetails={invoiceDetails}
        udfInvoiceList={udfInvoiceList}
        setUdfInvoiceList={setUdfInvoiceList}
        saveInvoiceDetail={() => saveInvoiceDetail(true)}
        groupKey={groupKey}
        disputeCodes={disputeCodes}
        disputeUDFList={disputeUDFList}
        setDisputeUDFList={setDisputeUDFList}
        selectedRow={selectedRow}
        isTaggedInvoice={isTaggedInvoice}
        setInvoiceDetails={setInvoiceDetails}
        closeInvoiceDialog={() => setShowInvoiceDetailsDialog(false)}
      />
      {showCustomerDocs && (
        <OpenDocs
          groupKey={groupKey}
          setShowCustomerDocs={setShowCustomerDocs}
          showCustomerDocs={showCustomerDocs}
          custName={invoiceDetails?.custName}
          custKey={invoiceDetails?.custKey}
          isInvoice
          selectedInvoice={selectedInvoice}
          custId={invoiceDetails?.custId}
        />
      )}
    </div>
  );
}
export default InvoiceDetailTemplate;
