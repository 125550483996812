import {actionTypes} from '../constants/actionTypes';
// backdrop on paymentPanel if fetchingCurrencies || fetchingPaymentMethods
const initialState = {
  fetchingCurrencies: false,
  fetchedCurrencies: false,
  fetchingCurrenciesFailed: false,
  currencies: [],
  selectedCurrency: '',

  fetchingPaymentMethods: false,
  fetchedPaymentMethods: false,
  fetchingPaymentMethodsFailed: false,
  addingPaymentMethod: false,
  addedPaymentMethod: false,
  addingPaymentMethodFailed: false,
  updatingPaymentMethod: false,
  updatedPaymentMethod: false,
  updatingPaymentMethodFailed: false,
  deletingPaymentMethod: false,
  deletedPaymentMethods: false,
  deletingPaymentMethodFailed: false,
  paymentMethodNotFoundErrorMessage: '',
  paymentMethodNotFoundError: false,
  updatingRedirectUrl: false,
  updatedRedirectUrl: false,

  paymentMethods: [],
  selectedPaymentMethod: {},
  paymentInfoPageSize: 10,
  paymentInfoSkip: 0,

  makingPayment: false,
  madePayment: false,
  makingPaymentFailed: false,
  // For Iframe if no redirectURL
  showLoader: false,
  redirectUrl: '',

  errorMessage: '',

  paymentErrorObj: {},

  paymentInformationLoadedPage: 1,
  paymentInformationCurrentPage: 1,
};

const paymentsCurrenciesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_CURRENCIES:
      return {
        ...state,
        fetchingCurrencies: true,
        fetchedCurrencies: false,
        fetchingCurrenciesFailed: false,
      };
    case actionTypes.FETCHED_CURRENCIES:
      return {
        ...state,
        fetchingCurrencies: false,
        fetchedCurrencies: true,
        fetchingCurrenciesFailed: false,
        currencies: action.currencies,
      };
    case actionTypes.FETCHING_CURRENCIES_FAILED:
      return {
        ...state,
        fetchingCurrencies: false,
        fetchedCurrencies: false,
        fetchingCurrenciesFailed: true,
      };
    case actionTypes.FETCHING_FILE_LIST_FAILED:
      return {
        ...state,
        fetchingCurrencies: false,
        fetchedCurrencies: false,
        fetchingCurrenciesFailed: true,
        currencies: initialState.currencies,
      };
    case actionTypes.FETCHING_PAYMENT_METHODS:
      return {
        ...state,
        fetchingPaymentMethods: true,
        fetchedPaymentMethods: false,
        fetchingPaymentMethodsFailed: false,
      };
    case actionTypes.FETCHED_PAYMENT_METHODS:
      return {
        ...state,
        fetchingPaymentMethods: false,
        fetchedPaymentMethods: true,
        fetchingPaymentMethodsFailed: false,
        paymentMethods: action.paymentMethods,
      };
    case actionTypes.FETCHING_PAYMENT_METHODS_FAILED:
      return {
        ...state,
        fetchingPaymentMethods: false,
        fetchedPaymentMethods: false,
        fetchingPaymentMethodsFailed: true,
        paymentMethods: initialState.paymentMethods,
      };
    case actionTypes.UPDATE_SELECTED_CURRENCY:
      return {
        ...state,
        fetchingCurrencies: false,
        selectedCurrency: action.selectedCurrency,
      };
    case actionTypes.UPDATING_SELECTED_CURRENCY:
      return {
        ...state,
        fetchingCurrencies: true,
      };
    case actionTypes.UPDATE_SELECTED_PAYMENT_METHOD:
      return {
        ...state,
        selectedPaymentMethod: action.selectedPaymentMethod,
      };
    case actionTypes.UPDATING_SELECTED_PAYMENT_METHOD:
      return {
        ...state,
        addedPaymentMethod: false,
        updatingPaymentMethod: true,
        updatedPaymentMethod: false,
        deletedPaymentMethods: false,
      };
    case actionTypes.UPDATE_PAYMENT_INFO_SKIP:
      return {
        ...state,
        paymentInfoSkip: action.paymentInfoSkip,
      };

    case actionTypes.ADDING_PAYMENT_METHOD:
      return {
        ...state,
        addingPaymentMethod: true,
        addedPaymentMethod: false,
        addingPaymentMethodFailed: false,
        updatedPaymentMethod: false,
        deletedPaymentMethods: false,
        showLoader: true,
        errorMessage: initialState.errorMessage,
      };

    case actionTypes.ADDED_PAYMENT_METHOD:
      return {
        ...state,
        addingPaymentMethod: false,
        addedPaymentMethod: true,
        addingPaymentMethodFailed: false,
        updatedPaymentMethod: false,
        deletedPaymentMethods: false,
        showLoader: false,
        errorMessage: initialState.errorMessage,
      };
    case actionTypes.ADDING_PAYMENT_METHOD_FAILED:
      return {
        ...state,
        addingPaymentMethod: false,
        addedPaymentMethod: false,
        addingPaymentMethodFailed: true,
        errorMessage: action.errorMessage,
        redirectUrl: initialState.redirectUrl,
        showLoader: false,
      };

    case actionTypes.UPDATING_PAYMENT_METHOD:
      return {
        ...state,
        addingPaymentMethod: false,
        addedPaymentMethod: false,
        updatingPaymentMethod: true,
        updatedPaymentMethod: false,
        updatingPaymentMethodFailed: false,
        deletedPaymentMethods: false,
        showLoader: true,
      };

    case actionTypes.UPDATED_PAYMENT_METHOD:
      return {
        ...state,
        addedPaymentMethod: false,
        updatingPaymentMethod: false,
        updatedPaymentMethod: true,
        updatingPaymentMethodFailed: false,
        deletedPaymentMethods: false,
        showLoader: false,
      };
    case actionTypes.UPDATING_PAYMENT_METHOD_FAILED:
      return {
        ...state,
        addedPaymentMethod: false,
        addingPaymentMethodFailed: false,
        updatingPaymentMethod: false,
        updatedPaymentMethod: false,
        updatingPaymentMethodFailed: true,
        deletedPaymentMethods: false,
      };

    case actionTypes.DELETING_PAYMENT_METHOD:
      return {
        ...state,
        addedPaymentMethod: false,
        updatedPaymentMethod: false,
        deletingPaymentMethod: true,
        deletedPaymentMethods: false,
        deletingPaymentMethodFailed: false,
        paymentMethodNotFoundError: false,
      };

    case actionTypes.DELETED_PAYMENT_METHOD:
      return {
        ...state,
        addedPaymentMethod: false,
        updatedPaymentMethod: false,
        deletingPaymentMethod: false,
        deletedPaymentMethods: true,
        deletingPaymentMethodFailed: false,
        paymentMethodNotFoundError: false,
      };

    case actionTypes.DELETING_PAYMENT_METHOD_FAILED:
      return {
        ...state,
        addedPaymentMethod: false,
        updatedPaymentMethod: false,
        deletingPaymentMethod: false,
        deletedPaymentMethods: false,
        deletingPaymentMethodFailed: true,
        paymentMethodNotFoundError: action.paymentMethodNotFoundError || false,
        paymentMethodNotFoundErrorMessage:
          action.paymentMethodNotFoundErrorMessage ||
          initialState.paymentMethodNotFoundErrorMessage,
      };
    case actionTypes.UPDATING_REDIRECT_URL:
      return {
        ...state,
        updatingRedirectUrl: true,
      };
    case actionTypes.UPDATED_REDIRECT_URL:
      return {
        ...state,
        redirectUrl: action.redirectUrl,
        updatingRedirectUrl: false,
        updatedRedirectUrl: true,
        showLoader: false,
      };

    case actionTypes.MAKING_PAYMENT: {
      return {
        ...state,
        makingPayment: true,
        madePayment: false,
        makingPaymentFailed: false,
        showLoader: true,
        errorMessage: initialState.errorMessage,
      };
    }
    case actionTypes.MADE_PAYMENT: {
      return {
        ...state,
        makingPayment: false,
        madePayment: true,
        makingPaymentFailed: false,
        showLoader: false,
        paymentErrorObj: initialState.paymentErrorObj,
      };
    }
    case actionTypes.MAKE_PAYMENT_FAILED: {
      return {
        ...state,
        makingPayment: false,
        madePayment: false,
        makingPaymentFailed: true,
        showLoader: false,
        errorMessage: action.errorMessage,
      };
    }
    case actionTypes.VIEWING_PAYMENT_INFO: {
      return {
        ...state,
        showLoader: true,
      };
    }
    case actionTypes.VIEWING_PAYMENT_INFO_FAILED: {
      return {
        ...state,
        errorMessage: action.errorMessage,
        redirectUrl: initialState.redirectUrl,
        showLoader: false,
      };
    }
    case actionTypes.UPDATE_PAYMENT_ERROR_OBJ: {
      return {
        ...state,
        paymentErrorObj: action.paymentErrorObj,
      };
    }
    case actionTypes.UPDATE_ERROR_MESSAGE: {
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    }
    case actionTypes.RESET_NO_PAYMENT_METHOD_FOUND_ERROR: {
      return {
        ...state,
        paymentMethodNotFoundError: false,
        paymentMethodNotFoundErrorMessage:
          initialState.paymentMethodNotFoundErrorMessage,
      };
    }

    case actionTypes.UPDATE_PAYMENT_INFO_CURRENT_PAGE: {
      return {
        ...state,
        paymentInformationCurrentPage: action.paymentInformationCurrentPage,
      };
    }

    case actionTypes.UPDATE_PAYMENT_INFO_LOADED_PAGE: {
      return {
        ...state,
        paymentInformationLoadedPage: action.paymentInformationLoadedPage,
      };
    }

    default:
      return state;
  }
};

export default paymentsCurrenciesReducer;
