import {actionTypes} from '../constants/actionTypes';

const initialState = {
  invoiceLinesCount: 0,
  invoiceLinesNextLink: '',
  invoiceLines: [],
  invoiceLinesLoading: false,
  fetchingInvoiceLines: false,
  fetchedInvoiceLines: false,
  fetchingInvoiceLinesFailed: false,
};

const invoiceLinesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_INVOICE_LINES:
      return {
        ...state,
        invoiceLinesLoading: true,
        fetchingInvoiceLines: true,
        fetchedInvoiceLines: false,
        fetchingInvoiceLinesFailed: false,
      };
    case actionTypes.FETCHED_INVOICE_LINES:
      return {
        ...state,
        invoiceLinesLoading: false,
        fetchingInvoiceLines: false,
        fetchedInvoiceLines: true,
        fetchingInvoiceLinesFailed: false,
        invoiceLines: action.invoiceLines,
        invoiceLinesCount: action.invoiceLinesCount,
        invoiceLinesNextLink: action.invoiceLinesNextLink,
      };
    case actionTypes.FETCHING_INVOICE_LINES_FAILED:
      return {
        ...state,
        invoiceLinesLoading: false,
        fetchingInvoiceLines: false,
        fetchedInvoiceLines: false,
        fetchingInvoiceLinesFailed: true,
      };
    default:
      return state;
  }
};
export default invoiceLinesReducer;
