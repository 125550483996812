import {constants} from '../../../constants/common';
import {Dialog} from '../../../ui';
import RenderAlerts from './RenderAlerts';

function AlertsModal({
  showAlertsPopup,
  handleCloseAlertsPopup,
  viewName,
  customFilterSqlValue,
  sortSettingJson,
  columnSettingJson,
  updateTableOnColumnSettingsChange,
  loadViewSetting,
}: {
  viewName: string;
  updateTableOnColumnSettingsChange: (
    updatedColumns: any[],
    settingKey: string,
  ) => void;
  showAlertsPopup: boolean;
  handleCloseAlertsPopup: () => void;
  customFilterSqlValue: string;
  sortSettingJson: string;
  columnSettingJson: string;
  loadViewSetting: (row: any) => void;
}) {
  return (
    <Dialog
      open={showAlertsPopup}
      onClose={handleCloseAlertsPopup}
      title={constants.SETUP_ALERTS}
      maxWidth="lg"
      fullWidth
      draggable
    >
      <RenderAlerts
        viewName={viewName}
        loadViewSetting={loadViewSetting}
        handleCloseAlertsPopup={handleCloseAlertsPopup}
        filterSqlText={customFilterSqlValue}
        sortSettingJson={sortSettingJson}
        columnSettingJson={columnSettingJson}
        updateTableOnColumnSettingsChange={updateTableOnColumnSettingsChange}
      />
    </Dialog>
  );
}

export default AlertsModal;
