import {useDispatch, useSelector} from 'react-redux';
import {updateViewSettingObject} from '../../../../store/actions/contactsActions';
import {ViewSettingsDropDown} from '../../../common/view-settings/view-settings-dropdown/ViewSettingsDropDown';

import {Box} from '../../../../ui/layouts';
import {gridModeTypes} from '../../../../constants/common';

export const ViewSettings = () => {
  const dispatch = useDispatch<any>();

  const {contactsSettingId, contactsViewSettings} = useSelector(
    (store: any) => store.contactsReducer,
  );

  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
      marginY="12px"
    >
      <Box width="250px">
        <ViewSettingsDropDown
          settingId={contactsSettingId}
          viewName="UserContacts"
          onViewSettingSelected={(selectedSettingId, viewSettings) =>
            dispatch(
              updateViewSettingObject({
                settingId: selectedSettingId,
                viewSettings,
              }),
            )
          }
          isQueryParamDefault
          currentViewSettings={contactsViewSettings}
          activityType={gridModeTypes.Contacts}
        />
      </Box>
    </Box>
  );
};
