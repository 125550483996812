import {useDispatch, useSelector} from 'react-redux';
import {ViewSettingsDropDown} from '../../../common/view-settings/view-settings-dropdown/ViewSettingsDropDown';
import {updateProjectedCashReceiptsViewSettingObject} from '../../../../store/actions/projectedCashReceiptsActions';
import {Box} from '../../../../ui/layouts';
import {gridModeTypes} from '../../../../constants/common';

export const ViewSettings = () => {
  const {projectedCashReceiptsSettingId, projectedCashReceiptsViewSettings} =
    useSelector((store: any) => store.projectedCashReceiptsReducer);
  const dispatch = useDispatch();
  return (
    <Box width="250px">
      <ViewSettingsDropDown
        settingId={projectedCashReceiptsSettingId}
        viewName="UserCashProjDetail"
        isQueryParamDefault
        onViewSettingSelected={(selectedSettingId, viewSettings) =>
          dispatch(
            updateProjectedCashReceiptsViewSettingObject({
              settingId: selectedSettingId,
              viewSettings,
            }),
          )
        }
        currentViewSettings={projectedCashReceiptsViewSettings}
        activityType={gridModeTypes.ProjectedCashReceipts}
      />
    </Box>
  );
};
