import {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {Dialog} from '../../ui';
import {Button} from '../../ui/inputs';
import {constants} from '../../constants/common';
import EmailTemplate from '../messages/EmailTemplate';
import InvoiceDetailTemplate from '../messages/InvoiceDetailTemplate';
import PaymentDetailTemplate from '../messages/payment-details/PaymentDetailTemplate';
import {checkIsTranTypePayment} from '../account-overview/statement-of-account/helper';
import {showDialog} from '../../store/actions/generalActions';
import {MODE_TYPES} from '../../utils/constants';
import {useQueryKeys} from '../../hooks/useQueryKeys';
import ActivityDetailTemplate from '../messages/activities-details/ActivityDetailTemplate';
import {IStatementsOfAccount} from '../account-overview/statement-of-account/interface';
import {isEmpty} from '../../lib/utils';

// Component to display email details
export const ViewEmail = ({values}: {values: any}) => {
  const [viewEmail, setViewEmail] = useState<boolean>(false);

  return (
    <>
      <Button
        className="py-1 d-flex align-items-center justify-content-center h-30-px"
        onClick={() => {
          setViewEmail(true);
        }}
        variant="contained"
        startIcon={<span className="pi pi-view" />}
        size="medium"
      />
      <Dialog
        open={viewEmail}
        width={800}
        height={600}
        draggable
        resizable
        title={constants.EMAIL}
        onClose={() => {
          setViewEmail(false);
        }}
      >
        <EmailTemplate
          {...values.row}
          onReload={() => {}}
          navigateBack={() => setViewEmail(false)}
        />
      </Dialog>
    </>
  );
};

export const InvoiceLink = ({values}: {values: any}) => {
  const {row} = values;
  const [showInvoiceDetailsDialog, setShowInvoiceDetailsDialog] =
    useState<boolean>(false);
  const [showPaymentDetailsDialog, setShowPaymentDetailsDialog] =
    useState<boolean>(false);
  const [selectedCustomerPmtKey, setSelectedCustomerPmtKey] =
    useState<string>('');
  const [selectedTranType, setSelectedTranType] = useState<string>('');
  const [selectedInvoiceKey, setSelectedInvoiceKey] = useState<string>('');
  const [selectedRow, setSelectedRow] = useState<IStatementsOfAccount | null>(
    null,
  );
  const handleOnInvoiceClick = (
    tranType: string,
    invcKey: string,
    custPmtKey: string,
  ) => {
    const checkPayment = checkIsTranTypePayment(tranType);
    if (checkPayment) {
      setShowPaymentDetailsDialog(true);
      setSelectedTranType(tranType);
      setSelectedCustomerPmtKey(custPmtKey);
      setSelectedRow(row);
    } else {
      setSelectedInvoiceKey(invcKey);
      setSelectedRow(row);
      setShowInvoiceDetailsDialog(true);
    }
  };

  return (
    <>
      <Dialog
        title={constants.PAYMENT_DETAILS}
        open={
          !!(
            !isEmpty(selectedRow) &&
            selectedCustomerPmtKey &&
            showPaymentDetailsDialog
          )
        }
        onClose={() => {
          setShowPaymentDetailsDialog(false);
          setSelectedCustomerPmtKey('');
          setSelectedTranType('');
          setSelectedRow(null);
        }}
        draggable
        maxWidth="md"
        fullWidth
      >
        <PaymentDetailTemplate
          custPmtKey={selectedCustomerPmtKey}
          tranType={selectedTranType}
        />
      </Dialog>
      <Dialog
        title={constants.INVOICE_DETAILS}
        open={
          !!(
            !isEmpty(selectedRow) &&
            selectedInvoiceKey &&
            showInvoiceDetailsDialog
          )
        }
        onClose={() => {
          setShowInvoiceDetailsDialog(false);
          setSelectedInvoiceKey('');
          setSelectedRow(null);
        }}
        draggable
        maxWidth="lg"
        fullWidth
      >
        <InvoiceDetailTemplate
          invcKey={selectedInvoiceKey}
          selectedRow={selectedRow}
          onReload={() => {}}
          isTaggedInvoice={false}
          setShowInvoiceDetailsDialog={setShowInvoiceDetailsDialog}
        />
      </Dialog>
      <span
        className={
          row.tranType.toLocaleLowerCase() === 'pp'.toLocaleLowerCase()
            ? ``
            : `blueLink cursor-pointer`
        }
        onClick={() => {
          handleOnInvoiceClick(row?.tranType, row?.invcKey, row?.custPmtKey);
        }}
        aria-hidden="true"
      >
        {row?.tranId}
      </span>
    </>
  );
};

export const TagCount = ({values}: {values: any}) => {
  const {row} = values;
  const dispatch = useDispatch();
  const {userKey} = useQueryKeys();
  const navigate = useNavigate();
  const location = useLocation();
  const {state, search} = location;

  const {custId, companyId, selectCompanyId} = state || {};

  const showExistingActivity = (rowData: any) => {
    const communicateProps = {
      ...rowData,
      ModeType: MODE_TYPES.enquiry,
      custId,
      companyId,
      selectCompanyId,
      id: rowData?.taskActKey,
    };
    const params = new URLSearchParams(search);
    params.set('activityId', communicateProps.id);

    navigate(`activity?${params.toString()}`, {
      state: {...communicateProps, from: location},
    });
  };

  const showActivitiesDialog = (InvcNumber: string, docKey: string) => {
    dispatch(
      // @ts-ignore
      showDialog({
        width: '70vw',
        title: constants.activityDetailsForInvoice(InvcNumber),
        dialogContent: (
          <ActivityDetailTemplate
            docKey={docKey}
            userKey={userKey}
            // @ts-ignore as ActivityDetailTemplate is jsx file
            showExistingActivity={showExistingActivity}
          />
        ),
      }),
    );
  };
  const onActivitiesClick = (rowData: any) => {
    if (rowData.tagCount > 0) {
      showActivitiesDialog(rowData.tranId, rowData.invcKey);
    }
  };
  return (
    <span
      aria-hidden="true"
      className={`${row.tagCount > 0 && 'blueLink cursor-pointer'}`}
      onClick={() => onActivitiesClick(row)}
    >
      {`${row.tagCount} ${constants.ACTIVITIES}`}{' '}
    </span>
  );
};
