import {SelectChangeEvent, FormControl} from '@mui/material';
import {
  useGridApiContext,
  useGridSelector,
  gridFilterModelSelector,
  GridHeaderFilterCellProps,
} from '@mui/x-data-grid-pro';
import React from 'react';
import {Select} from '../../../ui/inputs';

const getDefaultFilter = (field: string) => ({field, operator: 'is'});

export function CheckUnCheckFilter(props: GridHeaderFilterCellProps) {
  const {colDef} = props;
  const apiRef = useGridApiContext();
  const filterModel = useGridSelector(apiRef, gridFilterModelSelector);
  const currentFieldFilters = React.useMemo(
    () => filterModel.items?.filter(({field}) => field === colDef.field),
    [colDef.field, filterModel.items],
  );

  const handleChange = React.useCallback(
    (event: SelectChangeEvent) => {
      if (!event.target.value) {
        if (currentFieldFilters[0]) {
          apiRef.current.deleteFilterItem(currentFieldFilters[0]);
        }
        return;
      }
      apiRef.current.upsertFilterItem({
        ...(currentFieldFilters[0] || getDefaultFilter(colDef.field)),
        value: event.target.value,
      });
    },
    [apiRef, colDef.field, currentFieldFilters],
  );

  const value = currentFieldFilters[0]?.value ?? '';

  return (
    <FormControl variant="standard">
      <Select
        labelId="select-is-admin-label"
        id="select-is-admin"
        value={value || ''}
        onChange={handleChange as (event: SelectChangeEvent<unknown>) => void}
        label=""
        displayEmpty
        formMargin="none"
        items={[
          {label: 'Clear', value: ''},
          {label: 'Checked', value: 'true'},
          {label: 'Unchecked', value: 'false'},
        ]}
      />
    </FormControl>
  );
}
