import {constants} from '../../constants/common';
import {
  fetchBestActivities,
  fetchHomeDashboardStats,
  fetchLastSyncData,
  fetchUserAlertsSummary,
} from '../../services/home';
import {actionTypes} from '../constants/actionTypes';

export const getBestActivities = options => (dispatch, getState) => {
  const {userKey, groupKey, ruleKey, skipRows, rowCount} = options;
  const toast = getState().generalReducer?.toastRef;

  dispatch({type: actionTypes.FETCHING_BEST_ACTIVITY});
  return fetchBestActivities({userKey, groupKey, ruleKey, skipRows, rowCount})
    .then(response => {
      dispatch({
        type: actionTypes.FETCHED_BEST_ACTIVITY,
        bestActivityData: response.data.value,
        bestActivityDataCount: response.data.count,
      });
    })
    .catch(error => {
      if (!error.response || error.response.status !== 404) {
        dispatch({type: actionTypes.FETCHING_BEST_ACTIVITY_FAILED});
        toast?.current.show({
          severity: 'warn',
          summary: constants.FAILED_TO_LOAD_DATA,
          detail: constants.LOADING_BEST_ACTIVITIES_FAILED,
        });
      }
    });
};

export const getHomeDashboardStats =
  ({userKey, groupKey}) =>
  (dispatch, getState) => {
    const toast = getState().generalReducer?.toastRef;

    dispatch({type: actionTypes.FETCHING_HOME_DASHBOARD_STATS});

    return fetchHomeDashboardStats({userKey, groupKey})
      .then(response => {
        dispatch({
          type: actionTypes.FETCHED_HOME_DASHBOARD_STATS,
          homeDashboardStats: response.data,
        });
      })
      .catch(error => {
        if (!error.response || error.response.status !== 404) {
          toast?.current.show({
            severity: 'warn',
            summary: constants.FAILED_TO_LOAD_DATA,
            detail: constants.LOADING_HOME_DASHBOARD_STATS_FAILED,
          });
        }
        dispatch({type: actionTypes.FETCHING_HOME_DASHBOARD_STATS_FAILED});
      });
  };

export const getUserAlertSummary =
  ({userKey, groupKey}) =>
  (dispatch, getState) => {
    const toast = getState().generalReducer?.toastRef;
    return fetchUserAlertsSummary({userKey, groupKey})
      .then(response => {
        dispatch({
          type: actionTypes.FETCHED_USER_ALERT_SUMMARY,
          userAlertSummary: response.data,
        });
      })
      .catch(error => {
        if (!error.response || error.response.status !== 404) {
          toast?.current.show({
            severity: 'warn',
            summary: constants.FAILED_TO_LOAD_DATA,
            detail: constants.LOADING_USER_ALERT_SUMMARY_FAILED,
          });
        }
      });
  };

export const getLastSyncData = groupKey => (dispatch, getState) => {
  const toast = getState().generalReducer?.toastRef;
  return fetchLastSyncData({groupKey})
    .then(response => {
      dispatch({
        type: actionTypes.FETCHED_LAST_SYNC_DATA,
        lastSyncData: response.data,
      });
    })
    .catch(error => {
      if (!error.response || error.response.status !== 404) {
        toast?.current.show({
          severity: 'warn',
          summary: constants.FAILED_TO_LOAD_DATA,
          detail: constants.LOADING_LAST_SYNC_DATA_FAILED,
        });
      }
    });
};
