import {useLocation} from 'react-router-dom';
import Communicate from '../common/Communicate';
import ErrorBoundaryWrapper from '../error-boundary/ErrorBoundaryWrapper';

const CommunicateWrapper = () => {
  const {state} = useLocation();
  if (!state) return null;
  return (
    <ErrorBoundaryWrapper>
      <Communicate data={state} activityId={state.id} />
    </ErrorBoundaryWrapper>
  );
};

export default CommunicateWrapper;
