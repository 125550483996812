import {styled} from '@mui/material';
import {
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import CachedIcon from '@mui/icons-material/Cached';
import DescriptionIcon from '@mui/icons-material/Description';
import {FaArrowsAltH} from 'react-icons/fa';
import {CheckboxLabel, Button} from '../../../ui/inputs';
import {constants} from '../../../constants/common';

export default function ViewMessagesToolbar({
  onRefresh,
  exportToExcel,
  markAllRead,
  showAllMessages,
  handleShowAllMessages,
  applyBestFit,
}: {
  onRefresh: any;
  exportToExcel: any;
  markAllRead: any;
  showAllMessages: boolean;
  handleShowAllMessages: any;
  applyBestFit: () => void;
}) {
  const WrapperDiv = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  }));
  return (
    <WrapperDiv className="d-flex justify-content-between flex-wrap">
      <GridToolbarContainer>
        <GridToolbarFilterButton componentsProps={{button: {disabled: true}}} />
        <Button startIcon={<DescriptionIcon />} onClick={exportToExcel}>
          {constants.EXPORT_TO_EXCEL}
        </Button>
        <Button startIcon={<FaArrowsAltH />} onClick={() => applyBestFit()}>
          {constants.BEST_FIT}
        </Button>
        <GridToolbarDensitySelector />
        <Button startIcon={<CachedIcon />} onClick={onRefresh}>
          {constants.REFRESH}
        </Button>
      </GridToolbarContainer>
      <GridToolbarContainer>
        <CheckboxLabel
          label={constants.SHOW_ALL_MESSAGES}
          onChange={handleShowAllMessages}
          checked={showAllMessages}
        />
        <Button variant="contained" onClick={markAllRead}>
          {constants.MARK_ALL_AS_READ}
        </Button>
      </GridToolbarContainer>
    </WrapperDiv>
  );
}
