import {Dialog} from 'primereact';
import {
  FaFolderPlus,
  FaListUl,
  FaFileUpload,
  FaFolderOpen,
} from 'react-icons/fa';
import {AiFillDelete} from 'react-icons/ai';
import {FiGrid, FiRefreshCcw} from 'react-icons/fi';
import {useFileManager} from '../FileManager';
import './FileManageOptions.css';
import {FileUploadDialog} from './FileUploadDialog';
import {NewFolder} from './NewFolder';
import {ConfirmDeleteDialog} from './ConfirmDeleteDialog';
import {constants} from '../../../../constants/common';
import {validateDirForDelete} from '../../../../utils/fileUtils';

export const FileManageOptions = ({
  showFileUpload,
  setShowFileUpload = () => {},
  showAttachWarning,
  setShowAttachWarning = () => {},
  onAttachmentClick = () => {},
  showNewFolder,
  setShowNewFolder = () => {},
  setShowConfirmDelete = () => {},
  showConfirmDelete,
  hideAttachToCommuicate,
  showFolderNotSelected,
  setShowFolderNotSelected,
}) => {
  const {
    isListView,
    updateView,
    selectedDirectory,
    onFileOrFolderDelete,
    onRefresh,
    fileSelected,
    isInvoice,
    onOpenFolderClicked,
  } = useFileManager();

  const showDelete = validateDirForDelete(selectedDirectory, fileSelected);
  const onConfirmDelete = () => {
    onFileOrFolderDelete();
  };

  return (
    <div className="d-flex file-manage-options-container">
      <span className="cursor-pointer" title="open">
        <FaFolderOpen size={18} onClick={onOpenFolderClicked} />
      </span>
      <span className="cursor-pointer" title="refresh">
        <FiRefreshCcw size={18} onClick={onRefresh} />
      </span>
      <span className="cursor-pointer" title="Add folder">
        <FaFolderPlus size={18} onClick={() => setShowNewFolder(true)} />
      </span>
      {showDelete && (
        <span className="cursor-pointer" title="Delete">
          <AiFillDelete size={18} onClick={() => setShowConfirmDelete(true)} />
        </span>
      )}

      <span className="cursor-pointer" title="List view">
        <FaListUl
          size={18}
          color={isListView ? '#7d4ed8' : 'black'}
          onClick={() => updateView(true)}
        />
      </span>
      <span className="cursor-pointer" title="Grid View">
        <FiGrid
          size={18}
          color={isListView ? 'black' : '#7d4ed8'}
          onClick={() => updateView(false)}
        />
      </span>
      <span className="cursor-pointer" title="File upload">
        <FaFileUpload size={18} onClick={() => setShowFileUpload(true)} />
      </span>
      {!isInvoice && !hideAttachToCommuicate && (
        <span
          aria-hidden="true"
          className="cursor-pointer"
          title={constants.ATTACH_TO_COMMUNICATE}
          onClick={onAttachmentClick}
        >
          <FiRefreshCcw size={18} />{' '}
          <span className="blueLink">{constants.ATTACH_TO_COMMUNICATE}</span>
        </span>
      )}

      <Dialog
        visible={showNewFolder}
        className="w-25"
        position="center"
        baseZIndex={9999}
        onHide={() => setShowNewFolder(false)}
        closeOnEscape={false}
        header="New Folder"
      >
        <NewFolder onHide={() => setShowNewFolder(false)} />
      </Dialog>
      <Dialog
        visible={showFileUpload}
        className="w-50"
        position="center"
        baseZIndex={9999}
        onHide={() => setShowFileUpload(false)}
        closeOnEscape={false}
        header="Upload"
      >
        <FileUploadDialog onHide={() => setShowFileUpload(false)} />
      </Dialog>
      <Dialog
        visible={showConfirmDelete}
        className="w-25"
        position="center"
        baseZIndex={9999}
        onHide={() => setShowConfirmDelete(false)}
        closeOnEscape={false}
        header="Delete"
      >
        <ConfirmDeleteDialog
          onConfirm={onConfirmDelete}
          onHide={() => setShowConfirmDelete(false)}
        />
      </Dialog>
      <Dialog
        header={constants.INFO}
        visible={showAttachWarning}
        className="w-25"
        baseZIndex={9999}
        onHide={() => setShowAttachWarning(false)}
        closeOnEscape={false}
      >
        <p className="d-flex text-align-center">
          <i className="pi pi-custom pi-info mx-3" />
          {constants.DIRECTORY_CAN_NOT_BE_ADDED}
        </p>
      </Dialog>
      <Dialog
        header={constants.WARNING}
        visible={showFolderNotSelected}
        className="w-25"
        baseZIndex={9999}
        onHide={() => setShowFolderNotSelected(false)}
        closeOnEscape={false}
      >
        <p className="d-flex text-align-center">
          <i className="pi pi-custom pi-warning mx-3" />
          {constants.FOLDER_NOT_SELECTED}
        </p>
      </Dialog>
    </div>
  );
};
